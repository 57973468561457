export function replaceEmptyValues(array) {
  console.log(array, '处理的数据');
  const result = [];

  array.forEach((obj) => {
    const newObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === '' || obj[key] === null || obj[key].length === 0) {
          newObj[key] = '——';
          //   — —
        } else {
          newObj[key] = obj[key];
        }
      }
    }

    result.push(newObj);
  });

  return result;
}
