<template>
    <div id="userPower">
    </div>
</template>
<script setup>
import { ref, onMounted, onBeforeMount, computed, watch, reactive } from "vue";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
const router = useRouter();
const route = useRoute();
onMounted(()=>{
    if(route.name=='UserPower'){
    router.push({
        name:'UserInfo'
    })
}
})
</script>
<style lang="less" scoped>
@import url("@/style/views/systemManagement/UserPower.less");
</style>
