<template>
  <div id="ProjectSchedule">
    <div class="ContentBox">
      <div class="ProjectListBox">
        <div class="vxetable">
          <!-- 表格头部 工具按钮 -->
          <vxe-toolbar>
            <template #buttons>
              <el-date-picker v-model="SearchData.DateValue" type="month" placeholder="请选择月份" format="YYYY-MM" value-format="YYYY-MM" />
              <!-- <el-select v-model="ToolBar.DateValue" class="m-2" placeholder="Select">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
              </el-select> -->
              <vxe-input
                class="Search"
                v-model="SearchData.Content"
                type="search"
                placeholder="请输入项目编号或名称进行搜索"
                @Blur="ReqFist"></vxe-input>
              <vxe-button @click="ResetTable" class="Reset"><span class="reset-icon">重置</span></vxe-button>
            </template>
          </vxe-toolbar>
          <!-- 表格 -->
          <div class="tableOutSide">
            <vxe-table
              v-show="demo1.tableData.length != 0"
              border
              ref="xTable"
              height="auto"
              auto-resize
              :cell-class-name="cellClassName"
              show-overflow
              :loading="demo1.loading"
              :data="demo1.tableData"
              :column-config="{ resizable: true, useKey: 'id' }"
              :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
              row-id="_id"
              :checkbox-config="{ reserve: true }">
              <vxe-column field="number" type="html" title="项目编号" width="140" fixed="left" min-width="140">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === '项目编号'">
                    <span class="name">项目编号</span>

                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('项目编号', $event)"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <div class="projectNumber" @dblclick="toProjectDetail(row)">{{ row.number }}</div>
                </template>
              </vxe-column>
              <vxe-column field="name" type="html" title="项目名称" width="224" fixed="left" min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === '项目名称'">
                    <span class="name">项目名称</span>

                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('项目名称', $event)"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <div class="projectName" @dblclick="toProjectDetail(row)">{{ row.name }}</div>
                </template>
              </vxe-column>

              <vxe-column
                v-for="(item, index) in demo1.DateTitle"
                :key="index"
                :title="item"
                :field="'status' + index"
                type="html"
                width="65"
                :fixed="demo1.colFixeds.col3"
                min-width="65">
                <!-- 自定义项目状态的样式 -->
                <template #default="{ row }">
                  <div class="Status" @dblclick="toProjectDetail(row)">
                    <span v-if="false">{{ row }}}</span>
                    <!-- <span class="Full" :class="row.data[index] ? 'Fulls' : 'notFulls'">{{}}</span> -->
                    <el-tooltip placement="top" :show-after="300" v-if="getStatus(row, index)" popper-class="Back">
                      <template #content>
                        {{ '项目开始时间:' + dayjs(row.projectStart_time).format('YYYY-MM-DD') }}<br />{{
                          '项目结束时间:' + dayjs(row.projectEnd_time).format('YYYY-MM-DD')
                        }}
                      </template>
                      <span
                        class="Full"
                        :class="[
                          getStatus(row, index) ? 'Fulls' : '',
                          getStatus(row, index - 1) ? '' : 'Action',
                          getStatus(row, index + 1) ? '' : 'End',
                          getStatus(row, index + 1) != true && getStatus(row, index - 1) != true ? 'Midle' : '',
                        ]"></span>
                    </el-tooltip>
                  </div>
                </template>
              </vxe-column>

              <template #empty>
                <span style="color: red">
                  <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
                  <p>暂无数据</p>
                </span>
              </template>
            </vxe-table>
          </div>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="MoreOper.showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(MoreOper.FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(MoreOper.FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import dayjs from 'dayjs';

import { VxeTableInstance } from 'vxe-table';
import XEUtils from 'xe-utils';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import Dates from '@/utils/CurrentDate';

const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
// 排序
let SORT = ref({ createdAt: -1 });
const xTable = ref();
// api接收
const store = useStore();
const router = useRouter();

const SearchData = ref({
  DateValue: '',
  Content: '',
});
// 更多操作
const OperationBox = ref(null);
const MoreOper = ref({
  showIdOptions: false,
  FixedIndex: -1,
});
const options = [
  {
    value: 'Option1',
    label: 'Option1',
  },
  {
    value: 'Option2',
    label: 'Option2',
  },
  {
    value: 'Option3',
    label: 'Option3',
  },
  {
    value: 'Option4',
    label: 'Option4',
  },
  {
    value: 'Option5',
    label: 'Option5',
  },
];
//note--->demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  loading: false,
  list: [],
  week: '', //切换 0 日

  DateTitle: [],
});
let ProjectDetailsShow = ref(false);
// 新功能=>跳转至项目详情
const toProjectDetail = (row) => {
  // console.log('我要跳转喽！');
  console.log(row);
  store.commit('Set_CreatedProject', row._id);
  store.commit('Set_ProjectId', row._id);
  store.commit('Set_ProjectNumber', row.number);
  ProjectDetailsShow.value = true;
  store.commit('Set_ProjectTitle', 'projectDetails');
  store.commit('Set_ProjectDetails', row._id);
  setTimeout(() => {
    router.push({
      name: 'ProjectDetails',
      params: {
        _id: row._id,
      },
    });
  }, 100);
  store.commit('Set_CurrentProjectName', '项目列表');
};

// 工具栏数据
const ToolBar = ref({
  DateValue: '',
});

// tip  方法区域
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value,event :>> ', value, event);
  const table = xTable.value;
  const FixedList = table.getColumns();

  FixedList.forEach((item, index) => {
    if (item.title == value) {
      MoreOper.value.FixedIndex = index;
    }
  });
  console.log('object :>> ', MoreOper.value.FixedIndex);
  // MoreOper.value.showIdOptions = false;
  MoreOper.value.showIdOptions = true;

  console.log(' MoreOper.value.showIdOptions :>> ', MoreOper.value.showIdOptions, event.clientX);

  OperationBox.value.style.left = event.clientX - 50 + 'px';
  OperationBox.value.style.top = event.clientY + 10 + 'px';
};

// 获取每月的周几
function getDayOfWeek(year, month, day) {
  // month参数从0开始，所以需要减去1
  let date = new Date(year, month, day);
  let dayOfWeek = date.getDay(); // 返回值为0（星期日）到6（星期六）
  return dayOfWeek;
}
// 获取每个单元格的值
function getStatus(row, index) {
  // console.log('row[status+index] :>> ', row['status' + index]);
  return row['status' + index];
}
// 处理表头
const Day = (yy, mm) => {
  // console.log('yy,mm :>> ', yy, mm);
  demo1.DateTitle.splice(0, demo1.DateTitle.length);
  // 示例用法
  let year = yy;
  let month = mm; // 0表示1月，1表示2月，以此类推
  let daysInMonth = new Date(year, month + 1, 0).getDate(); // 获取该月的天数

  for (let day = 1; day <= daysInMonth; day++) {
    let dayOfWeek = getDayOfWeek(year, month, day);

    //   console.log(day + '号是星期' + dayOfWeek);
    switch (dayOfWeek) {
      case 1:
        demo1.week = '一';
        break;
      case 2:
        demo1.week = '二';
        break;
      case 3:
        demo1.week = '三';
        break;
      case 4:
        demo1.week = '四';
        break;
      case 5:
        demo1.week = '五';
        break;
      case 6:
        demo1.week = '六';
        break;
      case 0:
        demo1.week = '日';
        break;
      default:
        break;
    }
    //   console.log(day + '号是星期' + demo1.week);
    demo1.DateTitle.push(day + ' ' + demo1.week);

    // console.log('object :>> ', demo1.DateTitle);
  }
};

const cellClassName = ({ row, column }) => {
  // console.log('column :>> ', row, row[column.field]);
  // console.log('column :>> ', column);

  if (column.title.includes('日') || column.title.includes('六')) {
    return 'weedDay';
  }
  if (row[column.field] == true) {
    return 'FULLS';
  }
  return null;
};
// 获取初始数据
const ReqFist = async () => {
  demo1.loading = true;
  const res = await http.post('/project/getProjectSchdule-project', {
    content: SearchData.value.Content,
    monthString: SearchData.value.DateValue,
    sort: SORT.value,
  });
  console.log('项目排期初始数据返回结果', res);
  if (res.status == 0) {
    demo1.tableData = res.schdules;

    demo1.loading = false;
  }
};

//重置
const ResetTable = () => {
  let d = Dates('current');
  SearchData.value.DateValue = d.slice(0, 7);
  SearchData.value.Content = '';

  ReqFist();
};

// More -->升序/降序
const AscendingSort = async (index, type) => {
  console.log('index,type :>> ', index, type);
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  console.log('tableColumns :>> ', tableColumns);
  const ColumnId = tableColumns[index].field;
  console.log(ColumnId, 'ColumnId');
  const sorts = {};
  if (type == 'Asc') {
    sorts[ColumnId] = 1;
  } else {
    sorts[ColumnId] = -1;
  }

  SORT.value = sorts;
  console.log(SORT.value, 'SORT.value');
  ReqFist(); //调用接口
};

// 切换时间就发送请求
watch(
  () => SearchData.value.DateValue,
  (newValue) => {
    ReqFist(); //请求初始数据
    // console.log('newValue :>> ', newValue);
    let parts = newValue.split('-');
    let year = parts[0];
    let month = parts[1];

    month = parseInt(month, 10); // 将月份2转换为整数
    if (month < 10) {
      month = month.toString(); // 将单个数字的月份转换回字符串
    }
    month = month - 1; // 将单个数字的月份转换回字符串

    Day(year, month);
  },
);
onMounted(() => {
  store.commit('Set_CurrentProjectName', '项目排期表');
  let d = Dates('current');
  console.log('当前时间是 :>> ', d);
  SearchData.value.DateValue = d.slice(0, 7);

  // 点击下拉
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (MoreOper.value.showIdOptions == true) {
      if (e.target.id != 'MoreOptions') {
        MoreOper.value.showIdOptions = false;
      }
    }
  });
});
</script>
<style scoped lang="less">
@import url(@/style/views/ProjectManagement/ProjectSchedule.less);
</style>
