<template>
  <div id="HomeView987654321">
    <div class="home">
      <div class="home_top">
        <div class="home_top_item">
          <div class="text_text_text">新增志愿者人数 (人)</div>
          <div class="num_num_num">
            {{ VolunteerDataBoardInfo.volunteerNew }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">累计志愿者人数 (人)</div>
          <div class="num_num_num">
            {{ VolunteerDataBoardInfo.volunteerTotal }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">当日志愿者报名人次 (人)</div>
          <div class="num_num_num">
            {{ VolunteerDataBoardInfo.recruitNewDay }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">当月志愿者报名人次 (次)</div>
          <div class="num_num_num">
            {{ VolunteerDataBoardInfo.recruitNewMonth }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">累计志愿者报名人次 (次)</div>
          <div class="num_num_num">
            {{ VolunteerDataBoardInfo.recruitTotal }}
          </div>
        </div>
      </div>

      <div class="home_dataProject">
        <div class="data_trend">
          <div class="trend_head">
            <div class="head_title">数据趋势</div>
            <!-- <div
              class="head_nav"
              :class="dataTrendArrIndex == index ? 'head_click' : ''"
              @click="dataTrendArrIndexChange(index)"
              v-for="(item, index) in dataTrendArr"
              :key="index"
            >
              {{ item }}
            </div> -->
            <div class="head_time">
              <el-date-picker
                v-model="dataTrenTime"
                type="daterange"
                unlink-panels
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                range-separator=""
                start-placeholder="请选择日期时间"
                @change="dataTrenTimeChange"
                :clearable="false"
              />
            </div>
          </div>
          <div class="trend_heads">
            <div
              v-show="rangeArrs.length != 0"
              class="heads_item"
              @click="dataTrenitemArrChange(index)"
              v-for="(item, index) in dataTrenitemArr[dataTrendArrIndex]"
              :key="index"
            >
              <img
                class="item_img"
                :src="
                  dataTrenitemArrIndex == index
                    ? 'https://newstore.vynior.com/cems_web/%E6%A4%AD%E5%9C%86%20341%402x.png'
                    : 'https://newstore.vynior.com/cems_web/%E6%A4%AD%E5%9C%86%20341%402x%20%281%29.png'
                "
                alt=""
              />
              <div class="item_text">{{ item }}</div>
            </div>
          </div>
          <div id="treadEcharts" v-show="rangeArrs.length != 0"></div>
          <div class="noData" v-show="rangeArrs.length == 0">暂无数据</div>
        </div>
        <div class="data_project">
          <div class="project_head">
            <div class="head_title">分布情况</div>
            <div class="head_all">
              <el-select
                v-model="selectvalue"
                class="m-2"
                placeholder="请选择"
                size="large"
                @change="changevalue"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div class="project_main" v-if="false">
            <div class="main_item">
              <div class="item_name">wodsdfsdfsdfsdfsdfsdfsfdsdfsdfsdfemz</div>
              <div class="item_status">
                <div class="status_box status_incomplete">weikais</div>
              </div>
              <div class="item_speed">
                <div class="speed">
                  <div class="speed_back" style="width: 10%"></div>
                </div>
                <div class="speed_val">10%</div>
              </div>
              <div class="item_time">12346656564</div>
            </div>
          </div>
          <div
            id="project_ecarts"
            v-show="currentCircleDataInfo.length != 0"
          ></div>
          <div class="noData" v-show="currentCircleDataInfo.length == 0">
            暂无数据
          </div>
        </div>
      </div>
    </div>
    <div id="reset_password">
      <el-dialog
        v-model="resetPwd"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <template #header>修改初始密码</template>
        <template #default>
          <div class="content">
            <div class="title">
              为了您的账号安全，首次登录或重置密码后请修改初始密码
            </div>
            <div class="input-newPwd">
              <div class="input-item">
                <span>新密码</span>
                <div class="input_pwd">
                  <el-input
                    @input="pwd_verify"
                    :class="is_pwd_verify ? 'error_input' : ''"
                    v-model="new_pwd1"
                    type="password"
                    show-password
                    placeholder="请填写新密码"
                  />
                </div>
              </div>
              <div class="pwd-length-hint">
                <span v-if="is_pwd_verify" class="error-hint">
                  {{ first_pwd_hint }}
                </span>
                <span v-else>
                  密码长度为8-16位，不少于8位;应至少包含数字、大小写字母及特殊字符中的两种
                </span>
              </div>
              <div class="input-item">
                <span>确认密码</span>
                <div class="input_pwd">
                  <el-input
                    @input="sec_pwd_verify"
                    :class="is_pwd_verify2 ? 'error_input' : ''"
                    v-model="new_pwd2"
                    type="password"
                    show-password
                    placeholder="请再次输入新密码"
                  />
                </div>
              </div>
              <div class="pwd-length-hint">
                <span v-if="is_pwd_verify2" class="error-hint">
                  {{ sec_pwd_hint }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <el-button type="primary" @click="submit"> 确定 </el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script setup>
import { defineComponent, ref, onBeforeMount, onMounted } from "vue";
import { Lock } from "@element-plus/icons-vue";
import { message } from "@/utils/resetMessage";
import http from "@/utils/http";
import * as echarts from "echarts";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const selectvalue = ref("年龄段");
const options = [
  {
    value: "性别",
    label: "性别",
  },
  {
    value: "年龄段",
    label: "年龄段",
  },
  {
    value: "所在城市",
    label: "所在城市",
  },
];
onMounted(async () => {
  getCurrentMonthRange();
  getDataTrendInfo();
  getCircleData();

  getVolunteerDataBoard();
});

//获取数据趋势数据
//x轴刻度
const rangeArrs = ref([]);
//新增志愿者
const volunteerNew = ref([]);

//累计志愿者
const volunteerTotal = ref([]);

//新增志愿者报名人数
const recruitnew = ref([]);

//累计志愿者报名人数
const recruitTotal = ref([]);

//当前图表数据
const currentChartsData = ref([]);

//当前系列名称
let currentSeriesName = ref("");

//日期范围选择器  选中的值
let startData = ref("");
let endData = ref("");

//环形图  age数据对象
let circleDataOfAge = ref([]);
let circleDataOfGender = ref([]);
let circleDataOfCity = ref([]);

//获取本月的  第一天 和最后一天
const getCurrentMonthRange = () => {
  const now = new Date(); // 获取当前时间
  const year = now.getFullYear(); // 获取当前年份
  const month = now.getMonth() + 1; // 获取当前月份（注意，JavaScript 中月份是从 0 开始计数的，因此需要加 1）
  const firstDay = new Date(year, month - 1, 1); // 构造本月第一天的日期对象
  const lastDay = new Date(year, month, 0); // 构造本月最后一天的日期对象
  const firstDayStr = formatDate(firstDay); // 将本月第一天的日期格式化为字符串
  const lastDayStr = formatDate(lastDay); // 将本月最后一天的日期格式化为字符串
  console.log("当月的,", [firstDayStr, lastDayStr]);
  dataTrenTime.value = [firstDayStr, lastDayStr];
  startData.value = firstDay;
  endData.value = lastDay;
  return [firstDayStr, lastDayStr]; // 返回包含两个字符串的数组
};
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  return `${year}-${month}-${day}`;
};
const padZero = (num) => {
  return num < 10 ? "0" + num : num.toString();
};

//当下环形图数据  大数组
const currentCircleDataInfo = ref([]);

const currentCircleAgeData = ref([]);
const currentCircleSexData = ref([]);
const currentCircleCityData = ref([]);

//获取环形图数据
const getCircleData = async () => {
  let res = await http.post("/dashboard/volunteerCircleData", {});
  console.log("环形图数据", res);
  circleDataOfAge.value = res.volunteerCircleResult.age;
  circleDataOfGender.value = res.volunteerCircleResult.gender;
  circleDataOfCity.value = res.volunteerCircleResult.city;

  currentCircleAgeData.value = [
    {
      itemStyle: { color: "#004ADA" },
      name: circleDataOfAge.value[0]["name"],
      value: circleDataOfAge.value[0].num,
    },
    {
      itemStyle: { color: "#38BDD7" },
      name: circleDataOfAge.value[1]["name"],
      value: circleDataOfAge.value[1].num,
    },
    {
      itemStyle: { color: "#FC7A94" },
      name: circleDataOfAge.value[2]["name"],
      value: circleDataOfAge.value[2].num,
    },
    {
      itemStyle: { color: "#FFD47D" },
      name: circleDataOfAge.value[3]["name"],
      value: circleDataOfAge.value[3].num,
    },
  ];

  currentCircleSexData.value = [
    {
      itemStyle: { color: "#004ADA" },
      name: circleDataOfGender.value[0]["name"],
      value: circleDataOfGender.value[0].num,
    },
    {
      itemStyle: { color: "#38BDD7" },
      name: circleDataOfGender.value[1]["name"],
      value: circleDataOfGender.value[1].num,
    },
  ];

  currentCircleCityData.value = [
    {
      itemStyle: { color: "#004ADA" },
      name: circleDataOfCity.value[0]["name"],
      value: circleDataOfCity.value[0].num,
    },
    {
      itemStyle: { color: "#38BDD7" },
      name: circleDataOfCity.value[1]["name"],
      value: circleDataOfCity.value[1].num,
    },
    {
      itemStyle: { color: "#FC7A94" },
      name: circleDataOfCity.value[2]["name"],
      value: circleDataOfCity.value[2].num,
    },
    {
      itemStyle: { color: "#FFD47D" },
      name: circleDataOfCity.value[3]["name"],
      value: circleDataOfCity.value[3].num,
    },
    {
      itemStyle: { color: "#f16665" },
      name: circleDataOfCity.value[4]["name"],
      value: circleDataOfCity.value[4].num,
    },
  ];

  currentCircleDataInfo.value = currentCircleAgeData.value;

  resetPasswordChange();
};

//环形图  选择器
const changevalue = (val) => {
  console.log("环形图选择", val);
  if (val == "性别") {
    currentCircleDataInfo.value = currentCircleSexData.value;
    resetPasswordChange();
  } else if (val == "年龄段") {
    currentCircleDataInfo.value = currentCircleAgeData.value;
    resetPasswordChange();
  } else if (val == "所在城市") {
    currentCircleDataInfo.value = currentCircleCityData.value;
    resetPasswordChange();
  }
};

//获取数据看板 数据
let VolunteerDataBoardInfo = ref({});
const getVolunteerDataBoard = async () => {
  let res = await http.post("/dashboard/volunteer-dashboard", {});
  console.log("志愿者数据看板res", res);
  VolunteerDataBoardInfo.value = res.VolunteerData;
};

//获取数据趋势数据
const getDataTrendInfo = async () => {
  let res = await http.post("/dashboard/volunteerData", {
    //暂时传假数据
    // startDate: "2023-06-21",
    // endDate: "2023-06-30",
    startDate: startData.value || "",
    endDate: endData.value || "",
  });
  console.log("志愿者获取数据趋势数据", res);

  const rangeArr = [];
  const volunteerNewArr = [];
  const volunteerTotalArr = [];

  const recruitnewArr = [];
  const recruitTotalArr = [];

  res?.DataTrends?.volunteer?.forEach((item) => {
    rangeArr.push(item.range);
    volunteerNewArr.push(item.volunteerNew);

    volunteerTotalArr.push(item.volunteerTotal);
    recruitnewArr.push(item.recruitnew);
    recruitTotalArr.push(item.recruitTotal);
  });

  rangeArrs.value = rangeArr;
  volunteerNew.value = volunteerNewArr;

  volunteerTotal.value = volunteerTotalArr;
  // recruitnew.value = volunteerTotalArr;
  recruitnew.value = recruitnewArr;

  recruitTotal.value = recruitTotalArr;
  //   recruitTotal.value = [2, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  //当前图表数据
  currentChartsData.value = volunteerNewArr;

  treadEchartsChange();
  console.log("志愿者rangeArr", rangeArr);
  console.log("志愿者rangeArrs", rangeArrs.value);
};

// 折线图
const treadEchartsChange = () => {
  let myChart = echarts.init(document.getElementById("treadEcharts"));
  // 绘制图表
  myChart.setOption({
    xAxis: {
      type: "category",
      data: rangeArrs.value,
      //   [
      //     "2023-12-03",
      //     "2023-12-03",
      //     "2023-12-31",
      //     "2023-12-13",
      //     "2023-12-23",
      //     "2023-12-11",
      //   ],
      // boundaryGap: false,
      axisLine: {
        show: false,
        lineStyle: {
          color: "#8F94B2",
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        // interval: interval,
        // interval: 1,
        // axisLabel: {
        formatter: function (value) {
          return value.substring(5); // 提取从索引5开始的子字符串，即月-日部分
        },
        // },
      },
    },
    yAxis: {
      type: "value",
      axisLine: {
        show: false,
        lineStyle: {
          color: "#8F94B2",
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          // type: 'dashed',
        },
      },
    },
    tooltip: {
      show: true,
      trigger: "axis",
      // 自定义显示
      // formatter: '{b0}<br />新增用户数   {c0} 个',

      formatter: function (params) {
        // console.log(params[0].value);
        return (
          params[0].name +
          "<br />&nbsp; " +
          params[0].seriesName +
          " " +
          params[0].value +
          " " +
          (params[0].seriesName == "新增志愿者报名人次"
            ? "次"
            : params[0].seriesName == "累计志愿者报名人次"
            ? "次"
            : "个")
        );
      },
      padding: [10, 20],
      textStyle: {
        color: "#626366", // 设置字体颜色为白色
        fontSize: 10, // 设置字体大小为16
      },
    },
    series: [
      {
        name: currentSeriesName.value || "新增志愿者",
        type: "line",
        data: currentChartsData.value,
        //  [5, 20, 36, 10, 10, 20, 30, 40, 50],
        areaStyle: { type: "default" },
        smooth: true,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(0,74,218, 0.5)" },
            { offset: 0.5, color: "rgba(0,74,218, 0.3)" },
            { offset: 1, color: "rgba(0,74,218, 0.1)" },
          ]),
          borderColor: "#004ADA",
          borderWidth: 1,
          borderType: "solid",
        },
        symbol: "circle",
        showAllSymbol: "auto",
        symbolSize: 10,
        //折线拐点的类型
        // symbol: 'star',
        // 改变线条样式 lineStyle
        lineStyle: {
          color: "#004ADA",
          type: "solid",
          with: 2,
        },
        emphasis: {
          symbolSize: 0,
          itemStyle: {
            // 鼠标悬浮时拐点样式
            borderColor: "#004ADA",
            borderWidth: 10,
            borderType: "solid",
          },
        },
      },
    ],
  });
  window.onresize = function () {
    //自适应大小
    myChart.resize();
  };
};
// 环形图
const resetPasswordChange = () => {
  let myChart = echarts.init(document.getElementById("project_ecarts"));
  // 绘制图表
  myChart.setOption({
    // legend: {
    legend: {
      top: "85%",
      left: "center",
      itemGap: 83, // 设置图例项之间的距离为 20 像素
    },
    // grid: {
    // 				top: '30%', // 等价于 y: '16%'
    // 				left: '3%',
    // 				right: '0%',
    // 				bottom: '20%',
    // 				containLabel: true
    // 			},
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        avoidLabelOverlap: false,
        data: currentCircleDataInfo.value,
        // [
        //   {
        //     itemStyle: { color: "#004ADA" },
        //     name: circleDataOfAge.value[0]["name"],
        //     value: circleDataOfAge.value[0].num,
        //   },
        //   {
        //     itemStyle: { color: "#38BDD7" },
        //     name: circleDataOfAge.value[1]["name"],
        //     value: circleDataOfAge.value[1].num,
        //   },
        //   {
        //     itemStyle: { color: "#FC7A94" },
        //     name: circleDataOfAge.value[2]["name"],
        //     value: circleDataOfAge.value[2].num,
        //   },
        //   {
        //     itemStyle: { color: "#FFD47D" },
        //     name: circleDataOfAge.value[3]["name"],
        //     value: circleDataOfAge.value[3].num,
        //   },
        // ],
        itemStyle: {
          // borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 10,
        },
        // 数值显示
        label: {
          show: false,
        },
        radius: ["50%", "80%"],
        center: ["50%", "40%"],
        // itemStyle: {
        //   borderRadius: 8,
        //   borderColor: '#fff',
        //   borderWidth: 4,
        // },
      },
    ],
  });
  window.onresize = function () {
    //自适应大小
    myChart.resize();
  };
};
// 数据趋势头部导航数组
const dataTrendArr = ref(["项目", "志愿者", "仪器", "问卷", "样品", "专家"]);
// 数据趋势头部导航数组选中的值
const dataTrendArrIndex = ref(1);
// 点击头部导航选择展示的数据
const dataTrendArrIndexChange = (val) => {
  dataTrendArrIndex.value = val;
};
// 数据趋势日期数据
const dataTrenTime = ref([]);
// 数据趋势日期数据触发事件
const dataTrenTimeChange = (val) => {
  console.log("所选日期为", val);
  startData.value = val[0];
  endData.value = val[1];
};
// 数据趋势 二级导航数据
const dataTrenitemArr = ref([
  [],
  [
    "新增志愿者人数",
    "累计志愿者人数",
    "新增志愿者报名人次",
    "累计志愿者报名人次",
  ],
]);
// 选中数据趋势二级导航的值
const dataTrenitemArrIndex = ref(0);
// 数据趋势 二级导航数据选中的事件
const dataTrenitemArrChange = (val) => {
  dataTrenitemArrIndex.value = val;
  console.log("val is ", val);
  //To Do：更改表格数据，重新渲染,
  if (val == 0) {
    currentChartsData.value = volunteerNew.value;
    currentSeriesName.value = "新增志愿者";
    // myChart.resize();
    treadEchartsChange();

    console.log("当前curr", currentChartsData.value, val);
  } else if (val == 1) {
    currentChartsData.value = volunteerTotal.value;
    currentSeriesName.value = "累计志愿者";

    // myChart.resize();
    treadEchartsChange();

    console.log("当前curr", currentChartsData.value, val);
  } else if (val == 2) {
    currentChartsData.value = recruitnew.value;
    currentSeriesName.value = "新增志愿者报名人次";

    // myChart.resize();
    treadEchartsChange();

    console.log("当前curr", currentChartsData.value, val);
  } else if (val == 3) {
    currentChartsData.value = recruitTotal.value;
    currentSeriesName.value = "累计志愿者报名人次";

    // myChart.resize();

    treadEchartsChange();

    console.log("当前curr", currentChartsData.value, val);
  }
};

const resetPwd = ref(false);
const is_resetPwd = () => {
  // console.log(JSON.parse(localStorage.getItem('reset_password')));
  if (localStorage.getItem("reset_password")) {
    resetPwd.value = JSON.parse(localStorage.getItem("reset_password"));
    // console.log('重置密码');
  }
};

// 设置新密码
const new_pwd1 = ref("");
const new_pwd2 = ref("");
const is_hint = ref(false);

const is_pwd_verify = ref(false);
const first_pwd_hint = ref("新密码不能为空");
const pwd_verify = () => {
  let judgeFn = new RegExp(
    /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&/*()_+|{}:"?]{8,16}$/
  );
  if (new_pwd1.value === "") {
    is_pwd_verify.value = true;
    is_hint.value = true;
  } else if (!judgeFn.test(new_pwd1.value)) {
    is_pwd_verify.value = true;
    is_hint.value = true;
    first_pwd_hint.value = "密码强度不符合要求";
  } else {
    is_pwd_verify.value = false;
    is_hint.value = false;
  }
};
const is_pwd_verify2 = ref(false);
const sec_pwd_hint = ref("两次密码输入不一致");
const sec_pwd_verify = () => {
  let judgeFn = new RegExp(
    /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&/*()_+|{}:"?]{8,16}$/
  );
  if (new_pwd2.value === "") {
    is_pwd_verify2.value = true;
    sec_pwd_hint.value = "确认密码不能为空";
  } else if (new_pwd2.value === new_pwd1.value) {
    if (!judgeFn.test(new_pwd2.value)) {
      is_pwd_verify2.value = true;
      is_hint.value = true;
      sec_pwd_hint.value = "密码强度不符合要求";
    } else {
      is_pwd_verify2.value = false;
    }
  } else {
    is_pwd_verify2.value = true;
    sec_pwd_hint.value = "两次密码输入不一致";
  }
};

// 确认设置新密码，接口修改密码
const submit = async () => {
  pwd_verify();
  sec_pwd_verify();
  let judgeFn = new RegExp(
    /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&*()_+|{}:"?]{8,16}$/
  );
  if (
    new_pwd1.value !== "" &&
    new_pwd2.value !== "" &&
    new_pwd2.value === new_pwd1.value
  ) {
    if (new_pwd1.value.length != 0) {
      if (!judgeFn.test(new_pwd1.value)) {
        is_pwd_verify.value = true;
        is_hint.value = true;
        first_pwd_hint.value = "密码强度不符合要求";
        is_pwd_verify2.value = true;
        sec_pwd_hint.value = "";
      } else {
        const res = await http.post("/user/resetPassword-user", {
          _id: localStorage.getItem("_id"),
          password: new_pwd1.value,
        });
        console.log(localStorage.getItem("_id"), new_pwd1.value);
        console.log(res);
        if (res.status === 0) {
          router.push("/");
          message({
            type: "success",
            message: "密码修改成功，请重新登录",
            showClose: true,
          });
          localStorage.removeItem("access_token");
          localStorage.removeItem("_id");
          localStorage.removeItem("pwd_free_Login");
          localStorage.removeItem("reset_password");
        }
        console.log("提交新密码");
      }
    }
  }
};

onBeforeMount(() => {
  is_resetPwd();
});
</script>

<style lang="less">
@import url("@/style/views/VolunteerManagement/VolunteerDataBoard.less");

#reset-password {
  .el-dialog {
    width: 650px;
    .el-dialog__header {
      text-align: left;
      padding: 12px 24px;
      margin: 0;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #313233;
      border-bottom: 1px #bfbfbf solid;
    }
    .el-dialog__body {
      padding: 8px 24px 8px 20px;
      .content {
        .title {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #626366;
          text-align: left;
          margin-bottom: 20px;
        }
        .input-newPwd {
          .input-item {
            display: flex;
            height: 36px;
            align-items: center;
            & > span {
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #626366;
              width: 75px;
              flex-grow: 1;
              text-align: right;
              margin-right: 6px;
            }
            .input_pwd {
              .el-input {
                width: 528px;
                height: 36px;
                background: #ffffff;
                .el-input__wrapper {
                  box-shadow: none;
                  border: 1px solid #bfbfbf;
                  border-radius: 2px;
                  height: 36px;
                }
                .el-icon {
                  width: 20px;
                  height: 20px;
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
                .el-input__wrapper.is-focus {
                  border: 1px #027aff solid;
                  .el-input__prefix {
                    .el-icon {
                      color: #027aff;
                    }
                  }
                }
              }
              // 报错提示
              .error_input {
                .el-input__wrapper {
                  border: 1px solid #ff3200;
                  &.is-focus {
                    border: 1px solid #ff3200;
                    .el-input__prefix {
                      .el-icon {
                        color: #ff3200;
                      }
                    }
                  }
                  .el-input__prefix {
                    .el-icon {
                      color: #ff3200;
                    }
                  }
                }
              }
            }
          }
          .pwd-length-hint {
            min-height: 20px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #bfbfbf;
            line-height: 22px;
            text-align: left;
            display: flex;
            margin-left: 78px;
            margin-bottom: 12px;
          }
        }
        // 错误提示语
        .error-hint {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ff3200;
          line-height: 20px;
        }
      }
    }
    .el-dialog__footer {
      .el-button {
        width: 80px;
        height: 36px;
        background: #027aff;
        border-radius: 2px;
        span {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }
  }
}
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
