// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router';
import http from '@/utils/http';
import LoginViewVue from '../views/LoginView.vue';
import QuestionnaireList from '@/views/QuestionnaireManagement/QuestionnaireList.vue';
import store from '../store';
// const res = await http.post('');
const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginViewVue,
    children: [
      // 验证码已发送
      {
        path: 'verify',
        name: 'SendVerify',
        component: () => import('../views/LoginModules/SendVerify.vue'),
      },
      // 设置新密码
      {
        path: 'set_newpassword',
        name: 'SetNewpwd',
        component: () => import('../views/LoginModules/SetNewpwd.vue'),
      },
    ],
  },
  {
    path: '/HelpCenter',
    name: 'HelpCenter',

    component: () => import('@/views/HelpCenter/HelpCenter.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/tableView',
    name: 'tableview',
    component: () => import('../components/TableView.vue'),
  },
  {
    path: '/vxetable',
    name: 'vxetable',

    component: () => import('../components/VxeTable.vue'),
  },
  // 仪器类别
  {
    path: '/InstrumentType',
    name: 'InstrumentType',

    component: () => import('../views/InstrumentMangement/InstrumentType.vue'),
  },
  // // 项目列表
  // {
  //     path: '/ProjectList',
  //     name: 'ProjectList',

  //     component: () => import('../views/ProjectManagement/ProjectList.vue'),
  // },
  // // 项目列表
  // {
  //     path: '/ProjectSchedule',
  //     name: 'ProjectSchedule',

  //     component: () => import('../views/ProjectManagement/ProjectSchedule.vue'),
  // },
  // // 报告审批
  // {
  //     path: '/ReportApproval',
  //     name: 'ReportApproval',

  //     component: () => import('../views/ProjectManagement/ReportApproval.vue'),
  // },
  // // 报告详情页面
  // {
  //     path: '/ReportApproval/ReportDetails/:_id',
  //     name: 'ReportDetails',
  //     meta: {
  //         activeMenu: '/ReportApproval',
  //     },
  //     component: () => import('../components/Reportapprovalchild/ReportDetails.vue'),
  // },
  // //评论组件--临时路由
  // {
  //     path: '/CommentCpn',
  //     name: 'CommentCpn',

  //     component: () => import('../components/ProjectManagement/CommentCpn.vue'),
  // },
  // // !报告管理 空Report_management
  // {
  //     path: '/Report_management',
  //     name: 'Report_management',
  //     component: () => import('../views/ProjectManagement/Report_management.vue'),
  // },
  // // !自定义设置  志愿者空 volunteer_Custom_Settings
  // {
  //     path: '/volunteer_Custom_Settings',
  //     name: 'volunteer_Custom_Settings',
  //     component: () => import('../views/VolunteerManagement/volunteer_Custom_Settings.vue'),
  // },
  // // ! 自定义设置  样品空  Sample_Custom_Settings
  // {
  //     path: '/Sample_Custom_Settings',
  //     name: 'Sample_Custom_Settings',
  //     component: () => import('../views/SampleManagement/Sample_Custom_Settings.vue'),
  // },
  // // ! 自定义设置  仪器空  Lnstrument_Custom_Settings
  // {
  //     path: '/Lnstrument_Custom_Settings',
  //     name: 'Lnstrument_Custom_Settings',
  //     component: () => import('../views/InstrumentMangement/Lnstrument_Custom_Settings.vue'),
  // },
  // // !项目分享   空
  // {
  //     path: '/Project_sharing',
  //     name: 'Project_sharing',
  //     component: () => import('../views/ProjectManagement/Project_sharing.vue'),
  // },
  // {
  //     path: '/ShareMe',
  //     name: 'ShareMe',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('../components/ProjectManagement/ShareMe.vue'),
  // },
  // {
  //     path: '/MyShare',
  //     name: 'MyShare',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('../components/ProjectManagement/MyShare.vue'),
  // },
  // {
  //     path: '/Project_sharing/ShareDetails/:type/:_id',
  //     name: 'ShareDetails',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('../components/ProjectManagement/ShareDetails.vue'),
  // },
  // {
  //     path: '/Project_sharingSharePlan/:type/:_id',
  //     name: 'SharePlan',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('@/components/ProjectManagement/SharePlan.vue'),
  // },
  // {
  //     path: '/Project_sharing/ShareMember/:type/:_id',
  //     name: 'ShareMember',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('@/components/ProjectManagement/ShareMember.vue'),
  // },
  // // 项目分享问卷
  // {
  //     path: '/Project_sharing/ProjectShareQuestion/:type/:_id',
  //     name: 'ProjectShareQuestion',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('@/components/ProjectManagement/ShareQuestion.vue'),
  // },
  // // 指标和仪器
  // {
  //     path: '/Project_sharing/ShareIndicator_Instrument/:type/:_id',
  //     name: 'ShareInstrumentPage',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('@/components/ProjectManagement/ShareInstrumentPage.vue'),
  // },

  // // 志愿者数据
  // {
  //     path: '/Project_sharing/ShareVolunteer/:type/:_id',
  //     name: 'ShareVolunteerData',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('@/components/ProjectManagement/ShareVolunteerData.vue'),
  // },
  // // 样品
  // {
  //     path: '/Project_sharing/ShareSample/:type/:_id',
  //     name: 'ShareSample',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('@/components/ProjectManagement/ShareSample.vue'),
  // },
  // // 项目文档
  // {
  //     path: '/Project_sharing/ShareDocument/:type/:_id',
  //     name: 'ShareDocument',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('@/components/ProjectManagement/ShareDocument.vue'),
  // },
  // // 项目报告
  // {
  //     path: '/Project_sharing/ShareReport/:type/:_id',
  //     name: 'ShareReport',
  //     meta: {
  //         activeMenu: '/Project_sharing',
  //     },
  //     component: () => import('@/components/ProjectManagement/ShareReport.vue'),
  // },

  // {
  //     path: '/ProjectList/ProjectDetails/:_id',
  //     name: 'ProjectDetails',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('../components/ProjectManagement/ProjectDetails.vue'),
  // },
  // {
  //     path: '/ProjectList/project_plan/:_id',
  //     name: 'ProjectPlan',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('@/components/ProjectManagement/ProjectPlan.vue'),
  // },
  // {
  //     path: '/ProjectList/member/:_id',
  //     name: 'ProjectMember',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('@/components/ProjectManagement/ProjectMember.vue'),
  // },
  // // 项目问卷
  // {
  //     path: '/ProjectList/question/:_id',
  //     name: 'ProjectQuestion',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('@/components/ProjectManagement/ProjectQuestion.vue'),
  // },
  // // 指标和仪器
  // {
  //     path: '/ProjectList/Indicator_Instrument/:_id',
  //     name: 'InstrumentPage',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('@/components/ProjectManagement/InstrumentPage.vue'),
  // },

  // // 志愿者数据
  // {
  //     path: '/ProjectList/volunteer/:_id',
  //     name: 'VolunteerData',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('@/components/ProjectManagement/VolunteerData.vue'),
  // },
  // // 样品
  // {
  //     path: '/ProjectList/sample/:_id',
  //     name: 'ProjectSample',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('@/components/ProjectManagement/ProjectSample.vue'),
  // },
  // // 项目文档
  // {
  //     path: '/ProjectList/Document/:_id',
  //     name: 'ProjectDocument',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('@/components/ProjectManagement/ProjectDocument.vue'),
  // },
  // // 项目报告
  // {
  //     path: '/ProjectList/Report/:_id',
  //     name: 'ProjectReport',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('@/components/ProjectManagement/ProjectReport.vue'),
  // },
  // {
  //     path: '/ProjectList/CreateProject',
  //     name: 'CreateProject',
  //     meta: {
  //         activeMenu: '/ProjectList',
  //     },
  //     component: () => import('../components/ProjectManagement/CreateProject.vue'),
  // },

  // {
  //     path: '/VolunteerBank',
  //     name: 'VolunteerBank',

  //     component: () => import('../views/VolunteerManagement/VolunteerBank.vue'),
  // },
  // {
  //     path: '/ClockinView',
  //     name: 'ClockinView',

  //     component: () => import('../views/VolunteerManagement/ClockinView.vue'),
  // },
  // {
  //     path: '/RecruitVolunteer',
  //     name: 'RecruitVolunteer',
  //     component: () => import('../views/VolunteerManagement/RecruitVolunteer.vue'),
  // },
  // {
  //     path: '/InstrumentList',
  //     name: 'InstrumentList',
  //     component: () => import('../views/InstrumentMangement/InstrumentList.vue'),
  // },
  // {
  //     path: '/VolunteerSchedule',
  //     name: 'VolunteerSchedule',
  //     component: () => import('../views/VolunteerManagement/VolunteerSchedule.vue'),
  // },
  // {
  //     path: '/InstrumentTestRecord',
  //     name: 'InstrumentTestRecord',
  //     component: () => import('../views/InstrumentMangement/InstrumentTestRecord.vue'),
  // },
  // 志愿者 测试记录
  // {
  //     path: '/TestRecords',
  //     name: 'TestRecords',
  //     component: () => import('../views/VolunteerManagement/TestRecords.vue'),
  // },
  // //! 问卷管理
  // //  问卷列表
  // {
  //     path: '/QuestionnaireList',
  //     name: 'QuestionnaireList',
  //     component: QuestionnaireList,
  // },
  // // 统计分析
  // {
  //     path: '/QuestionnaireList/QuestionStatisticAnalysis/:_id',
  //     name: 'QuestionStatisticAnalysis',
  //     meta: {
  //         activeMenu: '/QuestionnaireList',
  //     },
  //     component: () => import('@/components/QuestionnaireManagement/QuestionStatisticAnalysis.vue'),
  // },

  // // 仪器管理-统计分析
  // {
  //     path: '/InstrumentStatisticalAnalysis',
  //     name: 'InstrumentStatisticalAnalysis',
  //     meta: {
  //         activeMenu: '/InstrumentStatisticalAnalysis',
  //     },
  //     component: () => import('@/components/InstrumentMangement/InstrumentStatisticalAnalysis.vue'),
  // },
  // // 默认报告
  // {
  //     path: '/QuestionnaireList/QuestionReport/:_id',
  //     name: 'QuestionReport',
  //     meta: {
  //         activeMenu: '/QuestionnaireList',
  //     },
  //     component: () => import('@/components/QuestionnaireManagement/QuestionReport.vue'),
  // },
  // //  分享问卷
  // {
  //     path: '/ShareQuestion/:_id',
  //     name: 'ShareQuestion',
  //     component: () => import('../views/QuestionnaireManagement/ShareQuestion.vue'),
  // },
  // // ! 样品管理
  // {
  //     path: '/SampleList',
  //     name: 'SampleList',
  //     component: () => import('../views/SampleManagement/SampleList.vue'),
  // },
  // {
  //     path: '/SampleFlowRecord',
  //     name: 'SampleFlowRecord',
  //     component: () => import('../views/SampleManagement/SampleFlowRecord.vue'),
  // },
  // {
  //     path: '/CustomSettings',
  //     name: 'CustomSettings',
  //     component: () => import('../views/SampleManagement/CustomSettings.vue'),
  // },
  // // 小程序管理
  // {
  //     path: '/AppAdmin',
  //     name: 'AppAdmin',
  //     component: () => import('../views/VolunteerManagement/AppAdmin.vue'),
  // },
  // // 新建问卷

  // {
  //     path: '/CreatedQuestionnaire/:_id',
  //     name: 'CreatedQuestionnaire',
  //     component: () => import('../components/QuestionnaireManagement/CreatedQuestionnaire.vue'),
  // },
  // // 编辑问卷

  // {
  //     path: '/EditQuestion/:_id',
  //     name: 'EditQuestion',
  //     component: () => import('../components/QuestionnaireManagement/EditQuestion.vue'),
  // },
  // // 文本导入问卷

  // {
  //     path: '/TextImport/:_id',
  //     name: 'TextImport',
  //     component: () => import('../components/QuestionnaireManagement/TextImport.vue'),
  // },
  // // h5问卷预览  临时   开发完后可删
  // {
  //     path: '/previewQuestion',
  //     name: 'previewQuestion',
  //     component: () => import('../components/QuestionnaireManagement/previewQuestion.vue'),
  // },
  // // web问卷预览  临时   开发完后可删
  // {
  //     path: '/webPreviewQuestion',
  //     name: 'webPreviewQuestion',
  //     component: () => import('../components/QuestionnaireManagement/webPreviewQuestion.vue'),
  // },
  // // 专家管理--专家列表
  // {
  //     path: '/expertList',
  //     name: 'ExpertList',
  //     component: () => import('../views/ExpertManagement/ExpertList.vue'),
  // },
  // // 专家管理--自定义设置
  // {
  //     path: '/customSettings',
  //     name: 'CustomSettings',
  //     component: () => import('../views/ExpertManagement/CustomSettings.vue'),
  // },
  // // 专家管理--专家评估记录
  // {
  //     path: '/expertEvaluationRecords',
  //     name: 'ExpertEvaluationRecords',
  //     component: () => import('../views/ExpertManagement/ExpertEvaluationRecords.vue'),
  // },

  // //系统管理--个人中心
  // {
  //     path: '/personalCenter',
  //     name: 'personalCenter',
  //     component: () => import('../views/systemManagement/personalCenter.vue'),
  // },
  // //系统管理--用户&权限管理
  // {
  //     path: '/UserPower',
  //     name: 'UserPower',
  //     component: () => import('../views/systemManagement/UserPower.vue'),
  // },
  // //系统管理--用户&权限管理
  // {
  //     path: '/SignatureManagement',
  //     name: 'SignatureManagement',
  //     component: () => import('../views/systemManagement/SignatureManagement.vue'),
  // },
  // //系统管理--用户&权限管理--用户信息
  // {
  //     path: '/UserPower/UserInfo',
  //     name: 'UserInfo',
  //     component: () => import('../components/systemManagement/UserInfo.vue'),
  //     meta: {
  //         activeMenu: '/UserPower',
  //     },
  // },
  // //系统管理--用户&权限管理--权限管理
  // {
  //     path: '/UserPower/PowerMange',
  //     name: 'PowerMange',
  //     component: () => import('../components/systemManagement/PowerMange.vue'),
  //     meta: {
  //         activeMenu: '/UserPower',
  //     },
  // },
  // {
  //     path: '/Home',
  //     name: 'home',
  //     component: () => import('@/views/Home/HomeView.vue'),
  // },
  // {
  //     path: '/OperationLog',
  //     name: 'OperationLog',
  //     component: () => import('../views/OperationLog/OperationLog.vue'),
  // },

  // //项目管理——数据看板

  // {
  //     path: '/ProjectManagement_DataBoard',
  //     name: 'ProjectManagement_DataBoard',
  //     component: () => import('../views/ProjectManagement/ProjectManagement_DataBoard.vue'),
  // },

  // //志愿者 管理——数据看板

  // {
  //     path: '/VolunteerDataBoard',
  //     name: 'VolunteerDataBoard',
  //     component: () => import('../views/VolunteerManagement/VolunteerDataBoard.vue'),
  // },
  // //仪器管理——数据看板
  // {
  //     path: '/InstrumentDataBoard',
  //     name: 'InstrumentDataBoard',
  //     component: () => import('../views/InstrumentMangement/InstrumentDataBoard.vue'),
  // },
  // //h5预览
  // {
  //     path: '/H5QuestionPreview/:_id',
  //     name: 'H5QuestionPreview',
  //     component: () => import('../views/QuestionnaireManagement/H5QuestionPreview.vue'),
  // },
  // //web 预览

  // {
  //     path: '/WebQuestionPreview',
  //     name: 'WebQuestionPreview',
  //     component: () => import('../views/QuestionnaireManagement/WebQuestionPreview.vue'),
  // },

  // //问卷-默认报告-临时路由-开发完后可删
  // {
  //     path: '/QuestionDefaultReport',
  //     name: 'QuestionDefaultReport',
  //     component: () => import('../components/QuestionnaireManagement/QuestionDefaultReport.vue'),
  // },

  // //仪器管理-数据分析-描述分析组件  -临时路由，开发完毕后可删
  // {
  //     path: '/InstrumentDescriptiveAnalysis',
  //     name: 'InstrumentDescriptiveAnalysis',
  //     component: () => import('../components/InstrumentMangement/InstrumentDescriptiveAnalysis.vue'),
  // },
];

const router = createRouter({
  history: createWebHashHistory(), // hash 模式
  // history: createWebHistory(process.env.BASE_URL),  // history 模式

  routes,
});
router.beforeEach((to, from) => {
  // console.log(to.path);
  store.commit('set_CurrentPath', to.path);
  // console.log('store :>> ', store);
  let token = localStorage.getItem('access_token');
  if (to.name !== 'login' && to.name !== 'SendVerify' && to.path !== '/set_newpassword') {
    if (!token) {
      store.commit('set_CurrentPath', '/');
      return { path: '/' };
    }
  } else {
    // next();
  }
});

export default router;
