<!--
 * @Author: ZhengJianYang
 * @Date: 2023-06-13 17:52:59
 * @LastEditors: ZhengJianYang
 * @LastEditTime: 2023-07-06 10:19:15
 * @Description: 问卷预览--组件
-->
<template>
  <div v-if="!isWebQuestionShow" class="previewQuestion">
    <div class="previewQuestion_top">
      <img
        class="imgleft"
        src="https://newstore.vynior.com/cems_web/%E9%97%AE%E5%8D%B7%E9%A2%84%E8%A7%88%E2%80%94%E2%80%94%E2%80%94%E2%80%94/%E7%BB%84%20110428%402x.png"
        alt=""
      />

      <img
        @click="goToWebPreviewQuestion"
        class="imgright"
        src="https://newstore.vynior.com/cems_web/%E9%97%AE%E5%8D%B7%E9%A2%84%E8%A7%88%E2%80%94%E2%80%94%E2%80%94%E2%80%94/%E7%BB%84%20111242%402x.png"
        alt=""
      />
    </div>

    <div class="previewQuestion_center">
      <div class="previewQuestion_center_phone">
        <div class="centerBar"></div>

        <div class="previewQuestion_center_phone_main">
          <div class="headerTitle">{{ mockArr.naire_title }}</div>

          <div class="questionSaying" v-if="mockArr.naire_explain != ''">
            {{ mockArr.naire_explain }}
          </div>

          <div v-for="(question, index) in mockArr.Questions" :key="index">
            <div class="singleChoice" v-if="question.type == '单选'">
              <div class="singleChoice_top">
                <dis class="num" v-if="mockArr.question_showNum">
                  <span v-if="question.required" style="color: red">*</span>
                  {{ index + 1 }}</dis
                >
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >
                  {{ question.title }}
                </div>
              </div>

              <div class="content">
                <div
                  class="item"
                  v-for="(item, aindex) of question.label"
                  :key="aindex"
                >
                  <input
                    class="radio"
                    type="radio"
                    name="item"
                    :value="question.selectValue"
                  />
                  <div class="radioContent">
                    <div>{{ item }}</div>
                    <img
                      v-if="question.imgSrc[aindex]"
                      class="select_img"
                      :src="question.imgSrc[aindex]"
                      alt="图片已缺失"
                    />
                    <div v-if="question.imgText[aindex]" class="text_intro">
                      {{ question.imgText[aindex] }}
                    </div>
                  </div>
                </div>

                <div class="item2" v-if="question.otherLabel">
                  <div class="item2_inner">
                    <input
                      @change="handleChange"
                      class="radio1"
                      type="radio"
                      name="item"
                      :value="question.selectValue"
                    />
                    <div class="radioContent">其他</div>
                  </div>

                  <el-input
                    v-if="isCheck"
                    class="inputText"
                    v-model="inputmock"
                    placeholder="请输入"
                  />
                </div>
              </div>
            </div>

            <div class="multipleChoice" v-if="question.type == '多选'">
              <div class="singleChoice_top">
                <div class="num" v-if="mockArr.question_showNum">
                  <span v-if="question.required" style="color: red">*</span
                  >{{ index + 1 }}
                </div>
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >{{ question.title }}
                </div>
              </div>

              <div class="content">
                <div
                  class="item"
                  v-for="(item, bindex) of question.label"
                  :key="bindex"
                >
                  <span class="radio">
                    <input
                      type="checkbox"
                      id="radio1"
                      name="item"
                      :value="isCheck2"
                    />
                    <!-- <label for="radio1"></label> -->
                    <div class="radioContent">
                      <div>{{ item }}</div>
                      <img
                        v-if="question.imgSrc[bindex]"
                        class="select_img"
                        :src="question.imgSrc[bindex]"
                        alt="图片已缺失"
                      />
                      <div v-if="question.imgText[bindex]" class="text_intro">
                        {{ question.imgText[bindex] }}
                      </div>
                    </div>
                  </span>
                </div>
                <div class="item2" v-if="question.otherLabel">
                  <div class="item2_inner">
                    <input
                      @change="handleChange2"
                      v-model="isCheck2"
                      class="radio1"
                      type="checkbox"
                      name="item"
                      :value="isCheck2"
                    />
                    <div class="radioContent">其他</div>
                  </div>

                  <el-input
                    v-if="isCheck2"
                    class="inputText"
                    v-model="inputmock2"
                    placeholder="请输入"
                  />
                </div>
              </div>
            </div>

            <div class="singleChoice" v-if="question.type == '评分单选'">
              <div class="singleChoice_top">
                <dis class="num" v-if="mockArr.question_showNum">
                  <span v-if="question.required" style="color: red">*</span>
                  {{ index + 1 }}</dis
                >
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >
                  {{ question.title }}
                </div>
              </div>

              <div class="content">
                <div
                  class="item"
                  v-for="(item, aindex) of question.label"
                  :key="aindex"
                >
                  <input
                    class="radio"
                    type="radio"
                    name="item"
                    :value="question.selectValue"
                  />
                  <div class="radioContent">
                    <div>{{ item }}</div>
                    <img
                      v-if="question.imgSrc[aindex]"
                      class="select_img"
                      :src="question.imgSrc[aindex]"
                      alt="图片已缺失"
                    />
                    <div v-if="question.imgText[aindex]" class="text_intro">
                      {{ question.imgText[aindex] }}
                    </div>
                  </div>
                </div>

                <div class="item2" v-if="question.otherLabel">
                  <div class="item2_inner">
                    <input
                      @change="handleChange"
                      class="radio1"
                      type="radio"
                      name="item"
                      :value="question.selectValue"
                    />
                    <div class="radioContent">其他</div>
                  </div>

                  <el-input
                    v-if="isCheck"
                    class="inputText"
                    v-model="inputmock"
                    placeholder="请输入"
                  />
                </div>
              </div>
            </div>

            <div class="multipleChoice" v-if="question.type == '评分多选'">
              <div class="singleChoice_top">
                <div class="num" v-if="mockArr.question_showNum">
                  <span v-if="question.required" style="color: red">*</span
                  >{{ index + 1 }}
                </div>
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >{{ question.title }}
                </div>
              </div>

              <div class="content">
                <div
                  class="item"
                  v-for="(item, bindex) of question.label"
                  :key="bindex"
                >
                  <span class="radio">
                    <input
                      type="checkbox"
                      id="radio1"
                      name="item"
                      :value="isCheck2"
                    />
                    <!-- <label for="radio1"></label> -->
                    <div class="radioContent">
                      <div>{{ item }}</div>
                      <img
                        v-if="question.imgSrc[bindex]"
                        class="select_img"
                        :src="question.imgSrc[bindex]"
                        alt="图片已缺失"
                      />
                      <div v-if="question.imgText[bindex]" class="text_intro">
                        {{ question.imgText[bindex] }}
                      </div>
                    </div>
                  </span>
                </div>
                <div class="item2" v-if="question.otherLabel">
                  <div class="item2_inner">
                    <input
                      @change="handleChange2"
                      v-model="isCheck2"
                      class="radio1"
                      type="checkbox"
                      name="item"
                      :value="isCheck2"
                    />
                    <div class="radioContent">其他</div>
                  </div>

                  <el-input
                    v-if="isCheck2"
                    class="inputText"
                    v-model="inputmock2"
                    placeholder="请输入"
                  />
                </div>
              </div>
            </div>

            <div class="fill_blank" v-if="question.type == '单项填空'">
              <div class="singleChoice_top">
                <dis class="num" v-if="mockArr.question_showNum"
                  ><span v-if="question.required" style="color: red">*</span
                  >{{ index + 1 }}</dis
                >
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >{{ question.title }}
                </div>
              </div>

              <div class="content">
                <div class="item">
                  <el-input v-model="inputvvv" placeholder="请输入" />
                </div>
              </div>
            </div>

            <div
              class="matrixRadioSelection"
              v-if="question.type == '矩阵单选'"
            >
              <div class="singleChoice_top">
                <dis class="num" v-if="mockArr.question_showNum"
                  ><span v-if="question.required" style="color: red">*</span
                  >{{ index + 1 }}</dis
                >
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >{{ question.title }}
                </div>
              </div>

              <div class="content">
                <div class="scoreBar">
                  <div
                    class="scoreBar_item"
                    v-for="(item, dindex) of question.valueTitle"
                    :key="dindex"
                  >
                    {{ item }}
                  </div>
                  <!-- <div class="scoreBar_item">不满意</div>
                  <div class="scoreBar_item">一般</div>
                  <div class="scoreBar_item">满意</div>
                  <div class="scoreBar_item">很满意</div> -->
                </div>

                <div
                  class="whichSize"
                  v-for="(item, findex) of question.labelTitle"
                  :key="findex"
                >
                  <div class="size">{{ item }}</div>
                  <div class="scoreBar">
                    <div
                      class="scoreBar_item"
                      v-for="(item2, eindex) in question.valueTitle"
                      :key="eindex"
                    >
                      <input
                        class="radio"
                        type="radio"
                        :name="item"
                        :value="item"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="matrixMultipleSelection"
              v-if="question.type == '矩阵多选'"
            >
              <div class="singleChoice_top">
                <dis class="num" v-if="mockArr.question_showNum"
                  ><span v-if="question.required" style="color: red">*</span
                  >{{ index + 1 }}</dis
                >
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >{{ question.title }}
                </div>
              </div>

              <div class="content">
                <div class="scoreBar">
                  <div
                    class="scoreBar_item"
                    v-for="(item, gindex) of question.valueTitle"
                    :key="gindex"
                  >
                    {{ item }}
                  </div>
                  <!-- <div class="scoreBar_item">不满意</div>
                  <div class="scoreBar_item">一般</div>
                  <div class="scoreBar_item">满意</div>
                  <div class="scoreBar_item">很满意</div> -->
                </div>

                <div
                  class="whichSize"
                  v-for="(item, hindex) of question.labelTitle"
                  :key="hindex"
                >
                  <div class="size">{{ item }}</div>
                  <div class="scoreBar">
                    <div
                      class="scoreBar_item"
                      v-for="(item, iindex) of question.valueTitle"
                      :key="iindex"
                    >
                      <input
                        class="radio"
                        type="checkbox"
                        :name="item"
                        :value="item"
                      />
                    </div>
                    <!-- <div class="scoreBar_item">
                      <input
                        class="radio"
                        type="checkbox"
                        name="val1"
                        :value="val1"
                      />
                    </div>
                    <div class="scoreBar_item">
                      <input
                        class="radio"
                        type="checkbox"
                        name="val1"
                        :value="val1"
                      />
                    </div>
                    <div class="scoreBar_item">
                      <input
                        class="radio"
                        type="checkbox"
                        name="val1"
                        :value="val1"
                      />
                    </div>
                    <div class="scoreBar_item">
                      <input
                        class="radio"
                        type="checkbox"
                        name="val1"
                        :value="val1"
                      />
                    </div> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="dropdownSelection" v-if="question.type == '下拉'">
              <div class="singleChoice_top">
                <dis class="num" v-if="mockArr.question_showNum"
                  ><span v-if="question.required" style="color: red">*</span
                  >{{ index + 1 }}</dis
                >
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >{{ question.title }}
                </div>
              </div>

              <div class="content">
                <el-select
                  v-model="dropdownvalue"
                  class="m-2"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, jindex) of question.label"
                    :key="jindex"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </div>
            </div>

            <div class="matrixRating" v-if="question.type == '矩阵量表'">
              <div class="singleChoice_top">
                <dis class="num" v-if="mockArr.question_showNum"
                  ><span v-if="question.required" style="color: red">*</span
                  >{{ index + 1 }}</dis
                >
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >{{ question.title }}
                </div>
              </div>

              <div class="content">
                <div class="scoreBar">
                  <div
                    class="scoreBar_item"
                    v-for="(item, kindex) of question.valueTitle3"
                    :key="kindex"
                  >
                    {{ item }}
                  </div>
                  <!-- <div class="scoreBar_item">不满意</div> -->
                  <!-- <div class="scoreBar_item">一般</div> -->
                  <!-- <div class="scoreBar_item">满意</div> -->
                  <!-- <div class="scoreBar_item">非常满意</div> -->
                </div>

                <div class="scoreRange">
                  <div
                    class="scoreRange_item"
                    :key="kkIndex"
                    v-for="(kk, kkIndex) of question.valueTitle"
                  >
                    {{ kkIndex + question.startNum }}
                  </div>
                </div>

                <div
                  class="whichSize"
                  v-for="(item, lindex) of question.labelTitle"
                  :key="lindex"
                >
                  <div class="size">{{ item }}</div>
                  <div class="scoreBar">
                    <!-- question.echartsDimension -->
                    <div
                      class="scoreBar_item"
                      v-for="(item2, oindex) in question.valueTitle"
                      :key="oindex"
                    >
                      <input
                        class="radio"
                        type="radio"
                        :name="item"
                        :value="item"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="Many_fill_blank" v-if="question.type == '多项填空'">
              <div class="singleChoice_top">
                <dis class="num" v-if="mockArr.question_showNum"
                  ><span v-if="question.required" style="color: red">*</span
                  >{{ index + 1 }}</dis
                >
                <div class="title">
                  <span
                    style="color: red"
                    v-if="
                      question.required == true &&
                      mockArr.question_showNum == false
                    "
                    >*</span
                  >{{ question.title }}
                </div>
              </div>

              <div class="content">
                <div
                  class="item"
                  v-for="(item, pindex) of question.label"
                  :key="pindex"
                >
                  <div class="titleName">{{ item }}</div>
                  <el-input
                    v-if="
                      question.inputValue != null ||
                      question.inputValue != undefined
                    "
                    v-model="question.inputValue[pindex]"
                    placeholder="请输入"
                  />
                </div>
                <!-- <div class="item">
                  <div class="titleName">姓名</div>
                  <el-input v-model="inputvvv" placeholder="请输入" />
                </div> -->
              </div>
            </div>
          </div>

          <div class="submitBtn">
            <img
              class="submitImg"
              src="https://newstore.vynior.com/cems_web/%E9%97%AE%E5%8D%B7%E9%A2%84%E8%A7%88%E2%80%94%E2%80%94%E2%80%94%E2%80%94/submtb.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <webPreviewQuestion
    :questionId="questionId"
    @closeWebPreview="handlecloseWebPreview"
    v-if="isWebQuestionShow"
  ></webPreviewQuestion>
</template>

<script setup>
import webPreviewQuestion from "./webPreviewQuestion.vue";
import http from "@/utils/http";
import {
  ref,
  onMounted,
  nextTick,
  computed,
  watch,
  reactive,
  shallowRef,
  onBeforeUnmount,
  defineProps,
} from "vue";

// 子组件传来的数据
const handlecloseWebPreview = (data) => {
  console.log("子组件传来的值", data);
  isWebQuestionShow.value = data;
};

let questionId = ref("");

const props = defineProps({
  questionId: {
    type: String || Number,
    required: true,
  },
  questionData: {
    type: Array || Number,
    required: true,
  },
});

onMounted(() => {
  console.log("父组件传来的questionId:", props.questionId);
  questionId.value = props.questionId;
  console.log("传给子组件的:", questionId.value);
  console.log(props.questionData, "questionData");
  if (props.questionId == false) {
    console.log(1223456);
    mockArr.value = props.questionData;

    mockArr.value.Questions.forEach((item) => {
      if (item.type == "矩阵量表") {
        let valueTitle3 = [];
        if (item.maxOption == 0) {
          valueTitle3 = ["非常不满意", "非常满意"];
        } else if (item.maxOption == 1) {
          valueTitle3 = ["非常不赞同", "非常赞同"];
        } else if (item.maxOption == 2) {
          valueTitle3 = ["非常不重要", "非常重要"];
        } else if (item.maxOption == 3) {
          valueTitle3 = ["非常不愿意", "非常愿意"];
        } else if (item.maxOption == 4) {
          valueTitle3 = ["非常不符合", "非常符合"];
        } else if (item.maxOption == 5) {
          valueTitle3 = ["毫无感觉", "十分强烈"];
        }

        item.valueTitle3 = valueTitle3;
      } else if (item.type == "多项填空") {
        item.inputValue = [];
      }
    });

    console.log("mockArr修改后", mockArr);
  } else {
    getAllQuestionsInfo();
  }
});

// 获取问卷信息
const getAllQuestionsInfo = async () => {
  await http
    .post("/questionnaire/findInfo-questionnaire", {
      // questionnaireId: "6492538990148f6dfd6cae64",
      questionnaireId: props.questionId,
    })

    .then((res) => {
      console.log("问卷res", res);
      console.log("问卷所有问题", res.naire.Questions);

      mockArr.value = res.naire;

      mockArr.value.Questions.forEach((item) => {
        if (item.type == "矩阵量表") {
          let valueTitle3 = [];
          if (item.echartsDimension == 0) {
            valueTitle3 = ["非常不满意", "非常满意"];
          } else if (item.echartsDimension == 1) {
            valueTitle3 = ["非常不赞同", "非常赞同"];
          } else if (item.echartsDimension == 2) {
            valueTitle3 = ["非常不重要", "非常重要"];
          } else if (item.echartsDimension == 3) {
            valueTitle3 = ["非常不愿意", "非常愿意"];
          } else if (item.echartsDimension == 4) {
            valueTitle3 = ["非常不符合", "非常符合"];
          } else if (item.echartsDimension == 5) {
            valueTitle3 = ["毫无感觉", "十分强烈"];
          }

          item.valueTitle3 = valueTitle3;
        } else if (item.type == "多项填空") {
          item.inputValue = [];
        }
      });

      console.log("mockArr修改后", mockArr);
    })
    .catch((err) => {
      console.log("问卷err", err);
    });
};

const mockArr = ref({
  _id: "648abde98403e92196a6cb7d",
  naire_title: "2023年第一季度产品测评调查 问卷",
  naire_explain: "感谢您能抽出几分钟时间来参加本次答题，现在我们就马上开始吧！",
  question_showNum: true,
  show_thank: true,
  thank_info: "真谢谢你",
  Questions: [
    {
      title: "单选标题",
      type: "单选",
      label: ["选项1", "选项2", "选项3", "选项4"],
      imgSrc: ["123"],
      labelTitle: [],
      valueTitle: [],
      imgText: ["这是个图片"],
      otherLabel: true,
      required: false,
      _id: "648abde98403e92196a6cb7e",
      selectValue: "",
    },
    {
      title: "多选标题",
      type: "多选",
      label: ["选项1", "选项2", "选项3", "选项4"],
      imgSrc: [
        "https://newstore.vynior.com/%2Bwis%2B%E6%AF%9B%E5%AD%94%E7%BB%86%E8%87%B4%E9%9D%A2%E8%86%9C.jpg",
      ],
      labelTitle: [],
      valueTitle: [],
      imgText: ["这是个图片"],
      otherLabel: true,
      required: true,
      maxOption: 2,
      minOption: 1,
      _id: "648abde98403e92196a6cb7f",
    },
    {
      title: "下拉标题",
      type: "下拉",
      label: ["选项1", "选项2", "选项3", "选项4"],
      imgSrc: [],
      labelTitle: [],
      valueTitle: [],
      imgText: [],
      required: false,
      _id: "648abde98403e92196a6cb80",
      selectValue: "",
      isShow: false,
    },
    {
      title: "单项填空的标题",
      type: "单项填空",
      label: [],
      imgSrc: [],
      labelTitle: [],
      valueTitle: [],
      imgText: [],
      required: false,
      minNum: 1,
      maxNum: 6,
      formatLimits: "不限",
      _id: "648abde98403e92196a6cb81",
      inputValue: "",
    },
    {
      title: "多项填空的标题",
      type: "多项填空",
      label: ["label标题1", "label标题2"],
      imgSrc: [],
      labelTitle: [],
      valueTitle: [],
      imgText: [],
      required: false,
      minNum: 1,
      maxNum: 6,
      formatLimits: "不限",
      _id: "648abde98403e92196a6cb82",
      inputValue: [],
    },
    {
      title: "矩阵单选的标题",
      type: "矩阵单选",
      label: [],
      imgSrc: [],
      labelTitle: ["选项标题1", "选项标题2"],
      valueTitle: ["很不满意", "不满意"],
      imgText: [],
      required: false,
      _id: "648abde98403e92196a6cb83",
      selectValue: "",
    },
    {
      title: "矩阵多选的标题",
      type: "矩阵多选",
      label: [],
      imgSrc: [],
      labelTitle: ["选项标题1", "选项标题2"],
      valueTitle: ["很不满意", "不满意"],
      imgText: [],
      required: false,
      _id: "648abde98403e92196a6cb84",
      selectValue: "",
    },
    {
      title: "矩阵量表的标题",
      type: "矩阵量表",
      numLabel: "满意度",
      echartsDimension: 5,
      startnum: 1,
      label: [],
      imgSrc: [],
      labelTitle: ["选项标题1", "选项标题2"],
      valueTitle: ["很不满意", "不满意"],
      imgText: [],
      required: false,
      _id: "648abde98403e92196a6cb85",
      selectValue: "",
    },
  ],
});

//
// 假数据
let val1 = ref("");
let val12 = ref("");

let inputvvv = ref("");
let inputvvvv = ref("");

let inputmock = ref("");
let inputmock2 = ref("");

let isCheck = ref(false);
let isCheck2 = ref(false);

const dropdownvalue = ref("");

const handleChange = () => {
  //   isCheck.value = !isCheck.value;
  isCheck.value = true;

  console.log("其他    勾选拉", isCheck.value);
};

const handleChange2 = () => {
  //   isCheck.value = !isCheck.value;
  isCheck2.value = true;

  console.log("其他    勾选拉", isCheck2.value);
};
const options = [
  {
    value: "Option1",
    label: "Option1",
  },
  {
    value: "Option2",
    label: "Option2",
  },
  {
    value: "Option3",
    label: "Option3",
  },
  {
    value: "Option4",
    label: "Option4",
  },
  {
    value: "Option5",
    label: "Option5",
  },
];

// 控制web预览显示
let isWebQuestionShow = ref(false);

// 打开web预览
const goToWebPreviewQuestion = () => {
  console.log("1");
  isWebQuestionShow.value = true;
};
</script>

<style lang="less" scoped>
@import url("@/style/components/QuestionnaireManagement/previewQuestion.less");
</style>
