<template>
  <div id="OperationLog">
    <!-- 表体 -->
    <div class="OperationTable">
      <div class="vxetable">
        <vxe-toolbar>
          <template #buttons>
            <vxe-input
              class="Search"
              v-model="demo1.filterName"
              type="search"
              placeholder="请输入操作用户账号进行搜索"
              @Blur="ReauireData('search')"></vxe-input>
            <vxe-button @click="reset" class="Reset"><span class="reset-icon">重置</span></vxe-button>
          </template>
        </vxe-toolbar>
        <div class="tableOutSide" v-show="demo1.tableData.length != 0">
          <vxe-table
            border
            stripe
            ref="xTable"
            height="auto"
            auto-resize
            show-overflow
            row-id="_id"
            :data="demo1.tableData"
            :column-config="{ resizable: true, useKey: 'id' }"
            :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
            :checkbox-config="{ reserve: true }"
            :loading="demo1.loading">
            <vxe-column field="createdAt" type="html" title="操作时间" width="200" :fixed="demo1.colFixeds.col1" min-width="120">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="ID-header" v-if="column.title === '操作时间'">
                  <span class="name">操作时间</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('操作时间', $event)"></i></span>
                </div> </template
            ></vxe-column>
            <vxe-column field="account_email" type="html" title="操作账号" width="240" :fixed="demo1.colFixeds.col2" min-width="150">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="name-header" v-if="column.title === '操作账号'">
                  <span class="name">操作账号</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('操作账号', $event)"></i></span>
                </div> </template
            ></vxe-column>
            <vxe-column field="handle_module" type="html" title="操作模块" width="240" :fixed="demo1.colFixeds.col4" min-width="80">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '操作模块'">
                  <span class="name">操作模块</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('操作模块', $event)"></i></span>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="handle_behavior" type="html" title="操作行为" width="240" :fixed="demo1.colFixeds.col5" min-width="150">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Address-header" v-if="column.title === '操作行为'">
                  <span class="name">操作行为</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('操作行为', $event)"></i></span>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="behavior_type" type="html" title="操作行为类型" width="240" :fixed="demo1.colFixeds.col3" min-width="150">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Role-header" v-if="column.title === '操作行为类型'">
                  <span class="name">操作行为类型</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('操作行为类型', $event)"></i></span>
                </div>
              </template>
              <template #default="{ row }">
                <span
                  :class="
                    row.behavior_type === '关键操作'
                      ? 'key'
                      : row.behavior_type === '敏感操作'
                      ? 'Sensitive'
                      : row.behavior_type === '一般操作'
                      ? 'genera'
                      : row.behavior_type === '异常操作'
                      ? 'abnormal'
                      : ''
                  "
                  >{{ row.behavior_type }}</span
                >
              </template>
            </vxe-column>
            <vxe-column field="handle_object" type="html" title="操作对象" width="475" :fixed="demo1.colFixeds.col3" min-width="150">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Role-header" v-if="column.title === '操作对象'">
                  <span class="name">操作对象</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('操作对象', $event)"></i></span>
                </div>
              </template>
            </vxe-column>

            <template #empty>
              <span style="color: red">
                <p>没有更多数据了！</p>
              </span>
            </template>
          </vxe-table>
        </div>

        <!-- 下拉箭头的更多操作 -->
        <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
          <div class="contentBox" id="MoreOptions">
            <ul class="list" id="MoreOptions">
              <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
              </li>
              <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
              </li>
            </ul>
          </div>
        </div>

        <p v-show="demo1.tableData.length != 0">
          <vxe-pager
            background
            @page-change="pageChange"
            v-model:current-page="page5.currentPage"
            v-model:page-size="page5.pageSize"
            :total="page5.totalResult"
            :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
            <template #left>
              <div class="Total">
                共<span class="Clicked" style="color: #027aff">{{ page5.totalResult }}</span
                >条
              </div>
            </template>
          </vxe-pager>
        </p>
        <!-- 空项目 -->
        <div class="EmptyProject" v-show="demo1.tableData.length == 0">
          <span>
            <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
            <p>暂无数据</p>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineComponent, isReactive, ref, reactive, nextTick, toRaw, toRefs, onMounted, watchEffect, onBeforeUnmount, watch, computed } from 'vue';
import http from '@/utils/http';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { message } from '@/utils/resetMessage';

const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
//API接收
const store = useStore();
const router = useRouter();
const xTable = ref();
//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
});

watch(
  () => store.state.Log.Type,
  () => {
    page5.currentPage = 1;
    ReauireData();
  }
);
//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  //   loading: true,
});

// 全局排序数据
let SORT = ref({ createdAt: -1 });

// 请求初始数据
const ReauireData = async (val) => {
  if (val == 'search') {
    page5.currentPage = 1;
  }
  const res = await http.post('/log/fetchList-log', {
    page: page5.currentPage,
    size: page5.pageSize,
    type: store.state.Log.Type,
    content: demo1.filterName,
    sort: SORT.value,
  });

  console.log('初始化数据的请求返回>>>>', res);
  if (res.status == 0) {
    demo1.tableData = res.logs;

    page5.totalResult = res.totalCount;
  }
};

// 分页--->跳转页面显示数据处理
const pageChange = () => {
  ReauireData();
};
// 点击表头的下标
const FixedIndex = ref(-1);
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const OperationBox = ref(null); //获取更多操作盒子的ref
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value :>> ', value);
  const table = xTable.value;
  const FixedList = table.getColumns();
  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index;
    }
  });
  //console.log('FixedIndex下标为 :>> ', FixedIndex);
  showIdOptions.value = false;
  showIdOptions.value = true;
  // 设置位置
  const Event = event;
  OperationBox.value.style.left = event.clientX - 90 + 'px';
  OperationBox.value.style.top = event.clientY + 20 + 'px';
};
// More -->升序/降序
const AscendingSort = async (index, type) => {
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  const ColumnId = tableColumns[index].field;
  console.log(ColumnId, 'ColumnId');
  const sorts = {};
  if (type == 'Asc') {
    sorts[ColumnId] = 1;
  } else {
    sorts[ColumnId] = -1;
  }

  SORT.value = sorts;
  console.log(SORT.value, 'SORT.value');
  const res = await http.post('/log/fetchList-log', {
    page: page5.currentPage,
    size: page5.pageSize,
    content: demo1.filterName,
    sort: SORT.value,
  });
  demo1.tableData = res.logs;

  page5.totalResult = res.totalCount;
};
// 重置
const reset = () => {
  demo1.filterName = '';
  page5.currentPage = 1;
  page5.pageSize = 10;
  SORT.value = { createdAt: -1 };
  ReauireData();
};

// ------------------------------------生命周期 onMounted

onMounted(() => {
  ReauireData(); //初始化数据
  store.commit('Set_CurrentProjectName', '操作日志');

  document.addEventListener('click', (e) => {
    // 操作

    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
    }
  });
});
</script>
<style lang="less" scoped>
@import url(../../style/views/OperationLog/OperationLog.less);
</style>
