<template>
  <!-- 报告列表 -->
  <div id="ReportApproval" v-show="true">
    <div class="VolunteerBox">
      <!-- 表内导航栏(筛选条件) -->
      <div class="project_main_state">
        <div
          :class="index == ClickActive ? 'click-active  main_state_item' : 'main_state_item'"
          v-for="(item, index) in ProjectState"
          :key="index"
          @click="ClickActive = index">
          <div class="sample">{{ item }}</div>
        </div>
      </div>
      <!-- 表体 -->
      <div class="VolunteerTable">
        <div class="vxetable">
          <vxe-toolbar>
            <template #buttons>
              <vxe-input
                class="Search"
                v-model="demo1.Search"
                type="search"
                placeholder="请输入报告编号或名称进行搜索"
                @Blur="ReqFistData()"></vxe-input>
              <vxe-button class="Reset"><span class="reset-icon" @click="reset()">重置</span></vxe-button>
            </template>
          </vxe-toolbar>
          <div class="tableOutSide" v-show="demo1.tableData.length != 0">
            <vxe-table
              border
              stripe
              ref="xTable"
              height="672"
              show-overflow
              row-id="_id"
              :data="demo1.tableData"
              :tooltip-config="tooltipConfig"
              :column-config="{ resizable: true, useKey: 'id' }"
              :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
              :checkbox-config="{ reserve: true }"
              :loading="demo1.loading">
              <vxe-column type="seq" title="序号" width="54" align="center"></vxe-column>
              <vxe-column field="report_name" type="html" title="报告名称" width="244" :fixed="demo1.colFixeds.col1" min-width="260">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === '报告名称'">
                    <span class="name">报告名称</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('报告名称', $event)"></i></span>
                  </div> </template
              ></vxe-column>
              <vxe-column field="report_number" type="html" title="报告编号" width="140" :fixed="demo1.colFixeds.col3" min-width="166">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Role-header" v-if="column.title === '报告编号'">
                    <span class="name">报告编号</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('报告编号', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="report_status" type="html" title="报告状态" width="108" :fixed="demo1.colFixeds.col3" min-width="166">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Role-header" v-if="column.title === '报告状态'">
                    <span class="name">报告状态</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('报告状态', $event)"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <span
                    :class="
                      row.report_status === '待审批'
                        ? 'wait'
                        : row.report_status === '审批通过'
                        ? 'pass'
                        : row.report_status === '审批拒绝'
                        ? 'refuse'
                        : ''
                    "
                    >{{ row.report_status }}</span
                  >
                </template>
              </vxe-column>
              <vxe-column field="report_number" type="html" title="检测项目编号" width="148" :fixed="demo1.colFixeds.col4" min-width="138">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Sex-header" v-if="column.title === '检测项目编号'">
                    <span class="name">检测项目编号</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('检测项目编号', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="project_number" type="html" title="检测项目名称" width="160" :fixed="demo1.colFixeds.col5" min-width="140">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '检测项目名称'">
                    <span class="name">检测项目名称</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('检测项目名称', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="project_director" type="html" title="项目负责人" width="120" :fixed="demo1.colFixeds.col7" min-width="110">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '项目负责人'">
                    <span class="name">项目负责人</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('项目负责人', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="createdAt" type="html" title="报告提交时间" width="140" :fixed="demo1.colFixeds.col4" min-width="130">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Sex-header" v-if="column.title === '报告提交时间'">
                    <span class="name">报告提交时间</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('报告提交时间', $event)"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  {{ dayjs(row.createdAt).format('YYYY-MM-DD') }}
                </template>
              </vxe-column>
              <vxe-column field="approval_date" type="html" title="审批通过时间" width="140" :fixed="demo1.colFixeds.col4" min-width="199">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Sex-header" v-if="column.title === '审批通过时间'">
                    <span class="name">审批通过时间</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('审批通过时间', $event)"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <span>{{ row.approval_date === '-----' ? '——' : row.approval_date }}</span>
                </template>
              </vxe-column>
              <vxe-column field="approval_managment" type="html" title="报告审批员" width="124" :fixed="demo1.colFixeds.col4" min-width="114">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Sex-header" v-if="column.title === '报告审批员'">
                    <span class="name">报告审批员</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('报告审批员', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="refusal_cause" type="html" title="备注" width="80" :fixed="demo1.colFixeds.col4" min-width="70">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Sex-header" v-if="column.title === '备注'">
                    <span class="name">备注</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('备注', $event)"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <span>{{ row.refusal_cause === undefined || row.refusal_cause === '' ? '——' : row.refusal_cause }}</span>
                </template>
              </vxe-column>
              <vxe-column title="操作" width="180" :fixed="demo1.colFixeds.col9" :resizable="false">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Operation-header" v-if="column.title === '操作'">
                    <span class="name">操作</span>
                  </div>
                </template>
                <template #default="{ row }">
                  <div style="display: flex" class="more_item">
                    <el-tooltip class="box-item" effect="dark" content="查看报告" placement="top">
                      <vxe-button class="detail" @click="LookReport(row)">查看报告</vxe-button>
                      <!-- @click="ShowDetails(row)" -->
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="审批签章" placement="top">
                      <vxe-button v-if="row.report_status == '待审批'" @click="get_click_tableData(row)" class="detail"> 审批签章</vxe-button>
                      <vxe-button
                        v-else-if="row.report_status == '审批通过' || row.report_status == '审批拒绝'"
                        style="color: #bfbfbf"
                        class="detail">
                        审批签章</vxe-button
                      >
                    </el-tooltip>
                  </div>
                </template>
              </vxe-column>
              <template #empty>
                <span style="color: red">
                  <p>没有更多数据了！</p>
                </span>
              </template>
            </vxe-table>
          </div>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
              </ul>
            </div>
          </div>
          <p v-show="demo1.tableData.length != 0">
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">共{{ page5.totalResult }}条</div>
              </template>
            </vxe-pager>
          </p>
          <!-- 空项目 -->
          <div class="EmptyProject" v-show="demo1.tableData.length == 0">
            <span>
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
              <p>暂无数据</p>
            </span>
          </div>
        </div>
      </div>
    </div>
    <!--  申报签章 -->
    <ReportApprovalchild @ReportApproval="ReportApproval" v-if="isReportApproval"></ReportApprovalchild>
  </div>
  <!--tip 报告数据  -->
  <div id="ReportDetail" v-show="true"></div>
</template>
<script setup>
import ReportApprovalchild from '@/components/Reportapprovalchild/ReportApprovalchild.vue'
import { defineComponent, ref, reactive, nextTick, toRaw, toRefs, provide, onMounted, computed, onBeforeUnmount, watch } from 'vue'
import { VxeTableInstance } from 'vxe-table'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import XEUtils from 'xe-utils'
import http from '@/utils/http'
import { message } from '@/utils/resetMessage'
import CreatedVolunteer from '@/components/SampleManagement/CreateSample.vue'
import getFilename from '@/utils/getFilename'
import dayjs from 'dayjs'
import * as xlsx from 'xlsx'
import { writeFileXLSX } from 'xlsx-js-style'
import cityData from '@/utils/citydata'
import axios from 'axios'
import data from '@/utils/CurrentDate'
import Dayjs from 'dayjs'

const linewidth = ref(window.innerWidth)
const handleResize = () => {
  linewidth.value = window.innerWidth
}

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

//API接收
const store = useStore()
const router = useRouter()
const xTable = ref()
// table组件数据
let totalCount = ref(0)
const SetTableHeader = ref(false)
//demo 显示数据
// ！ 假数据
const tableData = reactive([])
// ! 点击的数据存储 传递给子组件使用
const isReportApproval = ref(false)
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  Search: '',
  tableData: [],
  loading: false,
  list: [],
  rename: '',
  Type: '全部',
})
//  表内导航栏
const ProjectState = ref(['全部', '待审批', '审批通过', '审批拒绝']) // 导航栏数据
let ClickActive = ref(0)
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
})
let SORT = ref({ createdAt: -1 }) // sort排序字段
// 点击表头的下拉箭头
const OperationBox = ref(null) //获取更多操作盒子的ref
const showIdOptions = ref(false) //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1)

let ClickMoreOptions = ref(false)
const CMoreOptions = ref(null) //获取MoreOptions盒子的ref
let OptionsData = ref()
let EventSearch = ref(false) // 搜索
let Volunteereditor = ref(false) // 新建
var selectedRows = reactive([]) //复选框数组
const New_sample = reactive({
  ///绑定数据
  name: data(1),
})
const isDistribute_samples = ref(false) //Distribute_samples 分发样品
const isBulk_distribution = ref(false) //批量分发
const isSample_retention = ref(false) //样品留样
const isSample_recovery = ref(false) //样品回收
let ViewReport = ref(false) //查看切换id显示不同数据
let information = ref({
  _id: '',
  naire_title: '',
  status: '',
  recyclNumber: '',
  createdAt: '',
  createUser: '',
}) // !点击的志愿者信息
const oneDerivedData = ref('')
//导出弹窗显示隐藏
let dialogShow = ref(false)
let uploadPercentage = ref(0)
const fileLists = ref([])
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params
  console.log(columns, '表头的顺序')
  // // 打印排序后的表头列数组对象
}
// !项目样品流转记录详情表格数据
const Weighing_information = ref([
  {
    Weighing: '20.2',
    Date: '2023-7-3',
    remark: '重新检测',
  },
])
// ! 下拉箭头
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value :>> ', value)
  const table = xTable.value
  const FixedList = table.getColumns()
  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index
    }
  })
  //console.log('FixedIndex下标为 :>> ', FixedIndex);
  showIdOptions.value = false
  showIdOptions.value = true
  // 设置位置
  const Event = event
  OperationBox.value.style.left = event.clientX - 90 + 'px'
  OperationBox.value.style.top = event.clientY + 20 + 'px'
}
// tip tootip提示方法
const tooltipConfig = reactive({
  showAll: true,
  enterable: true,
  contentMethod: ({ type, column, row, items, _columnIndex }) => {
    const { field } = column

    // column 判断 column.title 是否为'操作'
    // 重写默认的提示内容
    if (column.title == '操作') {
      return (tooltipConfig.showAll = false)
    } else if (field === 'rate') {
      // 返回空字符串，控制单元格不显示提示内容
      return ''
    } else {
      tooltipConfig.showAll = true
    }
    // 其余的单元格使用默认行为

    return null
  },
})

const hiddenOperationBox = () => {
  showIdOptions.value = false
  //console.log('点击点击 :>> ');
}
// More -->升序/降序
const AscendingSort = async (index, type) => {
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  const ColumnId = tableColumns[index].field
  console.log(ColumnId, 'ColumnId')
  const sorts = {}
  if (type == 'Asc') {
    sorts[ColumnId] = 1
  } else {
    sorts[ColumnId] = -1
  }

  SORT.value = sorts
  console.log(SORT.value, 'SORT.value')
  const res = await http.post('/report/fetchApprovalList-report', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
  })
  demo1.tableData = res.reports
  page5.totalResult = res.totalCount
}
// ! 切换报告状态
watch(
  () => ClickActive.value,
  (newValue) => {
    console.log('newValue :>> ', newValue)
    if (newValue == 0) {
      demo1.Type = '全部'
    } else if (newValue == 1) {
      demo1.Type = '待审批'
    } else if (newValue == 2) {
      demo1.Type = '审批通过'
    } else if (newValue == 3) {
      demo1.Type = '审批拒绝'
    }
    ReqFistData()
  }
)

// 分页--->跳转页面显示数据处理
const pageChange = async () => {
  const res = await http.post('/report/fetchApprovalList-report', {
    content: demo1.Search, //输入框
    page: page5.currentPage, //页码
    size: page5.pageSize, //数量
    sort: SORT.value, //排序,
    type: demo1.Type,
  })
  console.log('res请求初始数据的返回res :>> ', res)
  if (res.status == 0) {
    demo1.tableData = res.reports
    page5.totalResult = res.totalCount
  }
}
const handlePreviews = (file) => {
  console.log(file, 'handlePreviews')
}

// 每一行的查看详情
const ShowDetails = (row) => {
  // console.log('固定信息2:', row);
  //    id.value = row._id
  //    console.log(row)
  // information.value = row
  // Volunteerdetails.value = !Volunteerdetails.value;
  Volunteereditor.value = true
}
// !详情点击添加
const addRow = () => {
  Weighing_information.value.push({
    Weighing: '',
    Date: '',
    remark: '',
  })
}
const emits = defineEmits(['update:modelValue'])
// !获取当前的报告信息
const get_click_tableData = (row) => {
  if (!store.state.role_info.includes('project-examine-signature')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  // click_tableData.value = row;
  // !走vuex
  store.commit('Set_Report_data', row)
  isReportApproval.value = true
  store.commit('Set_Details', row)
}

// !父子组件通信
const ReportApproval = (status) => {
  console.log(status, 'status')

  isReportApproval.value = status
  reset()
}

const is_dialogShow = ref(false)
watch(
  () => is_dialogShow.value,
  () => {
    if (is_dialogShow.value === false) {
      ReqFistData()
    }
  }
)
const handlerDialog = () => {
  dialogFormVisible.value = true
}

// 重置
const reset = () => {
  ClickActive.value = 0
  demo1.Search = ''
  page5.currentPage = 1
  page5.pageSize = 10
  SORT.value = { createdAt: -1 } // sort排序字段
  ReqFistData()
}
// 查看报告
const LookReport = (val) => {
  // !数据报告签章使用
  // store.commit('Set_Report_data', val);
  // store.commit('Set_ReportId', val._id);
  // sessionStorage.setItem('reportid', val._id);
  // console.log('val :>> ', val);
  // store.commit('Set_ViewReport', val.HTML);
  // store.commit('Set_ReportName', val.report_name);
  // store.commit('Set_ReportNumber', val.report_number);
  // store.commit('Set_Details', val);
  // router.push({
  //   name: 'ReportDetails',
  //   params: {
  //     _id: val._id,
  //   },
  // });

  console.log('每一行的报告数据val :>> ', val)
  const reportId = {
    Id: val._id,
  }
  var params = new URLSearchParams(reportId).toString()
  console.log('params :>> ', params)
  // 弹出报告页面
  window.open(`https://report.cems.fuhuan.ltd/?${params}`, '_blank')

  // // 获取div元素
  // let divElement = document.getElementById('ReportDetail');

  // // 创建要插入的HTML代码
  // let htmlCode = val.HTML;

  // // 在div中插入HTML代码
  // divElement.innerHTML = htmlCode;
}

// 请求初始数据
const ReqFistData = async () => {
  page5.currentPage = 1
  const res = await http.post('/report/fetchApprovalList-report', {
    content: demo1.Search, //输入框
    page: page5.currentPage, //页码
    size: page5.pageSize, //数量
    sort: SORT.value, //排序,
    type: demo1.Type,
  })
  console.log('res请求初始数据的返回res :>> ', res)
  if (res.status == 0) {
    demo1.tableData = res.reports
    page5.totalResult = res.totalCount
  }
}

onMounted(() => {
  data(1)

  ReqFistData() //
  store.commit('Set_CurrentProjectName', '报告审批')
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // 操作
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false
      }

      console.log(ClickMoreOptions.value)
    }
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false
      }
      console.log(ClickMoreOptions.value)
    }
  })
})
</script>

<style lang="less" scoped>
@import url('@/style/views/ProjectManagement/ReportApproval.less');
</style>
