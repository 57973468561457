<template>
  <div class="MainBox">
    <!-- <button @click="butChange">获取路由</button> -->
    <NavBar v-if="Current != '/' && Current != '/verify' && Current != '/set_newpassword' && route.name != 'HelpCenter'" />
    <div class="Content">
      <TopHeader v-if="Current != '/' && Current != '/verify' && Current != '/set_newpassword'" />
      <router-view />
    </div>
  </div>

  <!-- 30分钟之后未操作 组件 -->
  <TimeOut />
</template>

<script setup>
import { defineComponent, onMounted, nextTick, toRaw, ref, watch, watchEffect } from 'vue';
import NavBar from '@/components/NavBar.vue';
import TopHeader from '@/components/TopHeader.vue';
import { useStore } from 'vuex';
import { onBeforeRouteUpdate, useRouter, useRoute } from 'vue-router';
import TimeOut from '@/utils/TimeOut.vue';
import http from '@/utils/http';
import { comment } from 'html-docx-js/dist/html-docx';
// const modules = import.meta.glob('./views/**/*.vue');
onMounted(async () => {
  butChange();
});
const d = {
  '@/views/ProjectManagement/ProjectList.vue': () => import('@/views/ProjectManagement/ProjectList.vue'),
  '@/views/ProjectManagement/ProjectSchedule.vue': () => import('@/views/ProjectManagement/ProjectSchedule.vue'),
  '@/views/ProjectManagement/ReportApproval.vue': () => import('@/views/ProjectManagement/ReportApproval.vue'),
  '@/components/Reportapprovalchild/ReportDetails.vue': () => import('@/components/Reportapprovalchild/ReportDetails.vue'),
  '@/components/ProjectManagement/CommentCpn.vue': () => import('@/components/ProjectManagement/CommentCpn.vue'),
  '@/views/ProjectManagement/Report_management.vue': () => import('@/views/ProjectManagement/Report_management.vue'),
  '@/views/VolunteerManagement/volunteer_Custom_Settings.vue': () => import('@/views/VolunteerManagement/volunteer_Custom_Settings.vue'),
  '@/views/SampleManagement/Sample_Custom_Settings.vue': () => import('@/views/SampleManagement/Sample_Custom_Settings.vue'),
  '@/views/InstrumentMangement/Lnstrument_Custom_Settings.vue': () => import('@/views/InstrumentMangement/Lnstrument_Custom_Settings.vue'),
  '@/views/ProjectManagement/Project_sharing.vue': () => import('@/views/ProjectManagement/Project_sharing.vue'),
  '@/components/ProjectManagement/ShareMe.vue': () => import('@/components/ProjectManagement/ShareMe.vue'),
  '@/components/ProjectManagement/MyShare.vue': () => import('@/components/ProjectManagement/MyShare.vue'),
  '@/components/ProjectManagement/ShareDetails.vue': () => import('@/components/ProjectManagement/ShareDetails.vue'),
  '@/components/ProjectManagement/SharePlan.vue': () => import('@/components/ProjectManagement/SharePlan.vue'),
  '@/components/ProjectManagement/ShareMember.vue': () => import('@/components/ProjectManagement/ShareMember.vue'),
  '@/components/ProjectManagement/ShareQuestion.vue': () => import('@/components/ProjectManagement/ShareQuestion.vue'),
  '@/components/ProjectManagement/ShareInstrumentPage.vue': () => import('@/components/ProjectManagement/ShareInstrumentPage.vue'),
  '@/components/ProjectManagement/ShareVolunteerData.vue': () => import('@/components/ProjectManagement/ShareVolunteerData.vue'),
  '@/components/ProjectManagement/ShareSample.vue': () => import('@/components/ProjectManagement/ShareSample.vue'),
  '@/components/ProjectManagement/ShareDocument.vue': () => import('@/components/ProjectManagement/ShareDocument.vue'),
  '@/components/ProjectManagement/ShareReport.vue': () => import('@/components/ProjectManagement/ShareReport.vue'),
  '@/components/ProjectManagement/ProjectDetails.vue': () => import('@/components/ProjectManagement/ProjectDetails.vue'),
  '@/components/ProjectManagement/ProjectPlan.vue': () => import('@/components/ProjectManagement/ProjectPlan.vue'),
  '@/components/ProjectManagement/ProjectMember.vue': () => import('@/components/ProjectManagement/ProjectMember.vue'),
  '@/components/ProjectManagement/ProjectQuestion.vue': () => import('@/components/ProjectManagement/ProjectQuestion.vue'),
  '@/components/ProjectManagement/InstrumentPage.vue': () => import('@/components/ProjectManagement/InstrumentPage.vue'),
  '@/components/ProjectManagement/VolunteerData.vue': () => import('@/components/ProjectManagement/VolunteerData.vue'),
  '@/components/ProjectManagement/ProjectSample.vue': () => import('@/components/ProjectManagement/ProjectSample.vue'),
  '@/components/ProjectManagement/ProjectDocument.vue': () => import('@/components/ProjectManagement/ProjectDocument.vue'),
  '@/components/ProjectManagement/ProjectReport.vue': () => import('@/components/ProjectManagement/ProjectReport.vue'),
  '@/components/ProjectManagement/CreateProject.vue': () => import('@/components/ProjectManagement/CreateProject.vue'),
  '@/views/VolunteerManagement/VolunteerBank.vue': () => import('@/views/VolunteerManagement/VolunteerBank.vue'),
  '@/views/VolunteerManagement/ClockinView.vue': () => import('@/views/VolunteerManagement/ClockinView.vue'),
  '@/views/VolunteerManagement/RecruitVolunteer.vue': () => import('@/views/VolunteerManagement/RecruitVolunteer.vue'),
  '@/views/InstrumentMangement/InstrumentList.vue': () => import('@/views/InstrumentMangement/InstrumentList.vue'),
  '@/views/VolunteerManagement/VolunteerSchedule.vue': () => import('@/views/VolunteerManagement/VolunteerSchedule.vue'),
  '@/views/InstrumentMangement/InstrumentTestRecord.vue': () => import('@/views/InstrumentMangement/InstrumentTestRecord.vue'),
  '@/views/VolunteerManagement/TestRecords.vue': () => import('@/views/VolunteerManagement/TestRecords.vue'),
  '@/views/QuestionnaireManagement/QuestionnaireList.vue': () => import('@/views/QuestionnaireManagement/QuestionnaireList.vue'),
  '@/components/QuestionnaireManagement/QuestionStatisticAnalysis.vue': () =>
    import('@/components/QuestionnaireManagement/QuestionStatisticAnalysis.vue'),
  '@/components/InstrumentMangement/InstrumentStatisticalAnalysis.vue': () =>
    import('@/components/InstrumentMangement/InstrumentStatisticalAnalysis.vue'),
  '@/components/QuestionnaireManagement/QuestionReport.vue': () => import('@/components/QuestionnaireManagement/QuestionReport.vue'),
  '@/views/QuestionnaireManagement/ShareQuestion.vue': () => import('@/views/QuestionnaireManagement/ShareQuestion.vue'),
  '@/views/SampleManagement/SampleList.vue': () => import('@/views/SampleManagement/SampleList.vue'),
  '@/views/SampleManagement/SampleFlowRecord.vue': () => import('@/views/SampleManagement/SampleFlowRecord.vue'),
  '@/views/SampleManagement/CustomSettings.vue': () => import('@/views/SampleManagement/CustomSettings.vue'),
  '@/views/VolunteerManagement/AppAdmin.vue': () => import('@/views/VolunteerManagement/AppAdmin.vue'),
  '@/components/QuestionnaireManagement/CreatedQuestionnaire.vue': () => import('@/components/QuestionnaireManagement/CreatedQuestionnaire.vue'),
  '@/components/QuestionnaireManagement/EditQuestion.vue': () => import('@/components/QuestionnaireManagement/EditQuestion.vue'),
  '@/components/QuestionnaireManagement/TextImport.vue': () => import('@/components/QuestionnaireManagement/TextImport.vue'),
  '@/components/QuestionnaireManagement/previewQuestion.vue': () => import('@/components/QuestionnaireManagement/previewQuestion.vue'),
  '@/components/QuestionnaireManagement/webPreviewQuestion.vue': () => import('@/components/QuestionnaireManagement/webPreviewQuestion.vue'),
  '@/views/ExpertManagement/ExpertList.vue': () => import('@/views/ExpertManagement/ExpertList.vue'),
  '@/views/ExpertManagement/CustomSettings.vue': () => import('@/views/ExpertManagement/CustomSettings.vue'),
  '@/views/ExpertManagement/ExpertEvaluationRecords.vue': () => import('@/views/ExpertManagement/ExpertEvaluationRecords.vue'),
  '@/views/systemManagement/personalCenter.vue': () => import('@/views/systemManagement/personalCenter.vue'),
  '@/views/systemManagement/UserPower.vue': () => import('@/views/systemManagement/UserPower.vue'),
  '@/views/systemManagement/SignatureManagement.vue': () => import('@/views/systemManagement/SignatureManagement.vue'),
  '@/components/systemManagement/UserInfo.vue': () => import('@/components/systemManagement/UserInfo.vue'),
  '@/components/systemManagement/PowerMange.vue': () => import('@/components/systemManagement/PowerMange.vue'),
  '@/views/Home/HomeView.vue': () => import('@/views/Home/HomeView.vue'),
  '@/views/OperationLog/OperationLog.vue': () => import('@/views/OperationLog/OperationLog.vue'),
  '@/views/ProjectManagement/ProjectManagement_DataBoard.vue': () => import('@/views/ProjectManagement/ProjectManagement_DataBoard.vue'),
  '@/views/VolunteerManagement/VolunteerDataBoard.vue': () => import('@/views/VolunteerManagement/VolunteerDataBoard.vue'),
  '@/views/InstrumentMangement/InstrumentDataBoard.vue': () => import('@/views/InstrumentMangement/InstrumentDataBoard.vue'),
  '@/views/QuestionnaireManagement/H5QuestionPreview.vue': () => import('@/views/QuestionnaireManagement/H5QuestionPreview.vue'),
  '@/views/QuestionnaireManagement/WebQuestionPreview.vue': () => import('@/views/QuestionnaireManagement/WebQuestionPreview.vue'),
  '@/components/QuestionnaireManagement/QuestionDefaultReport.vue': () => import('@/components/QuestionnaireManagement/QuestionDefaultReport.vue'),
};
const butChange = async () => {
  if (localStorage.getItem('_id')) {
    const res = await http.post('/user/getUserMessage-user', {
      userId: localStorage.getItem('_id'),
    });
    if (!res) {
      return;
    }
    const routess = router.getRoutes();
    // routess.forEach((item) => {
    //     if (item.name != 'about' && item.name != 'login') {
    //         router.removeRoute(item.name);
    //     }
    // });
    let obj = [];
    res.user.route.forEach((item, index) => {
      obj.push(item.component);
      // console.log(item, 'item');
      const processedRoute = {};
      processedRoute.path = item.path;
      processedRoute.name = item.name;
      processedRoute['component'] = d[item.component];
      processedRoute.meta = item.meta;

      router.addRoute(processedRoute);
    });
    // history.go(0);
    // router.push('/InstrumentList');
  }
};

const router = useRouter(); //接收路由
const route = useRoute();
const store = useStore();
const Current = ref('/');
const loadView = (view) => {
  if (process.env.NODE_ENV === 'development') {
    return (resolve) => require([`@/views/${view}`], resolve);
  } else {
    // 使用 import 实现生产环境的路由懒加载
    return () => require(`@/views/${view}`);
  }
};

watch(
  () => store.state.user.CurrentPath,
  (newValue) => {
    Current.value = newValue;
  },
);

const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
</script>

<style lang="less">
@import url('@/style/tootip.less');
* {
  border: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
}
// 报告样式
#ProjectReport,
#ReportDetails {
  //表格边框
  table {
    border: 2px solid black;
    border-collapse: collapse;
    font-size: 16px;

    td {
      font-size: 16px;

      border: 2px solid black;
    }
    th {
      font-size: 16px;

      border: 2px solid black;
    }
  }
  li {
    font-size: 16px;
  }
}
// 气泡提示

// .Back {
//   .el_popper_arrow::before {
//     background: rgba(49, 50, 51, 0.8) !important;
//   }
// }
// .el-popper.is-dark {
//   background: rgba(49, 50, 51, 0.8) !important;
//   .el_popper_arrow {
//     background: rgba(49, 50, 51, 0.8) !important;
//   }
// }
// span.el_popper_arrow::before {
//   background: rgba(49, 50, 51, 0.8) !important;
// }
// .el-popper.is-dark[x-placement^='top'].el_popper_arrow::before {
//   background: rgba(49, 50, 51, 0.8) !important;
// }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

.el-aside {
  transition: width 0.15s;
  -webkit-transition: width 0.15s;
  -moz-transition: width 0.15s;
  -webkit-transition: width 0.15s;
  -o-transition: width 0.15s;
}
.MainBox {
  display: flex;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;

  opacity: 1;
}

/* 滚动条上的滚动滑块 */
::-webkit-scrollbar-thumb {
  background-color: #939599;
  border-radius: 10px;
  cursor: pointer;
}
</style>
