import http from '@/utils/http';
import cryptoUtils from '../utils/cryptoUtils';
import { GET_ADMINMESSAGE } from './actionTypes';
import { SET_ADMININFO, SET_EQUIPMENTOPTIONS, SET_ROLEINFO } from './mutationTypes';

export default {
    async [GET_ADMINMESSAGE]({ commit }, adminID) {
        // console.log(adminID, 'adminID');
        // const encryptPayload = window.sessionStorage.getItem('encryptPayload');
        const encryptPayload = window.sessionStorage.getItem('permission_list');
        // const encryptPayload={}
        if (!!encryptPayload && encryptPayload != 'undefined') {
            console.log('本地有角色信息');
            //   const payload = cryptoUtils.decrypt(encryptPayload);
            const payload = encryptPayload;
            // console.log(payload);
            // commit(SET_ADMININFO, payload.adminINfo);
            commit(SET_ROLEINFO, payload);
            // commit(SET_EQUIPMENTOPTIONS, payload.equipmentOptions);
        } else {
            console.log('本地没角色信息');
            if (localStorage.getItem('_id')) {
                const res = await http.post('/user/getUserMessage-user', {
                    userId: localStorage.getItem('_id'),
                });
                // console.log();
                //   const response = await http.get(`admin/get-adminMessage?adminID=${adminID}`);
                //   if (response.status === 0) {
                //     const payload = {
                //       adminINfo: response.adminMessage,
                //       roleINfo: response.roleMessage,
                //       equipmentOptions: response.equipmentOptions,
                //     };
                //     const encryptPayload = cryptoUtils.encrypt(payload);
                window.sessionStorage.setItem('permission_list', res.user.permission_list);
                commit(SET_ROLEINFO, res.user.permission_list);
                //     commit(SET_ADMININFO, payload.adminINfo);
                //     commit(SET_ROLEINFO, payload.roleINfo);
                //     commit(SET_EQUIPMENTOPTIONS, payload.equipmentOptions);
                //   }
            }
        }
    },
};
