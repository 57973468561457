<template>
  <div id="InstrumentTestRecord">
    <mPAInstrument v-if="store.state.Project.InstrumentName?.label == '16427414 皮肤弹性测试探头Cutometer MPA580'"></mPAInstrument>
    <vplusInstrument v-if="store.state.Project.InstrumentName?.label == 'CN-JK-001 Vplus 智能皮肤分析系统'"></vplusInstrument>
    <TiVi v-if="store.state.Project.InstrumentName?.label == 'TH-097539 皮肤敏感度测试仪 TiVi700'"></TiVi>
    <MX18Instrument v-if="store.state.Project.InstrumentName.label == 'Mexameter MX18 皮肤色素测试探头'"></MX18Instrument>
    <GL200Instrument v-if="store.state.Project.InstrumentName.label == 'Glossymeter GL200 皮肤光泽度测试探头'"></GL200Instrument>
    <CM825Instrument v-if="store.state.Project.InstrumentName.label == 'Corneometer CM825 363212  皮肤水分测试探头'"></CM825Instrument>
    <IDM800Instrument v-if="store.state.Project.InstrumentName.label == 'Indentometer IDM800 皮肤致密柔软度测试探头'"></IDM800Instrument>
    <CL400Instrument v-if="store.state.Project.InstrumentName.label == 'ColorimeterCL400 363212  皮肤颜色测试探头'"></CL400Instrument>
    <PH905Instrument v-if="store.state.Project.InstrumentName.label == 'Skin-pH-meter PH905 363212  皮肤酸碱度测试探头'"></PH905Instrument>
    <SM815Instrument v-if="store.state.Project.InstrumentName.label == 'SM 815 皮肤油脂测试仪Sebumeter'"></SM815Instrument>
    <TMHEXInstrument v-if="store.state.Project.InstrumentName.label == 'Tewameter TM HEX 水分流失测试探头'"></TMHEXInstrument>
    <div class="test_box" v-if="store.state.Project.InstrumentName.value && !defaultInstr.includes(store.state.Project.InstrumentName.label)">
      <vxe-toolbar>
        <template #buttons>
          <!-- <vxe-input
            class="Search"
            v-model="demo1.filterName"
            type="search"
            placeholder="请搜索"
            @keyup.enter="searchEvent2"
            @Blur="searchEvent2"></vxe-input>
          <vxe-button @click="reset" class="Reset"><span class="reset-icon">重置</span></vxe-button> -->
          <div class="head_tip" v-if="editStatus">
            <img class="tip_img" src="https://newstore.vynior.com/%E6%8F%90%E9%86%92%402x%20%283%29.png" alt="" />
            <span class="tip_text">注意: &nbsp;</span>
            若切换页面，当前编辑将自动保存
          </div>
          <!-- ！三个分类 -->
          <div class="form_header">
            <img
              class="created"
              @click="editTableClick"
              v-if="!editStatus && comTableData.length > 0"
              src="https://new.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2057%402x.png"
              alt="" />
            <img
              class="created"
              @click="upDataTableClick"
              v-if="editStatus"
              src="https://new.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2054%402x.png"
              alt="" />
            <img class="uploading" v-if="!editStatus" @click="uploadData" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B68319%402x.png" alt="" />
            <img class="export" v-if="!editStatus" @click="exportChange" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B682270%402x.png" alt="" />
            <img
              class="export"
              v-if="editStatus"
              @click="cancelEditClick"
              src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2082%402x.png"
              alt="" />
          </div>
        </template>
      </vxe-toolbar>
      <div class="VolunteerBox">
        <!-- 表内导航栏(筛选条件) -->
        <div class="VolunteerTable_main">
          <!-- 表体 -->
          <div class="VolunteerTable" :class="{ VolunteerTable1: isCollapse }">
            <div class="table">
              <vxe-table
                border
                stripe
                align="left"
                ref="xTable"
                :scroll-y="{ mode: 'default' }"
                show-overflow
                row-id="_id"
                :data="comTableData"
                :column-config="{ resizable: true }"
                :row-config="{
                  isHover: true,
                  keyField: 'id',
                  height: linewidth <= 1280 ? 32 : 48,
                  minHeight: 32,
                  useKey: 'id',
                }"
                :checkbox-config="{ reserve: true }"
                :loading="demo1.loading"
                @checkbox-all="selectAllChangeEvent1"
                @checkbox-change="selectChangeEvent1">
                <vxe-column class="table_check" field="checked" type="checkbox" width="50" :fixed="demo1.colFixeds.col0"></vxe-column>
                <vxe-column
                  v-for="(item, index) in tableHeader"
                  :key="index"
                  :field="item.fieldName"
                  :title="item.fieldName"
                  :fixed="demo1.colFixeds.col1"
                  min-width="120">
                  <!-- 增加下拉箭头 -->
                  <template #header>
                    <div class="ID_header">
                      <span class="name">{{ item.fieldName }}</span>
                      <!-- <span class="head_jt"><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item, $event)"></i></span> -->
                      <!-- <span class="head_set" v-if="index == tableHeader.length - 1"
                        ><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i
                      ></span> -->
                    </div>
                  </template>
                  <template #default="{ row }">
                    <vxe-input v-if="editStatus" class="row_inp" v-model="row[item.fieldName]" placeholder=""></vxe-input>
                    <span v-if="!editStatus">{{ row[item.fieldName] ? row[item.fieldName] : '——' }}</span></template
                  ></vxe-column
                >
                <vxe-column title="操作" align="center" min-width="109" fixed="right" :visible="true" :resizable="false">
                  <!-- 增加下拉箭头 -->
                  <template #header>
                    <div class="ID_headers">
                      <div class="name">操作</div>
                      <span v-if="!editStatus" class="head_set"><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                    </div>
                  </template>
                  <template #default="{ row }">
                    <div class="row_set">
                      <div class="set_del" @click="delTableClick(row)" v-if="editStatus == true">删除数据</div>
                      <div class="set_status" @click="upStatusClick(row, false)" v-if="row.isvalid == true && editStatus == false">标记失效</div>
                      <div class="set_status" @click="upStatusClick(row, true)" v-if="row.isvalid == false && editStatus == false">恢复有效</div>
                    </div>
                  </template>
                </vxe-column>
                <template #empty>
                  <span style="color: red">
                    <p>没有更多数据了！</p>
                  </span>
                </template>
              </vxe-table>
            </div>

            <!-- 下拉箭头的更多操作 -->
            <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
              <div class="contentBox" id="MoreOptions">
                <ul class="list" id="MoreOptions">
                  <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                    <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                  </li>
                  <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                    <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                  </li>
                  <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')">
                    <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" />固定
                  </li>
                  <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" style="color: #bfbfbf">
                    <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" />取消固定
                  </li>

                  <li id="MoreOptions" @click="HiddenColumn(FixedIndex)"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li>
                </ul>
              </div>
            </div>
            <p>
              <vxe-pager
                class="Buttons"
                background
                @page-change="pageChange"
                v-model:current-page="page5.currentPage"
                v-model:page-size="page5.pageSize"
                :total="page5.totalResult"
                :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
                <template #left>
                  <div class="Total">
                    已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                    >条/共{{ page5.totalResult }}条
                  </div>
                </template>
              </vxe-pager>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="Empty" v-if="!store.state.Project.InstrumentName.value">
      <img src="https://newstore.vynior.com/12%402x%20%281%29.png" />
      <span>请搜索仪器，查看对应的测试记录</span>
    </div>
    <!-- 导入 -->
    <el-dialog class="volunteer_dialog" v-model="dialogShow1" title="Shipping address" :align-center="true" :close-on-click-modal="false">
      <div class="dialog_title">
        <span>上传数据</span>
        <div class="title_cancel" @click="dialogShow1 = false"></div>
      </div>
      <div class="dialog_main">
        <div class="main_download">
          <div class="download_title">1.下载模板，填写数据信息</div>
          <div class="download_img"></div>
          <div class="upload_name"></div>
          <div class="upload_err"></div>
          <div class="download_but" @click="downloadXlsx">
            <button>下载模板</button>
          </div>
        </div>
        <div class="main_upload">
          <div class="upload_title">2.上传填写好的文件</div>
          <div class="upload_img"></div>
          <div class="upload_name"></div>
          <div class="upload_tip"></div>
          <div class="upload_but">
            <el-upload class="upload_xlsx" ref="videoRef" accept=".xlsx,.xls" :http-request="uploadFile" :show-file-list="false">
              <button>上传文件</button>
            </el-upload>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- !表头字段设置 -->
    <el-dialog class="SetTableHeader" v-model="SetTableHeader" title="表头字段设置" :show-close="true">
      <div class="body_top">
        <div class="top_left">
          <div class="left_head">
            <div class="head_back"></div>
            仪器测试记录列表预设字段
          </div>
          <div class="top_mian">
            <div class="main_check">
              <el-checkbox
                @change="leftCheckChange(index)"
                v-for="(item, index) in fieldList"
                :key="index"
                v-model="item.isValid"
                :label="item.fieldName"
                size="large" />
            </div>
          </div>
        </div>
        <div class="RightBox">
          <div class="text-header">列表页展示字段</div>
          <!-- 调整数据 -->
          <div class="switchBox">
            <div id="xxx" class="demo">
              <draggable itemKey="fieldName" :list="datafieldList" ghost-class="ghost" chosen-class="chosenClass" animation="300">
                <template #item="{ element }">
                  <div class="item move">
                    <div class="drag"></div>
                    {{ element.fieldName }}
                    <!-- -->
                    <img class="top" @click="backTop(element, 'top')" src=" https://newstore.vynior.com/cems_web/%E7%BB%841112%402x.png   " alt="" />
                    <img
                      class="delete"
                      @click="backTop(element, 'delete')"
                      src="
                       https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88221%402x.png
                      "
                      alt="" />
                  </div>
                </template>
              </draggable>
            </div>
          </div>
        </div>
      </div>
      <div class="body_bot">
        <div class="bot_cancel" @click="cancelHeadfieldClick">取消</div>
        <div class="bot_up" @click="upHeadfieldClick">保存</div>
      </div>
    </el-dialog>
    <!-- 标记失效有效 -->
    <div id="del_dialog" v-if="markShow">
      <el-dialog v-model="markShow" :close-on-click-modal="false" :align-center="true" :show-close="false">
        <template #header>
          <div class="title">确定将该数据标记为失效数据吗？</div>
        </template>
        <template #default>
          <div class="Derived_body">
            <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" alt="" />
            <div class="body_text">将数据标记失效后可以在操作栏恢复为有效数据</div>
          </div>
          <div class="Derived_but">
            <el-button class="cancel" @click="markShow = false">取消</el-button>
            <el-button class="sure" type="" @click="OkChange"> 确认 </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    <div id="del_dialog" v-if="nomarkShow">
      <el-dialog v-model="nomarkShow" :close-on-click-modal="false" :align-center="true" :show-close="false">
        <template #header>
          <div class="title">确定将该数据恢复为有效数据吗？</div>
        </template>
        <template #default>
          <div class="Derived_body">
            <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" alt="" />
            <div class="body_text">将数据恢复有效后可以在操作栏标记为有效数据</div>
          </div>
          <div class="Derived_but">
            <el-button class="cancel" @click="nomarkShow = false">取消</el-button>
            <el-button class="sure" type="" @click="OkNoMarkChange"> 确认 </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script setup>
// ! 仪器列表
import vplusInstrument from '@/components/Fullinstrument/vplusInstrument.vue'; //vplus
import mPAInstrument from '@/components/Fullinstrument/MPA580Instrument.vue'; //MPA580
import TiVi from '@/components/Fullinstrument/TiVi700Instrument.vue'; //Tivi
import MX18Instrument from '@/components/Fullinstrument/MX18Instrument.vue'; //Mx18
import GL200Instrument from '@/components/Fullinstrument/GL200Instrument.vue'; //GL200
import CM825Instrument from '@/components/Fullinstrument/CM825Instrument.vue'; //  CM825Instrument
import IDM800Instrument from '@/components/Fullinstrument/IDM800Instrument.vue'; //  IDM800Instrument
import CL400Instrument from '@/components/Fullinstrument/CL400Instrument.vue'; //  CL400Instrument
import PH905Instrument from '@/components/Fullinstrument/PH905Instrument.vue'; //  PH905Instrument
import SM815Instrument from '@/components/Fullinstrument/SM815Instrument.vue'; //  SM815Instrument
import TMHEXInstrument from '@/components/Fullinstrument/TMHEXInstrument.vue'; //  TMHEXInstrument
import { defineComponent, isReactive, ref, reactive, nextTick, toRaw, toRefs, onMounted, watchEffect, onBeforeUnmount, watch, computed } from 'vue';
import { VxeTableInstance } from 'vxe-table';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { message } from '@/utils/resetMessage';
import XEUtils from 'xe-utils';
import dayjs from 'dayjs';
import draggable from 'vuedraggable';
import * as xlsx from 'xlsx-js-style';
import http from '@/utils/http';
import { replaceEmptyValues } from '@/utils/emptyArray';
//API接收
const store = useStore();
const router = useRouter();
const xTable = ref();
onMounted(async () => {
  await getInstrTestHeadRes();
  await getInstrTestDataRes();
});
// 分页触发事件
const pageChange = async () => {
  console.log(editStatus.value);
  if (editStatus.value) {
    await upDataTableClick();
  }
  await getInstrTestDataRes();
};
const defaultInstr = ref([
  'SM 815 皮肤油脂测试仪Sebumeter',
  'Tewameter TM HEX 水分流失测试探头',
  'Skin-pH-meter PH905 363212  皮肤酸碱度测试探头',
  'ColorimeterCL400 363212  皮肤颜色测试探头',
  'Indentometer IDM800 皮肤致密柔软度测试探头',
  'Corneometer CM825 363212  皮肤水分测试探头',
  'Glossymeter GL200 皮肤光泽度测试探头',
  'Mexameter MX18 皮肤色素测试探头',
  'TH-097539 皮肤敏感度测试仪 TiVi700',
  'CN-JK-001 Vplus 智能皮肤分析系统',
  '16427414 皮肤弹性测试探头Cutometer MPA580',
]);
// 表头字段设置展示的数组
const fieldList = ref([]);
// 未保存的数组
const fieldLists = ref([]);
// 展示排序的数组
const datafieldList = ref([]);
// 未保存的排序数组
const datafieldLists = ref([]);
// 获取测试记录表头
const getInstrTestHeadRes = async () => {
  console.log(store.state.Project.InstrumentName);
  if (!!store.state.Project.InstrumentName.value) {
    const res = await http.post('/instrument/getFields-instrument', {
      InstrumentId: store.state.Project.InstrumentName.value,
      projectNumber: store.state.Project.instrProject,
    });
    console.log(res, '搜索仪器测试记录表头返回');
    let arr = [];
    if (res.status == 0) {
      res.instrumentFields.forEach((item, index) => {
        if (item.isValid == true) {
          arr.push(item);
        }
      });
      console.log(arr, 'arr');
      fieldList.value = arr;
      fieldLists.value = arr;
      tableHeader.value = arr;
    }
  }
};
// 计算属性未删除的数据
const comTableData = computed(() => {
  let arr = [];
  demo1.tableData.forEach((item) => {
    if (item.isdelete != true) {
      arr.push(item);
    }
  });
  return arr;
});
// 搜索触发事件
const searchEvent2 = async () => {
  page5.currentPage = 1;
  await getInstrTestHeadRes();
  await getInstrTestDataRes();
};
// 获取仪器数据
const getInstrTestDataRes = async () => {
  if (!!store.state.Project.InstrumentName.value) {
    const res = await http.post('/instrument/fetchRecord-instrumentNew', {
      InstrumentId: store.state.Project.InstrumentName.value,
      projectNumber: store.state.Project.instrProject,
      page: page5.currentPage,
      size: page5.pageSize,
    });
    console.log(res, '搜索仪器测试记录数据返回');
    if (res.status == 0) {
      tableData.value = JSON.parse(JSON.stringify(res.instruRecords));
      let arr = [];
      res.instruRecords.forEach((item) => {
        item.Record_data['_id'] = item._id;
        item.Record_data['isvalid'] = item.isvalid;
        item.Record_data['isdelete'] = item.isdelete;
        arr.push(item.Record_data);
      });
      console.log(arr, '处理后的数据');
      demo1.tableData = arr;
      page5.totalResult = res.totalCount;
    } else if (res.status == 2) {
      tableData.value = [];
      demo1.tableData = [];
      page5.totalResult = 0;
    }
  }
};
//复选框数组
var selectedRows = reactive([]);
const checkedArr = ref([]);
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [],
  filterName: '',
  loading: false,
});
const tableHeader = ref([]);
const tableData = ref([]);
// 编辑状态
const editStatus = ref(false);
// 点击在线编辑
const editTableClick = () => {
  editStatus.value = true;
};
// 点击取消编辑
const cancelEditClick = () => {
  editStatus.value = false;
  getInstrTestDataRes();
};
// 点击保存
const upDataTableClick = async () => {
  tableData.value.forEach((item, index) => {
    const upitem = demo1.tableData.find((items) => items._id == item._id);
    let obj = JSON.parse(JSON.stringify(upitem));
    delete obj._id;
    delete obj.isvalid;
    delete obj.isdelete;
    item.Record_data = obj;
    // upitem.Record_data = item;
  });
  console.log(tableData.value, '发送的数据');
  const res = await http.post('/instrument/updateRecord-instrumentNew', {
    Recordlist: tableData.value,
  });
  console.log(res, '编辑返回');

  editStatus.value = false;
  await getInstrTestDataRes();
};
// 点击重置
// const reset = async () => {
//   demo1.filterName = '';
//   page5.currentPage = 1;
//   page5.pageSize = 10;
//   await getInstrTestHeadRes();
//   await getInstrTestDataRes();
// };
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 1,
});
//导入
let dialogShow1 = ref(false);
const uploadData = () => {
  console.log(123333);
  dialogShow1.value = true;
};
// 点击导出
const exportChange = async () => {
  if (checkedArr.value.length == 0) {
    message({
      message: '请选择导出的数据',
      showClose: true,
      type: 'error',
    });
    return;
  }
  const res = await http.post('/upload/export-instrumentRecordnew', {
    InstrumentId: store.state.Project.InstrumentName.value,
    projectNumber: store.state.Project.instrProject,
    idList: checkedArr.value,
  });
  console.log(res, '导出仪器测试记录返回');
  xlsx.writeFileXLSX(res.xlsxData, `仪器测试记录 ${dayjs().format('YYYYMMDD')}.xlsx`);
};

// 点击下载模板
const downloadXlsx = async () => {
  const res = await http.post('/upload/download-instrumentRecordTemplate', {
    InstrumentId: store.state.Project.InstrumentName.value,
    projectNumber: store.state.Project.instrProject,
  });
  console.log(res, '下载模板返回');
  if (res.status == 0) {
    xlsx.writeFileXLSX(res.xlsxData, `仪器测试记录 ${dayjs().format('YYYYMMDD')}.xlsx`);
  } else {
    message({
      showClose: true,
      message: res.message,
      type: 'error',
    });
  }
};
// 上传事件
const uploadFile = async (params) => {
  // if (activeIndex.value == 1) {
  console.log(params);
  const file = params.file;
  const form = new FormData();
  form.append('file', file);
  console.log(store.state.Project.InstrumentName.value, 'store.state.Project.InstrumentName.value');
  form.append('InstrumentId', store.state.Project.InstrumentName.value);
  if (store.state.Project.instrProject) {
    form.append('projectNumber', store.state.Project.instrProject);
    console.log(store.state.Project.instrProject, '选了项目');
  } else {
    console.log('没选项目');
  }
  // console.log(store.state.Project, '选了项目');
  // return;
  console.log(params);
  await http
    .post('/upload/upload-instrumentRecordnew', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async (res) => {
      console.log(res);
      if (res.status == 0) {
        dialogShow1.value = false;
        getInstrTestDataRes();
        message({
          showClose: true,
          message: '上传数据成功',
          type: 'success',
        });
      } else if (res.status == 1) {
        message({
          showClose: true,
          message: '上传文件为空，请重新上传',
          type: 'error',
        });
      } else if (res.status == 2) {
        message({
          showClose: true,
          message: res.message,
          type: 'error',
        });
      }
    });
};
// 标记失效有效
// const upStatusClick = async (row, val) => {
//   console.log(row, val);
//   const updatedItem1 = demo1.tableData.find((item) => item._id === row._id);
//   updatedItem1.isvalid = val;
//   // demo1.tableData[index].isvalid = val;
//   const updatedItem = tableData.value.find((item) => item._id === row._id);
//   if (updatedItem) {
//     updatedItem.isvalid = val;
//   }
//   const res = await http.post('/instrument/updateRecord-instrumentNew', {
//     Recordlist: [updatedItem],
//   });
//   console.log(res, '编辑状态返回');
//   await getInstrTestDataRes();
// };
const editStatusRow = ref(null);
const upStatusClick = async (row, val) => {
  editStatusRow.value = row;
  if (val == false) {
    markShow.value = true;
  } else {
    nomarkShow.value = true;
  }
  // const res = await http.post('/instrument/update-instrumentType', {
  //   instrType_name: row.instrType_name,
  //   _id: row._id,
  //   instrType_status: row.instrType_status == '有效' ? '失效' : '有效',
  // });
  // console.log(res, '改变状态返回');
  // getInstTypeList();
};
const OkChange = async () => {
  const updatedItem1 = demo1.tableData.find((item) => item._id === editStatusRow.value._id);
  updatedItem1.isvalid = false;
  // demo1.tableData[index].isvalid = val;
  const updatedItem = tableData.value.find((item) => item._id === editStatusRow.value._id);
  if (updatedItem) {
    updatedItem.isvalid = false;
  }
  const res = await http.post('/instrument/updateRecord-instrumentNew', {
    Recordlist: [updatedItem],
  });
  // const res = await http.post('/instrument/update-instrumentType', {
  //   instrType_name: editStatusRow.value.instrType_name,
  //   _id: editStatusRow.value._id,
  //   instrType_status: '失效',
  // });
  console.log(res, '改变状态返回');
  await getInstrTestDataRes();
  markShow.value = false;
};
const OkNoMarkChange = async () => {
  const updatedItem1 = demo1.tableData.find((item) => item._id === editStatusRow.value._id);
  updatedItem1.isvalid = true;
  // demo1.tableData[index].isvalid = val;
  const updatedItem = tableData.value.find((item) => item._id === editStatusRow.value._id);
  if (updatedItem) {
    updatedItem.isvalid = true;
  }
  const res = await http.post('/instrument/updateRecord-instrumentNew', {
    Recordlist: [updatedItem],
  });
  // const res = await http.post('/instrument/update-instrumentType', {
  //   instrType_name: editStatusRow.value.instrType_name,
  //   _id: editStatusRow.value._id,
  //   instrType_status: '失效',
  // });
  console.log(res, '改变状态返回');
  await getInstrTestDataRes();
  nomarkShow.value = false;
};
const markShow = ref(false);
const nomarkShow = ref(false);
// 点击删除数据
const delTableClick = (row) => {
  const updatedItem1 = demo1.tableData.find((item) => item._id === row._id);
  updatedItem1.isdelete = true;
  // demo1.tableData[index].isvalid = val;
  const updatedItem = tableData.value.find((item) => item._id === row._id);
  if (updatedItem) {
    updatedItem.isdelete = true;
  }
};
// 表头字段设置对话框
const SetTableHeader = ref(false);
// 点击设置表头字段
const SetHeader = () => {
  fieldList.value = JSON.parse(JSON.stringify(fieldLists.value));
  if (datafieldLists.value.length == 0) {
    datafieldList.value = JSON.parse(JSON.stringify(fieldList.value));
  } else {
    datafieldList.value = JSON.parse(JSON.stringify(datafieldLists.value));
  }
  SetTableHeader.value = true;
};
// 左侧表头多选框选中
const leftCheckChange = (i) => {
  if (fieldList.value[i].isValid) {
    datafieldList.value.push(fieldList.value[i]);
  } else {
    //如果是false，从dragdemo中删除
    datafieldList.value = datafieldList.value.filter((item) => item.fieldName !== fieldList.value[i].fieldName);
  }
  console.log(fieldList.value);
  console.log(datafieldList.value);
};
// 置顶/删除方法
const backTop = (e, val) => {
  console.log('val :>> ', e);
  let indexItem = -1;
  datafieldList.value.forEach((item, index) => {
    if (item.fieldName == e.fieldName) {
      indexItem = index;
    }
  });
  console.log('下标是： :>> ', indexItem);
  // 置顶方法
  if (val == 'top') {
    if (indexItem != 0) {
      const itemToMove = datafieldList.value.splice(indexItem, 1)[0];
      datafieldList.value.unshift(itemToMove);
    }
  } else {
    // 删除方法
    datafieldList.value.splice(indexItem, 1);
    const index = fieldList.value.findIndex((item) => item.fieldName === e.fieldName);
    fieldList.value[index].isValid = false;
  }

  console.log(' datafieldList.value; :>> ', datafieldList.value);
};
// 取消设置表头字段
const cancelHeadfieldClick = () => {
  SetTableHeader.value = false;
};
// 保存设置表头字段
const upHeadfieldClick = () => {
  datafieldLists.value = JSON.parse(JSON.stringify(datafieldList.value));
  fieldLists.value = JSON.parse(JSON.stringify(fieldList.value));
  let arr = [];
  console.log(fieldList.value);
  datafieldList.value.forEach((item, index) => {
    if (item.isValid == true) {
      arr.push(item);
    }
  });
  console.log(arr, 'arr');
  tableHeader.value = arr;
  const $table = xTable.value;
  $table.refreshColumn();
  console.log('强制刷新表头');
  SetTableHeader.value = false;
};
// 复选框点击事件
const selectChangeEvent1 = (row, checked) => {
  // console.log('最新:', row);

  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records;
  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid);
  } else {
    let index = checkedArr.value.indexOf(row.rowid);
    checkedArr.value.splice(index, 1);
  }
  console.log('最新:', checkedArr.value);
};
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  console.log(checked ? '所有勾选事件' : '所有取消事件', records);
  selectedRows = records;
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id);
      }
    }
    console.log('最新:', checkedArr.value);
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = [];

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id);
    });

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item);
    });
    console.log('最新:', checkedArr.value);
  }
};
watch(
  () => store.state.Project.InstrumentName,
  async () => {
    console.log(store.state.Project.InstrumentName, 'store.state.Project.InstrumentName改变了');
    await getInstrTestHeadRes();
    await getInstrTestDataRes();
  },
  { deep: true },
);
watch(
  () => store.state.Project.instrProject,
  async () => {
    console.log(store.state.Project.instrProject, 'store.state.Project.instrProject改变了');
    await getInstrTestHeadRes();
    await getInstrTestDataRes();
  },
  { deep: true },
);
</script>

<style lang="less" scoped>
@import url('@/style/views/InstrumentMangement/InstrumentTestRecord.less');
</style>
