<template>
  <div class="help">
    <div class="help_top">
      <div class="top_ques" @click="helpIndexChange(0)" :class="{ top_click: helpIndex == 0 }">常见问题</div>
      <div class="top_operate" @click="helpIndexChange(1)" :class="{ top_click: helpIndex == 1 }">操作指引</div>
    </div>
    <div class="help_main">
      <div class="main_left">
        <div class="left_inp" v-if="helpIndex == 0">
          <el-input type="text" v-model="quesInp" placeholder="请输入常见问题" :suffix-icon="Search"></el-input>
        </div>
        <div class="left_nav" v-if="helpIndex == 1">
          <div
            class="nav_item"
            @click="operateNavChange(index)"
            :class="{ nav_click: operateNavClick == index }"
            v-for="(item, index) in operateNav"
            :key="index">
            {{ item }}
          </div>
        </div>
        <div class="main_feedback" @click="feedbackChange">
          <img class="feedback_img" src="https://newstore.vynior.com/cems_web/%E8%B7%AF%E5%BE%84%2032338%402x.png" alt="" />
          <span>我要反馈</span>
        </div>
      </div>
      <div class="main_box question_scroll" v-if="helpIndex == 0 && helpViewIndex == 0" @scroll="quesScroll">
        <div class="box_item">
          <div class="item_title">Q1.如何为用户申请注册新的账号？</div>
          <div class="item_main">
            <div>
              请使用超级管理员的账号登录系统后，前往「用户&权限管理」—「用户信息」页面，点击“新建用户”按钮，完善必填的用户基本信息后建立新账号。
            </div>
          </div>
        </div>
        <div class="box_item">
          <div class="item_title">Q2.如何设置系统中各个角色的权限？</div>
          <div class="item_main">
            <div>目前CEMS系统包含十四种用户角色，如下图：</div>
            <img class="img1" src="https://newstore.vynior.com/cems_web/image%402x.png" alt="" />
            <div>
              超级管理员默认拥有所有权限，且可以对系统内所有角色进行权限管理。具体方式为前往「用户&权限管理」—「权限信息」页面，点击对应「角色」的按钮，选择「模块」，勾选或取消勾选相应「功能权限」。
            </div>
          </div>
        </div>
        <div class="box_item box_item_bot">
          <div class="item_title">Q3.对各个板块中的列表表单可以进行哪些基础操作？</div>
          <div class="item_main">
            <div>
              1、基础样式调整：包括列宽调整、每列内容的升序/降序、隐藏/展开、固定/取消固定<br />
              2、表头字段设置：包括预设字段启用/禁用+顺序调整、添加自定义扩展字段<br />
              3、列表信息的筛选、搜索、翻页
            </div>
          </div>
        </div>
      </div>
      <div class="main_right" v-if="helpIndex == 0 && helpViewIndex == 0">
        <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="scrollChange(0)">Q1.如何为用户申请注册新的账号？</div>
        <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="scrollChange(1)">Q2.如何设置系统中各个角色的权限？</div>
        <div class="right_item" :class="{ right_click: operateScrollIndex == 2 }" @click="scrollChange(2)">
          Q3.对各个板块中的列表表单可以进行哪些基础操作？
        </div>
      </div>
      <div class="main_operete_info" v-if="helpIndex == 1 && helpViewIndex == 1">
        <div class="info" v-if="operateNavClick == 0">
          <div class="info_item">
            <div class="item_h">一、CEMS产品简介</div>
            <div class="item_title">1.CEMS是什么？</div>
            <div class="item_main">
              <div>
                CEMS系统是一款便捷易用、安全可靠的数字化管理系统。其主要特点包括：<br />
                【全流程】综合了在消费者研究与功效评测实践中常用流程，包括志愿者招募、项目流程跟踪、仪器数据录入、报告撰写、专家评估等环节。<br />
                【自动化】根据检测项目的特性不同，将过程中使用到的不同检测仪器接入，进而进行数据分析与可视化。根据不同机构需求，可自定义表头、字段、状态、标签、表单模板，并随着过程中的录入，自动生成最终实验报告初步框架，大幅提高相关评测与研究效率。<br />
                【安全性】支持加密托管或私有化部署方式，数据加密、独立存储，安全可靠。同时以加密
              </div>
            </div>
          </div>
          <div class="info_item info_items">
            <div class="item_title">2.产品矩阵</div>
            <div class="item_main">
              <img class="img1" src="https://newstore.vynior.com/cems_web/%E5%9B%BE%E7%89%871.png" alt="" />
            </div>
          </div>
        </div>
        <div class="info" v-if="operateNavClick == 1" @scroll="operateScroll">
          <div class="info_item">
            <div class="item_h">二、项目管理</div>
            <div class="item_title">1.新建项目</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「项目列表」，进入项目列表页，<span class="main_bold">点击页面右上角「+新建项目」</span
                >，填写项目概览，即项目基本信息与招募信息，<span class="main-bold">点击「完成」</span>，建立项目。<br />
                项目状态包括五种：未开始、招募中、进行中、已完成、已停止。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-project/1.png" alt="" class="img2" />
              <div>
                一个项目可以绑定多个“项目产品”及其“测试部位”，点击加号即增加“项目产品”及其“测试部位”，点击减号即删除“项目产品”及其“测试部位”。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-project/2.png" alt="" class="img3" />
              <div>“测试年龄限制”可通过选择两个数值来设定限制年龄范围，最小年龄数值不能小于18。</div>
              <img src="https://newstore.vynior.com/cems_web/help-project/3.png" alt="" class="img4" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">2.项目组成部分</div>
            <div class="item_main">
              <div>
                在项目列表页，选择某一个项目操作栏中的「查看详情」按钮，可编辑项目的详细信息，包括以下几项组成部分：<br />
                【概览】包含项目基本信息、项目招募信息、测试入选/排除条件、测试计划、测试信息收集等内容的编辑<br />
                【成员】设置参与该项目的成员信息，包括其姓名、账号邮箱、角色<br />
                【样品】绑定该项目检测的样品信息<br />
                【方案】包含项目概述、检测目的、检测流程及环节、打卡机制、知情同意书等内容的编辑<br />
                【指标&仪器】设置该项目的检测指标，绑定对应的检测仪器及其基本信息，查看该仪器数据及其统计分析结果<br />
                【志愿者】查看该项目的招募完成情况，
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-project/4.png" alt="" class="img5" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">3.发布项目</div>
            <div class="item_main">
              <div>
                可通过两种方式发布项目：<br />
                1）在项目列表页，<span class="main_bold">点击某一个项目操作栏中的「发布」按钮</span>，进行发布；<br />
                2）进入某一个项目的「概览」页面，<span class="main_nold">点击左上角的「未发布招募信息，去发布」按钮</span>，进行发布；<br />
                <span class="main_tip">注意</span
                >：需完成项目「概览」和「方案」页面上的必填信息，才可进行项目发布。项目一旦发布，志愿者即可在小程序端查看该项目的招募信息。
              </div>
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">4.撰写项目报告</div>
            <div class="item_main">
              <div>
                在项目列表页，选择某一个项目操作栏中的「查看详情」按钮，进入该项目的详细编辑页面，点击顶部导航栏中的「报告」按钮，进行项目报告撰写。<br />
                首次建立报告，需<span class="main_bold">输入报告编号 — 输入报告名称 — 选择模板 — 点击「下一步」按钮</span>
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-project/5.png" alt="" class="img6" />
              <div>
                <span class="main_tip">项目-「报告」中的“选择模板”功能正在开发中，敬请期待。</span><br />
                <span class="main_bold"> 拖动组件库中的组件至页面中间的编辑区域进行编辑</span>，组件库中分为三种类型的组件：<br />
                【默认组件】系统默认预设展示的组件，基于报告结构的必要元素，包括封面、声明、目录、结论、附件板块。每个板块拖拽至编辑区域后，富文本框内有部分标题字段，用户可增删改查富文本框内的其他内容。<br />
                【单元组件】基于项目内其他三级导航中的信息，包括项目方案、样品、志愿者、检测指标板块。每个板块拖拽至编辑区域后，自动生成该板块中已有内容，用户可增删改查富文本框内的已有内容。<br />
                【自定义组件】拖拽至编辑区域后，样式为空状态的富文本框，用户可随意编辑自定义信息。<br />
                完成编辑后，<span class="main_bold">点击「保存」按钮</span>，保存项目报告。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-project/6.png" alt="" class="img7" />
              <div>
                <span class="main_bold">点击右上角的「编辑」按钮</span>，可随时再次开始撰写修改项目报告；<br />
                若确定该项目报告已最终完成，<span class="main_bold">点击右上角的「申请审批」按钮</span>，提交报告，等待被审批。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-project/7.png" alt="" class="img8" />
              <div>该项目报告导出可选择导出为PDF或Word两种文件格式。</div>
              <img src="https://newstore.vynior.com/cems_web/help-project/8.png" alt="" class="img9" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">5.报告审批</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「报告审批」，进入项目报告审批页面。<span class="main_bold">点击某一条“待审批”状态的报告的「审批签章」按钮</span
                >，进入报告审批页面，预览报告信息。若确认报告已通过审核，<span class="main_bold">点击页面右上角的「审批签章」按钮</span
                >，跳出弹窗后，<span class="main_bold">点击审批结果中的「审批通过」</span
                >。若该报告未通过审核，可在报告预览界面右下角，输入相关评论后，<span class="main-bold">点击「添加评论」按钮</span
                >发送文字信息至上方评论区域，且所有参与者可见。<br />
                <span class="main_tip">注意</span
                >：系统预设只有超级管理员、该项目的项目负责人以及在该项目「成员」页面上，被项目负责人设置为“报告审批员”角色的用户，才有权在「报告审批」页内查看到该项
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-project/9.png" alt="" class="img10" />
              <img src="https://newstore.vynior.com/cems_web/help-project/10.png" alt="" class="img11" />
              <img src="https://newstore.vynior.com/cems_web/help-project/11.png" alt="" class="img12" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">6.报告及模板管理</div>
            <div class="item_main">
              <div><span class="main_tip">报告管理及报告模板设置功能正在开发中，敬请期待。</span></div>
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">7.查看项目排期表</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「项目排期表」，<span class="main_bold">选择指定的年月份</span
                >，查看当月的项目排期与分布情况。鼠标悬停在某段时间条上时，可查看该项目具体的开始时间和结束时间。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-project/12.png" alt="" class="img13" />
            </div>
          </div>
          <div class="info_item info_items">
            <div class="item_title">8.项目分享</div>
            <div class="item_main">
              <div>在项目列表的操作栏点击「分享」，将项目分享给系统内的其他账号，被分享的用户仅有查看项目的权限。</div>
              <img src="https://newstore.vynior.com/cems_web/help-project/13.png" alt="" class="img14" />
              <div>选择左侧菜单栏「项目分享」，查看分享给我/我分享的项目详情。</div>
              <img src="https://newstore.vynior.com/cems_web/help-project/14.png" alt="" class="img15" />
            </div>
          </div>
        </div>
        <div class="info" v-if="operateNavClick == 2" @scroll="twoScroll">
          <div class="info_item">
            <div class="item_h">三、志愿者管理</div>
            <div class="item_title">1. 新建志愿者</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「志愿者库」，<span class="main_bold">点击页面右上角「+新建志愿者」按钮</span
                >，跳出弹窗后，填写完善必填信息后，点击「确定」按钮，即可完成新建志愿者。<br />
                志愿者招募状态包括五种：已报名、已初审、测试中、已完成、已退出
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/1.png" alt="" class="img16" />
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/2.png" alt="" class="img17" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">2. 志愿者招募&初审</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「志愿者招募管理」，输入指定的一个项目编号或项目名称，查看已报名的志愿者信息（志愿者报名可通过微信小程序端或者网页端由工作人员进行「+线上报名」）。可通过点击操作栏内的「通过初审」或者「拒绝」来评估志愿者是否符合招募标准。<br />
                <span class="main_tip">注意：</span
                >顶部导航栏选择了一个指定的项目编号/名称后，可在其旁边查看到该项目预计招募人数，以及切换「正在招募中」和「招募完成」的整体项目招募状态。<br />
                <span class="main_tip">注意：</span>一旦切换志愿者状态后，不可逆向切换也不可撤回操作！
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/3.png" alt="" class="img18" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">3. 志愿者测试排期</div>
            <div class="item_main">
              <div>选择左侧菜单栏「志愿者排期表」，选择某一个项目，在四级导航栏中选择第几次到访后，<br /></div>
              <div class="main_bold">1）选择自动排期</div>
              <div>
                在列表最前端勾选部分或全选志愿者，点击页面右上角“批量排期”按钮，跳出弹窗后，设定到访日期、当日测试开始时间、当日测试结束时间、到访间隔时间段，点击“确认”按钮，自动排期完成。<br />
                <span class="main_tip">注意：</span
                >在“志愿者招募管理”页面里，志愿者状态由“已报名”切换为“已初审”的志愿者才会呈现在“志愿者排期表”的列表中；未选择某一个项目前，表单是空状态。<br />
                <span class="main_tip">注意：</span>（测试结束时间-测试开始时间）/间隔时间段≤选择的人数
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/4.png" alt="" class="img19" />
              <div class="main_bold">2）选择手动排期</div>
              <div>可通过<span class="main_bold">点击操作栏内的「编辑」及「保存」按钮</span>，手动调整单个志愿者的到访日期及到访时间。</div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/5.png" alt="" class="img20" />
              <div>
                系统排期完成后，通过拨打联系电话或其他媒介通知志愿者其对应的到访日期及到访时间，若确认时间合适，可<span class="main_bold"
                  >点击操作栏内的「确认」</span
                >，确认该位志愿者的排期时间，即排期完成。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/6.png" alt="" class="img21" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">4. 志愿者线下签到&分组&测试记录</div>
            <div class="item_main">
              <div class="main_bold">1）志愿者线下签到</div>
              <div>
                选择左侧菜单栏「志愿者招募管理」，选择某一个项目，若该项目中的志愿者通过了线下的复审，则可<span class="main_bold"
                  >点击操作栏内的「通过复审」</span
                >，将其状态由“已初审”切换为“测试中”。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/7.png" alt="" class="img22" />
              <div>
                <span class="main_tip">注意：</span
                >若该项目在「方案」中上传过电子知情同意书，那么通过复审的志愿者可在其小程序端点击「我的项目」页面，选择该项目，<span class="main_bold"
                  >点击「签署知情同意书」按钮</span
                >，阅读完知情同意书并用手指绘写电子签名，点击「提交」按钮上传知情同意书至系统。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/8.png" alt="" class="img23" />
              <div class="main_bold">2）志愿者分组</div>
              <div>
                选择左侧菜单栏「测试记录」，选择某一个项目，在四级导航栏中选择第几次到访后，<span class="main_bold">点击操作栏内的「分组」按钮</span
                >，进行志愿者们的分组。<br />
                <span class="main_tip">注意：</span>关于分组的下拉组名选项，可在「志愿者管理」—「自定义设置」中新建及编辑组别信息。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/9.png" alt="" class="img24" />
              <div class="main_bold">3）志愿者测试环节确认及测试记录<br /></div>
              <div>
                选择左侧菜单栏「测试记录」，选择某一个项目，在四级导航栏中选择第几次到访后，对应环节的负责人有权限切换志愿者是否已完成该环节。如图，若六项环节全部被勾选，则表示该名志愿者该次的线下测试完成。<br />
                <span class="main_tip">注意：</span>各个环节的表头名称及其对应的负责人信息是在新建项目时的<span class="main_bold">「方案」</span
                >页面进行设置的。<br />
                <span class="main_tip">注意：</span>非该环节的负责人仅可查看，但无法勾选确认其他环节的志愿者完成情况，默认为灰色的勾选框。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/10.png" alt="" class="img25" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">5. 志愿者打卡记录</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「打卡记录」，选择某一个项目，可概览参与本次项目且已通过复审，状态为“测试中”的所有志愿者的打卡记录及打卡信息。绿色圈圈表示已打卡，红色圈圈表示未打卡，灰色圈圈表示待打卡，暂时未开通补打卡机制。<br />
                <span class="main_tip">注意：</span>表单表头的具体打卡日期、打卡频率是在新建项目时的<span class="main_bold">「方案」</span
                >页面进行设置的。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/11.png" alt="" class="img26" />
              <div>
                点击操作栏中“查看详情”按钮，可查看某一位志愿者的详细打卡记录信息，包括预览及下载打卡的图片或视频文件。<br />
                <span class="main_tip">注意：</span>该项目的打卡是否需要志愿者在小程序端上传打卡图片或者视频也是在新建项目时的<span class="main_bold"
                  >「方案」</span
                >页面进行设置的。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/12.png" alt="" class="img27" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">6. 志愿者小程序管理</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「小程序管理」，可查看该系统对应的小程序码，<span class="main_bold">点击「下载图片」按钮</span
                >，可将其下载至本地，便于分享至进行其他媒体平台。页面右侧部分，可通过滑动鼠标滚轮进行小程序界面的预览。<br />
                <span class="main_tip">注意：</span>仅可预览小程序端的首页和点击首页上每个项目卡片跳转后的该项目详情页。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/13.png" alt="" class="img28" />
            </div>
          </div>
          <div class="info_item info_items">
            <div class="item_title">7. 自定义设置志愿者组别及标签</div>
            <div class="item_main">
              <div>所有自定义设置功能正在开发中，敬请期待，下图为设计图示意。</div>
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/14.png" alt="" class="img29" />
              <img src="https://newstore.vynior.com/cems_web/help-volunteer/15.png" alt="" class="img30" />
            </div>
          </div>
        </div>
        <div class="info" v-if="operateNavClick == 3" @scroll="threeScroll">
          <div class="info_item">
            <div class="item_h">四、仪器管理</div>
            <div class="item_title">1. 新建仪器</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「仪器列表」，<span class="main_bold">点击页面右上角「+新建仪器」按钮</span
                >，跳出弹窗后，填写完善必填信息后，点击「确定」按钮，即可完成新建仪器。<br />
                仪器状态包括四种：正常、待维护、维护中、报废
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-inst/1.png" alt="" class="img31" />
              <img src="https://newstore.vynior.com/cems_web/help-inst/2.png" alt="" class="img32" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">2. 仪器测试记录</div>
            <div class="item_main">
              <div class="main_tip">
                目前开发了九种仪器（Vplus
                智能皮肤分析系统、MPA580、TiVi700、CM825、MX18、GL200、CL400、PH905、IDM800），其他仪器的表单正在开发中，敬请期待。
              </div>
              <div class="main_bold">1）查看仪器测试记录</div>
              <div>
                选择左侧菜单栏「仪器测试记录」，选择某一台仪器，可查看其对应的仪器测试记录。不同的仪器其对应的表单表头不同。工作人员可在搜索栏输入项目编号或用户姓名进行搜索。对于某一条无效或者无意义的数据可<span
                  class="main_bold"
                  >点击操作栏内的「标记无效」按钮</span
                >进行数据标记。<br />
                <span class="main_tip">注意：</span>所有仪器数据不可修改和删除，以确保数据的真实性。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-inst/3.png" alt="" class="img33" />
              <img src="https://newstore.vynior.com/cems_web/help-inst/4.png" alt="" class="img34" />
              <div class="main_bold">2）导入仪器测试记录</div>
              <div>
                仪器测试记录导入分为两种方式：<br />
                【仪器数据自动批量同步】对于由肤焕科技研发生产的面部、头皮、手持式检测仪器等以及接口清晰的第三方检测仪器，工作人员在使用仪器时，其数据就自动批量同步至系统中。<br />
                【仪器数据上传入口】对于接口不清晰或难以自动化的第三方检测仪器，可<span class="main_bold">点击页面右上角的「上传数据」按钮</span
                >，跳出弹窗后，若为初次使用，<span class="main_bold">请先「下载模版」</span>，在本地填写或者录入仪器数据信息，然后<span
                  class="main_bold"
                  >点击「上传文件」按钮</span
                >上传填写好的文件至系统中。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-inst/5.png" alt="" class="img35" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">3. 仪器数据统计&分析</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「仪器数据统计&分析」，首先选择某一台仪器，然后搜索该仪器中的某个项目，进而可对与该项目相关的仪器数据进行五种数据统计分析，包括描述分析、相关分析、回归分析、方差分析、t检验。对应的操作方式为，将左侧区域的参数点击或拖拽至右侧区域，然后<span
                  class="main_bold"
                  >点击页面右上角的「开始分析」按钮</span
                >，进行分析。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-inst/6.png" alt="" class="img36" />
              <div>对于分析结果，可进行编辑和删除操作，以及将其导出Word至本地。</div>
              <img src="https://newstore.vynior.com/cems_web/help-inst/7.png" alt="" class="img37" />
            </div>
          </div>
          <div class="info_item info_items">
            <div class="item_title">4. 自定义设置仪器组别</div>
            <div class="item_main">
              <div>选择左侧菜单栏「自定义设置」，用户可新建组别满足使用需求。</div>
              <img src="https://newstore.vynior.com/cems_web/help-inst/8.png" alt="" class="img38" />
            </div>
          </div>
        </div>
        <div class="info" v-if="operateNavClick == 4" @scroll="fourScroll">
          <div class="info_item">
            <div class="item_h">五、问卷管理</div>
            <div class="item_title">1. 创建问卷</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「问卷列表」，<span class="main_bold">点击页面右上角「+新建问卷」按钮</span
                >，选择一种方式创建问卷；以空白问卷为例，来看一下具体的创建方法吧。<br />
                1）新建问卷<br />
                点击页面右上角<span class="main_bold">「+新建问卷」，选择「空白问卷」</span
                >，跳出弹窗后，填写该问卷绑定的项目信息（可通过输入项目编号及名称进行搜索），点击「确定」;
              </div>
              <div class="main_tip">新建问卷之「模板创建」和「文本导入」两种方式正在开发中，敬请期待。</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/1.png" alt="" class="img39" />
              <div class="">2）添加问卷标题和问卷说明</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/2.png" alt="" class="img40" />
              <div>
                3）编辑题目标题及选项<br />
                点击左侧区域的题型或将题型拖入中间区域，输入题目标题及选项信息。在右侧区域可以进行不同题型的题目设置和整体的问卷设置。<br />
              </div>
              <div class="main_tip">左侧区域的大纲和题库正在开发中，敬请期待。</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/3.png" alt="" class="img41" />
              <div>对于每道题目，可以进行顺序调整、上移、下移、复制、收藏到题库、删除的操作。题目中的选项内容可输入文字或图片两种形式。</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/4.png" alt="" class="img42" />
              <div>4）保存问卷 点击页面左上角的「退出」按钮，即可退出对该问卷的编辑模式，问卷已编辑内容会实时保存。</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/5.png" alt="" class="img43" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">2. 题型说明</div>
            <div class="item_main">
              <div>1）单选题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/6.png" alt="" class="img44" />
              <div>2）多选题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/7.png" alt="" class="img45" />
              <div>3）下拉选择题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/8.png" alt="" class="img46" />
              <div>4）单项填空题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/9.png" alt="" class="img47" />
              <div>5）多项填空题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/10.png" alt="" class="img48" />
              <div>6）矩阵单选题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/11.png" alt="" class="img49" />
              <div>7）矩阵多选题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/12.png" alt="" class="img50" />
              <div>8）矩阵量表题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/13.png" alt="" class="img51" />
              <div>9）评分单选题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/14.png" alt="" class="img52" />
              <div>10）评分多选题</div>
              <img src="https://newstore.vynior.com/cems_web/help-question/15.png" alt="" class="img53" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">3. 预览&发布&分享问卷</div>
            <div class="item_main">
              <div>
                1）预览问卷<br />
                <span class="main_bold">点击页面右上角的「预览」按钮</span
                >，可跳转至预览页面，包括手机端和电脑端的页面预览情况。再次点击页面右上角的「关闭预览」按钮，可退出预览模式。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-question/16.png" alt="" class="img54" />
              <img src="https://newstore.vynior.com/cems_web/help-question/17.png" alt="" class="img55" />
              <img src="https://newstore.vynior.com/cems_web/help-question/18.png" alt="" class="img56" />
              <div>
                2）发布问卷<br />
                <span class="main_bold">点击页面右上角的「分享问卷」按钮</span>，跳出弹窗后，确认是否发布该问卷。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-question/19.png" alt="" class="img57" />
              <div>
                3）分享问卷 <br />
                问卷发布后，可通过分享问卷二维码或问卷链接给用户进行答题。<br />
                <span class="main_tip">注意：</span>问卷一旦发布后，不可再编辑问卷！<br />
                <span class="main_tip">注意：</span>问卷一旦发布后，链接与二维码始终不变。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-question/20.png" alt="" class="img58" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">4. 查看问卷默认报告</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「问卷列表」，<span class="main_bold">点击列表某一份问卷操作栏内的「统计&分析」按钮</span
                >，进入该份问卷的「默认报告」板块。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-question/21.png" alt="" class="img59" />
              <div>
                默认报告包括每道题的题目信息、选项信息、有效填写人数、选项答题小计及比例情况。<span class="main_bold">点击每个表单右下方的按钮</span
                >，可切换五种图表类型，包括表格、饼状图、柱状图、条形图、折线图。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-question/22.png" alt="" class="img60" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">5. 各题型报告展示样式</div>
            <div class="item_main">
              <img src="https://newstore.vynior.com/cems_web/help-question/23.png" alt="" class="img61" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">6. 问卷数据统计分析</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「问卷列表」，<span class="main_bold">点击列表某一份问卷操作栏内的「统计&分析」按钮</span
                >，进入该份问卷的「统计分析」板块。对于问卷数据，可进行五种数据统计分析，包括描述分析、相关分析、回归分析、方差分析、t检验。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-question/24.png" alt="" class="img62" />
              <div>
                具体操作方式为，在四级导航内选择一种分析方法，点击页面右上角的「新增分析」按钮，跳出弹窗后，根据操作提示进行分析项选择，点击「确定」按钮后，生成统计表单。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-question/25.png" alt="" class="img63" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">7. 问卷模板库</div>
            <div class="item_main">
              <div class="main_tip">问卷模板库相关页面正在开发中，敬请期待。</div>
            </div>
          </div>
        </div>
        <div class="info" v-if="operateNavClick == 5" @scroll="fiveScroll">
          <div class="info_item">
            <div class="item_h">六、样品管理</div>
            <div class="item_title">1. 新建样品</div>
            <div class="item_main">
              <div>
                样品管理员选择左侧菜单栏「样品列表」，<span class="main_bold">点击页面右上角「+新建样品」按钮</span
                >，跳出弹窗后，填写完善必填信息后，点击「确定」按钮，即可完成新建样品。<br />
                <span class="main_tip">注意：</span>新建样品时的「样品检测项目信息」，即该样品绑定的项目信息需要在新建项目时的<span class="main_bold"
                  >「样品」</span
                >页面进行设置。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/1.png" class="img64" alt="" />
              <img src="https://newstore.vynior.com/cems_web/help-sam/2.png" class="img65" alt="" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">2. 发放样品</div>
            <div class="item_main">
              <div>
                样品管理员选择左侧菜单栏「样品列表」，输入样品编号或名称来搜索指定的样品，<span class="main_bold">点击操作栏内的「发放」按钮</span
                >，跳出弹窗后，填入“发放对象”、“发放数量”进行一批样品的发放。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/3.png" alt="" class="img66" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">3. 新建样品流转单</div>
            <div class="item_main">
              <div>
                样品分发员从样品管理员处领取样品后，选择左侧菜单栏「样品流转记录」，<span class="main_bold">点击页面右上角「+新建流转单」按钮</span
                >，跳出弹窗后，填写完善必填信息后，点击「确定」按钮，即可完成新建流转单。<br />
                样品状态包括三种：待检、在检、检毕<br />
                <span class="main_tip">注意：</span
                >新建流转单时的「样品领样记录信息」和「样品归还记录信息」，根据样品管理员分发或者回收时跳出弹窗里输入的信息自动填入。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/4.png" alt="" class="img67" />
              <img src="https://newstore.vynior.com/cems_web/help-sam/5.png" alt="" class="img68" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">4. 某个项目内的样品流转</div>
            <div class="item_main">
              <div>
                先选择左侧菜单栏「样品流转记录」，然后在顶部导航栏选择「项目样品流转记录」，并在旁边输入项目编号或名称来搜索指定的某个项目。可在四级导航栏处查看与该项目绑定的样品名称及其编号。选择其中一种样品后，列表内可查看该批样品具体分发于参与该项目的所有志愿者的情况。<br />
                <span class="main_tip">注意：</span>表单内的“样品分发编号”根据“样品编号”及“样品数量”信息自动生成。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/6.png" alt="" class="img69" />
              <div>主要操作包括：<br /></div>
              <div class="main_bold">1）某个项目内的样品分发/批量分发<br /></div>
              <div>
                点击表单操作栏内的「分发样品」按钮或者页面右上角的「批量分发」按钮，跳出弹窗后，输入“样品初始重量”、“分发对象”、“分发日期”信息，进行项目内的样品分发。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/7.png" alt="" class="img70" />
              <div>
                <span class="main_tip">注意：</span
                >批量分发会随机匹配样品分发编号与分发对象（即已通过初审的志愿者名单），且“样品初始重量”、“分发数量”、“分发日期”的信息将会保持一致。<br />
              </div>
              <div class="main_bold">2）某个项目内的样品称重</div>
              <div>点击表单操作栏中的「查看详情」按钮，跳出弹窗后，可点击「新增称重」按钮，录入样品每次的重量变化。</div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/8.png" alt="" class="img71" />
              <div class="main_bold">3）某个项目内的样品留样</div>
              <div>点击表单操作栏中的「留样」按钮，跳出弹窗后，输入“留样原因（选填）”及“留样日期”信息，进行样品留样。</div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/9.png" alt="" class="img72" />
              <div class="main_bold">4）某个项目内的样品回收</div>
              <div>点击表单操作栏中的「回收样品」按钮，跳出弹窗后，输入“样品最终重量”及“回收日期”，进行样品回收。</div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/10.png" alt="" class="img73" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">5. 回收样品</div>
            <div class="item_main">
              <div>
                样品管理员选择左侧菜单栏「样品列表」，输入样品编号或名称来搜索指定的样品，<span class="main_bold">点击操作栏内的「回收」按钮</span
                >，跳出弹窗后，填入“回收对象”、“回收数量”进行一批样品的回收。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/11.png" alt="" class="img74" />
            </div>
          </div>
          <div class="info_item info_items">
            <div class="item_title">6. 自定义设置样品标签</div>
            <div class="item_main">
              <div>选择左侧菜单栏「自定义设置」，用户可新建标签满足使用需求。</div>
              <img src="https://newstore.vynior.com/cems_web/help-sam/12.png" alt="" class="img75" />
            </div>
          </div>
        </div>
        <div class="info" v-if="operateNavClick == 6" @scroll="sixScroll">
          <div class="info_item">
            <div class="item_h">七、专家管理</div>
            <div class="item_title">1. 新建专家</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「专家列表」，<span class="main_bold">点击页面右上角「+新建专家」按钮</span
                >，跳出弹窗后，填写完善必填信息后，点击「确定」按钮，即可完成新建专家。 专家状态包括两种：全职、兼职
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-expert/1.png" alt="" class="img76" />
              <img src="https://newstore.vynior.com/cems_web/help-expert/2.png" alt="" class="img77" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">2. 新建专家评估记录</div>
            <div class="item_main">
              <div class="main_tip">专家评估记录相关页面正在开发中，敬请期待。</div>
              <div>
                选择左侧菜单栏「专家评估记录」，<span class="main_bold">点击页面右上角「+新建记录」按钮</span
                >，跳出弹窗后，填写完善必填信息后，点击「确定」按钮，即可完成新建专家评估记录。<br />
                <span class="main_tip">注意：</span>输入该专家评估的问卷ID后，“问卷标题”、“对应项目编号”、“对应项目名称”等信息会自动填入。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-expert/3.png" alt="" class="img78" />
              <img src="https://newstore.vynior.com/cems_web/help-expert/4.png" alt="" class="img79" />
            </div>
          </div>
          <div class="info_item info_items">
            <div class="item_title">3. 自定义设置专家标签</div>
            <div class="item_main">
              <div>选择左侧菜单栏「自定义设置」，用户可新建标签满足使用需求。</div>
              <img src="https://newstore.vynior.com/cems_web/help-expert/5.png" alt="" class="img80" />
            </div>
          </div>
        </div>
        <div class="info" v-if="operateNavClick == 7" @scroll="sevenScroll">
          <div class="info_item">
            <div class="item_h">八、系统管理</div>
            <div class="item_title">1. 个人中心</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「个人中心」，可查看登录该系统的账号信息。可以在本页更改登录密码。<br />
                <span class="main_tip">注意：</span>除了密码之外的其他账号信息，请联系超级管理员进行修改！
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-personal/1.png" alt="" class="img81" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">2. 新建用户</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「用户&权限管理」— 「用户管理」，<span class="main_bold">点击页面右上角「+新建用户」按钮</span
                >，跳出弹窗后，填写完善必填信息后，点击「确定」按钮，即可完成新建用户。<br />
                用户状态分为两种：启用、禁用。<br />
                <span class="main_tip">注意：</span
                >禁用某位用户则该用户的账号不能再登录，但是所有数据会保留。删除某位用户则该用户的账号不能再登录，且所有数据会被清除，不可恢复。<br />
                <span class="main_tip">注意：</span>每位用户可以设置多种角色。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-personal/2.png" alt="" class="img82" />
              <img src="https://newstore.vynior.com/cems_web/help-personal/3.png" alt="" class="img83" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">3. 权限管理</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「用户&权限管理」—
                「权限管理」，选择某一个角色（如“项目负责人”），再选择一个功能模块（如“项目管理”），勾选具体对于细分功能模块的操作权限（如“查看”、“编辑”、“导出”等）。<br />
                <span class="main_tip">注意：</span>超级管理员默认拥有所有权限，且可以对系统内所有角色进行权限管理。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-personal/4.png" alt="" class="img84" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">4. 签章管理</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「签章管理」— 顶部导航栏「电子签章」，<span class="main_bold">点击「点击上传」按钮</span
                >，可以将电子签章录入至系统内，便于报告审核时使用。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-personal/5.png" alt="" class="img85" />
              <div>
                选择左侧菜单栏「签章管理」— 顶部导航栏「电子签名」，<span class="main_bold">点击「点击上传」按钮</span
                >，可以将电子签名录入至系统内，便于报告审核时确认使用。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-personal/6.png" alt="" class="img86" />
            </div>
          </div>
          <div class="info_item info_items">
            <div class="item_title">5. 操作日志</div>
            <div class="item_main">
              <div>
                选择左侧菜单栏「操作日志」，可查看用户账号下的“操作行为”、“操作模块”、“操作对象”等信息。<br />
                操作行为类型包括：一般操作、关键操作、敏感操作、异常操作<br />
                <span class="main_tip">注意：</span>超级管理员拥有查看所有账号的操作日志。其他角色仅能查看自己账号的操作日志。<br />
                <span class="main_tip">注意：</span>操作日志的内容不可编辑与删除！
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-personal/7.png" alt="" class="img87" />
            </div>
          </div>
        </div>
        <div class="info" v-if="operateNavClick == 8" @scroll="eightScroll">
          <div class="info_item">
            <div class="item_h">九、数据看板</div>
            <div class="item_title">1. 主页数据看板</div>
            <div class="item_main">
              <div>
                主页数据看板包括数据概览、数据趋势、我的项目三个部分，便于快速了解项目、志愿者、仪器、问卷、样品、专家的累计总数、新增数量及增长趋势，点击「全部项目」将跳转至项目管理的项目列表页面。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-data/1.png" alt="" class="img88" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">2. 项目数据看板</div>
            <div class="item_main">
              <div>
                项目数据看板由数据概览、项目排期表、我的项目组成，点击「查看完整排期表」将跳转至项目排期表页面，点击「全部项目」将跳转至项目列表页面。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-data/2.png" alt="" class="img89" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">3. 志愿者数据看板</div>
            <div class="item_main">
              <div>志愿者数据看板由数据概览、数据趋势、分布情况组成，可查看志愿者性别、年龄段、所在城市的分布情况。</div>
              <img src="https://newstore.vynior.com/cems_web/help-data/3.png" alt="" class="img90" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">4. 仪器数据看板</div>
            <div class="item_main">
              <div>仪器数据看板由数据概览、仪器历史测试量、数据分析组成，各个仪器的测试量一目了然。</div>
              <img src="https://newstore.vynior.com/cems_web/help-data/4.png" alt="" class="img91" />
            </div>
          </div>
        </div>
        <div class="info" v-if="operateNavClick == 9" @scroll="nineScroll">
          <div class="info_item">
            <div class="item_h">十、其他</div>
            <div class="item_title">1. 登录</div>
            <div class="item_main">
              <div>
                输入正确格式的账号和密码进行登录，账号格式为邮箱地址，注意：若输入密码出错达到5次，该账号当日不可再登录。<br />
                首次登录会发送验证码，输入验证码后会弹出弹窗询问“是否将本设备加入可信设备”，确认加入可信设备后在此后的登录中不用输入验证码。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-other/1.png" alt="" class="img92" />
              <div>
                若忘记密码，系统会发送6位数验证码至账户邮箱，输入后重新设置新密码。密码长度需为8-16位，不少于8位；<br />
                密码应至少包含数字、大小写字母及特殊字符中的两种。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-other/2.png" alt="" class="img93" />
              <div>在首次登录进入系统后，必须对分配的初始密码进行修改，后续可以在设置中修改密码。</div>
              <img src="https://newstore.vynior.com/cems_web/help-other/3.png" alt="" class="img94" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">2. 帮助中心</div>
            <div class="item_main">
              <div>点击右上角第一个按钮将跳转至帮助中心。</div>
              <img src="https://newstore.vynior.com/cems_web/help-other/4.png" alt="" class="img95" />
              <div>用户可以查看常见问题的解答，也可以点击「我要反馈」反馈操作系统中所遇到的问题。</div>
              <img src="https://newstore.vynior.com/cems_web/help-other/5.png" alt="" class="img96" />
              <img src="https://newstore.vynior.com/cems_web/help-other/6.png" alt="" class="img97" />
              <div>操作指引包括产品介绍、各个模块的功能说明，帮助用户快速上手。</div>
              <img src="https://newstore.vynior.com/cems_web/help-other/7.png" alt="" class="img98" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">3. 设置中心</div>
            <div class="item_main">
              <div>当用户角色是超级管理员时，点击右上角第二个按钮将跳转至系统管理-用户&权限管理页面。</div>
              <img src="https://newstore.vynior.com/cems_web/help-other/8.png" alt="" class="img99" />
              <div>当用户角色不是超级管理员时，点击右上角第二个按钮将跳转至系统管理-个人中心页面。</div>
              <img src="https://newstore.vynior.com/cems_web/help-other/9.png" alt="" class="img100" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">4. 消息通知</div>
            <div class="item_main">
              <div>
                点击右上角第三个按钮可查看消息通知。<br />
                （1）工作相关：①项目报告审核通过/拒绝的通知；②收到报告分享的通知；<br />
                （2）账号相关：个人中心相关的信息被管理员调整后的通知；<br />
                （3）系统相关：系统版本更新的通知。
              </div>
              <img src="https://newstore.vynior.com/cems_web/help-other/10.png" alt="" class="img101" />
            </div>
          </div>
          <div class="info_item">
            <div class="item_title">5. 我的账号</div>
            <div class="item_main">
              <div>点击右上角的头像可以查看账号姓名及邮箱信息，可跳转至「个人中心」及「退出登录」。</div>
              <img src="https://newstore.vynior.com/cems_web/help-other/11.png" alt="" class="img102" />
            </div>
          </div>
        </div>
      </div>
      <div class="main_right" v-if="helpIndex == 1 && helpViewIndex == 1">
        <div v-if="operateNavClick == 0">
          <div class="right_title">一、CEMS产品简介</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="oneScrollChange(0)">1. CEMS是什么？</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="oneScrollChange(1)">2. 产品矩阵</div>
        </div>
        <div v-if="operateNavClick == 1">
          <div class="right_title">二、项目管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="twoScrollChange(0)">1. 新建项目</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="twoScrollChange(1)">2. 项目组成部分</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 2 }" @click="twoScrollChange(2)">3. 发布项目</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 3 }" @click="twoScrollChange(3)">4. 撰写项目报告</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 4 }" @click="twoScrollChange(4)">5. 报告审批</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 5 }" @click="twoScrollChange(5)">6. 报告及模板管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 6 }" @click="twoScrollChange(6)">7. 查看项目排期表</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 7 }" @click="twoScrollChange(7)">8. 项目分享</div>
        </div>
        <div v-if="operateNavClick == 2">
          <div class="right_title">二、项目管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="threeScrollChange(0)">1. 新建志愿者</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="threeScrollChange(1)">2. 志愿者招募&初审</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 2 }" @click="threeScrollChange(2)">3. 志愿者测试排期</div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 3 }" @click="threeScrollChange(3)">1）选择自动排期</div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 4 }" @click="threeScrollChange(4)">2）选择手动排期</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 5 }" @click="threeScrollChange(5)">
            4. 志愿者线下签到&分组&测试记录
          </div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 6 }" @click="threeScrollChange(6)">1）志愿者线下签到</div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 7 }" @click="threeScrollChange(7)">2）志愿者分组</div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 8 }" @click="threeScrollChange(8)">
            3）志愿者测试环节确认及测试记录
          </div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 9 }" @click="threeScrollChange(9)">5. 志愿者打卡记录</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 10 }" @click="threeScrollChange(10)">6. 志愿者小程序管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 11 }" @click="threeScrollChange(11)">
            7. 自定义设置志愿者组别及标签
          </div>
        </div>
        <div v-if="operateNavClick == 3">
          <div class="right_title">四、仪器管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="fourScrollChange(0)">1. 新建仪器</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="fourScrollChange(1)">2. 仪器测试记录</div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 2 }" @click="fourScrollChange(2)">1）查看仪器测试记录</div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 3 }" @click="fourScrollChange(3)">2）导入仪器测试记录</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 4 }" @click="fourScrollChange(4)">3. 仪器数据统计&分析</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 5 }" @click="fourScrollChange(5)">4. 自定义设置仪器组别</div>
        </div>
        <div v-if="operateNavClick == 4">
          <div class="right_title">五、问卷管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="fiveScrollChange(0)">1. 创建问卷</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="fiveScrollChange(1)">2. 题型说明</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 2 }" @click="fiveScrollChange(2)">3. 预览&发布&分享问卷</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 3 }" @click="fiveScrollChange(3)">4. 查看问卷默认报告</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 4 }" @click="fiveScrollChange(4)">5. 各题型报告展示样式</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 5 }" @click="fiveScrollChange(5)">6. 问卷数据统计分析</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 6 }" @click="fiveScrollChange(6)">7. 问卷模板库</div>
        </div>
        <div v-if="operateNavClick == 5">
          <div class="right_title">六、样品管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="sixScrollChange(0)">1. 新建样品</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="sixScrollChange(1)">2. 发放样品</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 2 }" @click="sixScrollChange(2)">3. 新建样品流转单</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 3 }" @click="sixScrollChange(3)">4. 某个项目内的样品流转</div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 4 }" @click="sixScrollChange(4)">
            1）某个项目内的样品分发/批量分发
          </div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 5 }" @click="sixScrollChange(5)">
            2）某个项目内的样品称重
          </div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 6 }" @click="sixScrollChange(6)">
            3）某个项目内的样品留样
          </div>
          <div class="right_item right_items" :class="{ right_click: operateScrollIndex == 7 }" @click="sixScrollChange(7)">
            4）某个项目内的样品回收
          </div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 8 }" @click="sixScrollChange(8)">5. 回收样品</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 9 }" @click="sixScrollChange(9)">6. 自定义设置样品标签</div>
        </div>
        <div v-if="operateNavClick == 6">
          <div class="right_title">七、专家管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="sevenScrollChange(0)">1. 新建专家</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="sevenScrollChange(1)">2. 新建专家评估记录</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 2 }" @click="sevenScrollChange(2)">3. 自定义设置专家标签</div>
        </div>
        <div v-if="operateNavClick == 7">
          <div class="right_title">八、系统管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="eightScrollChange(0)">1. 个人中心</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="eightScrollChange(1)">2. 新建用户</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 2 }" @click="eightScrollChange(2)">3. 权限管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 3 }" @click="eightScrollChange(3)">4. 签章管理</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 4 }" @click="eightScrollChange(4)">5. 操作日志</div>
        </div>
        <div v-if="operateNavClick == 8">
          <div class="right_title">九、数据看板</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="nineScrollChange(0)">1. 主页数据看板</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="nineScrollChange(1)">2. 项目数据看板</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 2 }" @click="nineScrollChange(2)">3. 志愿者数据看板</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 3 }" @click="nineScrollChange(3)">4. 仪器数据看板</div>
        </div>
        <div v-if="operateNavClick == 9">
          <div class="right_title">十、其他</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 0 }" @click="tenScrollChange(0)">1. 登录</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 1 }" @click="tenScrollChange(1)">2. 帮助中心</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 2 }" @click="tenScrollChange(2)">3. 设置中心</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 3 }" @click="tenScrollChange(3)">4. 消息通知</div>
          <div class="right_item" :class="{ right_click: operateScrollIndex == 4 }" @click="tenScrollChange(4)">5. 我的账号</div>
        </div>
      </div>
      <div class="main_feed" v-if="helpViewIndex == 2">
        <div class="feed_tit">问题反馈</div>
        <div class="feed_info">
          <div class="info_name">
            <span class="name_x">*</span>
            <div class="name_label">姓名</div>
            <div class="name_val"><el-input v-model="userName"></el-input></div>
          </div>
          <div class="info_name info_email">
            <span class="name_x">*</span>
            <div class="name_label">邮箱</div>
            <div class="name_val"><el-input v-model="userEmail"></el-input></div>
          </div>
          <div class="info_name info_com">
            <span class="name_x">*</span>
            <div class="name_label">公司名称</div>
            <div class="name_val"><el-input v-model="userCom"></el-input></div>
          </div>
        </div>
        <div class="feed_title">
          <span class="title_x">*</span>
          <div>问题描述</div>
          <!-- <div class="wangwditor"></div> -->
        </div>
        <div id="editor—wrapper">
          <Toolbar style="border-bottom: 1px solid #ccc" :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode" />
          <Editor
            style="height: 500px; overflow-y: hidden"
            v-model="valueHtml"
            :defaultConfig="editorConfig"
            :mode="mode"
            @onCreated="handleCreated" />
        </div>
        <div class="upload_feed">
          <div class="upload_but" @click="uploadClick">发送</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, onBeforeUnmount, shallowRef, reactive } from 'vue';
import { Search } from '@element-plus/icons-vue';
import '@wangeditor/editor/dist/css/style.css'; // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import { DomEditor } from '@wangeditor/editor';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
const userName = ref();
const userEmail = ref();
const userCom = ref();
onMounted(() => {
  userName.value = localStorage.getItem('userName');
  userEmail.value = localStorage.getItem('userEmail');
  userCom.value = localStorage.getItem('company');
});
// 展示常见问题还是操作指引
const helpIndex = ref(1);
// 中间显示的组件
const helpViewIndex = ref(1);
// 点击切换显示
const helpIndexChange = (val) => {
  helpIndex.value = val;
  helpViewIndex.value = val;
};
// 操作指引目录
const operateNav = ref([
  '一、CEMS产品简介',
  '二、项目管理',
  '三、志愿者管理',
  '四、仪器管理',
  '五、问卷管理',
  '六、样品管理',
  '七、专家管理',
  '八、系统管理',
  '九、数据看板',
  '十、其他',
]);
// 选中展示的目录
const operateNavClick = ref(0);
// 选中展示操作指引
const operateNavChange = (val) => {
  if (operateNavClick.value == val) {
    return;
  }
  operateNavClick.value = val;
  helpViewIndex.value = 1;
  operateScrollIndex.value = 0;
};
// 点击我要反馈
const feedbackChange = () => {
  helpViewIndex.value = 2;
  valueHtml.value = '';
};
// 操作指引二级导航
const operateScrollIndex = ref(0);
// 点击产品简介二级导航
const oneScrollChange = (val) => {
  operateScrollIndex.value = val;
};
const divData = reactive({
  hei: 0,
});
// 项目管理触发滚动
const operateScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  divData.hei = event.srcElement.scrollHeight;
  if (times.value == true) {
    return;
  }
  let str = event.srcElement.scrollTop / event.srcElement.scrollHeight;
  if (str >= 0 && str <= 0.2) {
    operateScrollIndex.value = 0;
  } else if (str > 0.2 && str < 0.25) {
    operateScrollIndex.value = 1;
  } else if (str >= 0.25 && str < 0.28) {
    operateScrollIndex.value = 2;
  } else if (str >= 0.28 && str < 0.57) {
    operateScrollIndex.value = 3;
  } else if (str >= 0.57 && str < 0.77) {
    operateScrollIndex.value = 4;
  } else if (str >= 0.77 && str < 0.79) {
    operateScrollIndex.value = 5;
  } else if (str >= 0.79 && str < 0.86) {
    operateScrollIndex.value = 6;
  } else if (str > 0.86) {
    operateScrollIndex.value = 7;
  }
};
const twoScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  divData.hei = event.srcElement.scrollHeight;
  if (times.value == true) {
    return;
  }
  const scrollValue = event.srcElement.scrollTop / event.srcElement.scrollHeight;
  const indexToRatio = {
    0: 0.112,
    1: 0.216,
    2: 0.298,
    3: 0.372,
    4: 0.38,
    5: 0.513,
    6: 0.557,
    7: 0.643,
    8: 0.805,
    9: 0.86,
  };
  let operateScrollIndexValue = 0;
  for (const index in indexToRatio) {
    if (scrollValue >= indexToRatio[index]) {
      operateScrollIndexValue = Number(index) + 1;
    } else {
      break;
    }
  }
  operateScrollIndex.value = operateScrollIndexValue;
};
const threeScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  divData.hei = event.srcElement.scrollHeight;
  if (times.value == true) {
    return;
  }
  const scrollValue = event.srcElement.scrollTop / event.srcElement.scrollHeight;
  const indexToRatio = {
    0: 0.202,
    1: 0.225,
    2: 0.476,
    3: 0.596,
    4: 0.769,
  };
  let operateScrollIndexValue = 0;
  for (const index in indexToRatio) {
    if (scrollValue >= indexToRatio[index]) {
      operateScrollIndexValue = Number(index) + 1;
    } else {
      break;
    }
  }
  operateScrollIndex.value = operateScrollIndexValue;
};
const fourScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  divData.hei = event.srcElement.scrollHeight;
  if (times.value == true) {
    return;
  }
  const scrollValue = event.srcElement.scrollTop / event.srcElement.scrollHeight;
  const indexToRatio = {
    0: 0.185,
    1: 0.446,
    2: 0.665,
    3: 0.75,
    4: 0.9,
  };
  let operateScrollIndexValue = 0;
  for (const index in indexToRatio) {
    if (scrollValue >= indexToRatio[index]) {
      operateScrollIndexValue = Number(index) + 1;
    } else {
      break;
    }
  }
  operateScrollIndex.value = operateScrollIndexValue;
};
const fiveScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  divData.hei = event.srcElement.scrollHeight;
  if (times.value == true) {
    return;
  }
  const scrollValue = event.srcElement.scrollTop / event.srcElement.scrollHeight;
  const indexToRatio = {
    0: 0.143,
    1: 0.245,
    2: 0.39,
    3: 0.495,
    4: 0.571,
    5: 0.66,
    6: 0.73,
    7: 0.804,
    8: 0.835,
  };
  let operateScrollIndexValue = 0;
  for (const index in indexToRatio) {
    if (scrollValue >= indexToRatio[index]) {
      operateScrollIndexValue = Number(index) + 1;
    } else {
      break;
    }
  }
  operateScrollIndex.value = operateScrollIndexValue;
};
const sixScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  divData.hei = event.srcElement.scrollHeight;
  if (times.value == true) {
    return;
  }
  const scrollValue = event.srcElement.scrollTop / event.srcElement.scrollHeight;
  const indexToRatio = {
    0: 0.363,
    1: 0.591,
  };
  let operateScrollIndexValue = 0;
  for (const index in indexToRatio) {
    if (scrollValue >= indexToRatio[index]) {
      operateScrollIndexValue = Number(index) + 1;
    } else {
      break;
    }
  }
  operateScrollIndex.value = operateScrollIndexValue;
};
const sevenScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  divData.hei = event.srcElement.scrollHeight;
  if (times.value == true) {
    return;
  }
  const scrollValue = event.srcElement.scrollTop / event.srcElement.scrollHeight;
  const indexToRatio = {
    0: 0.112,
    1: 0.36,
    2: 0.534,
    3: 0.73,
  };
  let operateScrollIndexValue = 0;
  for (const index in indexToRatio) {
    if (scrollValue >= indexToRatio[index]) {
      operateScrollIndexValue = Number(index) + 1;
    } else {
      break;
    }
  }
  operateScrollIndex.value = operateScrollIndexValue;
};
const eightScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  divData.hei = event.srcElement.scrollHeight;
  if (times.value == true) {
    return;
  }
  const scrollValue = event.srcElement.scrollTop / event.srcElement.scrollHeight;
  const indexToRatio = {
    0: 0.264,
    1: 0.51,
    2: 0.604,
  };
  let operateScrollIndexValue = 0;
  for (const index in indexToRatio) {
    if (scrollValue >= indexToRatio[index]) {
      operateScrollIndexValue = Number(index) + 1;
    } else {
      break;
    }
  }
  operateScrollIndex.value = operateScrollIndexValue;
};
const nineScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  divData.hei = event.srcElement.scrollHeight;
  if (times.value == true) {
    return;
  }
  const scrollValue = event.srcElement.scrollTop / event.srcElement.scrollHeight;
  const indexToRatio = {
    0: 0.293,
    1: 0.583,
    2: 0.776,
    3: 0.831,
  };
  let operateScrollIndexValue = 0;
  for (const index in indexToRatio) {
    if (scrollValue >= indexToRatio[index]) {
      operateScrollIndexValue = Number(index) + 1;
    } else {
      break;
    }
  }
  operateScrollIndex.value = operateScrollIndexValue;
};
let time;
const times = ref(false);
const scrollToPosition = (val) => {
  const parent = document.querySelector(`.info`);
  parent.scrollTo({
    top: val,
    behavior: 'smooth',
  });
};
// 点击项目管理二级导航
const twoScrollChange = async (val) => {
  operateScrollIndex.value = val;
  if (!divData.hei || divData.hei == 0) {
    console.log('没有初始值');
    scrollToPosition(1);
  }
  clearInterval(time);
  times.value = true;
  setTimeout(() => {
    const indexToRatio = {
      1: 0.208,
      2: 0.256,
      3: 0.282,
      4: 0.574,
      5: 0.776,
      6: 0.791,
      7: 0.866,
    };
    const top = divData.hei ? Number(divData.hei) * indexToRatio[val] : 0;
    scrollToPosition(top);
  }, 100);
  time = setInterval(() => {
    times.value = false;
  }, 1000);
};
// 点击志愿者管理二级导航
const threeScrollChange = async (val) => {
  operateScrollIndex.value = val;
  if (!divData.hei || divData.hei == 0) {
    console.log('没有初始值');
    scrollToPosition(1);
  }
  clearInterval(time);
  times.value = true;
  setTimeout(() => {
    const indexToRatio = {
      1: 0.112,
      2: 0.205,
      3: 0.216,
      4: 0.298,
      5: 0.372,
      6: 0.38,
      7: 0.513,
      8: 0.557,
      9: 0.643,
      10: 0.805,
      11: 0.867,
    };
    const top = divData.hei ? Number(divData.hei) * indexToRatio[val] : 0;
    scrollToPosition(top);
  }, 100);
  time = setInterval(() => {
    times.value = false;
  }, 1000);
};
// 点击仪器管理二级导航
const fourScrollChange = async (val) => {
  operateScrollIndex.value = val;
  if (!divData.hei || divData.hei == 0) {
    console.log('没有初始值');
    scrollToPosition(1);
  }
  clearInterval(time);
  times.value = true;
  setTimeout(() => {
    const indexToRatio = {
      1: 0.202,
      2: 0.225,
      3: 0.476,
      4: 0.596,
      5: 0.769,
    };
    const top = divData.hei ? Number(divData.hei) * indexToRatio[val] : 0;
    scrollToPosition(top);
  }, 100);
  time = setInterval(() => {
    times.value = false;
  }, 1000);
};
// 点击问卷管理二级导航
const fiveScrollChange = async (val) => {
  operateScrollIndex.value = val;
  if (!divData.hei || divData.hei == 0) {
    console.log('没有初始值');
    scrollToPosition(1);
  }
  clearInterval(time);
  times.value = true;
  setTimeout(() => {
    const indexToRatio = {
      1: 0.185,
      2: 0.446,
      3: 0.665,
      4: 0.75,
      5: 0.9,
      6: 0.9,
    };
    const top = divData.hei ? Number(divData.hei) * indexToRatio[val] : 0;
    scrollToPosition(top);
  }, 100);
  time = setInterval(() => {
    times.value = false;
  }, 1000);
};
// 点击样品管理二级导航
const sixScrollChange = async (val) => {
  operateScrollIndex.value = val;
  if (!divData.hei || divData.hei == 0) {
    console.log('没有初始值');
    scrollToPosition(1);
  }
  clearInterval(time);
  times.value = true;
  setTimeout(() => {
    const indexToRatio = {
      1: 0.143,
      2: 0.245,
      3: 0.39,
      4: 0.495,
      5: 0.571,
      6: 0.66,
      7: 0.73,
      8: 0.804,
      9: 0.835,
    };
    const top = divData.hei ? Number(divData.hei) * indexToRatio[val] : 0;
    scrollToPosition(top);
  }, 100);
  time = setInterval(() => {
    times.value = false;
  }, 1000);
};
// 点击专家管理二级导航
const sevenScrollChange = async (val) => {
  operateScrollIndex.value = val;
  if (!divData.hei || divData.hei == 0) {
    console.log('没有初始值');
    scrollToPosition(1);
  }
  clearInterval(time);
  times.value = true;
  setTimeout(() => {
    const indexToRatio = {
      1: 0.363,
      2: 0.591,
    };
    const top = divData.hei ? Number(divData.hei) * indexToRatio[val] : 0;
    scrollToPosition(top);
  }, 100);
  time = setInterval(() => {
    times.value = false;
  }, 1000);
};
// 点击系统管理二级导航
const eightScrollChange = async (val) => {
  operateScrollIndex.value = val;
  if (!divData.hei || divData.hei == 0) {
    console.log('没有初始值');
    scrollToPosition(1);
  }
  clearInterval(time);
  times.value = true;
  setTimeout(() => {
    const indexToRatio = {
      1: 0.112,
      2: 0.36,
      3: 0.534,
      4: 0.73,
    };
    const top = divData.hei ? Number(divData.hei) * indexToRatio[val] : 0;
    scrollToPosition(top);
  }, 100);
  time = setInterval(() => {
    times.value = false;
  }, 1000);
};
// 点击系统管理二级导航
const nineScrollChange = async (val) => {
  operateScrollIndex.value = val;
  if (!divData.hei || divData.hei == 0) {
    console.log('没有初始值');
    scrollToPosition(1);
  }
  clearInterval(time);
  times.value = true;
  setTimeout(() => {
    const indexToRatio = {
      1: 0.264,
      2: 0.51,
      3: 0.604,
      0: 0.293,
    };
    const top = divData.hei ? Number(divData.hei) * indexToRatio[val] : 0;
    scrollToPosition(top);
  }, 100);
  time = setInterval(() => {
    times.value = false;
  }, 1000);
};
// 点击系统管理二级导航
const tenScrollChange = async (val) => {
  operateScrollIndex.value = val;
  if (!divData.hei || divData.hei == 0) {
    console.log('没有初始值');
    scrollToPosition(1);
  }
  clearInterval(time);
  times.value = true;
  setTimeout(() => {
    const indexToRatio = {
      1: 0.293,
      2: 0.583,
      3: 0.774,
      4: 0.831,
    };
    const top = divData.hei ? Number(divData.hei) * indexToRatio[val] : 0;
    scrollToPosition(top);
  }, 100);
  time = setInterval(() => {
    times.value = false;
  }, 1000);
};
// 常见问题输入框
const quesInp = ref();
// 点击问卷目录
const scrollChange = (val) => {
  operateScrollIndex.value = val;
};
// 富文本
const editorRef = shallowRef(); //shallowRef
const valueHtml = ref('<p></p>');
// const toolbarConfig = {};
const toolbarConfig = {
  toolbarKeys: [
    // 'bold',
    // 'underline',
    // 'italic',
    // 'through',
    // 'code',
    // 'sub',
    // 'sup',
    // 'clearStyle',
    // 'color',
    // 'bgColor',
    // 'fontSize',
    // 'fontFamily',
    // 'indent',
    // 'delIndent',
    // 'justifyLeft',
    // 'justifyRight',
    // 'justifyCenter',
    // 'justifyJustify',
    // 'lineHeight',
    'insertImage',
    // 'deleteImage',
    // 'editImage',
    // 'viewImageLink',
    // 'imageWidth30',
    // 'imageWidth50',
    // 'imageWidth100',
    // 'divider',
    // 'emotion',
    'insertLink',
    // 'editLink',
    // 'unLink',
    // 'viewLink',
    // 'codeBlock',
    // 'blockquote',
    // 'headerSelect',
    // 'header1',
    // 'header2',
    // 'header3',
    // 'header4',
    // 'header5',
    // 'todo',
    // 'redo',
    // 'undo',
    // 'fullScreen',
    // 'enter',
    // 'bulletedList',
    // 'numberedList',
    // 'insertTable',
    // 'deleteTable',
    // 'insertTableRow',
    // 'deleteTableRow',
    // 'insertTableCol',
    // 'deleteTableCol',
    // 'tableHeader',
    // 'tableFullWidth',
    // 'insertVideo',
    // 'uploadVideo',
    // 'editVideoSize',
    // 'uploadImage',
    // 'codeSelectLang',
  ],
};
const editorConfig = { placeholder: '请输入问题描述，您也可以上传图片或附件' };
onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});
const handleCreated = (editor) => {
  editorRef.value = editor; // 记录 editor 实例，重要！
};
const mode = ref('default');
// 点击发送反馈邮件
const uploadClick = async () => {
  const res = await http.post('/auth/feedback', {
    name: userName.value,
    email: userEmail.value,
    company: userCom.value,
    content: valueHtml.value,
  });
  console.log(res, '发送反馈邮件反馈值');
  if (res.status == 0) {
    message({});
  }
};
onMounted(() => {
  // console.log(toolbarConfig);
  // console.log(editorRef.value.getConfig());
  // console.log(editorRef.value.getAllMenuKeys());
  // const editor = editorRef.value;
  // console.log(editor);
  // const toolbar = DomEditor.getToolbar(editor);
  // console.log(toolbar);
  // const curToolbarConfig = toolbar.getConfig();
  // console.log(curToolbarConfig.toolbarKeys); // 当前菜单排序和分组
});
// 问题触发滚动事件
const quesScroll = (event) => {
  console.log(event);
  console.log(event.srcElement.scrollTop, '滚动元素顶部的垂直偏移量');
  console.log(event.srcElement.scrollHeight, '滚动元素内容的总高度');
  console.log(event.srcElement.clientHeight, '滚动元素可见区域的高度');
  // console.log(val.scrollTop);
};
</script>
<style lang="less" scoped>
@import url('@/style/views/HelpCenter/HelpCenter.less');
</style>
