<template>
  <!-- 设置新密码页面 -->
  <div id="set_newPwd" v-if="is_setPwd">
    <div class="title">设置新密码</div>
    <div class="content">
      <div class="content-item">
        <el-input
          :prefix-icon="Lock"
          @input="pwd_verify"
          :class="is_pwd_verify ? 'error_input' : ''"
          v-model="new_pwd1"
          type="password"
          show-password
          placeholder="请填写新密码" />
        <span v-if="is_pwd_verify" class="error-hint">{{ first_pwd_hint }}</span>
        <span v-else class="pwd-length-hint">密码长度为8-16位，不少于8位;应至少包含数字、大小写字母及特殊字符中的两种</span>
      </div>
      <div class="content-item">
        <el-input
          :prefix-icon="Lock"
          @input="sec_pwd_verify"
          :class="is_pwd_verify2 ? 'error_input' : ''"
          v-model="new_pwd2"
          type="password"
          show-password
          placeholder="请再次输入密码" />
        <span v-if="is_pwd_verify2" class="error-hint">{{ sec_pwd_hint }}</span>
      </div>
      <div class="submit" @click="submit">
        <el-button type="primary">确认</el-button>
      </div>
      <div class="back" @click="back">返回</div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { Message, Lock } from '@element-plus/icons-vue';
import { message } from '@/utils/resetMessage';
import router from '@/router';
import http from '@/utils/http';

// 设置新密码
const is_setPwd = ref(true);
const new_pwd1 = ref('');
const new_pwd2 = ref('');

const back = () => {
  router.go(-1);
};

// 确认设置新密码，接口修改密码
const submit = async () => {
  pwd_verify();
  sec_pwd_verify();
  // 校验密码强度
  let judgeFn = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&*()_+|{}:"?]{8,16}$/);
  // 根据newpwd的数据长度来判断,0就不判断;
  if (new_pwd1.value !== '' && new_pwd2.value !== '' && new_pwd2.value === new_pwd1.value) {
    if (new_pwd1.value.length != 0) {
      if (!judgeFn.test(new_pwd1.value)) {
        // message({
        //   type: 'warning',
        //   message: '密码强度不符合要求',
        //   showClose: true,
        // });
        console.log('1');
        is_pwd_verify.value = true;
        is_hint.value = true;
        first_pwd_hint.value = '密码强度不符合要求';
        is_pwd_verify2.value = true;
        sec_pwd_hint.value = '';
      } else {
        const res = await http.post('/user/resetPassword-user', {
          _id: localStorage.getItem('_id'),
          password: new_pwd1.value,
        });
        console.log(res);
        if (res.status === 0) {
          localStorage.removeItem('_id');
          router.push('/');
          message({
            type: 'success',
            message: '密码修改成功，请重新登录',
            showClose: true,
          });
        } else {
          message({
            type: 'error',
            message: '密码修改失败',
            showClose: true,
          });
        }
      }
    }
  }
};

const is_hint = ref(false);

const is_pwd_verify = ref(false);
const first_pwd_hint = ref('新密码不能为空');
const pwd_verify = () => {
  let judgeFn = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&/*()_+|{}:"?]{8,16}$/);
  if (new_pwd1.value === '') {
    is_pwd_verify.value = true;
    is_hint.value = true;
  } else if (!judgeFn.test(new_pwd1.value)) {
    is_pwd_verify.value = true;
    is_hint.value = true;
    first_pwd_hint.value = '密码强度不符合要求';
  } else {
    is_pwd_verify.value = false;
    is_hint.value = false;
  }
};
const is_pwd_verify2 = ref(false);
const sec_pwd_hint = ref('两次密码输入不一致');
const sec_pwd_verify = () => {
  if (new_pwd2.value === '') {
    is_pwd_verify2.value = true;
    sec_pwd_hint.value = '确认密码不能为空';
  } else if (new_pwd2.value === new_pwd1.value) {
    is_pwd_verify2.value = false;
  } else {
    is_pwd_verify2.value = true;
    sec_pwd_hint.value = '两次密码输入不一致';
  }
};
</script>

<style lang="less">
@import url('@/style/views/LoginModules/SetNewpwd.less');
</style>
