<template>
  <div
    class="Header"
    :class="
      route.name == 'CreatedQuestionnaire' ||
      route.name == 'TextImport' ||
      route.name == 'ShareQuestion' ||
      route.name == 'EditQuestion' ||
      route.name == 'H5QuestionPreview' ||
      route.name == 'HelpCenter' ||
      route.name == 'WebQuestionPreview'
        ? 'createdHeader'
        : ''
    ">
    <div
      class="HeaderBox"
      v-if="
        route.name != 'CreatedQuestionnaire' &&
        route.name != 'TextImport' &&
        route.name != 'ShareQuestion' &&
        route.name != 'EditQuestion' &&
        route.name != 'H5QuestionPreview' &&
        route.name != 'WebQuestionPreview' &&
        route.name != 'HelpCenter'
      ">
      <div
        class="Title"
        :class="{
          titleBorder:
            route.name === 'VolunteerData' ||
            route.name === 'InstrumentPage' ||
            route.name === 'ProjectMember' ||
            route.name === 'ProjectPlan' ||
            route.name === 'ProjectDetails' ||
            route.name === 'RecruitVolunteer' ||
            route.name === 'TestRecords' ||
            route.name === 'VolunteerSchedule' ||
            route.name === 'ClockinView' ||
            route.name === 'ProjectDocument' ||
            route.name === 'ProjectSample' ||
            route.name === 'ProjectReport' ||
            route.name === 'ProjectQuestion' ||
            route.name === 'InstrumentStatisticalAnalysis' ||
            route.name === 'ShareVolunteerData' ||
            route.name === 'ShareInstrumentPage' ||
            route.name === 'ShareMember' ||
            route.name === 'SharePlan' ||
            route.name === 'ShareDetails' ||
            route.name === 'ShareDocument' ||
            route.name === 'ShareSample' ||
            route.name === 'ShareReport' ||
            route.name === 'ProjectShareQuestion' ||
            route.name == 'ShareMe' ||
            route.name == 'MyShare' ||
            route.name == 'Project_sharing' ||
            route.name == 'InstrumentTestRecord',
        }"
        @click="RefreshPage(store.state.Project.CurrentProjectName)"
        style="cursor: pointer">
        {{ store.state.Project.CurrentProjectName }}
      </div>
      <!-- 用户&权限管理 -->
      <div class="user" v-if="route.name == 'UserPower' || route.name == 'UserInfo' || route.name == 'PowerMange'">
        <div class="user_info" :class="UserInfo === 0 ? 'user_click' : ' '" @click="UserIndexChange(0)">用户信息</div>
        <div class="power" :class="UserInfo === 1 ? 'user_click' : ' '" @click="UserIndexChange(1)">权限管理</div>
      </div>
      <!-- 项目分享 -->
      <div class="share" v-if="route.name == 'ShareMe' || route.name == 'MyShare' || route.name == 'Project_sharing'">
        <div class="shareType">
          <div :class="route.name == 'ShareMe' ? 'active' : 'shareMe'" @click="toMe(ShareMe)">分享给我</div>
          <div class="myShare" :class="route.name == 'MyShare' ? 'active' : ''" @click="toShare(MyShare)">我分享的</div>
        </div>
      </div>
      <!-- 项目招募管理 -->
      <div
        class="Project aaa"
        v-if="
          route.name === 'VolunteerData' ||
          route.name === 'InstrumentPage' ||
          route.name === 'ProjectMember' ||
          route.name === 'ProjectPlan' ||
          route.name === 'ProjectDetails' ||
          route.name === 'RecruitVolunteer' ||
          route.name === 'TestRecords' ||
          route.name === 'VolunteerSchedule' ||
          route.name === 'ClockinView' ||
          route.name === 'ProjectDocument' ||
          route.name === 'ProjectSample' ||
          route.name === 'ProjectReport' ||
          route.name === 'ProjectQuestion'
        ">
        <el-select v-model="projectArrValue" class="Project_select" placeholder="请选择项目" size="large">
          <el-option v-for="item in projectArr[0]" :key="item" :label="item" :value="item" />
        </el-select>
        <div
          class="Project_title_if"
          v-if="
            route.name === 'VolunteerData' ||
            route.name === 'InstrumentPage' ||
            route.name === 'ProjectPlan' ||
            route.name === 'ProjectDetails' ||
            route.name === 'ProjectMember' ||
            route.name === 'ProjectDocument' ||
            route.name === 'ProjectSample' ||
            route.name === 'ProjectReport' ||
            route.name === 'ProjectQuestion'
          ">
          <div
            class="Project_title"
            @click="TitleValueChange(nav)"
            v-for="nav in projectTitleArr"
            :key="nav.nav_name"
            :class="nav.path_name === route.name ? 'Project_titles' : ''">
            <span>
              {{ nav.nav_name }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="Project Project123"
        v-if="
          route.name === 'ShareVolunteerData' ||
          route.name === 'ShareInstrumentPage' ||
          route.name === 'ShareMember' ||
          route.name === 'SharePlan' ||
          route.name === 'ShareDetails' ||
          route.name === 'ShareDocument' ||
          route.name === 'ShareSample' ||
          route.name === 'ShareReport' ||
          route.name === 'ProjectShareQuestion'
        ">
        <!-- <el-select v-show="false" v-model="shareProjectArrValue" disabled class="Project_select" placeholder="请选择项目" size="large">
          <el-option v-for="item in shareProjectArr" :key="item.label" :label="item.label" :value="item.value" />
        </el-select> -->
        <div class="Project_select1">
          <span>{{ shareName }}</span>
        </div>
        <div
          class="Project_title_if"
          v-if="
            route.name === 'ShareVolunteerData' ||
            route.name === 'ShareInstrumentPage' ||
            route.name === 'SharePlan' ||
            route.name === 'ShareDetails' ||
            route.name === 'ShareMember' ||
            route.name === 'ShareDocument' ||
            route.name === 'ShareSample' ||
            route.name === 'ShareReport' ||
            route.name === 'ProjectShareQuestion'
          ">
          <div
            class="Project_title"
            @click="ShareTitleValueChange(nav)"
            v-for="nav in shareProjectTitleArr"
            :key="nav.nav_name"
            :class="nav.path_name === route.name ? 'Project_titles' : ''">
            <span>
              {{ nav.nav_name }}
            </span>
          </div>
        </div>
      </div>
      <!-- 问卷列表统计分析 -->
      <div class="questionList" v-if="route.name == 'QuestionStatisticAnalysis' || route.name == 'QuestionReport'">
        <el-select v-model="questionArrIndex" @change="questionArrIndexChange" class="question_select" placeholder="请选择问卷" size="large">
          <el-option v-for="item in questionArr" :key="item.label" :label="item.label" :value="item.value" />
        </el-select>
        <div
          class="question_report"
          @click="quesReportChange('QuestionReport')"
          :class="route.name == 'QuestionReport' ? 'question_report_click' : ''">
          默认报告
        </div>
        <div
          class="question_report"
          @click="quesReportChange('QuestionStatisticAnalysis')"
          :class="route.name == 'QuestionStatisticAnalysis' ? 'question_report_click' : ''">
          统计分析
        </div>
      </div>
      <!-- //! 仪器下拉框 -->
      <div class="Project" v-if="route.name === 'InstrumentTestRecord'">
        <el-select
          v-model="Oneinstrument"
          filterable="true"
          class="Project_select"
          placeholder="请输入仪器编号或名称进行搜索"
          size="large"
          @change="getName">
          <el-option v-for="item in Instrument_complete" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <el-select
          v-if="!!Oneinstrument"
          v-model="selectInstrProject"
          class="instr_pro"
          placeholder="请选择"
          size="large"
          @change="selectInstrProjectChange"
          filterable>
          <el-option v-for="item in selectInstrProjectArr" :key="item.projectName" :label="item.projectName" :value="item.projectId" />
        </el-select>
        <div
          class="Project_title_if"
          v-if="
            route.name === 'VolunteerData' ||
            route.name === 'InstrumentPage' ||
            route.name === 'ProjectPlan' ||
            route.name === 'ProjectDetails' ||
            route.name === 'ProjectMember' ||
            route.name === 'ProjectDocument' ||
            route.name === 'ProjectSample'
          ">
          <div
            class="Project_title"
            @click="TitleValueChange(nav)"
            v-for="nav in projectTitleArr"
            :key="nav.nav_name"
            :class="nav.path_name === route.name ? 'Project_titles' : ''">
            <span>
              {{ nav.nav_name }}
            </span>
          </div>
        </div>
      </div>

      <!-- 仪器 数据统计分析 下拉框 -->
      <div class="Project" v-if="route.name == 'InstrumentStatisticalAnalysis'">
        <el-select
          v-model="Oneinstrument"
          filterable="true"
          class="Project_select"
          placeholder="请输入仪器编号或名称进行搜索"
          size="large"
          @change="getName">
          <el-option v-for="item in DataStatisticalAnalysisInstrument_complete" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <div
          class="Project_title_if"
          v-if="
            route.name === 'VolunteerData' ||
            route.name === 'InstrumentPage' ||
            route.name === 'ProjectPlan' ||
            route.name === 'ProjectDetails' ||
            route.name === 'ProjectMember' ||
            route.name === 'ProjectDocument' ||
            route.name === 'ProjectSample'
          ">
          <div
            class="Project_title"
            @click="TitleValueChange(nav)"
            v-for="nav in projectTitleArr"
            :key="nav.nav_name"
            :class="nav.path_name === route.name ? 'Project_titles' : ''">
            <span>
              {{ nav.nav_name }}
            </span>
          </div>
        </div>
      </div>
      <!--  //! 样品流转记录 -->
      <div class="Flow_record" v-if="route.name === 'SampleFlowRecord'">
        <div :class="chang_navigation == '1' ? 'item' : 'item_un'" @click="ischang_navigation(1)">全部</div>
        <div :class="chang_navigation == '2' ? 'item1' : 'item1_un'" @click="ischang_navigation(2)">项目样品流转记录</div>
        <el-select
          v-model="project_ID_value"
          class="m-2"
          size="large"
          v-if="route.name === 'SampleFlowRecord' && store.state.Project.sample_status === 2">
          <el-option v-for="item in SampleFlowRecord_options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <div class="Volunteer_list" v-if="route.name === 'SampleFlowRecord' && store.state.Project.sample_status === 2" @click="go_Volunteer_tabel">
          志愿者名单
        </div>
      </div>
      <!-- !招募管理的项目状态 -->
      <div class="Recruit" v-if="route.name === 'RecruitVolunteer' && store.state.Project.ProjectId != '' && Program.status != '未发布'">
        招募人数:{{ Program.num }}人
      </div>
      <div class="SetRecruitStatus" v-if="route.name === 'RecruitVolunteer' && store.state.Project.ProjectId != '' && Program.status != '未发布'">
        <el-select v-model="RecruitStatus" class="changes" placeholder="Select" size="small" @change="Change">
          <el-option v-for="item in StatusList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <!-- ! 签章管理 -->
      <div class="Signature" v-if="route.name === 'SignatureManagement'">
        <div class="Title1" :class="!Signature ? 'is_active' : ''" @click="Signature = false">电子签章</div>
        <div class="Title2" :class="Signature ? 'is_active' : ''" @click="Signature = true">电子签名</div>
      </div>
      <!-- ! 签章管理 -->
      <div class="Signature" v-if="route.name === 'volunteer_Custom_Settings'">
        <div class="Title1" :class="!Volnature ? 'is_active' : ''" @click="Volnature = false">组别管理</div>
        <div class="Title2" :class="Volnature ? 'is_active' : ''" @click="Volnature = true">标签管理</div>
      </div>
      <!-- !操作日志 -->
      <div class="OperationLog" v-if="route.name == 'OperationLog'">
        <span class="sp"></span>
        <el-select v-model="LogArea" class="Project_select" placeholder="请选择项目" size="large">
          <el-option v-for="item in LogArr" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <!-- !系统设置  最后-->
      <div class="Setting">
        <div class="opertion_1" @click="helpCenterChange">
          <img src="https://newstore.vynior.com/wenti%402x.png" alt="" />
        </div>
        <div class="opertion_2">
          <img src="https://newstore.vynior.com/setting%402x.png" @click.stop="goToUserPower" alt="" />
        </div>
        <div class="opertion_3" @click.stop="showMessage">
          <el-badge :value="msgCount" class="MessageCount" :hidden="msgCount === 0">
            <img :src="messageSrc" alt="" />
          </el-badge>
        </div>
        <div class="mesBox" @click.stop="showMesFunc">
          <div class="messageContent" ref="content" id="content" v-show="isshowMessage">
            <div class="Mes_title">未读通知</div>
            <div class="option_title">
              <div
                class="Mes_option"
                :class="index == ClickActive ? 'click-active' : ''"
                v-for="(item, index) in MesState"
                :key="index"
                @click="ClickActive = index">
                {{ item }}
              </div>
            </div>
            <div class="work" v-if="ClickActive == 0">
              <div class="listContent" v-if="!noWork">
                <div class="list" v-for="(item, index) in workArr" :key="index">
                  <div class="title">{{ item.content }}</div>
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
              <div class="empty_msg" v-if="noWork">暂无消息提醒</div>
            </div>
            <div class="limit" v-if="ClickActive == 1">
              <div class="listContent" v-if="!noLimit">
                <div class="list" v-for="(item, index) in limitArr" :key="index">
                  <div class="title">{{ item.content }}</div>
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
              <div class="empty_msg" v-if="noLimit">暂无消息提醒</div>
            </div>
            <div class="system" v-if="ClickActive == 2">
              <div class="listContent" v-if="!noSystem">
                <div class="list" v-for="(item, index) in systemArr" :key="index">
                  <div class="title">{{ item.content }}</div>
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
              <div class="empty_msg" v-if="noSystem">暂无消息提醒</div>
            </div>
          </div>
        </div>
        <div class="photo" @click.stop="SetShowFunction">
          <img
            :src="
              Gender === '女'
                ? 'https://newstore.vynior.com/cems_web/3081688106472_.pic%402x.png'
                : 'https://newstore.vynior.com/cems_web/3071688106472_.pic%402x.png'
            "
            alt="" />
          <div class="Quit" ref="QuitBox" v-show="SetShow" id="QuitBox">
            <el-tooltip class="box-item" effect="dark" placement="top">
              <template #content>
                <div class="Content2">
                  <span>{{ userName }}<br /> </span>
                  <span class="scondSpan">
                    {{ userEmail }}
                  </span>
                </div>
              </template>
            </el-tooltip>

            <div class="Content2">
              <el-tooltip class="box-item" effect="dark" placement="top">
                <template #content>
                  <div class="Content2">
                    <span>{{ userName }} </span>
                  </div>
                </template>

                <span>{{ userName }} </span>
              </el-tooltip>

              <el-tooltip class="box-item" effect="dark" placement="top">
                <template #content>
                  <div class="Content2">
                    <span>{{ userEmail }} </span>
                  </div>
                </template>

                <span>{{ userEmail }} </span>

                <span class="scondSpan">
                  {{ userEmail }}
                </span>
              </el-tooltip>
            </div>

            <div class="Content" @click.stop="goToPersonCenter">
              <span>个人中心</span>
            </div>

            <div class="Content" @click="quit">
              <span>退出登录</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="help" v-if="route.name == 'HelpCenter'">
      <div class="System">
        <img class="icon" src="https://newstore.vynior.com/cems_web/CEMS%20logo-03%402x.png" alt="" />
        <img class="sys" src="https://newstore.vynior.com/cems_web/CEMS%402x.png" alt="" />
      </div>
      <!-- !系统设置  最后-->
      <div class="Setting">
        <div class="opertion_1" @click="helpCenterChange">
          <img src="https://newstore.vynior.com/wenti%402x.png" alt="" />
        </div>
        <div class="opertion_2">
          <img src="https://newstore.vynior.com/setting%402x.png" @click.stop="goToUserPower" alt="" />
        </div>
        <div class="opertion_3" @click.stop="showMessage">
          <el-badge :value="msgCount" class="MessageCount" :hidden="msgCount === 0">
            <img :src="messageSrc" alt="" />
          </el-badge>
        </div>
        <div class="mesBox" @click.stop="showMesFunc">
          <div class="messageContent" ref="content" id="content" v-show="isshowMessage">
            <div class="Mes_title">未读通知</div>
            <div class="option_title">
              <div
                class="Mes_option"
                :class="index == ClickActive ? 'click-active' : ''"
                v-for="(item, index) in MesState"
                :key="index"
                @click="ClickActive = index">
                {{ item }}
              </div>
            </div>
            <div class="work" v-if="ClickActive == 0">
              <div class="listContent" v-if="!noWork">
                <div class="list" v-for="(item, index) in workArr" :key="index">
                  <div class="title">{{ item.content }}</div>
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
              <div class="empty_msg" v-if="noWork">暂无消息提醒</div>
            </div>
            <div class="limit" v-if="ClickActive == 1">
              <div class="listContent" v-if="!noLimit">
                <div class="list" v-for="(item, index) in limitArr" :key="index">
                  <div class="title">{{ item.content }}</div>
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
              <div class="empty_msg" v-if="noLimit">暂无消息提醒</div>
            </div>
            <div class="system" v-if="ClickActive == 2">
              <div class="listContent" v-if="!noSystem">
                <div class="list" v-for="(item, index) in systemArr" :key="index">
                  <div class="title">{{ item.content }}</div>
                  <div class="time">{{ item.time }}</div>
                </div>
              </div>
              <div class="empty_msg" v-if="noSystem">暂无消息提醒</div>
            </div>
          </div>
        </div>
        <div class="photo" @click.stop="SetShowFunction">
          <img
            :src="
              Gender === '女'
                ? 'https://newstore.vynior.com/cems_web/3081688106472_.pic%402x.png'
                : 'https://newstore.vynior.com/cems_web/3071688106472_.pic%402x.png'
            "
            alt="" />
          <div class="Quit" ref="QuitBox" v-show="SetShow" id="QuitBox">
            <el-tooltip class="box-item" effect="dark" placement="top">
              <template #content>
                <div class="Content2">
                  <span>{{ userName }}<br /> </span>
                  <span class="scondSpan">
                    {{ userEmail }}
                  </span>
                </div>
              </template>
            </el-tooltip>

            <div class="Content2">
              <el-tooltip class="box-item" effect="dark" placement="top">
                <template #content>
                  <div class="Content2">
                    <span>{{ userName }} </span>
                  </div>
                </template>

                <span>{{ userName }} </span>
              </el-tooltip>

              <el-tooltip class="box-item" effect="dark" placement="top">
                <template #content>
                  <div class="Content2">
                    <span>{{ userEmail }} </span>
                  </div>
                </template>

                <span>{{ userEmail }} </span>

                <span class="scondSpan">
                  {{ userEmail }}
                </span>
              </el-tooltip>
            </div>

            <div class="Content" @click.stop="goToPersonCenter">
              <span>个人中心</span>
            </div>

            <div class="Content" @click="quit">
              <span>退出登录</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="crearedQuestion"
      v-if="
        route.name == 'CreatedQuestionnaire' ||
        route.name == 'TextImport' ||
        route.name == 'ShareQuestion' ||
        route.name == 'EditQuestion' ||
        route.name == 'H5QuestionPreview' ||
        route.name == 'WebQuestionPreview'
      ">
      <img src="https://newstore.vynior.com/cems_web/%E8%BF%94%E5%9B%9E%E4%B8%BB%E9%A1%B5%402x.png" @click="isDialog" class="question_quit" alt="" />
      <div class="question_title">
        <span>{{
          route.name == 'CreatedQuestionnaire'
            ? '新建问卷'
            : route.name == 'ShareQuestion'
            ? ' '
            : route.name == 'EditQuestion'
            ? route.params._id
            : route.name == 'H5QuestionPreview'
            ? route.params._id
            : '文本导入'
        }}</span>
      </div>
      <div class="Setting">
        <div class="opertion_1" @click="helpCenterChange">
          <img src="https://newstore.vynior.com/wenti%402x.png" alt="" />
        </div>
        <div class="opertion_2">
          <img src="https://newstore.vynior.com/setting%402x.png" @click.stop="goToPersonCenter" alt="" />
        </div>
        <div class="opertion_3">
          <img src="https://newstore.vynior.com/notice2.png" alt="" />
        </div>
        <div class="photo" @click.stop="SetShowFunction"></div>
      </div>
    </div>
    <!-- !是否更改项目状态 -->
    <div class="ChangeS" v-if="dialogVisible">
      <el-dialog v-model="dialogVisible" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass">是否更改该项目的状态?</div>
          </div>
        </template>
        <!-- <div class="Body"><img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />如果不保存，你编辑的内容将会丢失。</div> -->
        <template #footer>
          <span class="dialog-footer">
            <el-button class="Cancel" @click="Cancel(Program.status)">取消</el-button>
            <el-button class="Delete" @click="ChangeStatus"> 确认 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- <div class="Dialog">
      <el-dialog v-model="dialogVisible" title="操作提示" width="30%">
        <span>是否更改该项目的状态?</span>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="Cancel(Program.status)">取消</el-button>
            <el-button type="primary" @click="ChangeStatus"> 确定 </el-button>
          </span>
        </template>
      </el-dialog>
    </div> -->
    <!-- 新建问卷未保存返回的弹窗 -->
    <div class="ChangeS" v-if="isShowDialog">
      <el-dialog v-model="isShowDialog" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass">问卷未保存，确认退出吗？</div>
          </div>
        </template>
        <div class="Body"><img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />如果不保存，你编辑的内容将会丢失。</div>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="Cancel" @click="isShowDialog = false">取消</el-button>
            <el-button class="Delete" @click="questionListChange"> 确认 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!--! 超时账号验证 -->
    <div class="TimeOutBox">
      <el-dialog v-model="TimeOutBoxData.Show" title="账号验证" class="DIALOG" :before-close="handleClose">
        <div class="InputBox">
          <div class="tips">您长时间没有操作，需要重新验证账号</div>
          <div class="email">
            <div class="title">邮箱</div>
            <div class="content">{{ TimeOutBoxData.userName }}</div>
          </div>
          <div class="field">
            <div class="title">密码</div>
            <div class="content">
              <el-input v-model="TimeOutBoxData.pwd" type="password" placeholder="请输入密码" />
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="primary" @click="Login"> 完成 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script setup>
import { message } from '@/utils/resetMessage';
// import { message } from 'element-plus';
import { onMounted, ref, reactive, watch, queuePostFlushCb, onBeforeUnmount, computed } from 'vue';
import { onBeforeRouteLeave, useRoute, useRouter, beforeRouteUpdate } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import http from '@/utils/http';
import { formEmits } from 'element-plus';
//API接收
const store = useStore();
const router = useRouter();
const route = useRoute();
// ! 样品流转id
const project_ID_value = ref('');

// 操作模块数据
const LogArea = ref('全部模块');
//===================消息====================
const isshowMessage = ref(false);
const msgCount = ref();
//消息图标路径
const messageSrc = ref('');
const messageRes = ref('https://newstore.vynior.com/cems_web/%E9%80%9A%E7%9F%A5%402x%20%281%29.png');
const showMessage = () => {
  console.log('点击了展开');
  ReadMessage();
  console.log(localStorage.getItem('_id'));
  isshowMessage.value = true;
};
let Gender = ref('');
const MesState = ref(['工作相关', '账号相关', '系统相关']);
const ClickActive = ref(0);
const noLimit = ref(false);
const noWork = ref(false);
const noSystem = ref(false);
const workArr = ref([]);
const limitArr = ref([]);
const systemArr = ref([]);
const getMessage = async () => {
  const res = await http.post('/message/fetchMessages', {
    user: localStorage.getItem('_id'),
  });
  if (res.status == 0) {
    console.log('消息--res--->', res);
    msgCount.value = res.result.count;
    messageSrc.value =
      msgCount.value >= 1 ? 'https://newstore.vynior.com/cems_web/%E9%80%9A%E7%9F%A5%402x%20%281%29.png' : 'https://newstore.vynior.com/notice2.png';
    // console.log(res.result.work.map(({ content, createdAt }) => ({ content, time: createdAt.substring(0, 10) })));
    workArr.value = res.result.work.map(({ content, createdAt }) => ({ content, time: createdAt }));
    limitArr.value = res.result.user.map(({ content, createdAt }) => ({ content, time: createdAt }));
    systemArr.value = res.result.system.map(({ content, createdAt }) => ({ content, time: createdAt }));
    if (res.result.work == [] || res.result.work == '' || res.result.work == null) {
      noWork.value = true;
    }
    if (res.result.user == [] || res.result.user == '' || res.result.user == null) {
      noLimit.value = true;
    }
    if (res.result.system == [] || res.result.system == '' || res.result.system == null) {
      noSystem.value = true;
    }
  } else {
    console.log('获取消息失败');
  }
};
// 已读
const ReadMessage = async () => {
  const res = await http.post('/message/readMessages', {
    user: localStorage.getItem('_id'),
  });
  if (res.status == 0) {
    console.log('已读消息--res--->', res);
    getMessage();
  } else {
    console.log('失败');
  }
};
const content = ref(null); // 创建一个引用
// 监听性别
watch(
  () => store.state.user.gender,
  (newValue) => {
    Gender.value = newValue;
    console.log(newValue);
    console.log(Gender.value);
  },
);
onMounted(() => {
  console.log(localStorage.getItem('userGender'));
  console.log(store.state);
  if (localStorage.getItem('userGender')) {
    Gender.value = localStorage.getItem('userGender');
  }

  getMessage();
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (isshowMessage.value == true) {
      if (e.target.id != 'content') {
        isshowMessage.value = false;
      }
    }
  });
  // 判断store仓库是否为空。如果为空就拿本地存入
  if (store.state.user.userName === '') {
    store.commit('set_UserName', localStorage.cemsAccount);
  }
  if (projectArrValue.value == '' && store.state.Project.ProjectId != '') {
    projectArrValue.value = sessionStorage.getItem('ProjectName'); //刷新页面之后给项目赋值
  }
  getProject();
  getQuestion();
  getShareProject(123);
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'QuitBox') {
        SetShow.value = false;
      }
    }
  });
  // content.value = content.value;
  // document.addEventListener('click', handleOutsideClick);
});
// 获取仪器可选项
const getInstrRes = async () => {
  const res = await http.post('/instrument/getOptions-instrument');
  console.log(res, '获取仪器可选项');
  Instrument_complete.value = res.instrumentOptions;
};
// 点击跳转帮助中心
const helpCenterChange = () => {
  router.push({
    name: 'HelpCenter',
  });
};
const showMesFunc = () => {
  isshowMessage.value = true;
};
// 检查点击的元素是否在内容区域内
// let isInsideContent = (target) => {
//   console.log('gggggggggghhh');
//   // 获取内容区域的 DOM 元素
//   const contentElement = content.value;
//   if (contentElement && contentElement.contains(target)) {
//     return true;
//   } else {
//     return false;
//   }
// };

// // 处理点击外部区域的事件
// const handleOutsideClick = (event) => {
//   console.log(isInsideContent(event.target));
//   // 如果点击的元素不在内容区域内，则收起页面
//   if (!isInsideContent(event.target)) {
//     isshowMessage.value = false;
//   }
// };

// // 处理内容区域的点击事件，阻止冒泡到外部区域
// const handleContentClick = (event) => {
//   event.stopPropagation();
// };
const LogArr = [
  { value: '全部模块', label: '全部模块' },
  { value: '主页', label: '主页' },
  { value: '项目管理', label: '项目管理' },
  { value: '志愿者管理', label: '志愿者管理' },
  { value: '仪器管理', label: '仪器管理' },
  { value: '问卷管理', label: '问卷管理' },
  { value: '样品管理', label: '样品管理' },
  { value: '专家管理', label: '专家管理' },
  { value: '系统管理', label: '系统管理' },
];
const toMe = () => {
  router.push({
    name: 'ShareMe',
  });
};

const toShare = () => {
  router.push({
    name: 'MyShare',
  });
};
// 签章管理-----------------------
const Signature = ref(false);
// 控制显示用户管理的用户信息和权限管理
const UserInfo = ref(0);

// 签章管理-----------------------
//志愿者自定义设置数据+++++++++++++++++++
const Volnature = ref(false);

//志愿者自定义设置数据+++++++++++++++++++

// 点击切换显示用户信息和权限管理
const UserIndexChange = (val) => {
  UserInfo.value = val;
  if (val == 0) {
    router.push({
      name: 'UserInfo',
    });
  } else if (val == 1) {
    router.push({
      name: 'PowerMange',
    });
  }
}; // 超时盒子数据
let TimeOutBoxData = ref({
  pwd: '',
  // Show: store.state.user.TimeOut,
  Show: false,
  userName: '',
});

// 监听 是否超时;
watch(
  () => store.state.user.TimeOut,
  (newValue) => {
    TimeOutBoxData.value.Show = newValue;
    if (newValue == true) {
      // console.log('localStorage.cemsAccount :>> ', localStorage.cemsAccount);
      if (store.state.user.userName != '') {
        TimeOutBoxData.value.userName = store.state.user.userName;
      } else {
        TimeOutBoxData.value.userName = localStorage.cemsAccount;
      }
    }
  },
);
// console.log('路由参数==>', route.name);
// 当前项目的 状态
let Program = ref({
  num: 0,
  status: '',
});
// 项目招募结束时间
let EndDate = ref('');
// 转换名字
let RecruitStatus = ref('');
// !样品 流转
const SampleFlowRecord_options = ref([]);
// 监听电子签章；
watch(
  () => Signature.value,
  (newValue) => {
    if (newValue) {
      store.commit('set_Signature', '电子签名');
    } else {
      store.commit('set_Signature', '电子签章');
    }
  },
);

// 监听志愿者自定义设置
watch(
  () => Volnature.value,
  (newValue) => {
    if (newValue) {
      store.commit('set_Volnature', '标签管理');
    } else {
      store.commit('set_Volnature', '组别管理');
    }
  },
);
// 监听志愿者自定义设置
watch(
  () => Volnature.value,
  (newValue) => {
    if (newValue) {
      store.commit('set_Volnature', '标签管理');
    } else {
      store.commit('set_Volnature', '组别管理');
    }
  },
);
// ! 监听样品流转id
watch(
  () => store.state.Sample.Click_projectdata,
  () => {
    // console.log('全部进入流转', store.state.Sample.Click_projectdata);
    project_ID_value.value = store.state.Sample.Click_projectdata.project;
  },
  { deep: true },
);
// ! 监听选中的样品流转记录项目id
watch(
  () => project_ID_value.value,
  () => {
    console.log('project_ID_value.value :>> ', project_ID_value.value);

    store.commit('Set_project_id', project_ID_value.value);
  },
);

watch(
  () => LogArea.value,
  (newValue) => {
    store.commit('set_Type', newValue);
  },
);
const updateVuexClickData = async () => {
  const res = await http.post('/flow/fetchList-flow', {
    page: 1,
    size: 100,
    sort: {
      createdAt: -1,
    },
  });
  if (!res.status) {
    console.log('res :>> ', res);
    let SampleData = res.result.find((item) => item.projectId == project_ID_value.value);
    console.log(' SampleData.value :>> ', SampleData);
    // store.commit('Set_project_id', SampleData._id);
  }
};
// ! 获取下拉框数据
const get_SampleFlowRecord_options = async () => {
  SampleFlowRecord_options.value = [];
  try {
    // console.log(project, project_id, "获取的数据");
    let { result } = await http.post('/sample/getCirculateFormProjectOptions');
    let project = [];
    let project_data = [];
    SampleFlowRecord_options.value = result;
    // console.log('options', options);
    // options.forEach((item, index) => {
    //   project.push(options[index].project);
    //   project_data.push(options[index]);
    //   SampleFlowRecord_options.value.push({
    //     label: options[index].project,
    //     // value: options[index].sample[0].flow_id,
    //     value: options[index].projectId,
    //   });
    // });
    // console.log(project, project_data, '获取的数据');
    //  存储vuex
    store.commit('Set_project', result);
    store.commit('Set_project_data', result);
    console.log(SampleFlowRecord_options.value);
  } catch (error) {
    console.log(error);
  }
  // SampleFlowRecord_options.value = [];
  // try {
  //   // console.log(project, project_id, "获取的数据");
  //   let { options } = await http.post('/flow/getFlowOptions-flow');
  //   let project = [];
  //   let project_data = [];
  //   options.forEach((item, index) => {
  //     project.push(options[index].project);
  //     project_data.push(options[index]);
  //     SampleFlowRecord_options.value.push({
  //       label: options[index].project,
  //       value: options[index].projectId,
  //     });
  //   });
  //   console.log(project, project_data, '获取的数据');
  //   //  存储vuex
  //   store.commit('Set_project', project);
  //   store.commit('Set_project_data', project_data);
  // } catch (error) {
  //   console.log(error);
  // }
};
//可查看的所有项目
let projectArr = ref([[], []]);
let shareProjectArr = ref([]);

const isShowDialog = ref(false);
const isDialog = () => {
  if (route.name == 'CreatedQuestionnaire' || route.name == 'EditQuestion') {
    isShowDialog.value = true;
  }
  if (route.name == 'ShareQuestion' || route.name == 'TextImport' || route.name == 'H5QuestionPreview') {
    router.push({
      name: 'QuestionnaireList',
    });
  }
};

// 点击跳转到问卷列表
const questionListChange = () => {
  // console.log("跳转前的isResOk", store.state.isResOk);
  console.log('123321', route);
  // "ShareQuestion"

  if (route.name == 'ShareQuestion' || route.name == 'TextImport' || route.name == 'H5QuestionPreview') {
    router.push({
      name: 'QuestionnaireList',
    });
  } else {
    // store.commit('setIsResOk', true);
    store.commit('setIsResOk');
    isShowDialog.value = false;
  }

  // console.log(" store.state.isResOk111", store.state.isResOk);
  // if (store.state.isResOk) {
  // router.push({
  //   name: "QuestionnaireList",
  // });
  // }
};
// 点击跳转默认报告和统计分析
const quesReportChange = (val) => {
  router.push({
    name: val,
    query: route.query._id,
  });
};
//请求所有可查看的项目
const getProject = async () => {
  console.log('获取项目');
  const result = await http.post('/project/find-project', {
    // page: 1,
    size: 100,
    sort: {
      createdAt: -1,
    },
    Project: {
      // _id:'6456126e6c814a2381cc24fb'
    },
  });
  // console.log(result, '获取所有项目');
  result.result.forEach((item, index) => {
    // console.log(item,index);
    let val = item.number + ' ' + item.name;

    projectArr.value[0][index] = val;
    projectArr.value[1][index] = item._id;
  });
  // console.log(projectArr.value);
};
const getShareProject = async (val) => {
  console.log(val, 'val');
  console.log('获取分享项目');
  console.log(route.params.type);
  console.log(localStorage.getItem('_id'));
  const result = await http.post('/project/getShareOptions-project', {
    userId: localStorage.getItem('_id'),
    type: route.params.type ? route.params.type : 'shareME',
  });
  console.log(result, '获取所有分享项目');

  shareProjectArr.value = result.options;
  console.log(shareProjectArr.value);
  if (
    route.name === 'ShareVolunteerData' ||
    route.name === 'ShareInstrumentPage' ||
    route.name === 'SharePlan' ||
    route.name === 'ShareDetails' ||
    route.name === 'ShareMember' ||
    route.name === 'ShareDocument' ||
    route.name === 'ShareSample' ||
    route.name === 'ShareReport' ||
    route.name === 'ProjectShareQuestion'
  ) {
    let obj;
    obj = shareProjectArr.value.find((item) => item.value === route.params._id);
    console.log(obj, 'objj');
    shareName.value = obj.label;
  }
  console.log(shareName.value);
};
// 问卷所有选项
const questionArr = ref([]);
// 选中的问卷
const questionArrIndex = ref();
// 选中问卷
const questionArrIndexChange = (val) => {
  router.push({
    name: route.name,
    params: {
      _id: val,
    },
  });
};
watch(
  () => questionArrIndex.value,
  (newValue) => {
    console.log('newnewvalue is ', newValue);
    store.commit('setQuesId', newValue);
  },
);

// 请求获取所有可选择的问卷
const getQuestion = async () => {
  const res = await http.post('/questionnaire/getNaireOptionsAll-questionnaire');
  console.log('获取问卷的所有选项', res);
  questionArr.value = res.options;
};
// 选中问卷事件
const questionChange = (val) => {
  console.log(val, '问卷选中事件');
  router.push({
    name: 'QuestionStatisticAnalysis',
    params: {
      _id: val,
    },
  });
};
let watchStatus = ref('');

let Num = ref(0);
//
// 选中的状态
const ChoiceStatus = ref('');
// 状态选择
const StatusList = [
  {
    value: '正在招募中',
    label: '正在招募中',
  },

  {
    value: '招募完成',
    label: '招募完成',
  },
];
// 项目选中的值
const projectArrValue = ref('');
const shareProjectArrValue = ref('');
//项目选中值的id
let projectArrValueId = ref();
let shareProjectArrValueId = ref();
let shareName = ref({});
// 弹窗
const dialogVisible = ref(false);
// 获取ref
const QuitBox = ref(null);
let SetShow = ref(false);
//退出
const quit = () => {
  localStorage.clear();
  sessionStorage.clear();
  router.push('login');
};
// ! 切换表头  默认一 全部
let chang_navigation = ref(1);
// ! 获取event 设置show显示框的位置
const SetShowFunction = (event) => {
  // SetShow.value = false;
  SetShow.value = true;
  // console.log('event :>> ', event, ShowBox.value);

  //
  // QuitBox.value.style.left = event.clientX - 137 + "px";
  // QuitBox.value.style.top = event.clientY + 38 + "px";
};
// !  改变表头导航栏
const ischang_navigation = (index) => {
  store.commit('Set_isVolunteer_tabel', false);
  chang_navigation.value = 0;
  chang_navigation.value = index;
  store.commit('Set_sample_status', index);
};
// !志愿者跳转
const go_Volunteer_tabel = () => {
  store.commit('Set_isVolunteer_tabel', true);
};
// !监听数据 发生改变调用接口即可
watch(
  () => store.state.Project.sample_status,
  () => {
    console.log('监听改变了');
    // ischang_navigation(store.state.Project.sample_status);
    // project_ID_value.value = "";
    get_SampleFlowRecord_options();
  },
);
watch(
  () => route.name,
  async (newValue) => {
    console.log(route.name, 'route.name');
    getShareProject(222);
    if (newValue == 'RecruitVolunteer') {
      // 如果是招募管理再次请求项目人数，状态；
      ReqProjectDetails();
      console.log('newValue :>> ', newValue, projectArr.value);
      if (projectArr.value[0][0] != '全部项目') {
        projectArr.value[0].unshift('全部项目');
        projectArr.value[1].unshift('');
        if (projectArrValue.value == '') {
          projectArrValue.value = '全部项目';
        }
      }
    } else {
      if (projectArr.value[0][0] == '全部项目') {
        projectArr.value[0].shift();
        projectArr.value[1].shift();
        // projectArrValue.value = '';
      }
      if (projectArrValue.value == '全部项目') {
        projectArrValue.value = '';
      }
    }
    console.log('projectArrValue :>> ', projectArrValue.value, projectArr.value);
    if (route.name == 'UserInfo') {
      UserInfo.value = 0;
    } else if (route.name == 'PowerMange') {
      UserInfo.value = 1;
    }
    if (route.name == 'ProjectDetails') {
      await getProject();
      projectArr.value[1].forEach((item, index) => {
        if (item == route.params._id) {
          projectArrValueId.value = item;
          projectArrValue.value = projectArr.value[0][index];
        }
      });
    }
    // 点击统计分析进入到统计分析
    if (route.name == 'QuestionReport') {
      questionArrIndex.value = route.params._id;
    } else if (route.name == 'SampleFlowRecord') {
      chang_navigation.value = 1;
      store.commit('Set_sample_status', 1);
    } else if (route.name == 'InstrumentTestRecord') {
      getInstrRes();
    }
    // if (route.name !== 'ProjectReport' && isReport.value === false) {
    //   isShowDialog.value = true;
    // }
  },
);

watch(
  () => store.state.Project.ProjectId,
  () => {
    console.log('变化了 :>> ');
    getProgram();
    reqEndDate();
  },
);
watch(
  () => store.state.Project.ProjectDetails,
  async () => {
    console.log('从项目列表进入');
    console.log(projectArr.value, 'projectArr.value');
    console.log(store.state.Project.ProjectId, 'store.state.Project.ProjectId');
    projectArr.value[1].forEach((item, index) => {
      if (item == store.state.Project.ProjectId) {
        projectArrValueId.value = item;
        projectArrValue.value = projectArr.value[0][index];

        console.log('从项目列表进入的项目名称projectArrValue.value :>> ', projectArrValue.value);
      }
    });
  },
);
// ! 监听就换样式
watch(
  () => store.state.Project.sample_status,
  () => {
    chang_navigation.value = store.state.Project.sample_status;
  },
);
watch(
  () => projectArrValue.value,
  (newValue, oldValue) => {
    getProgram();
    console.log(projectArrValue.value, 'projectArrValue.value');
    projectArr.value[0].forEach((item, index) => {
      if (item == projectArrValue.value) {
        const [number, name] = projectArrValue.value.split(' ');
        console.log('number :>> ', number);
        projectArrValueId.value = projectArr.value[1][index];
        ReqProjectDetails(projectArrValueId.value);
        store.commit('Set_ProjectId', projectArrValueId.value);
        store.commit('Set_ProjectNumber', number);
        console.log(route.path.substring(1, 12));
        // <<<<<<< HEAD
        sessionStorage.setItem('ProjectId', projectArrValueId.value);
        sessionStorage.setItem('ProjectName', projectArrValue.value);

        //         if (route.path.substring(1, 12) === 'ProjectList') {
        // =======
        if (route.path.substring(1, 12) === 'ProjectList') {
          // >>>>>>> 86a8bfe87f166ded73653c9c050d03e8c5ff59df
          router.push({
            name: route.name,
            params: {
              _id: projectArrValueId.value,
            },
          });
        } else if (route.path.substring(1, 12) === 'TestRecords') {
          router.push({
            name: route.TestRecords,
            query: {
              _id: projectArrValueId.value,
            },
          });
        }
        // if (route.name === 'ProjectDetails') {
        //   router.push({
        //     name: 'ProjectDetails',
        //     params: {
        //       _id: projectArrValueId.value,
        //     },
        //   });
        // }
      }
    });
  },
);
let projectTitleArr = ref([
  // 头部导航标题
  {
    nav_name: '概览',
    path_name: 'ProjectDetails',
  },
  {
    nav_name: '成员',
    path_name: 'ProjectMember',
  },
  {
    nav_name: '样品',
    path_name: 'ProjectSample',
  },
  {
    nav_name: '方案',
    path_name: 'ProjectPlan',
  },
  {
    nav_name: '指标&仪器',
    path_name: 'InstrumentPage',
    // path_name: '',
  },
  {
    nav_name: '志愿者',
    path_name: 'VolunteerData',
    // path_name: '',
  },
  {
    nav_name: '问卷',
    path_name: 'ProjectQuestion',
  },
  {
    nav_name: '报告',
    path_name: 'ProjectReport',
  },
  {
    nav_name: '文档',
    path_name: 'ProjectDocument',
  },
]);
let shareProjectTitleArr = ref([
  // 头部导航标题
  {
    nav_name: '概览',
    path_name: 'ShareDetails',
  },
  {
    nav_name: '成员',
    path_name: 'ShareMember',
  },
  {
    nav_name: '样品',
    path_name: 'ShareSample',
  },
  {
    nav_name: '方案',
    path_name: 'SharePlan',
  },
  {
    nav_name: '指标&仪器',
    path_name: 'ShareInstrumentPage',
    // path_name: '',
  },
  {
    nav_name: '志愿者',
    path_name: 'ShareVolunteerData',
    // path_name: '',
  },
  {
    nav_name: '问卷',
    path_name: 'ProjectShareQuestion',
  },
  {
    nav_name: '报告',
    path_name: 'ShareReport',
  },
  {
    nav_name: '文档',
    path_name: 'ShareDocument',
  },
]);
//所选中的项目标题
let projectTitleArrValue = ref('概览');
let shareProjectTitleArrValue = ref('概览');
// 选中项目标题的事件

const TitleValueChange = (val) => {
  projectTitleArrValue.value = val.nav_name;
  router.push({
    name: val.path_name,
    params: {
      _id: route.params._id,
    },
  });

  // console.log(route.params._id);
  // console.log('路由参数==>', route.name);
};

const ShareTitleValueChange = (val) => {
  console.log(val);
  shareProjectTitleArrValue.value = val.nav_name;

  router.push({
    name: val.path_name,
    params: {
      _id: route.params._id,
      type: route.params.type,
    },
  });

  // console.log(route.params._id);
  // console.log('路由参数==>', route.name);
  // 在ShareTitleValueChange函数中触发路由跳转
};

// 查看具体项目
const getProgram = async () => {
  const res = await http.post('/project/find-project', {
    page: 1,
    size: 20,
    sort: { createdAt: -1 },
    Project: {
      _id: store.state.Project.ProjectId,
    },
  });
  console.log('查看具体项目的数据 :>> ', res);
  if (res.status == 0) {
    if (res.result.length === 0) return;

    watchStatus.value = JSON.parse(JSON.stringify(res.result[0].status));
  }
};
// 点击Title刷新页面
const RefreshPage = (value) => {
  switch (value) {
    case '项目列表':
      router.push({ path: '/ProjectList' });
      break;
    case '项目分享':
      router.push({ path: '/Project_sharing' });
      break;
    case '新建项目':
      router.push({ path: '/CreateProject' });
      break;
    case '志愿者库':
      router.push({ path: '/VolunteerBank' });

      break;
    case '主页':
      router.push({ path: '/Home' });

      break;
  }
};

//tip 修改项目状态
const ChangeStatus = async () => {
  reqEndDate();
  console.log(EndDate.value);
  const role = dayjs().isAfter(EndDate.value);
  console.log('role', role);
  if (!role) {
    console.log(' Program.value.status:>> ', RecruitStatus.value);

    let res = await http.put('/project/update-project', {
      User: localStorage.getItem('_id'),
      Project: {
        _id: store.state.Project.ProjectId,
        status: RecruitStatus.value == '正在招募中' ? '招募中' : '进行中',
      },
    });
    if (res.status == 0) {
      dialogVisible.value = false;
      message({
        message: '修改成功',
        showClose: true,
        type: 'success',
      });
    }
  } else {
    message({
      message: '招募时间结束,不可以切换状态',
      showClose: true,
      type: 'warning',
    });
  }
};
// 取消操作
const Cancel = (val) => {
  dialogVisible.value = false;
  if (val == '招募完成') {
    RecruitStatus.value = '正在招募中';
  } else {
    RecruitStatus.value = '招募完成';
  }
};
// ! 仪器总类
const Instrument_complete = ref([
  // {
  //   value: 'CN-JK-001  Vplus 智能皮肤分析系统',
  //   label: 'CN-JK-001  Vplus 智能皮肤分析系统',
  // },
  {
    value: '16427414  皮肤弹性测试探头Cutometer MPA580',
    label: '16427414  皮肤弹性测试探头Cutometer MPA580',
  },
  {
    value: 'TH-097539  皮肤敏感度测试仪 TiVi700',
    label: 'TH-097539  皮肤敏感度测试仪 TiVi700',
  },
  {
    value: '皮肤色素测试探头 Mexameter MX18',
    label: '皮肤色素测试探头 Mexameter MX18',
  },
  {
    value: '皮肤光泽度测试探头Glossymeter GL200',
    label: '皮肤光泽度测试探头Glossymeter GL200',
  },
  {
    value: '363212  皮肤水分测试探头Corneometer CM825',
    label: '363212  皮肤水分测试探头Corneometer CM825',
  },
  {
    value: '皮肤致密柔软度测试探头Indentometer IDM800',
    label: '皮肤致密柔软度测试探头Indentometer IDM800',
  },
  {
    value: '363212  皮肤颜色测试探头ColorimeterCL400',
    label: '363212  皮肤颜色测试探头ColorimeterCL400',
  },
  {
    value: '363212  皮肤酸碱度测试探头Skin-pH-meter PH905',
    label: '363212  皮肤酸碱度测试探头Skin-pH-meter PH905',
  },
  {
    value: '皮肤油脂测试仪Sebumeter SM 815',
    label: '皮肤油脂测试仪Sebumeter SM 815',
  },
  {
    value: '水分流失测试探头 Tewameter TM HEX',
    label: '水分流失测试探头 Tewameter TM HEX',
  },
]);

// 数据统计分析 下拉框Item
const DataStatisticalAnalysisInstrument_complete = ref([
  // {
  //   value: 'CN-JK-001  Vplus 智能皮肤分析系统',
  //   label: 'CN-JK-001  Vplus 智能皮肤分析系统',
  // },

  {
    value: 'Mode1',
    label: '16427414  皮肤弹性测试探头Cutometer MPA580',
  },
  // {
  //   value: 'TH-097539  皮肤敏感度测试仪 TiVi700',
  //   label: 'TH-097539  皮肤敏感度测试仪 TiVi700',
  // },
  {
    value: 'MX18',
    label: '皮肤色素测试探头 Mexameter MX18',
  },
  // {
  //   value: '皮肤光泽度测试探头Glossymeter GL200',
  //   label: '皮肤光泽度测试探头Glossymeter GL200',
  // },
  {
    value: 'CM825',
    label: '363212  皮肤水分测试探头Corneometer CM825',
  },
  {
    value: 'IDM800',
    label: '皮肤致密柔软度测试探头Indentometer IDM800',
  },
  {
    value: 'CL400',
    label: '363212  皮肤颜色测试探头ColorimeterCL400',
  },
  {
    value: 'PH905',
    label: '363212  皮肤酸碱度测试探头Skin-pH-meter PH905',
  },
]);
// 选中的仪器
const Oneinstrument = ref('');
// 获取仪器名字
const getName = async () => {
  console.log('点击了1');
  console.log(Oneinstrument.value);
  let label = Instrument_complete.value.find((item) => item.value === Oneinstrument.value);
  let obj = {
    label: label.label,
    value: Oneinstrument.value,
  };
  console.log(obj);
  store.commit('Set_instrProject', null);
  store.commit('Set_InstrumentName', obj);
  const res = await http.post('/indicator/findProject-indicator', {
    instrument: Oneinstrument.value,
  });
  selectInstrProject.value = null;
  console.log(res, '仪器下的项目');
  selectInstrProjectArr.value = res.project;
};
// 选中仪器下的项目
const selectInstrProject = ref(null);
// 仪器下可选择的仪器
const selectInstrProjectArr = ref([{ projectName: '', projectId: '' }]);
// 仪器下项目选中事件
const selectInstrProjectChange = async (e) => {
  console.log(e, selectInstrProject.value);
  if (!!selectInstrProject.value) {
    const res = await http.post('/project/findInfo-project', {
      projectId: selectInstrProject.value,
    });
    console.log(res, '获取项目详情保存编号');
    store.commit('Set_instrProject', res.project[0].number);
  }
};
const handleClose = () => {};

// 登录
const Login = async () => {
  if (TimeOutBoxData.value.pwd != '') {
    const res = await http.post('/auth/login', {
      email: localStorage.getItem('cemsAccount'),
      password: TimeOutBoxData.value.pwd,
    });
    console.log('登录返回--->', res);
    if (res.status == 2) {
      message({
        showClose: true,
        message: '密码错误',
        type: 'error',
      });
    } else if (res.status == 0) {
      store.commit('Set_TimeOut', false);
      TimeOutBoxData.value.pwd = '';
      message({
        showClose: true,
        message: '校验成功',
        type: 'success',
      });
    }
  } else {
    message({
      showClose: true,
      message: '密码不可为空',
      type: 'warning',
    });
  }
};
// 切换状态
const Change = () => {
  dialogVisible.value = true;
};
// 请求项目招募结束时间
const reqEndDate = async () => {
  const res = await http.post('/project/getProjectRecruitEndTime-project', {
    projectId: store.state.Project.ProjectId,
  });

  if (res.status == 0) {
    EndDate.value = res.endTime;
    console.log(res.endTime);
  }
};

// 获取项目详情数据
const ReqProjectDetails = async (val) => {
  console.log('详细的id数据是 :>> ', val);
  if (val == '' || val == undefined) {
    val = sessionStorage.getItem('ProjectId');
  }
  const res = await http.post('/project/findInfo-project', {
    projectId: val,
  });
  console.log('res :>> ', res);
  if (res.status == 0) {
    Program.value.num = res.project[0].peopleNumber;
    Program.value.status = res.project[0].status;
    // 转换名字
    if (res.project[0].status == '招募中') {
      RecruitStatus.value = '正在招募中';
    } else if (res.project[0].status != '未发布' && res.project[0].status != '招募中') {
      RecruitStatus.value = '招募完成';
    }
  }
  console.log('招募人数111 :>> ', Program.value, RecruitStatus.value);
};

//右上角-点击个人中心-跳转个人中心
const goToPersonCenter = () => {
  store.commit('Set_CurrentProjectName', '个人中心');

  router.push({
    name: 'personalCenter',
  });
};
const goToUserPower = () => {
  console.log(route.name);
  if (route.name == 'UserPower' || route.name == 'UserInfo') {
    console.log(123);
  } else {
    store.commit('Set_CurrentProjectName', '用户&权限管理');
    router.push({
      name: 'UserPower',
    });
  }
};
// let userName = ref(localStorage.getItem('userName'));
let userName = computed(() => {
  return localStorage.getItem('userName');
});
let userEmail = ref(localStorage.getItem('userEmail'));
onBeforeUnmount(() => {
  console.log('我要被销毁了,快干点什么吧 :>> ');
});
window.addEventListener('beforeunload', function (event) {
  // 在这里可以执行清理操作或其他必要的逻辑
  // 例如，保存未保存的数据或向用户显示提示信息
  if (TimeOutBoxData.value.Show == true) {
    console.log('object :>> ', '我要刷新页面了', TimeOutBoxData.value.Show);

    quit();
  }
  // event.preventDefault(); // 可选，用于取消默认的提示信息
  // event.returnValue = '我刷新页面了'; // 可选，用于自定义提示信息（仅在某些浏览器中生效）
});
</script>
<style lang="less">
@import url(../style/components/TopHeader.less);
</style>
