<template>
  <div id="SampleFlowRecord">
    <div class="VolunteerBox">
      <!-- 表体 -->
      <div class="VolunteerTable" v-if="store.state.Project.sample_status === 1">
        <div class="vxetable">
          <vxe-toolbar>
            <template #buttons>
              <div class="tool">
                <div class="toolLeft">
                  <vxe-input
                    class="Search"
                    v-model="demo1.filterName"
                    type="search"
                    placeholder="请输入项目、样品编号或名称进行搜索"
                    @keyup.enter="searchEvent2"
                    @Blur="searchEvent2">
                  </vxe-input>
                  <vxe-button class="Reset" @click="reset"><span class="reset-icon">重置</span></vxe-button>
                </div>
                <div class="toolRight">
                  <!-- 新建 -->
                  <div class="New_voluntee" @click="VolunteereditorChange">
                    <img src="https://newstore.vynior.com/cems_web/add1.png" alt="" />
                    新建流转单
                  </div>
                  <!-- 批量 -->
                  <div class="batch_operation" @click.stop="isshowOperation = !isshowOperation">
                    <span class="text">批量操作</span>
                    <img src="https://newstore.vynior.com/%E4%B8%8B%E6%8B%89.png" alt="" v-show="!isshowOperation" />
                    <img src="https://newstore.vynior.com/cems_web/%E5%B1%95%E5%BC%80%402x.png" alt="" v-show="isshowOperation" />
                  </div>
                </div>
              </div>

              <!-- 批量操作下拉框 -->
              <div class="batch_operation_box" id="operationBox" v-if="isshowOperation">
                <div class="item_box item_box_dis">
                  <img src="https://newstore.vynior.com/%E5%AF%BC%E5%87%BA%402x.png" alt="" />
                  <div class="item_one_text" @click="EXPORT_box">导出</div>
                </div>
                <div class="item_box">
                  <img src="https://newstore.vynior.com/%E5%88%A0%E9%99%A4%402x.png" alt="" />
                  <div class="item_two_text" style="margin-top: 0px" @click="DELECT_box">删除</div>
                </div>
              </div>
            </template>
          </vxe-toolbar>
          <vxe-table
            border
            stripe
            ref="xTable"
            height="720"
            show-overflow
            row-id="_id"
            :data="demo1.tableData"
            :tooltip-config="tooltipConfig"
            :column-config="{ resizable: true, useKey: 'id' }"
            :row-config="{
              isHover: true,
              keyField: 'id',
              height: linewidth <= 1280 ? 32 : 48,
              minHeight: 32,
              useKey: 'id',
            }"
            :checkbox-config="{ reserve: true }"
            :loading="demo1.loading"
            @header-cell-dragend="handleHeaderDragend"
            @checkbox-all="selectAllChangeEvent1"
            @checkbox-change="selectChangeEvent1">
            >
            <vxe-column field="checked" type="checkbox" width="50" min-width="50" :fixed="demo1.colFixeds.col0"></vxe-column>
            <vxe-column field="form_code" type="html" title="流转单编号" width="146" :fixed="demo1.colFixeds.col1" min-width="146">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="ID-header" v-if="column.title === '流转单编号'">
                  <span class="name">流转单编号</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('流转单编号', $event)"></i></span>
                </div> </template
            ></vxe-column>
            <vxe-column field="project_number" type="html" title="检测项目编号" width="146" :fixed="demo1.colFixeds.col2" min-width="146">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="name-header" v-if="column.title === '检测项目编号'">
                  <span class="name">检测项目编号</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('检测项目编号', $event)"></i></span>
                </div> </template
            ></vxe-column>
            <vxe-column field="project_name" type="html" title="检测项目名称" width="146" :fixed="demo1.colFixeds.col3" min-width="146">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Role-header" v-if="column.title === '检测项目名称'">
                  <span class="name">检测项目名称</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('检测项目名称', $event)"></i></span>
                </div>
              </template>
            </vxe-column>

            <vxe-column field="sample_code" type="html" title="样品编号" width="146" :fixed="demo1.colFixeds.col4" min-width="146">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '样品编号'">
                  <span class="name">样品编号</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('样品编号', $event)"></i></span>
                </div>
              </template>
            </vxe-column>

            <vxe-column field="sample_name" type="html" title="样品名称" width="117" :fixed="demo1.colFixeds.col5" min-width="117">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Address-header" v-if="column.title === '样品名称'">
                  <span class="name">样品名称</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('样品名称', $event)"></i></span>
                </div>
              </template>
              <template #default="{ row }">
                <span class="text" @click="skip_project(row)">{{ row.sample_name }}</span>
              </template>
            </vxe-column>
            <vxe-column field="get_date" type="html" title="领样日期" width="120" :fixed="demo1.colFixeds.col7" min-width="92">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Address-header" v-if="column.title === '领样日期'">
                  <span class="name">领样日期</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('领样日期', $event)"></i></span> -->
                </div>
              </template>
            </vxe-column>
            <vxe-column field="getuser_name" type="html" title="领样人" width="89" :fixed="demo1.colFixeds.col4" min-width="89">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '领样人'">
                  <span class="name">领样人</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('领样人', $event)"></i></span> -->
                </div>
              </template>
            </vxe-column>
            <vxe-column field="take_total" type="html" title="领样数量" width="112" :fixed="demo1.colFixeds.col4" min-width="92">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '领样数量'">
                  <span class="name">领样数量</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('领样数量', $event)"></i></span> -->
                </div>
              </template>
            </vxe-column>
            <vxe-column field="await_total" type="html" title="待检数量" width="112" :fixed="demo1.colFixeds.col4" min-width="92">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '待检数量'">
                  <span class="name">待检数量</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('待检数量', $event)"></i></span> -->
                </div>
              </template>
            </vxe-column>
            <vxe-column field="under_total" type="html" title="在检数量" width="112" :fixed="demo1.colFixeds.col4" min-width="92">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '在检数量'">
                  <span class="name">在检数量</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('在检数量', $event)"></i></span> -->
                </div>
              </template>
            </vxe-column>
            <vxe-column field="completed_total" type="html" title="检毕数量" width="112" :fixed="demo1.colFixeds.col4" min-width="92">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '检毕数量'">
                  <span class="name">检毕数量</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('检毕数量', $event)"></i></span> -->
                </div>
              </template>
            </vxe-column>
            <vxe-column field="return_date" type="html" title="归还日期" width="112" :fixed="demo1.colFixeds.col4" min-width="92">
              <!-- &lt;!&ndash; 增加下拉箭头 &ndash;&gt; -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '归还日期'">
                  <span class="name">归还日期</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('归还日期', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <div>{{ row.return_date === '' ? '——' : row.return_date }}</div>
              </template>
            </vxe-column>
            <vxe-column field="returnuser_name" type="html" title="归还人" width="89" :fixed="demo1.colFixeds.col4" min-width="89">
              <!-- &lt;!&ndash; 增加下拉箭头 &ndash;&gt; -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '归还人'">
                  <span class="name">归还人</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('归还人', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <div>
                  {{ row.returnuser_name === '' ? '——' : row.returnuser_name }}
                </div>
              </template>
            </vxe-column>
            <vxe-column field="return_total" type="html" title="归还数量" width="112" :fixed="demo1.colFixeds.col4" min-width="92">
              <!-- &lt;!&ndash; 增加下拉箭头 &ndash;&gt; -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '归还数量'">
                  <span class="name">归还数量</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('归还数量', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <div>{{ row.return_total === null ? '——' : row.return_total }}</div>
              </template>
            </vxe-column>
            <vxe-column field="remark" type="html" title="备注" width="89" :fixed="demo1.colFixeds.col4" min-width="159">
              <!-- &lt;!&ndash; 增加下拉箭头 &ndash;&gt; -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '备注'">
                  <span class="name">备注</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('备注', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <div>{{ row.remark === '' ? '——' : row.remark }}</div>
              </template>
            </vxe-column>
            <vxe-column :tooltip-config="{ enabled: false }" title="操作" width="206" :fixed="demo1.colFixeds.col9" :resizable="false">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Operation-header" v-if="column.title === '操作'">
                  <span class="name">操作</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <div style="display: flex" class="more_item">
                  <span>
                    <el-tooltip class="box-item" effect="dark" content="查看详情" placement="top">
                      <div class="item" @click="ShowDetails(row)">查看详情</div>
                    </el-tooltip>
                  </span>
                  <span>
                    <el-tooltip class="box-item" effect="dark" content="删除" placement="top">
                      <div class="item1" @click="deldect_one(row)">删除</div>
                    </el-tooltip>
                  </span>
                </div>
              </template>
            </vxe-column>
          </vxe-table>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
              </ul>
            </div>
          </div>
          <p v-if="demo1?.tableData?.length != 0">
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                  >条/共{{ page5.totalResult }}条
                </div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
    </div>
    <!-- !没有数据 -->
    <div class="EmptyProject" v-if="demo1.tableData && demo1.tableData.length == 0">
      <span>
        <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
        <p>暂无项目</p>
      </span>
    </div>
    <!-- !表头字段设置 -->
    <div class="SetTableHeader" v-if="SetTableHeader">
      <div class="main">
        <div class="Header">
          <div class="text">
            表头字段设置
            <img @click="CancelShowHiden" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402x.png" alt="" />
          </div>
        </div>
        <!-- 设置表头的数据盒子 -->
        <div class="SetBox">
          <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
          <div class="LeftBox">
            <div class="text-header">样品库预设字段</div>
            <div class="ProjectRowField">
              <div class="text-header1">【样品基本信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetVolunteerHeaderArr.slice(0, 19)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                </div>
              </div>
            </div>
            <!-- 【系统信息】 -->
            <div class="ProjectRecruitMSG">
              <div class="text-header2">【样品检测项目信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div class="Checkbox" v-for="(item, index) in SetVolunteerHeaderArr.slice(19, 22)" :key="index">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 19)" />
                </div>
              </div>
            </div>
            <div class="Self-Defined">自定义扩展字段</div>
          </div>
          <!-- 右边盒子 拖动 -->
          <div class="RightBox">
            <div class="text-header">项目列表预设字段</div>
            <div style="font-size:16px; color='black';margin-top:150px">此功能正在开发中,敬请期待</div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="Bottom">
          <div class="add">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
          </div>
          <div class="recover" @click="RecoverData">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
          </div>
          <div class="cancel" @click="CancelShowHiden">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" />
          </div>
          <div class="save" @click="ShowHidenColumn">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- ! 新建-->
    <el-drawer v-model="Volunteereditor" :close-on-click-modal="false" title="I am the title" :with-header="false" size="57%">
      <div class="drawer">
        <div class="deawer_title">
          <div class="deawer_title_text">新建流转单</div>
          <!-- <div class="bar"></div> -->
        </div>
        <div class="drawer_body">
          <!-- 编辑抽屉显示 -->
          <div class="information_box">
            <div class="item_title_box"></div>
            <div class="item_main_box">
              <div class="item1">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>流转单编号</div>
                      <el-input placeholder="请输入流转单编号" v-model="New_circulation_order.Order_number" />
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>检测项目编号及名称</div>
                      <el-select
                        v-model="New_circulation_order.Test_item_number_name"
                        @change="change_id"
                        :remote-method="get_Test_item_number_name"
                        filterable
                        remote
                        reserve-keyword
                        class="m-2"
                        placeholder="输入以搜索..."
                        size="large">
                        <el-option v-for="item in options_data_id" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </div>
                    <!--                                        <div class="err_project" v-if="options_data_id.length == 0 && marker == 1">-->
                    <!--                                            没有找到项目？请联系项目负责人加入项目成员组-->
                    <!--                                        </div>-->
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="13">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>样品</div>
                      <el-select
                        v-model="New_circulation_order.Specimen"
                        @change="change_Specimen"
                        filterable
                        class="m-2"
                        placeholder="请选择"
                        size="large">
                        <el-option v-for="item in options_data" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </div>
                    <!--                    <div-->
                    <!--                      class="err_specimen"-->
                    <!--                      v-if="options_data.length == 0 && marker == 1"-->
                    <!--                    >-->
                    <!--                      没有找到样品？在【项目】-【样品】中链入后才能选择-->
                    <!--                    </div>-->
                    <!-- 样品空 -->

                    <!--                    <div-->
                    <!--                      class="err_specimen"-->
                    <!--                      v-if="-->
                    <!--                        marker == 1 &&-->
                    <!--                        New_circulation_order.Sample_complete_data.user == ''-->
                    <!--                      "-->
                    <!--                    >-->
                    <!--                      该样品没有发放记录，请联系样品管理员发放样品-->
                    <!--                    </div>-->
                  </el-col>
                  <el-col :span="9">
                    <div class="item_box" style="margin-left: 20px">
                      <div class="item_mian_text"><span class="star"></span>待检数量</div>

                      <div :class="New_sample.name === '' ? 'item_main_text_value_un' : 'item_main_text_value'">
                        {{ New_sample.name === '' ? '自动填入' : New_sample.name }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div class="item">
                <el-row>
                  <el-col :span="2"> </el-col>
                  <!--                  <el-col :span="7">-->
                  <!--                    <div class="item_box">-->
                  <!--                      <div class="item_mian_text">待检数量</div>-->
                  <!--                      <div-->
                  <!--                        :class="-->
                  <!--                          New_sample.name === ''-->
                  <!--                            ? 'item_main_text_value_un'-->
                  <!--                            : 'item_main_text_value'-->
                  <!--                        "-->
                  <!--                      >-->
                  <!--                        {{-->
                  <!--                          New_sample.name === "" ? "自动填入" : New_sample.name-->
                  <!--                        }}-->
                  <!--                      </div>-->
                  <!--                    </div>-->
                  <!--                  </el-col>-->
                  <el-col :span="7">
                    <div class="item_box" style="margin-left: -3px">
                      <div class="item_mian_text">在检数量</div>
                      <div :class="New_sample.name === '' ? 'item_main_text_value_un' : 'item_main_text_value'">
                        {{ New_sample.name === '' ? '自动填入' : New_sample.name }}
                      </div>
                    </div>
                  </el-col>
                  <!--                  <el-col :span="2"> </el-col>-->
                  <el-col :span="5"> </el-col>

                  <el-col :span="6">
                    <div class="item_box" style="margin-left: 20px">
                      <div class="item_mian_text">检毕数量</div>
                      <div :class="New_sample.name === '' ? 'item_main_text_value_un' : 'item_main_text_value'">
                        {{ New_sample.name === '' ? '自动填入' : 0 }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <!--              <div class="line"></div>-->
              <!--              <div class="item">-->
              <!--                <el-row>-->
              <!--                  <el-col :span="2"> </el-col>-->
              <!--                  <el-col :span="7">-->
              <!--                    <div class="item_box">-->
              <!--                      <div class="item_mian_text">领样日期</div>-->
              <!--                      <div-->
              <!--                        :class="-->
              <!--                          New_circulation_order.Sample_complete_data === ''-->
              <!--                            ? 'item_main_text_value_un'-->
              <!--                            : 'item_main_text_value'-->
              <!--                        "-->
              <!--                      >-->
              <!--                        {{-->
              <!--                          New_circulation_order.Sample_complete_data === ""-->
              <!--                            ? "自动填入"-->
              <!--                            : New_circulation_order.Sample_complete_data.date-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </el-col>-->
              <!--                  <el-col :span="7">-->
              <!--                    <div class="item_box">-->
              <!--                      <div class="item_mian_text">领样人</div>-->
              <!--                      <div-->
              <!--                        :class="-->
              <!--                          New_circulation_order.Sample_complete_data === ''-->
              <!--                            ? 'item_main_text_value_un'-->
              <!--                            : 'item_main_text_value'-->
              <!--                        "-->
              <!--                      >-->
              <!--                        {{-->
              <!--                          New_circulation_order.Sample_complete_data === ""-->
              <!--                            ? "自动填入"-->
              <!--                            : New_circulation_order.Sample_complete_data.user-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </el-col>-->
              <!--                  <el-col :span="7">-->
              <!--                    <div class="item_box">-->
              <!--                      <div class="item_mian_text">领样数量</div>-->
              <!--                      <div-->
              <!--                        :class="-->
              <!--                          New_circulation_order.Sample_complete_data === ''-->
              <!--                            ? 'item_main_text_value_un'-->
              <!--                            : 'item_main_text_value'-->
              <!--                        "-->
              <!--                      >-->
              <!--                        {{-->
              <!--                          New_circulation_order.Sample_complete_data === ""-->
              <!--                            ? "自动填入"-->
              <!--                            : New_circulation_order.Sample_complete_data.num-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </el-col>-->
              <!--                </el-row>-->
              <!--              </div>-->
              <!--              <div class="item">-->
              <!--                <el-row>-->
              <!--                  <el-col :span="2"> </el-col>-->
              <!--                  <el-col :span="7">-->
              <!--                    <div class="item_box">-->
              <!--                      <div class="item_mian_text">待检数量</div>-->
              <!--                      <div-->
              <!--                        :class="-->
              <!--                          New_sample.name === ''-->
              <!--                            ? 'item_main_text_value_un'-->
              <!--                            : 'item_main_text_value'-->
              <!--                        "-->
              <!--                      >-->
              <!--                        {{-->
              <!--                          New_sample.name === "" ? "自动填入" : New_sample.name-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </el-col>-->
              <!--                  <el-col :span="7">-->
              <!--                    <div class="item_box">-->
              <!--                      <div class="item_mian_text">在检数量</div>-->
              <!--                      <div-->
              <!--                        :class="-->
              <!--                          New_sample.name === ''-->
              <!--                            ? 'item_main_text_value_un'-->
              <!--                            : 'item_main_text_value'-->
              <!--                        "-->
              <!--                      >-->
              <!--                        {{-->
              <!--                          New_sample.name === "" ? "自动填入" : New_sample.name-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </el-col>-->
              <!--                  <el-col :span="7">-->
              <!--                    <div class="item_box">-->
              <!--                      <div class="item_mian_text">检毕数量</div>-->
              <!--                      <div-->
              <!--                        :class="-->
              <!--                          New_sample.name === ''-->
              <!--                            ? 'item_main_text_value_un'-->
              <!--                            : 'item_main_text_value'-->
              <!--                        "-->
              <!--                      >-->
              <!--                        {{-->
              <!--                          New_sample.name === "" ? "自动填入" : New_sample.name-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </el-col>-->
              <!--                </el-row>-->
              <!--              </div>-->
              <!--              <div class="item">-->
              <!--                <el-row>-->
              <!--                  <el-col :span="2"> </el-col>-->
              <!--                  <el-col :span="7">-->
              <!--                    <div class="item_box">-->
              <!--                      <div class="item_mian_text">归还日期</div>-->
              <!--                      <div-->
              <!--                        :class="-->
              <!--                          New_sample.name === ''-->
              <!--                            ? 'item_main_text_value_un'-->
              <!--                            : 'item_main_text_value'-->
              <!--                        "-->
              <!--                      >-->
              <!--                        {{-->
              <!--                          New_sample.name === "" ? "自动填入" : New_sample.name-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </el-col>-->
              <!--                  <el-col :span="7">-->
              <!--                    <div class="item_box">-->
              <!--                      <div class="item_mian_text">归还人</div>-->
              <!--                      <div-->
              <!--                        :class="-->
              <!--                          New_sample.name === ''-->
              <!--                            ? 'item_main_text_value_un'-->
              <!--                            : 'item_main_text_value'-->
              <!--                        "-->
              <!--                      >-->
              <!--                        {{-->
              <!--                          New_sample.name === "" ? "自动填入" : New_sample.name-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </el-col>-->
              <!--                  <el-col :span="7">-->
              <!--                    <div class="item_box">-->
              <!--                      <div class="item_mian_text">归还数量</div>-->
              <!--                      <div-->
              <!--                        :class="-->
              <!--                          New_sample.name === ''-->
              <!--                            ? 'item_main_text_value_un'-->
              <!--                            : 'item_main_text_value'-->
              <!--                        "-->
              <!--                      >-->
              <!--                        {{-->
              <!--                          New_sample.name === "" ? "自动填入" : New_sample.name-->
              <!--                        }}-->
              <!--                      </div>-->
              <!--                    </div>-->
              <!--                  </el-col>-->
              <!--                </el-row>-->
              <!--              </div>-->
              <div class="item">
                <el-row>
                  <el-col :span="3"> </el-col>
                  <el-col :span="7">
                    <div class="item_box" style="margin-left: -6px">
                      <div class="item_mian_text1">备注</div>
                      <el-input v-model="New_circulation_order.Remark" maxlength="100" placeholder="请输入备注" show-word-limit type="textarea" />
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>

          <div class="information_box">
            <div class="item_title_box"></div>
            <div class="item_main_box box1">
              <div class="top_title">
                <div class="blueBar"></div>
                <span class="title1">样品领样记录信息 </span>
                <span class="title2">自动填入</span>
              </div>
              <!--                                border: '1px solid #eeeeee !important',
-->
              <div class="table">
                <el-table
                  :header-cell-style="{
                    // 'text-align': 'center',
                    'font-size': '16px !important',
                    background: '#F8F8F8 !important',
                    color: '#313233',
                    'font-weight': 400,
                  }"
                  :data="tableData"
                  border
                  style="width: 100%">
                  <el-table-column
                    :cell-style="{
                      'text-align': 'center',
                      'vertical-align': 'middle',
                    }"
                    label="序号"
                    width="60">
                    <template #default="scope">
                      <div style="text-align: center">
                        {{ scope.$index + 1 }}
                      </div>
                      <!-- </div> -->
                    </template>
                    <!--                    <template #default="scope">-->
                    <!--                      <div>{{ scope.row.id }}</div>-->
                    <!--                      &lt;!&ndash; </div> &ndash;&gt;-->
                    <!--                    </template>-->
                  </el-table-column>
                  <el-table-column prop="date" label="领样日期" width="180" />
                  <el-table-column prop="user" label="领样人" width="300" />
                  <el-table-column prop="num" label="领样数量" width="180" />
                  <el-table-column prop="remark" label="备注" width="260" />
                </el-table>
              </div>
            </div>
            <div class="item_main_box box2">
              <div class="top_title">
                <div class="blueBar"></div>
                <span class="title1">样品归还记录信息 </span>
                <span class="title2">自动填入</span>
              </div>
              <!--                                border: '1px solid #eeeeee !important',
-->
              <div class="table">
                <el-table
                  :header-cell-style="{
                    // 'text-align': 'center',
                    'font-size': '16px !important',
                    background: '#F8F8F8 !important',
                    color: '#313233',
                    'font-weight': 400,
                  }"
                  :data="tableData2"
                  border
                  style="width: 100%">
                  <el-table-column
                    :cell-style="{
                      'text-align': 'center',
                      'vertical-align': 'middle',
                    }"
                    prop="index"
                    label="序号"
                    width="60" />
                  <el-table-column prop="sampleCollectionDate" label="归还日期" width="180" />
                  <el-table-column prop="sampleCollectionPenson" label="归还人" width="300" />
                  <el-table-column prop="sampleCollectionNum" label="归还数量" width="180" />
                  <el-table-column prop="notes" label="备注" width="316" />
                </el-table>
              </div>
            </div>
          </div>
        </div>
        <!-- 抽屉底部 -->
        <div class="drawer_footer">
          <div class="null">
            <img @click="cencelDataChange" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next">
            <img @click="Create_stream" src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" />
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- !项目样品详情页面 -->
    <keep-alive>
      <Projectsample v-if="store.state.Project.sample_status == 2"></Projectsample>
    </keep-alive>
    <!-- 导出 -->
    <!-- 删除 -->
    <!--  //查看流转详情 -->
    <el-drawer
      style="padding: 0"
      v-model="isParticulars_circulation"
      :close-on-click-modal="isParticulars_circulation"
      title="I am the title"
      :with-header="false"
      size="57%">
      <div class="drawer">
        <div class="deawer_title">
          <div class="deawer_title_text">流转单信息</div>
          <div>
            <img @click="change_state" v-if="!is_change_state" src="https://newstore.vynior.com/%E7%BC%96%E8%BE%91.png" alt="" />
          </div>
        </div>
        <div class="drawer_body">
          <!-- 编辑抽屉显示 -->
          <div class="information_box">
            <div class="item_title_box"></div>
            <div class="item_main_box">
              <div class="item1">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>流转单编号</div>
                      <div class="item_main_text_value">{{ information.form_code }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>检测项目编号及名称</div>
                      <div class="item_main_text_value">
                        {{ information.project_name }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1"><span class="star">*</span>样品</div>
                      <div class="item_main_text_value">
                        {{ information.sample_name }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="line"></div>
              <div class="item">
                <el-row>
                  <el-col :span="2"> </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">领样日期</div>
                      <div class="item_main_text_value">
                        {{ information.get_date === '' ? '——' : information.get_date }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">领样人</div>
                      <div class="item_main_text_value">
                        {{ information.getuser_name === '' ? '——' : information.getuser_name }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">领样数量</div>
                      <div class="item_main_text_value">
                        {{ information.take_total === '' ? '——' : information.take_total }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="2"> </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">待检数量</div>
                      <div class="item_main_text_value">
                        {{ information.await_total === '' ? '——' : information.await_total }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">在检数量</div>
                      <div class="item_main_text_value">
                        {{ information.under_total === '' ? '——' : information.under_total }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">检毕数量</div>
                      <div class="item_main_text_value">
                        {{ information.completed_total === '' ? '——' : information.completed_total }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="2"> </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">归还日期</div>
                      <div class="item_main_text_value">
                        {{ information.return_date == '' ? '——' : information.return_date }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">归还人</div>
                      <div class="item_main_text_value">
                        {{ information.returnuser_name == '' ? '——' : information.returnuser_name }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">归还数量</div>
                      <div class="item_main_text_value">
                        {{ information.return_total === '' ? '——' : information.return_total }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>

              <div class="item" v-if="is_change_state">
                <el-row>
                  <el-col :span="3"> </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text1">备注</div>
                      <el-input v-model="demo1.remark" maxlength="100" placeholder="请输入备注" show-word-limit type="textarea" />
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item" v-if="!is_change_state">
                <el-row>
                  <el-col :span="2"> </el-col>
                  <el-col :span="7">
                    <div class="item_box">
                      <div class="item_mian_text">备注</div>
                      <div class="item_main_text_value">
                        {{ information.remark == '' ? '——' : information.remark }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>

        <!-- 抽屉底部 -->
        <div class="drawer_footer" v-if="is_change_state">
          <div class="null">
            <img @click="cancelCreatedClick" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next">
            <img @click="Create_remark" src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" />
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- !删除的提示 -->
    <div class="Derived_volunteer_delect" v-if="isDerived_delect">
      <div class="Derived_volunteer_box">
        <!-- 批量操作删除一个 -->
        <div v-if="selectedRows.length == 1 && oneDerivedData == null">
          <div class="Derived_title">
            已选中&nbsp;&nbsp;
            <span class="Derived_name">
              {{ selectedRows[0].sample_name == ' ' ? '无名字' : selectedRows[0].sample_name }}
            </span>
            &nbsp;&nbsp; 样品流转单,确认删除吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isDerived_delect = !isDerived_delect">取消</div>
            <div class="ok" @click="deledct_arr">删除</div>
          </div>
        </div>
        <!-- 批量操作删除多个 -->
        <div v-if="selectedRows.length > 1 && oneDerivedData == null">
          <div class="Derived_title">
            已选中&nbsp;&nbsp;<span class="Derived_name">{{ selectedRows.length }}条</span>&nbsp;&nbsp; 样品流转单，确认删除吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isDerived_delect = !isDerived_delect">取消</div>
            <div class="ok" @click="deledct_arr">删除</div>
          </div>
        </div>
        <!-- !单个删除操作 -->
        <div v-if="oneDerivedData">
          <div class="Derived_title">
            已选中&nbsp;&nbsp;<span class="Derived_name"> {{ OptionsData.sample_name }} </span>&nbsp;&nbsp;样品流转单，确认删除吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isDerived_delect = !isDerived_delect">取消</div>
            <div class="ok" @click="deldect">删除</div>
          </div>
        </div>
      </div>
    </div>
    <!-- !导出的提示 -->
    <div class="Derived_volunteer" v-if="isDerived">
      <div class="Derived_volunteer_box">
        <!-- 导出一个 -->
        <div v-if="checkedArr.length == 1">
          <div class="Derived_title">
            已选中<span class="Derived_name">{{ selectedRows[0].sample_name == ' ' ? '无名字' : selectedRows[0].sample_name }}</span
            >样品流转单，确认导出吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">导出后可在excel表格中进行查看</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isDerived = !isDerived">取消</div>
            <div class="ok" @click="EXPORT">确认</div>
          </div>
        </div>
        <!-- 导出多个 -->
        <div v-if="checkedArr.length > 1">
          <div class="Derived_title">
            已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>样品流转单，确认导出吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">导出后可在excel表格中进行查看</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isDerived = !isDerived">取消</div>
            <div class="ok" @click="EXPORT">确认</div>
          </div>
        </div>
      </div>
    </div>
    <!-- !导出无数据 -->
    <div class="Derived_volunteer_null" v-if="null_reminder_export">
      <div class="Derived_volunteer_box">
        <div class="Derived_title">
          <div class="Derived_name">提示</div>
          <div>
            <img @click="null_reminder_export = !null_reminder_export" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
          </div>
        </div>
        <div class="Derived_body">
          <div>
            <img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" />
          </div>
          <div class="Derived_body_text">请选择需要导出的样品流转数据!</div>
        </div>
      </div>
    </div>
    <!-- !删除无数据 -->
    <div class="Derived_volunteer_null" v-if="null_reminder_delect">
      <div class="Derived_volunteer_box">
        <div class="Derived_title">
          <div class="Derived_name">提示</div>
          <div>
            <img @click="null_reminder_delect = !null_reminder_delect" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
          </div>
        </div>
        <div class="Derived_body">
          <div>
            <img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" />
          </div>
          <div class="Derived_body_text">请选择需要删除的样品流转数据!</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
// ! 测试报告审批
// import ReportApproval from "@/components/Reportapproval/ReportApproval.vue"
import { defineComponent, ref, reactive, nextTick, toRaw, toRefs, provide, onMounted, computed, onBeforeUnmount, watch } from 'vue';
import Projectsample from '@/components/Project_sample/Project_sample.vue';
import { VxeTableInstance } from 'vxe-table';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import XEUtils from 'xe-utils';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
//import CreatedVolunteer from '@/components/SampleManagement/CreateSample.vue';
import getFilename from '@/utils/getFilename';
import dayjs from 'dayjs';
import * as xlsx from 'xlsx';
import { writeFileXLSX } from 'xlsx-js-style';
import cityData from '@/utils/citydata';
import { ElMessage, ElMessageBox } from 'element-plus';
import axios from 'axios';

const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

//API接收
const store = useStore();
const router = useRouter();
const xTable = ref();
// table组件数据
let totalCount = ref(0);
const SetTableHeader = ref(false);
const tableData2 = ref([]);

//样品领样记录信息
const tableData = ref([
  // {
  //   index: "1",
  //   sampleCollectionDate: "2016-05-02",
  //   sampleCollectionPenson: "人",
  //   sampleCollectionNum: "12",
  //   notes: "备注",
  // },
  // {
  //   index: "2",
  //
  //   sampleCollectionDate: "2016-05-02",
  //   sampleCollectionPenson: "人",
  //   sampleCollectionNum: "12",
  //   notes: "备注",
  // },
  // {
  //   index: "3",
  //
  //   sampleCollectionDate: "2016-05-02",
  //   sampleCollectionPenson: "人",
  //   sampleCollectionNum: "12",
  //   notes: "备注",
  // },
  // {
  //   index: "4",
  //   sampleCollectionDate: "2016-05-02",
  //   sampleCollectionPenson: "人",
  //   sampleCollectionNum: "12",
  //   notes: "备注",
  // },
]);

// !demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [],
  // tableData: [],
  remark: '',
  filterName: '',
  loading: false,
  list: [],
  rename: '',
});
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 15,
  totalResult: 0,
});
const New_circulation_order = ref({
  Order_number: '', // 流转单编号
  Test_item_number_name: '', // 检测项目编号及名称
  Specimen: '', // 样品
  Sample_complete_data: '', // 样品全部数据
  Remark: '', // 备注
}); // ! 新建流转单数据
// ! 搜索的数据
const options_data_id = ref([]); //项目
const options_data = ref([]); // 样品
// !项目全部人id
const main_id = ref([]);
const marker = ref(0);
let SORT = ref({ createdAt: -1 }); // sort排序字段
// 点击表头的下拉箭头
const OperationBox = ref(null); //获取更多操作盒子的ref
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1);
let ClickMoreOptions = ref(false);
const CMoreOptions = ref(null); //获取MoreOptions盒子的ref

let EventSearch = ref(false); // 搜索
let Volunteereditor = ref(false); // 新建
const checkedArr = ref([]); //多选框
var selectedRows = reactive([]); //复选框数组
let isshowOperation = ref(false); //批量操作下拉框
let isParticulars_circulation = ref(false); // ! 查看流转详情
let is_change_state = ref(false);
const SampleFlowRecord_options = ref([]); //!项目流转记录下拉框数据
let isDerived_delect = ref(false); //删除的提示框
let OptionsData = ref(); //单个删除的数据
let isDerived = ref(false); //导出提示框
let null_reminder_export = ref(false); //没有导出数据
let null_reminder_delect = ref(false); // 没有删除数据
const New_sample = reactive({
  name: '',
});

// 判断是否有领样记录信息
let lingyang = ref(false);
// 显示功能的字段设置
let AllShowHeader = ref([]);
// 全部显示里被隐藏的
let AllHidenHeader = ref([]);
const SetShow = ref(false);
const ShowBox = ref(null); //获取Show盒子的ref
const ShowCheck = ref([]);
let information = ref({}); // !点击的志愿者信息
const oneDerivedData = ref(null);
//导出弹窗显示隐藏
let dialogShow = ref(false);
let uploadPercentage = ref(0);
const fileLists = ref([]);
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params;
  console.log(columns, '表头的顺序');
  // // 打印排序后的表头列数组对象
};
// ! 下拉箭头
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value :>> ', value);
  const table = xTable.value;
  const FixedList = table.getColumns();
  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index;
    }
  });
  //console.log('FixedIndex下标为 :>> ', FixedIndex);
  showIdOptions.value = false;
  showIdOptions.value = true;
  // 设置位置
  const Event = event;
  OperationBox.value.style.left = event.clientX - 100 + 'px';
};
// !tip tootip提示方法
const tooltipConfig = reactive({
  showAll: true,
  enterable: true,
  contentMethod: ({ type, column, row, items, _columnIndex }) => {
    const { field } = column;

    // column 判断 column.title 是否为'操作'
    // 重写默认的提示内容
    if (column.title == '操作') {
      return (tooltipConfig.showAll = false);
    } else if (field === 'rate') {
      // 返回空字符串，控制单元格不显示提示内容
      return '';
    } else {
      tooltipConfig.showAll = true;
    }
    // 其余的单元格使用默认行为

    return null;
  },
});
// !  切换改变状态
const change_state = () => {
  if (!store.state.role_info.includes('sample-flowRecords-edit')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  demo1.remark = JSON.parse(JSON.stringify(information.value.remark));
  is_change_state.value = true;
};
// !复选框点击
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  console.log(checked ? '所有勾选事件' : '所有取消事件', records);
  selectedRows = records;
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id);
      }
    }
    console.log('最新:', checkedArr.value);
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = [];

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id);
    });

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item);
    });
    console.log('最新:', checkedArr.value);
  }
};

//!  !!!获取全部流转数据
const reqProjectList = async () => {
  try {
    const res = await http.post('/sample/findCirculateForm', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      filter: demo1.filterName,
    });
    console.log('res :>> ', res);
    page5.totalResult = res.result.totalCount;
    demo1.tableData = res.result.forms;
    console.log('demo1.tableData', res.result);
    if (res.status == 0) {
      demo1.loading = false;
    }
  } catch (error) {
    console.log(error);
  }
};
// 点击新建样品流转记录;
const VolunteereditorChange = () => {
  console.log('未执行', Volunteereditor.value);

  if (!store.state.role_info.includes('sample-flowRecords-created')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }

  console.log('未执行');
  New_circulation_order.value.Order_number = '';
  New_circulation_order.value.Test_item_number_name = '';
  New_circulation_order.value.Specimen = '';
  Volunteereditor.value = !Volunteereditor.value;
};
// !!!重置按钮
const reset = () => {
  demo1.filterName = '';
  SORT.value = { createdAt: -1 };
  reqProjectList();
  const $table = xTable.value;
  $table.clearCheckboxRow();
};
// !复选框点击
const selectChangeEvent1 = (row) => {
  // console.log('点击的id:', row.rowid);
  console.log('rowidididid :>> ', row, row.row._id);

  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records;

  if (checkedArr.value.indexOf(row.row._id) == -1) {
    checkedArr.value.push(row.row._id);
  } else {
    let index = checkedArr.value.indexOf(row.row._id);
    checkedArr.value.splice(index, 1);
  }
  console.log('最新:', checkedArr.value);
};

const hiddenOperationBox = () => {
  showIdOptions.value = false;
  //console.log('点击点击 :>> ');
};
// !More -->升序/降序
const AscendingSort = async (index, type) => {
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  const ColumnId = tableColumns[index].field;
  console.log(ColumnId, 'ColumnId');
  const sorts = {};
  if (type == 'Asc') {
    if (ColumnId == 'form_code') {
      sorts[ColumnId] = 1;
    } else {
      sorts[ColumnId.replace(/_/g, '.')] = 1;
    }
  } else {
    if (ColumnId == 'form_code') {
      sorts[ColumnId] = -1;
    } else {
      sorts[ColumnId.replace(/_/g, '.')] = -1;
    }
  }

  SORT.value = sorts;
  console.log(SORT.value, 'SORT.value');
  reqProjectList();
};

// !分页--->跳转页面显示数据处理
const pageChange = async () => {
  // 判断是否是高级搜索模式
  // 不是高级搜索就是普通的数据
  reqProjectList();
};
const cancelCreatedClick = () => {
  is_change_state.value = false;
};
// ! 编辑 备注
const Create_remark = async () => {
  const res = await http.post('/sample/editFormRemark', {
    formId: information.value.sampleId,
    remark: information.value.remark,
  });
  console.log(res, '编辑流转单备注');
  if (res.status == 0) {
    information.value.remark = JSON.parse(JSON.stringify(demo1.remark));
    is_change_state.value = false;
    message({
      message: '编辑成功',
      ShowClose: true,
      type: 'success',
    });
  } else {
    message({
      message: '编辑失败',
      ShowClose: true,
      type: 'error',
    });
  }
  // try {
  //   await http.post('/sample/editFormRemark', {
  //     formId: information.value.sampleId,
  //     remark: information.value.remark,
  //   });
  //   message({
  //     message: '编辑成功',
  //     ShowClose: true,
  //     type: 'success',
  //   });
  //   isParticulars_circulation.value = false;
  // } catch (error) {
  //   message({
  //     message: '编辑失败',
  //     ShowClose: true,
  //     type: 'error',
  //   });
  //   console.log(error);
  // }
};
const handlePreviews = (file) => {
  console.log(file, 'handlePreviews');
};

// !每一行的查看详情
const ShowDetails = (row) => {
  console.log('固定信息2:', row, row._id);
  console.log(row);
  information.value = row;
  is_change_state.value = false;
  isParticulars_circulation.value = true;
};
console.log(information.value, '123');

// //! 新建逻辑
// watch(
//   () => Volunteereditor.value,
//   () => {
//     if (Volunteereditor.value === true) {
// reqProjectList();
//       get_Test_item_number_name();
//     }
//   },
// );
//! 实时搜索课选择的项目
const get_Test_item_number_name = async (val) => {
  const res = await http.post('/sample/bindSampleProject', {
    filter: val,
  });
  console.log(res, '返回项目可选项');
  options_data_id.value = res.result;
  // try {
  //   let { options } = await http.post('/team/getProjectOptionsBySample-team', {
  //     userId: localStorage.getItem('_id'),
  //   });
  //   console.log('option is 1', options);
  //   let arr = [];
  //   options.forEach((item, index) => {
  //     arr.push({
  //       value: item._id,
  //       label: item.label,
  //     });
  //   });
  //   console.log('arr is 1', arr);
  //   options_data_id.value = arr;
  //   marker.value = 1;
  // } catch (error) {
  //   console.log(error);
  // }
};
// !选择项目id
const change_id = () => {
  console.log('触发事件');
  console.log(New_circulation_order.value.Test_item_number_name, 'idididid');
  get_Specimen(New_circulation_order.value.Test_item_number_name);
  New_sample.name = '';
};
//! 通过项目请求样品
const get_Specimen = async (id) => {
  // ! 如果走的不是创建 ，走的就是编辑
  let data_id;
  if (New_circulation_order.value.Test_item_number_name == '') {
    data_id = information.value.project_name;
  } else {
    data_id = New_circulation_order.value.Test_item_number_name;
  }
  const res = await http.post('/sample/getBindSampleOptions', {
    projectId: data_id,
  });
  console.log(res, '获取项目下的样品');
  options_data.value = res.result;
  // try {
  //   let { options } = await http.post('/sample/getBindSampleOptions', {
  //     projectId: data_id,
  //   });
  //   let arr = [];
  //   options.forEach((item, index) => {
  //     arr.push({
  //       value: item._id,
  //       label: item.label,
  //     });
  //   });
  //   options_data.value = arr;
  // } catch (error) {
  //   console.log(error);
  // }
};
//  More -->固定/取消固定
const toggleFixedColumn = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  console.log(index, type, '获取的表格数据');
  showIdOptions.value = false;
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
const toggleFixedColumns = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false;
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
// 获取当前的全部数据
const TransferData = () => {
  // 将全部显示的数据  放到 AllShowHeader中
  SetVolunteerHeaderArr.value.forEach((item, index) => {
    if (item.checked == true) {
      AllShowHeader.value.push(item);
    }
  });
  console.log('AllShowHeader.value :>> ', AllShowHeader.value);
};
// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false;
  const $table = xTable.value;
  const column = $table.getColumns();
  // console.log('column[index] :>> ', column[index]);
  column[index].visible = false;
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value.push(column[index]);
  ShowCheck.value.push(false);
  $table.refreshColumn(); //刷新列表
  console.log('ShowChcke.value :>> ', ShowCheck.value);
  // console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
};
// !  选择样品id
const change_Specimen = (val) => {
  console.log(options_data_id.value, val, '触发事件');
  let obj = options_data.value.filter((item) => item.value == val);
  console.log(obj, 'obj');
  if (obj[0].take_total > 0) {
    New_sample.name = obj[0].take_total;
  } else {
    New_sample.name = '';
    New_circulation_order.value.Specimen = '';
    message({
      message: '无样品领样记录信息',
      ShowClose: true,
      type: 'warning',
    });
  }
  // get_Specimen_data(New_circulation_order.value.Specimen);
  // console.log(New_circulation_order.value.Specimen);
};
// ! 搜索
const searchEvent2 = async () => {
  reqProjectList();
};
let defaultHeader = ref(['样品编号', '样品名称', '样品总数量', '可发放数量', '操作']);

// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([]);
//表头字段设置--->设置表头的勾选数据
let SetVolunteerHeaderArr = ref([
  { fixed: '', field: 'sample_number', checked: true, lable: '样品编号' },
  { fixed: '', field: 'sample_name', checked: true, lable: '样品名称' },
  { fixed: '', field: 'sample_total', checked: true, lable: '样品总数量' },
  {
    fixed: '',
    field: 'available_quantity',
    checked: true,
    lable: '可发放数量',
  },
  { fixed: '', field: 'sample_tag', checked: false, lable: '样品标签' },
  { fixed: '', field: 'spec', checked: false, lable: '样品规格' },
  { fixed: '', field: 'character', checked: false, lable: '样品性状' },
  { fixed: '', field: 'batchNum', checked: false, lable: '样品批号' },
  { fixed: '', field: 'saveCondition', checked: false, lable: '储存条件' },
  { fixed: '', field: 'receiptDate', checked: true, lable: '样品接收日期' },
  { fixed: '', field: 'limitDate', checked: true, lable: '限制使用日期' },
  { fixed: '', field: 'expired', checked: false, lable: '是否过期' },
  { fixed: '', field: 'destory', checked: false, lable: '是否销毁' },
  {
    fixed: '',
    field: 'suggestTest',
    checked: false,
    lable: '建议检测项目类别',
  },
  { fixed: '', field: 'usefunc', checked: false, lable: '样品使用方法' },
  { fixed: '', field: 'management', checked: true, lable: '样品管理员' },
  { fixed: '', field: 'files', checked: false, lable: '附件' },
  { fixed: '', field: 'remark', checked: false, lable: '备注' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  {
    fixed: '',
    field: 'project_number',
    checked: true,
    lable: '样品检测项目编号',
  },
  {
    fixed: '',
    field: 'project_name',
    checked: true,
    lable: '样品检测项目名称',
  },
  { fixed: '', field: 'test_status', checked: true, lable: '样品检测状态' },
]);
// 表头字段设置--->恢复默认字段
let DefaultHeaderData = ref([
  { fixed: '', field: 'sample_number', checked: true, lable: '样品编号' },
  { fixed: '', field: 'sample_name', checked: true, lable: '样品名称' },
  { fixed: '', field: 'sample_total', checked: true, lable: '样品总数量' },
  {
    fixed: '',
    field: 'available_quantity',
    checked: true,
    lable: '可发放数量',
  },
  { fixed: '', field: 'sample_tag', checked: false, lable: '样品标签' },
  { fixed: '', field: 'spec', checked: false, lable: '样品规格' },
  { fixed: '', field: 'character', checked: false, lable: '样品性状' },
  { fixed: '', field: 'batchNum', checked: false, lable: '样品批号' },
  { fixed: '', field: 'saveCondition', checked: false, lable: '储存条件' },
  { fixed: '', field: 'receiptDate', checked: false, lable: '样品接收日期' },
  { fixed: '', field: 'limitDate', checked: false, lable: '限制使用日期' },
  { fixed: '', field: 'expired', checked: false, lable: '是否过期' },
  { fixed: '', field: 'destory', checked: false, lable: '是否销毁' },
  {
    fixed: '',
    field: 'suggestTest',
    checked: false,
    lable: '建议检测项目类别',
  },
  { fixed: '', field: 'usefunc', checked: false, lable: '样品使用方法' },
  { fixed: '', field: 'management', checked: false, lable: '样品管理员' },
  { fixed: '', field: 'files', checked: false, lable: '附件' },
  { fixed: '', field: 'remark', checked: false, lable: '备注' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  {
    fixed: '',
    field: 'project_number',
    checked: false,
    lable: '样品检测项目编号',
  },
  {
    fixed: '',
    field: 'project_name',
    checked: false,
    lable: '样品检测项目名称',
  },
  { fixed: '', field: 'test_status', checked: false, lable: '样品检测状态' },
]);

// 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true;
  TemporaryData.value = JSON.parse(JSON.stringify(SetVolunteerHeaderArr.value));

  //console.log('SetTableHeader', SetTableHeader.value);
};
//  表头字段设置  恢复默认字段
// const RecoverData = () => {
//   console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value);
//   // let defaultdata = readonly(DefaultHeaderData)
//   SetVolunteerHeaderArr.value = JSON.parse(JSON.stringify(DefaultHeaderData.value));
// };
// 表头字段设置 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false;
  SetVolunteerHeaderArr.value = JSON.parse(JSON.stringify(TemporaryData.value));
};

// // 表头阻断设置  的点击复选框
const CheckHeader = (i) => {
  // console.log('SetTableHeaderArr(value.checked) :>> ', SetTableHeaderArr.value[i]);
  // console.log('isReactive(SetTableHeaderArr) :>> ', isRef(SetTableHeaderArr));
  if (defaultHeader.value.indexOf(SetVolunteerHeaderArr.value[i].lable) != -1) {
    message({
      message: `${SetVolunteerHeaderArr.value[i].lable}不能删除`,
      grouping: true,
      type: 'error',
    });
    SetVolunteerHeaderArr.value[i].checked = true;
    return;
  }
};
// note 全局加载完毕之后执行此事件
nextTick(() => {
  // setHeaderColumn();
  // 执行 列表显示/隐藏
  // ShowHidenColumn();
  // 固定列表本地数据
  FixedColumn();

  // 转移数据
  TransferData();
});

// 检查是否有本地数据()
const setHeaderColumn = () => {
  if (localStorage.getItem('Sample_List') == null) {
    // ////console.log('"第一次" :>> ', '第一次');
  } else {
    // ////console.log('"第二次" :>> ', '第二次');
    SetVolunteerHeaderArr.value = JSON.parse(localStorage.getItem('Sample_List'));
  }
};
// ShowHidenColumn 设置column的的列表(确认按钮)
const ShowHidenColumn = () => {
  const $table = xTable.value;
  const column = $table.getColumns();

  //console.log('$table.getColumns :>> ', $table.getColumns());

  for (let i = 0; i < SetVolunteerHeaderArr.value.length; i++) {
    if (SetVolunteerHeaderArr.value[i].checked == true) {
      // //console.log('object :>> ', SetVolunteerHeaderArr[i].field);
      $table.showColumn(SetVolunteerHeaderArr.value[i].field);
    }
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetVolunteerHeaderArr.value[i].lable) {
        if (SetVolunteerHeaderArr.value[i].checked == false) {
          $table.hideColumn(column[j].field);
        }
      }
    }
  }
  $table.refreshColumn();
  //console.log('$table.getColumns :>> ', $table.getColumns());
  SetTableHeader.value = false;
};
// FixedColumn 设置column的固定列(初始化本地数据)
const FixedColumn = () => {
  const $table = xTable.value;
  const column = $table.getColumns();
  //console.log('column:>> ', column);
  //console.log('SetVolunteerHeaderArr', SetVolunteerHeaderArr.value);
  for (let i = 0; i < SetVolunteerHeaderArr.value.length; i++) {
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetVolunteerHeaderArr.value[i].lable) {
        if (SetVolunteerHeaderArr.value[i].fixed != '') {
          toggleFixedColumn(j, SetVolunteerHeaderArr.value[i].fixed);
        }
      }
    }
  }
  $table.refreshColumn();
  // //console.log('$table.getColumns :>> ', $table.getColumns());
  // SetTableHeader.value = false;
};
// ! 创建样品流转
const Create_stream = async () => {
  // ! 都有数据走接口
  if (!New_circulation_order.value.Order_number || !New_circulation_order.value.Test_item_number_name || !New_circulation_order.value.Specimen) {
    message({
      message: '请输入必填信息',
      showClose: true,
      type: 'warning',
    });
    return;
  }
  console.log('New_circulation_order :>> ', New_circulation_order.value);
  // if (lingyang.value) {
  //   try {
  console.log('main_id.value..value :>> ', main_id.value);

  // main_id.value.forEach((item, index) => {
  //   console.log('localStorage.get(id) :>> ', localStorage.getItem('_id'));
  //   if (localStorage.getItem('_id') == item._id) {
  //     drawUser = item._id;
  //   }
  // });
  // if (drawUser == '') {
  //   message({
  //     message: '用户权限错误',
  //     grouping: true,
  //     type: 'warning',
  //   });
  //   return;
  // }
  console.log('New_circulation_order.value :>> ', New_circulation_order.value);
  let { status } = await http.post('/sample/createCirculateForm', {
    num: Number(New_sample.name),
    code: New_circulation_order.value.Order_number,
    sampleId: New_circulation_order.value.Specimen,

    remark: New_circulation_order.value.Remark,
  });
  lingyang.value = false;
  if (status == 0) {
    Volunteereditor.value = false;
    reqProjectList();
  } else if (status == 1) {
    message({
      message: '流转单编号重复',
      ShowClose: true,
      type: 'warning',
    });
  } else if (status == 2) {
    message({
      message: '该产品已存在流转单',
      ShowClose: true,
      type: 'warning',
    });
  } else {
    message({
      message: '创建流转单成功',
      ShowClose: true,
      type: 'success',
    });
  }

  // } catch (error) {
  //   console.log(error);
  // }
  // } else {
  //   message({
  //     message: '无样品领样记录信息',
  //     ShowClose: true,
  //     type: 'warning',
  //   });
  // }
};
// ! 取消创建样品流转
const cencelDataChange = () => {
  Volunteereditor.value = !Volunteereditor.value;
  // !清空值
  New_circulation_order.value.Test_item_number_name = null;
  New_circulation_order.value.Specimen = null;
  New_circulation_order.value.Order_number = null;
  for (let prop in New_circulation_order.value.Sample_complete_data) {
    New_circulation_order.value.Sample_complete_data[prop] = '';
  }
};
// !获取全部用户id
const get_main_id = async () => {
  let { userOptions } = await http.post('/user/findOptions-user');
  main_id.value = userOptions;
};
// ! 跳转项目样品流转记录
const skip_project = (row) => {
  console.log(row, '点击的数据');
  store.commit('Set_sample_status', 2);
  console.log('项目编号，项目名称', row.project_number, row.project_name);
  store.commit('Set_Click_projectdata', row);
};
// ! 删除弹窗
const DELECT_box = () => {
  if (!store.state.role_info.includes('sample-flowRecords-batchdelete')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  // !没有勾选
  if (checkedArr.value.length == 0) {
    null_reminder_delect.value = !null_reminder_delect.value;
    return;
  }
  isDerived_delect.value = !isDerived_delect.value;
};
// !删除接口
const deldect = async (row) => {
  console.log(row, 'row');
  try {
    await http.post('/sample/deleteCirculateForm', {
      formId: OptionsData.value._id,
    });
    isDerived_delect.value = !isDerived_delect.value;
    message({
      showClose: true,
      message: '删除成功',
      type: 'success',
    });
    reqProjectList();
    oneDerivedData.value = null;
  } catch (error) {
    console.log(error);
  }
};
// !单个删除弹窗
const deldect_one = (row) => {
  if (!store.state.role_info.includes('sample-flowRecords-delete')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  OptionsData.value = row;
  isDerived_delect.value = !isDerived_delect.value;
  oneDerivedData.value = OptionsData.value;
};
// ! 批量导出接口
const EXPORT = async () => {
  try {
    let res = await http.post('/upload/export-flow', checkedArr.value);
    xlsx.writeFileXLSX(res.xlsxData, `样品流转记录 ${dayjs().format('YYYYMMDD')}.xlsx`);
    message({
      message: '导出成功',
      ShowClose: true,
      type: 'success',
    });
  } catch (error) {
    console.log(error);
    message({
      message: '导出失败',
      ShowClose: true,
      type: 'error',
    });
  }
};
// ! 导出弹窗
const EXPORT_box = () => {
  return;
  if (!store.state.role_info.includes('sample-flowRecords-batchexport')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  if (checkedArr.value.length == 0) {
    null_reminder_export.value = !null_reminder_export.value;
    return;
  }
  isDerived.value = !isDerived.value;
};
// !批量删除接口
const deledct_arr = async () => {
  try {
    await http.post('/flow/delete-flow', checkedArr.value);
    reqProjectList();
    isDerived_delect.value = !isDerived_delect.value;
  } catch (error) {
    console.log(error);
  }
};
// !  获取下拉框数据
const get_SampleFlowRecord_options = async () => {
  SampleFlowRecord_options.value = [];
  try {
    // console.log(project, project_id, "获取的数据");
    let { result } = await http.post('/sample/getCirculateFormProjectOptions');
    SampleFlowRecord_options.value = result;
    // let project = [];
    // let project_data = [];
    // options.forEach((item, index) => {
    //   project.push(options[index].project);
    //   project_data.push(options[index]);
    //   SampleFlowRecord_options.value.push({
    //     label: options[index].project,
    //     value: options[index].projectId,
    //   });
    // });
    // console.log(project, project_data, '获取的数据');
    //  存储vuex
    store.commit('Set_project', result);
    store.commit('Set_project_data', result);
  } catch (error) {
    console.log(error);
  }
};

watch(
  () => store.state.Project.sample_status,
  (newVale) => {
    console.log(newVale, '新的数据vuex');
  },
);
// !监听新建的编号即名称
watch(
  () => New_circulation_order.value.Test_item_number_name,
  () => {
    New_circulation_order.value.Specimen = '';
  },
);
const handlerDialog = () => {
  dialogFormVisible.value = true;
};
onMounted(() => {
  reqProjectList();
  get_SampleFlowRecord_options(); //请求下拉框数据
  // get_main_id();

  store.commit('Set_CurrentProjectName', '样品流转记录');
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // 操作
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false;
      }

      console.log(ClickMoreOptions.value);
    }
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
      console.log(ClickMoreOptions.value);
    }
    if (isshowOperation.value == true) {
      if (e.target.id != 'operationBox') {
        isshowOperation.value = false;
      }
      console.log(ClickMoreOptions.value);
    }
  });
});
</script>

<style lang="less" scoped>
@import url('@/style/views/SampleManagement/SampleFlowRecord.less');
</style>
