<template>
  <div id="volunteer_Custom_Settings">
    <div class="MainBoxs">
      <div class="Title">
        <div class="content">{{ Title }}</div>
      </div>
      <div class="Group" v-if="Title === '组别管理'">
        <div class="ContentBox">
          <div class="title">
            <span></span>
            <div class="text">系统预设分组</div>
          </div>
          <div class="ItemBoxs1">
            <div class="Full" v-for="(item, index) in defaultArrGroup" :key="index">
              <el-checkbox v-model="item.value" :label="item.name" size="large" />
            </div>
          </div>
          <!--  -->
        </div>
        <div class="ContentBox">
          <div class="title">
            <span></span>
            <div class="text">自定义组别</div>
          </div>
          <div class="ItemBoxs">
            <div class="ContentEmpty" v-if="GroupArr.length == 0">
              <div class="IMGS" v-show="defaultImg" @click="AddCustomItem">
                <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2081%20%E2%80%93%2059%402x.png" alt="" />
              </div>

              <div class="inputContent" v-show="!defaultImg">
                <el-input v-model="input" placeholder="请输入组别名称" />
                <div class="img1" @click="AddFists()">
                  <img class="comfirm" src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110563%402x.png" alt="" />
                </div>
                <div class="img2" @click="CloseInput">
                  <img class="cancel" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402xss.png" alt="" />
                </div>
              </div>
            </div>
            <div class="Full" v-if="GroupArr.length != 0">
              <div class="ContentVal" v-for="(item, index) in GroupArr" :key="index">
                <!-- 添加数据 -->
                <div class="ContentFull" v-show="item.name == 'empty'" :class="index >= 5 ? 'clearMargin' : ''">
                  <div class="IMGS" v-show="defaultImg" @click="AddCustomItem">
                    <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2081%20%E2%80%93%2061%402x.png" alt="" />
                  </div>
                  <!-- 输入框 -->
                  <div class="inputContent" v-show="!defaultImg">
                    <el-input v-model="input" placeholder="请输入组别名称" />
                    <div class="img1" @click="AddFists()">
                      <img class="comfirm" src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110563%402x.png" alt="" />
                    </div>
                    <div class="img2" @click="CloseInput">
                      <img class="cancel" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402xss.png" alt="" />
                    </div>
                  </div>
                </div>

                <!-- 已有数据 -->
                <div class="ValItem" v-show="item.name != 'empty'">
                  <el-checkbox v-model="item.value" :label="item.name" size="large" />
                  <img class="deleteimg" @click="Delete1(index)" src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402xs.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
        </div>
      </div>

      <div class="Tips" v-if="Title === '标签管理'">
        <div class="ContentBox">
          <div class="title">
            <span></span>
            <div class="text">系统预设标签</div>
          </div>
          <div class="ItemBoxs1">
            <div class="Full" v-for="(item, index) in defaultArrTips" :key="index">
              <el-checkbox v-model="item.value" :label="item.name" size="large" />
            </div>
          </div>
          <!--  -->
        </div>
        <div class="ContentBox">
          <div class="title">
            <span></span>
            <div class="text">自定义标签</div>
          </div>
          <div class="ItemBoxs">
            <div class="ContentEmpty" v-if="NameArr.length == 0">
              <div class="IMGS" v-show="defaultImg" @click="AddCustomItem">
                <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2081%20%E2%80%93%2061%402x.png" alt="" />
              </div>

              <div class="inputContent" v-show="!defaultImg">
                <el-input v-model="input" placeholder="请输入标签名称" />
                <div class="img1" @click="AddFist('NameArr')">
                  <img class="comfirm" src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110563%402x.png" alt="" />
                </div>
                <div class="img2" @click="CloseInput">
                  <img class="cancel" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402xss.png" alt="" />
                </div>
              </div>
            </div>
            <div class="Full" v-if="NameArr.length != 0">
              <div class="ContentVal" v-for="(item, index) in NameArr" :key="index">
                <!-- 添加数据 -->
                <div class="ContentFull" v-show="item.name == 'empty'" :class="index >= 5 ? 'clearMargin' : ''">
                  <div class="IMGS" v-show="defaultImg" @click="AddCustomItem">
                    <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2081%20%E2%80%93%2061%402x.png" alt="" />
                  </div>
                  <!-- 输入框 -->
                  <div class="inputContent" v-show="!defaultImg">
                    <el-input v-model="input" placeholder="请输入标签名称" />
                    <div class="img1" @click="AddFist('NameArr')">
                      <img class="comfirm" src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110563%402x.png" alt="" />
                    </div>
                    <div class="img2" @click="CloseInput">
                      <img class="cancel" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402xss.png" alt="" />
                    </div>
                  </div>
                </div>

                <!-- 已有数据 -->
                <div class="ValItem" v-show="item.name != 'empty'">
                  <el-checkbox v-model="item.value" :label="item.name" size="large" />
                  <img class="deleteimg" @click="Delete(index)" src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402xs.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <!--  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
import { useStore } from 'vuex';

const store = useStore();
// 初始数据空
const input = ref('');
const defaultImg = ref(true);

// 存储数组对应的名字;
const NameArr = ref([]);
// 存储数组组别
const GroupArr = ref([]);
// 默认组别数据
let defaultArrGroup = ref([]);
// 默认标签管理数据
let defaultArrTips = ref([]);
const AddCustomItem = () => {
  defaultImg.value = false;
};
let _id = ref('');
// 添加字段方法
const AddFist = (arr) => {
  // console.log('进入判断,最后一个为空', NameArr.value);
  if (NameArr.value.length != 0) {
    if (NameArr.value[NameArr.value.length - 1].name == 'empty') {
      // console.log('进入判断,最后一个为空');
      // 判断是否是空
      NameArr.value.splice(NameArr.value.length - 1, 1);
    }
  }

  if (input.value != '') {
    // 判断是否重复
    if (!NameArr.value.some((item) => item.name === input.value)) {
      NameArr.value.push({ name: input.value, value: false });

      defaultImg.value = true;
      // 增加一个空
      // if (NameArr.value[-1].name != 'empty') {
      NameArr.value.push({ name: 'empty', value: false });
      // }
      input.value = '';
      AddReq('add');
    } else {
      message({
        type: 'warning',
        showClose: true,
        message: '标签不可重复',
      });
      NameArr.value.push({ name: 'empty', value: false });
    }
  } else {
    NameArr.value.push({ name: 'empty', value: false });

    message({
      type: 'warning',
      showClose: true,
      message: '标签不可为空',
    });
  }
};

// 添加组别方法
const AddFists = () => {
  console.log('进入判断,最后一个为空', GroupArr.value);
  if (GroupArr.value.length != 0) {
    if (GroupArr.value[GroupArr.value.length - 1].name == 'empty') {
      console.log('进入判断,最后一个为空');
      // 判断是否是空
      GroupArr.value.splice(GroupArr.value.length - 1, 1);
    }
  }

  if (input.value != '') {
    // 判断是否重复
    if (!GroupArr.value.some((item) => item.name === input.value)) {
      GroupArr.value.push({ name: input.value, value: false });

      defaultImg.value = true;
      // 增加一个空
      // if (NameArr.value[-1].name != 'empty') {
      GroupArr.value.push({ name: 'empty', value: false });
      // }
      input.value = '';
      AddReq('add');
    } else {
      message({
        type: 'warning',
        showClose: true,
        message: '组别不可重复',
      });
      GroupArr.value.push({ name: 'empty', value: false });
    }
  } else {
    GroupArr.value.push({ name: 'empty', value: false });

    message({
      type: 'warning',
      showClose: true,
      message: '标签不可为空',
    });
  }
};

// 请求增加接口
const AddReq = async (val) => {
  let Allarr = []; //标签的
  let AllGroup = []; //组别的
  // 处理标签
  let arr = JSON.parse(JSON.stringify(NameArr.value));
  if (arr[arr.length - 1].name == 'empty') {
    // console.log('进入判断,最后一个为空');
    // 判断是否是空
    arr.splice(arr.length - 1, 1);
  }
  console.log('提交的组别标签数据是:', arr);
  // 处理组别
  let group = JSON.parse(JSON.stringify(GroupArr.value));
  if (group[group.length - 1]?.name == 'empty') {
    // console.log('进入判断,最后一个为空');
    // 判断是否是空
    group.splice(group.length - 1, 1);
  }
  console.log('提交的组别数据是:', group);
  // console.log('提交的数据是:', arr);
  // console.log(defaultArrTips.value);

  Allarr.push(defaultArrTips.value);
  Allarr.push(arr);

  AllGroup.push(defaultArrGroup.value);
  AllGroup.push(group);
  console.log('Allarr :>> ', Allarr);
  console.log('AllGroup :>> ', AllGroup);
  // 没有id 说明是新建的.
  if (_id.value === '') {
    const res = await http.post('/setting/add-setting', {
      type: 'volunteer',
      tags: Allarr,
      groups: AllGroup,
    });
    if (res.status == 0) {
      console.log('res新新创建数据返回 :>> ', res);
      if (val == 'delete') {
        message({
          type: 'success',
          showClose: true,
          message: '删除成功',
        });
      } else if (val == 'add') {
        message({
          type: 'success',
          showClose: true,
          message: '新建成功',
        });
      }
    }
  } else {
    // 有id 有初始数据.
    const res = await http.post('/setting/add-setting', {
      _id: _id.value,
      type: 'volunteer',
      tags: Allarr,
      groups: AllGroup,
    });
    if (res.status == 0) {
      console.log('前提是有初始数据的提交返回 :>> ', res);

      if (val == 'delete') {
        message({
          type: 'success',
          showClose: true,
          message: '删除成功',
        });
      } else if (val == 'add') {
        message({
          type: 'success',
          showClose: true,
          message: '新建成功',
        });
      }
    }
  }
};

// 请求初始数据
const ReqDefault = async () => {
  const res = await http.post('/setting/fetch-setting', {
    type: 'volunteer',
  });
  if (res.status == 0) {
    // 默认赋值

    defaultArrGroup.value = res.groups[0];
    defaultArrTips.value = res.tags[0];

    console.log('res请求初始数据为 :>> ', defaultArrTips.value);
    // 标签
    NameArr.value.splice(0, NameArr.value.length - 1);
    if (res.tags[1]) {
      res.tags[1].forEach((item, index) => {
        NameArr.value.push(item);
        if (res.tags[1].length - 1 == index) {
          NameArr.value.push({ name: 'empty', value: false });
        }
      });
    }

    console.log('获取的最终标签数据', NameArr.value);

    // ----------------
    // 组别
    GroupArr.value.splice(0, GroupArr.value.length - 1);
    if (res.groups[1]) {
      res.groups[1].forEach((item, index) => {
        GroupArr.value.push(item);
        if (res.groups[1].length - 1 == index) {
          GroupArr.value.push({ name: 'empty', value: false });
        }
      });
    }

    console.log('获取的最终标签数据', GroupArr.value);
    _id.value = res._id;
  } else {
    message({
      type: 'warning',
      showClose: true,
      message: '请创建数据',
    });
  }
};
// 关闭输入框
const CloseInput = () => {
  defaultImg.value = true;
  input.value = '';
};

//删除文本框
const Delete = (val) => {
  NameArr.value.splice(val, 1);

  console.log('删除之后的数据是', NameArr.value, val);
  AddReq('delete');
};
//删除文本框2
const Delete1 = (val) => {
  GroupArr.value.splice(val, 1);

  console.log('删除之后的数据是', GroupArr.value, val);
  AddReq('delete');
};
watch(
  () => store.state.user.Volnature,
  (newValue) => {
    Title.value = newValue;
    CloseInput();
  }
);
// 标题
const Title = ref('组别管理');
watch(
  () => NameArr.value,
  (newValue) => {
    console.log('数据监听', newValue);
    AddReq();
  },
  { deep: true }
);
watch(
  () => GroupArr.value,
  (newValue) => {
    console.log('数据监听', newValue);
    AddReq();
  },
  { deep: true }
);
onMounted(() => {
  ReqDefault();
  store.commit('Set_CurrentProjectName', '自定义设置');
});
</script>

<style lang="less" scoped>
@import url('@/style/views/VolunteerManagement/volunteer_Custom_Settings.less');
</style>
