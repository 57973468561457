<template>
  <div id="InstrumentList">
    <!-- 表格主体 -->
    <div class="VolunteerBox">
      <!-- 表内导航栏(筛选条件) -->
      <div class="project_main_state">
        <!-- 筛选status状态按钮 -->
        <div
          class="main_state_item"
          :class="index == ClickActive ? 'click-active' : ''"
          v-for="(item, index) in ProjectState"
          :key="index"
          @click="ClickActive = index">
          {{ item }}
        </div>
        <!-- 新建仪器/导入/批量 -->
        <div class="NewCreate" style="cursor: pointer" @click="CreatedDevice">
          <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%20152%402x.png" alt="" />
        </div>
        <div class="Import" @click="dialogShowChange">
          <!-- <el-upload
            class="upload_xlsx"
            ref="videoRef"
            accept=".xlsx,.xls"
            :http-request="uploadFile"
            :on-preview="handlePreviews"
            :on-remove="handleRemoves"
            :file-list="fileLists"
            :show-file-list="false"> -->
          <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2083%20%E2%80%93%207%402x.png" alt="" />
          <!-- </el-upload> -->
        </div>
        <div class="Batch" @click.stop="BatchBox = !BatchBox" :class="{ clicked_batch: BatchBox }">
          <span class="text">批量操作</span>
          <div class="arrow" v-show="!BatchBox">
            <img src="https://newstore.vynior.com/%E4%B8%8B%E6%8B%89.png" alt="" />
          </div>
          <div class="arrow" v-show="BatchBox">
            <img src="https://newstore.vynior.com/cems_web/blue.png" alt="" />
          </div>
        </div>
        <!-- 批量操作下拉框 -->
        <div class="batch_operation_box" id="operationBox" v-show="BatchBox">
          <div class="item_box">
            <div class="downImg"></div>
            <!-- <img src="https://newstore.vynior.com/%E5%AF%BC%E5%87%BA%402x.png" alt="" /> -->
            <div class="item_one_text" @click="isshowExport">导出</div>
          </div>
          <div class="item_box1">
            <img src="https://newstore.vynior.com/%E5%88%A0%E9%99%A4%402x.png" alt="" />
            <div class="item_two_text" style="margin-top: 0px" @click="delect">删除</div>
          </div>
        </div>
      </div>
      <!-- 表体 -->
      <div class="VolunteerTable">
        <div class="vxetable">
          <vxe-toolbar>
            <template #buttons>
              <vxe-input
                class="Search"
                v-model="demo1.filterName"
                type="search"
                placeholder="请输入仪器编号或名称进行搜索"
                @Blur="searchEvent2"></vxe-input>
              <vxe-button @click="reset" class="Reset"><span class="reset-icon">重置</span></vxe-button>
              <div class="Select" @click="ischangeShow">
                <img src="https://new.vynior.com/filter%402x.png" alt="" />
                <span class="text">高级搜索</span>
              </div>

              <vxe-button class="Show" :class="SetShow ? 'ShowHiden' : ''"
                ><span @click.stop="SetShowFunction" class="show-icon">显示</span></vxe-button
              >
            </template>
          </vxe-toolbar>

          <vxe-table
            v-show="!demo1.tableData || demo1.tableData.length != 0"
            border
            stripe
            ref="xTable"
            height="624"
            show-overflow
            row-id="_id"
            :data="tableData"
            :column-config="{ resizable: true, useKey: 'id' }"
            :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
            :checkbox-config="{ reserve: true }"
            :loading="demo1.loading"
            @header-cell-dragend="handleHeaderDragend"
            @checkbox-all="selectAllChangeEvent1"
            @checkbox-change="selectChangeEvent1">
            <vxe-column field="checked" type="checkbox" width="54" :fixed="demo1.colFixeds.col0"></vxe-column>
            <vxe-column
              v-for="(item, index) in allDatas"
              :key="index"
              :title="item.lable"
              :field="item.field"
              type="html"
              :width="item.width"
              min-height="36"
              :fixed="item.fixed"
              :min-width="item.min_width">
              <!-- 增加下拉箭头 -->
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="ID-header" v-if="column.title === item.lable && column.title != '操作'">
                  <span class="name">{{ item.lable }}</span>

                  <span v-if="item.lable != '操作'"
                    ><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item.lable, $event)"></i
                  ></span>
                </div>
                <div class="Operation-header" v-if="column.title === '操作'">
                  <span class="name names">操作</span>
                  <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                </div>
              </template>
              <template #default="{ row }">
                <div class="rows" v-if="item.lable == '操作'">
                  <el-tooltip class="box-item" effect="dark" content="查看详情" placement="top">
                    <vxe-button class="detail" @click="ShowDetails(row)" :class="row.instr_status == '报废' ? 'BaoFei' : ''">查看详情</vxe-button>
                  </el-tooltip>

                  <!-- <vxe-button class="detail" @click="ShowDetails(row)">开始维护</vxe-button> -->
                  <template v-if="row.instr_status == '待维护'">
                    <el-tooltip class="box-item" effect="dark" content="开始维护" placement="top">
                      <vxe-button class="copy" @click="Changestatus('开始维护', row)">开始维护</vxe-button>
                    </el-tooltip>
                  </template>
                  <template v-if="row.instr_status == '维护中'">
                    <el-tooltip class="box-item" effect="dark" content="维护完成" placement="top">
                      <vxe-button class="copy" @click="Changestatus('维护完成', row)">维护完成</vxe-button>
                    </el-tooltip>
                  </template>
                  <template v-if="row.instr_status == '正常'">
                    <el-tooltip class="box-item" effect="dark" content="申报维护" placement="top">
                      <vxe-button class="copy" @click="Changestatus('申报维护', row)">申报维护</vxe-button>
                    </el-tooltip>
                  </template>
                  <!-- <template v-if="row.status == '报废'">
                  <el-tooltip class="box-item" effect="dark" content="申报维护" placement="top">
                      <vxe-button class="copy" @click="Changestatus('申报维护', row)">申报维护</vxe-button>
                    </el-tooltip>
</template> -->
                  <span class="More" style="color: #027aff" id="CMoreOption" @click="ClickMethods(row, $event)">···</span>
                </div>
                <div class="rows" v-else-if="item.lable == '校准提醒'">
                  {{ row.calibration_alarm === true ? '是' : '否' }}
                </div>

                <div class="rows" v-else-if="item.lable == '仪器状态'">
                  <div
                    class="status"
                    :class="{
                      status_normal: row.instr_status == '正常',
                      status_tomaintained: row.instr_status == '待维护',
                      status_undermaintenance: row.instr_status == '维护中',
                      status_paper: row.instr_status == '报废',
                    }">
                    {{ row.instr_status }}
                  </div>
                </div>
                <div class="rows" v-else-if="item.lable == '下次校准日期'">
                  {{ row.calibration_nextdate != '——' ? dayjs(row.calibration_nextdate).format('YYYY-MM-DD') : '——' }}
                </div>
                <div class="rows" v-else-if="item.lable == '校准日期'">
                  {{ row.calibration_date != '——' ? dayjs(row.calibration_date).format('YYYY-MM-DD') : '——' }}
                </div>
                <div class="rows" v-else>
                  {{ formatterData(row[item.field]) }}
                </div>
              </template>
            </vxe-column>

            <template #empty>
              <span style="color: red">
                <!-- <img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif" /> -->
                <p>没有更多数据了！</p>
              </span>
            </template>
          </vxe-table>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
                <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')">
                  <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" />固定
                </li>
                <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" style="color: #bfbfbf">
                  <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" />取消固定
                </li>

                <li id="MoreOptions" @click="HiddenColumn(FixedIndex)"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li>
              </ul>
            </div>
          </div>
          <!-- todo -->
          <!-- 每一行的 ... -->
          <div class="Options" id="CMoreOption" ref="CMoreOptions" v-show="ClickMoreOptions">
            <div class="Copy" @click="showOneExport1">导出</div>
            <div class="Delete" @click="DeleteVolun(OptionsData)">删除</div>
          </div>
          <div class="Derived_volunteer" v-if="isshowOneExport1">
            <div class="Derived_volunteer_box" v-if="isshowOneExport1">
              <div v-if="isshowOneExport1">
                <div class="Derived_title">
                  确定导出<span class="Derived_name">{{ OptionsData.instr_name }}</span
                  >的仪器信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isshowOneExport1 = false">取消</div>
                  <div class="ok" @click="ExportFile('single')">确认</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 导出一个 -->
          <div class="Derived_volunteer" v-if="isshowOneExport">
            <div class="Derived_volunteer_box" v-if="isshowOneExport">
              <div v-if="isshowOneExport">
                <div class="Derived_title" v-if="checkedArr.length == 1">
                  确定导出<span class="Derived_name">{{ selectedRows[0].instr_name }}</span
                  >的仪器信息吗？
                </div>
                <div class="Derived_title" v-if="checkedArr.length > 1">
                  确定导出<span class="Derived_name">{{ checkedArr.length }}</span
                  >条仪器信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isshowOneExport = false">取消</div>
                  <div class="ok" @click="ExportFile">确认</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 导出一条没有的时候 -->
          <div class="Derived_volunteer_null" v-if="null_reminder_export">
            <!-- 导出 -->
            <div class="Derived_volunteer_box">
              <div class="Derived_title">
                <div class="Derived_name">提示</div>
                <div><img @click="null_reminder_export = false" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
              </div>
              <div class="Derived_body">
                <div><img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" /></div>
                <div class="Derived_body_text">请选择需要导出的仪器信息！</div>
              </div>
            </div>
          </div>
          <p>
            <vxe-pager
              v-show="!demo1.tableData || demo1.tableData.length != 0"
              class="Buttons"
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                  >条/共{{ page5.totalResult }}条
                </div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
    </div>
    <!-- !没有数据 -->
    <div class="EmptyProject" v-if="demo1.tableData && demo1.tableData.length == 0">
      <span>
        <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
        <p>暂无项目</p>
      </span>
    </div>
    <!-- ! 高级搜索 -->
    <div class="Select_box" v-if="changeShow">
      <div class="Select_main">
        <!-- 头部退出 -->
        <div class="Select_top">
          <div class="Select_titel">高级搜索</div>
          <div>
            <img @click="changeShow = !changeShow" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
          </div>
        </div>
        <!-- 中间表单 -->
        <div class="Select_body">
          <!-- 基础信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">仪器基本信息</div>
            </div>
            <el-form>
              <el-form-item :model="InstrumentData">
                <el-form-item id="add" label="仪器编号" class="box1">
                  <el-input v-model="InstrumentData.instr_number" placeholder="请输入仪器编号" />
                </el-form-item>
                <el-form-item label="仪器名称">
                  <el-input v-model="InstrumentData.instr_name" placeholder="请输入仪器名称" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="InstrumentData">
                <el-form-item label="仪器状态">
                  <el-select v-model="InstrumentData.instr_status" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in deviceStatusAll" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="仪器简称">
                  <el-input v-model="InstrumentData.instr_abbre" placeholder="请输入仪器简称" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="InstrumentData">
                <el-form-item label="仪器组别">
                  <el-select v-model="InstrumentData.group" class="m-2" placeholder="请选择" size="large">
                    <el-option
                      v-for="item in deviceGroupAll"
                      :key="item"
                      :label="item"
                      :value="item"
                      :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                  </el-select>
                </el-form-item>
                <el-form-item label="测量范围">
                  <el-input v-model="InstrumentData.measure_range" placeholder="请输入测量范围" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="InstrumentData">
                <el-form-item label="仪器类型">
                  <el-select v-model="InstrumentData.instr_type" class="select_type" placeholder="请选择" size="large">
                    <el-option
                      v-for="item in selectTypeArr"
                      :key="item.label"
                      :label="item.label"
                      :value="item.value"
                      :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="InstrumentData">
                <el-form-item label="测量原理" class="TESTy">
                  <el-input v-model="InstrumentData.measure_principle" placeholder="请输入测量原理" class="HeightPlus" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="InstrumentData">
                <el-form-item label="参数解释" class="TESTy">
                  <el-input v-model="InstrumentData.parameter_interpretation" placeholder="请输入参数解释" class="HeightPlus" />
                </el-form-item>
              </el-form-item>

              <el-form-item :model="InstrumentData">
                <el-form-item label="生产厂家">
                  <el-input v-model="InstrumentData.manufacturer" placeholder="请输入生产厂家" />
                </el-form-item>
                <el-form-item label="出厂编号">
                  <el-input v-model="InstrumentData.manufacturing_no" placeholder="请输入出厂编号" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="InstrumentData">
                <el-form-item label="规格型号">
                  <el-input v-model="InstrumentData.specification" placeholder="请输入规格型号" />
                </el-form-item>
                <el-form-item label="溯源方式">
                  <el-input v-model="InstrumentData.traceability" placeholder="请输入溯源方式" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="InstrumentData">
                <el-form-item label="仪器管理员" class="Tags">
                  <el-select v-model="InstrumentData.management" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in selectInstrumentAdministratorAll" :key="item.name" :label="item.name" :value="item._id" />
                  </el-select>
                </el-form-item>
                <el-form-item label="所属实验室">
                  <el-select v-model="InstrumentData.laboratory" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in selectAffiliatedLaboratoryAll" :key="item.value" :label="item.label" :value="item.label" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="InstrumentData">
                <el-form-item label="备注">
                  <el-input v-model="InstrumentData.remark" placeholder="请输入备注" />
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <!-- 仪器校准信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">仪器校准信息</div>
            </div>
            <el-form>
              <el-form-item :model="InstrumentData">
                <el-form-item label="校准日期" class="fistLogin">
                  <el-date-picker
                    v-model="InstrumentData.calibration_date"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="请选择" />
                </el-form-item>
                <el-form-item label="校验周期">
                  <el-select v-model="InstrumentData.calibration_period" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in verificationCycleAll" :key="item.value" :label="item.label" :value="item.label" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="InstrumentData">
                <el-form-item label="下次校准日期" class="fistLogin">
                  <el-date-picker
                    v-model="InstrumentData.calibration_nextdate"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="请选择" />
                </el-form-item>
                <el-form-item label="校准提醒">
                  <el-select v-model="InstrumentData.calibration_alarm" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in remindArr" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 底部按钮 -->
        <div class="Select_footer">
          <div class="null" @click="unSelect">
            取消
            <!-- <img src="https://new.vynior.com/%E7%BB%84%E4%BB%B6879%402x.png" alt="" /> -->
          </div>
          <div class="onselect" @click="reqAllSelectData">
            搜索
            <!-- <img src="https://new.vynior.com/%E7%BB%84%E4%BB%B6869%402x.png" alt="" /> -->
          </div>
        </div>
      </div>
    </div>
    <!-- !表头字段设置 -->
    <div class="SetTableHeader" v-if="SetTableHeader">
      <div class="main">
        <div class="Header">
          <div class="text">
            表头字段设置
            <img @click="CancelShowHiden" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402x.png" alt="" />
          </div>
        </div>
        <!-- 设置表头的数据盒子 -->
        <div class="SetBox">
          <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
          <div class="LeftBox">
            <div class="text-header">仪器列表预设字段</div>
            <div class="ProjectRowField">
              <div class="text-header1">【仪器基本信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetInstrumentListArr.slice(0, 18)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                </div>
              </div>
            </div>
            <!-- 【系统信息】 -->
            <div class="ProjectRecruitMSG">
              <div class="text-header2">【仪器校准信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetInstrumentListArr.slice(18, 22)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 18)" />
                </div>
              </div>
            </div>

            <div class="Self-Defined">自定义扩展字段</div>
          </div>
          <!-- 右边盒子 拖动 -->
          <div class="RightBox">
            <div class="text-header">列表页展示字段</div>
            <!-- 调整数据 -->
            <div class="switchBox">
              <div id="xxx" class="demo">
                <draggable
                  :list="dragDemo"
                  ghost-class="ghost"
                  :force-fallback="true"
                  chosen-class="chosenClass"
                  animation="300"
                  handle=".move"
                  delay="100"
                  @end="onEnd">
                  <template #item="{ element }">
                    <div class="item move">
                      <div class="drag"></div>
                      {{ element.lable }}
                      <!-- -->
                      <img
                        class="top"
                        @click="backTop(element, 'top')"
                        src=" https://newstore.vynior.com/cems_web/%E7%BB%841112%402x.png   "
                        alt="" />
                      <img
                        class="delete"
                        @click="backTop(element, 'delete')"
                        :src="
                          defaultHeader.indexOf(element.lable) != -1
                            ? 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88s%402x.png'
                            : 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88221%402x.png'
                        "
                        alt="" />
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="Bottom">
          <div class="leftBot">
            <div class="add">
              <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
            </div>
            <div class="recover" @click="RecoverData">
              <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
            </div>
          </div>
          <div class="rightBot">
            <div class="cancel" @click="CancelShowHiden">
              <!-- <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" /> -->
              取消
            </div>
            <div class="save" @click="ShowHidenColumn">
              <!-- <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" /> -->
              保存
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 导入 -->
    <el-dialog class="volunteer_dialog" v-model="dialogShow" title="Shipping address" :align-center="true" :close-on-click-modal="false">
      <div class="dialog_title">
        <span>上传数据</span>
        <div class="title_cancel" @click="dialogShow = false"></div>
      </div>
      <div class="dialog_main">
        <div class="main_download">
          <div class="download_title">1.下载模板，填写仪器数据信息</div>
          <div class="download_img"></div>
          <div class="upload_name"></div>
          <div class="upload_err"></div>
          <div class="download_but" @click="downloadXlsx">
            <button>下载模板</button>
          </div>
        </div>
        <div class="main_upload">
          <div class="upload_title">2.上传填写好的文件</div>
          <div class="upload_img"></div>
          <div class="upload_name">
            <span v-if="uploadFileName">{{ uploadFileName }}123</span>
          </div>
          <div class="upload_tip">
            <span v-if="uploadFileErr">文件格式错误，请重新上传</span>
            <span class="tip_up" v-if="uploadFileName && !uploadFileErr">重新选择</span>
          </div>
          <!--                        :on-preview="handlePreviews"
-->
          <div class="upload_but">
            <el-upload
              class="upload_xlsx"
              ref="videoRef"
              accept=".xlsx,.xls"
              :http-request="uploadFile"
              :on-preview="handlePreviews"
              :on-remove="handleRemoves"
              :file-list="fileLists"
              :show-file-list="false">
              <button>上传文件</button>
            </el-upload>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 导入仪器失败 -->
    <el-dialog class="volunteer_dialogs" :align-center="true" v-model="errdialogShow" title="Shipping address" :close-on-click-modal="false">
      <div class="dialog_title">
        <span>失败</span>
        <div class="title_cancel" @click="errdialogShow = false"></div>
      </div>
      <div class="dialog_main">
        <img class="main_err" src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402xs.png" alt="" />
        <div class="main_fail">导入失败</div>
        <div class="main_errT1">
          表格中存在<span class="tip">{{ errFileNum }}条</span>有误的仪器数据信息
        </div>
        <div class="main_errT2">请下载报错文件，修改后重新导入仪器数据。</div>
        <div class="main_but" @click="downloadErrFile">
          <button>下载文件</button>
        </div>
      </div>
    </el-dialog>
    <!-- 批量删除弹窗 -->
    <div class="DeleteConfit">
      <el-dialog v-model="BatchDelete" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass" v-if="DeleteMsg.Name == ''">
              已选中
              <span class="num">&nbsp;{{ checkedArr.length }}条&nbsp;</span>仪器信息，确认删除吗？
            </div>
            <div :id="titleId" :class="titleClass" v-if="DeleteMsg.Name != ''">
              确定删除
              <span class="num">&nbsp;{{ DeleteMsg.Name }}&nbsp;</span>仪器信息吗？
            </div>
          </div>
        </template>
        <div class="Body">
          <img src="https://new.vynior.com/111.png" alt="" />
          <div class="body_text">删除后将无法恢复</div>
        </div>
        <template #footer>
          <div class="dialog-footer">
            <div class="Cancel" @click="BatchDelete = false">取消</div>
            <div class="Delete" v-if="DeleteMsg.Name == ''" @click="DelteProject">删除</div>
            <div class="Delete" v-if="DeleteMsg.Name != ''" @click="SingleDelete(val)">删除</div>
          </div>
        </template>
      </el-dialog>
    </div>

    <!-- 抽屉仪器详情 -->
    <el-drawer v-model="Volunteerdetails" :close-on-click-modal="showDetail" title="I am the title" :with-header="false" size="57%">
      <div class="drawer">
        <div class="deawer_title">
          <span>仪器信息</span>
          <div class="Edit" @click="EditOpen" v-if="showDetail">
            <img @click="isVolunteereditor" v-if="!information1?.isdefault" src="https://newstore.vynior.com/%E7%BC%96%E8%BE%91.png" alt="" />
          </div>
        </div>
        <div class="drawer_body" v-if="showDetail">
          <div class="volunteer_main">
            <!-- 仪器基础信息 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">仪器基础信息</div>
              </div>
              <div class="info_main">
                <!-- 编号/名称 -->
                <div class="Lines">
                  <div class="In1">
                    <div class="name"><span>*</span>仪器编号</div>
                    <div class="value">{{ information.instr_number }}</div>
                  </div>
                  <div class="In2">
                    <div class="name"><span>*</span>仪器名称</div>
                    <div class="value">{{ information.instr_name }}</div>
                  </div>
                </div>
                <!-- 状态/简称-->
                <div class="Lines">
                  <div class="In1">
                    <div class="name"><span>*</span>仪器状态</div>
                    <div class="value">{{ information.instr_status }}</div>
                  </div>
                  <div class="In2">
                    <div class="name">仪器简称</div>
                    <div class="value">{{ information.instr_abbre }}</div>
                  </div>
                </div>
                <!-- 组别/范围-->
                <div class="Lines">
                  <div class="In1">
                    <div class="name">仪器组别</div>
                    <div class="value">{{ information.group }}</div>
                  </div>
                  <div class="In2">
                    <div class="name">测量范围</div>
                    <div class="value">{{ information.measure_range }}</div>
                  </div>
                </div>
                <!-- 仪器类型 -->
                <div class="Line_type">
                  <div class="In1">
                    <div class="name"><span>*</span>仪器类型</div>
                    <div class="value">
                      {{ selectTypeArr.find((obj) => obj.value == information.instr_type).label }}
                    </div>
                  </div>
                </div>
                <!-- 测量原理 -->
                <div class="Line">
                  <div class="In1">
                    <div class="name">测量原理</div>
                    <div class="values">
                      {{ information.measure_principle }}
                    </div>
                  </div>
                </div>
                <!-- 参数解释 -->
                <div class="Line">
                  <div class="In1">
                    <div class="name">参数解释</div>
                    <div class="values">
                      {{ information.parameter_interpretation }}
                    </div>
                  </div>
                </div>
                <!-- 生产厂家/出厂编号-->
                <div class="Lines">
                  <div class="In1">
                    <div class="name">生产厂家</div>
                    <div class="value">{{ information.manufacturer }}</div>
                  </div>
                  <div class="In2">
                    <div class="name">出厂编号</div>
                    <div class="value">{{ information.manufacturing_no }}</div>
                  </div>
                </div>
                <!-- 规格型号/溯源方式-->
                <div class="Lines">
                  <div class="In1">
                    <div class="name">规格型号</div>
                    <div class="value">{{ information.specification }}</div>
                  </div>
                  <div class="In2">
                    <div class="name">溯源方式</div>
                    <div class="value">{{ information.tracebility }}</div>
                  </div>
                </div>
                <!-- 仪器管理员/所属实验室-->
                <div class="Lines">
                  <div class="In1">
                    <div class="name">仪器管理员</div>
                    <div class="value">
                      {{ information.management }}
                    </div>
                  </div>
                  <div class="In2">
                    <div class="name">所属实验室</div>
                    <div class="value">{{ information.laboratory }}</div>
                  </div>
                </div>
                <!-- 备注-->
                <div class="Lines">
                  <div class="In1">
                    <div class="name">备注</div>
                    <div class="value">{{ information.remark }}</div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 仪器图片 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">仪器图片</div>
              </div>
              <div class="info_main_img" v-show="information.imgs?.length">
                <div class="info-item">
                  <div v-for="(imgUrl, index) in information.imgs" :key="index" class="img">
                    <!-- <img class="deleteIcon" src="https://newstore.vynior.com/cems_web/1%E9%94%99%E8%AF%AF%402x.png"/> -->
                    <img class="showImg" :src="imgUrl" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 文件 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">文件</div>
              </div>
              <div class="info_main_files">
                <div class="file">
                  <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2081%20%E2%80%93%2043%402x.png" alt="" />
                </div>
                <!-- <el-upload :limit="30" :http-request="uploadsFile" disabled :show-file-list="false">
                  <el-button type="primary" plain class="uploadIconBox">
                    <img class="uploadIcon" src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%E7%AE%AD%E5%A4%B4%402x.png" alt="" />
                    <span>上传附件</span>
                  </el-button>
                </el-upload> -->
                <div class="Table" v-show="information.files?.length">
                  <el-table :data="information.files" border stripe>
                    <el-table-column type="index" label="序号" width="56" min-width="55" />
                    <el-table-column prop="fileName" label="文件名称" width="470" min-width="259">
                      <template #default="scope">
                        <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                          {{ scope.row.fileName }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="uploadTime" label="上传时间" width="208" min-width="207">
                      <template #default="scope">
                        <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #626366">
                          {{ formatTime(scope.row.uploadTime) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="300" min-width="163">
                      <template #default="scope">
                        <!-- <el-button link type="primary" size="small">预览</el-button> -->
                        <el-button
                          link
                          type="primary"
                          style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                          size="small"
                          @click="downloadTheFiles(scope.row)"
                          >下载</el-button
                        >
                        <el-button
                          link
                          type="primary"
                          style="
                            font-size: 14px;
                            cursor: default;
                            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                            font-weight: 400;
                            color: #027aff;
                          "
                          size="small"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <!-- 仪器校准信息 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">仪器校准信息</div>
              </div>
              <div class="info_main_calibration">
                <!-- 校准日期/校验周期-->
                <div class="Lines">
                  <div class="In1">
                    <div class="name">校准日期</div>
                    <div class="value">
                      {{ information.calibration_date == null ? '' : dayjs(information.calibration_date).format('YYYY-MM-DD') }}
                    </div>
                  </div>
                  <div class="In2">
                    <div class="name">校验周期</div>
                    <div class="value">
                      {{ information.calibration_period }}
                    </div>
                  </div>
                </div>
                <!-- 校准日期/校验周期-->

                <div class="Lines two">
                  <div class="In1">
                    <div class="name">下次校准日期</div>
                    <div class="value">
                      {{ information.calibration_nextdate == null ? '' : dayjs(information.calibration_nextdate).format('YYYY-MM-DD') }}
                    </div>
                  </div>
                  <div class="In3">
                    <div class="name">校准提醒</div>
                    <div class="choice">
                      <div class="choiceList">
                        <img
                          :src="
                            !information.calibration_alarm
                              ? 'https://newstore.vynior.com/cems_web/%E7%A9%BA.png'
                              : 'https://newstore.vynior.com/cems_web/%E7%99%BD.png'
                          "
                          alt="" />
                        <span>不提醒</span>
                      </div>
                      <div class="choiceList">
                        <img
                          :src="
                            information.calibration_alarm
                              ? 'https://newstore.vynior.com/cems_web/%E7%A9%BA.png'
                              : 'https://newstore.vynior.com/cems_web/%E7%99%BD.png'
                          "
                          alt="" />
                        <span>提醒</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 仪器维护信息 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">仪器维护信息</div>
              </div>
              <div class="info_main_repair">
                <div class="Table">
                  <el-table :data="information.maintain_info" border stripe style="width: 1042px">
                    <el-table-column type="index" label="序号" width="65" min-width="64" />
                    <el-table-column prop="reason" label="维护原因" width="448" min-width="447" />
                    <el-table-column prop="time" :formatter="formatTime1" label="维护时间段" width="356" min-width="355" />

                    <el-table-column prop="director" label="维护负责人" width="172" min-width="170"> </el-table-column>
                  </el-table>
                </div>
                <div class="AddRepair" style="color: #626366">新增维护信息</div>
              </div>
            </div>
          </div>
        </div>
        <div class="Edit_box" v-if="showEdit">
          <div class="volunteer_main">
            <!-- 仪器基础信息 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">仪器基础信息</div>
              </div>
              <div class="info_main">
                <!-- 仪器编号 -->
                <div class="info_l">
                  <div class="l_text">
                    <span class="text_top">*</span>
                    仪器编号
                  </div>
                  <div class="l_main">
                    <el-input
                      v-model="information1.instr_number"
                      class="l_inp"
                      placeholder="请输入仪器编号"
                      suffix-icon=""
                      :class="errObj.inputDeviceNumber ? 'inp_err' : ''"
                      @focus="errObj.inputDeviceNumber = false" />
                    <div class="err_text">
                      <span v-if="errObj.inputDeviceNumber">{{ errNumber }}</span>
                    </div>
                  </div>
                </div>
                <!-- 仪器名称 -->
                <div class="info_r info_r2">
                  <div class="r_text">
                    <span class="text_top">*</span>
                    <span>仪器名称</span>
                  </div>
                  <div class="r_main">
                    <el-input
                      v-model="information1.instr_name"
                      class="r_select"
                      placeholder="请输入仪器名称"
                      suffix-icon=""
                      :class="errObj.inputDeviceName ? 'inp_err' : ''"
                      @focus="errObj.inputDeviceName = false" />

                    <div class="err_text">
                      <span v-if="errObj.inputDeviceName">{{ errName }}</span>
                    </div>
                  </div>
                </div>
                <!-- 仪器状态 -->
                <div class="info_l">
                  <div class="l_text">
                    <span class="text_top">*</span>
                    仪器状态
                  </div>
                  <div class="l_main">
                    <el-select
                      v-model="information1.instr_status"
                      class="l_inp"
                      placeholder="请选择"
                      size="large"
                      :class="errObj.selectDeviceStatus ? 'inp_err' : ''"
                      @focus="errObj.selectDeviceStatus = false">
                      <el-option v-for="item in deviceStatusAll" :key="item.value" :label="item.label" :value="item.value" />
                    </el-select>
                    <div class="err_text">
                      <span v-if="errObj.selectDeviceStatus">仪器状态不可为空</span>
                    </div>
                  </div>
                </div>
                <!-- 仪器简称 -->
                <div class="info_r">
                  <div class="phone_text r_text">
                    <!-- <span class="text_top">*</span> -->
                    仪器简称
                  </div>
                  <div class="r_main">
                    <el-input
                      maxlength="20"
                      v-model="information1.instr_abbre"
                      class="r_inp"
                      placeholder="请输入仪器简称"
                      suffix-icon=""
                      :class="errObj.phone ? 'inp_err' : ''"
                      @focus="errObj.phone = false" />
                    <div class="err_text">
                      <span v-if="errObj.phone">{{ phone_error }}</span>
                    </div>
                  </div>
                </div>
                <!-- 仪器组别 -->
                <div class="info_l">
                  <div class="l_text">
                    <!-- <span class="text_top">*</span> -->
                    仪器组别
                  </div>
                  <div class="l_main">
                    <el-select
                      v-model="information1.group"
                      class="l_inp"
                      placeholder="请选择"
                      size="large"
                      :class="errObj.sex ? 'inp_err' : ''"
                      @focus="errObj.sex = false">
                      <el-option
                        v-for="item in deviceGroupAll"
                        :key="item"
                        :label="item"
                        :value="item"
                        :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                    </el-select>

                    <div class="err_text">
                      <span v-if="errObj.referrer">组别不可为空</span>
                    </div>
                  </div>
                </div>
                <!-- 测量范围 -->
                <div class="info_r">
                  <div class="r_text">测量范围</div>
                  <div class="r_main" :class="errObj.date ? 'inp_err' : ''">
                    <!-- <el-date-picker
                    class="l_picker"
                    v-model="selectMeasuringRange"
                    type="date"
                    placeholder="请选择测量范围"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    @focus="errObj.date = false" /> -->

                    <el-input
                      v-model="information1.measure_range"
                      class="r_inp"
                      maxlength="20"
                      placeholder="请输入测量范围"
                      suffix-icon=""
                      :class="errObj.phone ? 'inp_err' : ''"
                      @focus="errObj.phone = false" />
                  </div>
                </div>
                <!-- 仪器类型 -->
                <div class="info_type">
                  <div class="l_text">
                    <span class="text_top">*</span>
                    仪器类型
                  </div>
                  <div class="l_main">
                    <el-select
                      v-model="information1.instr_type"
                      class="l_inp"
                      placeholder="请选择"
                      size="large"
                      filterable
                      :class="errObj.instr_type ? 'inp_err' : ''"
                      @focus="errObj.instr_type = false">
                      <el-option v-for="item in selectTypeArr" :key="item.label" :label="item.label" :value="item.value" />
                    </el-select>
                    <div class="err_text">
                      <span v-if="errObj.selectType">仪器类型不可为空</span>
                    </div>
                  </div>
                </div>
                <!-- 测量原理 -->
                <div class="info_r">
                  <div class="r_text2">测量原理</div>
                  <div class="r_main2" :class="errObj.date ? 'inp_err' : ''">
                    <el-input
                      v-model="information1.measure_principle"
                      class="r_inp2"
                      type="textarea"
                      resize="none"
                      placeholder="请输入测量原理"
                      suffix-icon=""
                      :class="errObj.phone ? 'inp_err' : ''"
                      @focus="errObj.phone = false" />
                  </div>
                </div>

                <!-- 参数解释 -->
                <div class="info_r22">
                  <div class="r_text2">参数解释</div>
                  <div class="r_main2" :class="errObj.date ? 'inp_err' : ''">
                    <el-input
                      v-model="information1.parameter_interpretation"
                      class="r_inp2"
                      type="textarea"
                      resize="none"
                      placeholder="请输入参数解释"
                      suffix-icon=""
                      :class="errObj.phone ? 'inp_err' : ''"
                      @focus="errObj.phone = false" />
                  </div>
                </div>

                <!-- 生产厂家 -->
                <div class="info_l22">
                  <div class="l_text">
                    <!-- <span class="text_top">*</span> -->
                    生产厂家
                  </div>
                  <div class="l_main">
                    <el-input v-model="information1.manufacturer" maxlength="20" class="l_inp" placeholder="请输入生产厂家" suffix-icon="" />
                    <!-- <div class="err_text">
                    <span v-if="errObj.phone">{{ phone_error }}</span>
                  </div> -->
                  </div>
                </div>

                <!-- 出厂编号 -->
                <div class="info_r">
                  <div class="r_text">
                    <!-- <span class="text_top">*</span> -->
                    出厂编号
                  </div>
                  <div class="r_main">
                    <el-input v-model="information1.manufacturing_no" maxlength="20" class="r_inp" placeholder="请输入出厂编号" suffix-icon="" />
                  </div>
                </div>

                <!-- 规格型号 -->
                <div class="info_l22">
                  <div class="l_text">
                    <!-- <span class="text_top">*</span> -->
                    规格型号
                  </div>
                  <div class="l_main">
                    <el-input v-model="information1.specification" maxlength="20" class="l_inp" placeholder="请输入规格型号" suffix-icon="" />
                  </div>
                </div>

                <!-- 溯源方式 -->
                <div class="info_r">
                  <div class="r_text">
                    <!-- <span class="text_top">*</span> -->
                    溯源方式
                  </div>
                  <div class="r_main">
                    <el-input v-model="information1.tracebility" maxlength="20" class="r_inp" placeholder="请输入溯源方式" suffix-icon="" />
                  </div>
                </div>

                <!-- 仪器管理员 -->
                <div class="info_l22">
                  <div class="l_text">
                    <!-- <span class="text_top">*</span> -->
                    仪器管理员
                  </div>
                  <div class="l_main">
                    <el-select
                      v-model="information1.management"
                      class="l_inp"
                      placeholder="请选择"
                      @change="selectInstrumentAdministratorValueChange"
                      size="large">
                      <el-option v-for="item in selectInstrumentAdministratorAll" :key="item.name" :label="item.name" :value="item._id" />
                    </el-select>
                  </div>
                </div>

                <!-- 所属实验室 -->
                <div class="info_r">
                  <div class="r_text">
                    <!-- <span class="text_top">*</span> -->
                    所属实验室
                  </div>
                  <div class="r_main">
                    <el-select v-model="information1.laboratory" class="r_inp" placeholder="请选择" size="large">
                      <el-option v-for="item in selectAffiliatedLaboratoryAll" :key="item.value" :label="item.label" :value="item.label" />
                    </el-select>
                  </div>
                </div>

                <!-- 备注 -->
                <div class="info_r">
                  <div class="r_text2">备注</div>
                  <div class="r_main3">
                    <el-input maxlength="20" type="text" v-model="information1.remark" class="r_inp2" placeholder="请输入备注" suffix-icon="" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 仪器图片 -->
            <div class="main_info22">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">仪器图片<span class="moreFivePic">最多上传5张图片</span></div>
              </div>
              <div class="info_main">
                <div class="info_main_img">
                  <div class="info-item">
                    <div v-for="(imgUrl, index) in information1.imgs" :key="index" class="img">
                      <img class="deleteIcon" @click="deleteImage(index)" src="https://newstore.vynior.com/cems_web/1%E9%94%99%E8%AF%AF%402x.png" />
                      <img class="showImg" :src="imgUrl" />
                    </div>
                  </div>
                  <div class="uploadImgBox">
                    <el-upload
                      class="Upload"
                      v-if="information1.imgs.length < 5"
                      :headers="{ Authorization: access_token }"
                      multiple
                      :limit="5"
                      :accept="'.png,.jpg,jpeg'"
                      action="https://cems.fuhuan.ltd/api/upload/file-upload"
                      list-type="picture-card"
                      :on-preview="handlePictureCardPreview"
                      :data="{ type: 'image' }"
                      :on-success="handleAvatarSuccess"
                      :on-remove="handleRemove"
                      :show-file-list="false">
                      <el-icon><Plus /></el-icon>
                    </el-upload>
                  </div>
                </div>
              </div>
            </div>

            <!-- 上传附件 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">文件<span class="more-20MB">单个文件不能超过20MB</span></div>
              </div>
              <div class="info_main">
                <div class="files">
                  <el-upload :limit="30" :http-request="uploadsFile" :show-file-list="false">
                    <!-- <el-button type="primary" plain class="uploadIconBox"> -->
                    <img class="uploadIcon" src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%E7%AE%AD%E5%A4%B4%402x.png" alt="" />
                    <span>上传附件</span>
                    <!-- </el-button> -->
                  </el-upload>
                </div>
              </div>
              <div class="Table" v-show="information1.files.length">
                <el-table :data="information1.files" border stripe>
                  <el-table-column type="index" label="序号" width="56" min-width="55" />
                  <el-table-column prop="fileName" label="文件名称" width="470" min-width="259">
                    <template #default="scope">
                      <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                        {{ scope.row.fileName }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="uploadTime" label="上传时间" width="208" min-width="207">
                    <template #default="scope">
                      <div style="color: #626366">
                        {{ formatTime(scope.row.uploadTime) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="300" min-width="163">
                    <template #default="scope">
                      <!-- <el-button link type="primary" size="small">预览</el-button> -->
                      <el-button
                        link
                        type="primary"
                        style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                        @click="downloadTheFiles(scope.row)"
                        size="small"
                        >下载</el-button
                      >
                      <el-button
                        link
                        type="primary"
                        style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                        @click="deleteTheFiles(scope.row)"
                        size="small"
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>

            <!-- 仪器校准信息 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">仪器校准信息</div>
              </div>
              <div class="info_main">
                <div class="info-item">
                  <div class="item-title">校准日期</div>
                  <div class="item-content">
                    <el-date-picker
                      class="l_picker"
                      v-model="information1.calibration_date"
                      type="date"
                      placeholder=""
                      @change="getOneTimeValue"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      @focus="errObj.date = false" />
                  </div>
                </div>
                <div class="info-item">
                  <div class="item-title">校验周期</div>
                  <div class="item-content">
                    <el-select v-model="information1.calibration_period" placeholder="请选择" @change="verificationCycleValueChange">
                      <el-option v-for="item in verificationCycleAll" :key="item.value" :label="item.label" :value="item.label" />
                    </el-select>
                  </div>
                </div>
                <div class="info-item">
                  <div class="item-title">下次校准日期</div>
                  <div class="item-content">
                    <el-date-picker
                      class="l_picker"
                      v-model="information1.calibration_nextdate"
                      type="date"
                      disabled
                      placeholder=""
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD"
                      @focus="errObj.date = false" />
                  </div>
                </div>
                <div class="info-item">
                  <div class="item-title">校准提醒</div>
                  <div class="item-content2">
                    <div class="info_main_item">
                      <!--  main_item_buts-->
                      <div class="main_item_but" :class="butInfoValueArr[0] == 1 ? 'item_but_back' : ''" @click.stop="infoChange(1)">
                        <div v-if="butInfoValueArr[0] == 1" class="item_but_back"></div>
                        <!-- <img  src="" alt=""> -->
                      </div>
                      <div class="main_item_text">不提醒</div>
                    </div>

                    <div class="info_main_item">
                      <!-- main_item_buts -->
                      <div class="main_item_but" :class="butInfoValueArr[0] == 2 ? 'item_but_back' : ''" @click.stop="infoChange(2)">
                        <div v-if="butInfoValueArr[0] == 2" class="item_but_back"></div>
                        <!-- <img  src="" alt=""> -->
                      </div>
                      <div class="main_item_text">提醒</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 仪器维护信息 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">仪器维护信息</div>
              </div>

              <!-- <template> -->
              <div class="Table1">
                <el-table :data="information1.maintain_info" border stripe style="width: 1032px">
                  <el-table-column type="index" prop="index" label="序号" width="56" min-width="55" />
                  <el-table-column label="维护原因" width="448" min-width="440">
                    <template #default="scope">
                      <el-input class="resonInp" placeholder="请输入维护原因" maxlength="20" v-model="scope.row.reason" />
                      <!-- <div>{{ scope.row }}</div> -->
                    </template>
                  </el-table-column>
                  <el-table-column label="维护时间段" width="356" min-width="355">
                    <template #default="scope">
                      <el-date-picker
                        class="timeInp"
                        v-model="scope.row.time"
                        type="daterange"
                        range-separator="-"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD" />
                    </template>
                  </el-table-column>
                  <el-table-column label="维护负责人" width="172" min-width="171">
                    <template #default="scope">
                      <el-input class="personInp" placeholder="请输入" maxlength="20" v-model="scope.row.director" />
                      <!-- <div>{{ scope.row }}</div> -->
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- </template> -->
              <div class="addDeviceInfo" @click="addDeviceInfo">新增维护信息</div>
            </div>

            <!-- 文件 -->
          </div>
        </div>
        <!-- 抽屉底部 -->
        <!-- <div class="drawer_footer">
          <div class="null"><img @click="ShowDetails" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" /></div>
          <div class="next"><img @click="updatedata" src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" /></div>
        </div> -->
      </div>
      <template #footer>
        <div style="flex: auto" v-if="showEdit">
          <el-button @click="EditClose">取消</el-button>
          <el-button type="primary" @click="updateInstru"> 确定 </el-button>
        </div>
      </template>
    </el-drawer>

    <!-- 显示功能弹窗 -->
    <div class="SetShowsBox" ref="ShowBox" v-show="SetShow" id="SetShowsBoxs">
      <div class="Titles" id="SetShowsBoxs">字段显示</div>
      <div class="ContentBox" :class="AllHidenHeader.length == 0 ? 'Emptys' : ''" id="SetShowsBoxs">
        <span class="Empty" v-if="AllHidenHeader.length == 0" id="SetShowsBoxs">暂无隐藏字段</span>
        <div class="ListBox" v-if="AllHidenHeader.length != 0" id="SetShowsBoxs">
          <div class="List" v-for="(item, index) in AllHidenHeader" :key="index" @click="ShowCheck[index] = !ShowCheck[index]" id="SetShowsBoxs">
            <div class="item" id="SetShowsBoxs">{{ item.title }}</div>

            <div class="Active" v-show="ShowCheck[index]" id="SetShowsBoxs">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110500%402x.png" alt="" />
            </div>
          </div>
          <div
            class="Button"
            @click="ConfirmRecover"
            id="SetShowsBoxs"
            :class="AllHidenHeader.length == 1 ? 'Buttons' : AllHidenHeader.length == 2 ? 'Buttonss' : ''">
            确认
          </div>
        </div>
      </div>
    </div>
    <!-- 切换状态弹窗 -->
    <div class="ChangeS">
      <el-dialog v-model="ChangeStatusData.Show" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass">
              确认<span class="num">&nbsp;{{ ChangeStatusData.Status }}&nbsp;</span>
              吗?
            </div>
          </div>
        </template>
        <div class="Body"><img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />切换后无法撤回，可在仪器状态栏进行查看</div>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="Cancel" @click="ChangeStatusData.Show = false">取消</el-button>
            <el-button class="Delete" @click="applyFix(ChangeStatusData.row)" v-if="ChangeStatusData.Status == '申报维护'"> 确认 </el-button>
            <el-button class="Delete" @click="startFix(ChangeStatusData.row)" v-if="ChangeStatusData.Status == '开始维护'"> 确认 </el-button>
            <el-button class="Delete" @click="tureFix(ChangeStatusData.row)" v-if="ChangeStatusData.Status == '维护完成'"> 确认 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <createInstrument v-model="is_CreatedDeviceDialogShow" v-if="is_CreatedDeviceDialogShow" :Change="information"></createInstrument>
    <!-- <InstrumentChange v-model="Details" v-if="Details"></InstrumentChange> -->
    <!-- :ChangeTite="DetailsMsg" -->
  </div>
</template>
<script setup>
import createInstrument from '@/components/InstrumentMangement/createInstrument.vue'
import { replaceEmptyValues } from '@/utils/emptyArray'
import http from '@/utils/http'
import { message } from '@/utils/resetMessage'
import { Plus, Upload } from '@element-plus/icons-vue'
import axios from 'axios'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { computed, defineEmits, defineProps, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as xlsx from 'xlsx'
import draggable from 'vuedraggable'
import ase from '@/utils/cryptoUtils'

//import { Row } from 'element-plus/es/components/table-v2/src/components';
//API接收
const store = useStore()
const router = useRouter()
const xTable = ref()
const linewidth = ref(window.innerWidth)
const handleResize = () => {
  linewidth.value = window.innerWidth
}

//tip新数据结构------------------：
// Vue.createApp(xxx).mount('#xxx');
const onEnd = () => {}

let dragDemo = ref([])
let dragDemos = ref([])
let allDatas = ref([
  // { fixed: '', field: 'number', checked: true, lable: '项目编号' },
  // { fixed: '', field: 'name', checked: true, lable: '项目名称' },
  // { fixed: '', field: 'product', checked: true, lable: '项目产品' },
  // { fixed: '', field: 'director_name', checked: true, lable: '项目负责人' },
  // { fixed: '', field: 'projectStart_time', checked: true, lable: '项目开始日期' },
  // { fixed: '', field: 'projectEnd_time', checked: true, lable: '项目结束日期' },
  // { fixed: '', field: 'status', checked: true, lable: '项目状态' },
  // { fixed: '', field: 'progress', checked: true, lable: '项目完成进度' },
  // { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
])
// 置顶/删除方法
const backTop = (e, val) => {
  console.log('val :>> ', e)
  let indexItem = -1
  dragDemo.value.forEach((item, index) => {
    if (item.lable == e.lable) {
      indexItem = index
    }
  })
  console.log('下标是： :>> ', indexItem)
  // 置顶方法
  if (val == 'top') {
    if (indexItem != 0) {
      dragDemo.value.unshift(dragDemo.value.splice(indexItem, 1)[0])
    }
  } else {
    // 不能删除的操作
    if (defaultHeader.value.indexOf(e.lable) != -1) {
      message({
        message: `${e.lable}不能删除`,
        showClose: true,
        type: 'error',
      })
    } else {
      // 可以删除的操作 同步删除左侧勾选；
      // 删除方法
      dragDemo.value.splice(indexItem, 1)
      const index = SetInstrumentListArr.value.findIndex((item) => item.lable === e.lable)
      SetInstrumentListArr.value[index].checked = false
    }
  }

  console.log(' dragDemo.value; :>> ', dragDemo.value)
}

// tip-----------------------

const access_token = ref('Bearer' + ' ' + localStorage.getItem('access_token'))
onMounted(() => {
  handleResize()
  ReqDefault()
  getTypeRes()
  window.addEventListener('resize', handleResize)
})
const selectTypeArr = ref([])
const getTypeRes = async () => {
  const res = await http.post('/instrument/get-instrumentAllType')
  console.log(res, '获取全部类型可选项')
  selectTypeArr.value = res.instrumentTypes
}
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
// !表单空白处理
const tableData = computed(() => {
  return replaceEmptyValues(demo1.tableData)
})
//切换状态
let ChangeStatusData = ref({
  Show: false,
  Name: '',
  Status: '',
  row: '',
})
let timeArr = ref([])
const nowStatus = ref('')
//申报维护
const applyFix = async (row) => {
  ChangeStatusData.value.Show = false
  console.log(row)
  console.log(row.maintain_info)
  // 修改为待维护状态
  row.instr_status = '待维护'
  console.log(row.instr_status)
  // 异步发送请求
  ;(async () => {
    try {
      const res = await http.post('/instrument/update-instrument', {
        // 仪器名字
        instr_name: demo1.tableData.instr_name,
        // 仪器编号
        instr_number: demo1.tableData.instr_number,
        // 仪器状态
        instr_status: row.instr_status,
        _id: row._id,
      })

      console.log('测试', res)
      if (res.status === 0) {
        //information.value = res.instruments;
        console.log('仪器状态更改成功')
        reqProjectList()
        const now = new Date()
        const year = now.getFullYear() // 获取年份
        const month = String(now.getMonth() + 1).padStart(2, '0') // 获取月份，注意要补齐两位数
        const day = String(now.getDate()).padStart(2, '0') // 获取日期，注意要补齐两位数
        const currentDate = `${year}-${month}-${day}`
        console.log(currentDate)
        //row.maintain_info.push(
        // {
        //  reason:'',
        // startDate:currentDate,
        // endDate:'',
        // time:[currentDate,''],
        // director:'',
        // }
        // )

        timeArr.value[0] = currentDate
        console.log(timeArr.value)
      }
    } catch (err) {
      console.log(err)
    }
  })()
}
//开始维护
const startFix = async (row) => {
  ChangeStatusData.value.Show = false
  console.log(row)
  // 修改为维护中状态
  row.instr_status = '维护中'
  console.log(row.instr_status)
  // 异步发送请求
  ;(async () => {
    try {
      const res = await http.post('/instrument/update-instrument', {
        // 仪器名字
        instr_name: demo1.tableData.instr_name,
        // 仪器编号
        instr_number: demo1.tableData.instr_number,
        // 仪器状态
        instr_status: row.instr_status,
        _id: row._id,
      })

      console.log('测试', res)
      if (res.status === 0) {
        //information.value = res.instruments;
        console.log('仪器状态更改成功')
        reqProjectList()
      }
    } catch (err) {
      console.log(err)
    }
  })()
}
const tureFix = async (row) => {
  ChangeStatusData.value.Show = false
  row.instr_status = '正常'
  // 异步发送请求
  ;(async () => {
    try {
      const res = await http.post('/instrument/update-instrument', {
        // 仪器名字
        instr_name: demo1.tableData.instr_name,
        // 仪器编号
        instr_number: demo1.tableData.instr_number,
        // 仪器状态
        instr_status: row.instr_status,
        _id: row._id,
      })

      console.log('测试', res)
      if (res.status === 0) {
        //information.value = res.instruments;
        console.log('仪器状态更改成功')
        reqProjectList()
        const now = new Date()
        const year = now.getFullYear() // 获取年份
        const month = String(now.getMonth() + 1).padStart(2, '0') // 获取月份，注意要补齐两位数
        const day = String(now.getDate()).padStart(2, '0') // 获取日期，注意要补齐两位数
        const currentDate = `${year}-${month}-${day}`
        console.log(currentDate)
        console.log(row.time)
        console.log(timeArr.value)
        if (timeArr.value[0] !== '') {
          timeArr.value[1] = currentDate
          // row.maintain_info[0].time = Object.fromEntries(timeArr.value.map((value, index) => [index, value]));
          // console.log(row.maintain_info[0].time);
        }
        console.log(timeArr)
        if (timeArr.value[0] != null && timeArr.value[0] != '' && timeArr.value[1] != '' && timeArr.value[1] != null) {
          console.log(row)
          if (row.maintain_info == '——') {
            row.maintain_info = []
          }
          row.maintain_info.push({
            reason: '',
            startDate: timeArr.value[0],
            endDate: timeArr.value[1],
            time: timeArr,
            director: '',
          })
          let res = await http.post('/instrument/update-instrument', {
            // 仪器名字
            instr_name: row.instr_name,
            // 仪器编号
            instr_number: row.instr_number,
            // 维护信息
            maintain_info: row.maintain_info,
            _id: row._id,
          })
          if (res.status == 0) {
            console.log('获取时间成功')
            reqProjectList()
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  })()
}
const Changestatus = async (status, row) => {
  if (status === '开始维护') {
    if (!store.state.role_info.includes('instrument-list-Maintenancestart')) {
      message({
        showClose: true,
        message: '你没有权限完成该操作，请向管理员申请权限',
        type: 'warning',
      })
      return
    }
    console.log(row.instr_name)
    ChangeStatusData.value.Name = row.instr_name
    ChangeStatusData.value.Show = true
    ChangeStatusData.value.row = row
    ChangeStatusData.value.Status = status
    // 修改为维护中状态
    //row.instr_status = '维护中';
  } else if (status === '维护完成') {
    if (!store.state.role_info.includes('instrument-list-MaintenanceCompleted')) {
      message({
        showClose: true,
        message: '你没有权限完成该操作，请向管理员申请权限',
        type: 'warning',
      })
      return
    }
    console.log(row.instr_name)
    ChangeStatusData.value.Name = row.instr_name
    ChangeStatusData.value.Show = true
    ChangeStatusData.value.row = row
    ChangeStatusData.value.Status = status

    // 修改为正常状态
    //row.instr_status = '正常';
  } else if (status === '申报维护') {
    if (!store.state.role_info.includes('instrument-list-declarationMaintenance')) {
      message({
        showClose: true,
        message: '你没有权限完成该操作，请向管理员申请权限',
        type: 'warning',
      })
      return
    }
    console.log(row.instr_name)
    ChangeStatusData.value.Name = row.instr_name
    ChangeStatusData.value.Show = true
    ChangeStatusData.value.row = row
    ChangeStatusData.value.Status = status
  }

  console.log('ahssssssssss')
}

//必选项是否为空提示
let errObj = reactive({
  inputDeviceNumber: false,
  inputDeviceName: false,
  selectDeviceStatus: false,
})
//是否提醒是否选中
const butInfoValueArr = ref([])
const uploadTableData = ref([])
let uploadTableDeviceIndex = ref(1)
const EditClose = () => {
  showEdit.value = false
  showDetail.value = true
}

//表格 序号  维护序列1、2、3、4
let tableDeviceIndex = ref(1)
const formatTime1 = (row) => {
  console.log(row)
  console.log(row.time)
  if (row.time != '' && row.time) {
    let time = Object.values(row.time)
    return time.join('—')
  } else {
    return ''
  }
}
const formaUploadTime = (row) => {
  console.log(row.uploadTime)
  if (row.uploadTime) {
    return row.uploadTime.slice(0, 10)
  } else {
    return ''
  }
}
//   仪器维护信息
const tableData1 = ref([
  {
    index: tableDeviceIndex.value,
    reason: '',
    startDate: '',
    endDate: '',
    director: '',
  },
])
//点了新增维护信息
const addDeviceInfo = () => {
  console.log('点了新增维护信息')

  let obj = {
    index: ++tableDeviceIndex.value,
    reason: '',
    startDate: '',
    endDate: '',
    director: '',

    // Alldirector: [
    //   { label: "负责人1", value: 1 },
    //   { label: "负责人2", value: 2 },
    // ],
  }
  information1.value.maintain_info.push(obj)
}
//点击提醒收集选项
const infoChange = (i) => {
  if (butInfoValueArr.value[0] == i) {
    // butInfoValueArr.value.length ==0
    butInfoValueArr.value[0] = -1
    return
  }

  if (butInfoValueArr.value.length == 0) {
    // butInfoValueArr.value.push(i)
    butInfoValueArr.value[0] = i
    console.log('butInfoValueArr1 is', butInfoValueArr)
  } else {
    // butInfoValueArr.value.splice(0, butInfoValueArr.value.length);    //1 不提醒， 2 提醒
    // butInfoValueArr.value.length =0
    // butInfoValueArr.value.push(i);
    butInfoValueArr.value[0] = i
    console.log('butInfoValueArr2 is', butInfoValueArr)
  }
  console.log('最终选择是否提醒', butInfoValueArr.value[0])
}
let totalCount = ref(0)
const SetTableHeader = ref(false)

//  检查    校准日期  和    下次校准日期   的合法性
const compareDates = (date1, date2) => {
  console.log(date1, date2)

  // 将输入的字符串类型时间转换为时间戳
  var timestamp1 = Date.parse(date1)
  var timestamp2 = Date.parse(date2)

  // 比较两个   校准日期   时间的先后顺序
  if (timestamp1 < timestamp2) {
    // return date1 + ' 在 ' + date2 + ' 之前';
    return '下次校准日期 需 小于首次校准日期，请重新选择'
  } else if (timestamp1 > timestamp2) {
    // return date1 + ' 在 ' + date2 + ' 之后';
    return '下次校准日期 需 大于首次校准日期，请重新选择'
  } else {
    // return date1 + ' 和 ' + date2 + ' 是相同的日期';
    return '下次校准日期 不能与 首次校准日期相同，请重新选择'
  }
}
//获取当下时间
const getCurrentDate = () => {
  var today = new Date()
  var year = today.getFullYear() // 获取当前年份
  var month = ('0' + (today.getMonth() + 1)).slice(-2) // 获取当前月份（补 0）
  var day = ('0' + today.getDate()).slice(-2) // 获取当前日期（补 0）
  return year + '-' + month + '-' + day
}
const getOneTimeValue = (val) => {
  console.log(val)
  let currentTime = getCurrentDate()
  console.log('Current', currentTime)

  let isTimeTrue = compareDates(currentTime, val)

  // if (isTimeTrue == '下次校准日期 需 大于首次校准日期，请重新选择') {
  //   information1.value.calibration_date = '';
  //   ElMessage({
  //     showClose: true,
  //     message: '校准日期需大于等于当前时间，请重新选择',
  //     type: 'error',
  //   });
  // } else {
  //   // 什么都不做
  // }
  if (information1.value.calibration_date !== '') {
    if (information1.value.calibration_period) {
      let date = new Date(information1.value.calibration_date)
      let num = parseInt(information1.value.calibration_period)
      let year = date.getFullYear()
      let month = date.getMonth()
      date.setMonth(month + num)
      information1.value.calibration_nextdate = date.toISOString().slice(0, 10)
      console.log(information1.value.calibration_nextdate)
    }
  } else {
    information1.value.calibration_nextdate = ''
  }
}
const ClickActive = ref(0)
//   仪器管理员  所有选项
let selectInstrumentAdministratorAll = ref([])
const selectInstrumentAdministratorValueChange = (val) => {
  console.log(val)
  information1.value.management = val
}
//   仪器状态  所有选项
let deviceStatusAll = ref([
  { label: '正常', value: '正常' },
  { label: '维护中', value: '维护中' },
  { label: '待维护', value: '待维护' },
  { label: '报废', value: '报废' },
])
let deviceGroupAll = ref([])

// 点击下载模板
const downloadXlsx = async () => {
  // const xlsxUrl = 'https://cems.fuhuan.ltd/仪器列表导入表格.xlsx';
  // const response = await fetch(xlsxUrl);
  // const blob = await response.blob();
  // 创建下载链接
  // const url = window.URL.createObjectURL('https://cems.fuhuan.ltd/仪器列表导入表格.xlsx');
  const link = document.createElement('a')

  link.href = 'https://cems.fuhuan.ltd/仪器列表导入表格.xlsx'
  link.download = '仪器列表导入表格.xlsx'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  // 释放 Blob URL
  // window.URL.revokeObjectURL(url);
}
// 获取仪器管理员
onMounted(async () => {
  try {
    const res = await http.post('/user/findInstrumentOptions-user', {})
    if (res.status == 0) {
      const admins = res.userOptions
      selectInstrumentAdministratorAll.value = admins
    } else {
      console.log('获取失败')
    }
  } catch (error) {
    console.error(error)
    // 处理错误
  }
})
const dialogImageUrl = ref('')
const dialogVisible = ref(false)
//   校验周期  所有选项
let verificationCycleAll = ref([
  { label: '6个月', value: 1 },
  { label: '12个月', value: 2 },
  { label: '18个月', value: 3 },
  { label: '24个月', value: 4 },
  { label: '30个月', value: 5 },
  { label: '36个月', value: 6 },
])
//校验周期 选中事件
const verificationCycleValueChange = (item) => {
  console.log(item)
  // console.log(item.value);
  information1.value.calibration_period = item
  if (information1.value.calibration_date !== '') {
    if (information1.value.calibration_period) {
      let date = new Date(information1.value.calibration_date)
      let num = parseInt(information1.value.calibration_period)
      let year = date.getFullYear()
      let month = date.getMonth()
      date.setMonth(month + num)
      information1.value.calibration_nextdate = date.toISOString().slice(0, 10)
      console.log(information1.value.calibration_nextdate)
    }
  } else {
    information1.value.calibration_nextdate = ''
  }
}
//   所属实验室  所有选项
let selectAffiliatedLaboratoryAll = ref([
  { label: '产品功效实验室1', value: 1 },
  { label: '产品功效实验室2', value: 2 },
  { label: '产品功效实验室3', value: 3 },
])
// 高级搜索校准提醒
let remindArr = ref([
  { label: '是', value: true },
  { label: '否', value: false },
])
let imgsArr = ref([])
// 照片墙
const handlePictureCardPreview = (uploadFile) => {
  console.log('是否执行')
  console.log(' uploadFile', uploadFile)
  dialogImageUrl.value = uploadFile.response.accessPath

  dialogVisible.value = true
}
const imgFileArr = ref([])
// 图片上传成功
const handleAvatarSuccess = (response, uploadFile, uploadFiles) => {
  information1.value.imgs.push(response.accessPath)
  imgFileArr.value.push(response.fileSavePath)
}
const deleteImageArr = ref([])
const deleteImage = (index) => {
  const deletedImage = information1.value.imgs.splice(index, 1)[0]
  if (deletedImage.includes('https://')) {
    deleteImageArr.value.push(deletedImage)
    console.log(deleteImageArr.value)
  }
}
const handleRemove = (uploadFile, uploadFiles) => {
  console.log('删除图片', uploadFile, uploadFiles)
  console.log('删除图片2', uploadFile.response.fileSavePath)

  const deleteIndex = imgsArr.value.indexOf(uploadFile.response.fileSavePath)
  if (deleteIndex > -1) {
    imgsArr.value.splice(deleteIndex, 1)
  }
  console.log('删除后的arr', imgsArr.value)
}
//附件--删除

const deleteTheFiles = (row) => {
  console.log('点击了附件删除data', row)
  console.log(information1.value.files)
  const fileDeleteIndex = information1.value.files.findIndex((item) => item.fileName === row.fileName)
  filesArr.value.forEach((item, index) => {
    if (item.name == data.fileName) {
      console.log('12312312')
      fileDeleteIndex = index
    }
  })
  if (fileDeleteIndex > -1) {
    information1.value.files.splice(fileDeleteIndex, 1)
    filesArr.value.splice(fileDeleteIndex, 1)
    console.log('附件删除后的数组', information1.value.files)

    const deleteWhichItem = uploadTableData.value.findIndex((item22) => item22.id === row.id)
    if (deleteWhichItem > -1) {
      information1.value.files.splice(deleteWhichItem, 1)
      console.log('information1.value.files删除成功')
      console.log('information1.value.files删除成功后的information1.value.files', information1.value.files)

      //To Do  删除成功提示   别忘了
      ElMessage({
        showClose: true,
        message: '删除成功',
        type: 'success',
      })
    }
  }
}
const formatTime = (timeString) => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')
  const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return currentDateTime
}
let filesArr = ref([])
const maxFileSize = ref(20 * 1024 * 1024) // 限制最大文件大小为 20MB
// 文件上传 之前的 回调  用于判断文件大小是否超过 20mb
const handleBeforeUpload = (file) => {
  console.log('file上传之前', file)
  if (file.size > maxFileSize.value) {
    console.log('当前上传的文件超过20mb，阻止上传', file.size)
    ElMessage({
      showClose: true,
      message: '当前上传的文件超过20mb，请重新选择',
      type: 'error',
    })
    return false // 返回 false 阻止文件上传
  }
  console.log('当前上传的文件符合要求，允许上传', file.size)

  return true // 允许文件上传
}
// 查看/修改仪器信息
let Details = ref(false)
let DetailsMsg = ref({})
// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([])
//表头字段设置--->设置表头的勾选数据
let SetInstrumentListArr = ref([
  { width: '192', min_width: '192', fixed: '', field: 'instr_number', checked: true, lable: '仪器编号' },
  { width: '308', min_width: '308', fixed: '', field: 'instr_name', checked: true, lable: '仪器名称' },
  { width: '108', min_width: '108', fixed: '', field: 'instr_status', checked: true, lable: '仪器状态' },
  { width: '160', min_width: '160', fixed: '', field: 'instr_abbre', checked: true, lable: '仪器简称' },
  { width: '160', min_width: '160', fixed: '', field: 'measure_principle', checked: false, lable: '测量原理' },
  { width: '160', min_width: '160', fixed: '', field: 'measure_range', checked: false, lable: '测量范围' },
  { width: '218', min_width: '218', fixed: '', field: 'parameter_interpretation', checked: false, lable: '参数解释' },
  { width: '108', min_width: '108', fixed: '', field: 'group', checked: false, lable: '仪器组别' },
  { width: '160', min_width: '160', fixed: '', field: 'manufacturer', checked: false, lable: '生产厂家' },
  { width: '192', min_width: '192', fixed: '', field: 'manufacturing_no', checked: false, lable: '出厂编号' },
  { width: '192', min_width: '192', fixed: '', field: 'specification', checked: false, lable: '规格型号' },
  { width: '160', min_width: '160', fixed: '', field: 'traceability', checked: false, lable: '溯源方式' },
  { width: '192', min_width: '192', fixed: '', field: 'management', checked: true, lable: '仪器管理员' },
  { width: '218', min_width: '218', fixed: '', field: 'laboratory', checked: true, lable: '所属实验室' },
  { width: '160', min_width: '160', fixed: '', field: 'remark', checked: false, lable: '备注' },
  { width: '264', min_width: '264', fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  { width: '160', min_width: '160', fixed: '', field: 'calibration_date', checked: false, lable: '校准日期' },
  { width: '160', min_width: '160', fixed: '', field: 'calibration_period', checked: false, lable: '校验周期' },
  { width: '144', min_width: '144', fixed: '', field: 'calibration_nextdate', checked: true, lable: '下次校准日期' },
  { width: '108', min_width: '108', fixed: '', field: 'calibration_alarm', checked: false, lable: '校准提醒' },
])
// 表头字段设置--->恢复默认字段
let DefaultHeaderData = ref([
  { width: '192', min_width: '192', fixed: '', field: 'instr_number', checked: true, lable: '仪器编号' },
  { width: '308', min_width: '308', fixed: '', field: 'instr_name', checked: true, lable: '仪器名称' },
  { width: '108', min_width: '108', fixed: '', field: 'instr_status', checked: true, lable: '仪器状态' },
  { width: '160', min_width: '160', fixed: '', field: 'instr_abbre', checked: true, lable: '仪器简称' },
  { width: '160', min_width: '160', fixed: '', field: 'measure_principle', checked: false, lable: '测量原理' },
  { width: '160', min_width: '160', fixed: '', field: 'measure_range', checked: false, lable: '测量范围' },
  { width: '218', min_width: '218', fixed: '', field: 'parameter_interpretation', checked: false, lable: '参数解释' },
  { width: '108', min_width: '108', fixed: '', field: 'group', checked: false, lable: '仪器组别' },
  { width: '160', min_width: '160', fixed: '', field: 'manufacturer', checked: false, lable: '生产厂家' },
  { width: '192', min_width: '192', fixed: '', field: 'manufacturing_no', checked: false, lable: '出厂编号' },
  { width: '192', min_width: '192', fixed: '', field: 'specification', checked: false, lable: '规格型号' },
  { width: '160', min_width: '160', fixed: '', field: 'traceability', checked: false, lable: '溯源方式' },
  { width: '192', min_width: '192', fixed: '', field: 'management', checked: true, lable: '仪器管理员' },
  { width: '218', min_width: '218', fixed: '', field: 'laboratory', checked: true, lable: '所属实验室' },
  { width: '160', min_width: '160', fixed: '', field: 'remark', checked: false, lable: '备注' },
  { width: '264', min_width: '264', fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  { width: '160', min_width: '160', fixed: '', field: 'calibration_date', checked: false, lable: '校准日期' },
  { width: '160', min_width: '160', fixed: '', field: 'calibration_period', checked: false, lable: '校验周期' },
  { width: '144', min_width: '144', fixed: '', field: 'calibration_nextdate', checked: true, lable: '下次校准日期' },
  { width: '108', min_width: '108', fixed: '', field: 'calibration_alarm', checked: false, lable: '校准提醒' },
])
// 要切换的值是:
const change_status_values = ref('')
const is_dialogShow = ref(true)
// todo
// 批量操作  true/false 盒子
let BatchBox = ref(false)
// 批量删除盒子 BatchDelete
let BatchDelete = ref(false)
// 单个删除仪器名字
const DeleteMsg = ref({
  Name: '',
  ID: '',
})

//   当前显示的详情数据id
let currentID = ref('')
// 新建仪器按钮
const is_CreatedDeviceDialogShow = ref(false)
//  每一行的 ... 点击
let ClickMoreOptions = ref(false)
const CMoreOptions = ref(null) //获取MoreOptions盒子的ref
let OptionsData = ref()
//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  loading: true,
  list: [],
  gender: '',
  age: '',
  Status: '全部',
})
const gender = ['男', '女']
const ageList = ['不限年龄', '18~25', '26~40', '40~49', '≥50']
let Volunteerdetails = ref(false) // !查看志愿者详情
// sort排序字段
let SORT = ref({ createdAt: -1 })
// 查看详情的数据
let information = ref([])
let information1 = ref([])
let Volunteereditor = ref(false)
// 模糊搜索 判断是否是第一次
const EventSearch = ref(false)
// 模糊Age 判断是否是第一次
const AgeSearch = ref(false)
// 模糊Gender 判断是否是第一次
const GenderSearch = ref(false)

// 高级搜索初始数据
const initialize = reactive(null)
// ! 显示按钮
let isShow = ref(false)
// ! 线上报名 身份证数据
let IDnumber = ref(null)
// 高级搜索数据存储
const InstrumentData = reactive({
  instr_number: null, //
  instr_name: null, //
  instr_status: null, //
  instr_abbre: null, //
  instr_type: null,
  group: null, //
  measure_range: null, //
  measure_principle: null, //
  parameter_interpretation: null, //
  manufacturer: null, //
  manufacturing_no: null, //
  specification: null, //
  traceability: null, //
  laboratory: null, //
  management: null, //
  remark: null, //
  calibration_nextdate: null,
  calibration_period: null,
  calibration_date: null,
  calibration_alarm: null,
})
const HeightOption = ref({
  Status: [
    { lable: '正常', value: '正常' },
    { lable: '待维护', value: '待维护' },

    { lable: '维护中', value: '维护中' },
    { lable: '报废', value: '报废' },
  ],
  group: [
    { lable: '组别1', value: '组别1' },
    { lable: '组别1', value: '组别1' },

    { lable: '组别1', value: '组别1' },
    { lable: '组别1', value: '组别1' },
  ],
  management: [
    { lable: '管理1', value: '管理1' },
    { lable: '管理2', value: '管理2' },
  ],
  laboratory: [
    { lable: '实验室1', value: '实验室1' },
    { lable: '实验室2', value: '实验室2' },
  ],
  calibration_period: [
    { lable: '周期1', value: '周期1' },
    { lable: '周期2', value: '周期2' },
  ],
  Search: false,
  Show: false,
})
let defaultHeader = ref(['仪器编号', '仪器名称', '仪器状态', '仪器简称', '仪器管理员', '所属实验室', '操作', '下次校准日期'])

const InstrumentDataOne = reactive({
  volun_number: '', // 志愿者id
  volun_name: '', // 姓名
  volun_gender: '', // 性别
  idNumber: '', //身份证号
  volun_phone: '', // 联系电话
  referee: '', //推荐人
  volun_birthday: '', // 出生日期
  volun_age: '', //  年龄
  address: '', //地址
  monthly_income: '', //  月收入
  marital_history: '', // 结婚
  three_phase: '', //三期
  tags: [], //标签
  remark: '', //备注
  createdAt: '', //第一次注册时间
  reg_total: '', //历史项目
  project_total: '', //正在进行
  parting_total: '', // 正在参与项目
  skin_texture: '', // 肤质
  skin_type: '', //皮肤类型
  skin_issues: [], //皮肤问题
  skin_color: '', //肤色
  use_products: [], //使用过的产品
  smoke: '',
  drink: '',
  underlying_disease: '', //健康
  account_name: '',
  account_number: '',
  bank: '',
  reservation_time: '',
  calibration_alarm: '',
})

//显示功能的字段设置
let AllShowHeader = ref([])
// 全部显示里被隐藏的
let AllHidenHeader = ref([])
const SetShow = ref(false)
const ShowBox = ref(null) //获取Show盒子的ref
const ShowCheck = ref([])
// !高级搜索数据

// 监听--->删除弹窗的显示隐藏
watch(
  () => BatchDelete.value,
  (newValue) => {
    if (newValue == false) {
      // DeleteMsg.value.Name = '';
      // DeleteMsg.value.ID = '';
    }
  }
)
// 监听 批量操作--->切换状态的状态值
watch(
  () => change_status_values,
  (newValue) => {
    console.log('最新的状态码', newValue)
  }
)
//切换/未开始/招募中/进行中/已停止/全部项目
watch(
  () => ClickActive.value,
  async (newValue) => {
    const $table = xTable.value
    $table.clearCheckboxRow()
    checkedArr.value.splice(0, checkedArr.value.length) //清空数组

    if (newValue == 1) {
      demo1.Status = '正常'
    } else if (newValue == 2) {
      demo1.Status = '待维护'
    } else if (newValue == 3) {
      demo1.Status = '维护中'
    } else if (newValue == 4) {
      demo1.Status = '报废'
    } else if (newValue == 0) {
      demo1.Status = '全部'
    }

    console.log('Project--->', newValue)

    const res = await http.post('/instrument/find-instrument', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
      status: demo1.Status,
    })
    if (res.status == 0) {
      demo1.tableData = res.instruments
      page5.totalResult = res.totalCount
    }
  }
)
// 监听-->关闭修改页面
watch(
  () => is_CreatedDeviceDialogShow.value,
  (newVale) => {
    if (newVale == false && information.value.length != 0) {
      console.log('修改关闭 :>> ', '修改关闭')
      reqProjectList()
      ResetInDetails()
    } else {
      console.log('修改 :>> ', '修改')
      reqProjectList()
    }
  }
)
// 筛选表头数据
const SearchData = async () => {
  demo1.loading = true
  let gender = ''
  let age = 0
  // 获取性别
  if (demo1.gender == '男') {
    gender = 1
  } else if (demo1.gender == '女') {
    gender = 2
  }
  // 获取年龄
  switch (demo1.age) {
    case '不限年龄':
      age = 0
      break
    case '18~25':
      age = 2
      break
    case '26~40':
      age = 3
      break
    case '40~49':
      age = 4
      break
    case '≥50':
      age = 5
      break
  }
  if (gender != '') {
    const res = await http.post('/Recruit_Form/find-regexRecruit_Forms', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      Project: store.state.Project.ProjectId,
      Content: demo1.filterName,
      sex: {
        volun_gender: gender,
      },
      age: age,
    })

    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.recruit_form
      page5.totalResult = res.totalCount
    }
  } else {
    const res = await http.post('/Recruit_Form/find-regexRecruit_Forms', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      Project: store.state.Project.ProjectId,
      Content: demo1.filterName,
      sex: {},
      age: age,
    })
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.recruit_form
      page5.totalResult = res.totalCount
    }
  }
}

// 新建仪器
const CreatedDevice = () => {
  if (!store.state.role_info.includes('instrument-list-created')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  if (!store.state.role_info.includes('project-list-edit')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  information.value = []
  // CreatedInstrumentDatahow.value = !CreatedInstrumentDatahow.value;
  console.log('点击新建仪器按钮')
  is_CreatedDeviceDialogShow.value = true
}
// 导入仪器
const dialogShowChange = () => {
  console.log('123')
  if (!store.state.role_info.includes('instrument-list-export')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  } else {
    console.log('456')
  }
  dialogShow.value = true
}
//->全部数据
const reqProjectList = async () => {
  const res = await http.post('/instrument/find-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
  })
  console.log('1111', res.instruments)
  console.log('1111', res.instruments.imgs)
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.instruments
    page5.totalResult = res.totalCount

    console.table('res :>> ', res)
    res.instruments.forEach((item, index) => {
      if (item.management != undefined) {
        console.log(item.management)
        console.log(selectInstrumentAdministratorAll.value)
        const result = selectInstrumentAdministratorAll.value.find((m) => m._id === item.management)
        console.log(result)
        let name = result ? result.name : null
        demo1.tableData[index].management = name
        console.log(demo1.tableData[index].management)
      }
      if (item.calibration_date) {
        demo1.tableData[index].calibration_date = item.calibration_date.slice(0, 10)
      }
      if (item.calibration_nextdate) {
        demo1.tableData[index].calibration_nextdate = item.calibration_nextdate.slice(0, 10)
      }
      console.log(item.maintain_info)
      if (item.maintain_info.length == 0) {
        demo1.tableData[index].maintain_info = []
      } else if (item.maintain_info[0] && item.maintain_info[0].time == undefined) {
        item.maintain_info.shift()
        demo1.tableData[index].maintain_info = item.maintain_info
      }
      console.log(item.maintain_info)
      // if (item.maintain_info && item.maintain_info.time==undefined) {
      // //demo1.tableData[index].maintain_info = item.maintain_info.splice(0, 1);
      //  demo1.tableData[index].maintain_info = [];
      //  console.log(demo1.tableData[index].maintain_info);
      //}
    })
  }
}
// 监听项目id
watch(
  () => store.state.Project.ProjectId,
  (newValue) => {
    reqProjectList()
  }
)
// 设置新建志愿者组件显示隐藏
let CreatedInstrumentDatahow = ref(false)
// ! 控制批量操作的显示
let isshowOperation = ref(false)
// ! 导出志愿信息弹框
let isDerived = ref(false)
// ! 导出志愿信息弹框   失败
let isDerived_delect = ref(false)
// ! 线上报名
let Onlineregistration = ref(false)
// ! 下拉实时更新数据
let options = ref([])
//  !输入框数据
let selectID = ref([])
// ! 报名弹窗
let Register = ref(false)
// ! 搜索的数据
let remark = ref(true)
let oneVolunteerdata = ref([])
// !  实时搜索数据
const remoteMethod = (query) => {
  console.log('刚开始的数据oneVolunteerdata', oneVolunteerdata.value.length)
  if (query) {
    setTimeout(async () => {
      const { result } = await http.post('/instrument/find-instrument', {
        page: 1,
        size: 10,
        sort: { createdAt: -1 },
        regex: query,
        sex: {},
        age: '',
      })

      if (result.length > 1) {
        console.log(result[0].idNumber, instruments[0].volun_name)
        options.value = result.map(({ idNumber, volun_name }) => {
          return {
            value: idNumber,
            label: `${idNumber} ${volun_name}`,
          }
        })
      } else {
        options.value = [
          {
            value: query,
            label: query,
          },
        ]
      }
    }, 200)
  } else {
    options.value = []
  }
}
//附件--下载
const downloadTheFiles = async (row) => {
  console.log('附件-下载-data', row)
  console.log('点击了附件下载')
  const { downloadPath, filePath } = row

  const repsonse = await http.post(
    'https://cems.fuhuan.ltd/api/upload/attachmentDownload',
    { downloadPath: downloadPath ? downloadPath : filePath },
    { responseType: 'arraybuffer' }
  )

  const blob = new Blob([repsonse], {
    type: 'application/x-zip-compressed;charset=utf-8',
  })
  let name = '附件下载.zip'
  const downloadElement = document.createElement('a')
  let classificationhref = window.URL.createObjectURL(blob)
  downloadElement.href = classificationhref
  downloadElement.download = `${name}`
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(classificationhref)
}
// ! 点击数据进行查询
const search_one_data = async () => {
  remark.value = false
  let id = selectID.value
  console.log(id, 'id')
  try {
    const { results } = await http.post('/volunteer/find-users', {
      size: 1,
      InstrumentData: { idNumber: id },
      page: 1,
      sort: { updatedAt: -1 },
    })
    oneVolunteerdata.value = results
    console.log(oneVolunteerdata, 'oneVolunteerdata')
    console.log('刚开始的数据oneVolunteerdata', oneVolunteerdata.value.length)
  } catch (error) {
    console.log('失败')
  }
}
// ! 线上报名弹窗
const isOnlineregistration = () => {
  Onlineregistration.value = !Onlineregistration.value
  selectID.value = []
  remark.value = true
  oneVolunteerdata.value = []
  Register.value = false
}
// 点击新建志愿者
const CreatedVolunteerChange = () => {
  CreatedInstrumentDatahow.value = !CreatedInstrumentDatahow.value
  //   //console.log('点击新建志愿者');
}
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params
  //   //console.log(columns); // 打印排序后的表头列数组对象
}
// note 表内导航栏
const ProjectState = ref('')
ProjectState.value = ['全部', '正常', '待维护', '维护中', '报废']
const changeShow = ref(false)
const reqAllData = async () => {
  const res = await http.post('/user/findAll-user', {})
  //   //console.log('全部返回的数据是:', res);
}
//!高级搜索
const reqAllSelectData = async (val) => {
  // ! 取消null的字段
  for (let prop in InstrumentData) {
    if (InstrumentData[prop] === null) {
      delete InstrumentData[prop]
    }
  }
  //   //console.log('In's'tu'men't', InstumentDa'ta);
  const res = await http.post('/instrument/advancedfind-instrument', {
    size: page5.pageSize,
    findParams: InstrumentData,
    page: val ? page5.currentPage : 1,
  })
  console.log('全部返回的数据是:', res)
  if (res.status == 0) {
    HeightOption.value.Show = true
    demo1.tableData = res.instruments
    page5.totalResult = res.totalCount
    changeShow.value = false
    res.instruments.forEach((item, index) => {
      if (item.management != undefined) {
        console.log(item.management)
        console.log(selectInstrumentAdministratorAll.value)
        const result = selectInstrumentAdministratorAll.value.find((m) => m._id === item.management)
        console.log(result)
        let name = result ? result.name : null
        demo1.tableData[index].management = name
        console.log(demo1.tableData[index].management)
      }
      if (item.calibration_date) {
        demo1.tableData[index].calibration_date = item.calibration_date.slice(0, 10)
      }
      if (item.calibration_nextdate) {
        demo1.tableData[index].calibration_nextdate = item.calibration_nextdate.slice(0, 10)
      }
      console.log(item.maintain_info)
      if (item.maintain_info) {
        //demo1.tableData[index].maintain_info = item.maintain_info.splice(0, 1);
        demo1.tableData[index].maintain_info = []
        console.log(demo1.tableData[index].maintain_info)
      }
    })
  }
}
// !重置按钮
const reset = () => {
  demo1.age = ''
  demo1.gender = ''
  demo1.filterName = ''
  EventSearch.value = false
  GenderSearch.value = false
  AgeSearch.value = false
  ClickActive.value = 0
  HeightOption.value.Show = false
  checkedArr.value.splice(0, checkedArr.value.length) //清空数组
  reqProjectList()
}
// ! 退出按钮
const unSelect = () => {
  changeShow.value = false
}
// !批量按钮显示
const showOperation = () => {
  isshowOperation.value = true
}
// ! 批量导出
const Export = () => {
  if (!store.state.role_info.includes('instrument-list-export')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  console.log(selectedRows)
  console.log(SetVolunteerHeaderArr.value)

  if (selectedRows.length !== 0) {
    console.log(SetVolunteerHeaderArr.value)
    isshowOperation.value = false
  } else {
    // ElMessage('一条都没有');
  }
  // 判断值，进行展示
}

//todo ! todo Batch删除按钮
const delect = () => {
  if (!store.state.role_info.includes('instrument-list-batchdelete')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  if (checkedArr.value.length !== 0) {
    BatchDelete.value = true
  } else {
    message({
      message: '请先选择数据',
      ShowClose: true,
      type: 'error',
    })
  }
}
// 批量删除
const DelteProject = async () => {
  const res = await http.post('/instrument/delete-instrument', checkedArr.value)
  if (res.status == 0) {
    BatchDelete.value = false
    reqProjectList()
    message({
      message: '删除成功',
      ShowClose: true,
      type: 'success',
    })
  }
}

// 上传附件返回信息
const attachmentList = ref([])
// 上传附件事件
const uploadsFile = async (params) => {
  //                                 action="https://cems.fuhuan.ltd/api/upload/file-upload"

  // filesArr.value.push(uploadFiles.file);

  console.log('parmas :>> ', params)
  const file = params.file
  console.log('file :>> ', file)
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'attachment')
  const response = await http.post('/upload/file-upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  console.log('response :>> ', response)
  const { status, file: fileData } = response
  if (status === 0) {
    attachmentList.value.push(fileData)
    filesArr.value.push(fileData)
    console.log('发给后端的filesArr数组', filesArr.value)
    const t = formatTime(response.file.uploadTime)
    console.log('t', t) // 2023-05-16 14:57
    let obj = {
      id: uploadTableDeviceIndex.value++,
      fileName: response.file.fileName,
      upLoadTime: t,
      data: response.file,
      // action: ["预览", "下载", "删除"],
      action: ['下载', '删除'],
    }
    uploadTableData.value.push(obj)
    console.log(uploadTableData.value)
    information1.value.files.push(obj)
    message({
      message: '附件上传成功',
      // showClose: true,
      showClose: true,
      type: 'success',
    })
  } else {
    message({
      message: '附件上传失败，请稍后再试',
      // showClose: true,
      showClose: true,
      type: 'warning',
    })
  }
}
//上传附件 上传成功回调
const onSuccess = (uploadFiles, uploadFiles2) => {
  // console.log('uploadFiles', uploadFiles);
  // console.log('uploadFiles.file', uploadFiles.file);

  // filesArr.value.push(uploadFiles.file);
  // console.log('附件上传成功后的file数组', filesArr.value);

  // console.log('uploadFiles2', uploadFiles2);

  const {
    name,
    raw: { lastModifiedDate },
    response: { file },
  } = uploadFiles2

  console.log('name', name)
  console.log('lastModifiedDate', lastModifiedDate)
  console.log('file', file)
  const t = formatTime(lastModifiedDate)
  console.log('t', t) // 2023-05-16 14:57
  let obj = {
    id: uploadTableDeviceIndex.value++,
    fileName: name,
    upLoadTime: t,
    // data: file,
    // action: ["预览", "下载", "删除"],
    action: ['下载', '删除'],
  }
  uploadTableData.value.push(obj)
}
// ! !导出志愿者信息弹窗  失败  确定
const isshowDerived_delect_true = async () => {
  // !删除对应数据，并刷新页面
  const ids = selectedRows.map((obj) => obj._id)
  console.log(ids, 'idddddd') // [1, 2, 3]
  try {
    const res = await http.delete('/volunteer/delete-user', {
      deleteData: ids,
    })
    isDerived_delect.value = !isDerived_delect.value
    reqProjectList()
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    })
  } catch (error) {
    message({
      message: '删除失败',
      grouping: true,
      type: 'error',
    })
  }
}
// !显示选择框
const showSelect = () => {
  isShow.value = !isShow.value
}
// ! 创建志愿者
// const CreateInstrumentData = async () => {
//   try {
//     await http.post('/Recruit_Form/created-Recruit_Form', InstrumentDataOne);
//     message({
//       message: '创建成功',
//       grouping: true,
//       type: 'success',
//     });
//     Onlineregistration.value = !Onlineregistration.value;
//     selectID.value = [];
//     remark.value = true;
//     oneVolunteerdata.value = [];
//     Register.value = false;
//     reqProjectList();
//   } catch (error) {
//     message({
//       message: '创建失败',
//       grouping: true,
//       type: 'error',
//     });
//   }
// };
// todo
const showEdit = ref(false)
const EditOpen = () => {
  timeArr.value = []
  console.log(information1.value.calibration_alarm)
  console.log(information1.value.files)
  //is_CreatedDeviceDialogShow.value = true;
  showDetail.value = false
  showEdit.value = true
  information1.value = JSON.parse(JSON.stringify(information.value))
  console.log(information1.value.calibration_alarm)

  dialogVisible.value = true
  butInfoValueArr.value[0] =
    information1.value.calibration_alarm === undefined || information1.value.calibration_alarm === null || information1.value.calibration_alarm === ''
      ? 0
      : information1.value.calibration_alarm == false
      ? 1
      : 2
  console.log(butInfoValueArr.value)
  console.log(information1.value)
}

//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
})

// 分页--->分页
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false
  //console.log('点击点击 :>> ');
}

//分页--->跳转页面显示数据处理
const pageChange = async () => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()

  if (demo1.filterName == '' && HeightOption.value.Show == false) {
    const res = await http.post('/instrument/find-instrument', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
      status: demo1.Status,
    })
    console.log('res123 :>> ', res)
    page5.totalResult = res.totalCount
    demo1.tableData = res.instruments
  } else if (HeightOption.value.Show) {
    console.log('高级搜索翻页')
    reqAllSelectData(page5.currentPage)
  } else {
    const res = await http.post('/instrument/find-instrument', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
      status: demo1.Status,
    })
    console.log(res, '1233456')

    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.instruments
      page5.totalResult = res.totalCount

      console.table('res :>> ', res)
      res.instruments.forEach((item, index) => {
        console.log(item)
        console.log(item.management)
        if (item.management != undefined) {
          const result = selectInstrumentAdministratorAll.value.find((m) => m._id === item.management)
          let name = result ? result.name : null
          console.log(name, '管理员名字')
          console.log(demo1.tableData[index].management)
          demo1.tableData[index].management = name
        }
        if (item.calibration_date) {
          demo1.tableData[index].calibration_date = item.calibration_date.slice(0, 10)
        }
        if (item.calibration_nextdate) {
          demo1.tableData[index].calibration_nextdate = item.calibration_nextdate.slice(0, 10)
        }
      })
    }
  }
}

//导出弹窗显示隐藏
let dialogShow = ref(false)
//单个导出
const isshowOneExport = ref(false)
const name = ref()
const isshowOneExport1 = ref(false)
const showOneExport1 = () => {
  if (!store.state.role_info.includes('instrument-list-batchexport')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  console.log(OptionsData.value.instr_name)
  name.value = OptionsData.value.instr_name
  isshowOneExport1.value = true
  console.log(isshowOneExport.value)
}
const null_reminder_export = ref(false)
const isshowExport = () => {
  console.log(selectedRows)
  if (checkedArr.value.length == 0) {
    null_reminder_export.value = true
  } else {
    null_reminder_export.value = false
    isshowOneExport.value = true
  }
}
// 点击...
const ClickMethods = (row, event) => {
  console.log(row)
  OptionsData.value = row
  console.log('object :>> ', OptionsData.value._id)
  // console.log(DelectData.value);
  ClickMoreOptions.value = false
  ClickMoreOptions.value = true

  // console.log('event :>> ', event, CMoreOptions.value);

  CMoreOptions.value.style.left = event.clientX - 120 + 'px'
  CMoreOptions.value.style.top = event.clientY + 30 + 'px'
  // console.log('event :>> ', ClickMoreOptions.value);
}
// 单个/批量导出
const ExportFile = async (val, row) => {
  console.log(checkedArr.value)
  console.log(val)
  console.log(row)
  // console.log('object :>> ', OptionsData.value._id);
  if (val == 'single') {
    isshowOneExport1.value = false
    // console.log(row);
    const res = await http.post('/upload/export-instrument', [OptionsData.value._id])
    console.log('单个导出的返回结果', res)
    if (res.status == 0) {
      xlsx.writeFileXLSX(res.xlsxData, `仪器列表 ${dayjs().format('YYYYMMDD')}.xlsx`)
    }
  } else {
    isshowOneExport.value = false
    // console.log(row);
    const res = await http.post('/upload/export-instrument', checkedArr.value)
    console.log('批量导出的返回结果', res)
    if (res.status == 0) {
      xlsx.writeFileXLSX(res.xlsxData, `仪器列表 ${dayjs().format('YYYYMMDD')}.xlsx`)
    }
  }
}
//导出方法
const exportDataEvent = () => {
  // const $table = xTable.value;
  // $table.exportData({ type: 'csv' });
  console.log('123')

  dialogShow.value = true
}

//复选框数组
var selectedRows = reactive([])
const checkedArr = ref([])

// 复选框点击事件
const selectChangeEvent1 = (row) => {
  // console.log('点击的id:', row.rowid);
  console.log('row :>> ', row)

  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records

  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid)
  } else {
    let index = checkedArr.value.indexOf(row.rowid)
    checkedArr.value.splice(index, 1)
  }
  console.log('最新:', checkedArr.value)
}
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  console.log(checked ? '所有勾选事件' : '所有取消事件', records)
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id)
      }
    }
    console.log('最新:', checkedArr.value)
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = []

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id)
    })

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item)
    })
    console.log('最新:', checkedArr.value)
  }
}
// 模糊查询

const searchEvent2 = async () => {
  demo1.loading = true
  let Project = ''
  if (ClickActive.value == 1) {
    Project = '正常'
  } else if (ClickActive.value == 2) {
    Project = '待维护'
  } else if (ClickActive.value == 3) {
    Project = '维护中'
  } else if (ClickActive.value == 4) {
    Project = '报废'
  }
  const res = await http.post('/instrument/find-instrument', {
    page: (page5.currentPage = 1),
    size: page5.pageSize,
    sort: { createdAt: -1 },
    content: demo1.filterName,
    status: Project,
  })
  console.log(res, '1233456')
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.instruments
    page5.totalResult = res.totalCount
  }
}
//  More -->下拉选择中的固定方法
const toggleFixedColumn = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
const toggleFixedColumns = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
// More -->升序/降序
const AscendingSort = async (index, type) => {
  console.log('我的type: :>> ', type)
  demo1.loading = true

  // console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  // console.log('固定信息:', tableColumns);
  const ColumnId = tableColumns[index].field
  const sorts = {}
  if (type == 'Asc') {
    sorts[ColumnId] = 1
  } else {
    sorts[ColumnId] = -1
  }

  SORT.value = sorts
  console.log(SORT.value, '123456789990')
  const res = await http.post('/instrument/find-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
  })
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.instruments
    page5.totalResult = res.totalCount

    console.table('res :>> ', res)
  }
}
//
// 获取当前的全部数据
const TransferData = () => {
  // 将全部显示的数据  放到 AllShowHeader中
  SetInstrumentListArr.value.forEach((item, index) => {
    if (item.checked == true) {
      AllShowHeader.value.push(item)
    }
  })
  console.log('AllShowHeader.value :>> ', AllShowHeader.value)
}
// !恢复目前隐藏的列表----> 获取event 设置show显示框的位置
const SetShowFunction = (event) => {
  SetShow.value = false
  SetShow.value = true
  // console.log('event :>> ', event, ShowBox.value);
  ShowBox.value.style.left = event.clientX - 10 + 'px'
  ShowBox.value.style.top = event.clientY + 20 + 'px'
}
// ! 恢复目前隐藏的列表----> 确认按钮
const ConfirmRecover = () => {
  let TeShowCheck = []
  let TeAllHidenHeader = []
  const $table = xTable.value
  const column = $table.getColumns()
  console.log('ShowCheck.value :>> ', ShowCheck.value)
  for (let i = ShowCheck.value.length; i >= 0; i--) {
    if (ShowCheck.value[i] == true) {
      // // column[index].visible = false;
      // console.log('column :>> ', ShowCheck.value[i], AllHidenHeader.value[i]);

      // 显示列
      $table.showColumn(AllHidenHeader.value[i].field)
      // 同步删除 数据 隐藏列/checked
      AllHidenHeader.value.splice(i, 1)
      ShowCheck.value.splice(i, 1)
      // console.log('column :>> ', column, $table);
      // console.log('column :>> ', $table.showColumn());
      // console.log('column :>> ', ShowCheck.value, AllHidenHeader.value);
    }
  }
  SetShow.value = false
}
// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false
  const $table = xTable.value
  const column = $table.getColumns()
  // console.log('column[index] :>> ', column[index]);
  column[index].visible = false
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value.push(column[index])
  ShowCheck.value.push(false)
  $table.refreshColumn() //刷新列表
  console.log('ShowChcke.value :>> ', ShowCheck.value)
  // console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
}
// 每一行的查看详情management
const showDetail = ref(false)
let adminName = ref('')
const ShowDetails = async (row) => {
  console.log('row :>> ', row)
  currentID.value = row._id

  Volunteerdetails.value = !Volunteerdetails.value
  Volunteereditor.value = true
  showDetail.value = true
  demo1.tableData.forEach((item, index) => {
    if (item._id == row._id) {
      console.log(item, '-----------------------------')

      information.value = JSON.parse(JSON.stringify(item))
      information1.value = JSON.parse(JSON.stringify(item))
    }
  })
}
//
// 每一行的删除
const DeleteVolun = async (row) => {
  if (!store.state.role_info.includes('instrument-list-delete')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  console.log('row :>> ', row)
  DeleteMsg.value.Name = row.instr_name
  DeleteMsg.value.ID = row._id
  BatchDelete.value = true
  // demo1.loading = true;
}
const SingleDelete = async (val) => {
  console.log(DeleteMsg.value.ID)
  const res2 = await http.post('/instrument/delete-instrument', [DeleteMsg.value.ID])
  if (res2.status == 0) {
    BatchDelete.value = false
    message({
      message: '删除成功',
      ShowClose: true,
      type: 'success',
    })
    console.log('object :>> ', checkedArr.value)
    console.log('object :>> ', val)

    // 清理勾选数组
    let index = checkedArr.value.indexOf(DeleteMsg.value.ID)
    console.log(index, '已存在id的下标')
    if (index != -1) {
      checkedArr.value.splice(index, 1)

      console.log(checkedArr.value, '单个删除之后的数据')
    }
  }
  reqProjectList()
}
// 关闭修改页面请求新的详情数据
const ResetInDetails = async () => {
  const res = await http.post('/instrument/find-instrument', {
    page: 1,
    size: 10,
    sort: SORT.value,
    content: demo1.filterName,
  })
  console.log('res :>> ', res)
  information.value = JSON.parse(JSON.stringify(res.instruments[0]))
  console.log(information.value)
}

// 点击表头的下拉箭头
const OperationBox = ref(null) //获取更多操作盒子的ref
const showIdOptions = ref(false) //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1)
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  //   //console.log('value :>> ', value);
  const table = xTable.value
  const FixedList = table.getColumns()

  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index
    }
  })
  //console.log('FixedIndex下标为 :>> ', FixedIndex);

  showIdOptions.value = false
  showIdOptions.value = true
  // 设置位置
  const Event = event
  OperationBox.value.style.left = event.clientX - 100 + 'px'
}

// ! 高级显示
const ischangeShow = () => {
  changeShow.value = !changeShow.value
  for (let prop in InstrumentData) {
    InstrumentData[prop] = null
  }
}
// // 高级搜索 下拉
// const change_category_val = (e) => {
//   // console.log(e);
//   if (e.includes('都没有')) {
//     // used_category_val.value = ['都没有'];
//     const newValueWithoutAll = ['都没有'];
//     In's't'ru.use_products = newValueWithoutAll;
//   }
// };
// 高级搜索 下拉

const change_basic_illness = (e) => {
  // console.log(e);
  if (e.includes('无任何以上提到的疾病')) {
    const newValueWithoutAll = ['无任何以上提到的疾病']
    Instrument.underlying_disease = newValueWithoutAll
  }
}
// note 全局加载完毕之后执行此事件
nextTick(() => {
  setHeaderColumn()
  // 执行 列表显示/隐藏
  ShowHidenColumn()
  // 固定列表本地数据
  FixedColumn()
  // 获取拖拽数据
  GetDrag()
  // 转移数据
  TransferData()
})

// 检查是否有本地数据()
const setHeaderColumn = () => {
  if (localStorage.getItem('InstrumentList') == null) {
    // ////console.log('"第一次" :>> ', '第一次');
  } else {
    // ////console.log('"第二次" :>> ', '第二次');
    SetInstrumentListArr.value = ase.decrypt(localStorage.getItem('InstrumentList'))
  }
  SetInstrumentListArr.value.forEach((item, index) => {
    if (item.checked == true) {
      allDatas.value.push(SetInstrumentListArr.value[index])
    }
  })
  console.log('最终渲染的全部true数据', allDatas.value)
  // 赋值给dragDemo；
  dragDemo.value.splice(0, dragDemo.value.length)
  dragDemo.value = JSON.parse(JSON.stringify(allDatas.value))
  dragDemos.value = JSON.parse(JSON.stringify(allDatas.value))
}
// 检查本地是否有拖拽数据
const GetDrag = () => {
  if (localStorage.getItem('InsrumentDrag') == null) {
    // //console.log('"第一次" :>> ', '第一次');
  } else {
    // //console.log('"第二次" :>> ', '第二次');
    allDatas.value = ase.decrypt(localStorage.getItem('InsrumentDrag'))
    dragDemo.value = JSON.parse(JSON.stringify(allDatas.value))
    dragDemos.value = JSON.parse(JSON.stringify(allDatas.value))
  }
}
// 表头字段设置----> 点击操作的设置icon
const SetHeader = () => {
  dragDemo.value = JSON.parse(JSON.stringify(dragDemos.value))
  SetTableHeader.value = true
  TemporaryData.value = JSON.parse(JSON.stringify(SetInstrumentListArr.value))

  //console.log('SetTableHeader', SetTableHeader.value);
}
// 表头字段设置----> 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false
  SetInstrumentListArr.value = JSON.parse(JSON.stringify(TemporaryData.value))
}
//  表头字段设置----> 恢复默认字段
const RecoverData = () => {
  // console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value);
  // let defaultdata = readonly(DefaultHeaderData)
  SetInstrumentListArr.value = JSON.parse(JSON.stringify(DefaultHeaderData.value))
  // 恢复默认 拖拽数据
  dragDemo.value = []
  SetInstrumentListArr.value.forEach((item, index) => {
    if (item.checked) {
      dragDemo.value.push(item)
    }
  })
}
// 表头阻断设置---->的点击复选框
const CheckHeader = (i) => {
  // 默认展示的字段/不可取消

  if (defaultHeader.value.indexOf(SetInstrumentListArr.value[i].lable) != -1) {
    message({
      message: `${SetInstrumentListArr.value[i].lable}不能删除`,
      Showclose: true,
      type: 'error',
    })
    SetInstrumentListArr.value[i].checked = true
    return
  }
  // 如果是true ，push进dragdemo
  if (SetInstrumentListArr.value[i].checked) {
    dragDemo.value.push(SetInstrumentListArr.value[i])
  } else {
    //如果是false，从dragdemo中删除
    dragDemo.value = dragDemo.value.filter((item) => item.lable !== SetInstrumentListArr.value[i].lable)
  }
  // console.log('dragDemo.value :>> ', dragDemo.value);
}
// ShowHidenColumn 设置column的的列表(确认按钮)
const ShowHidenColumn = () => {
  // 把drag字段放到 列表里；
  allDatas.value = JSON.parse(JSON.stringify(dragDemo.value))
  dragDemos.value = JSON.parse(JSON.stringify(dragDemo.value))
  const $table = xTable.value
  const column = $table.getColumns()

  //console.log('$table.getColumns :>> ', $table.getColumns());

  for (let i = 0; i < SetInstrumentListArr.value.length; i++) {
    if (SetInstrumentListArr.value[i].checked == true) {
      // //console.log('object :>> ', SetInstrumentListArr[i].field);
      $table.showColumn(SetInstrumentListArr.value[i].field)
    }
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetInstrumentListArr.value[i].lable) {
        if (SetInstrumentListArr.value[i].checked == false) {
          $table.hideColumn(column[j].field)
        }
      }
    }
  }

  $table.refreshColumn()
  //console.log('$table.getColumns :>> ', $table.getColumns());
  SetTableHeader.value = false
}
// FixedColumn 设置column的固定列(初始化本地数据)
const FixedColumn = () => {
  const $table = xTable.value
  const column = $table.getColumns()

  //console.log('column:>> ', column);
  //console.log('SetInstrumentListArr', SetInstrumentListArr.value);
  for (let i = 0; i < SetInstrumentListArr.value.length; i++) {
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetInstrumentListArr.value[i].lable) {
        if (SetInstrumentListArr.value[i].fixed != '') {
          toggleFixedColumn(j, SetInstrumentListArr.value[i].fixed)
        }
      }
    }
  }

  $table.refreshColumn()
  // //console.log('$table.getColumns :>> ', $table.getColumns());
  // SetTableHeader.value = false;
}

//  操作

// 处理出生日期的时间戳
const birthday = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD')
  }
})
// 处理注册时间戳
const createdAt = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD HH:mm')
  }
})
// mounted
onMounted(() => {
  reqProjectList()

  // 初始话存储数据下
  //console.log('demo1.tableData :>> ', demo1.tableData);
  //处理点击更多操作盒子 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false
      }
    }
  })
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (isshowOperation.value == true) {
      if (e.target.id != 'operationBox') {
        isshowOperation.value = false
      }
    }
  })
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (isShow.value == true) {
      if (e.target.id != 'show_box') {
        isShow.value = false
      }
    }
    // 操作...
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false
      }
      // console.log(ClickMoreOptions.value);
    }

    // 批量操作
    if (BatchBox.value == true) {
      if (e.target.id != 'operationBox') {
        BatchBox.value = false
      }
      // console.log(ClickMoreOptions.value);
    }
  })
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false
      }
    }
  })
})
//   提醒  勿删
const is_selectDeviceStatus = () => {
  if (information1.value.instr_status === '') {
    errObj.selectDeviceStatus = true
  } else {
    errObj.selectDeviceStatus = false
  }
}

//   提醒  勿删
const errName = ref('')
const is_inputDeviceName = () => {
  if (information1.value.instr_name === '') {
    errObj.inputDeviceName = true
    errName.value = '仪器名称不可为空'
  } else if (information1.value.instr_name.length > 50) {
    errObj.inputDeviceName = true
    errName.value = '仪器名称格式错误'
  } else {
    errObj.inputDeviceName = false
  }
}
const errNumber = ref('')
const is_inputDeviceNumber = () => {
  if (information1.value.instr_number === '') {
    errObj.inputDeviceNumber = true
    errNumber.value = '仪器编号不可为空'
  } else if (information1.value.instr_number.length > 20) {
    errObj.inputDeviceNumber = true
    errNumber.value = '仪器编号格式错误'
  } else {
    errObj.inputDeviceNumber = false
  }
}

//<!-- 编辑确定按钮点击事件 -->

const updateInstru = async () => {
  console.log(information1.value.management)
  const result = selectInstrumentAdministratorAll.value.find((m) => m.name === information1.value.management)
  let id = result ? result._id : information1.value.management
  console.log(name, '管理员名字')
  information1.value.management = id
  console.log(butInfoValueArr.value[0])
  information1.value.calibration_alarm = butInfoValueArr.value[0] == 1 ? false : butInfoValueArr.value[0] == 2 ? true : false
  is_inputDeviceNumber()
  is_inputDeviceName()
  is_selectDeviceStatus()
  // if (test_site_val.value !== '') {
  if (information1.value.management) {
    console.log(errObj.inputDeviceName, errObj.inputDeviceNumber, errObj.selectDeviceStatus)
    if (errObj.inputDeviceName == false && errObj.inputDeviceNumber == false && errObj.selectDeviceStatus == false) {
      let arr = information1.value.files.filter((item) => {
        const itemString = JSON.stringify(item)
        return !information.value.files.some((otherItem) => JSON.stringify(otherItem) === itemString)
      })
      const requestData = {
        // 仪器名字
        instr_name: information1.value.instr_name,
        // 仪器编号
        instr_number: information1.value.instr_number,
        instr_type: information1.value.instr_type,
        // 维护信息
        maintain_info: information1.value.maintain_info,

        // 附件信息
        files: arr,
        // isdefault: true,
        // 图片
        imgs: imgFileArr.value,
        deleteImgs: deleteImageArr.value,
        // 参数解释
        parameter_interpretation: information1.value.parameter_interpretation,
        // 规格型号
        specification: information1.value.specification,
        // 溯源方式
        traceability: information1.value.tracebility,

        // 仪器状态
        instr_status: information1.value.instr_status,

        // 校验周期
        calibration_period: information1.value.calibration_period,

        // 所属实验室
        laboratory: information1.value.laboratory,
        // 生产厂家
        manufacturer: information1.value.manufacturer,

        // 仪器组别
        group: information1.value.group,
        // 测量范围
        measure_range: information1.value.measure_range,

        // 校准日期
        ...(information1.value.calibration_date && { calibration_date: information1.value.calibration_date }),

        // 下次校准日期
        ...(information1.value.calibration_nextdate && { calibration_nextdate: information1.value.calibration_nextdate }),

        // 备注
        remark: information1.value.remark,

        // 校准提醒  不提醒false      提醒true
        calibration_alarm: information1.value.calibration_alarm,

        //  仪器简称
        instr_abbre: information1.value.instr_abbre,

        // 出厂编号
        manufacturing_no: information1.value.manufacturing_no,

        // 测量原理
        measure_principle: information1.value.measure_principle,
        //仪器管理员
        management: information1.value.management,
        //management: information1.value.management,
        _id: information1.value._id,
      }

      console.log('咋地了')
      await http
        .post('/instrument/update-instrument', requestData)
        .then((res) => {
          console.log('测试', res)
          if (res.status === 0) {
            //   IDS = res.volunteer_id;
            ElMessage({
              showClose: true,
              message: '仪器修改成功',
              type: 'success',
            })
            showEdit.value = false
            showDetail.value = true
            information.value = JSON.parse(JSON.stringify(information1.value))
            const result = selectInstrumentAdministratorAll.value.find((m) => m._id === information1.value.management)
            let name = result ? result.name : null
            console.log(name, '管理员名字')
            information.value.management = name
            reqProjectList()
            console.log(information.value, 'information')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  } else {
    console.log(errObj.inputDeviceName, errObj.inputDeviceNumber, errObj.selectDeviceStatus)
    if (errObj.inputDeviceName == false && errObj.inputDeviceNumber == false && errObj.selectDeviceStatus == false) {
      let arr = information1.value.files.filter((item) => {
        const itemString = JSON.stringify(item)
        return !information.value.files.some((otherItem) => JSON.stringify(otherItem) === itemString)
      })
      const requestData = {
        // 仪器名字
        instr_name: information1.value.instr_name,
        // 仪器编号
        instr_number: information1.value.instr_number,
        instr_type: information1.value.instr_type,
        // 维护信息
        maintain_info: information1.value.maintain_info,

        // 附件信息
        files: arr,

        // 图片
        imgs: imgFileArr.value,
        deleteImgs: deleteImageArr.value,
        // 参数解释
        parameter_interpretation: information1.value.parameter_interpretation,
        // 规格型号
        specification: information1.value.specification,
        // 溯源方式
        traceability: information1.value.tracebility,
        // isdefault: true,
        // 仪器状态
        instr_status: information1.value.instr_status,

        // 校验周期
        calibration_period: information1.value.calibration_period,

        // 所属实验室
        laboratory: information1.value.laboratory,
        // 生产厂家
        manufacturer: information1.value.manufacturer,

        // 仪器组别
        group: information1.value.group,
        // 测量范围
        measure_range: information1.value.measure_range,

        // 校准日期
        ...(information1.value.calibration_date && { calibration_date: information1.value.calibration_date }),

        // 下次校准日期
        ...(information1.value.calibration_nextdate && { calibration_nextdate: information1.value.calibration_nextdate }),

        // 备注
        remark: information1.value.remark,

        // 校准提醒  不提醒false      提醒true
        calibration_alarm: information1.value.calibration_alarm,

        //  仪器简称
        instr_abbre: information1.value.instr_abbre,

        // 出厂编号
        manufacturing_no: information1.value.manufacturing_no,

        // 测量原理
        measure_principle: information1.value.measure_principle,
        //仪器管理员
        // management: information1.value.management,
        //management: information1.value._id,
        _id: information1.value._id,
      }

      console.log('咋地了', requestData)
      await http
        .post('/instrument/update-instrument', requestData)
        .then((res) => {
          console.log('测试', res)
          if (res.status === 0) {
            //   IDS = res.volunteer_id;
            ElMessage({
              showClose: true,
              message: '仪器修改成功',
              type: 'success',
            })
            showEdit.value = false
            showDetail.value = true
            information.value = JSON.parse(JSON.stringify(information1.value))
            //  const result = selectInstrumentAdministratorAll.value.find((m) => m._id === information1.value.management);
            //  let name = result ? result.name : null;
            // console.log(name, '管理员名字');
            // information.value.management = name;
            reqProjectList()
            console.log(information.value, 'information')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
// 获取仪器管理员

// 导入志愿者错误返回的路径
let errorPath = ref()
// 导入志愿者错误的数据量
let errFileNum = ref(null)
// 上传的文件名
let fileName = ref('')
// 上传事件
const uploadFile = async (params) => {
  console.log(params)
  const file = params.file
  // fileName.value=file.name
  const lastIndex = file.name.lastIndexOf('.')
  fileName.value = file.name.substring(0, lastIndex)
  const form = new FormData()
  form.append('file', file)
  console.log(params)
  await http
    .post('/upload/upload-instrument', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async (res) => {
      console.log(res)
      if (res.status == 0) {
        dialogShow.value = false
        reqProjectList()
        ElMessage({
          showClose: true,
          message: '导入仪器数据成功',
          type: 'success',
        })
      } else if (res.status == 1) {
        ElMessage({
          showClose: true,
          message: res.message,
          type: 'error',
        })
      }
      if (res.status == 2) {
        dialogShow.value = false
        errorPath.value = res.errorPath
        errdialogShow.value = true
        errFileNum.value = res.errorNumber
      }
    })
}

onMounted(async () => {
  try {
    const res = await http.post('/user/findInstrumentOptions-user', {})
    if (res.status == 0) {
      const admins = res.userOptions
      selectInstrumentAdministratorAll.value = admins
    } else {
      console.log('获取失败')
    }
  } catch (error) {
    console.error(error)
    // 处理错误
  }
})
// note 组件销毁
onBeforeUnmount(() => {
  const table = xTable.value
  console.log('组件销毁时的数值 :>> ', table)
  const FixedList = table.getColumns()
  // console.log('销毁时的列顺序', FixedList);
  for (let i = 1; i < FixedList.length; i++) {
    for (let j = 0; j < SetInstrumentListArr.value.length; j++) {
      if (FixedList[i].field == SetInstrumentListArr.value[j].field) {
        SetInstrumentListArr.value[j].fixed = FixedList[i].fixed
      }
    }
  }

  localStorage.setItem('InstrumentList', ase.encrypt(SetInstrumentListArr.value))
  localStorage.setItem('InsrumentDrag', ase.encrypt(dragDemo.value))
})

// 上传文件名
let uploadFileName = ref('')
// 是否上传错误
let uploadFileErr = ref(false)

const handlePreviews = (file) => {
  console.log(file, 'handlePreviews')
}

const handleRemoves = (file, fileList) => {
  console.log(file, fileList, 'handleRemoves')
}
const fileLists = ref([])

//导入失败显示隐藏
const errdialogShow = ref(false)

//下载错误模板
const downloadErrFile = async () => {
  axios
    .post('https://cems.fuhuan.ltd/api/upload/downloadError-volunteer', { errorPath: errorPath.value }, { responseType: 'arraybuffer' })
    .then((result) => {
      console.log(result, '返回的错误数据')
      const blob = new Blob([result.data], {
        type: 'application/vnd.ms-excel',
      })
      let name = fileName.value + '-报错.xlsx'
      const downloadElement = document.createElement('a')
      let classificationhref = window.URL.createObjectURL(blob)
      downloadElement.href = classificationhref
      downloadElement.download = `${name}`
      // // console.log("blob :>> "，blob);
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(classificationhref)
    })
}
// 请求组别 数据
const ReqDefault = async () => {
  const res = await http.post('/setting/fetch-setting', {
    type: 'instrument',
  })

  console.log('res请求初始数据为 :>> ', res)
  if (res.status == 0) {
    console.log('res请求初始数据为 :>> ', res)

    res.groups.forEach((item, index) => {
      if (item.value != false) {
        deviceGroupAll.value.push(item.name)
      }
    })

    if (deviceGroupAll.value.length == 0) {
      deviceGroupAll.value.push('暂无标签，请前往【自定义设置】新建')
    }
    console.log('获取的最终数据', deviceGroupAll.value)
  }
}
// 数据--格式化
const formatterData = (val) => {
  return val === undefined || val === '' || val === null ? '——' : val
}
</script>

<style lang="less" scoped>
@import url('@/style/views/InstrumentMangement/InstrumentList.less');
.itxst {
  width: 600px;
  display: flex;
}

.itxst > div:nth-of-type(1) {
  flex: 1;
}

.itxst > div:nth-of-type(2) {
  width: 270px;
  padding-left: 20px;
}

.item {
  // border: solid 1px #eee;
  padding: 6px 10px;
  text-align: left;
}

.item:hover {
  cursor: move;
}

// .item + .item {
// margin-top: 10px;
// }

.ghost {
  // 跟随鼠标的样式
  border: solid 1px #027aff !important;
  background: #fff !important;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.chosenClass {
  // 鼠标拉动的样式。
  background-color: #fff;
  opacity: 1;
  border: solid 1px #027aff;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.fallbackClass {
  background-color: aquamarine;
}
</style>
