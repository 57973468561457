/*
 * @Author: ZhengJianYang
 * @Date: 2023-05-29 17:08:40
 * @LastEditors: ZhengJianYang
 * @LastEditTime: 2023-06-06 18:25:10
 * @Description:
 */
import axios from 'axios'

const service = axios.create({
  // baseURL: 'https://cems.fuhuan.ltd/api',    //旧版
  baseURL: 'https://cems.fuhuan.ltd/api', //新版
  // timeout: 100000,
})
const source = axios.CancelToken.source()
// 请求拦截器 requestInterceptor
service.interceptors.request.use(
  (config) => {
    config.cancelToken = source.token
    const access_token = localStorage.getItem('access_token')
    config.headers['Authorization'] = `Bearer ${access_token}`
    // if (config.data) {
    //   const encryptedData = cryptoUtils.encrypt(config.data);
    //   config.data = { data: encryptedData };
    // }
    return config
  },
  (error) => {
    console.log('=========请求报错=========')
    Promise.reject(error)
    console.log('=========请求报错=========')
    return
  }
)
// 响应拦截器 responseInterceptor
service.interceptors.response.use(
  (response) => {
    // const res = response.data;
    //  return cryptoUtils.decrypt(response.data);
    // console.log('11response :>> ', response);
    return response.data
  },
  (error) => {
    const res = error.response
    // if (res.status === 401 && res.statusText === 'Unauthorized') {
    // }
    console.log('=========响应报错=========')
    Promise.reject(error)
    console.log('=========响应报错=========')
    return
  }
)
const http = {
  post(url, data, options) {
    if (options) {
      return service({
        method: 'post',
        url,
        data,
        ...options,
      })
    } else {
      return service({
        method: 'post',
        url,
        data,
      })
    }
  },
  put(url, data) {
    return service({
      method: 'put',
      url,
      data,
    })
  },
  delete(url, data) {
    return service({
      method: 'delete',
      url,
      data,
    })
  },
}

export default http
