<template>
  <!-- 新建问卷 -->
  <div id="EditQuestionnaire999888">
    <div class="question_set">
      <div class="set_created">
        {{ mockArr?.naire_title ? mockArr?.naire_title : '问卷标题' }}
      </div>
      <!-- <div class="set_created">编辑</div>
        <div class="set_d">
          <el-icon>
            <ArrowRightBold />
          </el-icon>
        </div>
        <div class="set_share">分享</div> -->
      <!-- <qrcode-vue value="http://www.fuhuan.ltd/vplus-system/#/faceAnalyze?id=123456"  ></qrcode-vue> -->
      <img
        class="set_shareBut"
        @click="shareQuestionChange"
        src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%20161%402x.png"
        alt="" />
      <!-- <img
        v-show="!isH5Preview"
        @click="goToH5Preview"
        class="set_pre"
        src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%20170%402x.png"
        alt=""
      /> -->
      <img
        v-show="!isH5Preview"
        @click="closeH5Preview"
        class="set_pre"
        src="https://newstore.vynior.com/cems_web/%E5%85%B3%E9%97%AD%E9%A2%84%E8%A7%88222.png"
        alt="" />
      <!-- <button @click="getQuesChange">保存</button> -->
    </div>

    <!-- 下 -->
    <div v-if="!isWebQuestionShow" class="previewQuestion">
      <div class="previewQuestion_top">
        <img
          class="imgleft"
          src="https://newstore.vynior.com/cems_web/%E9%97%AE%E5%8D%B7%E9%A2%84%E8%A7%88%E2%80%94%E2%80%94%E2%80%94%E2%80%94/%E7%BB%84%20110428%402x.png"
          alt="" />

        <img
          @click="goToWebPreviewQuestion"
          class="imgright"
          src="https://newstore.vynior.com/cems_web/%E9%97%AE%E5%8D%B7%E9%A2%84%E8%A7%88%E2%80%94%E2%80%94%E2%80%94%E2%80%94/%E7%BB%84%20111242%402x.png"
          alt="" />
      </div>

      <div class="previewQuestion_center">
        <div class="previewQuestion_center_phone">
          <div class="centerBar"></div>

          <div class="previewQuestion_center_phone_main">
            <div class="headerTitle">{{ mockArr?.naire_title }}</div>

            <div class="questionSaying" v-if="mockArr.naire_explain != ''">
              {{ mockArr?.naire_explain }}
            </div>

            <div v-for="(question, index) in mockArr.Questions" :key="index">
              <div class="singleChoice" v-if="question.type == '单选'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum">
                    <span v-if="question.required" style="color: red">*</span>
                    {{ index + 1 }}</dis
                  >
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>
                    {{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, aindex) of question.label" :key="aindex">
                    <input class="radio" type="radio" name="item" :value="question.selectValue" />
                    <div class="radioContent">
                      <div>{{ item }}</div>
                      <img v-if="question.imgSrc[aindex]" class="select_img" :src="question.imgSrc[aindex]" alt="图片已缺失" />
                      <div v-if="question.imgText[aindex]" class="text_intro">
                        {{ question.imgText[aindex] }}
                      </div>
                    </div>
                  </div>

                  <div class="item2" v-if="question.otherLabel">
                    <div class="item2_inner">
                      <input @change="handleChange" class="radio1" type="radio" name="item" :value="question.selectValue" />
                      <div class="radioContent">其他</div>
                    </div>

                    <el-input v-if="isCheck" class="inputText" v-model="inputmock" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="multipleChoice" v-if="question.type == '多选'">
                <div class="singleChoice_top">
                  <div class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</div>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, bindex) of question.label" :key="bindex">
                    <span class="radio">
                      <input type="checkbox" id="radio1" name="item" :value="isCheck2" />
                      <!-- <label for="radio1"></label> -->
                      <div class="radioContent">
                        <div>{{ item }}</div>
                        <img v-if="question.imgSrc[bindex]" class="select_img" :src="question.imgSrc[bindex]" alt="图片已缺失" />
                        <div v-if="question.imgText[bindex]" class="text_intro">
                          {{ question.imgText[bindex] }}
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="item2" v-if="question.otherLabel">
                    <div class="item2_inner">
                      <input @change="handleChange2" v-model="isCheck2" class="radio1" type="checkbox" name="item" :value="isCheck2" />
                      <div class="radioContent">其他</div>
                    </div>

                    <el-input v-if="isCheck2" class="inputText" v-model="inputmock2" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="singleChoice" v-if="question.type == '评分单选'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum">
                    <span v-if="question.required" style="color: red">*</span>
                    {{ index + 1 }}</dis
                  >
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>
                    {{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, aindex) of question.label" :key="aindex">
                    <input class="radio" type="radio" name="item" :value="question.selectValue" />
                    <div class="radioContent">
                      <div>{{ item }}</div>
                      <img v-if="question.imgSrc[aindex]" class="select_img" :src="question.imgSrc[aindex]" alt="图片已缺失" />
                      <div v-if="question.imgText[aindex]" class="text_intro">
                        {{ question.imgText[aindex] }}
                      </div>
                    </div>
                  </div>

                  <div class="item2" v-if="question.otherLabel">
                    <div class="item2_inner">
                      <input @change="handleChange" class="radio1" type="radio" name="item" :value="question.selectValue" />
                      <div class="radioContent">其他</div>
                    </div>

                    <el-input v-if="isCheck" class="inputText" v-model="inputmock" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="multipleChoice" v-if="question.type == '评分多选'">
                <div class="singleChoice_top">
                  <div class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</div>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, bindex) of question.label" :key="bindex">
                    <span class="radio">
                      <input type="checkbox" id="radio1" name="item" :value="isCheck2" />
                      <!-- <label for="radio1"></label> -->
                      <div class="radioContent">
                        <div>{{ item }}</div>
                        <img v-if="question.imgSrc[bindex]" class="select_img" :src="question.imgSrc[bindex]" alt="图片已缺失" />
                        <div v-if="question.imgText[bindex]" class="text_intro">
                          {{ question.imgText[bindex] }}
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="item2" v-if="question.otherLabel">
                    <div class="item2_inner">
                      <input @change="handleChange2" v-model="isCheck2" class="radio1" type="checkbox" name="item" :value="isCheck2" />
                      <div class="radioContent">其他</div>
                    </div>

                    <el-input v-if="isCheck2" class="inputText" v-model="inputmock2" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="fill_blank" v-if="question.type == '单项填空'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item">
                    <el-input v-model="inputvvv" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="matrixRadioSelection" v-if="question.type == '矩阵单选'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="scoreBar">
                    <div class="scoreBar_item" v-for="(item, dindex) of question.valueTitle" :key="dindex">
                      {{ item }}
                    </div>
                    <!-- <div class="scoreBar_item">不满意</div>
                  <div class="scoreBar_item">一般</div>
                  <div class="scoreBar_item">满意</div>
                  <div class="scoreBar_item">很满意</div> -->
                  </div>

                  <div class="whichSize" v-for="(item, findex) of question.labelTitle" :key="findex">
                    <div class="size">{{ item }}</div>
                    <div class="scoreBar">
                      <div class="scoreBar_item" v-for="(item2, eindex) in question.valueTitle" :key="eindex">
                        <input class="radio" type="radio" :name="item" :value="item" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="matrixMultipleSelection" v-if="question.type == '矩阵多选'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="scoreBar">
                    <div class="scoreBar_item" v-for="(item, gindex) of question.valueTitle" :key="gindex">
                      {{ item }}
                    </div>
                    <!-- <div class="scoreBar_item">不满意</div>
                  <div class="scoreBar_item">一般</div>
                  <div class="scoreBar_item">满意</div>
                  <div class="scoreBar_item">很满意</div> -->
                  </div>

                  <div class="whichSize" v-for="(item, hindex) of question.labelTitle" :key="hindex">
                    <div class="size">{{ item }}</div>
                    <div class="scoreBar">
                      <div class="scoreBar_item" v-for="(item, iindex) of question.valueTitle" :key="iindex">
                        <input class="radio" type="checkbox" :name="item" :value="item" />
                      </div>
                      <!-- <div class="scoreBar_item">
                      <input
                        class="radio"
                        type="checkbox"
                        name="val1"
                        :value="val1"
                      />
                    </div>
                    <div class="scoreBar_item">
                      <input
                        class="radio"
                        type="checkbox"
                        name="val1"
                        :value="val1"
                      />
                    </div>
                    <div class="scoreBar_item">
                      <input
                        class="radio"
                        type="checkbox"
                        name="val1"
                        :value="val1"
                      />
                    </div>
                    <div class="scoreBar_item">
                      <input
                        class="radio"
                        type="checkbox"
                        name="val1"
                        :value="val1"
                      />
                    </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="dropdownSelection" v-if="question.type == '下拉'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <el-select v-model="dropdownvalue" class="m-2" placeholder="请选择">
                    <el-option v-for="(item, jindex) of question.label" :key="jindex" :label="item" :value="item" />
                  </el-select>
                </div>
              </div>

              <div class="matrixRating" v-if="question.type == '矩阵量表'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="scoreBar">
                    <div class="scoreBar_item" v-for="(item, kindex) of question.valueTitle3" :key="kindex">
                      {{ item }}
                    </div>
                    <!-- <div class="scoreBar_item">不满意</div> -->
                    <!-- <div class="scoreBar_item">一般</div> -->
                    <!-- <div class="scoreBar_item">满意</div> -->
                    <!-- <div class="scoreBar_item">非常满意</div> -->
                  </div>

                  <div class="scoreRange">
                    <div class="scoreRange_item" :key="kkIndex" v-for="(kk, kkIndex) of question.valueTitle">
                      {{ kkIndex + question.startNum }}
                    </div>
                  </div>

                  <div class="whichSize" v-for="(item, lindex) of question.labelTitle" :key="lindex">
                    <div class="size">{{ item }}</div>
                    <div class="scoreBar">
                      <!-- question.echartsDimension -->
                      <div class="scoreBar_item" v-for="(item2, oindex) in question.valueTitle" :key="oindex">
                        <input class="radio" type="radio" :name="item" :value="item" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="Many_fill_blank" v-if="question.type == '多项填空'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, pindex) of question.label" :key="pindex">
                    <div class="titleName">{{ item }}</div>
                    <el-input
                      v-if="question.inputValue != null || question.inputValue != undefined"
                      v-model="question.inputValue[pindex]"
                      placeholder="请输入" />
                  </div>
                  <!-- <div class="item">
                  <div class="titleName">姓名</div>
                  <el-input v-model="inputvvv" placeholder="请输入" />
                </div> -->
                </div>
              </div>
            </div>

            <div class="submitBtn">
              <img
                class="submitImg"
                src="https://newstore.vynior.com/cems_web/%E9%97%AE%E5%8D%B7%E9%A2%84%E8%A7%88%E2%80%94%E2%80%94%E2%80%94%E2%80%94/submtb.png"
                alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 上 -->

    <!-- web -->
    <div class="webPreviewQuestion" v-if="isWebQuestionShow">
      <div class="previewQuestion_top">
        <img
          @click="closeWebPreview"
          class="imgleft"
          src="https://newstore.vynior.com/cems_web/%E9%97%AE%E5%8D%B7%E9%A2%84%E8%A7%88%E2%80%94%E2%80%94%E2%80%94%E2%80%94/%E6%89%8B%E6%9C%BA111111111.png"
          alt="" />

        <img
          class="imgright"
          src="https://newstore.vynior.com/cems_web/%E9%97%AE%E5%8D%B7%E9%A2%84%E8%A7%88%E2%80%94%E2%80%94%E2%80%94%E2%80%94/web%E9%A2%84%E8%A7%88111111111111.png"
          alt="" />
      </div>

      <div class="previewQuestion_center">
        <div class="previewQuestion_center_phone">
          <!-- <div class="centerBar"></div> -->

          <div class="previewQuestion_center_phone_main">
            <div class="headerTitle">{{ mockArr.naire_title }}</div>

            <div class="questionSaying" v-if="mockArr.naire_explain != ''">
              {{ mockArr.naire_explain }}
            </div>

            <div v-for="(question, index) in mockArr.Questions" :key="index">
              <div class="singleChoice" v-if="question.type == '单选'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum">
                    <span v-if="question.required" style="color: red">*</span>
                    {{ index + 1 }}</dis
                  >
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>
                    {{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, aindex) of question.label" :key="aindex">
                    <input class="radio" type="radio" name="item" :value="question.selectValue" />
                    <div class="radioContent">
                      <div>{{ item }}</div>
                      <img v-if="question.imgSrc[aindex]" class="select_img22" :src="question.imgSrc[aindex]" alt="图片已缺失" />
                      <div v-if="question.imgText[aindex]" class="text_intro">
                        {{ question.imgText[aindex] }}
                      </div>
                    </div>
                  </div>

                  <div class="item2" v-if="question.otherLabel">
                    <div class="item2_inner">
                      <input @change="handleChange" class="radio1" type="radio" name="item" :value="question.selectValue" />
                      <div class="radioContent">其他</div>
                    </div>

                    <el-input v-if="isCheck" class="inputText" v-model="inputmock" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="multipleChoice" v-if="question.type == '多选'">
                <div class="singleChoice_top">
                  <div class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</div>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, bindex) of question.label" :key="bindex">
                    <span class="radio">
                      <input type="checkbox" id="radio1" name="item" :value="isCheck2" />
                      <!-- <label for="radio1"></label> -->
                      <div class="radioContent">
                        <div>{{ item }}</div>
                        <img v-if="question.imgSrc[bindex]" class="select_img22" :src="question.imgSrc[bindex]" alt="图片已缺失" />
                        <div v-if="question.imgText[bindex]" class="text_intro">
                          {{ question.imgText[bindex] }}
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="item2" v-if="question.otherLabel">
                    <div class="item2_inner">
                      <input @change="handleChange2" v-model="isCheck2" class="radio1" type="checkbox" name="item" :value="isCheck2" />
                      <div class="radioContent">其他</div>
                    </div>

                    <el-input v-if="isCheck2" class="inputText" v-model="inputmock2" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="singleChoice" v-if="question.type == '评分单选'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum">
                    <span v-if="question.required" style="color: red">*</span>
                    {{ index + 1 }}</dis
                  >
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>
                    {{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, aindex) of question.label" :key="aindex">
                    <input class="radio" type="radio" name="item" :value="question.selectValue" />
                    <div class="radioContent">
                      <div>{{ item }}</div>
                      <img v-if="question.imgSrc[aindex]" class="select_img22" :src="question.imgSrc[aindex]" alt="图片已缺失" />
                      <div v-if="question.imgText[aindex]" class="text_intro">
                        {{ question.imgText[aindex] }}
                      </div>
                    </div>
                  </div>

                  <div class="item2" v-if="question.otherLabel">
                    <div class="item2_inner">
                      <input @change="handleChange" class="radio1" type="radio" name="item" :value="question.selectValue" />
                      <div class="radioContent">其他</div>
                    </div>

                    <el-input v-if="isCheck" class="inputText" v-model="inputmock" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="multipleChoice" v-if="question.type == '评分多选'">
                <div class="singleChoice_top">
                  <div class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</div>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, bindex) of question.label" :key="bindex">
                    <span class="radio">
                      <input type="checkbox" id="radio1" name="item" :value="isCheck2" />
                      <!-- <label for="radio1"></label> -->
                      <div class="radioContent">
                        <div>{{ item }}</div>
                        <img v-if="question.imgSrc[bindex]" class="select_img22" :src="question.imgSrc[bindex]" alt="图片已缺失" />
                        <div v-if="question.imgText[bindex]" class="text_intro">
                          {{ question.imgText[bindex] }}
                        </div>
                      </div>
                    </span>
                  </div>
                  <div class="item2" v-if="question.otherLabel">
                    <div class="item2_inner">
                      <input @change="handleChange2" v-model="isCheck2" class="radio1" type="checkbox" name="item" :value="isCheck2" />
                      <div class="radioContent">其他</div>
                    </div>

                    <el-input v-if="isCheck2" class="inputText" v-model="inputmock2" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="fill_blank" v-if="question.type == '单项填空'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item">
                    <el-input v-model="inputvvv" placeholder="请输入" />
                  </div>
                </div>
              </div>

              <div class="matrixRadioSelection" v-if="question.type == '矩阵单选'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="scoreBar">
                    <div class="seatBox"></div>
                    <div class="scoreBar_item" v-for="(item, dindex) of question.valueTitle" :key="dindex">
                      {{ item }}
                    </div>
                    <!-- <div class="scoreBar_item">不满意</div>
                    <div class="scoreBar_item">一般</div>
                    <div class="scoreBar_item">满意</div>
                    <div class="scoreBar_item">很满意</div> -->
                  </div>

                  <div class="barLine"></div>

                  <div class="whichSize" v-for="(item, findex) of question.labelTitle" :key="findex">
                    <div class="size">{{ item }}</div>
                    <div class="scoreBar">
                      <div class="scoreBar_item" v-for="(item2, eindex) in question.valueTitle" :key="eindex">
                        <input class="radio" type="radio" :name="item" :value="item" />
                      </div>
                    </div>
                  </div>

                  <!-- <div class="barLine"></div> -->
                </div>
              </div>

              <div class="matrixMultipleSelection" v-if="question.type == '矩阵多选'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="scoreBar">
                    <div class="seatBox"></div>

                    <div class="scoreBar_item" v-for="(item, gindex) of question.valueTitle" :key="gindex">
                      {{ item }}
                    </div>
                    <!-- <div class="scoreBar_item">不满意</div>
                    <div class="scoreBar_item">一般</div>
                    <div class="scoreBar_item">满意</div>
                    <div class="scoreBar_item">很满意</div> -->
                  </div>

                  <div class="barLine"></div>

                  <div class="whichSize" v-for="(item, hindex) of question.labelTitle" :key="hindex">
                    <div class="size">{{ item }}</div>
                    <div class="scoreBar">
                      <div class="scoreBar_item" v-for="(item, iindex) of question.valueTitle" :key="iindex">
                        <input class="radio" type="checkbox" :name="item" :value="item" />
                      </div>
                      <!-- <div class="scoreBar_item">
                        <input
                          class="radio"
                          type="checkbox"
                          name="val1"
                          :value="val1"
                        />
                      </div>
                      <div class="scoreBar_item">
                        <input
                          class="radio"
                          type="checkbox"
                          name="val1"
                          :value="val1"
                        />
                      </div>
                      <div class="scoreBar_item">
                        <input
                          class="radio"
                          type="checkbox"
                          name="val1"
                          :value="val1"
                        />
                      </div>
                      <div class="scoreBar_item">
                        <input
                          class="radio"
                          type="checkbox"
                          name="val1"
                          :value="val1"
                        />
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="dropdownSelection" v-if="question.type == '下拉'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <el-select v-model="dropdownvalue" class="m-2" placeholder="请选择">
                    <el-option v-for="(item, jindex) of question.label" :key="jindex" :label="item" :value="item" />
                  </el-select>
                </div>
              </div>

              <div class="matrixRating" v-if="question.type == '矩阵量表'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="scoreBar">
                    <div class="seatBox"></div>

                    <div class="score_in">
                      <div class="scoreBar_item" v-for="(item, kindex) of question.valueTitle3" :key="kindex">
                        {{ item }}
                      </div>
                    </div>

                    <!-- <div class="scoreBar_item">不满意</div> -->
                    <!-- <div class="scoreBar_item">一般</div> -->
                    <!-- <div class="scoreBar_item">满意</div> -->
                    <!-- <div class="scoreBar_item">非常满意</div> -->
                  </div>

                  <div class="scoreRange">
                    <div class="seatBox"></div>

                    <div class="scoreRange_item" :key="kkIndex" v-for="(kk, kkIndex) of question.valueTitle">
                      {{ kkIndex + question.startNum }}
                    </div>
                  </div>

                  <div class="whichSize" v-for="(item, lindex) of question.labelTitle" :key="lindex">
                    <div class="size">{{ item }}</div>
                    <div class="scoreBar">
                      <!-- question.echartsDimension -->
                      <div class="scoreBar_item" v-for="(item2, oindex) in question.valueTitle" :key="oindex">
                        <input class="radio" type="radio" :name="item" :value="item" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="Many_fill_blank" v-if="question.type == '多项填空'">
                <div class="singleChoice_top">
                  <dis class="num" v-if="mockArr.question_showNum"><span v-if="question.required" style="color: red">*</span>{{ index + 1 }}</dis>
                  <div class="title">
                    <span style="color: red" v-if="question.required == true && mockArr.question_showNum == false">*</span>{{ question.title }}
                  </div>
                </div>

                <div class="content">
                  <div class="item" v-for="(item, pindex) of question.label" :key="pindex">
                    <div class="titleName">{{ item }}</div>
                    <el-input
                      v-if="question.inputValue != null || question.inputValue != undefined"
                      v-model="question.inputValue[pindex]"
                      placeholder="请输入" />
                  </div>
                  <!-- <div class="item">
                    <div class="titleName">姓名</div>
                    <el-input v-model="inputvvv" placeholder="请输入" />
                  </div> -->
                </div>
              </div>
            </div>

            <div class="submitBtn">
              <img
                class="submitImg"
                src="https://newstore.vynior.com/cems_web/%E9%97%AE%E5%8D%B7%E9%A2%84%E8%A7%88%E2%80%94%E2%80%94%E2%80%94%E2%80%94/submtb.png"
                alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- web -->

    <div id="ques_dialog" v-if="quesDialog">
      <el-dialog v-model="quesDialog" :close-on-click-modal="false" :align-center="true" :show-close="false">
        <template #header>
          <div class="title">
            确认发布
            <span class="col">{{ demoData.questionTitleVal }}</span> 吗？
          </div>
        </template>
        <template #default>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">发布后可在问卷列表查看详情</div>
          </div>
          <div class="Derived_but">
            <el-button class="cancel" @click="quesDialog = false">取消</el-button>
            <el-button class="sure" type="primary" @click="getQuesChange"> 确认 </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    <!-- <el-dialog
        class="ques_dialog"
        v-model="quesDialog"
        title="分享"
        :before-close="handleClose"
      >
        <div class="dialog_box">
          <div class="box_src"><span class="src_col">《问卷调研用户使用习惯调查》</span>答题链接</div>
          <div class="box_lj">
            <div class="lj_fz">{{ questionSrc }}</div>
            <div class="lj_but" @click="copyChange">复制链接</div>
          </div>
          <div class="box_code">
            <span class="code_text">《问卷调研用户使用习惯调查》</span>
            答题二维码
          </div>
          <div class="box_qrcode">
            <qrcode-vue :value="questionSrc" class="qrcode" ref="qrcodeRef" ></qrcode-vue>
            <div class="qrcode_xz" @click="uploadImgChange">下载</div>
          </div>
        </div>
      </el-dialog> -->
  </div>
</template>
<script setup>
import previewQuestion from '@/components/QuestionnaireManagement/previewQuestion'
import { Select, CloseBold, Upload, ArrowRightBold } from '@element-plus/icons-vue'
// import { DomEditor } from '@wangeditor/editor'
import { ElMessage } from 'element-plus'
import { ArrowDown } from '@element-plus/icons-vue'
import QrcodeVue from 'qrcode.vue'
import clipboard3 from 'vue-clipboard3'
import { ref, onMounted, nextTick, computed, watch, reactive, shallowRef, onBeforeUnmount, provide } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { message } from '@/utils/resetMessage'
import draggable from 'vuedraggable'
import http from '@/utils/http'
import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
const store = useStore()
const router = useRouter()
const route = useRoute()
//   此问卷的详情
const oldQuestion = ref()
//   根据id获取问卷数据
onMounted(async () => {
  console.log('onMounted中的route.params._id', route.params._id)
  // questionId.value = route.params._id;
  await getAllQuestionsInfo()
  // const res = await http.post("/questionnaire/findInfo-questionnaire", {
  //   questionnaireId: route.params._id,
  // });
  // console.log(res, "获取的问卷详情");
  // oldQuestion.value = res.naire;
  // demoData.questionTitleVal = res?.naire?.naire_title;
  // demoData.questionTitleExplainVal = res?.naire?.naire_explain;
  // demoData.showNumberSwitch = res?.naire?.question_showNum;
  // demoData.showSthankCheck = res?.naire?.show_thank;
  // res?.naire?.Questions?.forEach((item, index) => {
  //   if (item.type == "单选") {
  //     let obj = JSON.parse(JSON.stringify(radioQues.value));
  //     obj.label = item.label;
  //     obj.required = item.required;
  //     obj.title = item.title;
  //     item = JSON.parse(JSON.stringify(obj));
  //     res.naire.Questions[index] = item;
  //   } else if (item.type == "多选") {
  //     let obj = JSON.parse(JSON.stringify(checkQues.value));
  //     obj.label = item.label;
  //     obj.required = item.required;
  //     obj.title = item.title;
  //     item = JSON.parse(JSON.stringify(obj));
  //     res.naire.Questions[index] = item;
  //   } else if (item.type == "下拉") {
  //     let obj = JSON.parse(JSON.stringify(selectQues.value));
  //     obj.label = item.label;
  //     obj.required = item.required;
  //     obj.title = item.title;
  //     item = JSON.parse(JSON.stringify(obj));
  //     res.naire.Questions[index] = item;
  //   } else if (item.type == "单项填空") {
  //     let obj = JSON.parse(JSON.stringify(radioInpQues.value));
  //     obj.required = item.required;
  //     obj.title = item.title;
  //     item = JSON.parse(JSON.stringify(obj));
  //     res.naire.Questions[index] = item;
  //   } else if (item.type == "多项填空") {
  //     let obj = JSON.parse(JSON.stringify(checkInpQues.value));
  //     obj.required = item.required;
  //     obj.label = item.label;
  //     obj.title = item.title;
  //     item = JSON.parse(JSON.stringify(obj));
  //     res.naire.Questions[index] = item;
  //   }
  // });
  // // console.log(res.naire.Questions);
  // questionArr.value = res?.naire?.Questions;
})
//   点击分享问卷
const shareQuestionChange = () => {
  // console.log(oldQuestion.value);
  // if (oldQuestion.value.status != "收集中") {
  // quesDialog.value = true;
  // } else {
  router.push({
    name: 'ShareQuestion',
    params: {
      // _id: oldQuestion.value._id,
      _id: route.params._id,
    },
  })
  // }
  //
}

// 获取问卷信息
const getAllQuestionsInfo = async () => {
  console.log('321', route)

  await http
    .post('/questionnaire/findInfo-questionnaire', {
      // questionnaireId: "6492538990148f6dfd6cae64",
      questionnaireId: route.params._id,
    })

    .then((res) => {
      console.log('问卷res', res)
      // console.log('问卷所有问题', res.naire.Questions);
      if (res.naire == null) return
      mockArr.value = res.naire

      mockArr.value.Questions.forEach((item) => {
        if (item.type == '矩阵量表') {
          let valueTitle3 = []
          if (item.echartsDimension == 0) {
            valueTitle3 = ['非常不满意', '非常满意']
          } else if (item.echartsDimension == 1) {
            valueTitle3 = ['非常不赞同', '非常赞同']
          } else if (item.echartsDimension == 2) {
            valueTitle3 = ['非常不重要', '非常重要']
          } else if (item.echartsDimension == 3) {
            valueTitle3 = ['非常不愿意', '非常愿意']
          } else if (item.echartsDimension == 4) {
            valueTitle3 = ['非常不符合', '非常符合']
          } else if (item.echartsDimension == 5) {
            valueTitle3 = ['毫无感觉', '十分强烈']
          }

          item.valueTitle3 = valueTitle3
        } else if (item.type == '多项填空') {
          item.inputValue = []
        }
      })

      console.log('mockArr修改后', mockArr)
    })
    .catch((err) => {
      console.log('问卷err', err)
    })
}

const mockArr = ref(
  {}

  // {
  //   _id: '648abde98403e92196a6cb7d',
  //   naire_title: '2023年第一季度产品测评调查 问卷',
  //   naire_explain: '感谢您能抽出几分钟时间来参加本次答题，现在我们就马上开始吧！',
  //   question_showNum: true,
  //   show_thank: true,
  //   thank_info: '真谢谢你',
  //   Questions: [
  //     {
  //       title: '单选标题',
  //       type: '单选',
  //       label: ['选项1', '选项2', '选项3', '选项4'],
  //       imgSrc: ['123'],
  //       labelTitle: [],
  //       valueTitle: [],
  //       imgText: ['这是个图片'],
  //       otherLabel: true,
  //       required: false,
  //       _id: '648abde98403e92196a6cb7e',
  //       selectValue: '',
  //     },
  //     {
  //       title: '多选标题',
  //       type: '多选',
  //       label: ['选项1', '选项2', '选项3', '选项4'],
  //       imgSrc: ['https://newstore.vynior.com/%2Bwis%2B%E6%AF%9B%E5%AD%94%E7%BB%86%E8%87%B4%E9%9D%A2%E8%86%9C.jpg'],
  //       labelTitle: [],
  //       valueTitle: [],
  //       imgText: ['这是个图片'],
  //       otherLabel: true,
  //       required: true,
  //       maxOption: 2,
  //       minOption: 1,
  //       _id: '648abde98403e92196a6cb7f',
  //     },
  //     {
  //       title: '下拉标题',
  //       type: '下拉',
  //       label: ['选项1', '选项2', '选项3', '选项4'],
  //       imgSrc: [],
  //       labelTitle: [],
  //       valueTitle: [],
  //       imgText: [],
  //       required: false,
  //       _id: '648abde98403e92196a6cb80',
  //       selectValue: '',
  //       isShow: false,
  //     },
  //     {
  //       title: '单项填空的标题',
  //       type: '单项填空',
  //       label: [],
  //       imgSrc: [],
  //       labelTitle: [],
  //       valueTitle: [],
  //       imgText: [],
  //       required: false,
  //       minNum: 1,
  //       maxNum: 6,
  //       formatLimits: '不限',
  //       _id: '648abde98403e92196a6cb81',
  //       inputValue: '',
  //     },
  //     {
  //       title: '多项填空的标题',
  //       type: '多项填空',
  //       label: ['label标题1', 'label标题2'],
  //       imgSrc: [],
  //       labelTitle: [],
  //       valueTitle: [],
  //       imgText: [],
  //       required: false,
  //       minNum: 1,
  //       maxNum: 6,
  //       formatLimits: '不限',
  //       _id: '648abde98403e92196a6cb82',
  //       inputValue: [],
  //     },
  //     {
  //       title: '矩阵单选的标题',
  //       type: '矩阵单选',
  //       label: [],
  //       imgSrc: [],
  //       labelTitle: ['选项标题1', '选项标题2'],
  //       valueTitle: ['很不满意', '不满意'],
  //       imgText: [],
  //       required: false,
  //       _id: '648abde98403e92196a6cb83',
  //       selectValue: '',
  //     },
  //     {
  //       title: '矩阵多选的标题',
  //       type: '矩阵多选',
  //       label: [],
  //       imgSrc: [],
  //       labelTitle: ['选项标题1', '选项标题2'],
  //       valueTitle: ['很不满意', '不满意'],
  //       imgText: [],
  //       required: false,
  //       _id: '648abde98403e92196a6cb84',
  //       selectValue: '',
  //     },
  //     {
  //       title: '矩阵量表的标题',
  //       type: '矩阵量表',
  //       numLabel: '满意度',
  //       echartsDimension: 5,
  //       startnum: 1,
  //       label: [],
  //       imgSrc: [],
  //       labelTitle: ['选项标题1', '选项标题2'],
  //       valueTitle: ['很不满意', '不满意'],
  //       imgText: [],
  //       required: false,
  //       _id: '648abde98403e92196a6cb85',
  //       selectValue: '',
  //     },
  //   ],
  // }
)
// 二维码的ref
const qrcodeRef = ref(null)
// 点击下载
const uploadImgChange = async () => {
  await nextTick() // 确保二维码已经被正确渲染到页面上

  const qrcode = qrcodeRef.value.$el
  const canvasDataUrl = await html2canvas(qrcode).then(function (renderedCanvas) {
    return renderedCanvas.toDataURL()
  })

  const link = document.createElement('a')
  link.href = canvasDataUrl
  link.download = '问卷二维码'
  link.click()
}
const { toClipboard } = clipboard3()
// 点击复制链接
const copyChange = async () => {
  try {
    await toClipboard(questionSrc.value)
    // alert("复制的text: ")
    console.log('复制成功')
    message({
      message: '复制成功',
      showClose: true,
      type: 'success',
    })
  } catch (error) {
    //    alert("复制失败!!")
    console.log('复制失败 ')
    message({
      message: '复制失败',
      showClose: true,
      type: 'error',
    })
  }
}
// 是否是新建完成跳转页面
const createdqueationval = ref(false)
// 控制分享问卷对话框显示隐藏
const quesDialog = ref(false)
// 分享问卷的链接
const questionSrc = ref('https://cems.fuhuan.ltd/questionnaire/#/')
// 点击保存上传问卷信息
const getQuesChange = async () => {
  const localId = localStorage.getItem('_id')
  let res = await http.post('/questionnaire/update-questionnaire', {
    _id: oldQuestion?.value?._id,
    naire_title: demoData?.questionTitleVal,
    naire_explain: demoData?.questionTitleExplainVal,
    show_thank: demoData.showSthankCheck,
    thank_info: demoData.inputThankVal,
    question_showNum: demoData.showNumberSwitch,
    //   createUser: localId,
    project: oldQuestion.value.project,
    Questions: questionArr?.value,
    frequency: demoData.deviceRestrictionsNum,
    control_equipment: demoData.deviceRestrictionsCheck,
    status: '收集中',
  })
  console.log(res, 'res')
  quesDialog.value = false
  if (res.status == 0) {
    createdqueationval.value = true
    message({
      showClose: true,
      message: '发布问卷成功',
      type: 'success',
    })
    router.push({
      name: 'ShareQuestion',
      params: {
        _id: res.questionnaire._id,
      },
    })
  } else {
    message({
      showClose: true,
      message: '发布问卷失败',
      type: 'error',
    })
  }
}
const inputEl = ref() //解决第一次获取焦点后无效
// 左侧选中的大纲，题库，题型
const syllabusIndex = ref(1)
// 点击大纲。题库。提醒，选中值
const syllabusChange = (val) => {
  syllabusIndex.value = val
}
const questionTypeArr = reactive({
  select: [
    {
      type: '单选',
      imgSrc: ['https://newstore.vynior.com/cems_web/radios.png', 'https://newstore.vynior.com/cems_web/radio.png'],
      id: 0,
    },
    {
      type: '多选',
      imgSrc: ['https://newstore.vynior.com/cems_web/checks.png', 'https://newstore.vynior.com/cems_web/check.png'],
      id: 1,
    },
    {
      type: '下拉',
      imgSrc: ['https://newstore.vynior.com/cems_web/selects.png', 'https://newstore.vynior.com/cems_web/select.png'],
      id: 2,
    },
  ],
  input: [
    {
      type: '单项填空',
      imgSrc: ['https://newstore.vynior.com/cems_web/radioinputs.png', 'https://newstore.vynior.com/cems_web/radioinput.png'],
      id: 3,
    },
    {
      type: '多项填空',
      imgSrc: ['https://newstore.vynior.com/cems_web/checkinputs.png', 'https://newstore.vynior.com/cems_web/checkinput.png'],
      id: 4,
    },
  ],
  mateix: [
    {
      type: '矩阵单选',
      imgSrc: ['https://newstore.vynior.com/cems_web/matrixradios.png', 'https://newstore.vynior.com/cems_web/matrixradio.png'],
      id: 5,
    },
    {
      type: '矩阵多选',
      imgSrc: ['https://newstore.vynior.com/cems_web/matrixchecks.png', 'https://newstore.vynior.com/cems_web/matrixcheck.png'],
      id: 6,
    },
    {
      type: '矩阵量表',
      imgSrc: ['https://newstore.vynior.com/cems_web/matrixecharts.png', 'https://newstore.vynior.com/cems_web/matrixechart.png'],
      id: 7,
    },
  ],
  groupA: {
    name: 'itxst',
    put: false, //允许拖入
    pull: 'clone',
  },
  groupB: {
    name: 'itxst',
    put: true, //允许拖入
    pull: true,
  },
  groupC: {
    name: 'itxsts',
    put: true, //允许拖入
    pull: true,
  },
  // 拖拽区域的数据
  draggable: [],
})
// 拖拽区域老数组
const draggableArr = ref([])
//拖拽开始的事件
const onStart = (e) => {
  console.log('开始拖拽', e)
  draggbleShow.value = true
}

//拖拽结束的事件
const onEnd = () => {
  console.log('结束拖拽')
  console.log(draggableArr.value)
  console.log(questionTypeArr.draggable)
  draggbleShow.value = false
  if (draggableArr.value.length == questionTypeArr.draggable.length) {
    console.log('题目数组未发生任何变化')
  } else {
    console.log('题目数组发送改变')
    let a = 0
    questionTypeArr.draggable.forEach((item, index) => {
      console.log(index, 'index')
      console.log(item, 'item')
      if (!draggableArr.value[index]) {
        console.log('老数组没值')
        if (item.type == '单选') {
          questionArr.value.splice(index, 0, JSON.parse(JSON.stringify(radioQues.value)))
          console.log('增加了单选')
          a = 1
        } else if (item.type == '多选') {
          questionArr.value.splice(index, 0, JSON.parse(JSON.stringify(checkQues.value)))
          console.log('增加了多选')
          a = 1
        } else if (item.type == '下拉') {
          questionArr.value.splice(index, 0, JSON.parse(JSON.stringify(selectQues.value)))
          console.log('增加了下拉')
          a = 1
        } else if (item.type == '单项填空') {
          questionArr.value.splice(index, 0, JSON.parse(JSON.stringify(radioInpQues.value)))
          console.log('增加了单项填空')
          a = 1
        } else if (item.type == '多项填空') {
          questionArr.value.splice(index, 0, JSON.parse(JSON.stringify(checkInpQues.value)))
          console.log('增加了多项填空')
          a = 1
        } else if (item.type == '矩阵单选') {
          questionArr.value.splice(index, 0, JSON.parse(JSON.stringify(mateixRadioQues.value)))
          console.log('增加了矩阵单项')
          a = 1
        } else if (item.type == '矩阵多选') {
          questionArr.value.splice(index, 0, JSON.parse(JSON.stringify(mateixCheckQues.value)))
          console.log('增加了矩阵多选')
          a = 1
        } else if (item.type == '矩阵量表') {
          questionArr.value.splice(index, 0, JSON.parse(JSON.stringify(mateixEchartQues.value)))
          console.log('增加了矩阵量表')
          a = 1
        }
      } else if ((item.type != draggableArr.value[index].type && a == 0) || draggableArr.value.length == 0) {
        console.log('增加的题目', a)
        console.log(draggableArr.value, item)
        if (item.type == '单选') {
          questionArr.value.splice(index, 0, radioQues.value)
          console.log('增加了单选')
          a = 1
        } else if (item.type == '多选') {
          questionArr.value.splice(index, 0, checkQues.value)
          console.log('增加了多选')
          a = 1
        } else if (item.type == '下拉') {
          questionArr.value.splice(index, 0, selectQues.value)
          console.log('增加了下拉')
          a = 1
        } else if (item.type == '单项填空') {
          questionArr.value.splice(index, 0, radioInpQues.value)
          console.log('增加了单项填空')
          a = 1
        } else if (item.type == '多项填空') {
          questionArr.value.splice(index, 0, checkInpQues.value)
          console.log('增加了多项填空')
          a = 1
        } else if (item.type == '矩阵单选') {
          questionArr.value.splice(index, 0, mateixRadioQues.value)
          console.log('增加了矩阵单项')
          a = 1
        } else if (item.type == '矩阵多选') {
          questionArr.value.splice(index, 0, mateixCheckQues.value)
          console.log('增加了矩阵多选')
          a = 1
        } else if (item.type == '矩阵量表') {
          questionArr.value.splice(index, 0, mateixEchartQues.value)
          console.log('增加了矩阵量表')
          a = 1
        }
        console.log(draggableArr.value)
      }
      if (a == 1) {
        draggableArr.value = JSON.parse(JSON.stringify(questionTypeArr.draggable))
        return
      }
    })
  }
  console.log('此时的数据结构为 :>> ', questionTypeArr.draggable)
}

let isWebQuestionShow = ref(false)
// 打开web预览
const goToWebPreviewQuestion = () => {
  console.log('1')
  isWebQuestionShow.value = true
}

const closeWebPreview = () => {
  // console.log("1");
  isWebQuestionShow.value = false
}

const onStarts = (e) => {
  console.log('开始拖拽', e)
  // draggbleShow.value=true
}
//拖拽结束的事件
const onEnds = () => {
  console.log('结束拖拽')
}
const onMove = (e, originalEvent) => {
  //不允许停靠
  if (e.relatedContext.element.disabledPark == true) return false

  return true
}
const onMoves = (e, originalEvent) => {
  //不允许停靠
  if (e.relatedContext.element.disabledPark == true) return false

  return true
}
// 控制拖拽结束区域显示隐藏
const draggbleShow = ref(false)

// 题型中的选项悬浮移入和移出和下标
let questionTypeMouse = ref(null)
const questionTypeMouseenterChange = (val) => {
  questionTypeMouse.value = val
  // console.log("鼠标移入", val);
}
// 点击问卷标题开始输入
const quesTitleChange = () => {
  console.log('点击问卷标题开始输入')
  demoData.questionTitleShow = true
  nextTick(() => {
    setTimeout(() => {
      console.log('进入')
      const $inputEl = inputEl
      console.log($inputEl, '$inputEl')
      if (Array.isArray($inputEl.value)) {
        $inputEl.value[$inputEl.value.length - 1].focus()
      } else {
        $inputEl.value.focus()
      }
    }, 100)
  })
}
// 问卷标题失去焦点
const blurQuestionTitleChange = () => {
  demoData.questionTitleShow = false
}
// 点击问卷说明开始输入
const questionTitleExplainChange = () => {
  demoData.questionTitleExplainShow = true
  nextTick(() => {
    setTimeout(() => {
      console.log('进入')
      const $inputEl = inputEl
      console.log($inputEl, '$inputEl')
      if (Array.isArray($inputEl.value)) {
        $inputEl.value[$inputEl.value.length - 1].focus()
      } else {
        $inputEl.value.focus()
      }
    }, 100)
  })
}
// 问卷说明失去焦点
const blurquestionTitleExplainChange = () => {
  demoData.questionTitleExplainShow = false
}
const quesSet = ref({ type: '', index: null, input: null })
const questionTypeMouseleaveChange = (val) => {
  questionTypeMouse.value = null
  // console.log("鼠标移出", val);
}
// 问卷设置和题目设置默认选中
const questionSetIndex = ref(0)
const questionSetChange = (val) => {
  console.log('点击 ')
  questionSetIndex.value = val
}
// 问卷展示的题目
const questionArr = ref([])
// 点击题目设置切换题型
const switchSelectChange = (val) => {
  console.log(val, 'val')
  if (quesSet.value.type != val) {
    console.log('改变题型')
    quesSet.value.type = val
    if (val == '单选') {
      questionArr.value[quesSet.value.index] = radioQues.value
      console.log(questionArr.value[quesSet.value.index], '改变为单选')
    } else if (val == '多选') {
      questionArr.value[quesSet.value.index] = checkQues.value
      console.log(questionArr.value[quesSet.value.index], '改变为多选')
    } else if (val == '下拉') {
      questionArr.value[quesSet.value.index] = selectQues.value
      console.log(questionArr.value[quesSet.value.index], '改变为下拉')
    } else if (val == '单项填空') {
      questionArr.value[quesSet.value.index] = radioInpQues.value
      console.log(questionArr.value[quesSet.value.index], '改变为单项填空')
    } else if (val == '多项填空') {
      questionArr.value[quesSet.value.index] = checkInpQues.value
      console.log(questionArr.value[quesSet.value.index], '改变为多项填空')
    } else if (val == '矩阵单选') {
      questionArr.value[quesSet.value.index] = mateixRadioQues.value
      console.log(questionArr.value[quesSet.value.index], '改变为矩阵单选')
    } else if (val == '矩阵多选') {
      questionArr.value[quesSet.value.index] = mateixCheckQues.value
      console.log(questionArr.value[quesSet.value.index], '改变为矩阵多选')
    } else if (val == '矩阵量表') {
      questionArr.value[quesSet.value.index] = mateixEchartQues.value
      console.log(questionArr.value[quesSet.value.index], '改变为矩阵量表')
    }
  }
}
// 点击切换是否此题必答
const showAnswerSwitchChange = (val) => {
  console.log(val, '点击切换是否必答')
  questionArr.value[quesSet.value.index].required = val
}
// 点击题型增加题目
const addQuestionChange = (val) => {
  console.log(val)
  if (val == '单选') {
    draggableArr.value.push({
      type: '单选',
      imgSrc: ['https://newstore.vynior.com/cems_web/radios.png', 'https://newstore.vynior.com/cems_web/radio.png'],
      id: 0,
    })
    questionTypeArr.draggable.push({
      type: '单选',
      imgSrc: ['https://newstore.vynior.com/cems_web/radios.png', 'https://newstore.vynior.com/cems_web/radio.png'],
      id: 0,
    })
    questionArr.value.push(JSON.parse(JSON.stringify(radioQues.value)))
  } else if (val == '多选') {
    draggableArr.value.push({
      type: '多选',
      imgSrc: ['https://newstore.vynior.com/cems_web/checks.png', 'https://newstore.vynior.com/cems_web/check.png'],
      id: 1,
    })
    questionTypeArr.draggable.push({
      type: '多选',
      imgSrc: ['https://newstore.vynior.com/cems_web/checks.png', 'https://newstore.vynior.com/cems_web/check.png'],
      id: 1,
    })
    questionArr.value.push(JSON.parse(JSON.stringify(checkQues.value)))
  } else if (val == '下拉') {
    draggableArr.value.push({
      type: '下拉',
      imgSrc: ['https://newstore.vynior.com/cems_web/selects.png', 'https://newstore.vynior.com/cems_web/select.png'],
      id: 2,
    })
    questionTypeArr.draggable.push({
      type: '下拉',
      imgSrc: ['https://newstore.vynior.com/cems_web/selects.png', 'https://newstore.vynior.com/cems_web/select.png'],
      id: 2,
    })
    questionArr.value.push(JSON.parse(JSON.stringify(selectQues.value)))
  } else if (val == '单项填空') {
    questionArr.value.push(JSON.parse(JSON.stringify(radioInpQues.value)))
    questionTypeArr.draggable.push({
      type: '单项填空',
      imgSrc: ['https://newstore.vynior.com/cems_web/radioinputs.png', 'https://newstore.vynior.com/cems_web/radioinput.png'],
      id: 3,
    })
    draggableArr.value.push({
      type: '单项填空',
      imgSrc: ['https://newstore.vynior.com/cems_web/radioinputs.png', 'https://newstore.vynior.com/cems_web/radioinput.png'],
      id: 3,
    })
  } else if (val == '多项填空') {
    questionArr.value.push(JSON.parse(JSON.stringify(checkInpQues.value)))
    questionTypeArr.draggable.push({
      type: '多项填空',
      imgSrc: ['https://newstore.vynior.com/cems_web/checkinputs.png', 'https://newstore.vynior.com/cems_web/checkinput.png'],
      id: 4,
    })
    draggableArr.value.push({
      type: '多项填空',
      imgSrc: ['https://newstore.vynior.com/cems_web/checkinputs.png', 'https://newstore.vynior.com/cems_web/checkinput.png'],
      id: 4,
    })
  } else if (val == '矩阵单选') {
    questionArr.value.push(JSON.parse(JSON.stringify(mateixRadioQues.value)))
    questionTypeArr.draggable.push({
      type: '矩阵单选',
      imgSrc: ['https://newstore.vynior.com/cems_web/matrixradios.png', 'https://newstore.vynior.com/cems_web/matrixradio.png'],
      id: 5,
    })
    draggableArr.value.push({
      type: '矩阵单选',
      imgSrc: ['https://newstore.vynior.com/cems_web/matrixradios.png', 'https://newstore.vynior.com/cems_web/matrixradio.png'],
      id: 5,
    })
  } else if (val == '矩阵多选') {
    questionArr.value.push(JSON.parse(JSON.stringify(mateixCheckQues.value)))
    questionTypeArr.draggable.push({
      type: '矩阵多选',
      imgSrc: ['https://newstore.vynior.com/cems_web/matrixchecks.png', 'https://newstore.vynior.com/cems_web/matrixcheck.png'],
      id: 6,
    })
    draggableArr.value.push({
      type: '矩阵多选',
      imgSrc: ['https://newstore.vynior.com/cems_web/matrixchecks.png', 'https://newstore.vynior.com/cems_web/matrixcheck.png'],
      id: 6,
    })
  } else if (val == '矩阵量表') {
    questionArr.value.push(JSON.parse(JSON.stringify(mateixEchartQues.value)))
    draggableArr.value.push({
      type: '矩阵量表',
      imgSrc: ['https://newstore.vynior.com/cems_web/matrixecharts.png', 'https://newstore.vynior.com/cems_web/matrixechart.png'],
      id: 7,
    })
    questionTypeArr.draggable.push({
      type: '矩阵量表',
      imgSrc: ['https://newstore.vynior.com/cems_web/matrixecharts.png', 'https://newstore.vynior.com/cems_web/matrixechart.png'],
      id: 7,
    })
  }
}
//点击题目标题
const uploadquesTitleChange = (val) => {
  console.log(val, '选中的值')
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  switchSelectVal.value = questionArr.value[val].type
  questionArr.value[val].uploadTitle = true
  console.log(questionArr.value[val].uploadTitle)
  console.log(questionArr.value)
  nextTick(() => {
    setTimeout(() => {
      console.log('进入')
      const $inputEl = inputEl
      console.log($inputEl, '$inputEl')
      if (Array.isArray($inputEl.value)) {
        $inputEl.value[$inputEl.value.length - 1].focus()
      } else {
        $inputEl.value.focus()
      }
    }, 100)
  })
}
// 点击添加说明
const addImgTextChange = (val, value) => {
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  switchSelectVal.value = questionArr.value[val].type
  questionArr.value[val].imgTextShow[value] = true
  nextTick(() => {
    setTimeout(() => {
      console.log('进入')
      const $inputEl = inputEl
      console.log($inputEl, '$inputEl')
      if (Array.isArray($inputEl.value)) {
        $inputEl.value[$inputEl.value.length - 1].focus()
      } else {
        $inputEl.value.focus()
      }
    }, 100)
  })
}
// 添加说明输入框失去焦点
const blurTextShowChange = (val, value) => {
  setTimeout(() => {
    questionArr.value[val].imgTextShow[value] = false
  }, 200)
}
// 点击删除图片说明
const delLabelImgChange = (val, value) => {
  console.log(val, value, '点击删除图片说明')
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  switchSelectVal.value = questionArr.value[val].type
  questionArr.value[val].imgTextShow.splice(value, 1)
  questionArr.value[val].imgText.splice(value, 1)
}
// 题目标题失去焦点
const blurQuesTitleChange = (val) => {
  questionArr.value[val].uploadTitle = false
}
// 点击题目向上移动
const upLabelChange = (val) => {
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  switchSelectVal.value = questionArr.value[val].type
  console.log('点击上移的下标' + val)

  if (val == 0) {
    return
  }
  let a = questionArr.value[val]
  let b = questionArr.value[val - 1]
  questionArr.value.splice(val, 1, b)
  questionArr.value.splice(val - 1, 1, a)
  let aa = draggableArr.value[val]
  let bb = draggableArr.value[val - 1]
  draggableArr.value.splice(val, 1, bb)
  draggableArr.value.splice(val - 1, 1, aa)
  let aaa = questionTypeArr.draggable[val]
  let bbb = questionTypeArr.draggable[val - 1]
  questionTypeArr.draggable.splice(val, 1, bbb)
  questionTypeArr.draggable.splice(val - 1, 1, aaa)
}
// 点击题目向下移动
const downLabelChange = (val) => {
  console.log('点击下移的下标' + val)
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  switchSelectVal.value = questionArr.value[val].type
  if (val == questionArr.value.length - 1) {
    return
  }
  let a = questionArr.value[val]
  let b = questionArr.value[val + 1]
  questionArr.value.splice(val, 1, b)
  questionArr.value.splice(val + 1, 1, a)
  let aa = draggableArr.value[val]
  let bb = draggableArr.value[val + 1]
  draggableArr.value.splice(val, 1, bb)
  draggableArr.value.splice(val + 1, 1, aa)
  let aaa = questionTypeArr.draggable[val]
  let bbb = questionTypeArr.draggable[val + 1]
  questionTypeArr.draggable.splice(val, 1, bbb)
  questionTypeArr.draggable.splice(val + 1, 1, aaa)
}
// 点击删除题目
const deLabelChange = (val) => {
  questionArr.value.splice(val, 1)
  quesSet.value.type = ''
  quesSet.value.index = null
  questionTypeArr.draggable.splice(val, 1)
  draggableArr.value.splice(val, 1)
}
// 点击复制题目
const copytopicChange = (index, item) => {
  //   console.log(questionArr.value)
  //   console.log(item,index);
  questionArr.value.splice(index + 1, 0, item)
  // // questionArr.value[index]=questionArr.value[index]
  // console.log(questionArr.value);
  let arr1 = ['1']
  arr1.splice(0, 0, '2')
  console.log(arr1)
}
// 点击题目的选项显示输入框
const uploadLabelChange = (val, value) => {
  console.log(val, value)
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  quesSet.value.input = value
  switchSelectVal.value = questionArr.value[val].type
  if (questionArr.value[val].uploadLabel[value] == true) {
    return
  }
  questionArr.value[val].uploadLabel[value] = true
  nextTick(() => {
    setTimeout(() => {
      console.log('进入')
      const $inputEl = inputEl
      console.log($inputEl.value, '$inputEl')
      if (Array.isArray($inputEl.value)) {
        console.log('input数组')
        $inputEl.value[$inputEl.value.length - 1].focus()
      } else {
        console.log('input对象')
        $inputEl.value.focus()
        console.log($inputEl.value.focus)
      }
    }, 100)
  })
}
// 点击矩阵选项显示输入框
const uploadMatrixChange = (val, value) => {
  console.log()
  questionArr.value[val].uploadValue[value] = true
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  quesSet.value.input = value
  nextTick(() => {
    setTimeout(() => {
      console.log('进入')
      const $inputEl = inputEl
      console.log($inputEl, '$inputEl')
      if (Array.isArray($inputEl.value)) {
        $inputEl.value[$inputEl.value.length - 1].focus()
      } else {
        $inputEl.value.focus()
      }
    }, 100)
  })
}
// 题目失去焦点
const blurLabelChange = (val, value) => {
  console.log('失去焦点', val, value)
  setTimeout(() => {
    questionArr.value[val].uploadLabel[value] = false
  }, 200)
}
// 矩阵列的选项失去焦点
const blurMatrixChange = (val, value) => {
  setTimeout(() => {
    questionArr.value[val].uploadValue[value] = false
  }, 200)
}
// 点击添加选项
const addLabelChange = (val) => {
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  switchSelectVal.value = questionArr.value[val].type
  questionArr.value[val].label.push('')
  questionArr.value[val].uploadLabel.push(false)
  questionArr.value[val].imgSrc.push('')
  questionArr.value[val].imgText.push('')
  questionArrChange()
}
// 点击矩阵添加行
const addLabelTitleChange = (val) => {
  questionArr.value[val].labelTitle.push('')
  questionArr.value[val].uploadLabel.push(false)
}
// 点击矩阵选项
const addValueTitleChange = (val) => {
  if (questionArr.value[val].valueTitle.length == 5) {
    message({
      showClose: true,
      message: '添加行已上限',
      type: 'error',
    })
    return
  }
  questionArr.value[val].valueTitle.push('')
  questionArr.value[val].uploadValue.push(false)
}
// 下拉、多项填空点击添加选项
const addSelectLabelChange = (val) => {
  questionArr.value[val].label.push('')
  questionArr.value[val].uploadLabel.push(false)
}
// 点击添加其他选项
const addOtherLabelChange = (val) => {
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  switchSelectVal.value = questionArr.value[val].type
  questionArr.value[val].otherLabel = true
  questionArrChange()
}
// 点击删除选项
const delLabelChange = (val, value) => {
  console.log('点击删除选项')
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  switchSelectVal.value = questionArr.value[val].type
  if (value == '其他') {
    questionArr.value[val].otherLabel = false
    questionArrChange()
    return
  }
  console.log(questionArr.value[val].type, 'questionArr.value[val].type')
  if (questionArr.value[val].type == '矩阵单选' || questionArr.value[val].type == '矩阵多选' || questionArr.value[val].type == '矩阵量表') {
    questionArr.value[val].labelTitle.splice(value, 1)
    questionArr.value[val].uploadLabel.splice(value, 1)

    return
  }
  questionArr.value[val].label.splice(value, 1)
  questionArr.value[val].uploadLabel.splice(value, 1)
  questionArrChange()
}
// 矩阵点击删除选项
const delValueChange = (val, value) => {
  console.log('点击删除', val, value)
  questionArr.value[val].valueTitle.splice(value, 1)
  console.log('123')
  questionArr.value[val].uploadValue.splice(value, 1)
}
// 移入上传图片的位置
const imgMouseenterChange = (val, value) => {
  questionArr.value[val].imgHover[value] = true
}
const imgMouseleave = (val, value) => {
  questionArr.value[val].imgHover[value] = false
}
// 点击删除上传的图片
const delImgChange = (val, value) => {
  quesSet.value.type = questionArr.value[val].type
  quesSet.value.index = val
  switchSelectVal.value = questionArr.value[val].type
  questionArr.value[val].imgSrc[value] = ''
  console.log(questionArr.value)
}
// 上传附件事件
const uploadsFile = async (params) => {
  console.log(params)
  const file = params.file
  const url = window.URL.createObjectURL(file)
  const img = new Image()
  img.onload = function () {
    console.log(this.naturalWidth)
    console.log(this.naturalHeight)
  }
  img.src = url
  const formData = new FormData()
  formData.append('file', file)
  // formData.append('type', 'attachment');
  const response = await http.post('/upload/questionnaie-upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  console.log(response, 'response')
  // const { status, file: fileData } = response;
  if (response.status === 0) {
    // attachmentList.value.push(fileData);
    questionArr.value[quesSet.value.index].imgSrc[quesSet.value.input] = response.filePath
    console.log(questionArr.value[quesSet.value.index])
    ElMessage({
      message: '图片上传成功',
      // showClose: true,
      showClose: true,
      type: 'success',
    })
  } else {
    ElMessage({
      message: '图片上传失败，请稍后再试',
      // showClose: true,
      showClose: true,
      type: 'warning',
    })
  }
}
//页面内容
let demoData = reactive({
  questionTitleVal: '这是一个标题',
  questionTitleShow: false,
  questionTitleExplainVal: '',
  questionTitleExplainShow: '',
  showNumberSwitch: true,
  showSthankCheck: true,
  deviceRestrictionsCheck: false,
  deviceRestrictionsNum: null,
  weChatCheck: false,
  inputThankVal: '',
})
// 题型
const switchSelectVal = ref('')
// 切换题目的全部选项数组
const switchSelectArr = ref([
  { label: '单选', value: 0 },
  { label: '多选', value: 1 },
  { label: '下拉', value: 2 },
  { label: '单项填空', value: 3 },
  { label: '多项填空', value: 4 },
  { label: '矩阵单选', value: 5 },
  { label: '矩阵多选', value: 6 },
  { label: '矩阵量表', value: 7 },
])
// 格式限制的全部可选数组
const switchFormatLimitsArr = ref([
  { label: '不限', value: 0 },
  { label: '文本', value: 1 },
  { label: '数字', value: 2 },
  { label: '字母', value: 3 },
  { label: '中文', value: 4 },
  { label: '邮箱', value: 5 },
  { label: '手机号', value: 6 },
  { label: '网址', value: 7 },
  { label: '身份证', value: 8 },
])
// 选择可选范围最少数组
const switchMinCheckArr = ref([{ label: '不限', value: 0 }])
// 监听选中的题目有没有变化
watch(
  () => quesSet.value.type,
  () => {
    console.log('题型改变')
    questionArrChange()
  }
)
// 点击添加选项和选中题目变化时重新赋值可选范围可选项数组
const questionArrChange = () => {
  if (quesSet.value.type == '多选') {
    switchMinCheckArr.value = [{ label: '不限', value: 0 }]
    questionArr.value[quesSet.value.index].label.forEach((item, index) => {
      // console.log(index);
      let obj = {}
      obj.label = index + 1 + '项'
      obj.value = index + 1
      switchMinCheckArr.value.push(obj)
    })
    // console.log(switchMinCheckArr.value);
    if (questionArr.value[quesSet.value.index].otherLabel == true) {
      let obj = {}
      obj.label = switchMinCheckArr.value.length + '项'
      obj.value = switchMinCheckArr.value.length
      switchMinCheckArr.value.push(obj)
    }
    // console.log(switchMinCheckArr.value);
  }
}
// 选择可选范围最少触发事件
const switchMinCheckChange = (val) => {
  console.log(val)
  questionArr.value[quesSet.value.index].minOption = val
  if (questionArr.value[quesSet.value.index].maxOption != 0 && questionArr.value[quesSet.value.index].maxOption < val && val != 0) {
    questionArr.value[quesSet.value.index].minOption = ''
    console.log('最小值比最大值大')
    message({
      showClose: true,
      message: '可选范围最少值应小于可选范围最多值',
      type: 'error',
    })
  }
}
// 选择可选范围最多触发事件
const switchMaxCheckChange = (val) => {
  console.log(val)
  questionArr.value[quesSet.value.index].maxOption = val
  if (questionArr.value[quesSet.value.index].minOption != 0 && questionArr.value[quesSet.value.index].minOption > val && val != 0) {
    questionArr.value[quesSet.value.index].maxOption = ''
    console.log('最大值比最小值小')
    message({
      showClose: true,
      message: '可选范围最多值应大于可选范围最少值',
      type: 'error',
    })
  }
}
// 极值标签可选数组
const switchMaxOptionckArr = ref([
  { label: '满意度', value: 0, labelArr: ['非常不满意', '非常满意'] },
  { label: '认同感', value: 1, labelArr: ['非常不赞同', '非常赞同'] },
  { label: '重要度', value: 2, labelArr: ['非常不重要', '非常重要'] },
  { label: '意愿度', value: 3, labelArr: ['非常不愿意', '非常愿意'] },
  { label: '符合度', value: 4, labelArr: ['非常不符合', '非常符合'] },
  { label: '感知度', value: 5, labelArr: ['毫无感觉', '十分强烈'] },
])
// 极值标签选中事件
const switchMaxOptionckChange = (val) => {
  console.log('极值标签选中事件', val)
}
// 量表维度可选数组
const switchEchartsDimensionkArr = ref([
  { label: '2级', value: 0 },
  { label: '3级', value: 1 },
  { label: '4级', value: 2 },
  { label: '5级', value: 3 },
  { label: '6级', value: 4 },
  { label: '7级', value: 5 },
  { label: '8级', value: 6 },
  { label: '9级', value: 7 },
  { label: '10级', value: 8 },
])
// 量表维度选中事件
const switchEchartsDimensionkChange = (val) => {
  console.log('量表维度选中事件', val)
  let arr = []
  for (let i = 0; i <= val + 1; i++) {
    arr.push(i)
  }
  questionArr.value[quesSet.value.index].valueTitle = arr
}
// 此题必答控制
const showAnswerSwitch = ref(false)
// 显示序号控制
const showSerialSwitch = ref(true)
// 选项随机控制
const showRandomwitch = ref(false)
//   问卷标题
let questionTitleVal = ref('这是一个标题')
//编辑对话框
let settitleShow = ref(false)

// 定义的单选空题目
const radioQues = ref({
  type: '单选',
  title: '',
  label: ['', ''],
  uploadTitle: false,
  uploadLabel: [false, false],
  imgSrc: ['', ''],
  imgText: ['', ''],
  otherLabel: false,
  imgTextShow: [false, false],
  imgHover: [false, false],
  // required: false,
  required: true,

  id: 0,
})
// 定义的多选空题目
const checkQues = ref({
  type: '多选',
  title: '',
  label: ['', ''],
  uploadTitle: false,
  uploadLabel: [false, false],
  imgSrc: ['', ''],
  imgText: ['', ''],
  otherLabel: false,
  imgTextShow: [false, false],
  imgHover: [false, false],
  // required: false,
  required: true,

  minOption: 0,
  maxOption: 0,
  id: 1,
})
// 定义的下拉空题目
const selectQues = ref({
  type: '下拉',
  title: '',
  label: ['', ''],
  uploadTitle: false,
  uploadLabel: [false, false],
  otherLabel: false,
  // required: false,
  required: true,

  id: 2,
})
// 定义的单项填空空题目
const radioInpQues = ref({
  type: '单项填空',
  title: '',
  uploadTitle: false,
  // required: false,
  required: true,

  formatLimits: '不限',
  minNum: null,
  maxNum: null,
  id: 3,
})
// 定义的多项填空空题目
const checkInpQues = ref({
  type: '多项填空',
  title: '',
  uploadTitle: false,
  label: ['', ''],
  uploadLabel: [false, false],
  formatLimits: '不限',
  // required: false,
  required: true,

  minNum: null,
  maxNum: null,
  id: 4,
})
// 定义矩阵单项空题目
const mateixRadioQues = ref({
  type: '矩阵单选',
  title: '',
  labelTitle: ['', ''],
  valueTitle: ['很不满意', '不满意', '一般', '满意', '很满意'],
  uploadLabel: [false, false],
  uploadValue: [false, false, false, false, false],
  // required: false,
  required: true,

  showNum: false,
  id: 5,
})
// 定义矩阵多项空题目
const mateixCheckQues = ref({
  type: '矩阵多选',
  title: '',
  labelTitle: ['', ''],
  valueTitle: ['很不满意', '不满意', '一般', '满意', '很满意'],
  uploadLabel: [false, false],
  uploadValue: [false, false, false, false, false],
  // required: false,
  required: true,

  showNum: false,
  id: 6,
})
// 定义矩阵量表空题目
const mateixEchartQues = ref({
  type: '矩阵量表',
  title: '',
  labelTitle: ['', ''],
  valueTitle: ['1', '2', '3', '4', '5'],
  uploadLabel: [false, false],
  uploadValue: [false, false, false, false, false],
  // required: false,
  required: true,

  showNum: false,
  maxOption: 0,
  echartsDimension: 3,
  startNum: 1,
  id: 7,
})

// 传给子组件的questionId
let questionId = ref('')
//将id传给 孙子组件进行请求数据，孙子组件是webPreviewQuestion
provide('questionId', questionId)

// 返回给预览组件的数据
let questionData = reactive({
  naire_title: '这是一个标题',
  naire_explain: '',
  question_showNum: true,
  show_thank: true,
  inputThankVal: '',
  Questions: [],
})

provide('questionData', questionData)

// 预览show
let isH5Preview = ref(false)
// 打开预览
const goToH5Preview = () => {
  questionId.value = false
  questionData.naire_title = demoData?.questionTitleVal
  questionData.naire_explain = demoData.questionTitleExplainVal
  questionData.question_showNum = demoData.showNumberSwitch
  questionData.show_thank = demoData.showSthankCheck
  questionData.inputThankVal = demoData.inputThankVal
  questionData.Questions = questionArr?.value
  isH5Preview.value = true

  console.log('传入子组件的数据', questionData)
}

// onBeforeUnmount(async () => {
//   console.log("关闭页面保存");
//   if (createdqueationval.value == false) {
//     const localId = localStorage.getItem("_id");
//     let res = await http.post("/questionnaire/update-questionnaire", {
//       _id: oldQuestion.value._id,
//       naire_title: demoData.questionTitleVal,
//       naire_explain: demoData?.questionTitleExplainVal,
//       show_thank: demoData?.showSthankCheck,
//       thank_info: demoData?.inputThankVal,
//       question_showNum: demoData?.showNumberSwitch,
//       //   createUser: localId,
//       project: oldQuestion.value.project,
//       Questions: questionArr?.value,
//       frequency: demoData.deviceRestrictionsNum,
//       control_equipment: demoData.deviceRestrictionsCheck,
//       status: oldQuestion.value.status,
//     });
//     console.log(res, "res");
//     quesDialog.value = false;
//     if (res.status == 0) {
//       message({
//         showClose: true,
//         message: "新建问卷成功",
//         type: "success",
//       });
//       // router.push({
//       //   name: 'ShareQuestion',
//       //   params: {
//       //     _id: res.questionnaire._id,
//       //   },
//       // });
//     } else {
//       message({
//         showClose: true,
//         message: "新建问卷失败",
//         type: "error",
//       });
//     }
//   }
// });
// 关闭预览
const closeH5Preview = () => {
  // isH5Preview.value = false;
  router.go(-1)
}
</script>

<style lang="less" scoped>
@import url('@/style/components/QuestionnaireManagement/H5QuestionPreview.less');
</style>
