<template>
  <!-- <div id="CreateVolunteer" > -->
  <el-drawer :model-value="is_dialogShow" :show-close="false" :close-on-click-modal="false" title="I am the title" id="volunteer_dialog">
    <template #header>
      <div class="volunteer_title">
        <span v-if="!props.ChangeTite">新建志愿者</span>
        <span v-if="props.ChangeTite">线上报名</span>
      </div>
    </template>
    <template #default>
      <div class="volunteer_main">
        <!-- 个人基础信息 -->
        <div class="main_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">个人基础信息</div>
          </div>
          <div class="info_main">
            <!-- 志愿者ID -->
            <div class="info_id">
              <div class="id_text">志愿者ID</div>
              <div class="id_main">
                <div class="id_val">系统自动生成</div>
              </div>
            </div>
            <!-- 志愿者姓名 -->
            <div class="info_r">
              <div class="r_text">
                <span class="text_top">*</span>
                姓名
              </div>
              <div class="r_main">
                <el-input
                  v-model="inputNameValue"
                  class="r_inp"
                  placeholder="请输入姓名"
                  suffix-icon=""
                  :class="errObj.name ? 'inp_err' : ''"
                  @focus="errObj.name = false" />
                <div class="err_text">
                  <span v-if="errObj.name">{{ name_error }}</span>
                </div>
              </div>
            </div>
            <!-- 志愿者性别 -->
            <div class="info_l">
              <div class="l_text">
                <span class="text_top">*</span>
                <span>性别</span>
              </div>
              <div class="l_main">
                <el-select
                  v-model="sexArrValue"
                  class="l_select"
                  placeholder="请选择"
                  size="large"
                  @change="sexArrValueChange"
                  :class="errObj.sex ? 'inp_err' : ''"
                  @focus="errObj.sex = false">
                  <el-option v-for="item in sexArr" :key="item.label" :label="item.label" :value="item.label" />
                </el-select>
                <div class="err_text"><span v-if="errObj.sex">性别不可为空</span></div>
              </div>
            </div>
            <!-- 志愿者身份证号 -->
            <div class="info_r">
              <div class="r_text">
                <span class="text_top">*</span>
                身份证号
              </div>
              <div class="r_main">
                <el-input
                  :maxlength="18"
                  :minlength="15"
                  v-model="inputCardValue"
                  class="r_inp"
                  placeholder="请输入身份证号"
                  @input="is_inputCard"
                  @change="inputCardChange"
                  suffix-icon=""
                  :class="errObj.card ? 'inp_err' : ''"
                  @focus="errObj.card = false" />
                <div class="err_text">
                  <span v-if="errObj.card">{{ card_error }}</span>
                </div>
              </div>
            </div>
            <!-- 志愿者联系电话 -->
            <div class="info_l">
              <div class="phone_text l_text">
                <span class="text_top">*</span>
                联系电话
              </div>
              <div class="l_main">
                <el-input
                  v-model="inputPhoneValue"
                  class="l_inp"
                  placeholder="请输入联系电话"
                  suffix-icon=""
                  :class="errObj.phone ? 'inp_err' : ''"
                  @focus="errObj.phone = false" />
                <div class="err_text">
                  <span v-if="errObj.phone">{{ phone_error }}</span>
                </div>
              </div>
            </div>
            <!-- 推荐人 -->
            <div class="info_r">
              <div class="r_text">
                <span class="text_top">*</span>
                推荐人
              </div>
              <div class="r_main">
                <el-input
                  v-model="inputReferrerValue"
                  class="r_inp"
                  placeholder="请输入推荐人"
                  suffix-icon=""
                  :class="errObj.referrer ? 'inp_err' : ''"
                  @focus="errObj.referrer = false" />
                <div class="err_text">
                  <span v-if="errObj.referrer">{{ referrer_error }}</span>
                </div>
              </div>
            </div>
            <!-- 出生日期 -->
            <div class="info_l">
              <div class="l_text">出生日期</div>
              <div class="l_main" :class="errObj.date ? 'inp_err' : ''">
                <el-date-picker
                  disabled
                  class="l_picker"
                  v-model="dateValue"
                  type="date"
                  placeholder="请选择"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  @change="dateChange"
                  @focus="errObj.date = false" />
                <div class="err_text"><span v-if="errObj.date">必选项不可为空</span></div>
              </div>
            </div>
            <!-- 年龄 -->
            <div class="info_r">
              <div class="r_text">年龄</div>
              <div class="r_main">
                <div class="r_val">{{ ageValue ? ageValue : '系统根据出生日期自动计算' }}</div>
              </div>
            </div>
            <!-- 所在地址 -->
            <div class="info_l">
              <div class="l_text">所在地址</div>
              <div class="l_main">
                <el-cascader class="l_cascader" v-model="addressValue" :options="cityData" @change="handleChange" />
                <div class="err_text"><span v-if="errObj.address">必选项不可为空</span></div>
              </div>
            </div>
            <!-- 月收入范围 -->
            <div class="info_r">
              <div class="r_text">
                <span>月收入范围</span>
              </div>
              <div class="r_main">
                <el-select v-model="moneyArrValue" class="r_select" placeholder="请选择" size="large" @change="moneyArrValueChange">
                  <el-option v-for="item in moneyArr" :key="item.label" :label="item.label" :value="item.label" />
                </el-select>
                <div class="err_text"><span v-if="errObj.money">必选项不可为空</span></div>
              </div>
            </div>
            <!-- 婚育史 -->
            <div class="info_l">
              <div class="l_text">婚育史</div>
              <div class="l_main">
                <el-select v-model="Marriage_history" class="l_select" placeholder="请选择" size="large">
                  <el-option v-for="item in Marriage" :key="item.label" :label="item.label" :value="item.label" />
                </el-select>
                <div class="err_text"><span v-if="errObj.Marriage">必选项不可为空</span></div>
              </div>
            </div>
            <!-- 是否三期 -->
            <div class="info_r">
              <div class="r_text">是否三期</div>
              <div class="r_main">
                <el-select v-model="three_period" class="r_select" placeholder="请选择" size="large">
                  <el-option v-for="item in is_threePeriod" :key="item.label" :label="item.label" :value="item.label" />
                </el-select>
              </div>
            </div>
            <!-- 标签 -->
            <div class="info_l">
              <div class="l_text">标签</div>
              <div class="l_main">
                <el-select v-model="Label" class="l_select" placeholder="请选择" size="large" multiple collapse-tags collapse-tags-tooltip>
                  <el-option
                    v-for="item in labelList"
                    :key="item"
                    :label="item"
                    :value="item"
                    :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                </el-select>
              </div>
            </div>
            <!-- 备注 -->
            <div class="info_r">
              <div class="r_text">备注</div>
              <div class="r_main">
                <el-input :maxlength="20" type="text" v-model="Remark" class="r_inp" placeholder="请输入备注" suffix-icon="" />
              </div>
            </div>
          </div>
        </div>
        <!-- 系统信息 -->
        <div class="main_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">系统信息</div>
          </div>
          <div class="info_main">
            <div class="info-item">
              <div class="item-title">首次注册时间</div>
              <div class="item-content"><span>系统自动生成</span></div>
            </div>
            <div class="info-item">
              <div class="item-title">历史报名项目数量</div>
              <div class="item-content"><span>0</span></div>
            </div>
            <div class="info-item">
              <div class="item-title">历史完成项目数量</div>
              <div class="item-content"><span>0</span></div>
            </div>
            <div class="info-item">
              <div class="item-title">正在参与项目数量</div>
              <div class="item-content"><span>0</span></div>
            </div>
          </div>
        </div>
        <!-- 皮肤信息 -->
        <div class="main_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">皮肤信息</div>
          </div>
          <div class="info_main">
            <div class="info-item">
              <div class="item-title">皮肤肤质</div>
              <div class="item-content">
                <el-select v-model="skin_texture_val" placeholder="请选择">
                  <el-option v-for="item in skin_texture" :key="item" :label="item" :value="item" />
                </el-select>
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">皮肤类型</div>
              <div class="item-content">
                <el-select v-model="skin_type_val" placeholder="请选择">
                  <el-option v-for="item in skin_type" :key="item" :label="item" :value="item" />
                </el-select>
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">皮肤问题</div>
              <div class="item-content">
                <el-select placeholder="请选择" v-model="skin_problems_val" multiple collapse-tags collapse-tags-tooltip>
                  <el-option v-for="item in skin_problems" :key="item" :label="item" :value="item" />
                </el-select>
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">皮肤肤色</div>
              <div class="item-content">
                <el-select v-model="skin_complexion_val" placeholder="请选择">
                  <el-option v-for="item in skin_complexion" :key="item" :label="item" :value="item" />
                </el-select>
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">使用过的产品类别</div>
              <div class="item-content">
                <el-select
                  @change="change_category_val"
                  placeholder="请选择"
                  v-model="used_category_val"
                  multiple
                  collapse-tags
                  collapse-tags-tooltip>
                  <el-option v-for="item in used_category" :key="item" :label="item" :value="item" />
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!-- 健康信息 -->
        <div class="main_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">健康信息</div>
          </div>
          <div class="info_main">
            <div class="info-item">
              <div class="item-title">是否抽烟</div>
              <div class="item-content">
                <el-select v-model="smokes_val" placeholder="请选择">
                  <el-option v-for="item in smokes" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">是否饮酒</div>
              <div class="item-content">
                <el-select v-model="drink_val" placeholder="请选择">
                  <el-option v-for="item in drink" :key="item.label" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">身体健康情况</div>
              <div class="item-content is-illness">
                <el-select
                  placeholder="请选择"
                  @change="change_basic_illness"
                  v-model="basic_illness_val"
                  multiple
                  collapse-tags
                  collapse-tags-tooltip>
                  <el-option v-for="item in basic_illness" :key="item" :label="item" :value="item" />
                </el-select>
              </div>
            </div>
          </div>
        </div>
        <!-- 收款信息 -->
        <div class="main_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">收款信息</div>
          </div>
          <div class="info_main">
            <div class="info-item">
              <div class="item-title">收款户名</div>
              <div class="item-content collect-money">
                <el-input :maxlength="15" @input="isDecimal" v-model="collection_name" placeholder="请输入收款户名" />
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">收款账号</div>
              <div class="item-content collect-money">
                <el-input v-model="collection_account" @input="AreInteger" :maxlength="19" placeholder="请输入收款账号" />
              </div>
            </div>
            <div class="info-item">
              <div class="item-title">收款银行</div>
              <div class="item-content collect-money">
                <el-input :maxlength="20" @input="isDecimal" v-model="collection_bank" placeholder="请输入收款银行" />
              </div>
            </div>
          </div>
        </div>
        <!-- 测试场所 -->
        <div class="main_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">测试场所</div>
          </div>
          <div class="info_main">
            <div class="info-item">
              <div class="item-title testSite-title">测试场所</div>
              <div class="item-content">
                <el-select v-model="test_site_val" placeholder="请选择" :class="is_testError ? 'inp_err' : ''">
                  <el-option v-for="item in test_site" :key="item" :label="item" :value="item" />
                </el-select>
              </div>
              <div class="err_text test-error" v-if="is_testError"><span>测试场所不可为空</span></div>
            </div>
          </div>
        </div>
        <!-- 文件 -->
        <div class="main_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">文件</div>
          </div>
          <div class="info_main">
            <el-upload class="upload_xlsx" ref="videoRef" :http-request="uploadsFile" :show-file-list="false">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2081%20%E2%80%93%2010%402x.png" alt="" />

              <!-- <img src="https://newstore.vynior.com/cems_web/%E4%B8%8A%E4%BC%A0%402x.png" alt="" />
              <span>上传附件</span> -->
            </el-upload>
          </div>
          <div class="Table">
            <el-table :data="attachmentList" border stripe v-show="attachmentList.length">
              <el-table-column type="index" width="56" label="序号" />
              <el-table-column prop="fileName" label="文件名称" width="470">
                <template #default="scope">
                  <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                    {{ scope.row.fileName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="uploadTime" :formatter="formatterTime" label="上传时间" width="208">
                <template #default="scope">
                  <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                    {{ formatterTime(scope.row.uploadTime) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="300" align="center">
                <template #default="scope">
                  <el-button
                    style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                    @click="downloadAttachment(scope.row.filePath)"
                    >下载</el-button
                  >
                  <el-button
                    style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                    @click="deleteFile(scope.row.uuid)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="handleClose">取消</el-button>
        <el-button v-if="!props.ChangeTite" type="primary" @click="createdUser">确定</el-button>
        <el-button v-if="props.ChangeTite" type="primary" @click="createdUser">报名</el-button>
      </div>
    </template>
  </el-drawer>
  <!-- </div> -->
</template>
<script setup>
import cityData from '@/utils/citydata'
import http from '@/utils/http'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { defineEmits, defineProps, inject, reactive, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
const store = useStore()

let parentMethod = inject('parentMethod')
//限制不能是小数
const isDecimal = () => {
  console.log(collection_name.value)
  if (collection_name.value.includes('.')) {
    collection_name.value = ''
  }
  if (collection_bank.value.includes('.')) {
    collection_bank.value = ''
  }
}
const AreInteger = () => {
  const pattern = /^\d*$/
  if (!pattern.test(collection_account.value)) {
    collection_account.value = ''
  }
}
// 上传附件返回信息
const attachmentList = ref([])

// 上传附件事件
const uploadsFile = async (params) => {
  const file = params.file
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'attachment')
  const response = await http.post('/upload/file-upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  const { status, file: fileData } = response
  if (status === 0) {
    attachmentList.value.push(fileData)
    ElMessage({
      message: '附件上传成功',
      // grouping: true,
      showClose: true,
      type: 'success',
    })
  } else {
    ElMessage({
      message: '附件上传失败，请稍后再试',
      // grouping: true,
      showClose: true,
      type: 'warning',
    })
  }
}
// 格式化时间
const formatterTime = (row, column, cellValue, index) => {
  return dayjs(cellValue).format('YYYY-MM-DD HH:mm')
}
// 被删除附件（已保存）
const deleteFiles = ref([])
// 删除附件
const deleteFile = (data) => {
  attachmentList.value.forEach((attachment, index) => {
    if (attachment.uuid == data) {
      if (attachment.downloadPath) {
        deleteFiles.value.push(attachment.uuid)
      }
      attachmentList.value.splice(index, 1)
    }
  })
}
// 下载附件
const downloadAttachment = async (downloadPath) => {
  const repsonse = await http.post('https://cems.fuhuan.ltd/api/upload/attachmentDownload', { downloadPath }, { responseType: 'arraybuffer' })
  const blob = new Blob([repsonse], { type: 'application/x-zip-compressed;charset=utf-8' })
  let name = '附件下载.zip'
  const downloadElement = document.createElement('a')
  let classificationhref = window.URL.createObjectURL(blob)
  downloadElement.href = classificationhref
  downloadElement.download = `${name}`
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(classificationhref)
}

const emits = defineEmits(['update:modelValue'])
//关闭的点击事件
const handleClose = () => {
  emits('update:modelValue', false)
  // is_dialogShow.value = false
}

//新建志愿者显示
const is_dialogShow = ref(false)
// 志愿者姓名
let inputNameValue = ref('')
// 志愿者性别所有选项
let sexArr = ref([
  // { label: '未知', value: 0 },
  // { label: '男性', value: 1 },
  // { label: '女性', value: 2 },
  { label: '男', value: 1 },
  { label: '女', value: 2 },
])
//测志愿者性别选中的值
let sexArrValue = ref('')
//志愿者性别选中事件
const sexArrValueChange = (val) => {
  // console.log(val);
  sexArrValue.value = val
}
//志愿者身份证号
let inputCardValue = ref('')
//志愿者联系电话
let inputPhoneValue = ref('')
//志愿者推荐人
let inputReferrerValue = ref('')
// 出生日期
let dateValue = ref()
// 出生日期触发事件
const dateChange = (val) => {
  if (dateValue.value == null) {
    return null
  }
  // console.log(dateValue.value);
  const now = new Date()
  // 将给定的时间点转化为 Date 对象
  const birthDate = new Date(dateValue.value)
  // 计算时间差
  const diff = Math.abs(now.getTime() - birthDate.getTime())
  // 计算年龄
  const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25))
  // console.log(age);
  ageValue.value = age
  console.log(ageValue.value, 'ageValue.value')
}
//年龄
const ageValue = ref()
// let ageValue = computed(() => {
//   if (dateValue.value == null) {
//     return null;
//   }
//   // console.log(dateValue.value);
//   const now = new Date();
//   // 将给定的时间点转化为 Date 对象
//   const birthDate = new Date(dateValue.value);
//   // 计算时间差
//   const diff = Math.abs(now.getTime() - birthDate.getTime());
//   // 计算年龄
//   const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
//   // console.log(age);
//   return age;
// });
// 所在地址
let addressValue = ref('')
// const address_val = computed(() => {
//   console.log(addressValue);
//   return 1
// });
let address_val = ref('')
const handleChange = (res) => {
  console.log(res, '所在地址改变')
  console.log(addressValue.value, addressValue.value.length)
  for (let i = 0; i < addressValue.value.length; i++) {
    if (i == 0) {
      address_val.value = addressValue.value[i]
    } else {
      address_val.value = address_val.value + '-' + addressValue.value[i]
    }
    console.log(address_val.value)
  }
}
// 月收入范围所有选项
let moneyArr = ref([
  { label: '5千以下', value: 1 },
  { label: '5千-1万', value: 2 },
  { label: '1万-5万', value: 3 },
  { label: '5万以上', value: 4 },
])
//测志愿者性别选中的值
let moneyArrValue = ref('')
//志愿者性别选中事件
const moneyArrValueChange = (val) => {
  console.log(val)
  moneyArrValue.value = val
}

// 婚育史
const Marriage_history = ref('')
const Marriage = ref([
  { label: '未婚', value: 1 },
  { label: '已婚未育', value: 2 },
  { label: '已婚已育', value: 3 },
])

// 三期
const three_period = ref('')
const is_threePeriod = ref([
  { label: '无', value: 0 },
  { label: '孕期', value: 1 },
  { label: '产期', value: 2 },
  { label: '哺乳期', value: 3 },
])
watch(sexArrValue, (newValue) => {
  if (newValue === '男') {
    three_period.value = '无'
    is_threePeriod.value = [{ label: '无', value: 0 }]
  }
  if (newValue === '女') {
    three_period.value = ''
    is_threePeriod.value = [
      { label: '无', value: 0 },
      { label: '孕期', value: 1 },
      { label: '产期', value: 2 },
      { label: '哺乳期', value: 3 },
    ]
  }
})
const props = defineProps({
  ChangeTite: Boolean,
})
console.log('ChangeTite :>> ', props.ChangeTite, props)
// 标签
const Label = ref('')
const labelList = ref([])

// 备注
const Remark = ref('')
// === 皮肤信息 ===
// 皮肤肤质
const skin_texture_val = ref('')
const skin_texture = ref(['干性', '油性', '中性', '混合偏干', '混合偏油', '不确定', '其他'])
// 皮肤类型
const skin_type_val = ref('')
const skin_type = ref(['敏感', '非敏感', '不确定', '其他'])
// 皮肤问题
const skin_problems_val = ref('')
const skin_problems = ref([
  '毛孔粗大',
  '有炎性痘痘',
  '有闭口粉刺',
  '有色斑',
  '有痘印',
  '敏感泛红',
  '肤色暗沉',
  '有黑头',
  '黑眼圈',
  '眼袋',
  '皱纹细纹',
  '皮肤松弛',
  '其他',
])
// 皮肤肤色
const skin_complexion_val = ref('')
const skin_complexion = ref(['偏白', '偏黄', '偏黑', '偏红', '中等'])
// 用过的产品类别
const used_category_val = ref([])
const used_category = ref(['护肤品', '防晒霜', '粉底', '口红', '眼线笔', '睫毛膏', '脱毛仪', '都没有', '其他'])
// watch(
//   () => used_category_val.value,
//   (newvalue, oldvalue) => {
//     console.log(newvalue, oldvalue);
//     if (newvalue.includes('都没有')) {
//       // 组件中超过最大递归更新
//       // used_category_val.value = ['都没有'];
//       const newValueWithoutAll = ['都没有'];
//       used_category_val.value = newValueWithoutAll;
//     }
//   },
//   // { immediate: true },
// );
const change_category_val = (e) => {
  // console.log(e);
  // if (e.includes('都没有')) {
  //   // used_category_val.value = ['都没有'];
  //   const newValueWithoutAll = ['都没有'];
  //   used_category_val.value = newValueWithoutAll;
  // }
  if (e.includes('都没有') && e[e.length - 1] == '都没有') {
    const newValueWithoutAll = ['都没有']
    used_category_val.value = newValueWithoutAll
    console.log('点击')
  }
  if (e[0] == '都没有' && used_category_val.value.length > 1) {
    used_category_val.value.splice(0, 1)
    console.log('有提到')
  }
}
// === 健康信息 ===
// 抽烟
const smokes_val = ref()
const smokes = ref([
  { label: '是', value: true },
  { label: '否', value: false },
])
// 饮酒
const drink_val = ref()
const drink = ref([
  { label: '是', value: true },
  { label: '否', value: false },
])
// 基础疾病
const basic_illness_val = ref([])
// watch(
//   () => basic_illness_val.value,
//   () => {
//     if (basic_illness_val.value.includes('无任何以上提到的疾病')) {
//       // console.log('1');
//       // 组件中超过最大递归更新
//       basic_illness_val.value = ['无任何以上提到的疾病'];
//     }
//   },
// );
const change_basic_illness = (e) => {
  console.log(e)
  if (e.includes('无任何以上提到的疾病') && e[e.length - 1] == '无任何以上提到的疾病') {
    const newValueWithoutAll = ['无任何以上提到的疾病']
    basic_illness_val.value = newValueWithoutAll
    console.log('点击')
  }
  console.log(e[e.length - 1], 'e[e.length-1]')
  console.log(basic_illness_val.value.length, 'basic_illness_val.value.length')
  if (e[0] == '无任何以上提到的疾病' && basic_illness_val.value.length > 1) {
    basic_illness_val.value.splice(0, 1)
    console.log('有提到')
  }
  console.log(basic_illness_val.value, 'basic_illness_val')
}
const disease_name = ref([])
const basic_illness = ref([
  '高血压',
  '高血脂',
  '非胰岛素依赖性糖尿病',
  '胰岛素依赖性糖尿病',
  '免疫性疾病',
  '皮肤病史',
  '过敏史',
  '肝肾功能疾病',
  '心脑血管疾病',
  '哮喘患者',
  '其他呼吸系统疾病',
  '入院治疗史',
  '无任何以上提到的疾病',
])

// === 收款信息 ===
// 收款户名
const collection_name = ref('')
// 收款账号
const collection_account = ref('')
// 收款银行
const collection_bank = ref('')
// 测试场所
const test_site_val = ref('')
const test_site = ref(['测试场所一'])

//必选项是否为空提示
let errObj = reactive({
  name: false,
  sex: false,
  card: false,
  phone: false,
  referrer: false,
  date: false,
  address: false,
  money: false,
  Marriage: false,
  period: false,
  label: false,
  remark: false,
})
// 姓名校验
let name_error = ref('姓名不可为空')

const is_inputName = () => {
  if (inputNameValue.value === '') {
    name_error.value = '姓名不可为空'
    errObj.name = true
  } else if (inputNameValue.value.length < 2 || inputNameValue.value.length > 10) {
    errObj.name = true
    name_error.value = '姓名不符合格式'
  } else {
    errObj.name = false
  }
}
watch(
  () => inputNameValue.value,
  () => {
    is_inputName()
  }
)
const is_inputSex = () => {
  if (sexArrValue.value === '') {
    errObj.sex = true
  } else {
    errObj.sex = false
  }
  //   if(sexArrValue.value==='男'){
  //     is_threePeriod.value= ref([
  //   { label: '无', value: 0 }
  // ]);
  //   }
}
watch(
  () => sexArrValue.value,
  () => {
    is_inputSex()
  }
)
let card_error = ref('身份证号不可为空')
let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
let _IDRe15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
// 身份证输入事件
const is_inputCard = () => {
  if (inputCardValue.value === '') {
    card_error.value = '身份证号不可为空'
    errObj.card = true
  } else if (inputCardValue.value.length === 18) {
    console.log('18位身份证')
    if (!_IDRe18.test(inputCardValue.value)) {
      errObj.card = true
      // 身份证不符合格式
      card_error.value = '身份证不符合格式'
      // console.log('身份证不符合格式');
    } else {
      card_error.value = ''
      errObj.card = false
    }
  } else if (inputCardValue.value.length == 15) {
    console.log('身份证位数不正确')
    if (!_IDRe15.test(inputCardValue.value)) {
      errObj.card = true
      // 身份证不符合格式
      card_error.value = '身份证不符合格式'
      // console.log('身份证不符合格式');
    } else {
      card_error.value = ''
      errObj.card = false
    }
  } else if (inputCardValue.value.length < 15 || 15 < inputCardValue.value.length < 18) {
    console.log('身份证位数不正确')
    if (!_IDRe15.test(inputCardValue.value)) {
      errObj.card = true
      // 身份证不符合格式
      card_error.value = '身份证不符合格式'
      // console.log('身份证不符合格式');
    } else {
      card_error.value = ''
      errObj.card = false
    }
  } else {
    errObj.card = true
    // 身份证不符合格式
    card_error.value = '身份证不符合格式'
    // console.log('身份证不符合格式');
  }
}
// 身份证输入完触发事件
const inputCardChange = (idNumber) => {
  if (idNumber.length === 18) {
    var birthYear = Number(idNumber.substr(6, 4))
    var birthMonth = idNumber.substr(10, 2)
    var birthDay = idNumber.substr(12, 2)
  } else if (idNumber.length === 15) {
    var birthYear = Number('19' + idNumber.substr(6, 2))
    var birthMonth = idNumber.substr(8, 2)
    var birthDay = idNumber.substr(10, 2)
  } else {
    return '无效的身份证号码'
  }
  console.log(birthYear, '年')
  console.log(birthMonth, '月')
  console.log(birthDay, '日')
  // 获取当前日期
  var currentDate = new Date()
  var currentYear = currentDate.getFullYear()
  var currentMonth = currentDate.getMonth() + 1
  var currentDay = currentDate.getDate()
  // 计算年龄
  var age = currentYear - birthYear
  // 就算出生日期
  const formattedBirthDate = birthYear + '-' + birthMonth + '-' + birthDay
  // const formattedBirthDate = birthDate.toISOString().substring(0, 10).replace(/-/g, "x");
  if (birthMonth > currentMonth || (birthMonth === currentMonth && birthDay > currentDay)) {
    age -= 1
  }
  console.log(age, 'age')
  ageValue.value = age
  dateValue.value = formattedBirthDate
  console.log(dateValue.value, 'dateValue.value')
}
// watch(
//   () => inputCardValue.value,
//   () => {
//     is_inputCard();
//   },
// );

const phone = /^1[3-9]\d{9}$/
let phone_error = ref('联系电话不可为空')
const is_inputPhone = () => {
  if (inputPhoneValue.value === '') {
    phone_error.value = '联系电话不可为空'
    errObj.phone = true
  } else if (!phone.test(inputPhoneValue.value)) {
    phone_error.value = '联系电话不符合格式'
    errObj.phone = true
  } else {
    phone_error.value = ''
    errObj.phone = false
  }
}
watch(
  () => inputPhoneValue.value,
  () => {
    is_inputPhone()
  }
)
// 推荐人校验
let referrer_error = ref('姓名不可为空')
const is_inputReferrer = () => {
  if (inputReferrerValue.value === '') {
    referrer_error.value = '推荐人不可为空'
    errObj.referrer = true
  } else if (inputReferrerValue.value.length < 0 || inputReferrerValue.value.length > 10) {
    referrer_error.value = '推荐人不符合格式'
    errObj.referrer = true
  } else {
    errObj.referrer = false
  }
}
watch(
  () => inputReferrerValue.value,
  () => {
    is_inputReferrer()
  }
)
// 判断测试场所是否勾选
const test_site_error = ref('测试场所不可为空')
const is_testError = ref(false)
const is_inputTestSite = () => {
  if (test_site_val.value === '') {
    test_site_error.value = '测试场所不可为空'
    is_testError.value = true
  } else {
    test_site_error.value = ''
    is_testError.value = false
  }
}
watch(
  () => test_site_val.value,
  () => {
    is_inputTestSite()
  }
)
// 新建志愿者确定按钮
const createdUser = async () => {
  let IDS = '' //此次志愿者的id
  is_inputName()
  is_inputCard()
  is_inputSex()
  is_inputPhone()
  is_inputReferrer()
  is_inputTestSite()
  if (test_site_val.value !== '') {
    if (
      errObj.name === false &&
      errObj.sex === false &&
      errObj.card === false &&
      errObj.phone === false &&
      errObj.referrer === false &&
      test_site_val.value != ''
    ) {
      await http
        .post('/volunteer/create-volunteer', {
          // 个人基础信息
          volun_name: inputNameValue.value,
          volun_gender: sexArrValue.value,
          idNumber: inputCardValue.value,
          volun_phone: inputPhoneValue.value,
          referee: inputReferrerValue.value,
          volun_birthday: dateValue.value,
          volun_age: ageValue.value === '系统根据出生日期自动计算' ? 0 : ageValue.value,
          address: address_val.value,
          monthly_income: moneyArrValue.value,
          marital_history: Marriage_history.value,
          three_phase: three_period.value,
          tags: Label.value,
          remark: Remark.value,
          // 皮肤信息
          skin_texture: skin_texture_val.value,
          skin_type: skin_type_val.value,
          skin_issues: skin_problems_val.value,
          skin_color: skin_complexion_val.value,
          use_products: used_category_val.value,
          // // 健康信息
          smoke: smokes_val.value,
          drink: drink_val.value,
          underlying_disease: basic_illness_val.value,
          // 收款户名
          account_name: collection_name.value,
          account_number: collection_account.value,
          bank: collection_bank.value,
          // 测试场所
          testAddress: test_site_val.value,
          // 文件
          files: attachmentList.value,
        })
        .then((res) => {
          console.log(res)
          if (res.status === 0) {
            if (props.ChangeTite) {
              // 调用报名接口
              Recruit(res.volunteer_id)
            }

            IDS = res.volunteer_id
            ElMessage({
              showClose: true,
              message: '新建志愿者成功',
              type: 'success',
            })
            // application(IDS);
            handleClose()
          } else if (res.status === 1) {
            ElMessage({
              showClose: true,
              message: '身份证号重复',
              type: 'error',
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
// 线上报名
const Recruit = async (id) => {
  const res = await http.post('/recruit/webcreateRecord-recruit', {
    project: store.state.Project.ProjectId,
    volunteer: id,
    reservation_time: '2023-07-08', //预约时间
    skin_type: skin_type_val.value, //皮肤类型
    skin_issues: skin_problems_val.value, //问题
    skin_texture: skin_texture_val.value, //肤质,
    skin_color: skin_complexion_val.value, //皮肤颜色
    use_products: used_category_val.value, //使用过的产品
  })
  console.log('线上报名返回的数据res :>> ', res)
  if (res.status == 0) {
    parentMethod()
  }
}

// 请求初始数据
const ReqDefault = async () => {
  const res = await http.post('/setting/fetch-setting', {
    type: 'volunteer',
  })
  console.log('标签请求返回的数据是', res)
  // labelList
  if (res.status == 0) {
    console.log('res请求初始数据为 :>> ', res)

    res.tags.forEach((item, index) => {
      item.forEach((j) => {
        if (j.value != false) {
          labelList.value.push(j.name)
        }
      })
    })

    if (labelList.value.length == 0) {
      labelList.value.push('暂无标签，请前往【自定义设置】新建')
    }
    console.log('获取的最终数据', labelList.value)
  }
}
// 报名方法
// const application = async (val) => {
//   let res = await http.post('/Recruit_Form/created-Recruit_Form', {
//     project: store.state.Project.ProjectId,
//     volunteer: val,
//     reservation_time: '2023-05-25',
//   });
//   console.log(res);
// };
onMounted(() => {
  ReqDefault()
})
</script>

<style lang="less" scope>
@import url('@/style/components/VolunteerManagement/CreatedVolunteer.less');
</style>
