const state = {
  userName: '',
  reset_password: '',
  CurrentPath: '/asd',
  TimeOut: false,
  Signature: '电子签章',
  Volnature: '组别管理',
  Gender:''
};

const mutations = {
  set_Volnature(state, newValue) {
    state.Volnature = newValue;
  },

  set_Signature(state, newValue) {
    state.Signature = newValue;
  },
  set_UserName(state, newValue) {
    state.userName = newValue;
  },
  set_reset_password(state, newValue) {
    state.reset_password = newValue;
  },

  set_CurrentPath(state, newValue) {
    state.CurrentPath = newValue;
  },
  Set_TimeOut(state, newValue) {
    state.TimeOut = newValue;
  },
  Set_Gender(state, newValue) {
    state.gender = newValue;
  },
};

//导出
export default {
  state,
  mutations,
};
