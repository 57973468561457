<template>
  <div id="VolunteerSchedule">
    <div class="project_main">
      <!-- 头部导航 -->
      <div class="project_main_state">
        <!-- 筛选status状态按钮 -->

        <div class="TableHeader" v-if="store.state.Project.ProjectId == ''">
          <!-- 默认第一次 -->
          <div class="main_state_item">
            <div class="Times">第一次</div>
            <div class="D_Number">(D0)</div>
          </div>
        </div>
        <div class="TableHeader2" v-if="store.state.Project.ProjectId != ''">
          <!-- 循环n次 -->
          <div
            class="main_state_item2"
            v-for="(item, index) in ProjectState"
            :key="index"
            :class="index == ClickActive ? 'click-active' : ''"
            @click="ClickActive = index">
            <div class="Times">{{ item }}</div>
            <div class="D_Number">{{ `(D${D_value[index]})` }}</div>
          </div>
        </div>
      </div>

      <!-- 表格主体 -->
      <div class="ProjectListBox">
        <div class="vxetable">
          <!-- 表格头部 工具按钮 -->
          <vxe-toolbar>
            <template #buttons>
              <vxe-input
                class="Search"
                v-model="demo1.Search"
                type="search"
                placeholder="请输入志愿者ID或姓名进行搜索"
                @Blur="searchEvent2"></vxe-input>
              <vxe-button @click="ResetTable" class="Reset"><span class="reset-icon">重置</span></vxe-button>
              <div class="BatchSchedules" @click="JudgeLength('批量排期')">
                <button>
                  <img src="https://newstore.vynior.com/cems_web/%E6%97%A5%E6%9C%9F%402x%20%281%29.png" />
                  批量排期
                </button>
              </div>
              <div class="BatchComfirm" @click="JudgeLength('批量确认')">
                <button>
                  <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110598%402x.png" />
                  批量确认
                </button>
              </div>
              <vxe-button @click="isShowExport" class="Import"></vxe-button>
            </template>
          </vxe-toolbar>
          <!-- 表格 -->
          <div class="tableOutSide" v-if="store.state.Project.ProjectId != '' && demo1.tableData.length != 0">
            <vxe-table
              border
              stripe
              height="auto"
              auto-resize
              show-overflow
              ref="xTable"
              :column-config="{ resizable: true }"
              :loading="demo1.loading"
              :tooltip-config="tooltipConfig"
              :data="tableData"
              :edit-config="{ trigger: 'manual', mode: 'row', showIcon: false, autoClear: false }"
              @checkbox-all="selectAllChangeEvent1"
              @checkbox-change="selectChangeEvent1">
              <vxe-column field="checked" type="checkbox" width="41" min-width="40" fixed="left"></vxe-column>
              <vxe-column type="seq" title="序号" width="60" fixed="left"></vxe-column>
              <vxe-column field="_id" title="志愿者ID">
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === '志愿者ID'">
                    <el-tooltip class="box-item" effect="dark" content="志愿者ID" placement="top">
                      <span class="name">志愿者ID</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('志愿者ID', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="volun_id" title="志愿者编号">
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === '志愿者编号'">
                    <el-tooltip class="box-item" effect="dark" content="志愿者编号" placement="top">
                      <span class="name">志愿者编号</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('志愿者编号', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="volun_name" title="姓名">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === '姓名'">
                    <el-tooltip class="box-item" effect="dark" content="姓名" placement="top">
                      <span class="name">姓名</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('姓名', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="volun_phone" title="联系电话">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === '联系电话'">
                    <el-tooltip class="box-item" effect="dark" content="联系电话" placement="top">
                      <span class="name">联系电话</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('联系电话', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="visit_date" title="到访日期" :edit-render="{}">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === '到访日期'">
                    <el-tooltip class="box-item" effect="dark" content="到访日期" placement="top">
                      <span class="name">到访日期</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('到访日期', $event)"></i></span>
                  </div>
                </template>
                <template #edit="{ row }">
                  <!-- <el-date-picker class="startTime_picker" v-model="row.visit_date" type="date" placeholder="请选择" format="YYYY-MM-DD" /> -->
                  <el-select v-model="row.visit_date" class="VisitDate" placeholder="请选择" size="large">
                    <el-option v-for="item in Options" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </template>
                <template #default="{ row }">
                  <span>{{ dayjs(row.visit_date).format('YYYY-MM-DD') }} </span>
                </template>
              </vxe-column>
              <vxe-column field="visit_time" title="到访时间" :edit-render="{}">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === '到访时间'">
                    <el-tooltip class="box-item" effect="dark" content="到访时间" placement="top">
                      <span class="name">到访时间</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('到访时间', $event)"></i></span>
                  </div>
                </template>
                <template #edit="{ row }">
                  <el-time-select v-model="row.visit_time" start="00:00" step="00:15" end="23:30" placeholder="请选择" />
                </template>
              </vxe-column>
              <vxe-column field="remark" :formatter="formatterRemark" title="备注" :edit-render="{}">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === '备注'">
                    <span class="name">备注</span>

                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('备注', $event)"></i></span>
                  </div>
                </template>
                <template #edit="{ row }">
                  <span v-if="false">{{ row }}</span>
                  <el-input v-model="remark" placeholder="请输入" />
                </template>
              </vxe-column>
              <vxe-column title="操作" width="248" :resizable="false">
                <template #default="{ row }">
                  <template v-if="$refs.xTable.isEditByRow(row)">
                    <el-tooltip class="box-item" effect="dark" content="保存" placement="top">
                      <vxe-button class="Save" @click="saveRowEvent(row)">保存</vxe-button>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="取消" placement="top">
                      <vxe-button class="CancelSave" @click="cancelRowEvent(row)">取消</vxe-button>
                    </el-tooltip>
                  </template>
                  <template v-else>
                    <template v-if="row.status == false">
                      <el-tooltip class="box-item" effect="dark" content="确认" placement="top">
                        <vxe-button v-if="row.visit_time.length == 5" class="Confirm" @click="confirm(row)">确认</vxe-button>
                        <vxe-button v-if="row.visit_time.length != 5" class="Confirm" style="color: gray">确认</vxe-button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="编辑" placement="top">
                        <vxe-button class="Edit" @click="editRowEvent(row)">编辑</vxe-button>
                      </el-tooltip>
                    </template>
                    <template v-else>
                      <el-tooltip class="box-item" effect="dark" content="取消确认" placement="top">
                        <vxe-button class="CancelPass" @click="CancelConfirm(row)">取消确认</vxe-button>
                      </el-tooltip>
                    </template>
                  </template>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
              </ul>
            </div>
          </div>
          <!-- 批量志愿者信息  导出 -->
          <div class="Derived_volunteer" v-if="isExport">
            <div class="Derived_volunteer_box">
              <!-- 导出一个 -->
              <div v-if="checkedArr.length == 1">
                <div class="Derived_title">
                  确定导出<span class="Derived_name">{{ selectedRows[0].volun_name == ' ' ? '无名字' : selectedRows[0].volun_name }}</span
                  >的志愿者排期信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="exportDataEvent">确认</div>
                </div>
              </div>
              <!-- 导出多个 -->
              <div v-if="checkedArr.length > 1">
                <div class="Derived_title">
                  已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>志愿者排期信息，确认导出吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="exportDataEvent">确认</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 导出一条没有的时候 -->
          <div class="Derived_volunteer_null" v-if="null_reminder_export">
            <!-- 导出 -->
            <div class="Derived_volunteer_box">
              <div class="Derived_title">
                <div class="Derived_name">提示</div>
                <div><img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
              </div>
              <div class="Derived_body">
                <div><img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" /></div>
                <div class="Derived_body_text">请选择需要导出的志愿者排期信息！</div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <p v-if="store.state.Project.ProjectId != '' && demo1.tableData.length != 0">
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                  >条/共{{ page5.totalResult }}条
                </div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
      <!-- 空id页面 -->
      <div class="Empty" v-if="store.state.Project.ProjectId == ''">
        <div class="Img">
          <img src="https://newstore.vynior.com/12%402x.png" alt="" />
        </div>
        <div class="Text">
          <p>请选择项目,查看对应的排期表</p>
        </div>
      </div>
      <!-- 空数据页面 -->
      <div class="EmptyProject" v-if="demo1.tableData.length == 0 && store.state.Project.ProjectId != ''">
        <span>
          <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
          <p>暂无数据</p>
        </span>
      </div>
      <!-- 批量排期 -->
      <div class="BatchSchedule" v-if="ShowSchedule">
        <div class="ScheduleBox">
          <div class="Title">
            <span> 批量排期</span>
            <img class="Close" @click="ShowSchedule = false" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
          </div>
          <div class="FlexInput">
            <!-- 到访日期 -->
            <div class="VisitInputs">
              <div class="title"><span class="tips">*</span><span>到访日期</span></div>
              <div class="Data">
                <!-- <el-date-picker class="startTime_picker" v-model="BatchSchedule.VisitData" type="date" placeholder="请选择" format="YYYY-MM-DD" /> -->
                <el-select v-model="BatchSchedule.VisitData" class="VisitDate" placeholder="请选择" size="large">
                  <el-option v-for="item in Options" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </div>
            <!-- 当日测试开始时间 -->
            <div class="StartInput">
              <div class="title"><span class="tips">*</span><span>当日测试开始时间</span></div>
              <div class="Data">
                <el-time-select v-model="BatchSchedule.StartTime" start="00:00" step="00:15" end="23:30" placeholder="请选择" />
              </div>
            </div>
            <!-- 当日测试结束时间 -->
            <div class="EndInput">
              <div class="title"><span class="tips">*</span><span>当日测试结束时间</span></div>
              <div class="Data" :class="TimeX == false ? 'Error' : ''">
                <el-time-select v-model="BatchSchedule.EndTime" start="00:00" step="00:15" end="23:30" placeholder="请选择" />
                <div class="error_text"><span v-show="TimeX == false">结束时间不能早于开始时间</span></div>
              </div>
            </div>
            <!-- 到访间隔时间段 -->
            <div class="VisitInput" :class="TimeX == false ? 'Error' : ''">
              <div class="title"><span class="tips">*</span><span>到访间隔时间段</span></div>
              <div class="Data">
                <el-select v-model="BatchSchedule.IntervalTime" placeholder="请选择">
                  <el-option v-for="item in IntervalAll" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </div>
            </div>
          </div>
          <div class="Buttons">
            <button class="Cnacel" @click="ShowSchedule = false">取消</button>
            <button class="Confirm" @click="BatchSchedules">确定</button>
          </div>
        </div>
      </div>
      <!-- 批量确认 -->
      <div class="BatchConfirm" v-show="BatchConfirms">
        <div class="ConfirmBox">
          <div class="Title">
            已选中 <span class="text">{{ checkedArr.length }}条</span>志愿者信息，确认到访吗？
          </div>
          <div class="Content">
            <div class="img">
              <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" alt="" />
            </div>
            <div class="text">确认后可在表单取消确认</div>
          </div>
          <div class="Buttons">
            <button class="Cancel" @click="BatchConfirms = false">取消</button>
            <button class="Confirm" @click="BatchPass">确定</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineComponent, ref, reactive, nextTick, toRaw, toRefs, onMounted, watchEffect, watch, computed, onBeforeUnmount, isRef } from 'vue';
import { VXETable, VxeTableInstance } from 'vxe-table';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { message } from '@/utils/resetMessage';
import * as xlsx from 'xlsx';

import http from '@/utils/http';
import dayjs from 'dayjs';
import { replaceEmptyValues } from '@/utils/emptyArray';
import Date from '@/utils/CurrentDate';
// 导出的当前时间
let ExportDate = ref('');
// !表单空白处理
const tableData = computed(() => {
  return replaceEmptyValues(demo1.tableData);
});
const isExport = ref(false);
const xTable = ref();
//复选框数组
var selectedRows = reactive([]);
let null_reminder_export = ref(false); // ! 没有勾选志愿者数据导出
// ! 关闭提醒弹窗
const Closereminder = () => {
  null_reminder_export.value = false;
};
//API接收
const store = useStore();
const router = useRouter();
const demo1 = reactive({
  loading: false,
  tableData: [],
  Search: '',
});

// 切换状态 stage
const ProjectState = ref('');
ProjectState.value = []; //'第一次', '第二次', '第三次', '第四次', '第五次'

const D_value = ref([]); //'(D1)', '(D7)', '(D14)', '(D21)', '(D28)'
// 鼠标点击切换类
const ClickActive = ref(0);
// 批量排期弹窗 显示隐藏
const ShowSchedule = ref(false);
// 批量确认弹窗 显示隐藏
const BatchConfirms = ref(false);
// 排期日期
const BatchSchedule = reactive({
  VisitData: '',
  StartTime: '',
  EndTime: '',
  IntervalTime: '',
});
// 批量排期错误提示
const TimeX = ref(true);
// 点击表头的下拉箭头
const OperationBox = ref(null); //获取更多操作盒子的ref
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1);

//todo 项目的到访日期
let ProjectVisitDate = ref([]);

// 每一行的备注数据
let remark = ref('');
//到访日期数组
let Options = ref([]);
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
});
// 时间间隔
const IntervalAll = ref([
  { value: '15', label: '15分钟' },
  { value: '30', label: '30分钟' },
  { value: '45', label: '45分钟' },
  { value: '60', label: '1小时' },
  { value: '75', label: '1小时15分钟' },
  { value: '90', label: '1小时30分钟' },
  { value: '105', label: '1小时45分钟' },
  { value: '120', label: '2小时' },
  { value: '135', label: '2小时15分钟' },
  { value: '150', label: '2小时30分钟' },
  { value: '165', label: '2小时45分钟' },
  { value: '180', label: '3小时' },
  { value: '195', label: '3小时15分钟' },
  { value: '210', label: '3小时30分钟' },
  { value: '225', label: '3小时45分钟' },
  { value: '240', label: '4小时' },
  { value: '255', label: '4小时15分钟' },
  { value: '270', label: '4小时30分钟' },
  { value: '285', label: '4小时45分钟' },
  { value: '300', label: '5小时' },
]);
// 复选框数组
const checkedArr = ref([]);
// sort排序字段
let SORT = ref({ createdAt: -1 });
//切换/未开始/招募中/进行中/已停止/全部项目
watch(
  () => ClickActive.value,
  async (newValue) => {
    // const Project = {};
    console.log('newValue :>> ', newValue, D_value.value[newValue]);

    reqProjectList();
    getVisitDate();
  },
);
// 切换项目ID请求新项目
watch(
  () => store.state.Project.ProjectId,
  async (newvalue) => {
    ClickActive.value = 0;
    getPlan();
  },
);
// 监听批量排期弹窗
watch(
  () => ShowSchedule.value,
  (newValue) => {
    if (newValue == false) {
      BatchSchedule.VisitData = '';
      BatchSchedule.StartTime = '';
      BatchSchedule.EndTime = '';
      BatchSchedule.IntervalTime = '';
    }
  },
);
//监听 测试结束时间
watch(
  () => BatchSchedule.EndTime,
  (newValue) => {
    console.log('newValue :>> ', newValue);
    console.log('TimeX :>> ', TimeX.value);

    if (newValue > BatchSchedule.StartTime) {
      // console.log('true :>> ', true);
      TimeX.value = true;
    } else {
      // console.log('false :>> ', false);
      TimeX.value = false;
    }
  },
);

// 监听 批量排期弹窗
watch(
  () => ShowSchedule.value,
  (newValue, oldVale) => {
    console.log('newValue :>> ', newValue);
    if (newValue == false) {
      TimeX.value = true;
    }
  },
);

const formatSex = (value) => {
  if (value === '1') {
    return '男';
  }
  if (value === '0') {
    return '女';
  }
  return '';
};

const formatMultiSex = (values) => {
  if (values) {
    return values.map((val) => formatSex(val)).join(',');
  }
  return '';
};

const isActiveStatus = (row) => {
  const $table = xTable.value;
  return $table.isEditByRow(row);
};
// 每一行的编辑
const editRowEvent = (row) => {
  const $table = xTable.value;
  $table.setEditRow(row);
};
// 每一行的保存
const saveRowEvent = async (row) => {
  console.log(row);
  demo1.loading = true;
  let times = row.visit_time;
  let time = dayjs(row.visit_date).format('YYYY-MM-DD');
  console.log(time, times);

  const $table = xTable.value;
  if (times != null) {
    const res = await http.post('/schedule/update-schedule', {
      _id: row._id,
      visit_date: time,
      visit_time: times,
      remark: remark.value,
    });
    console.log('res :>> ', res);
    if (res.status == 0) {
      remark.value = '';
      reqProjectList();
      // demo1.loading = false;
      // VXETable.modal.message({ content: '保存成功！', status: 'success' });
      // $table.clearEdit();
      message({
        message: '编辑成功',
        ShowClose: true,
        type: 'success',
      });
      // 关闭编辑模式
      $table.clearEdit().then(() => {
        demo1.loading = true;
        setTimeout(() => {
          demo1.loading = false;
          // VXETable.modal.message({ content: '保存成功！', status: 'success' });
        }, 300);
      });
    }
  } else {
    demo1.loading = false;

    message({
      message: '到访时间不能为空',
      ShowClose: true,
      type: 'warning',
    });
  }

  // demo1.loading = false;
  // VXETable.modal.message({ content: '保存成功！', status: 'success' });
};
// 每一行的取消
const cancelRowEvent = (row) => {
  const $table = xTable.value;
  $table.clearEdit().then(() => {
    // 还原行数据
    $table.revertData(row);
  });
};
// 每一行的确认
const confirm = async (row) => {
  console.log('row :>> ', row);
  const res = await http.post('/schedule/batchConfirm-schedule', [row._id]);
  console.log(res);
  if (res.status == 0) {
    reqProjectList();
    message({
      message: '确认成功',
      grouping: true,
      type: 'success',
    });
  }
};
// 每一行的取消确认
const CancelConfirm = async (row) => {
  console.log(row);
  const res = await http.post('/schedule/cancelconfirm-schedule', {
    scheduleId: row._id,
  });

  if (res.status == 0) {
    reqProjectList();
    message({
      message: '取消确认成功',
      grouping: true,
      type: 'success',
    });
  }
};
//todo 切换项目请求数据
const reqProjectList = async () => {
  console.log(' D_value.value[ClickActive.value] :>> ', D_value.value[ClickActive.value]);
  const stage = D_value.value[ClickActive.value];

  demo1.loading = true;
  checkedArr.value.splice(0, checkedArr.value.length);
  const res = await http.post('/schedule/find-schedule', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: '',
    project: store.state.Project.ProjectId,
    currnetPlan: stage.toString(),
  });
  // console.log('res :>> ', res);
  // page5.totalResult = res.totalCount;

  if (res.status == 0) {
    demo1.loading = false;
    page5.totalResult = res.totalCount;
    demo1.tableData = res.schedules;
  }
  console.log('res :>> ', demo1.tableData);

  // 请求到访日期
  getVisitDate();
};

// 获取计划选项
const getPlan = async () => {
  ProjectState.value = []; //'第一次', '第二次', '第三次', '第四次', '第五次'
  D_value.value = []; //'(D1)', '(D7)', '(D14)', '(D21)', '(D28)'
  const res = await http.post('/test/getPlanOptions-test', {
    projectId: store.state.Project.ProjectId,
  });
  if (res.status == 0) {
    console.log('res :>> ', res);
    res.planOptions.forEach((item, index) => {
      ProjectState.value.push(item.label);
      D_value.value.push(item.value);
      console.log('D_value.value :>> ', D_value.value);
      reqProjectList();
    });
  }
};

// 获取项目的到访日期
const getVisitDate = async () => {
  // 重置数据
  ProjectVisitDate.value = [];

  const res = await http.post('/test/getTimeOptions-test', {
    projectId: store.state.Project.ProjectId,
  });
  console.log('获取项目的到访日期返回 :>> ', res);
  if (res.status == 0) {
    ProjectVisitDate.value = res.times;
  }
  // console.log('项目的到访日期是:', ProjectVisitDate.value, ProjectVisitDate.value[ClickActive.value]);
  // ClickActive
  // console.log('获取项目的到访日期返÷回 :>> ', ProjectVisitDate.value[ClickActive.value].length);
  Options.value = [];
  ProjectVisitDate.value[ClickActive.value].forEach((item, index) => {
    let obj = {};

    obj.value = item;
    obj.label = item;
    Options.value.push(obj);
  });
  console.log('当前下拉数据:', Options.value);
};
// 全部编辑
const BatchEdit = () => {
  const $table = xTable.value;
  console.log($table);
  let data = $table.getData();
  data.forEach((item) => {
    $table.setEditCell(item, 'visit_date');
  });
};
const JudgeLength = (val) => {
  if (checkedArr.value.length != 0) {
    if (val == '批量排期') {
      ShowSchedule.value = true;
    } else {
      BatchConfirms.value = true;
    }
  } else {
    message({
      message: '请先选择数据',
      grouping: true,
      type: 'error',
    });
  }
};
// 批量确认
const BatchPass = async () => {
  if (checkedArr.value.length == 0) {
    message({
      message: '请先勾选数据,再进行操作!',
      grouping: true,
      type: 'error',
    });
  } else {
    const res = await http.post('/schedule/batchConfirm-schedule', checkedArr.value);

    reqProjectList();
    message({
      message: '批量确认成功',
      grouping: true,
      type: 'success',
    });
  }
  BatchConfirms.value = false;
};
// 批量排期
const BatchSchedules = async () => {
  if (
    TimeX.value == false ||
    BatchSchedule.VisitData == '' ||
    BatchSchedule.EndTime == '' ||
    BatchSchedule.IntervalTime == '' ||
    BatchSchedule.StartTime == ''
  ) {
    message({
      message: '请校验时间',
      grouping: true,
      type: 'error',
    });
  } else {
    if (checkedArr.value.length != 0) {
      console.log(D_value.value[ClickActive.value]);
      console.log(store.state.Project.ProjectId);
      console.log(BatchSchedule.StartTime);
      console.log(BatchSchedule.EndTime);
      let visit = dayjs(BatchSchedule.VisitData).format('YYYY-MM-DD');
      console.log(visit);
      const res = await http.post('/schedule/batchSchedule-schedule', {
        visit_date: visit,
        start_time: BatchSchedule.StartTime,
        end_time: BatchSchedule.EndTime,
        scheduleList: checkedArr.value,
        // project: store.state.Project.ProjectId,
        time_interval: BatchSchedule.IntervalTime,
        currnetPlan: D_value.value[ClickActive.value].toString(),
        project: store.state.Project.ProjectId,
        // currnetPlan计划
      });
      console.log(res);
      if (res.status == 0) {
        ShowSchedule.value = false;
        message({
          message: '批量排期成功',
          grouping: true,
          type: 'success',
        });
        reqProjectList();
      } else if (res.status == 1) {
        ShowSchedule.value = false;
        message({
          message: '有效时间间隔小于选定的到访间隔时间段',
          grouping: true,
          type: 'warning',
        });
        reqProjectList();
      }
    } else {
      ShowSchedule.value = false;
      message({
        message: '请先勾选数据,再进行操作!',
        grouping: true,
        type: 'error',
      });
    }
  }
};
// 复选框点击事件
const selectChangeEvent1 = (row) => {
  console.log('点击的id:', row.row._id);

  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  selectedRows = records;
  // console.log(checked ? '勾选事件' : '取消事件', records);

  if (checkedArr.value.indexOf(row.row._id) == -1) {
    checkedArr.value.push(row.row._id);
  } else {
    let index = checkedArr.value.indexOf(row.row._id);
    checkedArr.value.splice(index, 1);
  }
  console.log('最新:', checkedArr.value);
};
// 复选框全选时间
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  console.log(checked ? '所有勾选事件' : '所有取消事件', records);
  selectedRows = records;
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id);
      }
    }
    console.log('最新:', checkedArr.value);
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = [];

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id);
    });

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item);
    });
    console.log('最新:', checkedArr.value);
  }
};
const CheckId = reactive([]);
// 监听勾选行
watch(
  () => selectedRows,
  (newvalue) => {
    newvalue.forEach((item) => {
      CheckId.value.push(item._id);
    });
  },
);
// 模糊搜索
const searchEvent2 = async () => {
  if (demo1.Search != '') {
    const res = await http.post('/schedule/find-schedule', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.Search,
      project: store.state.Project.ProjectId,
      currnetPlan: D_value.value[ClickActive.value].toString(),
    });
    console.log('res :>> ', res);
    demo1.tableData = res.schedules;
  }
};
// 重置模糊搜索
const ResetTable = () => {
  demo1.Search = '';
  reqProjectList();
};
// 翻页操作
const pageChange = async () => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  // for (let i = 0; i < records.length; i++) {
  //   // console.log('object :>> ', checkedArr.indexOf(records[i]._id));
  //   if (checkedArr.value.indexOf(records[i]._id) == -1) {
  //     checkedArr.value.push(records[i]._id);
  //   }
  // }

  demo1.loading = true;
  checkedArr.value.splice(0, checkedArr.value.length);
  const res = await http.post('/schedule/find-schedule', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: '',
    project: store.state.Project.ProjectId,
    currnetPlan: D_value.value[ClickActive.value].toString(),
  });
  // console.log('res :>> ', res);
  // page5.totalResult = res.totalCount;

  if (res.status == 0) {
    demo1.loading = false;
    page5.totalResult = res.totalCount;
    demo1.tableData = res.schedules;
  }
};
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false;
};
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  const table = xTable.value;
  const FixedList = table.getColumns();

  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index;
    }
  });

  showIdOptions.value = false;
  showIdOptions.value = true;
  // 设置位置
  const Event = event;
  OperationBox.value.style.left = event.clientX - 160 + 'px';
};

// 取消固定列
const toggleFixedColumns = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false;
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
// 固定列
const toggleFixedColumn = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false;
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
const isShowExport = () => {
  if (checkedArr.value.length >= 1) {
    null_reminder_export.value = false;
    isExport.value = true;
  } else {
    null_reminder_export.value = true;
  }
};

// 导出
const exportDataEvent = async () => {
  const res = await http.post('/upload/export-schedule', checkedArr.value);
  if (res.status == 0) {
    isExport.value = false;
    message({
      message: '导出成功',
      type: 'success',
      ShowClose: true,
    });
    // xlsx.writeFileXLSX(res.xlsxData, `${res.xlsxName}.xlsx`);
    xlsx.writeFileXLSX(res.xlsxData, '志愿者排期表 ' + store.state.Project.ProjectNumber + ExportDate.value + ' .xlsx');
  }
  console.log('res :>> ', res);
};
// More -->升序/降序
const AscendingSort = async (index, type) => {
  let gender = '';
  let age = 0;

  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  console.log('tableColumns :>> ', tableColumns);
  let ColumnId = tableColumns[index].field;
  console.log(ColumnId, 'ColumnId');
  let sorts = {};

  if (ColumnId == '_id') {
    let recruitRecord = {};
    recruitRecord.volun_id;
    ColumnId = 'volunteer._id';
  } else if (ColumnId == 'volun_id') {
    ColumnId = 'recruitRecord.volun_id';
  } else if (ColumnId == 'volun_name') {
    ColumnId = 'volunteer.volun_name';
  } else if (ColumnId == 'volun_phone') {
    ColumnId = 'volunteer.volun_phone';
  } else {
  }
  if (type == 'Asc') {
    sorts[ColumnId] = 1;
  } else {
    sorts[ColumnId] = -1;
  }

  SORT.value = sorts;
  console.log(SORT.value, 'SORT.value');
  const res = await http.post('/schedule/find-schedule', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    project: store.state.Project.ProjectId,
    currnetPlan: D_value.value[ClickActive.value].toString(),
  });
  if (res.status == 0) {
    demo1.loading = false;
    page5.totalResult = res.totalCount;
    demo1.tableData = res.schedules;
  }
};
// tip tootip提示方法
const tooltipConfig = reactive({
  showAll: true,
  enterable: true,
  contentMethod: ({ type, column, row, items, _columnIndex }) => {
    const { field } = column;

    // column 判断 column.title 是否为'操作'
    // 重写默认的提示内容
    if (column.title == '操作') {
      return (tooltipConfig.showAll = false);
    } else {
      tooltipConfig.showAll = true;
    }
    // 其余的单元格使用默认行为

    return null;
  },
});
onMounted(() => {
  getPlan();
  ExportDate.value = Date(); //获取当前时间
  // reqProjectList();
  store.commit('Set_CurrentProjectName', '志愿者排期表');

  // 点击下拉
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
    }
  });
});
//
const formatterRemark = ({ cellValue }) => {
  //console.log('cellValue :>> ', cellValue);
  return cellValue === undefined || cellValue === '' || cellValue === null ? '——' : cellValue;
};
// // 处理出生日期的时间戳
// const birthday = computed(() => (e) => {
//   if (e) {
//     return dayjs(e).format('YYYY-MM-DD');
//   }
// });
</script>
<style lang="less" scoped>
@import url('@/style/views/VolunteerManagement/VolunteerSchedule.less');
</style>
