
const state={
    Click_projectdata: {},  //！样品流转点击的数据
    project: [],  //！样品流下拉框标题
    project_data: [], //！样品流下拉数据
    project_id:null,  // ! 选择框选择的数据
    isVolunteer_tabel:false,  //志愿者名单


}

const mutations={
    Set_Click_projectdata(state,newValue){
        state.Click_projectdata=newValue
    },
    Set_project(state,newValue){
        state.project=newValue
    },
    Set_project_data(state,newValue){
        state.project_data=newValue
    },
    Set_project_id(state,newValue){
        state.project_id=newValue
    },
    Set_isVolunteer_tabel(state,newValue){
        state.isVolunteer_tabel=newValue
    },
   

}


export default{
    state,
    mutations
}