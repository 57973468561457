
const state={
   CurrentDetails:{}
}

const mutations={
    set_CurrentDetails(state,newValue){
        state.CurrentDetails=newValue
    },
 
}


//导出
export default {
    state,
    mutations
}