<template>
  <div id="QuestionnaireList">
    <!-- <CreatedVolunteer v-if="CreatedVolunteerShow"></CreatedVolunteer> -->
    <CreatedVolunteer v-model="is_dialogShow" v-if="is_dialogShow"></CreatedVolunteer>
    <div class="VolunteerBox">
      <!-- 表体 -->
      <div class="VolunteerTable">
        <div class="vxetable">
          <vxe-toolbar>
            <template #buttons>
              <vxe-input
                class="Search"
                v-model="demo1.filterName"
                type="search"
                placeholder="请输入问卷名称或创建人姓名进行搜索"
                @Blur="searchEvent2"></vxe-input>
              <vxe-button class="Reset" @click="reset"><span class="reset-icon">重置</span></vxe-button>
              <!-- 新建 -->
              <div class="CreateProject" @click.stop="New_voluntee_box = !New_voluntee_box">
                <button>
                  <img src="https://newstore.vynior.com/cems_web/add1.png" />
                  <span>新建问卷</span>
                </button>
              </div>
              <div class="batch_operation_box" id="batch_operation_box" v-show="New_voluntee_box">
                <div class="item_box" @click="Blank_questionnaire">
                  <img class="img1" src="https://newstore.vynior.com/%E7%BB%84111136%402x.png" alt="" />
                  <div class="item_one_text">空白问卷</div>
                </div>
                <!-- <div class="item_box" @click="New_voluntee_box = !New_voluntee_box"> -->
                <div class="item_box item_boxs">
                  <img src="https://newstore.vynior.com/%E5%85%A8%E8%84%B8%E5%88%86%E6%9E%90%4022222x.png" alt="" />
                  <div class="item_one_text" style="margin-top: 0px">模板创建</div>
                </div>
                <div class="item_box" @click="Text_import">
                  <img src="https://newstore.vynior.com/%E8%A7%86%E9%A2%91%402x.png" alt="" />
                  <div class="item_one_text" style="margin-top: 0px">文本导入</div>
                </div>
              </div>
            </template>
          </vxe-toolbar>
          <!--             :tooltip-config="{ showAll: true }"
 -->
          <vxe-table
            border
            stripe
            ref="xTable"
            height="672"
            show-overflow
            row-id="_id"
            :loading-config="demo1.loading"
            :data="demo1.tableData"
            :column-config="{ resizable: true, useKey: 'id' }"
            :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
            :checkbox-config="{ reserve: true }"
            :loading="demo1.loading"
            @header-cell-dragend="handleHeaderDragend">
            <vxe-column field="naire_title" type="html" title="名称" width="360" :fixed="demo1.colFixeds.col1" min-width="300">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="ID-header" v-if="column.title === '名称'">
                  <span class="name">名称</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('名称', $event)"></i></span>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="_id" type="html" title="ID" width="180" :fixed="demo1.colFixeds.col2" min-width="160">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="name-header" v-if="column.title === 'ID'">
                  <span class="name">ID</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('ID', $event)"></i></span>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="status" type="html" title="状态" width="180" :fixed="demo1.colFixeds.col3" min-width="160">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Role-header" v-if="column.title === '状态'">
                  <span class="name">状态</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('状态', $event)"></i></span>
                </div>
              </template>
              <template #default="{ row }">
                <span
                  :class="
                    row.status === '收集中'
                      ? 'sample_status_green'
                      : row.status === '未发布'
                      ? 'sample_status_yellow'
                      : row.status === '已暂停'
                      ? 'sample_status_blue'
                      : ''
                  "
                  >{{ row.status }}</span
                >
              </template>
            </vxe-column>
            <vxe-column field="recyclNumber" type="html" title="回收统计" width="180" :fixed="demo1.colFixeds.col4" min-width="160">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '回收统计'">
                  <span class="name">回收统计</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('回收统计', $event)"></i></span>
                </div>
              </template>
              <template #default="{ row }">
                <!-- <span :class="row.sample_status === '可用' ? 'sample_status_green' : row.sample_status === '已发放' ? 'sample_status_yellow' : row.sample_status === '已回收' ? 'sample_status_gray' : ''" >{{ row.sample_status }}</span> -->
                <span>{{ row.recyclNumber }}份</span>
              </template>
            </vxe-column>
            <vxe-column field="createdAt" type="html" title="创建时间" width="180" :fixed="demo1.colFixeds.col5" min-width="160">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Address-header" v-if="column.title === '创建时间'">
                  <span class="name">创建时间</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('创建时间', $event)"></i></span>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="createUser" type="html" title="创建人" width="180" :fixed="demo1.colFixeds.col7" min-width="160">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Address-header" v-if="column.title === '创建人'">
                  <span class="name">创建人</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('创建人', $event)"></i></span>
                </div>
              </template>
            </vxe-column>
            <vxe-column title="操作" width="366" :fixed="demo1.colFixeds.col9" :resizable="false">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Operation-header" v-if="column.title === '操作'">
                  <span class="name">操作</span>
                </div>
              </template>
              <template #default="{ row }">
                <!-- !停止 -->
                <div style="display: flex" class="more_item">
                  <el-tooltip class="box-item" effect="dark" content="停止" placement="top">
                    <div @click="StopVolun(row, '已暂停')" v-if="row.status === '收集中'" class="item">
                      <img src="https://newstore.vynior.com/%E9%A1%B5%E9%9D%A2-1%402x.png" alt="" />停止
                    </div>
                  </el-tooltip>

                  <el-tooltip class="box-item" effect="dark" content="发布" placement="top">
                    <div @click="Derived_run(row)" v-if="row.status === '未发布' || row.status === '已暂停'" class="item">
                      <img src="https://newstore.vynior.com/%E9%A1%B5%E9%9D%A2-11111%402x.png" alt="" />发布
                    </div>
                  </el-tooltip>

                  <!-- !编辑 -->
                  <el-tooltip class="box-item" effect="dark" content="编辑" placement="top">
                    <div class="item2" @click="editQuestionChange(row)">
                      <img src="https://newstore.vynior.com/%E7%BC%96%E8%BE%91%402x.png" alt="" />编辑
                    </div>
                  </el-tooltip>

                  <!-- !分析 -->
                  <el-tooltip class="box-item" effect="dark" content="统计&分析" placement="top">
                    <div v-if="row.status !== '未发布'" class="item1" @click="StatisticChange(row)">
                      <img src="https://newstore.vynior.com/%E9%A1%B5%E9%9D%A2-11%402x.png" alt="" />统计&分析
                    </div>
                  </el-tooltip>

                  <div v-if="row.status == '未发布'" class="item1"></div>
                  <!-- todo -->
                  <span class="More" id="CMoreOption" @click="ClickMethods(row, $event)">···</span>
                </div>
              </template>
            </vxe-column>
            <template #empty>
              <span style="color: red">
                <!-- <img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif" /> -->
                <p>没有更多数据了！</p>
              </span>
            </template>
          </vxe-table>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
              </ul>
            </div>
          </div>
          <!--   todo每一行的 ... -->
          <div class="Options" id="CMoreOption" ref="CMoreOptions" v-show="ClickMoreOptions">
            <div class="Copy" @click="preview(OptionsData)"><img src="https://newstore.vynior.com/cems_web/browse.png" alt="" />预览</div>
            <div class="Copy" style="border-top: 1px solid #e5e5e5" @click="isRename = !isRename">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20111399.png" alt="" />重命名
            </div>
            <div class="Copy" @click="shareQuestionChange">
              <img src="https://newstore.vynior.com/cems_web/%E5%88%86%E4%BA%AB.png" alt="" />分享问卷
            </div>
            <div class="Copy" @click="Copyquestion(OptionsData)">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20111398.png" alt="" />复制问卷
            </div>
            <div class="Copy" @click="isshowOneExport = true">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20111400.png" alt="" />导出问卷(word)
            </div>
            <div class="Delete" style="border-top: 1px solid #e5e5e5" @click="DeleteVolun(OptionsData)">
              <img src="https://newstore.vynior.com/cems_web/%E5%88%A0%E9%99%A4.png" alt="" />删除问卷
            </div>
          </div>
          <!-- 导出一个 -->
          <div class="Derived_volunteer" v-if="isshowOneExport">
            <div class="Derived_volunteer_box" v-if="isshowOneExport">
              <div v-if="isshowOneExport">
                <div class="Derived_title">
                  确定导出<span class="Derived_name">{{ OptionsData.naire_title }}</span
                  >的问卷信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在Word中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isshowOneExport = false">取消</div>
                  <div class="ok" @click="ExportFile">确认</div>
                </div>
              </div>
            </div>
          </div>
          <p v-show="demo1.tableData.length != 0">
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">共{{ page5.totalResult }}条</div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
    </div>
    <!-- h5预览组件 -->
    <div class="Preview" v-if="isH5Preview">
      <div class="Preview_img">
        <img class="set_shareBut" src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%20161%402x.png" alt="" />
        <img
          v-show="isH5Preview"
          @click="isH5Preview = !isH5Preview"
          class="set_pre"
          src="https://newstore.vynior.com/cems_web/%E5%85%B3%E9%97%AD%E9%A2%84%E8%A7%88222.png"
          alt="" />
      </div>
      <previewQuestion :questionId="questionId" v-if="isH5Preview"></previewQuestion>
    </div>
    <!--   导出 -->
    <div class="Derived_volunteer" v-if="isDerived">
      <div class="Derived_volunteer_box">
        <!-- 导出一个 -->
        <div>
          <div class="Derived_title">
            确定导出<span class="Derived_name">{{ selectedRows[0].sample_name == ' ' ? '无名字' : selectedRows[0].sample_name }}</span
            >的样品信息吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">导出后可在excel表格中进行查看</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived">取消</div>
            <div class="ok" @click="isshowDerivedtrue">确认</div>
          </div>
        </div>
      </div>
    </div>
    <!--   删除 -->
    <div class="Derived_volunteer_delect" v-if="isDerived_delect">
      <div class="Derived_volunteer_box">
        <div v-if="oneDerivedData">
          <div class="Derived_title">
            确认删除&nbsp;&nbsp;<span class="Derived_name"> 《{{ oneDerivedData.naire_title }}》 </span>吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived_delect">取消</div>
            <div class="ok" @click="oneDeleteVolun">删除</div>
          </div>
        </div>
      </div>
    </div>
    <!-- ! 暂停发布 -->
    <div class="Derived_volunteer_stop" v-if="isDerived_stop">
      <div class="Derived_volunteer_box">
        <div>
          <div class="Derived_title">
            确认暂停发布<span class="Derived_name"> 《{{ stop_data.data.naire_title }}》 </span>吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">暂停后该问卷所有数据收集将被停止，答题链接将无法收集数据</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived_Stop">取消</div>
            <div class="ok" @click="oneStopVolun">确认</div>
          </div>
        </div>
      </div>
    </div>
    <!-- !发布 -->
    <div class="Derived_volunteer_stop" v-if="isDerived_run">
      <div class="Derived_volunteer_box">
        <div>
          <div class="Derived_title">
            确认发布问卷<span class="Derived_name"> 《{{ oneDerivedData.naire_title }}》 </span>吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">发布后该问卷答题链接可收集数据</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isDerived_run = !isDerived_run">取消</div>
            <div class="ok" @click="Publish('收集中')">确认</div>
          </div>
        </div>
      </div>
    </div>
    <!-- ! 重命名 -->
    <div class="Derived_volunteer_stop" v-if="isRename">
      <div class="Derived_volunteer_box">
        <div>
          <div class="Derived_title">重命名</div>
          <div class="Derived_body">
            <vxe-input class="Search" v-model="demo1.rename" placeholder="请输入"></vxe-input>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="unisRename">取消</div>
            <div class="ok" @click="Rename">确认</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 绑定问卷 -->
    <div class="bindingQues" v-if="quesDialog">
      <el-dialog v-model="quesDialog" title="填写问卷项目信息" :close-on-click-modal="true" align-center="true" :show-close="true">
        <template #default>
          <div class="bindingQues">
            <div class="binding_title">新建问卷之前,请填写问卷项目信息</div>
            <div class="binding_select">
              <div class="select_text"><span class="col">*</span>项目编号及名称</div>
              <el-select v-model="projectClick" filterable class="m-2" @change="ProjectClickChange" placeholder="输入以搜索..." size="large">
                <el-option v-for="item in projectSelectArr" :key="item._id" :label="item.label" :value="item._id" />
              </el-select>
            </div>
            <div class="Derived_but">
              <el-button class="cancel" @click="quesDialog = false">取消</el-button>
              <el-button class="sure" type="primary" @click="getQuesChange"> 确认</el-button>
            </div>
          </div>
        </template>
      </el-dialog>
    </div>
    <!-- 不可编辑提示 -->
    <div class="noCreatQues" v-if="noCreatDialog">
      <el-dialog v-model="noCreatDialog" title="提示" :close-on-click-modal="true" :align-center="true" :show-close="true">
        <template #default>
          <div class="noCreatQues_box">
            <div class="box_img">
              <img class="box_img" src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x%20%281%29.png" alt="" />
            </div>
            <div class="box_h">不可编辑</div>
            <div class="hox_span1">此问卷发布后不可编辑，</div>
            <div class="hox_span1">编辑问卷将导致正在填写此问卷的用户无法提交</div>
          </div>
        </template>
      </el-dialog>
    </div>
    <!-- 不可分享 -->
    <div class="noCreatQues" v-if="noShareDialog">
      <el-dialog v-model="noShareDialog" title="提示" :close-on-click-modal="true" :align-center="true" :show-close="true">
        <template #default>
          <div class="noCreatQues_box">
            <div class="box_img">
              <img class="box_img" src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x%20%281%29.png" alt="" />
            </div>
            <div class="box_h">不可分享</div>
            <div class="hox_span1">此问卷已被暂停，请运行后再分享问卷！</div>
          </div>
        </template>
      </el-dialog>
    </div>
  </div>
  <div>
    <!-- 问卷导出world -->
    <div ref="exportContent" class="exportContent" v-show="showHTML">
      <!-- 问卷标题 -->
      <div class="Title">
        <span>{{ naire_title }}</span>
      </div>
      <!-- 提示语 -->
      <div class="explain">
        <span>{{ naire_explain }}</span>
      </div>
      <!-- 分割 -->
      <div class="listLine">&nbsp;&nbsp;</div>
      <!-- 问卷内容 -->
      <div class="naireContent">
        <!-- type -->
        <div class="ContestListTitle" v-for="(item, index) in questionTitleArr" :key="index">
          {{ index + 1 }}. {{ item }}
          <!-- 问题标题 -->
          [{{ questionTypeArr[index] }}]
          <!-- 单选 -->
          <div class="oneOption" v-if="questionTypeArr[index] == '单选'">
            <div class="require" v-if="requiredArr[index] == true">【必选】</div>
            <div class="label" v-for="(item1, index1) in questionLabelArr[index]" :key="index1">
              ○&nbsp;{{ item1 }}
              <div style="width: 300px; height: 200px; object-fit: contain; overflow: hidden">
                <img :src="base64Array[index] ? base64Array[index][index1] : ''" alt="" />
                <!-- <div v-for="(item2, index2) in base64Array" :key="index2">
                  <img :src="item2" alt="" />
                </div> -->
              </div>
              <div class="label">{{ ImgTextArr[index][index1] }}</div>
            </div>
          </div>
          <!-- 多选 -->
          <div class="moreOption" v-if="questionTypeArr[index] == '多选'">
            <div class="require" v-if="requiredArr[index] == true">【必选】</div>
            <div class="label" v-for="(item1, index1) in questionLabelArr[index]" :key="index1">
              □&nbsp;{{ item1 }}
              <div style="width: 300px; height: 200px; object-fit: contain; overflow: hidden">
                <img :src="base64Array[index] ? base64Array[index][index1] : ''" alt="" />
                <!-- <div v-for="(item2, index2) in base64Array1" :key="index2">
                  <img :src="item2" alt="" />
                </div> -->
              </div>
              <div class="label">{{ ImgTextArr[index][index1] }}</div>
            </div>
          </div>
          <!-- 下拉 -->
          <div class="down" v-if="questionTypeArr[index] == '下拉'">
            <div class="require" v-if="requiredArr[index] == true">【必选】</div>
            <div class="label" v-for="(item1, index1) in questionLabelArr[index]" :key="index1">{{ item1 }}</div>
          </div>
          <!-- 单项填空 -->
          <div class="oneBlank" v-if="questionTypeArr[index] == '单项填空'">
            <div class="require" v-if="requiredArr[index] == true">【必选】</div>
            &nbsp;&nbsp;________________________
          </div>
          <!-- 多项填空 -->
          <div class="moreBlank" v-if="questionTypeArr[index] == '多项填空'">
            <div class="require" v-if="requiredArr[index] == true">【必选】</div>
            <div class="label" v-for="(item1, index1) in questionLabelArr[index]" :key="index1">
              &nbsp;{{ item1 }}
              <div>&nbsp;&nbsp;________________________</div>
            </div>
          </div>
          <!-- 矩阵单选 -->
          <div class="boxOne" v-if="questionTypeArr[index] == '矩阵单选'">
            <div class="require" v-if="requiredArr[index] == true">【必选】</div>
            <!-- <div class="label" v-for="(item1, index1) in valueTitleArr[index]" :key="index1">{{ item1 }}</div> -->
            {{ valueTitleArr[index].toString().replace(/,/g, ' ') }}
            <div class="label" v-for="(item1, index1) in labelTitleArr[index]" :key="index1">{{ item1 }}</div>
          </div>
          <!-- 矩阵多选 -->
          <div class="boxMore" v-if="questionTypeArr[index] == '矩阵多选'">
            <div class="require" v-if="requiredArr[index] == true">【必选】</div>
            <!-- <div class="label" v-for="(item1, index1) in valueTitleArr[index]" :key="index1">{{ item1 }}</div> -->
            {{ valueTitleArr[index].toString().replace(/,/g, ' ') }}
            <div class="label" v-for="(item1, index1) in labelTitleArr[index]" :key="index1">{{ item1 }}</div>
          </div>
          <!-- 矩阵量表 -->
          <div class="boxMutip" v-if="questionTypeArr[index] == '矩阵量表'">
            <div class="require" v-if="requiredArr[index] == true">【必选】</div>
            <!-- <div class="label" v-for="(item1, index1) in maxOptionArr[index]" :key="index1">
              {{
                item1 == 0
                  ? '满意度'
                  : item1 == 1
                  ? '认同度'
                  : item1 == 2
                  ? '重要度'
                  : item1 == 3
                  ? '意愿度'
                  : item1 == 4
                  ? '符合度'
                  : item1 == 5
                  ? '感知度'
                  : ''
              }}
            </div> -->
            {{ startNumArr[index] }}
            ~
            {{ startNumArr[index] + echartsDimensionArr[index] + 1 }}
            <!-- <div class="label" v-for="(item1, index1) in valueTitleArr[index]" :key="index1">{{ item1 }}</div> -->
            <div class="label" v-for="(item1, index1) in labelTitleArr[index]" :key="index1">{{ item1 }}</div>
          </div>
          <!-- 分割 -->
          <div class="listLine">&nbsp;&nbsp;</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import previewQuestion from '@/components/QuestionnaireManagement/previewQuestion.vue';
import { onBeforeUnmount, onMounted, provide, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
import CreatedVolunteer from '@/components/SampleManagement/CreateSample.vue';
// import $ from 'jquery';

const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

//API接收
const store = useStore();
const router = useRouter();
const xTable = ref();
// 不可编辑提示
const noCreatDialog = ref(false);
// 不可分享提示
const noShareDialog = ref(false);
const showHTML = ref(false);
// 点击编辑问卷
const editQuestionChange = (row) => {
  console.log(row, 'row');
  if (row.status != '未发布') {
    noCreatDialog.value = true;
    return;
  }
  router.push({
    name: 'EditQuestion',
    params: {
      _id: row._id,
    },
  });
};
// }点击统计分析
const StatisticChange = (row) => {
  console.log(row);
  router.push({
    name: 'QuestionReport',
    params: {
      _id: row._id,
      // title: row.naire_title,
    },
  });
};
// 绑定问卷对话框
const quesDialog = ref(false);
// 可供绑定问卷的项目
const projectSelectArr = ref([]);
// 绑定项目选中的值
const projectClick = ref('');
// 获取可供绑定问卷项目的事件
const projectSelectArrChange = async () => {
  const res = await http.post('/project/findProjectOptions-project');
  console.log(res, '可供绑定问卷的项目');
  projectSelectArr.value = res.projectOptions;
};
// 选中绑定项目触发的事件
const ProjectClickChange = (val) => {
  console.log(val, '选中绑定项目触发的事件');
};
// 点击绑定项目确定
const getQuesChange = () => {
  if (projectClick.value.slice('').length != 0) {
    router.push({
      name: quesRoute.value,
      params: {
        _id: projectClick.value,
      },
    });
  } else {
    message({
      showClose: true,
      message: '请选择绑定的项目',
      type: 'error',
    });
  }
};

// table组件数据
let totalCount = ref(0);
const SetTableHeader = ref(false);
//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [],
  filterName: '',
  loading: true,
  list: [],
  rename: '',
});
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 15,
  totalResult: 0,
});
let SORT = ref({ createdAt: -1 }); // sort排序字段
// 点击表头的下拉箭头
const OperationBox = ref(null); //获取更多操作盒子的ref
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1);
// todo
//  !  !!!每一行的 ... 点击
let ClickMoreOptions = ref(false);
const CMoreOptions = ref(null); //获取MoreOptions盒子的ref
let OptionsData = ref();
let EventSearch = ref(false); // 搜索
let CreatedVolunteerShow = ref(false); // 设置新建志愿者组件显示隐藏
let New_voluntee_box = ref(false);
let isDerived = ref(false); // ! 导出志愿信息弹框
let isDerived_delect = ref(false); // ! ！！！ 删除弹框
let isDerived_stop = ref(false); //!　停止弹窗
let isDerived_run = ref(false); //! 收集数据
let isRename = ref(false); //重命名弹窗显示
let isH5Preview = ref(false);
// ! 暂停数据
let stop_data = reactive({
  data: {},
  state: '',
});
let Volunteerdetails = ref(false); // !查看志愿者详情
let information = ref({
  _id: '',
  naire_title: '',
  status: '',
  recyclNumber: '',
  createdAt: '',
  createUser: '',
}); // !点击的志愿者信息
// 点击分享问卷
const shareQuestionChange = () => {
  // if(OptionsData.value.status)
  console.log(OptionsData.value);
  if (OptionsData.value.status == '已暂停') {
    noShareDialog.value = true;
    return;
  } else if (OptionsData.value.status == '未发布') {
    message({
      showClose: true,
      type: 'error',
      message: '请发布问卷',
    });
    return;
  }
  router.push({
    name: 'ShareQuestion',
    params: {
      _id: OptionsData.value._id,
    },
  });
};
let Volunteereditor = ref(true); // !查看志愿者详情编辑
const oneDerivedData = ref('');
//导出弹窗显示隐藏
let dialogShow = ref(false);
let uploadPercentage = ref(0);
const fileLists = ref([]);
// todo
// ! !!!点击...
const ClickMethods = (row, event) => {
  console.log(row);
  OptionsData.value = row;
  // console.log(DelectData.value);
  ClickMoreOptions.value = false;
  ClickMoreOptions.value = true;

  // console.log('event :>> ', event, CMoreOptions.value);

  CMoreOptions.value.style.left = event.clientX - 120 + 'px';
  CMoreOptions.value.style.top = event.clientY + 30 + 'px';
  // console.log('event :>> ', ClickMoreOptions.value);
};
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params;
  console.log(columns, '表头的顺序');
  // // 打印排序后的表头列数组对象
};
//!  !!!获取全部问卷数据
const reqProjectList = async () => {
  try {
    const res = await http.post('/questionnaire/fetchList-questionnaire', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: { createdAt: -1 },
      content: '',
    });
    page5.totalResult = res.totalCount;
    demo1.tableData = res.naires;
    if (res.status == 0) {
      demo1.loading = false;
    }
  } catch (error) {
    console.log(error);
  }
};
// !!!重置按钮
const reset = () => {
  demo1.filterName = '';
  page5.currentPage = 1;
  page5.pageSize = 15;
  reqProjectList();
  const $table = xTable.value;
  $table.clearCheckboxRow();
};
// !  !!!发布按钮
const Publish = async (state) => {
  try {
    await http.post('/questionnaire/updateStatus-questionnaire', {
      naireId: oneDerivedData.value._id,
      status: state,
    });
    reqProjectList();
  } catch (error) {
    console.log(error);
  }
  isDerived_run.value = !isDerived_run.value;
};
//
// ! 发布弹窗
const Derived_run = (row) => {
  isDerived_run.value = !isDerived_run.value;
  oneDerivedData.value = row;
};
// ! 退出按钮
const unSelect = () => {
  Show_transform.value = false;
};
let informationsAddress = ref();
// ! 关闭提醒弹窗
const Closereminder = () => {
  null_reminder_export.value = false;
  null_reminder_delect.value = false;
};
const hiddenOperationBox = () => {
  showIdOptions.value = false;
  //console.log('点击点击 :>> ');
};
// More -->升序/降序
const AscendingSort = async (index, type) => {
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  const ColumnId = tableColumns[index].field;
  console.log(ColumnId, 'ColumnId');
  const sorts = {};
  if (type == 'Asc') {
    sorts[ColumnId] = 1;
  } else {
    sorts[ColumnId] = -1;
  }

  SORT.value = sorts;
  console.log(SORT.value, 'SORT.value');
  const res = await http.post('/questionnaire/fetchList-questionnaire', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
  });
  demo1.tableData = res.naires;
  page5.totalResult = res.totalCount;
};
//  More -->固定/取消固定
const toggleFixedColumn = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false;
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
const toggleFixedColumns = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false;
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
// 分页--->跳转页面显示数据处理
const pageChange = async () => {
  // 判断是否是高级搜索模式
  // 不是高级搜索就是普通的数据
  const res = await http.post('/questionnaire/fetchList-questionnaire', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
  });
  demo1.tableData = res.naires;
  page5.totalResult = res.totalCount;
};
const handlePreviews = (file) => {
  console.log(file, 'handlePreviews');
};
const uploadFileSuccess = (res) => {
  console.log('上传成功');
  console.log(res);
};

const handleRemoves = (file, fileList) => {
  console.log(file, fileList, 'handleRemoves');
};
// 每一行的查看详情
const ShowDetails = (row) => {
  console.log('固定信息2:', row);
  id.value = row._id;
  console.log(row);
  information.value = row;
  Volunteerdetails.value = !Volunteerdetails.value;
  Volunteereditor.value = true;
};
console.log(information.value, '123');
// ! !!! 复制
const Copyquestion = async (data) => {
  console.log(data, '复制的数据');
  let user_id = localStorage.getItem('_id');
  try {
    await http.post('/questionnaire/copy-questionnaire', {
      naireId: data._id,
      createUser: user_id,
    });
    reqProjectList();
  } catch (error) {
    console.log(error);
  }
};

// !  !!! 删除弹窗
const DeleteVolun = async (q) => {
  console.log('删除数据', q);
  // console.log('点击的数据', row);
  // OptionsData
  isDerived_delect.value = true;
  oneDerivedData.value = OptionsData.value;
};
//  !  !!!确定删除
const oneDeleteVolun = async () => {
  let { _id } = oneDerivedData.value;
  try {
    await http.post('/questionnaire/delete-questionnaire', { naireId: _id });
    reqProjectList();
    oneDerivedData.value = null;
    isDerived_delect.value = false;
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    });
  } catch (error) {
    message({
      message: '删除失败',
      grouping: true,
      type: 'error',
    });
  }
};
// ! !!!取消删除
const isshowDerived_delect = () => {
  isDerived_delect.value = false;
};
// !  !!! 停止弹窗
const StopVolun = async (row, state) => {
  isDerived_stop.value = true;
  stop_data.data = row;
  stop_data.state = state;
  console.log(stop_data, '停止数据');
};
//  !  !!!确定停止
const oneStopVolun = async () => {
  let {
    data: { _id },
    state,
  } = stop_data;
  try {
    await http.post('/questionnaire/updateStatus-questionnaire', {
      naireId: _id,
      status: state,
    });
    reqProjectList();
    isDerived_stop.value = false;
  } catch (error) {
    console.log(error);
  }
};
// ! !!!取消停止
const isshowDerived_Stop = () => {
  isDerived_stop.value = false;
};
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value :>> ', value);
  const table = xTable.value;
  const FixedList = table.getColumns();

  //   //console.log('当前的列顺序为', FixedList);

  //   //console.log('FixedIndex :>> ', FixedIndex);

  FixedList.forEach((item, index) => {
    if (item.title == value) {
      // //console.log('value的位置在,index :>> ', value, '的位置在', index);
      FixedIndex.value = index;
    }
  });
  //console.log('FixedIndex下标为 :>> ', FixedIndex);
  showIdOptions.value = false;
  showIdOptions.value = true;
  // 设置位置
  const Event = event;
  OperationBox.value.style.left = event.clientX - 100 + 'px';
};
//! !!!模糊搜索
const searchEvent2 = async () => {
  EventSearch.value = true;
  const res = await http.post('/questionnaire/fetchList-questionnaire', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: { createdAt: -1 },
    content: demo1.filterName,
  });
  console.log('全局搜索的返回结果res :>> ', res);
  if (res.status == 0) {
    demo1.loading = false;
    demo1.tableData = res.naires;
    page5.totalResult = res.totalCount;
  }
};
// ! 问卷重命名
const Rename = async () => {
  try {
    let { _id } = OptionsData.value;
    await http.post('/questionnaire/rename-questionnaire', {
      naireId: _id,
      naire_title: demo1.rename,
    });
    isRename.value = false;
    reqProjectList();
  } catch (error) {
    console.log(error);
  }
};
// ! !!!取消重命名
const unisRename = () => {
  isRename.value = false;
};

// 问卷跳转的路由地址
const quesRoute = ref();
// ! 调跳空白问卷
const Blank_questionnaire = () => {
  // router.push("/CreatedQuestionnaire");
  quesRoute.value = 'CreatedQuestionnaire';
  projectClick.value = '';
  quesDialog.value = true;
};
// ! 调准文本导入
const Text_import = () => {
  // router.push("/TextImport");
  quesRoute.value = 'TextImport';
  projectClick.value = '';
  quesDialog.value = true;
};
// 传给子组件的questionId
let questionId = ref('');
//将id传给 孙子组件进行请求数据，孙子组件是webPreviewQuestion
provide('questionId', questionId);
const preview = (data) => {
  console.log('data is ', data);
  let { _id } = data;
  console.log('id is ', _id);

  router.push({
    name: 'H5QuestionPreview',
    params: {
      _id: _id,
    },
  });

  return;
  // let {_id} = data
  //   questionId.value = _id
  //    isH5Preview.value = !isH5Preview.value
};
// 导出
const isshowOneExport = ref(false);
const name = ref();
const showOneExport = () => {
  console.log();
  name.value = OptionsData.value.naire_title;
  isshowOneExport.value = true;
  console.log(isshowOneExport.value);
};

//const ExportFile = async (row) => {
//isshowOneExport.value=false
//console.log(row);
//const res = await http.post('/upload/export-sample', [row._id]);
// console.log(res);
// if (res.status == 0) {
//  xlsx.writeFileXLSX(res.xlsxData, '单个导出.xlsx');
//}
//};
const is_dialogShow = ref(false);
watch(
  () => is_dialogShow.value,
  () => {
    if (is_dialogShow.value === false) {
      reqProjectList();
    }
  },
);
const handlerDialog = () => {
  dialogFormVisible.value = true;
};
// watch(
//   () => store.state.isResOk,
//   () => {
//     if (store.state.isResOk == true) {
//       console.log("watch监听到了isResOk");
//       reqProjectList();
//     }
//   }
// );
onMounted(() => {
  projectSelectArrChange();
  reqProjectList();
  // setTimeout(() => {
  //   console.log("1秒延时器");
  //   reqProjectList();
  // }, 100);
  store.commit('Set_CurrentProjectName', '问卷列表');
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // 操作
    if (New_voluntee_box.value == true) {
      if (e.target.id != 'New_voluntee_box') {
        New_voluntee_box.value = false;
      }
      console.log(ClickMoreOptions.value);
    }
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false;
      }

      console.log(ClickMoreOptions.value);
    }
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
      console.log(ClickMoreOptions.value);
    }
  });
});

//试试
const exportContent = ref(null);
//问卷标题
const naire_title = ref('');
//提示语
const naire_explain = ref('');
//所有问卷
const questionArr = ref([]);
//问卷标题
const questionTitleArr = ref([]);
//问卷类型
const questionTypeArr = ref([]);
//选项
const questionLabelArr = ref([]);
const labelTitleArr = ref([]);
const valueTitleArr = ref([]);
//图片
const questionImgArr = ref([]);
const questionImgArr1 = ref([]);
// 目标Base64数组
const base64Array = ref([]);
const base64Array1 = ref([]);
let imgSrcArray = ref([]);
//图片说明
const ImgTextArr = ref([]);
//量表维度
const echartsDimensionArr = ref([]);
//起始数值
const startNumArr = ref([]);
//极值标签
const maxOptionArr = ref([]);
//必选？
const requiredArr = ref([]);

//获取问卷列表
const ExportFile = async () => {
  console.log(OptionsData.value.naire_title);
  const res = await http.post('/questionnaire/findInfo-questionnaire', {
    questionnaireId: OptionsData.value._id,
  });
  console.log(res, '问卷信息');
  naire_title.value = res.naire.naire_title;
  naire_explain.value = res.naire.naire_explain;
  questionArr.value = res.naire.Questions;
  console.log(res.naire.Questions);
  questionImgArr;
  //问题标题
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].title);
    questionTitleArr.value.push(questionArr.value[i].title);
  }
  console.log('questionTitleArr :>> ', questionTitleArr);
  //题目type
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].type);
    questionTypeArr.value.push(questionArr.value[i].type);
  }
  //单选、多选、下拉、单项填空、多项填空选项
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].label);
    questionLabelArr.value.push(questionArr.value[i].label);
  }
  //必选？
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].required);
    requiredArr.value.push(questionArr.value[i].required);
  }
  //矩阵单选、多选、量选选项
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].labelTitle);
    labelTitleArr.value.push(questionArr.value[i].labelTitle);
  }
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].valueTitle);
    valueTitleArr.value.push(questionArr.value[i].valueTitle);
  }
  //图片提示语
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].imgText);
    ImgTextArr.value.push(questionArr.value[i].imgText);
  }
  //图片
  for (let i = 0; i < questionArr.value.length; i++) {
    if (questionArr.value[i].type === '单选') {
      questionImgArr.value.push(questionArr.value[i].imgSrc);
    } else {
      questionImgArr.value.push(questionArr.value[i].imgSrc);
    }
  }
  console.log('questionImgArr :>> ', questionImgArr.value);
  // 创建包含所有异步操作的新数组
  for (let imgArr of questionImgArr.value) {
    const baseArr = [];
    for (let imagePath of imgArr) {
      console.log('imagePath', imagePath);

      const response = await fetch(imagePath);
      const blob = await response.blob(); // 将图片的Blob对象转换为Base64编码
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64Image = reader.result; // 将Base64编码添加到数组中
        if (imagePath !== '') baseArr.push(base64Image);
        else baseArr.push('');
      };
    }
    base64Array.value.push(baseArr);
  }
  //起始数值
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].startNum);
    startNumArr.value.push(questionArr.value[i].startNum);
  }
  //量表维度
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].echartsDimension);
    echartsDimensionArr.value.push(questionArr.value[i].echartsDimension);
  }

  //极值标签
  for (let i = 0; i < questionArr.value.length; i++) {
    console.log(questionArr.value[i].maxOption);
    maxOptionArr.value.push(questionArr.value[i].maxOption);
  }

  setTimeout(() => {
    const exportElement = exportContent.value; // 获取要导出的 DOM 元素
    console.log('exportElement :>> ', exportElement);
    const htmlContent = exportElement.innerHTML; // 获取要导出的内容
    const blob = new Blob(
      [
        `<!DOCTYPE html>
      <html xmlns:w="urn:schemas-microsoft-com:office:word">
        <head>
          <style>
            .Title {
              font-size: 18px;
              color:black;
              text-align: center;
              font-weight: bold;
            }
            .explain{
              font-size: 14px;
              color:black;
            }
            .ContestListTitle{
                display: inline-block;
                font-size: 14px;
                color:black;
                white-space: nowrap
            }
            .require{
                color:red
            }

          </style>
        </head>
        <body>
          ${htmlContent}
        </body>

      </html>`,
      ],
      {
        type: 'application/msword',
      },
    );
    const dataUri = URL.createObjectURL(blob);

    // 创建一个隐藏的 <a> 元素，下载 Word 文档
    const downloadLink = document.createElement('a');
    downloadLink.href = dataUri;
    downloadLink.download = OptionsData.value.naire_title + '.doc';
    downloadLink.style.display = 'none';

    // 将 <a> 元素添加到 DOM 中
    document.body.appendChild(downloadLink);

    // 模拟点击下载链接
    downloadLink.click();

    // 清理 DOM 中的下载链接
    document.body.removeChild(downloadLink);
  }, 1000);
};
</script>

<style lang="less" scoped>
@import url('@/style/views/QuestionnaireManagement/QuestionnaireList.less');
</style>
