<template>
  <div class="about">
    <div class="home">
      <!-- <NavBar /> -->
      <div class="LeftBox">
        <!-- <TopHeader /> -->
        <!-- <component :is="comName"></component> -->
        <router-view />
      </div>
    </div>
    <!-- 修改出示密码弹窗  v-if="store.state.user.initialPSW"-->
    <div class="changePSW" v-if="true">
      <div class="box">
        <p>修改初始密码</p>
        <div class="password">
          <div class="text">请输入新密码:</div>
          <el-input v-model="NewPwd" type="password" placeholder="请输入用户新密码" show-password @blur="VerifyNewPwd" />
        </div>
        <div class="confirm_password">
          <div class="text">请再次输入新密码:</div>
          <el-input v-model="ConfirmNewPwd" type="password" placeholder="请输入用户再次输入密码" show-password @blur="ConfirmPsw" />
        </div>
        <button @click="Confirm">确认</button>
        <button @click="Cancel">取消</button>
      </div>
    </div>
  </div>
</template>
<script setup>
// @ is an alias to /src
import { defineComponent, ref, onMounted, watchEffect } from 'vue';
import VxeTable from '@/components/VxeTable.vue';
import NavBar from '@/components/NavBar.vue';
import TopHeader from '@/components/TopHeader.vue';
import TableView from '@/components/TableView';
// import RecruitVolunteer from './VolunteerManagement/VolunteerBank.vue';
import ProjectList from '@/views/ProjectManagement/ProjectList.vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { message } from '@/utils/resetMessage';
import http from '@/utils/http';
//接收api
onMounted(() => {
  console.log('store.user.state.reset_password :>> ', store.state.user.reset_password);
});

const store = useStore(); //接收vuex
const router = useRouter(); //接收路由
//data数据
const ConfirmNewPwd = ref(''); //确认密码值
const NewPwd = ref(''); //新密码值
const PassVerify = ref(false); //是否符合更改密码条件
const PassConfirm = ref(false);
// 校验密码强度
const VerifyNewPwd = () => {
  // console.log('NewPwd.value.length :>> ', NewPwd.value.length);
  let judgeFn = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&/*()_+|{}:"?]{8,16}$/);
  // 根据newpwd的数据长度来判断,0就不判断;
  if (NewPwd.value.length != 0) {
    if (!judgeFn.test(NewPwd.value)) {
      PassVerify.value = false;
      message({
        type: 'warning',
        message: '密码强度不符合要求',
        showClose: true,
      });
    } else {
      PassVerify.value = true;
    }
  }
};
//校验两次密码一致
const ConfirmPsw = () => {
  if (NewPwd.value != ConfirmNewPwd.value) {
    message({
      type: 'error',
      message: '两次输入的密码不一致',
      showClose: true,
    });
    PassConfirm.value = false;
  } else {
    PassConfirm.value = true;
  }
};
//修改密码
const Confirm = async () => {
  // console.log(PassConfirm.value);
  if (PassConfirm.value == true && PassVerify.value == true) {
    const res = await http.post('/user/resetPassword-user', {
      _id: localStorage.getItem('_id'),
      password: NewPwd.value,
    });
    console.log('初始化密码返回--->', res);
    if (res.status == 0) {
      message({
        type: 'success',
        message: '修改密码成功!',
        showClose: true,
      });
      router.push('/');
    } else {
      message({
        type: 'error',
        message: '无效用户,请联系管理员!',
        showClose: true,
      });
    }
  } else {
    ConfirmPsw();
    VerifyNewPwd();
    console.log('123');
    console.log('PassConfirm,PassVerify :>> ', PassConfirm.value, PassVerify.value);
  }
};
const Cancel = () => {
  router.push('/');
};
const comName = ref('');

// // 监听组件变化
// watchEffect(() => {
//   console.log("'当前组件是'", store.state.Project.CurrentProjectName);
//   switch (store.state.Project.CurrentProjectName) {
//     case '项目列表':
//       comName.value = ProjectList;
//       // reqProjectList();
//       break;
//     case '新建项目':
//       comName.value = CreateProject;
//       break;
//     case '志愿者库':
//       comName.value = RecruitVolunteer;
//       reqVolunteer();

//       break;
//   }
//   console.log('comname', comName.value);
// });
//志愿者招募管理
const reqVolunteer = async () => {
  const res = await http.post('/user/findAll-user', {});
  console.log('res :>> ', res);
};
//项目列表
// const reqProjectList = async () => {
//   const res = await http.post('/project/find-project', {
//     page: 1,
//     size: 1,
//     sort: { createdAt: -1 },
//     Project: {},
//   });
//   console.log('res :>> ', res);
//   store.commit('set_PL_AllData', res.result);
// };
// const clickEven = (val) => {
//   console.log(val.value);
//   comName.value = val.value;
//   console.log(comName.value);
// };
</script>
<style lang="less">
@import url(@/style/views/HomeView.less);
</style>
