<template>
  <div class="vxetable">
    <!-- <vxe-toolbar>
      <template #buttons>
        <vxe-button @click="addColumn()">最后增加一列</vxe-button>
        <vxe-button @click="removeColumn()">删除最后一列</vxe-button>
        <vxe-button @click="updateSexFilter()">修改sex列筛选条件</vxe-button>
        <vxe-button @click="toggleFixedColumn(0, 'left')">切换第一列固定</vxe-button>
        <vxe-button @click="toggleFixedColumn(1, 'left')">切换第二列固定</vxe-button>
        <vxe-button @click="toggleFixedColumn(3, 'left')">切换第二列固定</vxe-button>
        <vxe-button @click="updateWidthColumn(2, 500)">修改第三列宽度</vxe-button>
        <vxe-button @click="updateWidthColumn(3, 500)">修改第四列宽度</vxe-button>

        <vxe-button @click="$refs.xTable.hideColumn($refs.xTable.getColumnByField('role'))">隐藏role</vxe-button>
        <vxe-button @click="$refs.xTable.showColumn($refs.xTable.getColumnByField('role'))">显示role</vxe-button>
        <vxe-button @click="$refs.xTable.hideColumn($refs.xTable.getColumnByField('sex'))">隐藏SEX</vxe-button>
        <vxe-button @click="$refs.xTable.showColumn($refs.xTable.getColumnByField('sex'))">显示SEX</vxe-button>

        <vxe-button @click="printEvent1">打印</vxe-button>
        <vxe-button @click="exportDataEvent">默认导出</vxe-button>
      </template>
    </vxe-toolbar> -->

    <vxe-table
      border
      ref="xTable"
      height="550"
      :data="demo1.tableData"
      :column-config="{ resizable: true }"
      :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
      :checkbox-config="{ reserve: true }"
      @header-cell-dragend="handleHeaderDragend"
      @checkbox-change="selectChangeEvent1"
      @sort-change="handleSortChange">
      <vxe-column field="checked" type="checkbox" width="50" :fixed="demo1.colFixeds.col0"></vxe-column>
      <!-- <vxe-column type="seq" width="60" :fixed="demo1.colFixeds.col1"></vxe-column> -->

      <vxe-column field="id" title="ID" width="300" :fixed="demo1.colFixeds.col1" min-width="150">
        <!-- 增加下拉箭头 -->
        <template #header="{ column }">
          <div class="ID-header" v-if="column.title === 'ID'">
            <span class="name">ID</span>
            <p><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('ID', $event)"></i></p>
          </div> </template
      ></vxe-column>
      <vxe-column field="name" title="Name" width="300" :fixed="demo1.colFixeds.col2" min-width="150">
        <!-- 增加下拉箭头 -->
        <template #header="{ column }">
          <div class="name-header" v-if="column.title === 'Name'">
            <span class="name">name</span>
            <p><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Name', $event)"></i></p>
          </div> </template
      ></vxe-column>
      <vxe-column field="role" title="Role" width="300" :fixed="demo1.colFixeds.col3" min-width="150">
        <!-- 增加下拉箭头 -->
        <template #header="{ column }">
          <div class="Role-header" v-if="column.title === 'Role'">
            <span class="name">Role</span>
            <p><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Role', $event)"></i></p>
          </div>
        </template>
      </vxe-column>

      <vxe-column field="sex" title="Sex" width="300" :fixed="demo1.colFixeds.col4" min-width="150">
        <!-- 增加下拉箭头 -->
        <template #header="{ column }">
          <div class="Sex-header" v-if="column.title === 'Sex'">
            <span class="name">Sex</span>
            <p><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Sex', $event)"></i></p>
          </div>
        </template>
      </vxe-column>
      <vxe-column field="address" title="Address" width="300" :fixed="demo1.colFixeds.col5" min-width="150">
        <!-- 增加下拉箭头 -->
        <template #header="{ column }">
          <div class="Address-header" v-if="column.title === 'Address'">
            <span class="name">Address</span>
            <p><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Address', $event)"></i></p>
          </div>
        </template>
      </vxe-column>
      <vxe-column title="操作" width="200" :fixed="demo1.colFixeds.col6" min-width="250">
        <!-- 增加下拉箭头 -->
        <template #header="{ column }">
          <div class="name-header" v-if="column.title === '操作'">
            <span class="name">操作</span>
            <p><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('操作', $event)"></i></p>
          </div>
        </template>
        <template #default>
          <vxe-button status="primary">按钮1</vxe-button>
          <vxe-button>按钮2</vxe-button>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 下拉箭头的更多操作 -->
    <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
      <div class="contentBox" id="MoreOptions">
        <ul class="list" id="MoreOptions">
          <li id="MoreOptions">升序</li>
          <li id="MoreOptions">降序</li>
          <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')">固定</li>
          <li id="MoreOptions">隐藏</li>
        </ul>
      </div>
    </div>
    <p>
      <vxe-pager
        background
        @page-change="pageChange"
        v-model:current-page="page5.currentPage"
        v-model:page-size="page5.pageSize"
        :total="page5.totalResult"
        :layouts="['PrevJump', 'PrevPage', 'JumpNumber', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total']">
      </vxe-pager>
    </p>
  </div>
</template>
<script setup>
import { defineComponent, ref, reactive, onBeforeUnmount, nextTick, toRaw, toRefs, onMounted, watchEffect } from 'vue';
import { VxeTableInstance } from 'vxe-table';
import XEUtils from 'xe-utils';
const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
onMounted(() => {
  //处理点击更多操作盒子 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
    }
  });
});
const OperationBox = ref(null); //获取更多操作盒子的ref
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1);
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value :>> ', value);
  // demo1.tableColumn.forEach((item, index) => {
  //   if (item.title == value) {
  //     // console.log('value的位置在,index :>> ', value, '的位置在', index);
  //     FixedIndex.value = index;
  //   }
  // });
  switch (value) {
    case 'Name':
      FixedIndex.value = 2;
      break;
    case 'Role':
      FixedIndex.value = 3;
      break;
    case 'ID':
      FixedIndex.value = 1;
      break;
    case 'Sex':
      FixedIndex.value = 4;
      break;
    case 'Address':
      FixedIndex.value = 5;
      break;
    default:
      break;
  }
  showIdOptions.value = false;
  showIdOptions.value = true;
  // console.log(' showIdOptions.value :>> ', showIdOptions.value);
  const Event = event;
  // console.log('event :>> ', event);
  // console.log('Operations :>> ', OperationBox);
  // console.log('Operations :>> ', OperationBox.value.style.height);
  OperationBox.value.style.left = event.clientX - 160 + 'px';
  // console.log('object :>> ', OperationBox.value.style.left);

  const $table = xTable.value;
  // console.log('$table :>> ', $table);
};
const handleButtonClick = (btn) => {
  console.log('btn :>> ', btn);
};
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false;
  console.log('点击点击 :>> ');
};
//全部数据
const allData = reactive([
  {
    id: 10001,
    name: 'Test1',
    nickname: 'T1',
    role: 'Develop',
    sex: '1',
    age: 28,
    address: 'test abc',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10002,
    name: 'Test2',
    nickname: 'T2',
    role: 'Test',
    sex: '0',
    age: 22,
    address: 'Guangzhou',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10003,
    name: 'Test3',
    nickname: 'T3',
    role: 'PM',
    sex: '1',
    age: 32,
    address: 'Shanghai',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10004,
    name: 'Test4',
    nickname: 'T4',
    role: 'Designer',
    sex: '0 ',
    age: 23,
    address: 'test abc',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10005,
    name: 'Test5',
    nickname: 'T5',
    role: 'Develop',
    sex: '0 ',
    age: 30,
    address: 'Shanghai',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10006,
    name: 'Test6',
    nickname: 'T6',
    role: 'Designer',
    sex: '0 ',
    age: 21,
    address: 'test abc',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10007,
    name: 'Test7',
    nickname: 'T7',
    role: 'Test',
    sex: '1',
    age: 29,
    address: 'test abc',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10008,
    name: 'Test8',
    nickname: 'T8',
    role: 'Develop',
    sex: '1',
    age: 35,
    address: 'test abc',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10009,
    name: 'Test9',
    nickname: 'T7',
    role: 'Test',
    sex: '1',
    age: 29,
    address: 'test abc',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10010,
    name: 'Test10',
    nickname: 'T8',
    role: 'Develop',
    sex: '1',
    age: 35,
    address: 'test abc',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  {
    id: 10011,
    name: 'Test11',
    nickname: 'T1',
    role: 'Develop',
    sex: '1',
    age: 28,
    address: 'test abc',
    buttons: [
      { label: '按钮1', action: 'action1' },
      { label: '按钮2', action: 'action2' },
    ],
  },
  { id: 10012, name: 'Test12', nickname: 'T2', role: 'Test', sex: '0', age: 22, address: 'Guangzhou' },
  { id: 10013, name: 'Test13', nickname: 'T3', role: 'PM', sex: '1', age: 32, address: 'Shanghai' },
  { id: 10014, name: 'Test14', nickname: 'T4', role: 'Designer', sex: '0 ', age: 23, address: 'test abc' },
  { id: 10015, name: 'Test15', nickname: 'T5', role: 'Develop', sex: '0 ', age: 30, address: 'Shanghai' },
  { id: 10016, name: 'Test16', nickname: 'T6', role: 'Designer', sex: '0 ', age: 21, address: 'test abc' },
  { id: 10017, name: 'Test17', nickname: 'T7', role: 'Test', sex: '1', age: 29, address: 'test abc' },
  { id: 10018, name: 'Test18', nickname: 'T8', role: 'Develop', sex: '1', age: 35, address: 'test abc' },
  { id: 10019, name: 'Test19', nickname: 'T7', role: 'Test', sex: '1', age: 29, address: 'test abc' },
  { id: 10020, name: 'Test20', nickname: 'T8', role: 'Develop', sex: '1', age: 35, address: 'test abc' },
  { id: 10021, name: 'Test21', nickname: 'T1', role: 'Develop', sex: '1', age: 28, address: 'test abc' },
  { id: 10022, name: 'Test22', nickname: 'T2', role: 'Test', sex: '0', age: 22, address: 'Guangzhou' },
  { id: 10023, name: 'Test23', nickname: 'T3', role: 'PM', sex: '1', age: 32, address: 'Shanghai' },
  { id: 10024, name: 'Test24', nickname: 'T4', role: 'Designer', sex: '0 ', age: 23, address: 'test abc' },
  { id: 10025, name: 'Test25', nickname: 'T5', role: 'Develop', sex: '0 ', age: 30, address: 'Shanghai' },
  { id: 10026, name: 'Test26', nickname: 'T6', role: 'Designer', sex: '0 ', age: 21, address: 'test abc' },
  { id: 10027, name: 'Test27', nickname: 'T7', role: 'Test', sex: '1', age: 29, address: 'test abc' },
  { id: 10028, name: 'Test28', nickname: 'T8', role: 'Develop', sex: '1', age: 35, address: 'test abc' },
  { id: 10029, name: 'Test29', nickname: 'T7', role: 'Test', sex: '1', age: 29, address: 'test abc' },
  { id: 10030, name: 'Test30', nickname: 'T8', role: 'Develop', sex: '1', age: 35, address: 'test abc' },
]);
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: allData.length,
});
//分页排序处理
function handleSortChange(sortObj) {
  const list = toRaw(sortObj.$table.props.data);
  console.log('list :>> ', list);
  demo1.tableData = allData;
  const field = sortObj.props;
  const order = sortObj.order;
  console.log('sortObj :>> ', sortObj);
  console.log('field,order :>> ', field, order);
  // console.log('object :>> ', JSON.parse(JSON.stringify(sortObj.$table.props.data)));

  demo1.tableData.sort((a, b) => {
    if (order === 'ascending') {
      return a[field] > b[field] ? 1 : -1;
    } else {
      return a[field] < b[field] ? 1 : -1;
    }
  });
  // allData = sortObj.$table.props.data;
  console.log('list :>> ', list);
  demo1.tableData = allData.slice(0, page5.pageSize);
}
//复选框数组
var selectedRows = reactive([]);
//跳转页面显示数据处理
const pageChange = () => {
  console.log('currentPage,pageSize :>> ', page5.currentPage, page5.pageSize);
  const page = page5.currentPage;
  const size = page5.pageSize;
  console.log('page,size :>> ', page, size);
  if (page > 1) {
    demo1.tableData = allData.slice(page * size - size, page * size);
  } else {
    demo1.tableData = allData.slice(page * size - size, page * size);
  }

  console.log('selectedRows :>> ', selectedRows);
  const startIndex = (page - 1) * size;
  const endIndex = startIndex + size;
  const currentPageRows = allData.slice(startIndex, endIndex);
  console.log('currentPageRows :>> ', currentPageRows);
  for (const row of currentPageRows) {
    if (selectedRows.includes(row)) {
      row.selected = true;
    } else {
      row.selected = false;
    }
  }
};
//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: 'right',
  },
  tableData: [
    //  展示的数据
  ],
});
demo1.tableData = allData.slice(0, page5.pageSize);
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params;
  console.log(columns); // 打印排序后的表头列数组对象
};

// 复选框点击事件
const selectChangeEvent1 = ({ checked }) => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records;
};

const xTable = ref();

const addColumn = () => {
  const uniqueId = XEUtils.uniqueId();
  demo1.tableColumn.push({
    field: `new_${uniqueId}`,
    title: `新列_${uniqueId}`,
    width: 100,
  });
};

//打印方法
const printEvent1 = () => {
  const $table = xTable.value;
  $table.print();
};
//导出方法
const exportDataEvent = () => {
  const $table = xTable.value;
  $table.exportData({ type: 'csv' });
};

const removeColumn = () => {
  demo1.tableColumn.pop();
};

const updateSexFilter = async () => {
  const $table = xTable.value;
  const column = $table.getColumnByField('sex');
  if (column) {
    // 修改筛选列表，并默认设置为选中状态
    await $table.setFilter(column, [
      { value: '1', label: '男' },
      { value: '0', label: '女', checked: true },
    ]);
    // 修改条件之后，需要手动调用 updateData 处理表格数据
    await $table.updateData();
  }
};

const toggleFixedColumn = async (index, value) => {
  console.log('index,value :>> ', index, value);
  // console.log('object :>> ', demo1.tableColumn[index].fixed ? null : value);

  const $table = xTable.value;

  const tableColumns = $table.getColumns();
  const fxColumn = tableColumns[index];
  if (fxColumn) {
    fxColumn.fixed = fxColumn.fixed ? null : value;
  }
  // 刷新列
  await nextTick();
  await $table.refreshColumn();
  await $table.refreshScroll();
};

const updateWidthColumn = async (index, value) => {
  const $table = xTable.value;
  demo1.tableColumn[index].width = value;
  // 更改了列属性，需要手动刷新列
  await nextTick();
  await $table.refreshColumn();
};
</script>
<style lang="less" scoped>
@import url('@/style/components/VxeTable.less');
</style>
