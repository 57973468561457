import { createStore } from 'vuex'
// 引入各个模块
import state  from './state'
import mutations from './mutations'
import actions from './actions'
//导入项目列表模块
import ProjectList from './modules/Project/ProjectList'
//导入用户模块
import user from './modules/user'
//导入项目模块
import Project  from './modules/Project'
// 导入仪器模块
import Details from './modules/Instrument/Details'
//导入报告模块
import Report  from './modules/Report'
// ! 仪器导入
import Sample from "./modules/Sample"
// ! 报告审批
import Report_details from "./modules/Report_details"
 
// 日志
import Log from "./modules/Log"

export default createStore({
  state,
  mutations,
  actions,
  modules: {
    ProjectList,
    user,
    Project,
    Details,
    Report,
    Sample,
    Report_details,
    Log
  }
})
