<template>
  <!-- 验证码已发送页面 -->
  <div id="code_sent" v-if="is_codeSent">
    <div class="title">验证码已发送</div>
    <div class="content">
      <div class="hint-text">
        我们已经向您的邮箱
        <span>{{ mail }}</span>
        发送了一封包含了验证码的邮件，请填写到下面输入框中
      </div>
      <div class="input_verifyCode">
        <el-input :prefix-icon="Lock" v-model="codeSent" :class="is_hint ? 'error_input' : ''" placeholder="请输入验证码" clearable />
        <span v-if="is_hint" class="error-hint">{{ text }}</span>
      </div>
      <div class="countdown" v-if="isCountdown">
        <span> {{ countdown_time }}s 后重新发送</span>
      </div>
      <div v-else class="resend">没有收到邮件？<span @click="resend">点击这里重发</span></div>
      <div class="submit" @click="submit">
        <el-button type="primary">提交</el-button>
      </div>
      <div class="back" @click="back">返回</div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { Message, Lock } from '@element-plus/icons-vue';
import { message } from '@/utils/resetMessage';
import { useRouter, useRoute } from 'vue-router';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';
import http from '@/utils/http';

const router = useRouter();
const route = useRoute();
const text=ref("请输入验证码");
// 验证码已发送页面的数据
const is_codeSent = ref(true);
const codeSent = ref('');

// 倒计时
const countdown_time = ref(60);
const isCountdown = ref(true);

const mail = ref(atob(route.query.mail));

const countdown = () => {
  if (is_codeSent.value === true) {
    countdown_time.value = 60;
    var times = setInterval(() => {
      countdown_time.value--; //递减
      if (countdown_time.value <= 0) {
        isCountdown.value = false;
        clearInterval(times);
        countdown_time.value = 60;
      }
      // console.log(countdown_time.value);
    }, 1000); //1000
  }
};
// 重新发送验证码
const resend = async () => {
  isCountdown.value = true;
  const res = await http.post('/auth/getVerifyCode', {
    email: mail.value,
  });
  console.log(res);
  countdown();
};
// 提交按钮
const is_hint = ref(false);
const submit = async () => {
  if (codeSent.value !== '') {
    // console.log(codeSent.value);
    is_hint.value = false;
    const res = await http.post('/auth/validVerifyCode', {
      email: mail.value,
      verifyCode: codeSent.value,
    });
    console.log(res);
    // console.log(res.verifyCode);
    if (res.status === 0) {
      console.log('验证码校验成功');
      localStorage.setItem('_id', res.userId);
      router.push('/set_newpassword');
    } else if (res.status === 1) {
      // console.log('验证码校验失败');
      is_hint.value = true;
      text.value="验证码错误";
    } else if (res.status === 2) {
      is_hint.value = true;
      text.value="验证码错误";
    }else if (res.status === 3) {
      // console.log('验证码校验失败');
      is_hint.value = true;
      text.value="验证码错误";
    }
  } else {
    is_hint.value = true;
    text.value="请输入验证码";
    // message({
    //   type: 'error',
    //   showClose: true,
    //   message: '验证码错误',
    // });
  }
};
watch(
  () => codeSent.value,
  (newvalue, oldvalue) => {
    if (codeSent.value !== '') {
      is_hint.value = false;
    }
  },
);
// 验证码已发送页面的返回按钮
const back = () => {
  console.log('返回');
  router.push('/');
};
onMounted(() => {
  countdown();
  // console.log(route.query.mail, atob(route.query.mail));
});
onBeforeRouteUpdate((to, from) => {
  // console.log(to, from);
});
onBeforeRouteLeave((to, from) => {
  // console.log(to, from);
});
</script>

<style lang="less">
@import url('@/style/views/LoginModules/SendVerify.less');
</style>
