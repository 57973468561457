<!--
 * @Author: ZhengJianYang
 * @Date: 2023-06-12 09:41:12
 * @LastEditors: ZhengJianYang
 * @LastEditTime: 2023-06-24 23:49:53
 * @Description: 系统管理-个人中心
-->
<template>
  <div class="personCenter">
    <div class="personCenter_main">
      <div class="personCenter_main_header">
        <div class="personCenter_main_header_title">账号信息</div>
        <!-- <div class="personCenter_main_header_right">
          <img
            v-if="isEditStatus"
            class="editImg"
            @click="editPersonInfo"
            src="https://newstore.vynior.com/cems_web/bianji11111.png"
            alt="编辑"
          />
          <img
            v-if="!isEditStatus"
            class="saveImg"
            @click="savePersonInfo"
            src="https://newstore.vynior.com/cems_web/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83%E4%BF%9D%E5%AD%98%E6%8C%89%E9%92%AE123123123.png"
            alt="保存"
          />
        </div> -->
      </div>

      <div class="personCenter_main_center">
        <div class="personCenter_main_center_avatar">
          <div class="label">头像</div>
          <div class="photo">
            <el-avatar
              class="avatar"
              :src="
                demoData.gender == '女'
                  ? 'https://newstore.vynior.com/cems_web/%E5%A4%B4%E5%83%8F-%E5%A5%B3.png'
                  : 'https://newstore.vynior.com/cems_web/%E5%A4%B4%E5%83%8F-%E7%94%B7.png'
              " />
          </div>
        </div>

        <div class="personCenter_main_center_userName">
          <div class="label"><span class="label_req">*</span>姓名</div>
          <div v-if="isEditStatus" class="photo">{{ demoData.name }}</div>
          <el-input
            v-else
            @focus="focusInputChange('name')"
            v-model="userNameInputValue"
            placeholder="请输入用户名"
            :class="errObj.name != 0 ? 'inp_err' : ''" />
        </div>
        <div class="box_err">
          <span v-if="errObj.name != 0">{{ errObj.nameArr[errObj.name - 1] }}</span>
        </div>
        <div class="personCenter_main_center_email">
          <div class="label"><span class="label_req">*</span>邮箱</div>
          <div v-if="isEditStatus" class="photo">{{ demoData.email }}</div>
          <el-input
            v-else
            v-model="emailInputValue"
            @focus="focusInputChange('email')"
            :class="errObj.email != 0 ? 'inp_err' : ''"
            placeholder="请输入邮箱" />
        </div>
        <div class="box_err">
          <span v-if="errObj.email != 0">{{ errObj.emailArr[errObj.email - 1] }}</span>
        </div>
        <div class="personCenter_main_center_pwd">
          <div class="label"><span class="label_req">*</span>密码</div>
          <div v-if="isEditStatus" class="photo password">*******</div>
          <el-input v-else :disabled="true" type="password" show-password v-model="pwdInputValue" placeholder="请输入密码" />
          <span @click="goToUpdataPwd" class="updataPwd"> 更改密码</span>
        </div>
        <div class="personCenter_main_center_job">
          <div class="label">角色</div>
          <div class="photos" v-if="demoData.role.length != 0">
            <span v-for="(item, index) in demoData.role" :key="index">
              {{ index < demoData.role.length - 1 ? item + '、' : item }}
            </span>
          </div>
          <div class="photo" v-if="demoData.role.length == 0">-----</div>
          <!-- <el-input v-else v-model="jobInputValue" placeholder="请输入角色" /> -->
        </div>
        <div class="personCenter_main_center_corporateName">
          <div class="label">职位</div>
          <div class="photo">{{ demoData.post ? demoData.post : '-----' }}</div>
        </div>

        <div class="personCenter_main_center_workNum">
          <div class="label">工号</div>
          <div class="photo">{{ demoData.num ? demoData.num : '-----' }}</div>
        </div>
      </div>
    </div>

    <el-dialog class="updatePwdDialog" v-model="updateCodeDialogShow" title="验证身份">
      <div class="updatePwdDialog_box">
        <div class="box_email">
          <div class="email_label">邮箱</div>
          <div class="email">{{ demoData.email }}</div>
        </div>
        <div class="box_code">
          <div class="code_text">验证码</div>
          <el-input
            :class="errObj.code != 0 ? 'inp_err' : ''"
            @focus="focusInputChange('code')"
            class="code_inp"
            v-model="verifyCodeInputValue"
            placeholder="请输入验证码"></el-input>
          <button class="code_but" @click="sendingCodeChange" :class="numTime != 0 ? 'code_buts' : ''">
            {{ numTime == 0 ? '发送验证码' : numTime + 's后重发' }}
          </button>
        </div>
        <div class="box_err">
          <span v-if="errObj.code != 0">{{ errObj.codeArr[errObj.code - 1] }}</span>
        </div>
        <!-- <div class="sendAgain">
          <span class="noReceive">没有收到邮件？</span>
        <span class="clickTosendAgain" @click="goToSendAgain"
          >点击这里重发</span
        >
        </div> -->
      </div>
      <el-button class="next_but" type="primary" @click="nextStep"> 下一步 </el-button>
    </el-dialog>
    <!-- 设置新密码 -->
    <el-dialog class="setNewPwd" v-model="updatePwdDialogShow" title="设置新密码" top="25vh" width="34.5%">
      <div class="pwd_box">
        <div class="box_new">
          <div class="new_text">
            <span class="text_x">*</span>
            新密码
          </div>
          <el-input
            class="new_inp"
            :class="errObj.newPassword != 0 ? 'inp_err' : ''"
            v-model="newPasswordInp"
            placeholder="请输入新密码"
            type="password"
            :show-password="true"
            @focus="focusInputChange('password')"></el-input>
        </div>
        <div class="box_tip">
          <span v-if="errObj.newPassword == 0">密码长度为8-16位，不少于8位；应至少包含数字、大小写字母及特殊字符中的两种</span>
          <div v-if="errObj.newPassword != 0" class="box_err">
            {{ errObj.passwordArr[errObj.newPassword - 1] }}
          </div>
        </div>
        <div class="box_new box_news">
          <div class="new_text">
            <span class="text_x">*</span>
            确认新密码
          </div>
          <el-input
            @focus="focusInputChange('passwords')"
            class="new_inp"
            :class="errObj.newPasswords != 0 ? 'inp_err' : ''"
            v-model="newPasswordInps"
            placeholder="请再次输入新密码"
            type="password"
            :show-password="true"></el-input>
        </div>
        <div class="box_tip">
          <div v-if="errObj.newPasswords != 0" class="box_err">
            {{ errObj.passwordArr[errObj.newPasswords - 1] }}
          </div>
        </div>
      </div>
      <el-button class="next_but" type="primary" @click="setPassword"> 提交 </el-button>
    </el-dialog>
  </div>

  <!-- 修改密码模态框 -->
  <!-- <el-dialog v-model="updatePwdDialogShow">
    <div class="updatePwdDialog">
      <div class="updatePwdDialog_title">验证身份</div>
      <div class="mainContent">222</div>
      <div class="footerContent">下一步</div>
    </div>
  </el-dialog> -->
</template>

<script setup>
import { ref, reactive, onMounted, defineEmits, watch, computed, onBeforeUnmount } from 'vue';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
onMounted(() => {
  getUserInfo();
});
const userId = reactive(localStorage.getItem('_id'));
// 获取用户信息
const getUserInfo = async () => {
  const res = await http.post('/user/findInfo-user', {
    userId: localStorage.getItem('_id'),
  });
  console.log(res, '获取的用户信息123');
  demoData.name = res.user.name;
  demoData.email = res.user.email;
  demoData.email = res.user.email;
  demoData.role = res.user.role;
  demoData.post = res.user.position;
  demoData.num = res.user.number;
  demoData.num = res.user.number;
  demoData.gender = res.user.gender;
  //本地存一份
  localStorage.setItem('userName', res.user.name);
  localStorage.setItem('userEmail', res.user.email);
  localStorage.setItem('userGender', res.user.gender);
};
const circleUrl = ref('https://newstore.vynior.com/00fe43a0-3ab0-11ea-8207-c33b1028aed8.png');
const demoData = reactive({
  name: '张三',
  email: 'hxu@fuhuan.ltd',
  password: '123456789',
  role: '招募管理员',
  post: [],
  num: '02312',
  gender: '',
});
const errObj = reactive({
  name: 0,
  email: 0,
  emailArr: ['邮箱不能为空', '邮箱格式错误，请重新输入', '此邮箱已注册，请重新输入'],
  nameArr: ['姓名不能为空', '姓名应为2~10个中文字符'],
  code: 0,
  newPassword: 0,
  newPasswords: 0,
  codeArr: ['验证码不能为空', '验证码错误', '验证码已失效，请重新发送'],
  passwordArr: [
    '新密码不能为空',
    '密码长度不能少于8位',
    '密码长度不能多于16位',
    '密码至少包含数字、大小写字母及特殊字符中的两种',
    '两次密码输入不一致',
  ],
});
// 输入框获取焦点
const focusInputChange = (val) => {
  console.log(val, '‘输入框获取焦点');
  if (val == 'code') {
    errObj.code = 0;
  } else if (val == 'password') {
    errObj.newPassword = 0;
  } else if (val == 'passwords') {
    errObj.newPasswords = 0;
  } else if (val == 'name') {
    errObj.name = 0;
  } else if (val == 'email') {
    errObj.email = 0;
  }
};
// 设置新密码对话框显示隐藏
let updatePwdDialogShow = ref(false);
// 验证身份对话框显示隐藏
const updateCodeDialogShow = ref(false);
// 用户名编辑框
const userNameInputValue = ref('');

// 公司名称
const corporateNameInputValue = ref('');

// 职位
const jobInputValue = ref('');

// 工号
const workNumInputValue = ref('');

// 邮箱
const emailInputValue = ref('');

// 密码编辑框
const pwdInputValue = ref('********');

// 全局编辑状态
const isEditStatus = ref(true);

// 编辑个人信息按钮
const editPersonInfo = () => {
  console.log('点击了编辑个人信息按钮');
  isEditStatus.value = !isEditStatus.value;
  console.log('当前编辑状态', isEditStatus.value);
  userNameInputValue.value = JSON.parse(JSON.stringify(demoData.name));
  emailInputValue.value = JSON.parse(JSON.stringify(demoData.email));
};
// 倒计时
const numTime = ref(0);
const time = ref();
// 点击发送验证码
const sendingCodeChange = async () => {
  if (numTime.value == 0) {
    const res = await http.post('/user/getVerifyCode-user', {
      userId: userId,
    });
    if (res.status == 0) {
      message({
        showClose: true,
        message: '已发送验证码',
        type: 'success',
      });
    }
    console.log(res, '点击发送验证码');
    numTime.value = 60;
    time.value = setInterval(() => {
      if (numTime.value == 0) {
        clearInterval(time.value);
        return;
      }
      numTime.value--;
      // console.log(numTime.value);
    }, 1000);
  }
};
// 设置新密码 新密码的值
const newPasswordInp = ref('');
// 设置新密码 ，确认新密码的值
const newPasswordInps = ref('');
// 点击设置新密码的提交
const setPassword = async () => {
  console.log('点击设置新密码点击提交');
  var regex = /^(?!^\d+$)(?!^[a-z]+$)(?!^[A-Z]+$)(?!^[^a-z0-9]+$)(?!^[^A-Z0-9]+$)(?!^.*[\u4E00-\u9FA5].*$)^\S*$/;
  if (newPasswordInp.value == '') {
    errObj.newPassword = 1;
  } else if (newPasswordInp.value.split('').length < 8) {
    errObj.newPassword = 2;
  } else if (newPasswordInp.value.split('').length > 16) {
    errObj.newPassword = 3;
  } else if (!regex.test(newPasswordInp.value)) {
    errObj.newPassword = 4;
  } else if (newPasswordInp.value != newPasswordInps.value) {
    errObj.newPassword = 5;
  }
  if (newPasswordInps.value == '') {
    errObj.newPasswords = 1;
  } else if (newPasswordInps.value.split('').length < 8) {
    errObj.newPasswords = 2;
  } else if (newPasswordInps.value.split('').length > 16) {
    errObj.newPasswords = 3;
  } else if (!regex.test(newPasswordInps.value)) {
    errObj.newPasswords = 4;
    console.log('强度不符');
  } else if (newPasswordInp.value != newPasswordInps.value) {
    errObj.newPasswords = 5;
  }
  if (errObj.newPassword == 0 && errObj.newPasswords == 0) {
    const res = await http.post('/user/resetPassword-user', {
      _id: userId,
      password: newPasswordInp.value,
    });
    console.log(res, '修改密码返回值');
    if (res.status == 0) {
      message({
        showClose: true,
        message: '更新密码成功',
        type: 'success',
      });
      updatePwdDialogShow.value = false;
    } else {
      message({
        showClose: true,
        message: '更新密码失败',
        type: 'error',
      });
    }
  }
};
//保存
const savePersonInfo = async () => {
  const regex = /^[\u4E00-\u9FA5]{2,4}$/;
  const regexs =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (userNameInputValue.value.split('').length == 0) {
    errObj.name = 1;
  } else if (!regex.test(userNameInputValue.value)) {
    errObj.name = 2;
  }
  if (emailInputValue.value.split('').length == 0) {
    errObj.email = 1;
  } else if (!regexs.test(emailInputValue.value)) {
    errObj.email = 2;
  }
  if (errObj.email == 0 && errObj.name == 0) {
    const res = await http.post('/user/update-user', {
      name: userNameInputValue.value,
      email: emailInputValue.value,
      _id: localStorage.getItem('_id'),
    });
    console.log(res, '点击了保存个人信息按钮');
    if (res.status == 0) {
      await getUserInfo();
      isEditStatus.value = !isEditStatus.value;
      console.log('当前编辑状态', isEditStatus.value);
    } else if (res.status == 1) {
      errObj.email = 3;
    }
  }
  // const res=await http.post('/user/update-user',{
  //   name:userNameInputValue.value,
  //   email:emailInputValue.value,
  //   _id:localStorage.getItem('_id')
  // })
  // console.log(res,"点击了保存个人信息按钮");
  // await getUserInfo()
  // isEditStatus.value = !isEditStatus.value;
  // console.log("当前编辑状态", isEditStatus.value);
};

//修改密码
const goToUpdataPwd = () => {
  verifyCodeInputValue.value = '';
  console.log('点击了修改密码');
  errObj.code = 0;
  errObj.newPassword = 0;
  errObj.newPasswords = 0;
  updateCodeDialogShow.value = true;
};

// 下一步按钮
const nextStep = async () => {
  console.log('点击了  下一步按钮 ');
  if (verifyCodeInputValue.value == '') {
    errObj.code = 1;
  } else {
    const res = await http.post('/user/validVerifyCode-user', {
      verifyCode: verifyCodeInputValue.value,
      userId: userId,
    });
    console.log(res, '点击下一步效验验证码');
    if (res.status != 0) {
      errObj.code = 2;
    } else {
      updateCodeDialogShow.value = false;
      updatePwdDialogShow.value = true;
    }
  }
};
// 验证码input框
let verifyCodeInputValue = ref('');

// 发送验证码
const sendVerifyCode = () => {
  console.log('点击了  发送验证码按钮 ');
};
// 没有收到邮件？点击这里重发
const goToSendAgain = () => {
  console.log('点击了 没有收到邮件？点击这里重发 ');
};
// 设置新密码，新密码
const verifyPassInputValue = ref(null);
// 设置新密码，确认密码
const verifyPassInputValues = ref(null);
// 页面销毁清除定时器
onBeforeUnmount(() => {
  clearInterval(time.value);
});
</script>

<style lang="less" scoped>
@import url('@/style/views/systemManagement/personalCenter.less');
</style>
