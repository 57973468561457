/*
 * @Author: ZhengJianYang
 * @Date: 2023-06-30 14:01:49
 * @LastEditors: ZhengJianYang
 * @LastEditTime: 2023-07-17 12:33:58
 * @Description:
 */
export default {
  msg: "I`m Vuex",
  name: "小明",
  list: [1, 2, 3, 4],
  age: 18,
  gender: "男",
  slider_state: false,
  role_info: [],
  questionData: {},
  InstrumentAnalyzableOptionsData: {}, //仪器可分析选项
  // isResOk: false, // 新建问卷退出-判断是否跳转
  isResOk: 0, // 新建问卷退出-判断是否跳转

  quesId: null,
  projectQuesId: null,

  idsArr: [],

  DescriptAnalysisItem: null,
};
