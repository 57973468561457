import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

// px转rem
import 'amfe-flexible/index.js';

//表格组件
import 'xe-utils';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import VXETablePluginElement from 'vxe-table-plugin-element';
import { GET_ADMINMESSAGE } from './store/actionTypes';
// import 'vxe-table-plugin-element/dist/style.css'
// VXETable.use(VXETablePluginElement)

//饿了么组件
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
// import locale from 'element-plus/lib/locale/lang/zh-cn.js';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';

import * as ElIcons from '@element-plus/icons-vue';

//拖动组件
import dragVerify from 'vue-drag-verify';

//引入字体包
import '@/assets/fonts/font.css';

const againGetAdminMessage = () => {
  const loginChecked = window.localStorage.getItem('loginChecked');
  if (loginChecked) {
    store.dispatch(GET_ADMINMESSAGE, window.localStorage.getItem('adminID'));
  }
};
againGetAdminMessage();

store.commit('Set_ProjectId', sessionStorage.getItem('ProjectId'));

createApp(App).use(store).use(router).use(VXETable).use(ElementPlus, { locale: zhCn }).use(dragVerify).mount('#app');
