<template>
    <div class="flex-center">
        <div id="dragContainer">
            <!-- 绿色区域 -->
            <div id="dragBg"></div>
            <!-- 滑动容器文本 -->
            <div id="dragText"></div>
            <!-- 拖动元素 -->
            <div id="dragHandler" class="dragHandlerBg"></div>
        </div>
        <!-- <div class="btn" @click="initDrag">初始化</div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            dragContainer: null,
            dragBg: null,
            dragText: null,
            dragHandler: null,
            maxHandlerOffset: null,
            is_slider_state: this.$store.state.slider_state,
        };
    },
    mounted() {
        //获取滑动控件容器,灰色背景
        this.dragContainer = document.getElementById('dragContainer');
        //获取滑块左边部分,绿色背景
        this.dragBg = document.getElementById('dragBg');
        //获取滑动验证容器文本
        this.dragText = document.getElementById('dragText');
        //获取滑块
        this.dragHandler = document.getElementById('dragHandler');

        //滑块的最大偏移量 = 滑动验证容器文本长度 - 滑块长度
        this.maxHandlerOffset = dragContainer.clientWidth - dragHandler.clientWidth;

        // 初始化
        this.initDrag();
    },
    methods: {
        // 初始化
        initDrag() {
            //在滑动验证容器文本写入“拖动滑块验证”
            this.dragText.textContent = '请按住滑块，拖动到最右边';
            //给滑块添加鼠标按下监听
            this.dragHandler.addEventListener('mousedown', this.onDragHandlerMouseDown);

            //初始化滑块移动量
            this.dragHandler.style.left = 0;
            //初始化绿色背景
            this.dragBg.style.width = 0;
            this.dragText.style.color = '';
            this.dragHandler.setAttribute('class', 'dragHandlerBg');
        },
        // 鼠标按下
        onDragHandlerMouseDown() {
            //鼠标移动监听
            document.addEventListener('mousemove', this.onDragHandlerMouseMove);
            //鼠标松开监听
            document.addEventListener('mouseup', this.onDragHandlerMouseUp);
        },
        // 鼠标移动
        onDragHandlerMouseMove() {
            //滑块移动量
            let dragX = this.dragBg?.getBoundingClientRect() || {};
            var left = event.clientX - this.dragHandler.clientWidth / 2 - dragX.left;

            if (left < 0) {
                // 滑动小于0设为0
                left = 0;
            } else if (left >= this.maxHandlerOffset) {
                // 滑动超过最大偏移量则校验成功
                left = this.maxHandlerOffset;
                this.verifySucc();
            }
            // 超过最大偏移量，不增加绿色区域宽度
            if (left > this.maxHandlerOffset) return;
            // 滑块移动量（距离左侧的定位）
            this.dragHandler.style.left = left + 'px';
            // 绿色背景的长度
            this.dragBg.style.width = left + 'px';
        },
        // 鼠标抬起
        onDragHandlerMouseUp() {
            //移除鼠标移动监听
            document.removeEventListener('mousemove', this.onDragHandlerMouseMove);
            //移除鼠标松开监听
            document.removeEventListener('mouseup', this.onDragHandlerMouseUp);
            //初始化滑块移动量
            this.dragHandler.style.left = 0;
            //初始化绿色背景
            this.dragBg.style.width = 0;
        },
        // 滑动完成
        verifySucc() {
            //容器文本的文字改为白色“验证通过”字体
            this.dragText.textContent = '验证通过';
            this.dragText.style.color = 'white';
            this.is_slider_state = true;
            this.$store.state.slider_state = true;
            //验证通过的滑块背景
            this.dragHandler.setAttribute('class', 'dragHandlerOkBg');
            //移除鼠标按下监听
            this.dragHandler.removeEventListener('mousedown', this.onDragHandlerMouseDown);
            //移除 鼠标移动监听
            document.removeEventListener('mousemove', this.onDragHandlerMouseMove);
            //移除鼠标松开监听
            document.removeEventListener('mouseup', this.onDragHandlerMouseUp);
            // alert('操作成功')
        },
    },
    watch: {
        is_slider_state: function () {
            if (this.is_slider_state === true) {
                this.$store.state.slider_state = true;
                // console.log('验证成功');
            } else {
                this.$store.state.slider_state = false;
                this.initDrag();
                // console.log('初始化');
            }
        },
        '$store.state.slider_state'() {
            this.is_slider_state = this.$store.state.slider_state;
            console.log('store.state.slider_state变化');
        },
    },
};
</script>

<style>
.flex-center {
    display: flex;
    align-items: center;
}
.btn {
    border: 1px solid blue;
    width: 60px;
    text-align: center;
    color: blue;
    height: 33px;
    line-height: 33px;
    margin-left: 15px;
    cursor: pointer;
}
/* 滑动控件容器,灰色背景 */
#dragContainer {
    position: relative;
    display: inline-block;
    background-color: #f0f0f0;
    width: 300px;
    height: 36px;
    border-radius: 2px;
    border: 1px solid #f0f0f0;
}
/* 滑块左边部分,绿色背景 */
#dragBg {
    position: absolute;
    background-color: #53c31a;
    width: 0px;
    height: 100%;
}
/* 滑动验证容器文本 */
#dragText {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: left;
    margin-left: 45px;
    line-height: 33px;
    user-select: none;
    -webkit-user-select: none;
    font-size: 12px;
    padding-top: 2px;
}
/* 滑块 */
#dragHandler {
    position: absolute;
    width: 36px;
    height: 36px;
    /* cursor: move; */
    cursor: pointer;
    border: 1px solid #bfbfbf;
    border-radius: 2px;
}
/* 滑块初始背景 */
.dragHandlerBg {
    background: #fff no-repeat center
        url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA3hpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NTc3MiwgMjAxNC8wMS8xMy0xOTo0NDowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo0ZDhlNWY5My05NmI0LTRlNWQtOGFjYi03ZTY4OGYyMTU2ZTYiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NTEyNTVEMURGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NTEyNTVEMUNGMkVFMTFFNEI5NDBCMjQ2M0ExMDQ1OUYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTQgKE1hY2ludG9zaCkiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2MTc5NzNmZS02OTQxLTQyOTYtYTIwNi02NDI2YTNkOWU5YmUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6NGQ4ZTVmOTMtOTZiNC00ZTVkLThhY2ItN2U2ODhmMjE1NmU2Ii8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+YiRG4AAAALFJREFUeNpi/P//PwMlgImBQkA9A+bOnfsIiBOxKcInh+yCaCDuByoswaIOpxwjciACFegBqZ1AvBSIS5OTk/8TkmNEjwWgQiUgtQuIjwAxUF3yX3xyGIEIFLwHpKyAWB+I1xGSwxULIGf9A7mQkBwTlhBXAFLHgPgqEAcTkmNCU6AL9d8WII4HOvk3ITkWJAXWUMlOoGQHmsE45ViQ2KuBuASoYC4Wf+OUYxz6mQkgwAAN9mIrUReCXgAAAABJRU5ErkJggg==');
}
/* 验证成功时的滑块背景 有√*/
.dragHandlerOkBg {
    background: #fff no-repeat center url('https://newstore.vynior.com/cems_web/slider-success.png');
}
</style>
