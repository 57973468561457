/*
 * @Author: ZhengJianYang
 * @Date: 2023-07-21 14:19:28
 * @LastEditors: ZhengJianYang
 * @LastEditTime: 2023-07-23 18:41:27
 * @Description:
 */
import {
  SET_ADMININFO,
  SET_EQUIPMENTOPTIONS,
  SET_ROLEINFO,
} from "./mutationTypes";
export default {
  setMsg(state, newValue) {
    state.msg = newValue;
  },
  setList(state, newValue) {
    state.list = newValue;
  },
  setName(state, newValue) {
    state.name = newValue;
  },
  setAge(state, newValue) {
    state.age = newValue;
  },
  setGender(state, newValue) {
    state.gender = newValue;
  },
  [SET_ADMININFO](state, admin_info) {
    window.localStorage.setItem("adminID", admin_info._id);
    state.admin_info = admin_info;
  },
  [SET_ROLEINFO](state, role_info) {
    state.role_info = role_info;
    // console.log(state.role_info, "role_info变化了");
  },
  [SET_EQUIPMENTOPTIONS](state, equipment_options) {
    state.equipment_options = equipment_options;
  },
  setQuestionData(state, newValue) {
    state.questionData = newValue;
    console.log(state.questionData, "state.questionData");
  },

  setInstrumentAnalyzableOptionsData(state, newValue) {
    state.InstrumentAnalyzableOptionsData = newValue;
    console.log("store中的setInstrumentAnalyzableOptionsData");
  },

  setIsResOk(state, newValue) {
    // state.isResOk = newValue;
    state.isResOk++;

    console.log("mutations中的isResOk", state.isResOk);
  },

  setQuesId(state, newValue) {
    state.quesId = newValue;
    console.log("mutations中的quesId,newValue", newValue);
  },

  setprojectQuesId(state, newValue) {
    state.projectQuesId = newValue;
    console.log("mutations中的projectQuesId,newValue", newValue);
  },

  setInsIds(state, newValue) {
    state.idsArr = newValue;
  },

  setDescriptAnalysisItem(state, newValue) {
    state.DescriptAnalysisItem = newValue;
  },
};
