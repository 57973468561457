<template>
  <div class="inttype">
    <div class="type_box">
      <div class="box_head">
        <div class="head_item" :class="{ head_items: typeStatus == '全部' }" @click="typeStatusClick('全部')">全部</div>
        <div class="head_item" :class="{ head_items: typeStatus == '有效' }" @click="typeStatusClick('有效')">有效</div>
        <div class="head_item" :class="{ head_items: typeStatus == '失效' }" @click="typeStatusClick('失效')">失效</div>
      </div>
      <vxe-toolbar>
        <template #buttons>
          <vxe-input class="Search" v-model="demo1.filterName" type="search" placeholder="请输入仪器类型进行搜索" @Blur="searchEvent2"></vxe-input>
          <vxe-button @click="reset" class="Reset"><span class="reset-icon">重置</span></vxe-button>
          <!-- ！三个分类 -->
          <img
            class="head_created"
            @click="createdClick"
            src="https://newstore.vynior.com/cems_web/1111%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2013%402x.png"
            alt="" />
        </template>
      </vxe-toolbar>
      <div class="box_table">
        <vxe-table
          border
          stripe
          align="left"
          ref="xTable"
          :scroll-y="{ mode: 'default' }"
          show-overflow
          row-id="_id"
          :data="demo1.tableData"
          :column-config="{ resizable: true }"
          :row-config="{
            isHover: true,
            keyField: 'id',
            height: linewidth <= 1280 ? 32 : 48,
            minHeight: 32,
            useKey: 'id',
          }"
          :checkbox-config="{ reserve: true }"
          :loading="demo1.loading">
          <!-- <vxe-column class="table_check" field="checked" type="checkbox" width="50" :fixed="demo1.colFixeds.col0"></vxe-column> -->
          <vxe-column :formatter="formatterRemark" field="instrType_name" title="仪器类型" :fixed="demo1.colFixeds.col1" min-width="580">
            <!-- 增加下拉箭头 -->
            <template #header>
              <div class="ID_header">
                <span class="name">仪器类型</span>
                <!-- <span class="head_jt"><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item, $event)"></i></span> -->
              </div>
            </template>
            <template #default="{ row }">
              <span>{{ row.instrType_name }}</span></template
            ></vxe-column
          >
          <vxe-column :formatter="formatterRemark" field="instrType_status" title="仪器类型状态" :fixed="demo1.colFixeds.col1" min-width="142">
            <!-- 增加下拉箭头 -->
            <template #header>
              <div class="ID_header">
                <span class="name">仪器类型状态</span>
                <!-- <span class="head_jt"><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item, $event)"></i></span> -->
              </div>
            </template>
            <template #default="{ row }">
              <div class="row_status" :class="{ row_statuss: row.instrType_status == '失效' }">
                {{ row.instrType_status }}
              </div></template
            ></vxe-column
          >
          <vxe-column :formatter="formatterRemark" field="field_list" title="仪器测试记录表头字段" :fixed="demo1.colFixeds.col1" min-width="300">
            <!-- 增加下拉箭头 -->
            <template #header>
              <div class="ID_header">
                <span class="name">仪器测试记录表头字段</span>
                <!-- <span class="head_jt"><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item, $event)"></i></span> -->
              </div>
            </template>
            <template #default="{ row }">
              <div class="row_head" v-if="row.isdefault == false">
                <div class="head_set" @click="setHeadClick(row)">配置字段</div>
                <div class="head_all">总字段:{{ row.field_list.length }}</div>
                <div class="head_up">总启用字段:{{ row.fieldNum }}</div>
              </div>
              <div v-else class="row_heads">默认字段</div>
            </template></vxe-column
          >
          <vxe-column :formatter="formatterRemark" field="setup_time" title="配置时间" :fixed="demo1.colFixeds.col1" min-width="200">
            <!-- 增加下拉箭头 -->
            <template #header>
              <div class="ID_header">
                <div class="name">配置时间</div>
                <div class="head_jt"><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item, $event)"></i></div>
              </div>
            </template>
            <template #default="{ row }">
              <span>{{ row.setup_time ? dayjs(row.setup_time).format('YYYY-MM-DD') : '——' }}</span></template
            ></vxe-column
          >
          <vxe-column :formatter="formatterRemark" field="management" title="配置者" :fixed="demo1.colFixeds.col1" min-width="174">
            <!-- 增加下拉箭头 -->
            <template #header>
              <div class="ID_header">
                <span class="name">配置者</span>
                <!-- <span class="head_jt"><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item, $event)"></i></span> -->
              </div>
            </template>
            <template #default="{ row }">
              <span>{{ row.management?.name ? row.management.name : '——' }}</span></template
            ></vxe-column
          >
          <vxe-column title="操作" align="center" min-width="215" fixed="right" :visible="true" :resizable="false">
            <!-- 增加下拉箭头 -->
            <template #header="{ column }">
              <div class="ID_header" style="display: flex; width: 100px; justify-content: space-between" v-if="column.title === '操作'">
                <div class="name">操作</div>
                <!-- <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span> -->
              </div>
            </template>
            <template #default="{ row }">
              <div class="row_set">
                <div class="set_detail" @click="lookDetaulClick(row)">查看详情</div>
                <div class="set_status" @click="upStatusClick(row, '失效')" v-if="row.instrType_status == '有效'">标记失效</div>
                <div class="set_status" @click="upStatusClick(row, '有效')" v-if="row.instrType_status == '失效'">恢复有效</div>
              </div>
              <!-- </el-tooltip> -->
            </template>
          </vxe-column>
          <template #empty>
            <span style="color: red">
              <p>没有更多数据了！</p>
            </span>
          </template>
        </vxe-table>
      </div>
      <p>
        <vxe-pager
          class="Buttons"
          background
          @page-change="pageChange"
          v-model:current-page="page5.currentPage"
          v-model:page-size="page5.pageSize"
          :total="page5.totalResult"
          :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
          <template #left>
            <div class="Total">共{{ page5.totalResult }}条</div>
          </template>
        </vxe-pager>
      </p>
    </div>

    <el-dialog class="created_dialog" v-model="createdShow" title="新建仪器类型" :show-close="true">
      <div class="body_item">
        <div class="item_tit"><span class="tit_req">*</span>仪器类型</div>
        <el-input class="item_inp" :class="{ err_inp: createdErr.name }" v-model="createdVal.instrType_name" placeholder="请输入"></el-input>
        <div class="item_err"><span v-if="createdErr.name">请输入仪器类型</span></div>
      </div>
      <div class="body_item">
        <div class="item_tit"><span class="tit_req">*</span>仪器类型状态</div>
        <el-radio-group v-model="createdVal.instrType_status" class="item_radio">
          <el-radio label="有效" size="large">有效</el-radio>
          <el-radio label="失效" size="large">失效</el-radio>
        </el-radio-group>
        <div class="item_err"><span v-if="createdErr.status">请输入仪器类型状态</span></div>
      </div>
      <div class="body_item">
        <div class="item_tit">备注</div>
        <el-input
          :show-word-limit="true"
          class="item_area"
          maxlength="100"
          type="textarea"
          v-model="createdVal.remark"
          placeholder="请输入"></el-input>
      </div>
      <div class="item_but">
        <div class="but_cancel" @click="cancelCreatedClick">取消</div>
        <div class="but_up" @click="upCreatedClick">完成</div>
      </div>
    </el-dialog>
    <!-- 表头字段设置 -->
    <el-dialog class="head_set_dialog" v-model="headSetShow" :show-close="true">
      <div class="dialog_head">
        <div class="head_title">仪器测试记录表头字段设置</div>
        <div class="head_tip">
          <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x%20%284%29.png" class="tip_img" alt="" />
          <span class="tip_text">注意:</span>
          <span>表头字段仅可新增，不能修改或删除</span>
        </div>
      </div>
      <div class="dialog_body">
        <div class="body_head">
          <div class="head_back"></div>
          自定义表头字段
        </div>
        <div class="body_null" v-if="fieldList.length == 0">
          <img class="null_img" src="https://newstore.vynior.com/%E7%BB%84%20110334%402x%20%281%29.png" alt="" />
          <div class="null_text">暂无数据</div>
        </div>
        <div v-else class="body_main">
          <div class="main_check">
            <el-checkbox v-for="(item, index) in fieldList" :key="index" v-model="item.isValid" :label="item.fieldName" size="large" />
          </div>
        </div>
        <div class="body_but">
          <div class="but_add" @click="addHeadClick"><span class="add">+</span>添加自定义表头字段</div>
          <div class="but_adds" @click="uploadData"><span class="adds">+</span>批量上传表头字段</div>
          <div class="but_cancel" @click="cancelSetClick">取消</div>
          <div class="but_up" @click="upSetClick">保存</div>
        </div>
      </div>
    </el-dialog>
    <!-- 添加表头字段 -->
    <el-dialog class="head_add_dialog" v-model="addHeadShow" title="自定义表头字段" :show-close="true">
      <div class="body_item">
        <div class="item_tit"><span class="tit_req">*</span>字段标题</div>
        <el-input class="item_inp" :class="{ err_inp: addHeadValErr.fieldName }" v-model="addHeadVal.fieldName" placeholder="请输入"></el-input>
        <div class="item_err"><span v-if="addHeadValErr.fieldName">请输入字段标题</span></div>
      </div>
      <div class="body_item">
        <div class="item_tit"><span class="tit_req">*</span>输入类型</div>
        <el-select v-model="addHeadVal.fieldType" class="item_select" :class="{ err_inp: addHeadValErr.fieldType }" placeholder="Select" size="large">
          <el-option v-for="item in inpTypeArr" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
        <div class="item_err"><span v-if="addHeadValErr.fieldType">请选择输入类型</span></div>
      </div>
      <div class="body_item">
        <div class="item_tit">注释</div>
        <el-input
          :show-word-limit="true"
          class="item_area"
          maxlength="20"
          type="textarea"
          v-model="addHeadVal.note"
          placeholder="请输入对于字段标题的注释，当鼠标悬停在表头字段时该信息会呈现"></el-input>
      </div>
      <div class="item_but">
        <div class="but_cancel" @click="cancelAddClick">取消</div>
        <div class="but_up" @click="upAddClick">完成</div>
      </div>
    </el-dialog>
    <div id="del_dialog" v-if="markShow">
      <el-dialog v-model="markShow" :close-on-click-modal="false" :align-center="true" :show-close="false">
        <template #header>
          <div class="title">确定将该数据标记为失效数据吗？</div>
        </template>
        <template #default>
          <div class="Derived_body">
            <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" alt="" />
            <div class="body_text">将数据标记失效后可以在操作栏恢复为有效数据</div>
          </div>
          <div class="Derived_but">
            <el-button class="cancel" @click="markShow = false">取消</el-button>
            <el-button class="sure" type="" @click="OkChange"> 确认 </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    <div id="del_dialog" v-if="nomarkShow">
      <el-dialog v-model="nomarkShow" :close-on-click-modal="false" :align-center="true" :show-close="false">
        <template #header>
          <div class="title">确定将该数据恢复为有效数据吗？</div>
        </template>
        <template #default>
          <div class="Derived_body">
            <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" alt="" />
            <div class="body_text">将数据恢复有效后可以在操作栏标记为有效数据</div>
          </div>
          <div class="Derived_but">
            <el-button class="cancel" @click="nomarkShow = false">取消</el-button>
            <el-button class="sure" type="" @click="OkNoMarkChange"> 确认 </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    <div class="detail_drawer" v-if="detailDrawer">
      <el-drawer v-model="detailDrawer" title="仪器类型">
        <div class="drawer_main">
          <div class="main_type">
            <div class="type_back"></div>
            仪器类型信息
          </div>
          <div class="main_item">
            <div class="item_text">仪器类型</div>
            <div class="item_val">{{ typeDetail.instrType_name }}</div>
          </div>
          <div class="main_item">
            <div class="item_text">仪器类型状态</div>
            <div class="item_val">{{ typeDetail.instrType_status }}</div>
          </div>
          <div class="main_item">
            <div class="item_text">备注</div>
            <div class="item_val" v-if="!editRemark">{{ typeDetail.remark }}</div>
            <img
              class="item_edit"
              v-if="!editRemark"
              @click="editRemarkClick"
              src="https://newstore.vynior.com/cems_web/%E7%BC%96%E8%BE%91%402x2.png"
              alt="" />
            <el-input
              v-model="typeDetail.remark"
              @blur="editUpdataBlur"
              class="item_input"
              placeholder="请输入备注"
              suffix-icon=""
              v-if="editRemark" />
          </div>
          <div class="main_types">
            <div class="type_back"></div>
            仪器测试记录表头字段配置详情
          </div>
          <div class="main_item">
            <div class="item_text">总字段</div>
            <div class="item_val">{{ typeDetail.totalCount }}</div>
          </div>
          <div class="main_item">
            <div class="item_text">启用字段</div>
            <div class="item_val">{{ typeDetail.validCount }}</div>
          </div>
          <el-table :data="typeDetail.settingList" border>
            <el-table-column prop="fieldList" label="配置字段" width="564">
              <template #default="scope">
                <div>
                  <!-- {{ scope.row.fieldList }} -->
                  <span>{{ scope.row.fieldList.join('、') }}</span>
                </div>
                <!-- <span v-for="(item, index) in scope" :key="index">{{ item < scope.length - 1 ? item + '、' : item }}</span> -->
              </template>
            </el-table-column>
            <el-table-column prop="number" label="字段数量" width="98" />
            <el-table-column prop="time" label="配置时间" width="160">
              <template #default="scope">
                <div>
                  <!-- {{ scope.row }} -->
                  {{ dayjs(scope.row.time).format('YYYY-MM-DD') }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="management" label="	配置人" width="220" />
          </el-table>
        </div>
      </el-drawer>
    </div>
    <!-- 导入 -->
    <el-dialog class="volunteer_dialog" v-model="dialogShow1" title="Shipping address" :align-center="true" :close-on-click-modal="false">
      <div class="dialog_title">
        <span>上传数据</span>
        <div class="title_cancel" @click="dialogShow1 = false"></div>
      </div>
      <div class="dialog_main">
        <div class="main_download">
          <div class="download_title">1.下载模板，填写数据信息</div>
          <div class="download_img"></div>
          <div class="upload_name"></div>
          <div class="upload_err"></div>
          <div class="download_but" @click="downloadXlsx">
            <button>下载模板</button>
          </div>
        </div>
        <div class="main_upload">
          <div class="upload_title">2.上传填写好的文件</div>
          <div class="upload_img"></div>
          <div class="upload_name"></div>
          <div class="upload_tip"></div>
          <div class="upload_but">
            <el-upload class="upload_xlsx" ref="videoRef" accept=".xlsx,.xls" :http-request="uploadFile" :show-file-list="false">
              <button>上传文件</button>
            </el-upload>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script setup>
import { ref, reactive, onMounted } from 'vue';
import dayjs from 'dayjs';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
onMounted(async () => {
  getInstTypeList();
  handleResize();
  window.addEventListener('resize', handleResize);
});
const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};
// 获取全部数据
const getInstTypeList = async () => {
  const res = await http.post('/instrument/find-instrumentType', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    status: typeStatus.value,
  });
  console.log(res, '查询列表');
  res.instrumentTypes.forEach((item, index) => {
    if (item.field_list.length > 0) {
      item.fieldNum = item.field_list.filter((items) => items.isValid === true).length;
    } else {
      item.fieldNum = 0;
    }
  });
  console.log(res.instrumentTypes);
  demo1.tableData = res.instrumentTypes;
  page5.totalResult = res.totalCount;
};
//导入
let dialogShow1 = ref(false);
// 点击批量导入
const uploadData = () => {
  dialogShow1.value = true;
};
// 点击下载模板
const downloadXlsx = async () => {
  const url = 'https://cems.fuhuan.ltd/批量上传配置仪器字段接口.xlsx';
  window.open(url, '_blank');
};
// 上传事件
const uploadFile = async (params) => {
  // if (activeIndex.value == 1) {
  console.log(params);
  const file = params.file;
  const form = new FormData();
  form.append('file', file);
  // form.append('InstrumentTypeId', setRowVal.value._id);
  await http
    .post('/upload/upload-fieldList', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async (res) => {
      console.log(res);
      if (res.status == 0) {
        res.field_list.forEach((item) => {
          const exists = fieldList.value.some((obj) => obj.fieldName === item.fieldName);
          if (!exists) {
            fieldList.value.push(item);
          }
        });
        dialogShow1.value = false;

        message({
          showClose: true,
          message: '上传数据成功',
          type: 'success',
        });
      } else if (res.status == 1) {
        message({
          showClose: true,
          message: '上传文件为空，请重新上传',
          type: 'error',
        });
      } else if (res.status == 2) {
        message({
          showClose: true,
          message: res.message,
          type: 'error',
        });
      }
    });
};
// 仪器类型的查看详情
const detailDrawer = ref(false);
// 仪器类型的详情
const typeDetail = ref({ remark: '' });
// 编辑备注状态
const editRemark = ref(false);
// 点击查看详情
const lookDetaulClick = async (row) => {
  console.log(row, 'row');
  editRemark.value = false;
  const res = await http.post('/instrument/findInfo-instrumentType', {
    instrumentTypeId: row._id,
  });
  detailDrawer.value = true;
  typeDetail.value = res.instrumentType;
  typeDetail.value.totalCount = res.totalCount;
  typeDetail.value.validCount = res.validCount;

  console.log(res, '仪器类型详情返回');
};
// 点击编辑备注
const editRemarkClick = () => {
  editRemark.value = true;
};
// 编辑备注失去焦点上传数据
const editUpdataBlur = async () => {
  const res = await http.post('/instrument/update-instrumentType', {
    _id: typeDetail.value._id,
    remark: typeDetail.value.remark,
    // isdefault: true,
  });
  console.log(res, '编辑备注返回');
  if (res.status == 0) {
    editRemark.value = false;
  } else {
    message({
      showClose: true,
      message: res.message,
      type: 'error',
    });
  }
};
// 搜索仪器的状态
const typeStatus = ref('全部');
// 查询仪器状态
const typeStatusClick = (val) => {
  page5.currentPage = 1;
  typeStatus.value = val;
  getInstTypeList();
};
// 点击重置
const reset = () => {
  demo1.filterName = '';
  page5.currentPage = 1;
  page5.pageSize = 15;
  typeStatus.value = '全部';
  getInstTypeList();
};
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [],
  filterName: '',
  loading: false,
});
//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 15,
  totalResult: 1,
});
// 点击分页
const pageChange = () => {
  getInstTypeList();
};
// 搜索列表
const searchEvent2 = () => {
  page5.currentPage = 1;
  getInstTypeList();
};
const editStatusRow = ref(null);
// 点击改变状态
const upStatusClick = async (row, val) => {
  editStatusRow.value = row;
  if (val == '失效') {
    markShow.value = true;
  } else {
    nomarkShow.value = true;
  }
  // const res = await http.post('/instrument/update-instrumentType', {
  //   instrType_name: row.instrType_name,
  //   _id: row._id,
  //   instrType_status: row.instrType_status == '有效' ? '失效' : '有效',
  // });
  // console.log(res, '改变状态返回');
  // getInstTypeList();
};
const OkChange = async () => {
  const res = await http.post('/instrument/update-instrumentType', {
    instrType_name: editStatusRow.value.instrType_name,
    _id: editStatusRow.value._id,
    instrType_status: '失效',
  });
  console.log(res, '改变状态返回');
  getInstTypeList();
  markShow.value = false;
};
const OkNoMarkChange = async () => {
  const res = await http.post('/instrument/update-instrumentType', {
    instrType_name: editStatusRow.value.instrType_name,
    _id: editStatusRow.value._id,
    instrType_status: '有效',
  });
  console.log(res, '改变状态返回');
  getInstTypeList();
  nomarkShow.value = false;
};
const markShow = ref(false);
const nomarkShow = ref(false);
// sort排序字段
let SORT = ref({ createdAt: -1 });
// 创建类型显示隐藏
const createdShow = ref(false);
// 创建仪器类型数据
const createdVal = ref({
  instrType_name: '', //仪器类型名称
  instrType_status: '', //仪器类型状态
  remark: '',
});
// 点击创建仪器类型
const createdClick = () => {
  createdVal.value = {
    instrType_name: '', //仪器类型名称
    instrType_status: '', //仪器类型状态
    remark: '',
  };
  createdErr.value = {
    name: false,
    status: false,
  };
  createdShow.value = true;
};
// 创建仪器错误提示
const createdErr = ref({
  name: false,
  status: false,
});
// 点击取消创建
const cancelCreatedClick = () => {
  createdShow.value = false;
};
// 点击完成创建仪器类型
const upCreatedClick = async () => {
  if (createdVal.value.instrType_name == '') {
    createdErr.value.name = true;
  } else {
    createdErr.value.name = false;
  }
  if (createdVal.value.instrType_status == '') {
    createdErr.value.status = true;
  } else {
    createdErr.value.status = false;
  }
  if (createdErr.value.status == true || createdErr.value.name == true) {
    return;
  }
  createdVal.value['field_list'] = [
    { fieldName: '项目编号', fieldType: '文本', isValid: true, note: '' },
    { fieldName: '志愿者编号', fieldType: '文本', isValid: true, note: '' },
  ];
  console.log(createdVal.value, 'createdVal.value');
  // return;
  const res = await http.post('/instrument/create-instrumentType', createdVal.value);
  console.log(res, '创建返回');
  getInstTypeList();
  if (res.status == 0) {
    message({
      showClose: true,
      message: '仪器类型新建成功，请配置测试记录字段',
      type: 'success',
    });
    createdShow.value = false;
  } else {
    message({
      showClose: true,
      message: res.message,
      type: 'error',
    });
  }
};
// 配置的row
const setRowVal = ref();
// 发送数据对比的row
const oldRow = ref();
// 点击配置字段
const setHeadClick = (row) => {
  // message({
  //   showClose: true,
  //   message: '注意：表头字段仅可新增，不能修改或删除',
  //   type: 'warning',
  // });
  console.log(row);
  oldRow.value = JSON.parse(JSON.stringify(row.field_list));
  setRowVal.value = JSON.parse(JSON.stringify(row));
  fieldList.value = JSON.parse(JSON.stringify(row.field_list));
  headSetShow.value = true;
};
// 仪器测试记录表头字段设置对话框
const headSetShow = ref(false);
// 配置字段设置
const fieldList = ref([
  { fieldName: '类型名称', fieldType: '我是类型', isValid: true, note: '我是注释' },
  { fieldName: '类型名称', fieldType: '我是类型', isValid: true, note: '我是注释' },
  { fieldName: '类型名称', fieldType: '我是类型', isValid: true, note: '我是注释' },
  { fieldName: '类型名称', fieldType: '我是类型', isValid: true, note: '我是注释' },
  { fieldName: '类型名称', fieldType: '我是类型', isValid: true, note: '我是注释' },
  { fieldName: '类型名称', fieldType: '我是类型', isValid: true, note: '我是注释' },
]);
// 点击添加自定义表头字段
const addHeadClick = () => {
  addHeadValErr.value = {
    fieldName: false,
    fieldType: false,
  };
  addHeadVal.value = {
    fieldName: '',
    fieldType: '',
    note: '',
  };
  addHeadShow.value = true;
};
// 添加自定义字段弹窗;
const addHeadShow = ref(false);
//  添加自定义表头字段
const addHeadVal = ref({
  fieldName: '',
  fieldType: '',
  note: '',
});
const inpTypeArr = ref([
  { label: '文本', value: '文本' },
  { label: '数值', value: '数值' },
  { label: '日期', value: '日期' },
]);
const addHeadValErr = ref({
  fieldName: false,
  fieldType: false,
});
// 点击取消创建
const cancelAddClick = () => {
  addHeadShow.value = false;
};
// 点击完成创建仪器类型
const upAddClick = async () => {
  if (addHeadVal.value.fieldName == '') {
    addHeadValErr.value.fieldName = true;
  } else {
    addHeadValErr.value.fieldName = false;
  }
  if (addHeadVal.value.fieldType == '') {
    addHeadValErr.value.fieldType = true;
  } else {
    addHeadValErr.value.fieldType = false;
  }
  if (addHeadValErr.value.fieldName == true || addHeadValErr.value.fieldType == true) {
    return;
  }
  let obj = {
    fieldName: addHeadVal.value.fieldName,
    fieldType: addHeadVal.value.fieldType,
    note: addHeadVal.value.note,
    isValid: true,
  };
  if (fieldList.value.some((item) => item.fieldName === addHeadVal.value.fieldName)) {
    message({
      showClose: true,
      message: '表头字段重复',
      type: 'error',
    });
  } else {
    fieldList.value.push(obj);
    console.log(fieldList.value);
    addHeadShow.value = false;
    message({
      showClose: true,
      message: '表头字段添加成功',
      type: 'success',
    });
  }
};
// 点击取消配置字段
const cancelSetClick = () => {
  headSetShow.value = false;
};
// 点击完成配置字段
const upSetClick = async () => {
  if (fieldList.value.length == 0) {
    message({
      showClose: true,
      message: '表头字段设置不可为空',
      type: 'error',
    });
  } else {
    let arr = [];
    console.log(fieldList.value);
    console.log(oldRow.value);
    fieldList.value.forEach((item, index) => {
      if (!oldRow.value[index] || oldRow.value[index].isValid != item.isValid) {
        arr.push(item.fieldName);
      }
    });
    console.log(arr, '改变的数据');
    if (arr.length != 0) {
      const res = await http.post('/instrument/update-instrumentTypeFiedlist', {
        _id: setRowVal.value._id,
        field_list: fieldList.value,
        instrType_name: setRowVal.value.instrType_name,
        newFieldList: arr,
      });
      console.log(res, '配置字段返回');
      if (res.status == 0) {
        message({
          showClose: true,
          message: '仪器测试记录表头字段配置成功',
          type: 'success',
        });
        headSetShow.value = false;
        getInstTypeList();
      } else {
        message({
          showClose: true,
          message: res.message,
          type: 'error',
        });
      }
    } else {
      headSetShow.value = false;
    }
  }
  // headSetShow.value=false
};
// 字段为空展示——
const formatterRemark = ({ cellValue }) => {
  //console.log('cellValue :>> ', cellValue);
  return cellValue === undefined || cellValue === '' || cellValue === null ? '——' : cellValue;
};
</script>
<style lang="less" scoped>
@import url('@/style/views/InstrumentMangement/InstrumentType.less');
</style>
