// 获取当前月份
export default function (data) {
    let currentTime = new Date();
    //
    //   let times = currentTime.toLocaleString().substring(0, 10);
    // 获取年
    let year = currentTime.getFullYear();
    //   获取月
    let month = (currentTime.getMonth() + 1).toString().padStart(2, '0');
    //   获取日
    let day = currentTime.getDate().toString().padStart(2, '0');
  if(data){
      //   获取时间
      let formattedDate = year  + '-'+month+'-'  + day;
      console.log(formattedDate);

    return formattedDate;
  }else{
    let formattedDate = year  + month  + day;
    console.log(formattedDate);

    return formattedDate;
  }

    
  }