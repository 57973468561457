<template>
  <div id="VolunteerBank">
    <CreatedVolunteer v-model="is_dialogShow" v-if="is_dialogShow"></CreatedVolunteer>
    <div class="VolunteerBox">
      <!-- 表内导航栏(筛选条件) -->
      <div class="project_main_state">
        <div class="main_state_whole">全部</div>
        <!-- <div class="main_state_item" v-for="(item, index) in ProjectState" :key="index">
          {{ item }}
        </div> -->
      </div>

      <!-- 表体 -->
      <div class="VolunteerTable">
        <div class="vxetable">
          <vxe-toolbar>
            <template #buttons>
              <span class="top_text_selsct">性别</span>

              <el-select v-model="demo1.gender" placeholder="不限性别" class="Gender">
                <el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
              <span class="top_text_selsct">年龄</span>
              <el-select v-model="demo1.age" placeholder="所有年龄" class="Age">
                <el-option v-for="item in ageList" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>

              <vxe-input
                class="Search"
                v-model="demo1.filterName"
                type="search"
                placeholder="志愿者ID、姓名、身份证号"
                @Blur="searchEvent2"></vxe-input>
              <vxe-button class="Reset"><span class="reset-icon" @click="reset">重置</span></vxe-button>
              <div class="Select" @click="ischangeShow">
                <img src="https://newstore.vynior.com/cems_web/2801693535643_.pic.jpg" alt="" />
              </div>
              <vxe-button class="Show" :class="SetShow ? 'ShowHiden' : ''"
                ><span @click.stop="SetShowFunction" class="show-icon">显示</span></vxe-button
              >
              <!-- 新建志愿者 -->
              <div class="New_voluntee" @click="CreatedVolunteerChange">
                <img src="https://newstore.vynior.com/cems_web/2791693535640_.pic.jpg" alt="" />
              </div>
              <div class="Import_volunteers" @click="exportDataEvent">
                <img src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%402x.png" alt="" /> <span class="text">导入志愿者</span>
              </div>
              <div class="batch_operation" @click.stop="isshowOperation = !isshowOperation" :class="{ clicked_batch: isshowOperation }">
                <span class="text">批量操作</span>
                <div class="arrow" v-show="!isshowOperation">
                  <img src="https://newstore.vynior.com/%E4%B8%8B%E6%8B%89.png" alt="" />
                </div>
                <div class="arrow" v-show="isshowOperation">
                  <img src="https://newstore.vynior.com/cems_web/blue.png" alt="" />
                </div>
              </div>
              <!-- 批量操作下拉框 -->
              <div class="batch_operation_box" id="operationBox" v-if="isshowOperation">
                <div class="item_box">
                  <div class="downImg"></div>
                  <!-- <img src="https://newstore.vynior.com/%E5%AF%BC%E5%87%BA%402x.png" alt="" /> -->
                  <div class="item_one_text" @click="Export">导出</div>
                </div>
                <div class="item_box1">
                  <img src="https://newstore.vynior.com/%E5%88%A0%E9%99%A4%402x.png" alt="" />
                  <div class="item_two_text" style="margin-top: 0px" @click="delect">删除</div>
                </div>
              </div>
            </template>
          </vxe-toolbar>

          <div class="tableOutSide">
            <vxe-table
              border
              stripe
              ref="xTable"
              height="auto"
              auto-resize
              show-overflow
              row-id="_id"
              :data="tableData"
              :column-config="{ resizable: true, useKey: 'id' }"
              :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
              :checkbox-config="{ reserve: true }"
              :loading="demo1.loading"
              @header-cell-dragend="handleHeaderDragend"
              @checkbox-all="selectAllChangeEvent1"
              @checkbox-change="selectChangeEvent1">
              <vxe-column field="checked" type="checkbox" width="41" min-width="40" :fixed="demo1.colFixeds.col0"></vxe-column>
              <vxe-column
                v-for="(item, index) in allDatas"
                :key="index"
                :title="item.lable"
                :field="item.field"
                type="html"
                :width="item.width"
                min-height="36"
                :fixed="item.fixed"
                :min-width="item.min_width">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <!-- {{ column.title }} -->
                  <div class="ID-header" v-if="column.title === item.lable && column.title != '操作'">
                    <span class="name">{{ item.lable }}</span>
                    <span v-if="item.lable != '操作'"
                      ><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item.lable, $event)"></i
                    ></span>
                  </div>
                  <div class="Operation-header" v-if="column.title == '操作'">
                    <!-- {{ column.title }} -->
                    <span class="name names">操作</span>
                    <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                  </div>
                </template>
                <!-- 单个数据设置 -->
                <template #default="{ row }">
                  <div class="rows" v-if="item.lable == '操作'">
                    <el-tooltip class="box-item" effect="dark" content="查看详情" placement="top">
                      <vxe-button class="detail" @click="ShowDetails(row)">查看详情</vxe-button>
                    </el-tooltip>
                    <span class="More" id="CMoreOption" @click="ClickMethods(row, $event)">···</span>
                  </div>

                  <!-- <div class="rows" v-else-if="item.lable == '项目完成进度'">
                    <div class="project-progress">
                      <span class="show-text">{{ Number(row.progress).toFixed(0) }}%</span>
                      <el-progress :percentage="row.progress" :show-text="false" />
                    </div>
                  </div>
                  <div class="rows" v-else-if="item.lable == '项目开始日期'">
                    {{ format_time(row.projectStart_time) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '项目结束日期'">
                    {{ format_time(row.projectEnd_time) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '项目产品'">
                    {{ JSON.stringify(row.product).replace(/"|\[|\]/g, '') }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '测试部位'">
                    {{ JSON.stringify(row.testArea).replace(/"|\[|\]/g, '') }}
                  </div>
                  SetVolunteerHeaderArr
                  SetRecruitVolunteerArr
                  <div class="rows" v-else-if="item.lable == '测试年龄限制'">
                    {{ JSON.stringify(row.ageRequirement).replace(/"|\[|\]/g, '') }}
                  </div> -->
                  <div class="rows" v-else-if="item.lable == '标签'">
                    {{ JSON.stringify(row.tags).replace(/"|\[|\]/g, '') }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '使用过的产品类别'">
                    {{ JSON.stringify(row.use_products).replace(/"|\[|\]/g, '') }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '皮肤问题'">
                    {{ JSON.stringify(row.skin_issues).replace(/"|\[|\]/g, '') }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '是否有基础疾病'">
                    {{ JSON.stringify(row.underlying_disease).replace(/"|\[|\]/g, '') }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '是否饮酒'">
                    {{ row.drink === undefined ? '——' : row.smoke == true ? '是' : '否' }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '是否抽烟'">
                    {{ row.smoke === undefined ? '——' : row.smoke == true ? '是' : '否' }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '首次注册时间'">
                    {{ createdAt(row.createdAt) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '历史报名项目数量'">
                    {{ FormatterNum(row.reg_total) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '历史完成项目数量'">
                    {{ FormatterNum(row.project_total) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '正在参与项目数量'">
                    {{ FormatterNum(row.parting_total) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '备注'">
                    {{ row.remark ? row.remark : '——' }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '收款账号'">
                    {{ row.account_number ? row.account_number : '——' }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '出生日期'">
                    {{ row.volun_birthday === undefined ? '——' : dayjs(row.volun_birthday).format('YYYY-MM-DD') }}
                  </div>
                  <div class="rows" v-else>
                    {{ row[item.field] }}
                  </div>
                </template>
              </vxe-column>
            </vxe-table>
          </div>

          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
                <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')" :class="!CancelFixed ? 'light' : ''">
                  <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" v-show="CancelFixed" />
                  <img src="https://newstore.vynior.com/cems_web/%E9%94%81%E5%AE%9A%402xs.png" alt="" v-show="!CancelFixed" />固定
                </li>
                <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" :class="CancelFixed ? 'light' : ''">
                  <img src="https://newstore.vynior.com/cems_web/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="!CancelFixed" />
                  <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="CancelFixed" />取消固定
                </li>

                <li id="MoreOptions" @click="HiddenColumn(FixedIndex)"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li>
              </ul>
            </div>
          </div>
          <!-- todo -->
          <!-- 每一行的 ... -->
          <div class="Options" id="CMoreOption" ref="CMoreOptions" v-show="ClickMoreOptions">
            <div class="Copy" @click="showOneExport">导出</div>
            <div class="Delete" @click="DeleteVolun">删除</div>
          </div>
          <!-- 导出一个 -->
          <div class="Derived_volunteer" v-if="isshowOneExport">
            <div class="Derived_volunteer_box" v-if="isshowOneExport">
              <div v-if="isshowOneExport">
                <div class="Derived_title">
                  确定导出<span class="Derived_name">{{ name }}</span
                  >的志愿者信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isshowOneExport = false">取消</div>
                  <div class="ok" @click="ExportFile">确认</div>
                </div>
              </div>
            </div>
          </div>
          <p v-show="demo1.tableData?.length != 0">
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr?.length }}</span
                  >条/共{{ page5.totalResult }}条
                </div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
    </div>

    <!-- 空数据页面 -->
    <div class="EmptyProject" v-if="demo1.tableData?.length == 0">
      <span>
        <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
        <p>暂无数据</p>
      </span>
    </div>
    <!-- ! 高级搜索 -->
    <div class="Select_box" v-if="Show_transform">
      <div class="Select_main">
        <!-- 头部退出 -->
        <div class="Select_top">
          <div class="Select_titel">高级搜索</div>
          <div><img @click="Show_transform = !Show_transform" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
        </div>
        <!-- 中间表单 -->
        <div class="Select_body">
          <!-- 个人基础信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">个人基础信息</div>
            </div>
            <el-form>
              <el-form-item :model="Volunteers">
                <el-form-item id="add" label="志愿者ID" class="box1">
                  <el-input v-model="Volunteers.idString" placeholder="请输入志愿者ID" />
                </el-form-item>
                <el-form-item label="姓名">
                  <el-input v-model="Volunteers.volun_name" placeholder="请输入姓名" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="性别">
                  <el-select v-model="Volunteers.volun_gender" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.volun_gender" :key="item.label" :label="item.label" :value="item.label" />
                  </el-select>
                </el-form-item>
                <el-form-item label="身份证号">
                  <el-input v-model="Volunteers.idNumber" placeholder="请输入身份证号" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="联系电话">
                  <el-input v-model="Volunteers.volun_phone" placeholder="请输入联系电话" />
                </el-form-item>
                <el-form-item label="推荐人">
                  <el-input v-model="Volunteers.referee" placeholder="请输入推荐人" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="出生日期">
                  <el-date-picker
                    v-model="Volunteers.volun_birthday"
                    format="YYYY-MM-DD"
                    type="date"
                    value-format="YYYY-MM-DD"
                    placeholder="请选择"
                    size="large" />

                  <!-- <el-select v-model="Volunteers.volun_birthday" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.date_birth" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
                <el-form-item label="年龄">
                  <el-input v-model="Volunteers.volun_age" placeholder="请输入年龄" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="所在地址">
                  <el-cascader
                    class="l_cascader"
                    v-model="Volunteers.address"
                    :options="cityData"
                    @change="handleChanges"
                    :suffix-icon="CaretBottom" />
                  <!-- <el-select v-model="Volunteers.address" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.address" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
                <el-form-item label="月收入范围">
                  <el-select v-model="Volunteers.monthly_income" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.salary" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="婚育史">
                  <el-select v-model="Volunteers.marital_history" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.marry" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="是否三期">
                  <el-select v-model="Volunteers.three_phase" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.three_times" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="标签" class="Tags">
                  <el-select
                    v-model="Volunteers.tags"
                    class="l_select"
                    placeholder="请选择"
                    size="large"
                    multiple
                    collapse-tags
                    collapse-tags-tooltip>
                    <el-option v-for="item in Totalinitialdata.tag" :key="item" :label="item" :value="item" />
                  </el-select>
                  <!-- <el-select v-model="Volunteers.tags" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.tag" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
                <el-form-item label="备注">
                  <el-input v-model="Volunteers.remark" placeholder="请输入备注" />
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <!-- 系统信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">系统信息</div>
            </div>
            <el-form>
              <el-form-item :model="Volunteers">
                <el-form-item label="首次注册时间">
                  <el-date-picker v-model="Volunteers.createdAt" type="date" placeholder="请选择" :size="size" />
                  <!-- 
                  <el-select v-model="Volunteers.createdAt" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.sign" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
                <el-form-item label="历史报名项目数量" class="ProNums">
                  <!-- <el-input-number v-model="Volunteers.reg_total" :min="0" :max="999" /> -->
                  <el-input v-model="Volunteers.reg_total" placeholder="请输入历史报名项目数量" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="历史完成项目数量" class="CompleteNums">
                  <!-- <el-input-number v-model="Volunteers.project_total" :min="0" :max="999" /> -->
                  <el-input v-model="Volunteers.project_total" placeholder="请输入历史完成项目数量" />
                </el-form-item>
                <el-form-item label="正在参与项目数量" class="CurrentNums">
                  <!-- <el-input-number v-model="Volunteers.test_total" :min="0" :max="999" /> -->
                  <el-input v-model="Volunteers.parting_total" placeholder="请输入正在参与项目数量" />
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <!-- 皮肤信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">皮肤信息</div>
            </div>
            <el-form>
              <el-form-item :model="Volunteers">
                <el-form-item label="皮肤肤质">
                  <el-select v-model="Volunteers.skin_texture" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.skin_type" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="皮肤类型">
                  <el-select v-model="Volunteers.skin_type" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.type" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="皮肤问题" class="skinProgram">
                  <el-select placeholder="请选择" v-model="Volunteers.skin_issues" multiple collapse-tags collapse-tags-tooltip>
                    <el-option v-for="item in Totalinitialdata.skin_problem" :key="item" :label="item" :value="item" />
                  </el-select>
                  <!-- <el-select v-model="Volunteers.skin_issues" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.skin_problem" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
                <el-form-item label="皮肤肤色">
                  <el-select v-model="Volunteers.skin_color" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.skin_color" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="使用过的产品类别" class="useProduct">
                  <el-select
                    @change="change_category_val"
                    placeholder="请选择"
                    v-model="Volunteers.use_products"
                    multiple
                    collapse-tags
                    collapse-tags-tooltip>
                    <el-option v-for="item in Totalinitialdata.product_type" :key="item" :label="item" :value="item" />
                  </el-select>
                  <!-- <el-select v-model="Volunteers.use_products" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.product_type" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <!-- 健康信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">健康信息</div>
            </div>
            <el-form>
              <el-form-item :model="Volunteers">
                <el-form-item label="是否抽烟">
                  <el-select v-model="Volunteers.smoke" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.smoking" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="是否饮酒">
                  <el-select v-model="Volunteers.drink" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.drink" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="身体健康情况" class="bodyHealth">
                  <el-select
                    placeholder="请选择"
                    @change="change_basic_illness"
                    v-model="Volunteers.underlying_disease"
                    multiple
                    collapse-tags
                    collapse-tags-tooltip>
                    <el-option v-for="item in Totalinitialdata.fitness" :key="item" :label="item" :value="item" />
                  </el-select>
                  <!-- <el-select v-model="Volunteers.underlying_disease" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.fitness" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 底部按钮 -->
        <div class="Select_footer">
          <div class="null" @click="unSelect"><img src="https://new.vynior.com/%E7%BB%84%E4%BB%B6879%402x.png" alt="" /></div>
          <div class="onselect" @click="reqAllSelectData"><img src="https://new.vynior.com/%E7%BB%84%E4%BB%B6869%402x.png" alt="" /></div>
        </div>
      </div>
    </div>
    <!-- !表头字段设置 -->
    <div class="SetTableHeader" v-if="SetTableHeader">
      <div class="main">
        <div class="Header">
          <div class="text">
            表头字段设置 <img @click="CancelShowHiden" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402x.png" alt="" />
          </div>
        </div>
        <!-- 设置表头的数据盒子 -->
        <div class="SetBox">
          <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
          <div class="LeftBox">
            <div class="text-header">志愿者库预设字段</div>
            <div class="ProjectRowField">
              <div class="text-header1">【个人基本信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetVolunteerHeaderArr.slice(0, 15)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                </div>
              </div>
            </div>
            <!-- 【系统信息】 -->
            <div class="ProjectRecruitMSG">
              <div class="text-header2">【系统信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetVolunteerHeaderArr.slice(15, 19)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 15)" />
                </div>
              </div>
            </div>

            <!-- 【皮肤信息】 -->
            <div class="SkinMsg">
              <div class="text-header3">【皮肤信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetVolunteerHeaderArr.slice(19, 24)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 19)" />
                </div>
              </div>
            </div>
            <!-- 【健康信息】 -->
            <div class="HealthMsg">
              <div class="text-header4">【健康信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetVolunteerHeaderArr.slice(24, 27)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 24)" />
                </div>
              </div>
            </div>
            <!-- 【收款信息】 -->
            <div class="PayeeMsg">
              <div class="text-header5">【收款信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetVolunteerHeaderArr.slice(27, 30)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 27)" />
                </div>
              </div>
            </div>
            <!-- 【项目基本信息】 -->
            <div class="ProjectMsg">
              <div class="text-header6">【项目基本信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetVolunteerHeaderArr.slice(30, 32)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 30)" />
                </div>
              </div>
            </div>
            <div class="Self-Defined">自定义扩展字段</div>
          </div>
          <!-- 右边盒子 拖动 -->
          <div class="RightBox">
            <div class="text-header">列表页展示字段</div>
            <!-- 调整数据 -->
            <div class="switchBox">
              <div id="xxx" class="demo">
                <draggable
                  :list="dragDemo"
                  ghost-class="ghost"
                  :force-fallback="true"
                  chosen-class="chosenClass"
                  animation="300"
                  handle=".move"
                  delay="100"
                  @end="onEnd">
                  <template #item="{ element }">
                    <div class="item move">
                      <div class="drag"></div>
                      {{ element.lable }}
                      <!-- -->
                      <img
                        class="top"
                        @click="backTop(element, 'top')"
                        src=" https://newstore.vynior.com/cems_web/%E7%BB%841112%402x.png   "
                        alt="" />
                      <img
                        class="delete"
                        @click="backTop(element, 'delete')"
                        :src="
                          defaultHeader.indexOf(element.lable) != -1
                            ? 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88s%402x.png'
                            : 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88221%402x.png'
                        "
                        alt="" />
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="Bottom">
          <div class="add">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
          </div>
          <div class="recover" @click="RecoverData">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
          </div>
          <div class="cancel" @click="CancelShowHiden">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" />
          </div>
          <div class="save" @click="ShowHidenColumn">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 批量志愿者信息  导出 -->
    <div class="Derived_volunteer" v-if="isDerived">
      <div class="Derived_volunteer_box">
        <!-- 导出一个 -->
        <div v-if="checkedArr?.length == 1">
          <div class="Derived_title">
            确定导出<span class="Derived_name">{{ selectedRows[0].volun_name == ' ' ? '无名字' : selectedRows[0].volun_name }}</span
            >的志愿者信息吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">导出后可在excel表格中进行查看</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived">取消</div>
            <div class="ok" @click="isshowDerivedtrue">确认</div>
          </div>
        </div>
        <!-- 导出多个 -->
        <div v-if="checkedArr?.length > 1">
          <div class="Derived_title">
            已选中<span class="Derived_name">&nbsp;{{ checkedArr?.length }}条&nbsp;</span>志愿者信息，确认导出吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">导出后可在excel表格中进行查看</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived">取消</div>
            <div class="ok" @click="isshowDerivedtrue">确认</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 批量志愿者信息  删除 -->
    <div class="Derived_volunteer_delect" v-if="isDerived_delect">
      <div class="Derived_volunteer_box">
        <!-- 批量操作删除一个 -->
        <div v-if="selectedRows?.length == 1 && oneDerivedData == null">
          <div class="Derived_title">
            确认删除&nbsp;&nbsp;
            <span class="Derived_name"> {{ selectedRows[0].volun_name == ' ' ? '无名字' : selectedRows[0].volun_name }} </span>
            &nbsp;&nbsp; 的志愿者信息吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived_delect">取消</div>
            <div class="ok" @click="isshowDerived_delect_true">删除</div>
          </div>
        </div>
        <!-- 批量操作删除多个 -->
        <div v-if="selectedRows?.length > 1 && oneDerivedData == null">
          <div class="Derived_title">
            已选中&nbsp;&nbsp;<span class="Derived_name">{{ selectedRows?.length }}条</span>&nbsp;&nbsp;志愿者信息，确认删除吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived_delect">取消</div>
            <div class="ok" @click="isshowDerived_delect_true">删除</div>
          </div>
        </div>
        <!-- !单个删除操作 -->
        <div v-if="oneDerivedData">
          <div class="Derived_title">
            确认删除&nbsp;&nbsp;<span class="Derived_name"> {{ oneDerivedData.volun_name == ' ' ? '无名字' : oneDerivedData.volun_name }} </span
            >&nbsp;&nbsp;的志愿者信息吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived_delect">取消</div>
            <div class="ok" @click="oneDeleteVolun">删除</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 导出一条没有的时候 -->
    <div class="Derived_volunteer_null" v-if="null_reminder_export">
      <!-- 导出 -->
      <div class="Derived_volunteer_box">
        <div class="Derived_title">
          <div class="Derived_name">提示</div>
          <div><img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
        </div>
        <div class="Derived_body">
          <div><img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" /></div>
          <div class="Derived_body_text">请选择需要导出的志愿者信息！</div>
        </div>
      </div>
    </div>
    <!-- 无数据删除 -->
    <div class="Derived_volunteer_null" v-if="null_reminder_delect">
      <div class="Derived_volunteer_box">
        <div class="Derived_title">
          <div class="Derived_name">提示</div>
          <div><img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
        </div>
        <div class="Derived_body">
          <div><img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" /></div>
          <div class="Derived_body_text">请选择需要删除的志愿者信息！</div>
        </div>
      </div>
    </div>
    <!-- 导入 -->
    <el-dialog class="volunteer_dialog" v-model="dialogShow" title="Shipping address" :align-center="true" :close-on-click-modal="false">
      <div class="dialog_title">
        <span>导入志愿者</span>
        <div class="title_cancel" @click="dialogShow = false"></div>
      </div>
      <div class="dialog_main">
        <div class="main_download">
          <div class="download_title">1.下载模板，填写志愿者信息</div>
          <div class="download_img"></div>
          <div class="upload_name"></div>
          <div class="upload_err"></div>
          <div class="download_but" @click="downloadXlsx">
            <button>下载模板</button>
          </div>
        </div>
        <div class="main_upload">
          <div class="upload_title">2.上传填写好的文件</div>
          <div class="upload_img"></div>
          <div class="upload_name">
            <span v-if="uploadFileName">{{ uploadFileName }}123</span>
          </div>
          <div class="upload_tip">
            <span v-if="uploadFileErr">文件格式错误，请重新上传</span>
            <span class="tip_up" v-if="uploadFileName && !uploadFileErr">重新选择</span>
          </div>
          <div class="upload_but">
            <el-upload
              class="upload_xlsx"
              ref="videoRef"
              accept=".xlsx,.xls"
              :http-request="uploadFile"
              :on-preview="handlePreviews"
              :on-remove="handleRemoves"
              :file-list="fileLists"
              :show-file-list="false">
              <button>上传文件</button>
            </el-upload>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 导入志愿者失败 -->
    <el-dialog class="volunteer_dialogs" :align-center="true" v-model="errdialogShow" title="Shipping address" :close-on-click-modal="false">
      <div class="dialog_title">
        <span>失败</span>
        <div class="title_cancel" @click="errdialogShow = false"></div>
      </div>
      <div class="dialog_main">
        <img class="main_err" src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402xs.png" alt="" />
        <div class="main_fail">导入失败</div>
        <div class="main_errT1">
          表格中存在<span class="tip">{{ errFileNum }}条</span>有误的志愿者信息
        </div>
        <div class="main_errT2">请下载报错文件，修改后重新导入志愿者。</div>
        <div class="main_but" @click="downloadErrFile">
          <button>下载文件</button>
        </div>
      </div>
    </el-dialog>
    <!-- 抽屉志愿者详情 -->
    <!-- tip---------------------- -->
    <el-drawer v-model="Volunteerdetails" :close-on-click-modal="Volunteereditor" title="I am the title" :with-header="false" size="57%">
      <div class="drawer">
        <div class="deawer_title">
          <div class="deawer_title_text">志愿者信息</div>
          <div>
            <img @click="isVolunteereditor" v-if="Volunteereditor" src="https://newstore.vynior.com/%E7%BC%96%E8%BE%91.png" alt="" />
          </div>
        </div>
        <div class="drawer_body">
          <!-- 个人基础信息  查看状态 -->
          <div class="information_box" v-if="Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">个人基础信息</div>
            </div>
            <div class="item_main_box">
              <div class="item1">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">志愿者ID</div>
                      <div class="item_main_text_value">{{ information._id }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>姓名</div>
                      <div class="item_main_text_value">{{ information.volun_name }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>性别</div>
                      <div class="item_main_text_value">{{ information.volun_gender }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>身份证号</div>
                      <div class="item_main_text_value">{{ information.idNumber }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>联系电话</div>
                      <div class="item_main_text_value">{{ information.volun_phone }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>推荐人</div>
                      <div class="item_main_text_value">{{ information.referee }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">出生日期</div>
                      <div class="item_main_text_value">{{ information.volun_birthday.substring(0, 10) }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">年龄</div>
                      <div class="item_main_text_value">{{ information.volun_age }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">所在地址</div>
                      <div class="item_main_text_value">{{ information.address }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">月收入范围</div>
                      <div class="item_main_text_value">
                        {{ information.monthly_income }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">婚育史</div>
                      <div class="item_main_text_value">
                        {{ information.marital_history }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否三期</div>
                      <div class="item_main_text_value">
                        {{ information.three_phase }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">标签</div>
                      <div class="item_main_text_value">
                        <div v-if="information.tags !== ''" class="item_main_text_value">
                          <span v-for="(item, index) in information.tags" :key="item">
                            {{ item }}{{ index === information.tags?.length - 1 ? ' ' : ' 、' }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">备注</div>
                      <div class="item_main_text_value">{{ information.remark ? information.remark : '' }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 编辑抽屉显示 -->
          <div class="information_box" v-if="!Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">个人基础信息</div>
            </div>
            <div class="item_main_box">
              <div class="item1">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">志愿者ID</div>
                      <div class="item_main_text_value">{{ information._id }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>姓名</div>
                      <el-input
                        placeholder="请输入姓名"
                        :class="errObj.name ? 'inp_err' : ''"
                        @focus="errObj.name = false"
                        @input="is_inputName"
                        v-model="information.volun_name" />
                    </div>
                    <div class="err_text">
                      <span v-if="errObj.name">{{ name_error }}</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>性别</div>
                      <el-select @input="is_inputSex" v-model="information.volun_gender" class="m-2" placeholder="请选择" size="large">
                        <el-option v-for="item in Totalinitialdata.volun_gender" :key="item.label" :label="item.label" :value="item.label" />
                      </el-select>
                    </div>
                    <div class="err_text"><span v-if="errObj.sex">性别不可为空</span></div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>身份证号</div>
                      <el-input
                        :maxlength="18"
                        :minlength="15"
                        @input="is_inputCard"
                        suffix-icon=""
                        :class="errObj.card ? 'inp_err' : ''"
                        @focus="errObj.card = false"
                        @change="inputCardChange"
                        v-model="information.idNumber" />
                    </div>
                    <div class="err_text">
                      <span v-if="errObj.card">{{ card_error }}</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item item1">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>联系电话</div>
                      <el-input
                        placeholder="请输入联系电话"
                        suffix-icon=""
                        @input="is_inputPhone"
                        :class="errObj.phone ? 'inp_err' : ''"
                        @focus="errObj.phone = false"
                        v-model="information.volun_phone" />
                    </div>
                    <div class="err_text">
                      <span v-if="errObj.phone">{{ phone_error }}</span>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>推荐人</div>
                      <el-input
                        placeholder="请输入推荐人"
                        suffix-icon=""
                        @input="is_inputReferrer"
                        :class="errObj.referrer ? 'inp_err' : ''"
                        @focus="errObj.referrer = false"
                        v-model="information.referee" />
                    </div>
                    <div class="err_text">
                      <span v-if="errObj.referrer">{{ referrer_error }}</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">出生日期</div>
                      <!-- <div class="l_main" :class="errObj.date ? 'inp_err' : ''"></div> -->
                      <el-date-picker
                        disabled
                        v-model="information.volun_birthday"
                        @change="showAge"
                        type="date"
                        placeholder="请选择"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        @focus="errObj.date = false"
                        :size="size" />
                    </div>
                    <!-- <div class="err_text"><span v-if="errObj.date">必选项不可为空</span></div> -->
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">年龄</div>
                      <div class="item_main_text_value">{{ information.volun_age }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">所在地址</div>
                      <el-cascader class="l_cascader" v-model="information.address" :options="cityData" @change="handleChange" />
                      <!-- <div class="err_text"><span v-if="errObj.address">必选项不可为空</span></div> -->
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">月收入范围</div>
                      <el-select v-model="number_salary" class="m-2" @change="salaryChange" placeholder="请选择" size="large">
                        <el-option v-for="item in Totalinitialdata.salary" :key="item.label" :label="item.label" :value="item.label" />
                      </el-select>
                      <!-- <div class="err_text"><span v-if="errObj.money">必选项不可为空</span></div> -->
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">婚育史</div>
                      <el-select v-model="information.marital_history" class="m-2" placeholder="请选择" size="large">
                        <el-option v-for="item in Totalinitialdata.marry" :key="item.label" :label="item.label" :value="item.label" />
                      </el-select>
                      <!-- <div class="err_text"><span v-if="errObj.Marriage">必选项不可为空</span></div> -->
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否三期</div>
                      <el-select v-model="information.three_phase" class="m-2" placeholder="请选择" size="large">
                        <el-option v-for="item in Totalinitialdata.three_times" :key="item.label" :label="item.label" :value="item.label" />
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">标签</div>
                      <el-select
                        v-model="information.tags"
                        class="m-2"
                        placeholder="请选择"
                        size="large"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip>
                        <el-option
                          v-for="item in Totalinitialdata.tag"
                          :key="item"
                          :label="item"
                          :value="item"
                          :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">备注</div>
                      <el-input :maxlength="20" :class="errObj.remark ? 'inp_err' : ''" v-model="information.remark" @input="is_inputRemark" />
                    </div>
                    <div class="err_text">
                      <span v-if="errObj.remark">{{ remarkErr }}</span>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 系统信息 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">系统信息</div>
            </div>
            <div class="item_main_box">
              <div class="item2" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">首次注册时间</div>
                      <div class="item_main_text_value">{{ dayjs(information.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">历史报名项目数量</div>
                      <div class="item_main_text_value">{{ information.reg_total }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item2">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">历史完成项目数量</div>
                      <div class="item_main_text_value">{{ information.project_total }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">正在参与项目数量</div>
                      <div class="item_main_text_value">{{ information.parting_total }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 历史完成项目 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">历史完成项目</div>
            </div>
            <div class="History_item_table">
              <el-table border :data="finish_projects" v-if="finish_projects?.length != 0" stripe style="width: 100%" class="T-Header">
                <el-table-column type="index" label="序号" align="center" width="60" />
                <el-table-column prop="number" label="项目编号" width="250" />
                <el-table-column prop="name" label="项目名称" width="190" />
                <el-table-column prop="testAddress" label="测试场所" width="225" />
                <el-table-column prop="projectStart_time" label="项目开始日期" width="145">
                  <template #default="scope">
                    {{ dayjs(scope.row.projectStart_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
                <el-table-column prop="projectEnd_time" label="项目结束日期" width="145">
                  <template #default="scope">
                    {{ dayjs(scope.row.projectEnd_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="EmptyData" v-if="finish_projects?.length == 0">该志愿者无历史完成项目</div>
          </div>
          <!-- 正在参与项目 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">正在参与项目</div>
            </div>
            <div class="History_item_table" v-if="attend_projects?.length != 0">
              <el-table stripe border :data="attend_projects" style="width: 100%" class="T-Header">
                <el-table-column type="index" label="序号" align="center" width="60" />
                <el-table-column prop="number" label="项目编号" width="252" />
                <el-table-column prop="name" label="项目名称" width="196" />
                <el-table-column prop="testAddress" label="测试场所" width="144" />
                <el-table-column prop="status" label="项目状态" width="88" />
                <el-table-column prop="projectStart_time" label="项目开始日期" width="140">
                  <template #default="scope">
                    {{ dayjs(scope.row.projectStart_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
                <el-table-column prop="projectEnd_time" label="项目结束日期" width="140">
                  <template #default="scope">
                    {{ dayjs(scope.row.projectEnd_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="EmptyData" v-if="attend_projects?.length == 0">该志愿者无正在参与项目</div>
          </div>
          <!-- 皮肤信息  查看状态 -->
          <div class="information_box" v-if="Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">皮肤信息</div>
            </div>
            <div class="item_main_box">
              <div class="item1" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤肤质</div>
                      <div class="item_main_text_value">{{ information.skin_texture != '' ? information.skin_texture : '———' }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤类型</div>
                      <div class="item_main_text_value">{{ information.skin_type != '' ? information.skin_type : '———' }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤问题</div>
                      <div class="item_main_text_value" v-if="information.skin_issues?.length != 0">
                        <span v-for="(item, index) in information.skin_issues" :key="index">
                          {{ item != undefined ? item : '———' }}{{ index === information.skin_issues?.length - 1 ? ' ' : ' 、' }}</span
                        >
                      </div>
                      <div class="item_main_text_value" v-if="information.skin_issues?.length == 0">
                        <span> {{ '———' }}</span>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤肤色</div>
                      <div class="item_main_text_value">{{ information.skin_color != '' ? information.skin_color : '———' }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">使用过的产品类别</div>
                      <div class="item_main_text_value" v-if="information.use_products?.length != 0">
                        <span v-for="(item, index) in information.use_products" :key="index">
                          {{ item != undefined ? item : '———' }}{{ index === information.use_products?.length - 1 ? ' ' : ' 、' }}</span
                        >
                      </div>
                      <div class="item_main_text_value" v-if="information.use_products?.length == 0">
                        <span> {{ '———' }}</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div class="information_box" v-if="!Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">皮肤信息</div>
            </div>
            <div class="item_main_box">
              <div class="item1" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤肤质</div>
                      <el-select v-model="information.skin_texture" class="m-2" placeholder="请选择" size="large">
                        <el-option v-for="item in Totalinitialdata.skin_type" :key="item.label" :label="item.label" :value="item.label" />
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤类型</div>
                      <el-select v-model="information.skin_type" class="m-2" placeholder="请选择" size="large">
                        <el-option v-for="item in Totalinitialdata.type" :key="item.label" :label="item.label" :value="item.label" />
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤问题</div>
                      <el-select
                        v-model="information.skin_issues"
                        class="m-2"
                        placeholder="请选择"
                        size="large"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip>
                        <el-option v-for="item in Totalinitialdata.skin_problem" :key="item" :label="item" :value="item" />
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤肤色</div>
                      <el-select v-model="information.skin_color" class="m-2" placeholder="请选择" size="large">
                        <el-option v-for="item in Totalinitialdata.skin_color" :key="item.label" :label="item.label" :value="item.label" />
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">使用过的产品类别</div>
                      <el-select
                        v-model="information.use_products"
                        class="m-2"
                        placeholder="请选择"
                        size="large"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip>
                        <el-option v-for="item in Totalinitialdata.product_type" :key="item" :label="item" :value="item" />
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 健康信息 查看状态 -->
          <div class="information_box" v-if="Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">健康信息</div>
            </div>
            <div class="item_main_box">
              <div class="item2" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否抽烟</div>
                      <div class="item_main_text_value">
                        {{ is_output(information.smoke) != '' ? is_output(information.smoke) : '———' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否饮酒</div>
                      <div class="item_main_text_value">
                        {{ is_output(information.drink) != '' ? is_output(information.drink) : '———' }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item2">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">身体健康情况</div>
                      <div class="item_main_text_value" v-if="information.underlying_disease?.length != 0">
                        <span v-for="(item, index) in information.underlying_disease" :key="item"
                          >{{ item }}{{ index === information.underlying_disease?.length - 1 ? ' ' : ' 、' }}</span
                        >
                      </div>
                      <div class="item_main_text_value" v-if="information.underlying_disease?.length == 0">
                        <span>{{ '———' }}</span>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div class="information_box" v-if="!Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">健康信息</div>
            </div>
            <div class="item_main_box">
              <div class="item2" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否抽烟</div>
                      <el-select v-model="information.smoke" class="m-2" placeholder="请选择" size="large">
                        <el-option v-for="item in Totalinitialdata.smoking" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否饮酒</div>
                      <el-select v-model="information.drink" class="m-2" placeholder="请选择" size="large">
                        <el-option v-for="item in Totalinitialdata.drink" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item2">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">身体健康情况</div>
                      <el-select
                        v-model="information.underlying_disease"
                        @change="change_basic_illness2"
                        class="m-2"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip
                        placeholder="请选择"
                        size="large">
                        <el-option v-for="item in Totalinitialdata.fitness" :key="item" :label="item" :value="item" />
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 收款信息 -->
          <div class="information_box" v-if="Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">收款信息</div>
            </div>
            <div class="item_main_box">
              <div class="item" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款户名</div>
                      <div class="item_main_text_value">{{ information.account_name != '' ? information.account_name : '———' }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款账号</div>
                      <div class="item_main_text_value">{{ information.account_number != '' ? information.account_number : '———' }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款银行</div>
                      <div class="item_main_text_value">{{ information.bank != '' ? information.bank : '———' }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div class="information_box" v-if="!Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">收款信息</div>
            </div>
            <div class="item_main_box">
              <div class="item" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款户名</div>
                      <el-input @input="isDecimal" :maxlength="15" v-model="information.account_name" placeholder="请输入收款户名" />
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款账号</div>
                      <el-input :maxlength="19" @input="AreInteger" v-model="information.account_number" placeholder="请输入收款账号" />
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款银行</div>
                      <el-input :maxlength="20" @input="isDecimal" v-model="information.bank" placeholder="请输入收款银行" />
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 文件 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">文件</div>
            </div>
            <div class="file" v-if="!Volunteereditor">
              <el-upload class="upload_xlsx" ref="videoRef" :http-request="uploadsFile" :show-file-list="false" :disabled="Volunteereditor">
                <img src="https://newstore.vynior.com/cems_web/%E4%B8%8A%E4%BC%A0%402x.png" alt="" />

                <span>上传附件</span>
              </el-upload>
            </div>
            <div class="files" v-if="Volunteereditor">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%208111%402x.png" alt="" />
            </div>
            <el-table :data="attachmentList" border stripe v-show="attachmentList?.length">
              <el-table-column type="index" width="60" label="序号" />
              <el-table-column prop="fileName" label="文件名称" width="180" />
              <el-table-column prop="uploadTime" :formatter="formatterTime" label="上传时间" width="180" />
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button @click="downloadAttachment(scope.row)">下载</el-button>
                  <el-button :disabled="Volunteereditor" @click="deleteFile(scope.row.uuid)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 抽屉底部 -->
        <div class="drawer_footer" v-if="!Volunteereditor">
          <div class="null"><img @click="cencelDataChange" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" /></div>
          <div class="next"><img @click="updatedata" src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" /></div>
        </div>
      </div>
    </el-drawer>
    <!-- tip---------------------- -->
    <!-- 显示功能弹窗 -->
    <div class="SetShowsBox" ref="ShowBox" v-show="SetShow" id="SetShowsBoxs">
      <div class="Titles" id="SetShowsBoxs">字段显示</div>
      <div class="ContentBox" :class="AllHidenHeader?.length == 0 ? 'Emptys' : ''" id="SetShowsBoxs">
        <span class="Empty" v-if="AllHidenHeader?.length == 0" id="SetShowsBoxs">暂无隐藏字段</span>
        <div class="ListBox" v-if="AllHidenHeader?.length != 0" id="SetShowsBoxs">
          <div class="List" v-for="(item, index) in AllHidenHeader" :key="index" @click="ShowCheck[index] = !ShowCheck[index]" id="SetShowsBoxs">
            <div class="item" id="SetShowsBoxs">{{ item.title }}</div>
            <div class="Active" v-show="ShowCheck[index]" id="SetShowsBoxs">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110500%402x.png" alt="" />
            </div>
          </div>
          <div
            class="Button"
            @click="ConfirmRecover"
            id="SetShowsBoxs"
            :class="AllHidenHeader?.length == 1 ? 'Buttons' : AllHidenHeader?.length == 2 ? 'Buttonss' : ''">
            确认
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import CreatedVolunteer from '@/components/VolunteerManagement/CreatedVolunteer.vue'
import cityData from '@/utils/citydata'
import { replaceEmptyValues } from '@/utils/emptyArray'
import getFilename from '@/utils/getFilename'
import http from '@/utils/http'
import { message } from '@/utils/resetMessage'
import axios from 'axios'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as xlsx from 'xlsx'
import { CaretBottom } from '@element-plus/icons-vue'
import draggable from 'vuedraggable'
import ase from '@/utils/cryptoUtils'

const linewidth = ref(window.innerWidth)
const handleResize = () => {
  linewidth.value = window.innerWidth
}

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
// 导出的当前时间
let ExportDate = ref('')
// !表单空白处理
const tableData = computed(() => {
  return replaceEmptyValues(demo1.tableData)
})
// 上传附件返回信息
const attachmentList = ref([])
// 上传附件事件
const uploadsFile = async (params) => {
  const file = params.file
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'attachment')
  const response = await http.post('/upload/file-upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  const { status, file: fileData } = response
  if (status === 0) {
    attachmentList.value.push(fileData)
    ElMessage({
      message: '附件上传成功',
      // grouping: true,
      showClose: true,
      type: 'success',
    })
  } else {
    ElMessage({
      message: '附件上传失败，请稍后再试',
      // grouping: true,
      showClose: true,
      type: 'warning',
    })
  }
}
//限制必须是整数
const isDecimal = () => {
  console.log('hahahah')
  if (information.value.account_name.includes('.')) {
    information.value.account_name = ''
  }
  if (information.value.bank.includes('.')) {
    information.value.bank = ''
  }
}
const AreInteger = () => {
  const pattern = /^\d*$/
  if (!pattern.test(information.value.account_number)) {
    information.value.account_number = ''
  }
}

// 格式化时间
const formatterTime = (row, column, cellValue, index) => {
  return dayjs(cellValue).format('YYYY-MM-DD HH:mm')
}
let defaultHeader = ref(['志愿者ID', '姓名', '操作'])
// 被删除附件（已保存）
const deleteFiles = ref([])
// 删除附件
const deleteFile = (data) => {
  console.log('data iiiiiidddddd:>> ', data)
  attachmentList.value.forEach((attachment, index) => {
    if (attachment.uuid == data) {
      if (attachment.downloadPath) {
        deleteFiles.value.push(attachment.uuid)
      }
      attachmentList.value.splice(index, 1)
    }
  })
}
// 下载附件
const downloadAttachment = async (data) => {
  const { downloadPath, filePath } = data
  const repsonse = await http.post(
    'https://cems.fuhuan.ltd/api/upload/attachmentDownload',
    { downloadPath: downloadPath ? downloadPath : filePath },
    { responseType: 'arraybuffer' }
  )
  const blob = new Blob([repsonse], { type: 'application/x-zip-compressed;charset=utf-8' })
  let name = '附件下载.zip'
  const downloadElement = document.createElement('a')
  let classificationhref = window.URL.createObjectURL(blob)
  downloadElement.href = classificationhref
  downloadElement.download = `${name}`
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(classificationhref)
}
const is_dialogShow = ref(false)
watch(
  () => is_dialogShow.value,
  () => {
    if (is_dialogShow.value === false) {
      reqProjectList()
    }
  }
)

const handlerDialog = () => {
  dialogFormVisible.value = true
}
const remarkErr = ref('备注最多20个字')
//API接收
const store = useStore()
const router = useRouter()
const xTable = ref()
// table组件数据
let totalCount = ref(0)
const SetTableHeader = ref(false)
const CheckId = reactive([])
let CancelFixed = ref(false) //控制每个表头下拉箭头 固定和取消固定的高亮

// 查看详情里的长在参与项目
const attend_projects = ref([])
// 查看详情里的历史完成项目
const finish_projects = ref([])
// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([])
//表头字段设置--->设置表头的勾选数据
let SetVolunteerHeaderArr = ref([
  { width: '136', min_width: '136', fixed: '', field: '_id', checked: true, lable: '志愿者ID' },
  { width: '112', min_width: '112', fixed: '', field: 'volun_name', checked: true, lable: '姓名' },
  { width: '72', min_width: '72', fixed: '', field: 'volun_gender', checked: true, lable: '性别' },
  { width: '184', min_width: '184', fixed: '', field: 'idNumber', checked: true, lable: '身份证号' },
  { width: '136', min_width: '136', fixed: '', field: 'volun_phone', checked: true, lable: '联系电话' },
  { width: '100', min_width: '100', fixed: '', field: 'referee', checked: false, lable: '推荐人' },
  { width: '116', min_width: '116', fixed: '', field: 'volun_birthday', checked: true, lable: '出生日期' },
  { width: '72', min_width: '72', fixed: '', field: 'volun_age', checked: true, lable: '年龄' },
  { width: '120', min_width: '120', fixed: '', field: 'address', checked: true, lable: '所在地址' },
  { width: '176', min_width: '176', fixed: '', field: 'monthly_income', checked: false, lable: '月收入范围' },
  { width: '176', min_width: '176', fixed: '', field: 'marital_history', checked: false, lable: '婚育史' },
  { width: '176', min_width: '176', fixed: '', field: 'three_phase', checked: false, lable: '是否三期' },
  { width: '84', min_width: '84', fixed: '', field: 'tags', checked: false, lable: '标签' },
  { width: '90', min_width: '90', fixed: '', field: 'remark', checked: true, lable: '备注' },
  { width: '136', min_width: '136', fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  { width: '152', min_width: '152', fixed: '', field: 'createdAt', checked: true, lable: '首次注册时间' },
  { width: '176', min_width: '176', fixed: '', field: 'reg_total', checked: true, lable: '历史报名项目数量' },
  { width: '176', min_width: '176', fixed: '', field: 'project_total', checked: true, lable: '历史完成项目数量' },
  { width: '176', min_width: '176', fixed: '', field: 'parting_total', checked: true, lable: '正在参与项目数量' },
  { width: '176', min_width: '176', fixed: '', field: 'skin_texture', checked: false, lable: '皮肤肤质' },
  { width: '176', min_width: '176', fixed: '', field: 'skin_type', checked: false, lable: '皮肤类型' },
  { width: '176', min_width: '176', fixed: '', field: 'skin_issues', checked: false, lable: '皮肤问题' },
  { width: '176', min_width: '176', fixed: '', field: 'skin_color', checked: false, lable: '皮肤肤色' },
  { width: '184', min_width: '184', fixed: '', field: 'use_products', checked: false, lable: '使用过的产品类别' },
  { width: '184', min_width: '184', fixed: '', field: 'smoke', checked: false, lable: '是否抽烟' },
  { width: '184', min_width: '184', fixed: '', field: 'drink', checked: false, lable: '是否饮酒' },
  { width: '184', min_width: '184', fixed: '', field: 'underlying_disease', checked: false, lable: '是否有基础疾病' },
  { width: '184', min_width: '184', fixed: '', field: 'account_name', checked: false, lable: '收款户名' },
  { width: '184', min_width: '184', fixed: '', field: 'account_number', checked: false, lable: '收款账号' },
  { width: '184', min_width: '184', fixed: '', field: 'bank', checked: false, lable: '收款银行' },
  { width: '184', min_width: '184', fixed: '', field: 'testAddress', checked: false, lable: '测试场所' },
])
// 表头字段设置--->恢复默认字段
let DefaultHeaderData = ref([
  { width: '136', min_width: '136', fixed: '', field: '_id', checked: true, lable: '志愿者ID' },
  { width: '112', min_width: '112', fixed: '', field: 'volun_name', checked: true, lable: '姓名' },
  { width: '72', min_width: '72', fixed: '', field: 'volun_gender', checked: false, lable: '性别' },
  { width: '184', min_width: '184', fixed: '', field: 'idNumber', checked: false, lable: '身份证号' },
  { width: '136', min_width: '136', fixed: '', field: 'volun_phone', checked: false, lable: '联系电话' },
  { width: '100', min_width: '100', fixed: '', field: 'referee', checked: false, lable: '推荐人' },
  { width: '116', min_width: '116', fixed: '', field: 'volun_birthday', checked: false, lable: '出生日期' },
  { width: '72', min_width: '72', fixed: '', field: 'volun_age', checked: false, lable: '年龄' },
  { width: '120', min_width: '120', fixed: '', field: 'address', checked: false, lable: '所在地址' },
  { width: '176', min_width: '176', fixed: '', field: 'monthly_income', checked: false, lable: '月收入范围' },
  { width: '176', min_width: '176', fixed: '', field: 'marital_history', checked: false, lable: '婚育史' },
  { width: '176', min_width: '176', fixed: '', field: 'three_phase', checked: false, lable: '是否三期' },
  { width: '84', min_width: '84', fixed: '', field: 'tags', checked: false, lable: '标签' },
  { width: '90', min_width: '90', fixed: '', field: 'remark', checked: false, lable: '备注' },
  { width: '136', min_width: '136', fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  { width: '152', min_width: '152', fixed: '', field: 'createdAt', checked: false, lable: '首次注册时间' },
  { width: '176', min_width: '176', fixed: '', field: 'reg_total', checked: false, lable: '历史报名项目数量' },
  { width: '176', min_width: '176', fixed: '', field: 'project_total', checked: false, lable: '历史完成项目数量' },
  { width: '176', min_width: '176', fixed: '', field: 'parting_total', checked: false, lable: '正在参与项目数量' },
  { width: '176', min_width: '176', fixed: '', field: 'skin_texture', checked: false, lable: '皮肤肤质' },
  { width: '176', min_width: '176', fixed: '', field: 'skin_type', checked: false, lable: '皮肤类型' },
  { width: '176', min_width: '176', fixed: '', field: 'skin_issues', checked: false, lable: '皮肤问题' },
  { width: '176', min_width: '176', fixed: '', field: 'skin_color', checked: false, lable: '皮肤肤色' },
  { width: '184', min_width: '184', fixed: '', field: 'use_products', checked: false, lable: '使用过的产品类别' },
  { width: '184', min_width: '184', fixed: '', field: 'smoke', checked: false, lable: '是否抽烟' },
  { width: '184', min_width: '184', fixed: '', field: 'drink', checked: false, lable: '是否饮酒' },
  { width: '184', min_width: '184', fixed: '', field: 'underlying_disease', checked: false, lable: '是否有基础疾病' },
  { width: '184', min_width: '184', fixed: '', field: 'account_name', checked: false, lable: '收款户名' },
  { width: '184', min_width: '184', fixed: '', field: 'account_number', checked: false, lable: '收款账号' },
  { width: '184', min_width: '184', fixed: '', field: 'bank', checked: false, lable: '收款银行' },
  { width: '184', min_width: '184', fixed: '', field: 'testAddress', checked: false, lable: '测试场所' },
])

//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  loading: true,
  list: [],
  gender: '',
  age: '',
})
const gender = [
  {
    value: '1',
    label: '男',
  },
  {
    value: '2',
    label: '女',
  },
]
const ageList = [
  {
    value: '不限年龄',
    label: '不限年龄',
  },
  {
    value: '18~25',
    label: '18~25',
  },
  {
    value: '26~40',
    label: '26~40',
  },
  {
    value: '40~49',
    label: '40~49',
  },
  {
    value: '≥50',
    label: '≥50',
  },
]

//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
})
// sort排序字段
let SORT = ref({ createdAt: -1 })
//导入失败显示隐藏
const errdialogShow = ref(false)
//  表内导航栏
const ProjectState = ref('')
ProjectState.value = ['已报名', '已初审', '测试中', '已完成', '已退出']
// 模糊搜索 判断是否是第一次
const EventSearch = ref(false)
// 模糊Age 判断是否是第一次
const AgeSearch = ref(false)
// 模糊Gender 判断是否是第一次
const GenderSearch = ref(false)
// 点击表头的下拉箭头
const OperationBox = ref(null) //获取更多操作盒子的ref
const showIdOptions = ref(false) //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1)
// 显示功能的字段设置
let AllShowHeader = ref([])
// 全部显示里被隐藏的
let AllHidenHeader = ref([])
const SetShow = ref(false)
const ShowBox = ref(null) //获取Show盒子的ref
const ShowCheck = ref([])
// 判断是否是高级搜索模式
let Advancedfind = ref(false)
// todo
// 每一行的 ... 点击
let ClickMoreOptions = ref(false)
const CMoreOptions = ref(null) //获取MoreOptions盒子的ref
let OptionsData = ref()
// 高级搜索数据存储
const Volunteers = reactive({
  idString: null, // 志愿者id
  volun_name: null, // 姓名
  volun_gender: null, // 性别
  idNumber: null, //身份证号
  volun_phone: null, // 联系电话
  referee: null, //推荐人
  volun_birthday: null, // 出生日期
  volun_age: null, //  年龄
  address: null, //地址
  monthly_income: null, //  月收入
  marital_history: null, // 结婚
  three_phase: null, //三期
  tags: null, //标签
  remark: null, //备注
  createdAt: null, //第一次注册时间
  reg_total: null, //历史项目
  project_total: null, //正在进行
  parting_total: null, // 正在参与项目
  skin_texture: null, // 肤质
  skin_type: null, //皮肤类型
  skin_issues: null, //皮肤问题
  skin_color: null, //肤色
  use_products: null, //使用过的产品
  smoke: null,
  drink: null,
  underlying_disease: null, //健康
})
// 高级搜索初始数据
const initialize = reactive(null)
// !高级搜索数据
const Totalinitialdata = ref({
  volun_gender: [
    {
      value: '男',
      label: '男',
    },
    {
      value: '女',
      label: '女',
    },
  ],
  date_birth: [
    {
      value: '1',
      label: '1',
    },
    {
      value: '1',
      label: '1',
    },
  ],
  address: [
    {
      value: '上海',
      label: '上海',
    },
    {
      value: '北京',
      label: '北京',
    },
  ],
  salary: [
    {
      value: '5千以下',
      label: '5千以下',
    },
    {
      value: '5千-1万',
      label: '5千-1万',
    },
    {
      value: '1万-5万',
      label: '1万-5万',
    },
    {
      value: '5万以上',
      label: '5万以上',
    },
  ],
  marry: [
    {
      value: '未婚',
      label: '未婚',
    },
    {
      value: '已婚未育',
      label: '已婚未育',
    },
    {
      value: '已婚已育',
      label: '已婚已育',
    },
  ],
  three_times: [
    { value: '无', label: '无' },
    {
      value: '孕期',
      label: '孕期',
    },
    {
      value: '产期',
      label: '产期',
    },
    {
      value: '哺乳期',
      label: '哺乳期',
    },
  ],
  tag: ['依从性好', '依从性差', '爽约严重', '不正常使用产品', '打卡率高', '打卡率低', '工作日来访', '周末来访'],
  sign: [
    {
      value: '12.12',
      label: '12.12',
    },
    {
      value: '11.11',
      label: '11.11',
    },
  ],
  historical_project: [
    {
      value: '5',
      label: '5',
    },
    {
      value: '1',
      label: '1',
    },
  ],
  finish_project: [
    {
      value: '5',
      label: '5',
    },
    {
      value: '1',
      label: '1',
    },
  ],
  doing_project: [
    {
      value: '5',
      label: '5',
    },
    {
      value: '1',
      label: '1',
    },
  ],
  skin_type: [
    {
      value: '干性',
      label: '干性',
    },
    {
      value: '油性',
      label: '油性',
    },
    {
      value: '中性',
      label: '中性',
    },
    {
      value: '混合偏干',
      label: '混合偏干',
    },
    {
      value: '混合偏油',
      label: '混合偏油',
    },
    {
      value: '不确定',
      label: '不确定',
    },
    {
      value: '其他',
      label: '其他',
    },
  ],
  type: [
    {
      value: '敏感',
      label: '敏感',
    },
    {
      value: '非敏感',
      label: '非敏感',
    },
    {
      value: '不确定',
      label: '不确定',
    },
    {
      value: '其他',
      label: '其他',
    },
  ],
  skin_problem: [
    '毛孔粗大',
    '有炎性痘痘',
    '有闭口粉刺',
    '有色斑',
    '有痘印',
    '敏感泛红',
    '肤色暗沉',
    '有黑头',
    '黑眼圈',
    '眼袋',
    '皱纹细纹',
    '皮肤松弛',
    '其他',
  ],
  skin_color: [
    {
      value: '偏白',
      label: '偏白',
    },
    {
      value: '偏黄',
      label: '偏黄',
    },
    {
      value: '偏黑',
      label: '偏黑',
    },
    {
      value: '偏红',
      label: '偏红',
    },
    {
      value: '中等',
      label: '中等',
    },
  ],
  product_type: ['护肤品', '防晒霜', '粉底', '口红', '眼线笔', '睫毛膏', '脱毛仪', '都没有', '其他'],
  smoking: [
    {
      value: true,
      label: '是',
    },
    {
      value: false,
      label: '否',
    },
  ],
  drink: [
    {
      value: true,
      label: '是',
    },
    {
      value: false,
      label: '否',
    },
  ],
  fitness: [
    '高血压',
    '高血脂',
    '非胰岛素依赖性糖尿病',
    '胰岛素依赖性糖尿病',
    '免疫性疾病',
    '皮肤病史',
    '过敏史',
    '肝肾功能疾病',
    '心脑血管疾病',
    '哮喘患者',
    '其他呼吸系统疾病',
    '入院治疗史',
    '无任何以上提到的疾病',
  ],
})
let CreatedVolunteerShow = ref(false) // 设置新建志愿者组件显示隐藏
let isshowOperation = ref(false) // ! 控制批量操作的显示
let isDerived = ref(false) // ! 导出志愿信息弹框
let isDerived_delect = ref(false) // ! 导出志愿信息弹框   失败
let Volunteerdetails = ref(false) // !查看志愿者详情
let information = ref(null) // !点击的志愿者信息
let Volunteereditor = ref(true) // !查看志愿者详情编辑
let null_reminder_export = ref(false) // ! 没有勾选志愿者数据导出
let null_reminder_delect = ref(false) // ! 没有勾选志愿者数据删除
let isoneDerived_delect = ref(false) // !  单个志愿者删除
const oneDerivedData = ref('')
let Show_transform = ref(false) // ! 控制高级搜索显示

//导出弹窗显示隐藏
let dialogShow = ref(false)
let uploadPercentage = ref(0)
// 上传文件名
let uploadFileName = ref('')
// 是否上传错误
let uploadFileErr = ref(false)

const fileLists = ref([])
//复选框数组
var selectedRows = reactive([])
const checkedArr = ref([])
//tip新数据结构------------------：
// Vue.createApp(xxx).mount('#xxx');
const onEnd = () => {
  let $table = xTable.value
  // $table.refreshColumn();
}
let dragDemo = ref([])
let allDatas = ref([])
// 置顶/删除方法
const backTop = (e, val) => {
  console.log('val :>> ', e)
  let indexItem = -1
  dragDemo.value.forEach((item, index) => {
    if (item.lable == e.lable) {
      indexItem = index
    }
  })
  console.log('下标是： :>> ', indexItem)
  // 置顶方法
  if (val == 'top') {
    if (indexItem != 0) {
      dragDemo.value.unshift(dragDemo.value.splice(indexItem, 1)[0])
    }
  } else {
    // 不能删除的操作
    if (defaultHeader.value.indexOf(e.lable) != -1) {
      message({
        message: `${e.lable}不能删除`,
        showClose: true,
        type: 'error',
      })
    } else {
      // 可以删除的操作 同步删除左侧勾选；
      // 删除方法
      dragDemo.value.splice(indexItem, 1)
      const index = SetVolunteerHeaderArr.value.findIndex((item) => item.lable === e.lable)
      SetVolunteerHeaderArr.value[index].checked = false
    }
  }

  console.log(' dragDemo.value; :>> ', dragDemo.value)
}

// tip-------------------------
// 监听gender
watch(
  () => demo1.gender,
  (newvalue) => {
    // console.dir(newvalue);
    if (newvalue != '') {
      if (GenderSearch.value == false) {
        page5.currentPage = 1
      }
      page5.page = 1
      page5.currentPage = 1

      GenderSearch.value = true
      SearchGender()
    }
  }
)

// 监听年龄限制
watch(
  () => demo1.age,
  (newvalue) => {
    console.dir(newvalue)
    if (AgeSearch.value == false) {
      page5.currentPage = 1
    }
    AgeSearch.value = true
    SearchGender()
  }
)
// 监听勾选行
watch(
  () => selectedRows,
  (newvalue) => {
    newvalue.forEach((item) => {
      CheckId.value.push(item._id)
    })
  }
)

// todo
// 点击...
const ClickMethods = (row, event) => {
  // console.log(row);
  OptionsData.value = row
  // console.log(DelectData.value);
  ClickMoreOptions.value = false
  ClickMoreOptions.value = true

  // console.log('event :>> ', event, CMoreOptions.value);

  CMoreOptions.value.style.left = event.clientX - 120 + 'px'
  CMoreOptions.value.style.top = event.clientY + 30 + 'px'
  // console.log('event :>> ', ClickMoreOptions.value);
}
// 筛选---->性别
const SearchGender = async () => {
  const $table = xTable.value
  $table.clearCheckboxRow()
  demo1.loading = true
  let gender = ''
  let age = 0
  let content = ''
  // 获取性别
  if (demo1.gender == '1') {
    gender = '男'
  } else if (demo1.gender == '2') {
    gender = '女'
  } else {
    gender = '全部'
  }
  // 获取年龄
  switch (demo1.age) {
    case '不限年龄':
      age = 0
      break
    case '18~25':
      age = 2
      break
    case '26~40':
      age = 3
      break
    case '40~49':
      age = 4
      break
    case '≥50':
      age = 5
      break
  }
  // 获取搜索框
  content = demo1.filterName
  if (gender != '') {
    const res = await http.post('/volunteer/find-volunteer', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      sex: gender,
      age: age,
      content: content,
    })
    if (res.status == 0) {
      demo1.loading = false
      console.log('切换男女')
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
      checkedArr.value.splice(0, checkedArr.value?.length)
    }
  } else {
    const res = await http.post('/volunteer/find-volunteer', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: content,
      sex: gender,
      age: age,
    })
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
      checkedArr.value.splice(0, checkedArr.value?.length)
    }
  }
}

// 点击新建志愿者
const CreatedVolunteerChange = () => {
  if (!store.state.role_info.includes('volunteer-bank-created')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  CreatedVolunteerShow.value = !CreatedVolunteerShow.value
  console.log('点击新建志愿者')
  is_dialogShow.value = true
}
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params
  //   //console.log(columns); // 打印排序后的表头列数组对象
}

// 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true
  TemporaryData.value = JSON.parse(JSON.stringify(SetVolunteerHeaderArr.value))

  //console.log('SetTableHeader', SetTableHeader.value);
}
//  表头字段设置  恢复默认字段
const RecoverData = () => {
  console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value)

  SetVolunteerHeaderArr.value = JSON.parse(JSON.stringify(DefaultHeaderData.value))

  dragDemo.value = []
  SetVolunteerHeaderArr.value.forEach((item, index) => {
    if (item.checked) {
      console.log('导入 :>> ', item)
      dragDemo.value.push(item)
    }
  })
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  console.log('tableColumns :>> ', tableColumns)
  // 刷新列
  $table.refreshColumn()
}
// 表头字段设置 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false
  SetVolunteerHeaderArr.value = JSON.parse(JSON.stringify(TemporaryData.value))
}
// 表头阻断设置  的点击复选框
const CheckHeader = (i) => {
  // 默认展示的字段/不可取消

  if (defaultHeader.value.indexOf(SetVolunteerHeaderArr.value[i].lable) != -1) {
    message({
      message: `${SetVolunteerHeaderArr.value[i].lable}不能删除`,
      grouping: true,
      type: 'error',
    })
    SetVolunteerHeaderArr.value[i].checked = true
    return
  }
  // 拖拽左边勾选，右边增加；右边删除，左边取消勾选

  // console.log('object :>> ', SetVolunteerHeaderArr.value[i]);
  // 如果是true ，push进dragdemo
  if (SetVolunteerHeaderArr.value[i].checked) {
    dragDemo.value.push(SetVolunteerHeaderArr.value[i])
  } else {
    //如果是false，从dragdemo中删除
    dragDemo.value = dragDemo.value.filter((item) => item.lable !== SetVolunteerHeaderArr.value[i].lable)
  }
  // console.log('dragDemo.value :>> ', dragDemo.value);
}
//note--->全部数据
const reqProjectList = async (val) => {
  if (val == '批量删除') {
    page5.currentPage = 1
  }
  const res = await http.post('/volunteer/find-volunteer', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: '',
  })
  // //console.log('res :>> ', res);
  page5.totalResult = res.totalCount
  demo1.tableData = res.result
  if (res.status == 0) {
    demo1.loading = false
  }
}

//!高级搜索
const reqAllSelectData = async () => {
  
  // ! 取消null的字段
  for (let prop in Volunteers) {
    if (Volunteers[prop] === null) {
      delete Volunteers[prop]
    }
  }
  console.log("page5.currentPage是：",page5.currentPage)

  const res = await http.post('/volunteer/advancedfind-volunteer', {
    findParams: Volunteers,
    page: (page5.currentPage),
    size: page5.pageSize,
  })
  
  console.log('全部返回的数据是:', res)

  if (res.status == 0) {
    const $table = xTable.value
    $table.clearCheckboxRow()
    checkedArr.value.splice(0, checkedArr.value?.length)
    Advancedfind.value = true //已进入高级搜索模式(更新分页)
    demo1.tableData = res.volunteers
    page5.totalResult = res.totalCount

    Show_transform.value = false
  }
}
// !重置按钮
const reset = () => {
  reqProjectList()
  const $table = xTable.value
  Advancedfind.value = false //重置高级搜索模式
  $table.clearCheckboxRow()
  checkedArr.value.splice(0, checkedArr.value?.length) //清空勾选状态
  EventSearch.value = false //
  GenderSearch.value = false
  AgeSearch.value = false
  // 清空输入的搜索数据
  demo1.age = ''
  demo1.gender = ''
  demo1.filterName = ''
}
// ! 退出按钮
const unSelect = () => {
  Show_transform.value = false
}
// !批量按钮显示
const showOperation = () => {
  isshowOperation.value = true
}
// 批量导出方法
const allderive = async () => {
  let userlist = []
  selectedRows.forEach((item) => {
    userlist.push(item._id)
  })
  try {
    let response = await http.post('/upload/export-volunteer', {
      userlist: userlist,
    })
    let name = getFilename(response)
    console.log(name, 'name')
    console.log(response)
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    })
    let downloadElement = document.createElement('a')
    let allProjecthref = window.URL.createObjectURL(blob)
    downloadElement.href = allProjecthref
    downloadElement.download = `${name}`
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(allProjecthref)
    message({
      message: '导出成功',
      showClose: true,
      type: 'success',
    })
  } catch (error) {
    message({
      message: '导出失败',
      showClose: true,
      type: 'error',
    })
  }
}
// ! 点击批量导出
const Export = (row) => {
  if (!store.state.role_info.includes('volunteer-bank-export')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  console.log(selectedRows)
  console.log(SetVolunteerHeaderArr.value)
  if (checkedArr.value?.length !== 0) {
    console.log(SetVolunteerHeaderArr.value)
    isshowOperation.value = false

    isshowDerived()
  } else {
    null_reminder_export.value = true
  }
  // 判断值，进行展示
}
// ! 高级显示
const ischangeShow = () => {
  Show_transform.value = !Show_transform.value
  for (let prop in Volunteers) {
    Volunteers[prop] = null
  }
}
// ! 点击批量删除
const delect = () => {
  if (!store.state.role_info.includes('volunteer-bank-delete')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  if (checkedArr.value?.length !== 0) {
    isshowOperation.value = false
    isshowDerived_delect()
  } else {
    null_reminder_delect.value = true
  }
}
// !导出志愿者信息弹窗  正确 取消
const isshowDerived = () => {
  isDerived.value = !isDerived.value
}
// !导出志愿者信息弹窗  正确  确定
const isshowDerivedtrue = () => {
  // allderive();
  ExportFiles()
  isDerived.value = !isDerived.value
}
// !删除志愿者信息弹窗  失败  取消
const isshowDerived_delect = () => {
  oneDerivedData.value = null
  isDerived_delect.value = !isDerived_delect.value
}
// ! !删除志愿者信息弹窗  失败  确定
const isshowDerived_delect_true = async () => {
  // !删除对应数据，并刷新页面
  const ids = selectedRows.map((obj) => obj._id)
  console.log(ids, 'idddddd') // [1, 2, 3]
  try {
    const res = await http.delete('/volunteer/delete-volunteer', checkedArr.value)
    isDerived_delect.value = !isDerived_delect.value
    reqProjectList('批量删除')
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    })
    checkedArr.value = []
  } catch (error) {
    message({
      message: '删除失败',
      grouping: true,
      type: 'error',
    })
  }
}
// 点击编辑时的数据
let informations = ref(null)
// 处理过的所在地址
let uploadAddress_val = ref('')
//年龄变化
const showAge = () => {
  let information1 = information.value
  if (information.value.volun_birthday == null || information.value.volun_birthday == '') {
    return null
  }
  // console.log(dateValue.value);
  const now = new Date()
  console.log(now)
  // 将给定的时间点转化为 Date 对象
  const birthDate = new Date(information.value.volun_birthday)

  console.log(information.value.volun_birthday)
  // 计算时间差
  const diff = Math.abs(now.getTime() - birthDate.getTime())
  // 计算年龄
  const age = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25))
  // console.log(age);
  // ageValue.value=age.
  information.value.volun_age = age
  // information1.volun_age = new Date(Date.now() - new Date(information1.volun_birthday).getTime()).getFullYear() - 1970;
}
// 点击所在地址变化
const handleChange = (res) => {
  let information1 = information.value

  // console.log(res,'所在地址改变');
  // console.log(addressValue.value, addressValue.value?.length);
  for (let i = 0; i < information1.address?.length; i++) {
    if (i == 0) {
      uploadAddress_val.value = information1.address[i]
    } else {
      uploadAddress_val.value = uploadAddress_val.value + '-' + information1.address[i]
    }
    console.log(uploadAddress_val.value)
  }
}
let informationsBei = ref()
let informationsAddress = ref()
// ! 志愿者查看编辑状态切换
const isVolunteereditor = () => {
  if (!store.state.role_info.includes('volunteer-bank-edit')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  Volunteereditor.value = !Volunteereditor.value
  informationsBei.value = JSON.parse(JSON.stringify(information.value))
  // informations.value = information.value;
  console.log(information.value, '编辑时展示的数据')
  // if(informations.value.address?.length>2&&Volunteereditor.value==false){
  informationsAddress.value = information.value.address
  information.value.address = JSON.parse(JSON.stringify(information.value.address?.split('-')))

  // }
  // console.log(informations.value.address, 'informations.value.address');
}
// 编辑志愿者点击取消
const cencelDataChange = () => {
  information.value = JSON.parse(JSON.stringify(informationsBei.value))
  information.value.address = JSON.parse(JSON.stringify(informationsAddress.value))
  Volunteereditor.value = true
}
//必选项是否为空提示
let errObj = reactive({
  name: false,
  sex: false,
  card: false,
  phone: false,
  referrer: false,
  date: false,
  address: false,
  money: false,
  Marriage: false,
  period: false,
  label: false,
  remark: false,
})
// 姓名校验
let name_error = ref('姓名不可为空')

const is_inputName = () => {
  if (information.value.volun_name === '') {
    name_error.value = '姓名不可为空'
    errObj.name = true
  } else if (information.value.volun_name?.length < 2 || information.value.volun_name?.length > 10) {
    errObj.name = true
    name_error.value = '姓名不符合格式'
  } else {
    errObj.name = false
  }
}

const is_inputSex = () => {
  if (information.value.volun_gender === '') {
    errObj.sex = true
  } else {
    errObj.sex = false
  }
}

let card_error = ref('身份证号不可为空')
let _IDRe18 = /^([1-6][1-9]|50)\d{4}(18|19|20)\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
let _IDRe15 = /^([1-6][1-9]|50)\d{4}\d{2}((0[1-9])|10|11|12)(([0-2][1-9])|10|20|30|31)\d{3}$/
const is_inputCard = () => {
  if (information.value.idNumber === '') {
    card_error.value = '身份证号不可为空'
    errObj.card = true
  } else if (information.value.idNumber?.length === 18) {
    console.log('18位身份证')
    if (!_IDRe18.test(information.value.idNumber)) {
      errObj.card = true
      // 身份证不符合格式
      card_error.value = '身份证不符合格式'
      // console.log('身份证不符合格式');
    } else {
      card_error.value = ''
      errObj.card = false
    }
  } else if (information.value.idNumber?.length == 15) {
    console.log('身份证位数不正确')
    if (!_IDRe15.test(information.value.idNumber)) {
      errObj.card = true
      // 身份证不符合格式
      card_error.value = '身份证不符合格式'
      // console.log('身份证不符合格式');
    } else {
      card_error.value = ''
      errObj.card = false
    }
  } else if (information.value.idNumber?.length < 15 || 15 < information.value.idNumber?.length < 18) {
    console.log('身份证位数不正确')
    if (!_IDRe15.test(information.value.idNumber?.length)) {
      errObj.card = true
      // 身份证不符合格式
      card_error.value = '身份证不符合格式'
      // console.log('身份证不符合格式');
    } else {
      card_error.value = ''
      errObj.card = false
    }
  } else {
    errObj.card = true
    // 身份证不符合格式
    card_error.value = '身份证不符合格式'
    // console.log('身份证不符合格式');
  }
}
// 备注触发事件
const is_inputRemark = () => {
  if (!information.value.remark) return
  if (information.value.remark?.length == 20) {
    errObj.remark = true
  } else {
    errObj.remark = false
  }
}
// 身份证触发事件
const inputCardChange = (idNumber) => {
  if (idNumber?.length === 18) {
    var birthYear = Number(idNumber.substr(6, 4))
    var birthMonth = idNumber.substr(10, 2)
    var birthDay = idNumber.substr(12, 2)
  } else if (idNumber?.length === 15) {
    var birthYear = '19' + idNumber.substr(6, 2)
    var birthMonth = idNumber.substr(8, 2)
    var birthDay = idNumber.substr(10, 2)
  } else {
    return '无效的身份证号码'
  }

  // 获取当前日期
  var currentDate = new Date()
  console.log(currentDate, 'currentDate')
  var currentYear = currentDate.getFullYear()
  var currentMonth = currentDate.getMonth() + 1
  var currentDay = currentDate.getDate()

  // 计算年龄
  var age = currentYear - birthYear
  if (birthMonth > currentMonth || (birthMonth === currentMonth && birthDay > currentDay)) {
    age -= 1
  }
  // 就算出生日期
  const formattedBirthDate = birthYear + '-' + birthMonth + '-' + birthDay
  console.log(age, 'age')
  information.value.volun_age = age
  information.value.volun_birthday = formattedBirthDate
}
const phone = /^1[3-9]\d{9}$/
let phone_error = ref('联系电话不可为空')
const is_inputPhone = () => {
  if (information.value.volun_phone === '') {
    phone_error.value = '联系电话不可为空'
    errObj.phone = true
  } else if (!phone.test(information.value.volun_phone)) {
    phone_error.value = '联系电话不符合格式'

    errObj.phone = true
  } else {
    phone_error.value = ''
    errObj.phone = false
  }
}

// 推荐人校验
let referrer_error = ref('姓名不可为空')
const is_inputReferrer = () => {
  if (information.value.referee === '') {
    referrer_error.value = '推荐人不可为空'
    errObj.referrer = true
  } else if (information.value.referee?.length < 0 || information.value.referee?.length > 10) {
    referrer_error.value = '推荐人不符合格式'
    errObj.referrer = true
  } else {
    errObj.referrer = false
  }
}

// ! 修改原有志愿者
const updatedata = async () => {
  let information1 = information.value
  is_inputName()
  is_inputCard()
  is_inputSex()
  is_inputPhone()
  is_inputReferrer()
  is_inputRemark()
  console.log('errObj :>> ', errObj)
  if (errObj.name || errObj.card || errObj.sex || errObj.phone || errObj.referrer || errObj.remark)
    return message.warning('请检查必填项是否为空/格式是否正确')

  for (let i = 0; i < information1.address?.length; i++) {
    if (i == 0) {
      uploadAddress_val.value = information1.address[i]
    } else {
      uploadAddress_val.value = uploadAddress_val.value + '-' + information1.address[i]
    }
    console.log(uploadAddress_val.value)
  }
  console.log(information1)
  const newFiles = []
  attachmentList.value.forEach((attachment) => {
    if (!attachment.downloadPath) {
      newFiles.push(attachment)
    }
  })

  const res = await http.put('/volunteer/update-volunteer', {
    updateData: {
      _id: information1._id,
      volun_name: information1.volun_name,
      volun_gender: information1.volun_gender,
      idNumber: information1.idNumber,
      volun_phone: information1.volun_phone,
      referee: information1.referee,
      volun_birthday: information1.volun_birthday,
      volun_age: information1.volun_age === '系统根据出生日期自动计算' ? 0 : information1.volun_age,
      address: uploadAddress_val.value,
      monthly_income: information1.monthly_income,
      marital_history: information1.marital_history,
      three_phase: information1.three_phase,
      tags: information1.tags,
      remark: information1.remark,
      // 皮肤信息
      skin_texture: information1.skin_texture,
      skin_type: information1.skin_type,
      skin_issues: information1.skin_issues,
      skin_color: information1.skin_color,
      use_products: information1.use_products,
      // // 健康信息
      smoke: information1.smoke,
      drink: information1.drink,
      underlying_disease: information1.underlying_disease,
      // 收款户名
      account_name: information1.account_name,
      account_number: information1.account_number,
      bank: information1.bank,
      // 测试场所
      test_area: information1.test_area,
      //文件
      files: newFiles,
      deletefiles: deleteFiles.value,
    },
    company: localStorage.getItem('company'),
  })
  if (res.status == 0) {
    information.value.address = uploadAddress_val.value
    reqProjectList()
    Volunteereditor.value = true

    console.log(uploadAddress_val, 'uploadAddress_val')
    message({
      message: '修改成功',
      grouping: true,
      type: 'success',
    })
  } else {
    message({
      message: '修改失败',
      grouping: true,
      type: 'error',
    })
  }
  console.log(res, '修改成功返回数据')

  // isVolunteereditor();
}
// ! 关闭提醒弹窗
const Closereminder = () => {
  null_reminder_export.value = false
  null_reminder_delect.value = false
}
// const updatedata  = ()=>{
//       console.log(information.value);
// }
// 分页
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false
  //console.log('点击点击 :>> ');
}

// More -->升序/降序
const AscendingSort = async (index, type) => {
  let gender = ''
  let age = 0
  // 获取性别
  if (demo1.gender == '1') {
    gender = '男'
  } else if (demo1.gender == '2') {
    gender = '女'
  } else {
    gender = '全部'
  }
  // 获取年龄
  switch (demo1.age) {
    case '不限年龄':
      age = 0
      break
    case '18~25':
      age = 2
      break
    case '26~40':
      age = 3
      break
    case '40~49':
      age = 4
      break
    case '≥50':
      age = 5
      break
  }
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  const ColumnId = tableColumns[index].field
  console.log(ColumnId, 'ColumnId')
  const sorts = {}
  if (type == 'Asc') {
    sorts[ColumnId] = 1
  } else {
    sorts[ColumnId] = -1
  }

  SORT.value = sorts
  console.log(SORT.value, 'SORT.value')
  const res = await http.post('/volunteer/find-volunteer', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    age: age,
    sex: gender,
  })
  demo1.tableData = res.result
  page5.totalResult = res.totalCount
}
//  More -->固定/取消固定
const toggleFixedColumn = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
const toggleFixedColumns = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false
  const $table = xTable.value
  const column = $table.getColumns()
  // console.log('column[index] :>> ', column[index]);
  column[index].visible = false
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value.push(column[index])
  ShowCheck.value.push(false)
  $table.refreshColumn() //刷新列表
  console.log('ShowChcke.value :>> ', ShowCheck.value)
  // console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
}
// 获取当前的全部数据
const TransferData = () => {
  // 将全部显示的数据  放到 AllShowHeader中
  SetVolunteerHeaderArr.value.forEach((item, index) => {
    if (item.checked == true) {
      AllShowHeader.value.push(item)
    }
  })
  console.log('AllShowHeader.value :>> ', AllShowHeader.value)
}
// ! 获取event 设置show显示框的位置
const SetShowFunction = (event) => {
  SetShow.value = false
  SetShow.value = true
  // console.log('event :>> ', event, ShowBox.value);
  ShowBox.value.style.left = event.clientX - 10 + 'px'
  ShowBox.value.style.top = event.clientY + 20 + 'px'
}
// ! 恢复目前隐藏的列表 确认按钮
const ConfirmRecover = () => {
  let TeShowCheck = []
  let TeAllHidenHeader = []
  const $table = xTable.value
  const column = $table.getColumns()
  console.log('ShowCheck.value :>> ', ShowCheck.value)
  for (let i = ShowCheck.value?.length; i >= 0; i--) {
    if (ShowCheck.value[i] == true) {
      // // column[index].visible = false;
      // console.log('column :>> ', ShowCheck.value[i], AllHidenHeader.value[i]);

      // 显示列
      $table.showColumn(AllHidenHeader.value[i].field)
      // 同步删除 数据 隐藏列/checked
      AllHidenHeader.value.splice(i, 1)
      ShowCheck.value.splice(i, 1)
      // console.log('column :>> ', column, $table);
      // console.log('column :>> ', $table.showColumn());
      // console.log('column :>> ', ShowCheck.value, AllHidenHeader.value);
    }
  }
  SetShow.value = false
}
// 分页--->跳转页面显示数据处理
const pageChange = async () => {
  // 判断是否是高级搜索模式
  if (Advancedfind.value) {
    reqAllSelectData()
  } else {
    // 不是高级搜索就是普通的数据
    if (demo1.filterName == '' && demo1.age == '' && demo1.gender == '') {
      const res = await http.post('/volunteer/find-volunteer', {
        page: page5.currentPage,
        size: page5.pageSize,
        sort: SORT.value,
        content: '',
      })
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    } else {
      SearchGender()
    }
  }
}
// 点击下载模板
const downloadXlsx = async () => {
  const xlsxUrl = 'https://cems.fuhuan.ltd//志愿者管理-志愿者库-导入表格.xlsx'
  const response = await fetch(xlsxUrl)
  const blob = await response.blob()
  // 创建下载链接
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = '志愿者管理-志愿者库-导入表格.xlsx'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  // 释放 Blob URL
  window.URL.revokeObjectURL(url)
}

//导入方法
const exportDataEvent = () => {
  if (!store.state.role_info.includes('volunteer-bank-import')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  // const $table = xTable.value;
  // $table.exportData({ type: 'csv' });
  dialogShow.value = true
}
// 导入志愿者错误返回的路径
let errorPath = ref()
// 导入志愿者错误的数据量
let errFileNum = ref(null)
// 上传的文件名
let fileName = ref('')
// 上传事件
const uploadFile = async (params) => {
  console.log(params)
  const file = params.file
  // fileName.value=file.name
  const lastIndex = file.name.lastIndexOf('.')
  fileName.value = file.name.substring(0, lastIndex)
  const form = new FormData()
  form.append('file', file)
  console.log(params)
  await http
    .post('/upload/upload-volunteer', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async (res) => {
      console.log(res)
      if (res.status == 0) {
        dialogShow.value = false
        reqProjectList()
        ElMessage({
          showClose: true,
          message: '导入志愿者成功',
          type: 'success',
        })
      } else if (res.status == 1) {
        ElMessage({
          showClose: true,
          message: '上传文件为空，请重新上传',
          type: 'error',
        })
      }
      if (res.status == 2) {
        dialogShow.value = false
        errorPath.value = res.errorPath
        errdialogShow.value = true
        errFileNum.value = res.errorNumber
      }
    })
}
// 点击下载错误模板
const downloadErrFile = async () => {
  axios
    .post('https://cems.fuhuan.ltd/api/upload/downloadError-volunteer', { errorPath: errorPath.value }, { responseType: 'arraybuffer' })
    .then((result) => {
      console.log(result, '返回的错误数据')
      const blob = new Blob([result.data], { type: 'application/vnd.ms-excel' })
      let name = fileName.value + '-报错.xlsx'
      const downloadElement = document.createElement('a')
      let classificationhref = window.URL.createObjectURL(blob)
      downloadElement.href = classificationhref
      downloadElement.download = `${name}`
      // // console.log("blob :>> "，blob);
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(classificationhref)
    })
}
const handlePreviews = (file) => {
  console.log(file, 'handlePreviews')
}
const uploadFileSuccess = (res) => {
  console.log('上传成功')
  console.log(res)
}

const handleRemoves = (file, fileList) => {
  console.log(file, fileList, 'handleRemoves')
}

// 复选框点击事件
const selectChangeEvent1 = (row, checked) => {
  // console.log('最新:', row);

  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records
  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid)
  } else {
    let index = checkedArr.value.indexOf(row.rowid)
    checkedArr.value.splice(index, 1)
  }
  console.log('最新:', checkedArr.value)
}
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  console.log(checked ? '所有勾选事件' : '所有取消事件', records)
  selectedRows = records
  if (records?.length != 0) {
    for (let i = 0; i < records?.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id)
      }
    }
    console.log('最新:', checkedArr.value)
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = []

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id)
    })

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item)
    })
    console.log('最新:', checkedArr.value)
  }
}
//性别
let number_gender = ref()
// 选择性别事件
const genderChange = (res) => {
  console.log(res, '选择性别事件改变')
}
// 月收入范围数据
let number_salary = ref()
// 点击月收入范围事件
const salaryChange = (res) => {
  console.log(res, '收入范围')
  information.value.monthly_income = res
}
// 每一行的查看详情
const ShowDetails = (row) => {
  console.log('固定信息2:', row)
  SingleVolun(row._id)
  demo1.tableData.forEach((item, index) => {
    if (item._id == row._id) {
      information.value = item
    }
  })
  // information.value.volun_gender==1?information.value.volun_gender='男':'女'
  // console.log(typeof(information.value.monthly_income));
  // typeof(information.value.monthly_income)=='number'?information.value.monthly_income=JSON.parse(JSON.stringify(Totalinitialdata.value.salary[information.value.monthly_income-1].label)):''
  // number_salary=JSON.parse(JSON.stringify(Totalinitialdata.value.salary[row.monthly_income].label))
  // console.log('Totalinitialdata.value.marry[information.value.marital_history].label',Totalinitialdata.value.marry[information.value.marital_history].label);
  // typeof(information.value.marital_history)=='number'?information.value.marital_history=JSON.parse(JSON.stringify(Totalinitialdata.value.marry[information.value.marital_history].label)):''
  Volunteerdetails.value = !Volunteerdetails.value
  Volunteereditor.value = true
}

// bug  可能会出现bug/返回数据目前没有测试场所
// 查询单个志愿者的接口
const SingleVolun = async (val) => {
  console.log(val, 'val')
  const res = await http.post('/volunteer/findInfo-volunteer', {
    volunteerId: val,
  })
  console.log('志愿者详情请求结果', res)
  finish_projects.value = res.volunteer.history_projects
  attend_projects.value = res.volunteer.parting_projects
  attachmentList.value = res.volunteer.files
  console.log('最后的正在参加项目数据', attend_projects.value)
}
// 每一条的删除
const DeleteVolun = async () => {
  if (!store.state.role_info.includes('volunteer-bank-delete')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  // console.log('点击的数据', row);
  // OptionsData
  isoneDerived_delect.value = true
  isDerived_delect.value = true
  oneDerivedData.value = OptionsData.value
  console.log(' oneDerivedData.value', oneDerivedData.value)
  // -----------
}
// 每一条确定删除
const oneDeleteVolun = async () => {
  let id = oneDerivedData.value
  console.log('确定删除的数据', id)
  //   //console.log('row :>> ', row);
  try {
    const res = await http.delete('/volunteer/delete-volunteer', [id])

    isoneDerived_delect.value = false
    oneDerivedData.value = null
    isDerived_delect.value = false
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    })
    // 刷新数据
    if (demo1.filterName == '' && demo1.age == '' && demo1.gender == '') {
      reqProjectList()
    } else {
      SearchGender()
    }
    // 清理勾选数组
    let index = checkedArr.value.indexOf(id._id)
    console.log(index, '已存在id的下标')
    if (index != -1) {
      checkedArr.value.splice(index, 1)

      // console.log(checkedArr.value, '单个删除之后的数据');
    }

    //todo----------------------------------
  } catch (error) {
    message({
      message: '删除失败',
      grouping: true,
      type: 'error',
    })
  }
}

//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value :>> ', value)
  const table = xTable.value
  const FixedList = table.getColumns()
  //   //console.log('当前的列顺序为', FixedList);
  //   //console.log('FixedIndex :>> ', FixedIndex);
  FixedList.forEach((item, index) => {
    if (item.title == value) {
      // //console.log('value的位置在,index :>> ', value, '的位置在', index);
      FixedIndex.value = index
      if (item.fixed) {
        CancelFixed.value = false
        // console.log('有值 :>> ');
      } else {
        CancelFixed.value = true
        // console.log('无值 :>> ');
      }
    }
  })
  //console.log('FixedIndex下标为 :>> ', FixedIndex);
  showIdOptions.value = false
  showIdOptions.value = true
  // 设置位置
  const Event = event
  OperationBox.value.style.left = event.clientX - 100 + 'px'
}
// 模糊搜索
const searchEvent2 = async () => {
  let gender = ''
  let age = 0

  // 获取性别
  if (demo1.gender == '1') {
    gender = '男'
  } else if (demo1.gender == '2') {
    gender = '女'
  } else {
    gender = '全部'
  }
  // 获取年龄
  switch (demo1.age) {
    case '不限年龄':
      age = 0
      break
    case '18~25':
      age = 2
      break
    case '26~40':
      age = 3
      break
    case '40~49':
      age = 4
      break
    case '≥50':
      age = 5
      break
  }

  demo1.loading = true
  if (EventSearch.value == false) {
    page5.currentPage = 1
  }
  EventSearch.value = true
  const res = await http.post('/volunteer/find-volunteer', {
    page: (page5.currentPage = 1),
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    sex: gender,
    age: age,
  })
  console.log('全局搜索的返回结果res :>> ', res)
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.result
    page5.totalResult = res.totalCount
  }
}
let address_val = ref('')
const handleChanges = () => {
  console.log(Volunteers.address, Volunteers.address?.length)
  for (let i = 0; i < Volunteers.address?.length; i++) {
    address_val.value += Volunteers.address?.length[i]
    // console.log(address_val.value);
  }
}
// note 全局加载完毕之后执行此事件
nextTick(() => {
  setHeaderColumn()
  // 执行 列表显示/隐藏
  ShowHidenColumn()
  // 固定列表本地数据
  FixedColumn()
  // 获取拖拽数据
  GetDrag()
  // 转移数据
  TransferData()
})

// 检查是否有本地全部列表数据()
const setHeaderColumn = () => {
  if (localStorage.getItem('SetBank_Volun') == null) {
    // ////console.log('"第一次" :>> ', '第一次');
  } else {
    // ////console.log('"第二次" :>> ', '第二次');
    SetVolunteerHeaderArr.value = ase.decrypt(localStorage.getItem('SetBank_Volun'))
  }

  SetVolunteerHeaderArr.value.forEach((item, index) => {
    if (item.checked == true) {
      allDatas.value.push(SetVolunteerHeaderArr.value[index])
    }
  })

  console.log('最终渲染的全部true数据', allDatas.value)
  // 赋值给dragDemo；
  dragDemo.value.splice(0, dragDemo.value?.length)
  dragDemo.value = JSON.parse(JSON.stringify(allDatas.value))
}
// 检查本地拖拽顺序
const GetDrag = () => {
  if (localStorage.getItem('VolBankDrag') == null) {
    // //console.log('"第一次" :>> ', '第一次');
  } else {
    // //console.log('"第二次" :>> ', '第二次');

    allDatas.value = ase.decrypt(localStorage.getItem('VolBankDrag'))
    dragDemo.value = JSON.parse(JSON.stringify(allDatas.value))
  }
}

// ShowHidenColumn 设置column的的列表(确认按钮)
const ShowHidenColumn = () => {
  allDatas.value = JSON.parse(JSON.stringify(dragDemo.value))
  // 获取列表对象
  const $table = xTable.value
  const column = $table.getColumns()

  console.log('$table.getColumns :>> ', $table.getColumns())
  console.log(SetVolunteerHeaderArr.value, 'SetVolunteerHeaderArr')

  // for (let i = 0; i < SetVolunteerHeaderArr.value?.length; i++) {
  //   if (SetVolunteerHeaderArr.value[i].checked == true) {
  //     // //console.log('object :>> ', SetVolunteerHeaderArr[i].field);
  //     $table.showColumn(SetVolunteerHeaderArr.value[i].field);
  //   }
  // for (let j = 1; j < column?.length; j++) {
  //   if (column[j].title == SetVolunteerHeaderArr.value[i].lable) {
  //     if (SetVolunteerHeaderArr.value[i].checked == false) {

  //       $table.hideColumn(column[j].field);
  //     }
  //   }
  // }
  // }
  $table.refreshColumn()
  console.log('$table.getColumns :>> ', $table.getColumns())
  SetTableHeader.value = false
}
// FixedColumn 设置column的固定列(初始化本地数据)
const FixedColumn = () => {
  const $table = xTable.value
  const column = $table.getColumns()
  //console.log('column:>> ', column);
  //console.log('SetVolunteerHeaderArr', SetVolunteerHeaderArr.value);
  for (let i = 0; i < SetVolunteerHeaderArr.value?.length; i++) {
    for (let j = 1; j < column?.length; j++) {
      if (column[j].title == SetVolunteerHeaderArr.value[i].lable) {
        if (SetVolunteerHeaderArr.value[i].fixed != '') {
          toggleFixedColumn(j, SetVolunteerHeaderArr.value[i].fixed)
        }
      }
    }
  }
  $table.refreshColumn()
  // //console.log('$table.getColumns :>> ', $table.getColumns());
  // SetTableHeader.value = false;
}

const isshowOneExport = ref(false)
const name = ref()
const showOneExport = () => {
  if (!store.state.role_info.includes('volunteer-bank-export')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  console.log()
  name.value = OptionsData.value.volun_name
  isshowOneExport.value = true
  console.log(isshowOneExport.value)
}
// 单个导出
const ExportFile = async (row) => {
  isshowOneExport.value = false
  console.log(row)
  console.log(OptionsData.value._id)
  const res = await http.post('/upload/export-volunteer', { volunteerList: [OptionsData.value._id], sort: SORT.value })
  console.log(res, 1111111111111111)
  if (res.status == 0) {
    message({
      message: '导出成功',
      showClose: true,
      type: 'success',
    })
    xlsx.writeFileXLSX(res.xlsxData, `志愿者信息 ${dayjs().format('YYYYMMDD')}.xlsx`)
  }
}
// 批量导出
const ExportFiles = async (row) => {
  console.log(row)
  const res = await http.post('/upload/export-volunteer', { volunteerList: checkedArr.value, sort: SORT.value })
  // console.log(res);
  if (res.status == 0) {
    message({
      message: '导出成功',
      showClose: true,
      type: 'success',
    })
    xlsx.writeFileXLSX(res.xlsxData, `志愿者信息 ${dayjs().format('YYYYMMDD')}.xlsx`)
  }
}
// 高级搜索 下拉
const change_category_val = (e) => {
  // console.log(e);
  if (e.includes('都没有')) {
    // used_category_val.value = ['都没有'];
    const newValueWithoutAll = ['都没有']
    Volunteers.use_products = newValueWithoutAll
  }
}
// 高级搜索 下拉

const change_basic_illness = (e) => {
  // console.log(e);
  if (e.includes('无任何以上提到的疾病')) {
    const newValueWithoutAll = ['无任何以上提到的疾病']
    Volunteers.underlying_disease = newValueWithoutAll
  }
}
// 格式化项目数量的数据
const FormatterNum = (val) => {
  return val === '——' || val === undefined || val === '' || val === null ? '0' : val
}

const change_basic_illness2 = (e) => {
  // console.log(e);

  if (e.includes('无任何以上提到的疾病') && e[e?.length - 1] == '无任何以上提到的疾病') {
    const newValueWithoutAll = ['无任何以上提到的疾病']
    information.value.underlying_disease = newValueWithoutAll
    console.log('点击')
  }
  console.log(e[e?.length - 1], 'e[e?.length-1]')
  console.log(information.value.underlying_disease?.length, 'basic_illness_val.value?.length')
  if (e[0] == '无任何以上提到的疾病' && information.value.underlying_disease?.length > 1) {
    information.value.underlying_disease.splice(0, 1)
    console.log('有提到')
  }
}

// tip tootip提示方法
const tooltipConfig = reactive({
  showAll: true,
  enterable: true,
  contentMethod: ({ type, column, row, items, _columnIndex }) => {
    const { field } = column

    // column 判断 column.title 是否为'操作'
    // 重写默认的提示内容
    if (column.title == '操作') {
      return (tooltipConfig.showAll = false)
    } else if (field === 'rate') {
      // 返回空字符串，控制单元格不显示提示内容
      return ''
    } else {
      tooltipConfig.showAll = true
    }
    // 其余的单元格使用默认行为

    return null
  },
})
watch(
  () => information.value,
  (newValue) => {
    console.log('object :>> ', newValue)
    // 如果备注不满20
    if (information.value.remark) {
      if (information.value.remark?.length == 20) {
        errObj.remark = true
      }
    }
  }
)
// 请求初始数据
const ReqDefault = async () => {
  // Totalinitialdata.tag
  const res = await http.post('/setting/fetch-setting', {
    type: 'volunteer',
  })
  console.log('标签请求返回的数据是', res)
   // 初始化标签数组
   Totalinitialdata.value.tag = [];
  // labelList
  if (res.status == 0) {
    console.log('res请求初始数据为 :>> ', res)

    res.tags.forEach((item, index) => {
      item.forEach((j) => {
        if (j.value != false) {
          Totalinitialdata.value.tag.push(j.name)
        }
      })
    })

    if (Totalinitialdata.value.tag?.length == 0) {
      Totalinitialdata.value.tag.push('暂无标签，请前往【自定义设置】新建')
    }
    console.log('获取的最终数据', Totalinitialdata.value.tag)
  }
}
//note mounted
onMounted(() => {
  reqProjectList()
  ReqDefault() //请求初始数据
  ExportDate.value = Date() //获取当前时间
  store.commit('Set_CurrentProjectName', '志愿者库')
  
  // 初始话存储数据下
  //console.log('demo1.tableData :>> ', demo1.tableData);
  //处理点击更多操作盒子 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false
      }
    }
  })
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (isshowOperation.value == true) {
      if (e.target.id != 'operationBox') {
        isshowOperation.value = false
      }
    }
  })
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false
      }
    }
    // 操作
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false
      }
      console.log(ClickMoreOptions.value)
    }
  })
})
// note 组件销毁
onBeforeUnmount(() => {
  const table = xTable.value
  console.log('组件销毁时的数值 :>> ', table)
  const FixedList = table.getColumns()
  // console.log('销毁时的列顺序', FixedList);
  for (let i = 1; i < FixedList?.length; i++) {
    for (let j = 0; j < SetVolunteerHeaderArr.value?.length; j++) {
      if (FixedList[i].field == SetVolunteerHeaderArr.value[j].field) {
        SetVolunteerHeaderArr.value[j].fixed = FixedList[i].fixed
      }
    }
  }
  localStorage.setItem('SetBank_Volun', ase.encrypt(SetVolunteerHeaderArr.value))
  localStorage.setItem('VolBankDrag', ase.encrypt(dragDemo.value))
})

// 处理出生日期的时间戳
const birthday = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD')
  }
})
// 处理注册时间戳
const createdAt = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD HH:mm')
  }
})
// 判断是否
const is_output = computed(() => (e) => {
  // console.log('吸烟', e);
  if (e === false) {
    return '否'
  } else if (e === true) {
    return '是'
  } else {
    return ''
  }
})
</script>

<style lang="less" scoped>
@import url('@/style/views/VolunteerManagement/VolunteerBank.less');
.itxst {
  width: 600px;
  display: flex;
}

.itxst > div:nth-of-type(1) {
  flex: 1;
}

.itxst > div:nth-of-type(2) {
  width: 270px;
  padding-left: 20px;
}

.item {
  // border: solid 1px #eee;
  padding: 6px 10px;
  text-align: left;
}

.item:hover {
  cursor: move;
}

.item + .item {
  // margin-top: 10px;
}

.ghost {
  // 跟随鼠标的样式
  border: solid 1px #027aff !important;
  background: #fff !important;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.chosenClass {
  // 鼠标拉动的样式。
  background-color: #fff;
  opacity: 1;
  border: solid 1px #027aff;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.fallbackClass {
  background-color: aquamarine;
}
</style>
