<template>
  <div id="ExpertList">
    <CreateExpert v-model="is_dialogShow" v-if="is_dialogShow"></CreateExpert>
    <div class="ExpertBox">
      <div class="Expert_main_state">
        <!-- 筛选status状态按钮 -->
        <div
          class="main_state_item"
          :class="index == ClickActive ? 'click-active' : ''"
          v-for="(item, index) in ExpertState"
          :key="index"
          @click="ClickActive = index">
          {{ item }}
        </div>
      </div>
      <div class="ExpertTable_main">
        <div class="ExpertTable">
          <div class="vxeTable">
            <vxe-toolbar>
              <template #buttons>
                <div class="topList">
                  <vxe-input class="Search" type="search" v-model="demo1.filterName" placeholder="请输入专家编号或姓名进行搜索" @Blur="searchEvent2">
                  </vxe-input>
                  <vxe-button @click="reset" class="Reset"><span class="reset-icon">重置</span></vxe-button>
                  <!-- 新建专家 -->
                  <div class="New_Expert" @click="CreatedExpert">
                    <img src="https://newstore.vynior.com/%E7%BB%84110130%402x.png" alt="" />
                    <span class="text">新建专家</span>
                  </div>
                  <!-- 导入专家 -->
                  <div class="import_Expert" @click="importExpert">
                    <img src="https://newstore.vynior.com/cems_web/%E4%B8%8A%E4%BC%A0%4023x.png" alt="" />
                    <span class="text">导入专家</span>
                  </div>
                  <div class="batch_operation" @click.stop="showOperation = !showOperation" :class="{ clicked_batch: showOperation }">
                    <span class="text">批量操作</span>
                    <div class="arrow" v-show="!showOperation">
                      <img src="https://newstore.vynior.com/%E4%B8%8B%E6%8B%89.png" alt="" />
                    </div>
                    <div class="arrow" v-show="showOperation">
                      <img src="https://newstore.vynior.com/cems_web/blue.png" alt="" />
                    </div>
                  </div>
                  <!-- 批量操作下拉框 -->
                  <div class="batch_operation_box" id="operationBox" v-if="showOperation">
                    <div class="item_box" @click="isExportAll">
                      <div class="downImg"></div>
                      <!-- <img src="https://newstore.vynior.com/%E5%AF%BC%E5%87%BA%402x.png" alt="" /> -->
                      <div class="item_one_text">导出</div>
                    </div>
                    <div class="item_box1" @click="isDeleteAll">
                      <img src="https://newstore.vynior.com/%E5%88%A0%E9%99%A4%402x.png" alt="" />
                      <div class="item_two_text" style="margin-top: 0px">删除</div>
                    </div>
                  </div>
                  <!-- </div> -->
                </div>
              </template>
            </vxe-toolbar>
            <div class="ContentBox" v-if="tableData.length != 0">
              <vxe-table
                border
                stripe
                ref="xTable"
                height="auto"
                show-overflow
                auto-resize
                align="left"
                row-id="_id"
                :data="tableData"
                :column-config="{ resizable: true }"
                :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
                :checkbox-config="{ reserve: true }"
                :loading="demo1.loading"
                @header-cell-dragend="handleHeaderDragend"
                @checkbox-all="selectAllChangeEvent1"
                @checkbox-change="selectChangeEvent1">
                <vxe-column field="checked" type="checkbox" width="50" :fixed="demo1.colFixeds.col0"></vxe-column>
                <vxe-column field="expert_number" type="html" title="专家编号" width="190" :fixed="demo1.colFixeds.col1" min-width="120">
                  <!-- 增加下拉箭头 -->
                  <template #header="{ column }">
                    <div class="ID-header" v-if="column.title === '专家编号'">
                      <span class="name">专家编号</span>
                      <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('专家编号', $event)"></i></span>
                    </div> </template
                ></vxe-column>
                <vxe-column field="expert_name" type="html" title="专家姓名" width="190" :fixed="demo1.colFixeds.col2">
                  <!-- 增加下拉箭头 -->
                  <template #header="{ column }">
                    <div class="name-header" v-if="column.title === '专家姓名'">
                      <span class="name">专家姓名</span>
                      <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('专家姓名', $event)"></i></span>
                    </div> </template
                ></vxe-column>
                <vxe-column field="expert_gender" type="html" title="专家性别" width="190" :fixed="demo1.colFixeds.col3">
                  <!-- 增加下拉箭头 -->
                  <template #header="{ column }">
                    <div class="Sex-header" v-if="column.title === '专家性别'">
                      <span class="name">专家性别</span>
                      <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('专家性别', $event)"></i></span>
                    </div>
                  </template>
                </vxe-column>
                <vxe-column field="expert_phone" type="html" title="联系方式" width="190" :fixed="demo1.colFixeds.col4">
                  <!-- 增加下拉箭头 -->
                  <template #header="{ column }">
                    <div class="Address-header" v-if="column.title === '联系方式'">
                      <span class="name">联系方式</span>
                      <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('联系方式', $event)"></i></span>
                    </div>
                  </template>
                </vxe-column>
                <vxe-column field="status" type="html" title="专家状态" width="190" :fixed="demo1.colFixeds.col5" min-width="80">
                  <!-- 增加下拉箭头 -->
                  <template #header="{ column }">
                    <div class="Role-header" v-if="column.title === '专家状态'">
                      <span class="name">专家状态</span>
                      <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('专家状态', $event)"></i></span>
                    </div>
                  </template>
                  <template #default="{ row }">
                    <div class="Expert-status">
                      <span class="status_style" :class="row.status == '兼职' ? 'Green' : row.status == '全职' ? 'Blue' : ''">{{ row.status }}</span>
                    </div>
                  </template>
                </vxe-column>

                <vxe-column field="tags" type="html" title="标签" width="190" :fixed="demo1.colFixeds.col6">
                  <!-- 增加下拉箭头 -->
                  <template #header="{ column }">
                    <div class="Role-header" v-if="column.title === '标签'">
                      <span class="name">标签</span>
                      <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('标签', $event)"></i></span>
                    </div>
                  </template>
                </vxe-column>
                <vxe-column field="remark" type="html" title="备注" width="190" :fixed="demo1.colFixeds.col7">
                  <!-- 增加下拉箭头 -->
                  <template #header="{ column }">
                    <div class="Role-header" v-if="column.title === '备注'">
                      <span class="name">备注</span>
                      <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('备注', $event)"></i></span>
                    </div>
                  </template>
                </vxe-column>
                <vxe-column align="center" title="操作" width="200" :fixed="demo1.colFixeds.col8" min-width="90" :resizable="false">
                  <!-- 增加下拉箭头 -->
                  <template #header="{ column }">
                    <div class="Operation-header" v-if="column.title === '操作'">
                      <span class="name">操作</span>
                      <!-- <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span> -->
                    </div>
                  </template>
                  <template #default="{ row }">
                    <vxe-button class="detail" @click="showDetails(row)">查看详情</vxe-button>
                    <vxe-button class="delete" @click="delchange(row)">删除</vxe-button>
                  </template>
                  <!-- <template #default>
                      <vxe-button class="delete">删除</vxe-button>
                    </template> -->
                </vxe-column>

                <template #empty>
                  <span style="color: red">
                    <!-- <img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif" /> -->
                    <p>没有更多数据了！</p>
                  </span>
                </template>
              </vxe-table>
              <!-- 下拉箭头的更多操作 -->
              <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
                <div class="contentBox" id="MoreOptions">
                  <ul class="list" id="MoreOptions">
                    <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                      <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                    </li>
                    <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                      <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                    </li>
                    <!-- <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')">
                                <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" />固定
                              </li>
                              <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" style="color: #bfbfbf">
                                <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" />取消固定
                              </li>

                              <li id="MoreOptions" @click="HiddenColumn(FixedIndex)">
                                <img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏
                              </li> -->
                  </ul>
                </div>
              </div>
            </div>
            <div class="EmptyProject" v-if="tableData.length == 0">
              <div class="emptyConetnt">
                <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
                <p>暂无项目</p>
              </div>
            </div>
            <p>
              <vxe-pager
                class="Buttons"
                background
                @page-change="pageChange"
                v-model:current-page="page5.currentPage"
                v-model:page-size="page5.pageSize"
                :total="page5.totalResult"
                :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
                <template #left>
                  <div class="Total">
                    已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                    >条/共{{ page5.totalResult }}条
                  </div>
                </template>
              </vxe-pager>
            </p>
          </div>
        </div>
      </div>
      <!-- 导入窗口 -->
      <!-- 导入 -->
      <el-dialog class="expert_dialog" v-model="importShow" title="Shipping address" :align-center="true" :close-on-click-modal="false">
        <div class="dialog_title">
          <span>导入专家</span>
          <div class="title_cancel" @click="importShow = false"></div>
        </div>
        <div class="dialog_main">
          <div class="main_download">
            <div class="download_title">1.下载模板，填写专家信息</div>
            <div class="download_img"></div>
            <div class="upload_name"></div>
            <div class="upload_err"></div>
            <div class="download_but" @click="downloadXlsx">
              <button>下载模板</button>
            </div>
          </div>
          <div class="main_upload">
            <div class="upload_title">2.上传填写好的文件</div>
            <div class="upload_img"></div>
            <div class="upload_name">
              <span v-if="uploadFileName">{{ uploadFileName }}123</span>
            </div>
            <div class="upload_tip">
              <span v-if="uploadFileErr">文件格式错误，请重新上传</span>
              <span class="tip_up" v-if="uploadFileName && !uploadFileErr">重新选择</span>
            </div>
            <div class="upload_but">
              <el-upload
                class="upload_xlsx"
                ref="videoRef"
                accept=".xlsx,.xls"
                :http-request="uploadFile"
                :on-preview="handlePreviews"
                :on-remove="handleRemoves"
                :file-list="fileLists"
                :show-file-list="false">
                <button>上传文件</button>
              </el-upload>
            </div>
          </div>
        </div>
      </el-dialog>
      <!-- 导入专家失败 -->
      <el-dialog class="expert_dialogs" :align-center="true" v-model="errExpertShow" title="Shipping address" :close-on-click-modal="false">
        <div class="dialog_title">
          <span>失败</span>
          <div class="title_cancel" @click="errExpertShow = false"></div>
        </div>
        <div class="dialog_main">
          <img class="main_err" src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402xs.png" alt="" />
          <div class="main_fail">导入失败</div>
          <div class="main_errT1">
            表格中存在<span class="tip">{{ errFileNum }}条</span>有误的专家信息
          </div>
          <div class="main_errT2">请下载报错文件，修改后重新导入专家。</div>
          <div class="main_but" @click="downloadErrFile">
            <button>下载文件</button>
          </div>
        </div>
      </el-dialog>
      <!-- 无数据删除 -->
      <div class="Derived_volunteer_null" v-if="null_reminder_delect">
        <div class="Derived_volunteer_box">
          <div class="Derived_title">
            <div class="Derived_name">提示</div>
            <div>
              <img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
            </div>
          </div>
          <div class="Derived_body">
            <div>
              <img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" />
            </div>
            <div class="Derived_body_text">请选择需要删除的志愿者信息！</div>
          </div>
        </div>
      </div>

      <!-- 导出弹窗 -->
      <!-- 批量导出 -->
      <div class="Derived_expert" v-if="showExportAll">
        <div class="Derived_expert_box">
          <div v-if="checkedArr.length > 1">
            <div class="Derived_title">
              已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>专家信息，确认导出吗？
            </div>
            <div class="Derived_body">
              <img src="https://new.vynior.com/111.png" alt="" />
              <div class="body_text">导出后可在excel表格中进行查看</div>
            </div>
            <div class="Derived_bottom">
              <div class="off" @click="showExportAll = false">取消</div>
              <div class="ok" @click="exportAll">确认</div>
            </div>
          </div>
          <div v-if="checkedArr.length == 1">
            <div class="Derived_title">
              已选中<span class="Derived_name">&nbsp;{{ selectedRows[0].expert_name }}的&nbsp;</span>专家信息，确认导出吗？
            </div>
            <div class="Derived_body">
              <img src="https://new.vynior.com/111.png" alt="" />
              <div class="body_text">导出后可在excel表格中进行查看</div>
            </div>
            <div class="Derived_bottom">
              <div class="off" @click="showExportAll = false">取消</div>
              <div class="ok" @click="exportAll">确认</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 批量删除弹窗 -->
      <div id="ques_dialog" v-if="showDeleteAll">
        <el-dialog v-model="showDeleteAll" :close-on-click-modal="false" align-center="true" :show-close="false">
          <template #header>
            <div class="title" v-if="checkedArr.length > 1">
              已选中
              <span class="col">{{ checkedArr.length }}</span>
              条专家信息，确定删除吗？
            </div>
            <div class="title" v-if="checkedArr.length == 1">
              已选中
              <span class="col">{{ selectedRows[0]?.expert_name }}</span>
              的专家信息，确定删除吗？
            </div>
          </template>
          <template #default>
            <div class="Derived_body">
              <img src="https://new.vynior.com/111.png" alt="" />
              <div class="body_text">删除后将无法恢复</div>
            </div>
            <div class="Derived_but">
              <el-button class="cancel" @click="showDeleteAll = false">取消</el-button>
              <el-button class="sure" type="primary" @click="deleteAll"> 删除 </el-button>
            </div>
          </template>
        </el-dialog>
      </div>
      <!-- 单个删除弹窗 -->
      <div id="ques_dialog" v-if="delDialog">
        <el-dialog v-model="delDialog" :close-on-click-modal="false" align-center="true" :show-close="false">
          <template #header>
            <div class="title">
              确认删除
              <span class="col">{{ OptionsData?.expert_name }}</span>
              的专家信息吗？
            </div>
          </template>
          <template #default>
            <div class="Derived_body">
              <img src="https://new.vynior.com/111.png" alt="" />
              <div class="body_text">删除后将无法恢复</div>
            </div>
            <div class="Derived_but">
              <el-button class="cancel" @click="delDialog = false">取消</el-button>
              <el-button class="sure" type="primary" @click="delUserInfoChange"> 删除 </el-button>
            </div>
          </template>
        </el-dialog>
      </div>
      <!-- 无数据导出 -->
      <div class="Derived_expert_null" v-if="nullExport">
        <!-- 导出 -->
        <div class="Derived_expert_box">
          <div class="Derived_title">
            <div class="Derived_name">提示</div>
            <div>
              <img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
            </div>
          </div>
          <div class="Derived_body">
            <div>
              <img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" />
            </div>
            <div class="Derived_body_text">请选择需要导出的专家信息！</div>
          </div>
        </div>
      </div>
      <!-- 无数据删除 -->
      <div class="Derived_expert_null" v-show="nullDelete">
        <div class="Derived_expert_box">
          <div class="Derived_title">
            <div class="Derived_name">提示</div>
            <div>
              <img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
            </div>
          </div>
          <div class="Derived_body">
            <div>
              <img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" />
            </div>
            <div class="Derived_body_text">请选择需要删除的专家信息！</div>
          </div>
        </div>
      </div>
      <!-- 查看详情抽屉 -->
      <el-drawer v-model="clickDetail" :close-on-click-modal="showDetail" :show-close="false" title="I am the title" id="expert_dialog">
        <template #header>
          <div class="expert_title">
            <span>专家信息</span>
            <div class="Edit" @click="EditOpen">
              <img @click="showEdit" v-if="showDetail" src="https://newstore.vynior.com/%E7%BC%96%E8%BE%91.png" alt="" />
            </div>
          </div>
        </template>
        <template #default>
          <div class="expert_main" v-if="showDetail">
            <!-- 基础信息 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">基础信息</div>
              </div>
              <div class="info_main">
                <div class="list_line_top">
                  <div class="list_line">
                    <!-- 专家编号 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span class="text_top">*</span>
                          专家编号
                        </div>
                        <div class="main">
                          <div class="listText">
                            {{ information.expert_number == '' ? '—' : information.expert_number }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 专家性别 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span class="text_top">*</span>
                          专家性别
                        </div>
                        <div class="main">
                          <div class="listText">
                            {{ information.expert_gender == '' ? '—' : information.expert_gender }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 专家状态 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span class="text_top">*</span>
                          专家状态
                        </div>
                        <div class="main">
                          <div class="listText">
                            {{ information.status == '' ? '—' : information.status }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 标签 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span>标签</span>
                        </div>
                        <div class="main">
                          <div class="listText">
                            {{ information.tags == '- -' || information.tags == '——' ? '——' : information.tags.join(',') }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_line">
                    <!-- 专家姓名 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span class="text_top">*</span>
                          专家姓名
                        </div>
                        <div class="main">
                          <div class="listText">
                            {{
                              information?.expert_name == '' || information?.expert_name == '——' || information?.expert_name == '- -'
                                ? '——'
                                : information?.expert_name
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 联系方式 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span class="text_top">*</span>
                          <span>联系方式</span>
                        </div>
                        <div class="main">
                          <div class="listText">
                            {{
                              information.expert_phone == '' || information.expert_phone == '——' || information.expert_phone == '- -'
                                ? '——'
                                : information.expert_phone
                            }}
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- 注册时间 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span>注册时间</span>
                        </div>
                        <div class="main">
                          <div class="listText">
                            {{
                              information.createdAt.slice(0, 10) == '' || information.createdAt == '——' || information.createdAt == '- -'
                                ? '——'
                                : information.createdAt.slice(0, 10)
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 备注 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text" id="shortTe">
                          <span>备注</span>
                        </div>
                        <div class="main">
                          <div class="listText">
                            {{ information.remark == '' || information.remark == '——' || information.remark == '- -' ? '——' : information.remark }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 专家介绍 -->
                <div class="list_line">
                  <div class="info">
                    <div class="list_content">
                      <div class="text">
                        <span>专家介绍</span>
                      </div>
                      <div class="main">
                        <div class="bigListText">
                          {{
                            information.description == '' || information.description == '——' || information.description == '- -'
                              ? '——'
                              : information.description
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 文件 -->
            <div class="upload_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">文件</div>
              </div>
              <div class="file">
                <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2081%20%E2%80%93%2043%402x.png" alt="" />
              </div>
              <div class="Table" v-show="attachmentList?.length">
                <el-table :data="attachmentList" border stripe style="width: 688px">
                  <el-table-column type="index" label="序号" width="56" min-width="55" />
                  <el-table-column prop="fileName" label="文件名称" width="470" min-width="259">
                    <template #default="scope">
                      <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                        {{ scope.row.fileName }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="upLoadTime" label="上传时间" width="208" min-width="207">
                    <template #default="scope">
                      <div style="color: #626366">
                        {{ formatTime(scope.row.uploadTime) }}
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column label="操作" width="300" min-width="163">
                    <template #default="scope">
                      <!-- <el-button link type="primary" size="small">预览</el-button> -->
                      <el-button
                        link
                        type="primary"
                        @click="downloadTheFiles(scope.row)"
                        size="small"
                        style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                        >下载</el-button
                      >
                      <el-button
                        link
                        type="primary"
                        size="small"
                        style="
                          font-size: 14px;
                          cursor: default;
                          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                          font-weight: 400;
                          color: #027aff;
                        "
                        >删除</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <!-- 参与项目信息 -->
            <div class="main_info">
              <div class="info_title">
                <div class="title_color"></div>
                <div class="title_text">参与项目信息</div>
                <div class="remindText">
                  <span>在【项目】-【专家】中链入后自动填入</span>
                </div>
              </div>
              <div class="project_table1">
                <el-table :data="table_Data" stripe border style="width: 998px">
                  <el-table-column prop="orderNumber" label="序号" width="56" />
                  <el-table-column prop="number" label="项目编号" width="482" />
                  <el-table-column prop="name" label="项目名称" width="460" />
                </el-table>
              </div>
            </div>
          </div>
          <!-- 编辑抽屉 -->
          <div class="edit_main" v-if="isShowEdit">
            <div class="expert_main">
              <!-- 基础信息 -->
              <div class="main_info">
                <div class="info_title">
                  <div class="title_color"></div>
                  <div class="title_text">基础信息</div>
                </div>
                <div class="info_main">
                  <div class="list_line_top">
                    <div class="list_line">
                      <!-- 专家编号 -->
                      <div class="info">
                        <div class="list_content" id="left_content">
                          <div class="text">
                            <span class="text_top">*</span>
                            专家编号
                          </div>
                          <div class="main">
                            <el-input
                              v-model="information1.expert_number"
                              class="inp"
                              placeholder="请输入专家编号"
                              suffix-icon=""
                              :class="errObj.number ? 'inp_err' : ''"
                              @focus="errObj.number = false" />
                            <div class="err_text">
                              <span v-if="errObj.number">专家编号不可为空</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 专家性别 -->
                      <div class="info">
                        <div class="list_content" id="left_content">
                          <div class="text">
                            <span class="text_top">*</span>
                            专家性别
                          </div>
                          <div class="main">
                            <el-select class="select" v-model="information1.expert_gender" placeholder="请选择" :class="is_sexError ? 'inp_err' : ''">
                              <el-option v-for="item in sexArr" :key="item" :label="item" :value="item" />
                            </el-select>
                            <div class="err_text sex_error" v-if="is_sexError">
                              <span>专家性别不可为空</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 专家状态 -->
                      <div class="info">
                        <div class="list_content" id="left_content">
                          <div class="text">
                            <span class="text_top">*</span>
                            专家状态
                          </div>
                          <div class="main">
                            <el-select v-model="information1.status" placeholder="请选择" class="select" :class="is_statusError ? 'inp_err' : ''">
                              <el-option v-for="item in statusArr" :key="item" :label="item" :value="item" />
                            </el-select>
                            <div class="err_text status_error" v-if="is_statusError">
                              <span>专家状态不可为空</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 标签 -->
                      <div class="info">
                        <div class="list_content" id="left_content">
                          <div class="text">
                            <span>标签</span>
                          </div>
                          <div class="main">
                            <el-select
                              v-model="information1.tags"
                              class="select"
                              placeholder="请选择"
                              size="large"
                              multiple
                              collapse-tags
                              collapse-tags-tooltip>
                              <el-option
                                v-for="item in labelArr"
                                :key="item"
                                :label="item"
                                :value="item"
                                :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                            </el-select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="list_line">
                      <!-- 专家姓名 -->
                      <div class="info">
                        <div class="list_content">
                          <div class="text">
                            <span class="text_top">*</span>
                            专家姓名
                          </div>
                          <div class="main">
                            <el-input
                              v-model="information1.expert_name"
                              class="inp"
                              placeholder="请输入专家姓名"
                              suffix-icon=""
                              :class="errObj.name ? 'inp_err' : ''"
                              @focus="errObj.name = false" />
                            <div class="err_text">
                              <span v-if="errObj.name">{{ nameError }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 联系方式 -->
                      <div class="info">
                        <div class="list_content">
                          <div class="text">
                            <span class="text_top">*</span>
                            <span>联系方式</span>
                          </div>
                          <div class="main">
                            <el-input
                              v-model="information1.expert_phone"
                              class="inp"
                              placeholder="请输入联系方式"
                              suffix-icon=""
                              :class="errObj.phone ? 'inp_err' : ''"
                              @focus="errObj.phone = false" />
                            <div class="err_text">
                              <span v-if="errObj.phone">{{ phoneError }}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- 注册时间 -->
                      <div class="info">
                        <div class="list_content">
                          <div class="text">
                            <span>注册时间</span>
                          </div>
                          <div class="main">
                            <div class="val">
                              {{ information1.createdAt.slice(0, 10) == '' ? '系统自动生成' : information1.createdAt.slice(0, 10) }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- 备注 -->
                      <div class="info">
                        <div class="list_content">
                          <div class="text" id="shortTe">
                            <span>备注</span>
                          </div>
                          <div class="main">
                            <el-input maxlength="20" v-model="information1.remark" class="inp" placeholder="请输入备注" suffix-icon="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 专家介绍 -->
                  <div class="list_line">
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span>专家介绍</span>
                        </div>
                        <div class="main">
                          <el-input
                            v-model="information1.description"
                            class="bigInp"
                            type="textarea"
                            resize="none"
                            placeholder="请输入专家介绍"
                            suffix-icon="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 文件 -->
              <div class="upload_info">
                <div class="info_title">
                  <div class="title_color"></div>
                  <div class="title_text">文件</div>
                </div>
                <div class="files">
                  <el-upload class="upload_xlsx" ref="videoRef" :http-request="uploadsFile" :show-file-list="false" :disabled="Volunteereditor">
                    <img src="https://newstore.vynior.com/cems_web/%E4%B8%8A%E4%BC%A0%402x.png" alt="" />
                    <span>上传附件</span>
                  </el-upload>
                  <!-- <el-upload
                    class="div"
                    ref="videoRef"
                    :http-request="uploadFile"
                    :on-preview="handlePreviews"
                    :on-remove="handleRemoves"
                    :file-list="fileLists"
                    :show-file-list="false">
                    <el-button type="primary" plain>
                      <img class="uploadIcon" src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%E7%AE%AD%E5%A4%B4%402x.png" alt="" />
                      上传附件
                    </el-button>
                  </el-upload> -->
                </div>
                <div class="Table" v-show="attachmentList?.length">
                  <el-table :data="attachmentList" border stripe>
                    <el-table-column type="index" label="序号" width="56" min-width="55" />
                    <el-table-column prop="fileName" label="文件名称" width="470" min-width="259">
                      <template #default="scope">
                        <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                          {{ scope.row.fileName }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column prop="upLoadTime" label="上传时间" width="208" min-width="207">
                      <template #default="scope">
                        <div style="color: #626366">
                          {{ formatTime(scope.row.uploadTime) }}
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" width="300" min-width="163">
                      <template #default="scope">
                        <!-- <el-button link type="primary" size="small">预览</el-button> -->
                        <el-button
                          link
                          type="primary"
                          @click="downloadTheFiles(scope.row)"
                          size="small"
                          style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                          >下载</el-button
                        >
                        <el-button
                          link
                          type="primary"
                          @click="deleteTheFiles(scope.row.uuid)"
                          size="small"
                          style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                          >删除</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <!-- 参与项目信息 -->
              <div class="main_info">
                <div class="info_title">
                  <div class="title_color"></div>
                  <div class="title_text">参与项目信息</div>
                  <div class="remindText">
                    <span>在【项目】-【专家】中链入后自动填入</span>
                  </div>
                </div>
                <div class="project_table1">
                  <el-table :data="table_Data" stripe border style="width: 998px">
                    <el-table-column prop="orderNumber" label="序号" width="56" />
                    <el-table-column prop="number" label="项目编号" width="482" />
                    <el-table-column prop="name" label="项目名称" width="460" />
                  </el-table>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <div style="flex: auto" v-if="isShowEdit">
            <el-button @click="EditClose">取消</el-button>
            <el-button type="primary" @click="updateExpert"> 确定 </el-button>
          </div>
        </template>
      </el-drawer>
    </div>
  </div>
</template>

<script setup>
import CreateExpert from '@/components/ExpertManagement/CreateExpert.vue'
// import Date from '@/utils/CurrentDate';
import data from '@/utils/CurrentDate'
import { replaceEmptyValues } from '@/utils/emptyArray'
import http from '@/utils/http'
import { message } from '@/utils/resetMessage'
import dayjs from 'dayjs'
import axios from 'axios'
import { ElMessage, ElTable, ElTableColumn } from 'element-plus'
import { computed, onMounted, onBeforeUnmount, reactive, ref, watch, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as xlsx from 'xlsx'

const linewidth = ref(window.innerWidth)
const handleResize = () => {
  linewidth.value = window.innerWidth
}

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
//API接收
const store = useStore()
const router = useRouter()
const xTable = ref()

const formatTime = (timeString) => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')
  const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return currentDateTime
}

let TypeList = ref('全部')
let table_Data = [
  // {
  //   orderNumber: '001',
  //   projectNumber: 'HT-CD-202x-xxx-R-1-xxx',
  //   projectName: '4周功效测试-V1',
  // },
  // {
  //   orderNumber: '002',
  //   projectNumber: 'HT-CD-202x-xxx-R-1-xxx',
  //   projectName: '4周功效测试-V1',
  // },
]
// !表单空白处理
const tableData = computed(() => {
  console.log('2332', replaceEmptyValues(demo1.tableData))

  return replaceEmptyValues(demo1.tableData)
})
// 判断是否是高级搜索模式
let Advancedfind = ref(false)
// 分页--->跳转页面显示数据处理
const pageChange = async () => {
  // 判断是否是高级搜索模式
  if (Advancedfind.value) {
    reqAllSelectData()
  } else {
    // 不是高级搜索就是普通的数据
    if (demo1.filterName == '' && demo1.age == '' && demo1.gender == '') {
      if (ClickActive.value == 0) {
        const res = await http.post('/expert/find-expert', {
          page: page5.currentPage,
          size: page5.pageSize,
          sort: SORT.value,
          content: '',
        })
        demo1.tableData = res.result
        page5.totalResult = res.totalCount
        // checkedArr.value.splice(0, checkedArr.value.length);
      }
      if (ClickActive.value == 1) {
        console.log('全职')

        // page5.currentPage = 1;
        const res = await http.post('/expert/find-expert', {
          page: page5.currentPage,
          size: page5.pageSize,
          sort: SORT.value,
          status: '全职',
          content: '',
        })
        demo1.tableData = res.result
        page5.totalResult = res.totalCount
        // checkedArr.value.splice(0, checkedArr.value.length);
      }
      if (ClickActive.value == 2) {
        console.log('兼职')
        // page5.currentPage = 1;
        const res = await http.post('/expert/find-expert', {
          page: page5.currentPage,
          size: page5.pageSize,
          sort: SORT.value,
          status: '兼职',
          content: '',
        })
        demo1.tableData = res.result
        page5.totalResult = res.totalCount
        // checkedArr.value.splice(0, checkedArr.value.length);
      }
    } else {
      SearchGender()
    }
  }
}

// 切换状态true/false
const ChangeStatus = ref(false)
// ! 控制批量操作的显示
let showOperation = ref(false)
// !批量按钮显示
//const showOperation = () => {
// isshowOperation.value = true;
//};
// ! 导出志愿信息弹框
let isDerived = ref(false)
// ! 导出志愿信息弹框   失败
let isDerived_delect = ref(false)
// ! 下拉实时更新数据
let options = ref([])
//  !输入框数据
let selectID = ref([])
// ! 显示按钮
let isShow = ref(false)
//  每一行的 ... 点击
let ClickMoreOptions = ref(false)
const CMoreOptions = ref(null) //获取MoreOptions盒子的ref
let OptionsData = ref()
// 批量操作  true/false 盒子
let BatchBox = ref(false)
// 批量删除盒子 BatchDelete
let BatchDelete = ref(false)
// ! 搜索的数据
let remark = ref(true)
let oneexpertdata = ref([])
// note 表内导航栏
const ExpertState = ['全部', '全职', '兼职']
const ClickActive = ref(0)
//切换/全部/全职/兼职];
watch(
  () => ClickActive.value,
  async (newValue) => {
    let Project = ''
    if (newValue == 0) {
      Project = '全部'
      console.log('全部')
      const $table = xTable.value
      $table.clearCheckboxRow()
      // checkedArr.value.splice(0, checkedArr.value.length);
    } else if (newValue == 1) {
      Project = '全职'
      console.log('全职')
      const $table = xTable.value
      $table.clearCheckboxRow()
      // checkedArr.value.splice(0, checkedArr.value.length);
    } else if (newValue == 2) {
      Project = '兼职'
      console.log('兼职')
      const $table = xTable.value
      $table.clearCheckboxRow()
      // checkedArr.value.splice(0, checkedArr.value.length);
    }
    console.log('Project--->', newValue)
    if (newValue == 0) {
      const $table = xTable.value
      $table.clearCheckboxRow()
      TypeList.value = '全部'
      reqProjectList()
    } else if (newValue == 1) {
      const $table = xTable.value
      $table.clearCheckboxRow()
      TypeList.value = '全职'
      page5.currentPage = 1
      const res = await http.post('/expert/find-expert', {
        page: page5.currentPage,
        size: page5.pageSize,
        //sort: SORT.value,
        content: demo1.filterName,
        status: '全职',
      })
      console.log('res--->', res)
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
      console.log('全职信息', res.result)
      console.log('全职信息2', res.totalCount)

      console.log('所有勾选2', checkedArr.value)

      // nextTick(() => {
      // 这里可以访问到更新后的数据和视图
      checkedArr.value.splice(0, checkedArr.value.length)
      // });
      console.log('所有勾选22', checkedArr.value)
    } else if (newValue == 2) {
      const $table = xTable.value
      $table.clearCheckboxRow()
      page5.currentPage = 1
      TypeList.value = '兼职'
      const res = await http.post('/expert/find-expert', {
        page: page5.currentPage,
        size: page5.pageSize,
        sort: SORT.value,
        content: demo1.filterName,
        status: '兼职',
      })
      console.log('res--->', res)
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
      console.log('所有勾选3', checkedArr.value)
      // nextTick(() => {
      // 这里可以访问到更新后的数据和视图
      checkedArr.value.splice(0, checkedArr.value.length)
      // });
      console.log('所有勾选33', checkedArr.value)
    }
  }
)
//新建专家抽屉显示隐藏
const is_dialogShow = ref(false)
watch(
  () => is_dialogShow.value,
  () => {
    if (is_dialogShow.value === false) {
      ClickActive.value = 0
      reqProjectList()
    }
  }
)
// 设置新建专家组件显示隐藏
let CreatedExpertShow = ref(false)
// 点击新建专家
const CreatedExpert = () => {
  CreatedExpertShow.value = !CreatedExpertShow.value
  console.log('点击新建专家')
  is_dialogShow.value = true
  ClickActive.value = 0
}
const currentPage = ref(1)
const pageSize = ref(15)
const totoalCount = ref(100)
const loading = ref(false)
// 模糊搜索 判断是否是第一次
const EventSearch = ref(false)
// 模糊Age 判断是否是第一次
const AgeSearch = ref(false)
// 模糊Gender 判断是否是第一次
const GenderSearch = ref(false)
// !重置按钮
const reset = () => {
  demo1.age = ''
  demo1.gender = ''
  demo1.filterName = ''
  EventSearch.value = false
  GenderSearch.value = false

  const $table = xTable.value
  $table.clearCheckboxRow()
  checkedArr.value.splice(0, checkedArr.value.length) //清空勾选状态

  AgeSearch.value = false
  ClickActive.value = 0
  reqProjectList()
}
// sort排序字段
let SORT = ref({ createdAt: -1 })
//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
})
//显示功能的字段设置
let AllShowHeader = ref([])
// 全部显示里被隐藏的
let AllHidenHeader = ref([])
const SetShow = ref(false)
const ShowBox = ref(null) //获取Show盒子的ref
const ShowCheck = ref([])
//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  loading: false,
  list: [],
  gender: '',
  age: '',
})
//批量导入
//导出弹窗显示隐藏
let importShow = ref(false)
let uploadPercentage = ref(0)
// 上传文件名
let uploadFileName = ref('')
// 上传的文件名
let fileName = ref('')
// 是否上传错误
let uploadFileErr = ref(false)
//导入失败显示隐藏
const errExpertShow = ref(false)
// 导入错误的数据量
let errFileNum = ref(null)
//导入弹窗
const importExpert = () => {
  // const $table = xTable.value;
  // $table.exportData({ type: 'csv' });
  importShow.value = true
}
let errorPath = ''
// 上传事件
const uploadFile = async (params) => {
  console.log(params)
  const file = params.file
  // fileName.value=file.name
  const lastIndex = file.name.lastIndexOf('.')
  fileName.value = file.name.substring(0, lastIndex)
  const form = new FormData()
  form.append('file', file)
  console.log(params.file)
  console.log(form)
  await http
    .post('/upload/upload-expert', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async (res) => {
      console.log(res)
      if (res.status == 0) {
        importShow.value = false
        if (ClickActive.value == 0) {
          reqProjectList()
        } else if (ClickActive.value == 1) {
          const res = await http.post('/expert/find-expert', {
            page: page5.currentPage,
            size: page5.pageSize,
            sort: SORT.value,
            content: demo1.filterName,
            status: '全职',
          })
          console.log('res--->', res)
          demo1.tableData = res.result
          page5.totalResult = res.totalCount
        } else if (ClickActive.value == 2) {
          const res = await http.post('/expert/find-expert', {
            page: page5.currentPage,
            size: page5.pageSize,
            sort: SORT.value,
            content: demo1.filterName,
            status: '兼职',
          })
          console.log('res--->', res)
          demo1.tableData = res.result
          page5.totalResult = res.totalCount
        }
        ElMessage({
          showClose: true,
          message: '导入专家成功',
          type: 'success',
        })
      } else if (res.status == 1) {
        ElMessage({
          showClose: true,
          message: '上传文件为空，请重新上传',
          type: 'error',
        })
      }
      if (res.status == 2) {
        importShow.value = false
        ElMessage({
          showClose: true,
          message: '上传文件失败，请重新上传',
          type: 'error',
        })
        errorPath = res.errorPath
        errExpertShow.value = true
        errFileNum.value = res.errorNumber
      }
    })
}
// 点击下载错误模板
const downloadErrFile = async () => {
  axios
    .post('https://cems.fuhuan.ltd/api/upload/downloadError-volunteer', { errorPath: errorPath }, { responseType: 'arraybuffer' })
    .then((result) => {
      console.log(result, '返回的错误数据')
      const blob = new Blob([result.data], {
        type: 'application/vnd.ms-excel',
      })
      let name = fileName.value + '-报错.xlsx'
      const downloadElement = document.createElement('a')
      let classificationhref = window.URL.createObjectURL(blob)
      downloadElement.href = classificationhref
      downloadElement.download = `${name}`
      // // console.log("blob :>> "，blob);
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(classificationhref)
    })
}
const handlePreviews = (file) => {
  console.log(file, 'handlePreviews')
}
const uploadFileSuccess = (res) => {
  console.log('上传成功')
  console.log(res)
}

const handleRemoves = (file, fileList) => {
  console.log(file, fileList, 'handleRemoves')
}
// 点击下载模板
const downloadXlsx = async () => {
  const xlsxUrl = 'https://cems.fuhuan.ltd//专家列表导入表格.xlsx'
  const response = await fetch(xlsxUrl)
  const blob = await response.blob()
  // 创建下载链接
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = '专家列表导入表格.xlsx'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  // 释放 Blob URL
  window.URL.revokeObjectURL(url)
}
//批量导出
const nullExport = ref(false)
const showExportAll = ref(false)

const isExportAll = () => {
  console.log(123, checkedArr.value)
  console.log(123, checkedArr.value)
  if (checkedArr.value.length != 0) {
    console.log(checkedArr.value)

    showExportAll.value = true
    console.log(checkedArr.value)
  } else if (checkedArr.value.length == 0) {
    nullExport.value = true
    showExportAll.value = false
  }
  console.log(showExportAll.value)
  console.log(nullExport.value)
  console.log(checkedArr.value.length)
}
// 导出的当前时间
let ExportDate = ref('')
ExportDate.value = Date() //获取当前时间
// 批量导出
const exportAll = async () => {
  console.log(123, 'OptionsData.value')
  console.log(information1.value.expert_name)
  console.log(checkedArr.value)
  const res = await http.post('/upload/export-expert', {
    ExpertList: checkedArr.value,
    sort: SORT.value,
  })
  console.log(res)
  if (res.status == 0) {
    showExportAll.value = false
    xlsx.writeFileXLSX(res.xlsxData, '专家列表' + ExportDate.value + '.xlsx')
  } else {
    message({
      message: '批量导出失败',
      showClose: true,
      type: 'error',
    })
  }
}
//批量删除
const showDeleteAll = ref(false)

const nullDelete = ref(false)
const isDeleteAll = () => {
  // console.log(selectedRows[0].expert_name);
  if (checkedArr.value.length != 0) {
    showDeleteAll.value = true
  } else if (checkedArr.value.length == 0) {
    showDeleteAll.value = false
    nullDelete.value = true
  }
  console.log(checkedArr.value.length)
}
const Closereminder = () => {
  nullDelete.value = false
  nullExport.value = false
}
const deleteAll = async () => {
  console.log(checkedArr.value)
  console.log(checkedArr.value.length)

  if (checkedArr.value.length != 0) {
    const res = await http.delete('/expert/delete-expert', checkedArr.value)
    console.log(res, '批量删除返回')
    delDialog.value = false
    if (res.status == 0) {
      showDeleteAll.value = false
      message({
        message: '批量删除成功',
        showClose: true,
        type: 'success',
      })
    } else {
      message({
        message: '批量删除失败',
        showClose: true,
        type: 'error',
      })
    }
    if (ClickActive.value == 0) {
      reqProjectList()
    } else if (ClickActive.value == 1) {
      const res = await http.post('/expert/find-expert', {
        page: page5.currentPage,
        size: page5.pageSize,
        sort: SORT.value,
        content: demo1.filterName,
        status: '全职',
      })
      console.log('res--->', res)
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    } else if (ClickActive.value == 2) {
      const res = await http.post('/expert/find-expert', {
        page: page5.currentPage,
        size: page5.pageSize,
        sort: SORT.value,
        content: demo1.filterName,
        status: '兼职',
      })
      console.log('res--->', res)
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    }
  }
}
// More -->升序/降序
const AscendingSort = async (index, type) => {
  console.log('我的type: :>> ', type)
  demo1.loading = true

  // console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  // console.log('固定信息:', tableColumns);
  const ColumnId = tableColumns[index].field
  const sorts = {}
  if (type == 'Asc') {
    sorts[ColumnId] = 1
  } else {
    sorts[ColumnId] = -1
  }

  SORT.value = sorts
  console.log(SORT.value, '123456789990')
  if (ClickActive.value == 0) {
    const res = await http.post('/expert/find-expert', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
    })
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount

      console.table('res :>> ', res)
    }
  }
  if (ClickActive.value == 1) {
    const res = await http.post('/expert/find-expert', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      status: '全职',
      content: demo1.filterName,
    })
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount

      console.table('res :>> ', res)
    }
  }
  if (ClickActive.value == 2) {
    const res = await http.post('/expert/find-expert', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
      status: '兼职',
    })
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount

      console.table('res :>> ', res)
    }
  }
}
//  More -->下拉选择中的固定方法
const toggleFixedColumn = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
const toggleFixedColumns = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false
  const $table = xTable.value
  const column = $table.getColumns()
  // console.log('column[index] :>> ', column[index]);
  column[index].visible = false
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value.push(column[index])
  ShowCheck.value.push(false)
  $table.refreshColumn() //刷新列表
  console.log('ShowChcke.value :>> ', ShowCheck.value)
  // console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
}
let information1 = ref({
  _id: '',
  createdAt: '',
  description: '',
  expert_gender: '',
  expert_name: '',
  expert_number: '',
  expert_phone: '',
  files: [],
  isdelete: '',
  parting_projects: [],
  remark: '',
  status: '',
  tags: [],
  updatedAt: '',
  _id: '',
})

let information = ref({
  _id: '',
  createdAt: '',
  description: '',
  expert_gender: '',
  expert_name: '',
  expert_number: '',
  expert_phone: '',
  files: [],
  isdelete: '',
  parting_projects: [],
  remark: '',
  status: '',
  tags: [],
  updatedAt: '',
  _id: '',
})

//专家标签
let labelArr = ref([])
// 专家性别
let sexArr = ref(['男', '女'])
// 专家状态
let statusArr = ref(['全职', '兼职'])
//编辑抽屉显示隐藏
let isShowEdit = ref(false)
const showEdit = () => {
  information1.value = JSON.parse(JSON.stringify(information.value))
  showDetail.value = false
  isShowEdit.value = true
  if (information1.value.expert_name == '——') {
    information1.value.expert_name = ''
  }
  if (information1.value.expert_number == '——') {
    information1.value.expert_number = ''
  }
  if (information1.value.expert_gender == '——') {
    information1.value.expert_gender = ''
  }
  if (information1.value.expert_phone == '——') {
    information1.value.expert_phone = ''
  }
  if (information1.value.status == '——') {
    information1.value.status = ''
  }
  if (information1.value.remark == '——') {
    information1.value.remark = ''
  }
  if (information1.value.tags == '——') {
    information1.value.tags = ''
  }
  if (information1.value.description == '——') {
    information1.value.description = ''
  }
}
const EditClose = () => {
  isShowEdit.value = false
  showDetail.value = true
}
//必选项是否为空提示
let errObj = reactive({
  number: false,
  name: false,
  phone: false,
  status: false,
  sex: false,
})
// 判断专家编号是否填写
let is_inputNumber = () => {
  if (information1.value.expert_number === '') {
    errObj.number = true
  } else {
    errObj.number = false
  }
}
watch(
  () => information1.value.expert_number,
  () => {
    is_inputNumber()
  }
)
// 判断专家姓名
let nameError = ref('')
let is_inputName = () => {
  if (information1.value.expert_name === '') {
    errObj.name = true
    nameError.value = '专家姓名不可为空'
  } else if (information1.value.expert_name.length < 2 || information1.value.expert_name.length > 10) {
    errObj.name = true
    nameError.value = '专家姓名不符合格式'
  } else {
    nameError.value = ''
    errObj.name = false
  }
}

watch(
  () => information1.value.expert_name,
  () => {
    is_inputName()
  }
)
// 判断专家电话
const phone = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
let phoneError = ref('')
let is_inputPhone = () => {
  if (information1.value.expert_phone === '') {
    errObj.phone = true
    phoneError.value = '联系电话不可为空'
  } else if (!phone.test(information1.value.expert_phone)) {
    phoneError.value = '联系电话不符合格式'
    errObj.phone = true
  } else {
    phoneError.value = ''
    errObj.phone = false
  }
}
watch(
  () => information1.value.expert_phone,
  () => {
    is_inputPhone()
  }
)
// 判断专家性别是否勾选
let sex_error = ref('专家性别不可为空')
let is_sexError = ref(false)
let is_inputSex = () => {
  if (information1.value.expert_gender === '') {
    sex_error.value = '专家性别不可为空'
    is_sexError.value = true
  } else {
    sex_error.value = ''
    is_sexError.value = false
  }
}
watch(
  () => information1.value.expert_gender,
  () => {
    is_inputSex()
  }
)
// 判断专家状态是否勾选
let status_error = ref('专家状态不可为空')
let is_statusError = ref(false)
let is_inputStatus = () => {
  if (information1.value.status === '') {
    status_error.value = '专家状态不可为空'
    is_statusError.value = true
  } else {
    status_error.value = ''
    is_statusError.value = false
  }
}
watch(
  () => information1.value.status,
  () => {
    is_inputStatus()
  }
)
// 上传附件返回信息
const attachmentList = ref([])
// 上传附件事件
const uploadsFile = async (params) => {
  const file = params.file
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'attachment')
  const response = await http.post('/upload/file-upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  const { status, file: fileData } = response
  if (status === 0) {
    attachmentList.value.push(fileData)
    console.log(attachmentList.value, '有文件上传了')
    ElMessage({
      message: '附件上传成功',
      // grouping: true,
      showClose: true,
      type: 'success',
    })
  } else {
    ElMessage({
      message: '附件上传失败，请稍后再试',
      // grouping: true,
      showClose: true,
      type: 'warning',
    })
  }
}
const downloadTheFiles = async (data) => {
  console.log('附件-下载-data', data)
  console.log('点击了附件下载')
  const { downloadPath, filePath } = data

  const repsonse = await http.post(
    'https://cems.fuhuan.ltd/api/upload/attachmentDownload',
    { downloadPath: downloadPath ? downloadPath : filePath },
    { responseType: 'arraybuffer' }
  )

  const blob = new Blob([repsonse], {
    type: 'application/x-zip-compressed;charset=utf-8',
  })
  let name = '附件下载.zip'
  const downloadElement = document.createElement('a')
  let classificationhref = window.URL.createObjectURL(blob)
  downloadElement.href = classificationhref
  downloadElement.download = `${name}`
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(classificationhref)
}
// 被删除附件（已保存）
const deleteFiles = ref([])
// 删除附件
const deleteTheFiles = (data) => {
  console.log('data iiiiiidddddd:>> ', data)
  attachmentList.value.forEach((attachment, index) => {
    console.log(attachment)
    if (attachment.uuid == data) {
      if (attachment.downloadPath) {
        console.log('删我')
        deleteFiles.value.push(attachment.uuid)
      }
      attachmentList.value.splice(index, 1)
    }
  })
}
// 编辑专家确定按钮
let updateExpert = async () => {
  console.log(nameError.value)
  let IDS = '' //此次专家的id
  is_inputName()
  is_inputPhone()
  is_inputNumber()
  is_inputStatus()
  is_inputSex()
  const newFiles = []
  attachmentList.value.forEach((attachment) => {
    if (!attachment.downloadPath) {
      newFiles.push(attachment)
    }
  })
  // console.log(errObj);
  // if (information.value.expert_gender !== "") {
  if (errObj.name == false && errObj.number == false && errObj.phone == false && is_sexError.value == false && is_statusError.value == false) {
    await http
      .put('/expert/update-expert', {
        //=====专家id
        _id: information1.value._id,
        //===== 专家基础信息

        //专家姓名
        expert_name: information1.value.expert_name,
        //专家编号
        expert_number: information1.value.expert_number,
        // 专家性别
        expert_gender: information1.value.expert_gender,
        //联系方式
        expert_phone: information1.value.expert_phone,
        // 专家状态
        status: information1.value.status,
        // 备注
        remark: information1.value.remark,
        //  专家标签
        tags: information1.value.tags,
        //专家介绍
        description: information1.value.description,
        //文件
        files: newFiles,
        deletefiles: deleteFiles.value,
      })
      .then(async (res) => {
        console.log(res, '111')
        if (res.status === 0) {
          IDS = res._id
          information.value = JSON.parse(JSON.stringify(information1.value))
          console.log(information.value)
          console.log(information1.value)

          ElMessage({
            showClose: true,
            message: '编辑专家成功',
            type: 'success',
          })
          // application(IDS);
          if (ClickActive.value == 0) {
            reqProjectList()
          } else if (ClickActive.value == 1) {
            const res = await http.post('/expert/find-expert', {
              page: page5.currentPage,
              size: page5.pageSize,
              sort: SORT.value,
              content: demo1.filterName,
              status: '全职',
            })
            console.log('res--->', res)
            demo1.tableData = res.result
            page5.totalResult = res.totalCount
          } else if (ClickActive.value == 2) {
            const res = await http.post('/expert/find-expert', {
              page: page5.currentPage,
              size: page5.pageSize,
              sort: SORT.value,
              content: demo1.filterName,
              status: '兼职',
            })
            console.log('res--->', res)
            demo1.tableData = res.result
            page5.totalResult = res.totalCount
          }

          EditClose()
          showDetail.value = true
        } else if (res.status === 1) {
          ElMessage({
            showClose: true,
            message: '专家编号重复',
            type: 'warning',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
//查看详情抽屉显示隐藏
let showDetail = ref(false)
const clickDetail = ref(false)
// 每一行的查看详情
let id = ref(null)
const showDetails = async (row) => {
  // 获取样品文件
  const res = await http.post('/expert/findInfo-expert', {
    expertId: row._id,
  })
  if (res.status == 0) {
    attachmentList.value = res.expert.files
    table_Data = []
    table_Data = res.expert.parting_projects
  }
  console.log(attachmentList.value)
  clickDetail.value = true
  showDetail.value = true
  console.log('row :>> ', row)
  console.log('查看详情信息:', row._id)
  information1.value = JSON.parse(JSON.stringify(row))
  information.value = JSON.parse(JSON.stringify(row))
  //demo1.tableData.forEach((item,index)=>{
  //if(item._id == row._id){
  // information1.value  = item
  // information.value  = item
  // }
  // })
  //id.value = row._id
  //  console.log(row)
}
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  console.log(checked ? '所有勾选事件' : '所有取消事件', records)
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id)
        console.log('所有勾选', checkedArr.value)
      }
    }
    console.log('最新:', checkedArr.value)
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = []

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id)
    })

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item)
    })
    console.log('最新:', checkedArr.value)
  }
}

//复选框数组
var selectedRows = reactive([])
const checkedArr = ref([])

// 复选框点击事件
const selectChangeEvent1 = (row) => {
  // console.log('点击的id:', row.rowid);
  console.log('row :>> ', row)

  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records

  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid)
  } else {
    let index = checkedArr.value.indexOf(row.rowid)
    checkedArr.value.splice(index, 1)
  }
}

// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params
  //   //console.log(columns); // 打印排序后的表头列数组对象
}
// 点击表头的下拉箭头
const OperationBox = ref(null) //获取更多操作盒子的ref
const showIdOptions = ref(false) //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1)
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  //   //console.log('value :>> ', value);
  const table = xTable.value
  const FixedList = table.getColumns()

  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index
    }
  })
  //console.log('FixedIndex下标为 :>> ', FixedIndex);

  showIdOptions.value = false
  showIdOptions.value = true
  // 设置位置
  const Event = event
  OperationBox.value.style.left = event.clientX - 100 + 'px'
}
//搜索
// 模糊查询
const searchEvent2 = async () => {
  if (demo1.filterName == '') {
    if (ClickActive.value == 0) {
      reqProjectList()
    } else if (ClickActive.value == 1) {
      const res = await http.post('/expert/find-expert', {
        page: page5.currentPage,
        size: page5.pageSize,
        sort: SORT.value,
        content: demo1.filterName,
        status: '全职',
      })
      console.log('res--->', res)
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    } else if (ClickActive.value == 2) {
      const res = await http.post('/expert/find-expert', {
        page: page5.currentPage,
        size: page5.pageSize,
        sort: SORT.value,
        content: demo1.filterName,
        status: '兼职',
      })
      console.log('res--->', res)
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    }
  }
  demo1.loading = true
  if (ClickActive.value == 0) {
    const res = await http.post('/expert/find-expert', {
      page: (page5.currentPage = 1),
      size: page5.pageSize,
      sort: { createdAt: -1 },
      content: demo1.filterName,
    })
    console.log(res, '1233456')
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    } else {
      console.log('查询失败')
    }
  }
  if (ClickActive.value == 1) {
    const res = await http.post('/expert/find-expert', {
      page: (page5.currentPage = 1),
      size: page5.pageSize,
      sort: { createdAt: -1 },
      status: '全职',
      content: demo1.filterName,
    })
    console.log(res, '1233456')
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    } else {
      console.log('查询失败')
    }
  }
  if (ClickActive.value == 2) {
    const res = await http.post('/expert/find-expert', {
      page: (page5.currentPage = 1),
      size: page5.pageSize,
      sort: { createdAt: -1 },
      status: '兼职',
      content: demo1.filterName,
    })
    console.log(res, '1233456')
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    } else {
      console.log('查询失败')
    }
  }
}

let null_reminder_delect = ref(false) // ! 没有勾选志愿者数据删除

// 删除专家弹窗显示隐藏
const delDialog = ref(false)
// 点击删除
const delchange = (row) => {
  console.log(row)
  OptionsData.value = row
  delDialog.value = true
}
// 点击确认删除专家
const delUserInfoChange = async () => {
  let arr = []
  arr[0] = OptionsData.value._id
  console.log(arr)
  const res = await http.delete('/expert/delete-expert', arr)
  console.log(res, '删除专家返回')
  delDialog.value = false
  if (res.status == 0) {
    message({
      message: '删除专家成功',
      showClose: true,
      type: 'success',
    })
    let iindex = checkedArr.value.indexOf(OptionsData.value._id)
    if (iindex != -1) {
      checkedArr.value.splice(iindex, 1)
    }
    // checkedArr.value.splice();
  } else {
    message({
      message: '删除专家失败',
      showClose: true,
      type: 'error',
    })
  }
  if (ClickActive.value == 0) {
    reqProjectList()
  } else if (ClickActive.value == 1) {
    const res = await http.post('/expert/find-expert', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
      status: '全职',
    })
    console.log('res--->', res)
    demo1.tableData = res.result
    page5.totalResult = res.totalCount
  } else if (ClickActive.value == 2) {
    const res = await http.post('/expert/find-expert', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
      status: '兼职',
    })
    console.log('res--->', res)
    demo1.tableData = res.result
    page5.totalResult = res.totalCount
  }
}
//->全部数据
const reqProjectList = async () => {
  const res = await http.post('/expert/find-expert', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
  })
  console.log('1111', res)
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.result
    page5.totalResult = res.totalCount

    console.table('res :>> ', res)
  }
  ChangeStatus.value = false
}
// 请求标签 数据
const ReqDefault = async () => {
  const res = await http.post('/setting/fetch-setting', {
    type: 'expert',
  })

  console.log('res请求初始数据为 :>> ', res)
  if (res.status == 0) {
    console.log('res请求初始数据为 :>> ', res)

    res.tags.forEach((item, index) => {
      if (item.value != false) {
        labelArr.value.push(item.name)
      }
    })

    if (labelArr.value.length == 0) {
      labelArr.value.push('暂无标签，请前往【自定义设置】新建')
    }
    console.log('获取的最终数据', labelArr.value)
  }
}
onMounted(() => {
  reqProjectList()
  ReqDefault()
  // 初始话存储数据下
  //console.log('demo1.tableData :>> ', demo1.tableData);
  //处理点击更多操作盒子 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false
      }
    }
  })
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showOperation.value == true) {
      if (e.target.id != 'operationBox') {
        showOperation.value = false
      }
    }
  })
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (isShow.value == true) {
      if (e.target.id != 'show_box') {
        isShow.value = false
      }
    }
    // 操作...
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false
      }
      // console.log(ClickMoreOptions.value);
    }

    // 批量操作
    if (BatchBox.value == true) {
      if (e.target.id != 'operationBox') {
        BatchBox.value = false
      }
      // console.log(ClickMoreOptions.value);
    }
  })
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false
      }
    }
  })
})
</script>

<style lang="less" scoped>
@import url('@/style/views/ExpertManagement/ExpertList.less');
</style>
