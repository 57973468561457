
const state={
    Report_data:"",  //报告数据
}

const mutations={
    Set_Report_data(state,newValue){
        state.Report_data=newValue
    },
}
export default{
    state,
    mutations
}