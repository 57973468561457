<template>
  <div id="vplusInstrument">
    <!-- 表格主体 -->
    <div class="VolunteerBox">
      <!-- 表内导航栏(筛选条件) -->
      <div class="project_main_state">
        <!-- 筛选status状态按钮 -->
        <div
          class="main_state_item"
          :class="index == ClickActive ? 'click-active' : ''"
          v-for="(item, index) in ProjectState"
          :key="index"
          @click="ClickActive = index">
          {{ item }}
        </div>
      </div>
      <!-- !  子目录结构-->
      <div class="VolunteerTable_main">
        <!-- 表体 -->
        <div class="VolunteerTable">
          <div class="vxetable">
            <vxe-toolbar>
              <template #buttons>
                <div class="topList">
                  <!-- <vxe-input
                    class="Search"
                    v-model="demo1.filterName"
                    type="search"
                    placeholder="请输入项目编号或志愿者编号进行搜索"
                    @Blur="searchEvent2">
                  </vxe-input> -->
                  <vxe-button @click="reset" class="Reset"><span class="reset-icon">重置</span></vxe-button>
                  <div class="option">
                    <el-select
                      filterable
                      v-model="currentDimension"
                      class="custom-select"
                      :max-collapse-tags="2"
                      multiple
                      collapse-tags
                      collapse-tags-tooltip
                      default-first-option
                      placeholder-style="{ color: 'red', fontStyle: 'italic' }"
                      placeholder="所有维度">
                      <el-option
                        v-for="(render, index) in allRenderList"
                        :key="index"
                        :label="render.label"
                        :value="render.key"
                        :disabled="render.label === '纹理'" />
                    </el-select>
                  </div>
                  <vxe-button class="Show" :class="SetShow ? 'ShowHiden' : ''">
                    <span @click.stop="SetShowFunction" class="show-icon">显示</span>
                  </vxe-button>
                  <!-- ！三个分类 -->
                  <div class="type_list">
                    <el-radio-group v-model="currentShow" class="littleOptions" v-show="!AOISHOW">
                      <el-radio label="numberKey" size="large" class="left_item">
                        <template v-slot:default>
                          <div style="color: #939599">特征计数（个）</div>
                        </template>
                      </el-radio>
                      <el-radio label="scoreKey" size="large" class="item">
                        <template v-slot:default>
                          <div style="color: #939599">分值（分）</div>
                        </template>
                      </el-radio>
                      <el-radio label="rankKey" size="large" class="item">
                        <template v-slot:default>
                          <div style="color: #939599">百分位数</div>
                        </template>
                      </el-radio>
                    </el-radio-group>
                  </div>
                  <div class="btn_list" :class="{ btn_list1: AOISHOW }">
                    <!-- <img class="uploading" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B68319%402x.png" @click="uploadData" alt="" /> -->
                    <img class="export" @click="isShowExport" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B682270%402x.png" alt="" />
                    <!-- <img class="export" @click="isshowExport" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B682270%402x.png" alt="" /> -->
                  </div>
                  <!-- </div> -->
                </div>
              </template>
            </vxe-toolbar>
            <div v-show="tableData.length > 0">
              <!-- 导入 -->
              <el-dialog class="volunteer_dialog" v-model="dialogShow1" title="Shipping address" :align-center="true" :close-on-click-modal="false">
                <div class="dialog_title">
                  <span>上传数据</span>
                  <div class="title_cancel" @click="dialogShow1 = false"></div>
                </div>
                <div class="dialog_main">
                  <div class="main_download">
                    <div class="download_title">1.下载模板，填写数据信息</div>
                    <div class="download_img"></div>
                    <div class="upload_name"></div>
                    <div class="upload_err"></div>
                    <div class="download_but" @click="downloadXlsx">
                      <button>下载模板</button>
                    </div>
                  </div>
                  <div class="main_upload">
                    <div class="upload_title">2.上传填写好的文件</div>
                    <div class="upload_img"></div>
                    <div class="upload_name">
                      <span v-if="uploadFileName">{{ uploadFileName }}</span>
                    </div>
                    <div class="upload_tip">
                      <span v-if="uploadFileErr">文件格式错误，请重新上传</span>
                      <span class="tip_up" v-if="uploadFileName && !uploadFileErr">重新选择</span>
                    </div>
                    <div class="upload_but">
                      <el-upload
                        class="upload_xlsx"
                        ref="videoRef"
                        accept=".xlsx,.xls"
                        :http-request="uploadFile"
                        :on-preview="handlePreviews"
                        :on-remove="handleRemoves"
                        :file-list="fileLists"
                        :show-file-list="false">
                        <button>上传文件</button>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </el-dialog>
              <!-- 导入失败 -->
              <el-dialog
                class="volunteer_dialogs"
                :align-center="true"
                v-model="errdialogShow"
                title="Shipping address"
                :close-on-click-modal="false">
                <div class="dialog_title">
                  <span>失败</span>
                  <div class="title_cancel" @click="errdialogShow = false"></div>
                </div>
                <div class="dialog_main">
                  <img class="main_err" src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402xs.png" alt="" />
                  <div class="main_fail">导入失败</div>
                  <div class="main_errT1">
                    表格中存在<span class="tip">{{ errFileNum }}条</span>有误的数据信息
                  </div>
                  <div class="main_errT2">请下载报错文件，修改后重新上传数据。</div>
                  <div class="main_but" @click="downloadErrFile">
                    <button>下载文件</button>
                  </div>
                </div>
              </el-dialog>
              <div class="ContentBox">
                <div class="MenuList" v-show="ClickActive == 0" :class="{ clicked: !isCollapse }">
                  <div class="Title">
                    <span v-show="!isCollapse" class="title">子目录</span>
                    <img
                      class="open"
                      v-show="!isCollapse"
                      @click="isCollapse = !isCollapse"
                      src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B61615%402x.png"
                      alt="" />
                    <img
                      v-show="isCollapse"
                      class="close"
                      @click="isCollapse = !isCollapse"
                      src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%20161%20%E2%80%93%208%402x.png"
                      alt="" />
                  </div>

                  <el-menu
                    default-active="1"
                    :index="activeIndex"
                    @select="handleSelect"
                    class="el-menu-vertical-demo Line"
                    :collapse="isCollapse"
                    @open="handleOpen"
                    @close="handleClose"
                    :collapse-transition="false">
                    <el-menu-item index="1" class="menu_item">
                      <template #title>Center</template>
                    </el-menu-item>
                    <el-menu-item index="2" class="menu_item">
                      <template #title>Left</template>
                    </el-menu-item>
                    <el-menu-item index="3" class="menu_item">
                      <template #title>Right</template>
                    </el-menu-item>
                  </el-menu>
                </div>
                <vxe-table
                  :class="{ move_left: !isCollapse }"
                  v-if="!AOISHOW"
                  border
                  stripe
                  ref="xTable"
                  show-overflow
                  show-header-overflow
                  height="678"
                  row-id="_id"
                  :scroll-y="{ mode: 'default' }"
                  :data="tableData"
                  :column-config="{ resizable: true }"
                  :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
                  :checkbox-config="{ reserve: true }"
                  :loading="demo1.loading"
                  @header-cell-dragend="handleHeaderDragend"
                  @checkbox-all="selectAllChangeEvent1"
                  @checkbox-change="selectChangeEvent1">
                  <vxe-column type="checkbox" width="56" fixed="left"> </vxe-column>
                  <vxe-column field="analysis_time" title="测试时间" :visible="true" width="144" :edit-render="{ placeholder: '——' }">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '测试时间'">
                        <span class="name">测试时间</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('测试时间', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="project_number" title="项目编号" :visible="true" width="144" :edit-render="{ placeholder: '——' }">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '项目编号'">
                        <span class="name">项目编号</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('项目编号', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="user_name" title="用户姓名" :visible="true" width="110" :edit-render="{ placeholder: '——' }">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '用户姓名'">
                        <span class="name">用户姓名</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('用户姓名', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="user_age" title="年龄" :visible="true" width="74" :edit-render="{ placeholder: '——' }">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '年龄'">
                        <span class="name">年龄</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('年龄', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="skin_age" title="肤龄" :visible="true" width="74" :edit-render="{ placeholder: '——' }">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '肤龄'">
                        <span class="name">肤龄</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('肤龄', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="pore" title="毛孔" :visible="true" width="64">
                    <template #header>
                      <span class="name">毛孔</span>
                      <el-icon v-show="showCaret && !showMoreObj.pore" @click="MyshowColumn('pore')"><CaretRight /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.pore" @click="MyhideColumn('pore')"><CaretLeft /></el-icon>
                    </template>
                    <template #default="{ row }">
                      <span @click="openDrawer('pore', row)">
                        <span class="centerBoxOfTest"> {{ row.pore[allRenderList[0][currentShow]] || '——' }}</span>
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="pore_num" title="毛孔个数" :visible="false" width="88">
                    <template #header>
                      <span style="text-align: center" class="name">毛孔个数</span>
                      <span style="text-align: center; display: inline-block; width: 100%" class="unit">（个）</span>
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        {{ row.pore.pore_num || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="pore_mean_area" title="毛孔总面积" :visible="false" width="102">
                    <template #header>
                      <span style="text-align: center" class="name">毛孔总面积</span>
                      <span style="text-align: center; display: inline-block; width: 100%" class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        {{ row.pore.pore_mean_area || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="pore_all_area" title="毛孔平均面积" :visible="false" width="116">
                    <template #header>
                      <span style="text-align: center" class="name">毛孔平均面积</span>
                      <span style="text-align: center; display: inline-block; width: 100%" class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        {{ row.pore.pore_all_area || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="pore_area_rate" title="毛孔密度" :visible="false" width="88">
                    <template #header>
                      <span style="text-align: center" class="name">毛孔密度</span>
                      <span style="text-align: center; display: inline-block; width: 100%" class="unit">（%）</span>
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        {{ row.pore.pore_area_rate || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="wrinkle" title="皱纹" :visible="true" width="64">
                    <template #header>
                      <span style="text-align: center; width: 100%" class="name">皱纹</span>
                      <el-icon v-show="showCaret && !showMoreObj.wrinkle" @click="MyshowColumn('wrinkle')"><CaretRight /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.wrinkle" @click="MyhideColumn('wrinkle')"><CaretLeft /></el-icon>
                    </template>
                    <template #default="{ row }">
                      <span @click="openDrawer('wrinkle', row)">
                        <span style="text-align: center; width: 100%; display: inline-block">
                          {{ row.wrinkle[allRenderList[1][currentShow]] || '——' }}</span
                        >
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="wrinkle_num" title="皱纹数量" :visible="false" width="88">
                    <template #header>
                      <span class="name">皱纹数量</span>
                      <span style="text-align: center; width: 100%" class="unit">（条）</span>
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        {{ row.wrinkle.wrinkle_num || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="wrinkle_area" title="皱纹总面积" :visible="false" width="102">
                    <template #header>
                      <span class="name">皱纹总面积</span>
                      <span style="text-align: center; width: 100%" class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        {{ row.wrinkle.wrinkle_area || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="wrinkle_area_mean" title="皱纹平均面积" :visible="false" width="116">
                    <template #header>
                      <span class="name">皱纹平均面积</span>
                      <span style="text-align: center; width: 100%" class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        {{ row.wrinkle.wrinkle_area_mean || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="wrinkle_length" title="皱纹总长度" :visible="false" width="102">
                    <template #header>
                      <span class="name">皱纹总长度</span>
                      <span style="text-align: center; width: 100%" class="unit">（mm）</span>
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        {{ row.wrinkle.wrinkle_length || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="wrinkle_length_mean" title="皱纹平均长度" :visible="false" width="116">
                    <template #header>
                      <span class="name">皱纹平均长度</span>
                      <span style="text-align: center; width: 100%" class="unit">（mm）</span>
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        {{ row.wrinkle.wrinkle_length_mean || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="dark_circle" title="黑眼圈" :visible="true" width="80">
                    <template #header>
                      <span class="name">黑眼圈</span>
                      <el-icon v-show="showCaret && !showMoreObj.dark_circle" @click="MyshowColumn('dark_circle')"><CaretRight /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.dark_circle" @click="MyhideColumn('dark_circle')"><CaretLeft /></el-icon>
                    </template>
                    <template #default="{ row }">
                      <span @click="openDrawer('dark_circle', row)">
                        <span class="centerBoxOfTest"> {{ row.dark_circle[allRenderList[2][currentShow]] || '——' }}</span>
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="dark_circle_rate" title="黑眼圈密度" :visible="false" width="102">
                    <template #header>
                      <span class="name">黑眼圈密度</span>
                      <span class="unit">（%）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.dark_circle.dark_circle_rate || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="dark_circle_gray_mean" title="灰度值均值" :visible="false" width="102">
                    <template #header>
                      <span class="name">灰度值均值</span>
                      <!-- <span class="unit">（mm）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.dark_circle.dark_circle_gray_mean || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="eyelash" title="睫毛" :visible="true" width="64">
                    <template #header>
                      <span class="name">睫毛</span>
                      <el-icon v-show="showCaret && !showMoreObj.eyelash" @click="MyshowColumn('eyelash')"><CaretRight /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.eyelash" @click="MyhideColumn('eyelash')"><CaretLeft /></el-icon>
                    </template>
                    <template #default="{ row }"
                      ><span @click="openDrawer('eyelash', row)">
                        <span class="centerBoxOfTest">
                          {{ row.eyelash[allRenderList[3][currentShow]] || '——' }}
                        </span>
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="eyelash_num" title="睫毛根数" :visible="false" width="88">
                    <template #header>
                      <span class="name">睫毛根数</span>
                      <span class="unit">（根）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.eyelash.eyelash_num || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="eyelash_length_mean" title="睫毛平均长度" :visible="false" width="116">
                    <template #header>
                      <span class="name">睫毛平均长度</span>
                      <span class="unit">（mm）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.eyelash.eyelash_length_mean || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin" title="紫外荧光" :visible="true" width="96">
                    <template #header>
                      <span class="name">紫外荧光</span>
                      <el-icon v-show="showCaret && !showMoreObj.porphyrin" @click="MyshowColumn('porphyrin')"><CaretRight /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.porphyrin" @click="MyhideColumn('porphyrin')"><CaretLeft /></el-icon>
                    </template>
                    <template #default="{ row }"
                      ><span @click="openDrawer('porphyrin', row)">
                        <span class="centerBoxOfTest">
                          {{ row.porphyrin[allRenderList[4][currentShow]] || '——' }}
                        </span>
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin_num" title="紫外荧光点数量" :visible="false" width="130">
                    <template #header>
                      <span class="name">紫外荧光点数量</span>
                      <span class="unit">（个）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.porphyrin.porphyrin_num || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin_area" title="紫外荧光点总面积" :visible="false" width="140">
                    <template #header>
                      <span class="name">紫外荧光点总面积</span>
                      <span class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.porphyrin.porphyrin_area || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin_density" title="紫外荧光点密度" :visible="false" width="130">
                    <template #header>
                      <div class="name">紫外荧光点密度</div>
                      <span class="unit">（%）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.porphyrin.porphyrin_density || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin_num_red" title="红色荧光点数量" :visible="false" width="130">
                    <template #header>
                      <span class="name">红色荧光点数量</span>
                      <span class="unit">（个）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.porphyrin.porphyrin_num_red || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin_area_red" title="红色荧光点总面积" :visible="false" width="140">
                    <template #header>
                      <span class="name">红色荧光点总面积</span>
                      <span class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.porphyrin.porphyrin_area_red || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin_density_red" title="红色荧光点密度" :visible="false" width="130">
                    <template #header>
                      <span class="name">红色荧光点密度</span>
                      <span class="unit">（%）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.porphyrin.porphyrin_density_red || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin_num_blue" title="蓝色荧光点数量" :visible="false" width="130">
                    <template #header>
                      <span class="name">蓝色荧光点数量</span>
                      <span class="unit">（个）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.porphyrin.porphyrin_num_blue || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin_area_blue" title="蓝色荧光点总面积" :visible="false" width="144">
                    <template #header>
                      <span class="name">蓝色荧光点总面积</span>
                      <span class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.porphyrin.porphyrin_area_blue || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="porphyrin_density_blue" title="蓝色荧光点密度" :visible="false" width="130">
                    <template #header>
                      <span class="name">蓝色荧光点密度</span>
                      <span class="unit">（%）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.porphyrin.porphyrin_density_blue || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="texture" title="纹理" :visible="true" width="64">
                    <template #header>
                      <span style="margin-left: 6px" class="name">纹理</span>
                      <!-- <el-icon v-show="showCaret && !showMoreObj.texture"><CaretRight /></el-icon>
                    <el-icon v-show="showCaret && showMoreObj.texture"><CaretLeft /></el-icon> -->
                    </template>
                    <template #default="{ row }">
                      <div style="text-align: center">
                        <span class="centerBoxOfTest">
                          {{ row.texture[allRenderList[5][currentShow]] || '——' }}
                        </span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="acne" title="痘痘与痘印" :visible="true" width="112">
                    <template #header>
                      <span class="name">痘痘与痘印</span>
                      <el-icon v-show="showCaret && !showMoreObj.acne" @click="MyshowColumn('acne')"><CaretRight /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.acne" @click="MyhideColumn('acne')"><CaretLeft /></el-icon>
                    </template>
                    <template #default="{ row }"
                      ><span @click="openDrawer('acne', row)">
                        <span class="centerBoxOfTest"> {{ row.acne[allRenderList[6][currentShow]] || '——' }}</span>
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="acne_num" title="痘痘数量" :visible="false" width="88">
                    <template #header>
                      <span class="name">痘痘数量</span>
                      <span class="unit">（个）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.acne.acne_num || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="acne_scar_num" title="痘印数量" :visible="false" width="88">
                    <template #header>
                      <span class="name">痘印数量</span>
                      <span class="unit">（个）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.acne.acne_scar_num || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="acne_diameter_mean" title="痘痘平均直径" :visible="false" width="116">
                    <template #header>
                      <span class="name">痘痘平均直径</span>
                      <span class="unit">（mm）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.acne.acne_diameter_mean || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="brown_spot" title="黑素与色斑" :visible="true" width="112">
                    <template #header>
                      <span class="name">黑素与色斑</span>
                      <el-icon v-show="showCaret && !showMoreObj.brown_spot" @click="MyshowColumn('brown_spot')"><CaretRight /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.brown_spot" @click="MyhideColumn('brown_spot')"><CaretLeft /></el-icon>
                    </template>
                    <template #default="{ row }"
                      ><span @click="openDrawer('brown_spot', row)">
                        <span class="centerBoxOfTest">
                          {{ row.brown_spot[allRenderList[7][currentShow]] || '——' }}
                        </span>
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="brown_num" title="色斑数量" :visible="false" width="88">
                    <template #header>
                      <span class="name">色斑数量</span>
                      <span class="unit">（个）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.brown_spot.brown_num || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="brown_area_all" title="色斑总面积" :visible="false" width="102">
                    <template #header>
                      <span class="name">色斑总面积</span>
                      <span class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.brown_spot.brown_area_all || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="brown_area_mean" title="色斑平均面积" :visible="false" width="116">
                    <template #header>
                      <span class="name">色斑平均面积</span>
                      <span class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.brown_spot.brown_area_mean || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="brown_area_rate" title="色斑密度" :visible="false" width="88">
                    <template #header>
                      <span class="name">色斑密度</span>
                      <span class="unit">（%）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.brown_spot.brown_area_rate || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="brownLAB" title="色斑L*a*b均值" :visible="false" width="163">
                    <template #header>
                      <span class="name">色斑L*a*b均值</span>
                      <!-- <span class="unit">（%）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        L:{{ row.brown_spot.L || '——' }} a:{{ row.brown_spot.A || '——' }} b:{{ row.brown_spot.B || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="red_spot" title="血红素" :visible="true" width="80">
                    <template #header>
                      <span class="name">血红素</span>
                      <el-icon v-show="showCaret && !showMoreObj.red_spot" @click="MyshowColumn('red_spot')"><CaretRight /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.red_spot" @click="MyhideColumn('red_spot')"><CaretLeft /></el-icon>
                    </template>
                    <template #default="{ row }"
                      ><span @click="openDrawer('red_spot', row)">
                        <span class="centerBoxOfTest"> {{ row.red_spot[allRenderList[8][currentShow]] || '——' }}</span>
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="red_num" title="血红素数量" :visible="false" width="102">
                    <template #header>
                      <span class="name">血红素数量</span>
                      <span class="unit">（个）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.red_spot.red_num || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="red_area_all" title="血红素总面积" :visible="false" width="116">
                    <template #header>
                      <span class="name">血红素总面积</span>
                      <span class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.red_spot.red_area_all || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="red_area_mean" title="血红素平均面积" :visible="false" width="130">
                    <template #header>
                      <span class="name">血红素平均面积</span>
                      <span class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.red_spot.red_area_mean || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="red_area_rate" title="血红素密度" :visible="false" width="102">
                    <template #header>
                      <span class="name">血红素密度</span>
                      <span class="unit">（%）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.red_spot.red_area_rate || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="redLAB" title="血红素L*a*b均值" :visible="false" width="177">
                    <template #header>
                      <span class="name">血红素L*a*b均值</span>
                      <!-- <span class="unit">（%）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">L:{{ row.red_spot.L || '——' }} a:{{ row.red_spot.A || '——' }} b:{{ row.red_spot.B || '——' }}</div>
                    </template>
                  </vxe-column>
                  <vxe-column field="uv_spot" title="紫外发色团" :visible="true" width="112">
                    <template #header>
                      <span class="name">紫外发色团</span>
                      <el-icon v-show="showCaret && !showMoreObj.uv_spot" @click="MyshowColumn('uv_spot')"><CaretRight /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.uv_spot" @click="MyhideColumn('uv_spot')"><CaretLeft /></el-icon>
                    </template>
                    <template #default="{ row }"
                      ><span @click="openDrawer('uv_spot', row)">
                        <span class="centerBoxOfTest"> {{ row.uv_spot[allRenderList[9][currentShow]] || '——' }}</span>
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="uv_num" title="发色团数量" :visible="false" width="102">
                    <template #header>
                      <span class="name">发色团数量</span>
                      <span class="unit">（个）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.uv_spot.uv_num || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="area_all" title="发色团总面积" :visible="false" width="116">
                    <template #header>
                      <span class="name">发色团总面积</span>
                      <span class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.uv_spot.area_all || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="area_mean" title="发色团平均面积" :visible="false" width="130">
                    <template #header>
                      <span class="name">发色团平均面积</span>
                      <span class="unit">（mm²）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.uv_spot.area_mean || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="areauv_rate" title="发色团密度" :visible="false" width="102">
                    <template #header>
                      <span class="name">发色团密度</span>
                      <span class="unit">（%）</span>
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.uv_spot.areauv_rate || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="uv_gray_mean" title="发色团灰度值均值" :visible="false" width="144">
                    <template #header>
                      <span class="name">发色团灰度值均值</span>
                      <!-- <span class="unit">（%）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.uv_spot.uv_gray_mean || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="face_light_analysis" title="肤色及均匀度" width="128" :visible="true">
                    <template #header>
                      <span class="name">肤色及均匀度</span>
                      <el-icon v-show="showCaret && !showMoreObj.face_light_analysis" @click="MyshowColumn('face_light_analysis')"
                        ><CaretRight
                      /></el-icon>
                      <el-icon v-show="showCaret && showMoreObj.face_light_analysis" @click="MyhideColumn('face_light_analysis')"
                        ><CaretLeft
                      /></el-icon>
                    </template>
                    <template #default="{ row }"
                      ><span @click="openDrawer('face_light_analysis', row)">
                        <span class="centerBoxOfTest"> {{ row.face_light_analysis[allRenderList[10][currentShow]] || '——' }}</span>
                      </span>
                    </template>
                  </vxe-column>
                  <vxe-column field="var" title="皮肤均匀度" :visible="false" width="102">
                    <template #header>
                      <span class="name">皮肤均匀度</span>
                      <!-- <span class="unit">（个）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.face_light_analysis.var || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="light" title="光泽度" :visible="false" width="116">
                    <template #header>
                      <span class="name">光泽度</span>
                      <!-- <span class="unit">（mm²）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.face_light_analysis.light || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="ITA" title="ITA角" :visible="false" width="130">
                    <template #header>
                      <span class="name">ITA角</span>
                      <!-- <span class="unit">（mm²）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.face_light_analysis.ITA || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="Hue_std" title="Hue-std值" :visible="false" width="102">
                    <template #header>
                      <span class="name">Hue-std值</span>
                      <!-- <span class="unit">（%）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.face_light_analysis.Hue_std || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="linear_light_degree" title="线性光强度" :visible="false" width="144">
                    <template #header>
                      <span class="name">线性光强度</span>
                      <!-- <span class="unit">（%）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        {{ row.face_light_analysis.linear_light_degree || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="LAB" title="L*a*b均值" :visible="false" width="150">
                    <template #header>
                      <span class="name">L*a*b均值</span>
                      <!-- <span class="unit">（%）</span> -->
                    </template>
                    <template #default="{ row }">
                      <div class="centerBoxOfTest">
                        L:{{ row.face_light_analysis.L || '——' }} a:{{ row.face_light_analysis.A || '——' }} b:{{ row.face_light_analysis.B || '——' }}
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column title="操作" align="left" width="110" fixed="right" :visible="true" min-width="0" :resizable="false">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Operation-header" v-if="column.title === '操作'">
                        <span class="name">操作</span>
                        <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                      </div>
                    </template>
                    <template #default>
                      <vxe-button class="detail">标记无效</vxe-button>
                    </template>
                  </vxe-column>

                  <!-- <template #empty>
                  <span style="color: red">
                    <p>没有更多数据了！</p>
                  </span>
                </template> -->
                </vxe-table>

                <!-- 导出一条没有的时候 -->
                <div class="Derived_volunteer_null" v-if="null_reminder_export">
                  <!-- 导出 -->
                  <div class="Derived_volunteer_box">
                    <div class="Derived_title">
                      <div class="Derived_name">提示</div>
                      <div>
                        <img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
                      </div>
                    </div>
                    <div class="Derived_body">
                      <div>
                        <img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" />
                      </div>
                      <div class="Derived_body_text">请选择需要导出的仪器测试记录信息！</div>
                    </div>
                  </div>
                </div>
                <!-- 批量样品信息  导出 -->
                <div class="Derived_volunteer" v-if="isExport">
                  <div class="Derived_volunteer_box" v-if="isExport">
                    <!-- 导出一个 -->
                    <div v-if="checkedArr.length == 1">
                      <div class="Derived_title">
                        确定导出<span class="Derived_name">{{ selectedRows[0].user_name == ' ' ? '无名字' : selectedRows[0].user_name }}</span
                        >的仪器测试记录信息吗？
                      </div>
                      <div class="Derived_body">
                        <img src="https://new.vynior.com/111.png" alt="" />
                        <div class="body_text">导出后可在excel表格中进行查看</div>
                      </div>
                      <div class="Derived_bottom">
                        <div class="off" @click="isExport = false">取消</div>
                        <div class="ok" @click="exportToExcel">确认</div>
                      </div>
                    </div>
                    <!-- 导出多个 -->
                    <div v-if="checkedArr.length > 1">
                      <div class="Derived_title">
                        已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>仪器测试记录信息，确认导出吗？
                      </div>
                      <div class="Derived_body">
                        <img src="https://new.vynior.com/111.png" alt="" />
                        <div class="body_text">导出后可在excel表格中进行查看</div>
                      </div>
                      <div class="Derived_bottom">
                        <div class="off" @click="isExport = false">取消</div>
                        <div class="ok" @click="exportToExcel">确认</div>
                      </div>
                    </div>
                  </div>
                </div>
                <vxe-table
                  :class="{ aoiTable: AOISHOW }"
                  v-if="AOISHOW"
                  border
                  stripe
                  ref="xTable"
                  height="678"
                  :scroll-y="{ mode: 'default' }"
                  show-overflow
                  show-header-overflow="true"
                  row-id="_id"
                  :data="tableData"
                  :column-config="{ resizable: true }"
                  :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
                  :checkbox-config="{ reserve: true }"
                  :loading="demo1.loading"
                  @header-cell-dragend="handleHeaderDragend"
                  @checkbox-all="selectAllChangeEvent1"
                  @checkbox-change="selectChangeEvent1">
                  <vxe-column field="checked" type="checkbox" width="50" :fixed="demo1.colFixeds.col0"></vxe-column>
                  <vxe-column field="instr_number" type="html" title="测试时间" width="146" :fixed="demo1.colFixeds.col1" min-width="120">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="ID-header" v-if="column.title === '测试时间'">
                        <span class="name">测试时间</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('测试时间', $event)"></i></span>
                      </div> </template
                  ></vxe-column>
                  <vxe-column field="instr_name" type="html" title="项目编号" width="112" :fixed="demo1.colFixeds.col2">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="name-header" v-if="column.title === '项目编号'">
                        <span class="name">项目编号</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('项目编号', $event)"></i></span>
                      </div> </template
                  ></vxe-column>
                  <vxe-column field="instr_status" type="html" title="用户姓名" width="112" :fixed="demo1.colFixeds.col4">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Sex-header" v-if="column.title === '用户姓名'">
                        <span class="name">用户姓名</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('用户姓名', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="instr_abbre" type="html" title="年龄" width="72" :fixed="demo1.colFixeds.col5">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '年龄'">
                        <span class="name">年龄</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('年龄', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="measure_principle" type="html" title="肤龄" width="72" :fixed="demo1.colFixeds.col3">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Role-header" v-if="column.title === '肤龄'">
                        <span class="name">肤龄</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('肤龄', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="measure_range" type="html" title="AOI区域名称" width="132" :fixed="demo1.colFixeds.col3">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Role-header" v-if="column.title === 'AOI区域名称'">
                        <span class="name">AOI区域名称</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('AOI区域名称', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="parameter_interpretation" type="html" title="分析指标" width="112" :fixed="demo1.colFixeds.col6">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '分析指标'">
                        <span class="name">分析指标</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('分析指标', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="group" type="html" title="图像信息" width="100" :fixed="demo1.colFixeds.col7" min-width="80">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '图像信息'">
                        <span class="name">图像信息</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('图像信息', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="manufacturer" type="html" title="指标参数" width="112" :fixed="demo1.colFixeds.col8">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '指标参数'">
                        <span class="name">指标参数</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('指标参数', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="manufacturing_no" type="html" title="分析结果" width="112" :fixed="demo1.colFixeds.col8">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '分析结果'">
                        <span class="name">分析结果</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('分析结果', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="specification" type="html" title="指标参数" width="112" :fixed="demo1.colFixeds.col8">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '指标参数'">
                        <span class="name">指标参数</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('指标参数', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="traceability" type="html" title="分析结果2" width="112" :fixed="demo1.colFixeds.col8">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '分析结果2'">
                        <span class="name">分析结果2</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('分析结果2', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>
                  <vxe-column field="management" type="html" title="指标参数3" width="112" :fixed="demo1.colFixeds.col8">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Address-header" v-if="column.title === '指标参数3'">
                        <span class="name">指标参数3</span>
                        <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('指标参数3', $event)"></i></span>
                      </div>
                    </template>
                  </vxe-column>

                  <vxe-column title="操作" align="center" width="90" :fixed="demo1.colFixeds.col9" min-width="90" :resizable="false">
                    <!-- 增加下拉箭头 -->
                    <template #header="{ column }">
                      <div class="Operation-header" v-if="column.title === '操作'">
                        <span class="name">操作</span>
                        <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                      </div>
                    </template>
                    <template #default>
                      <vxe-button class="detail">标记无效</vxe-button>
                    </template>
                  </vxe-column>

                  <template #empty>
                    <span style="color: red">
                      <!-- <img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif" /> -->
                      <p>没有更多数据了！</p>
                    </span>
                  </template>
                </vxe-table>
                <!-- 下拉箭头的更多操作 -->
                <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
                  <div class="contentBox" id="MoreOptions">
                    <ul class="list" id="MoreOptions">
                      <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                        <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                      </li>
                      <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                        <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                      </li>
                      <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')" :class="!CancelFixed ? 'light' : ''">
                        <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" v-show="CancelFixed" />
                        <img src="https://newstore.vynior.com/cems_web/%E9%94%81%E5%AE%9A%402xs.png" alt="" v-show="!CancelFixed" />固定
                      </li>
                      <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" :class="CancelFixed ? 'light' : ''">
                        <img src="https://newstore.vynior.com/cems_web/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="!CancelFixed" />
                        <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="CancelFixed" />取消固定
                      </li>

                      <li id="MoreOptions" @click="HiddenColumn(FixedIndex)">
                        <img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- todo -->
                <!-- 每一行的 ... -->
                <!-- <div class="Options" id="CMoreOption" ref="CMoreOptions" v-show="ClickMoreOptions">
                <div class="Copy" @click="ExportData(OptionsData)">导出</div>
                <div class="Delete" @click="DeleteVolun(OptionsData)">删除</div>
              </div> -->
              </div>
              <!-- 显示功能弹窗 -->
              <div class="SetShowsBox" ref="ShowBox" v-show="SetShow" id="SetShowsBoxs">
                <div class="Titles" id="SetShowsBoxs">字段显示</div>
                <div class="ContentBox" :class="AllHidenHeader.length == 0 ? 'Emptys' : ''" id="SetShowsBoxs">
                  <span class="Empty" v-if="AllHidenHeader.length == 0" id="SetShowsBoxs">暂无隐藏字段</span>
                  <div class="ListBox" v-if="AllHidenHeader.length != 0" id="SetShowsBoxs">
                    <div
                      class="List"
                      v-for="(item, index) in AllHidenHeader"
                      :key="index"
                      @click="ShowCheck[index] = !ShowCheck[index]"
                      id="SetShowsBoxs">
                      <div class="item" id="SetShowsBoxs">{{ item.title }}</div>
                      <div class="Active" v-show="ShowCheck[index]" id="SetShowsBoxs">
                        <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110500%402x.png" alt="" />
                      </div>
                    </div>
                    <div
                      class="Button"
                      @click="ConfirmRecover"
                      id="SetShowsBoxs"
                      :class="AllHidenHeader.length == 1 ? 'Buttons' : AllHidenHeader.length == 2 ? 'Buttonss' : ''">
                      确认
                    </div>
                  </div>
                </div>
              </div>
              <p>
                <vxe-pager
                  class="Buttons"
                  background
                  @page-change="pageChange"
                  v-model:current-page="page5.currentPage"
                  v-model:page-size="page5.pageSize"
                  :total="page5.totalResult"
                  :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
                  <template #left>
                    <div class="Total">
                      已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                      >条/共{{ page5.totalResult }}条
                    </div>
                  </template>
                </vxe-pager>
              </p>
            </div>
            <div
              class="EmptyPage"
              style="
                width: 100%;
                height: 100%;
                background-color: #f8f8f8f8;
                padding: 8px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              v-show="tableData.length == 0">
              <div
                class="pageContent"
                style="
                  width: 100%;
                  height: 700px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                  background: #ffffff;
                  border-radius: 2px 2px 2px 2px;
                  opacity: 1;
                ">
                <img src="https://newstore.vynior.com/cems_web/%E6%9A%82%E6%97%A0%E6%95%B0%E6%8D%AE111.png" alt="" />
                <span style="font-size: 16px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #626366"
                  >暂无数据</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <createInstrument v-model="is_CreatedDeviceDialogShow" v-if="is_CreatedDeviceDialogShow" :Change="information"></createInstrument> -->
    <!-- <InstrumentChange v-model="Details" v-if="Details"></InstrumentChange> -->
    <!-- :ChangeTite="DetailsMsg" -->
    <!--
      表头
     -->
    <!-- //! 全脸分析表头 -->
    <div class="SetTableHeader" v-if="SetTableHeader">
      <div class="main">
        <div class="Header">
          <div class="text">表头字段设置</div>
        </div>
        <!-- 设置表头的数据盒子 -->
        <div class="SetBox">
          <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
          <div class="LeftBox">
            <div class="text-header">仪器测试记录列表预设字段</div>
            <div class="ProjectRowField">
              <!-- <div class="text-header1">【仪器基本信息】</div> -->
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetInstrumentListArr.slice(0, 37)"
                  :key="index"
                  :class="defaultHeader?.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                </div>
              </div>
            </div>
            <!-- 【系统信息】 -->
            <!-- <div class="ProjectRecruitMSG">
              <div class="text-header2">【仪器校准信息】</div>
           
              <div class="CheckBox">
                <div class="Checkbox" v-for="(item, index) in SetInstrumentListArr.slice(18, 22)" :key="index">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 18)" />
                </div>
              </div>
            </div> -->

            <div class="Self-Defined">自定义扩展字段</div>
          </div>
          <!-- 右边盒子 拖动 -->
          <div class="RightBox">
            <div class="text-header">列表页展示字段</div>
            <div style="font-size:16px; color='black';margin-top:348px">此功能正在开发中,敬请期待</div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="Bottom">
          <div class="add">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
          </div>
          <div class="recover" @click="RecoverData">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
          </div>
          <div class="cancel" @click="CancelShowHiden">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" />
          </div>
          <div class="save" @click="ShowHidenColumn">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- //!AOI区域分析 -->
  </div>
</template>
<script setup>
import { Document, Menu as IconMenu, Location, Setting } from '@element-plus/icons-vue'
import InstrumentChange from '@/components/InstrumentMangement/InstrumentChange.vue'
import createInstrument from '@/components/InstrumentMangement/createInstrument.vue'
import { defineComponent, isReactive, ref, reactive, nextTick, toRaw, toRefs, onMounted, watchEffect, onBeforeUnmount, watch, computed } from 'vue'
import { VxeTableInstance } from 'vxe-table'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import { CaretLeft, CaretRight, Upload } from '@element-plus/icons-vue'
import { message } from '@/utils/resetMessage'
import dayjs from 'dayjs'
import http from '@/utils/http'
import XLSX from 'xlsx'
import * as xlsx from 'xlsx-js-style'
import FileSaver from 'file-saver'
import { replaceEmptyValues } from '@/utils/emptyArray'

const linewidth = ref(window.innerWidth)
const handleResize = () => {
  linewidth.value = window.innerWidth
}
let null_reminder_export = ref(false) // ! 没有勾选数据导出
// ! 关闭提醒弹窗
const Closereminder = () => {
  null_reminder_export.value = false
}

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
// !表单空白处理
const tableData = computed(() => {
  return replaceEmptyValues(demo1.tableData)
})
//  ! 收缩导航栏
const isCollapse = ref(false)
const handleOpen = (key, keyPath) => {
  console.log(key, keyPath)
}
const handleClose = (key, keyPath) => {
  console.log(key, keyPath)
}
const isExport = ref(false)
const isShowExport = () => {
  console.log('点击了导出')

  if (checkedArr.value.length >= 1) {
    null_reminder_export.value = false
    isExport.value = true
  } else {
    null_reminder_export.value = true
    isExport.value = false
  }
}

//导出
const exportToExcel = async () => {
  console.log(checkedArr.value)
  if (checkedArr.value.length >= 1) {
    const res = await http.post('/instrument/export-fullface', checkedArr.value)
    if (res.status == 0) {
      xlsx.writeFileXLSX(res.xlsxData, `vplus智能皮肤分析系统列表 ${dayjs().format('YYYYMMDD')}.xlsx`)
      message({
        message: '导出成功',
        showClose: true,
        type: 'success',
      })
      isExport.value = false
    }
  }
}
//API接收
const store = useStore()
const router = useRouter()
const xTable = ref()
let AOISHOW = ref(false)
//面朝方向
const faceDirection = ref(['Center', 'left', 'Right'])
let activeIndex = ref(1)
const handleSelect = (index) => {
  activeIndex.value = index
  console.log(index)
  if (activeIndex.value == 1) {
    centerList()
  } else if (activeIndex.value == 2) {
    leftList()
  } else if (activeIndex.value == 3) {
    rightList()
  }
}
const currentAge = ref('')
const analysisTime = ref(null)
const currentShow = ref('scoreKey')
const currentDataType = ref(undefined)
const fullFaceInput = ref('')
const currentEquipment = ref('')
const resetQuery = () => {
  currentAge.value = ''
  fullFaceInput.value = ''
  analysisTime.value = null
  currentEquipment.value = ''
  currentDataType.value = undefined
  faceDirection.value = ''
  fetchFullFaceList()
}
const fullFaceList = ref([])
const loading = ref(false)
const fetchFullFaceList = async () => {
  let query = { page: currentPage.value, size: pageSize.value }
  if (currentEquipment.value) query.fullFace_equipment = currentEquipment.value
  if (analysisTime.value) query.analysis_time = analysisTime.value
  if (currentAge.value) query.fullFace_userAge = currentAge.value
  if (fullFaceInput.value) query.fullFace_input = fullFaceInput.value
  if (currentDataType.value !== undefined) query.currentDataType = currentDataType.value
  if (faceDirection.value) query.faceDirection = faceDirection.value
  try {
    loading.value = true
    const response = await http.post('/instrument/fetchRecord-instrument', query)
    // console.log(response);
    if (response) {
      if (response.status == 0) {
        fullFaceList.value = response.findOutFullFace
        totoalCount.value = response.totalCount
        loading.value = false
      }
    } else {
      fullFaceList.value = []
      totalCount.value = 0
    }
  } catch (error) {
    console.log('error :>> ', error)
  }
}
// ?  所有维度数据
const demo2 = reactive({
  value10: 12,
  value11: null,
  value12: null,
  value13: null,
})
const cellClassName = ({ row, column }) => {
  if (
    column.field === 'pore' ||
    column.field === 'wrinkle' ||
    column.field === 'dark_circle' ||
    column.field === 'eyelash' ||
    column.field === 'porphyrin' ||
    column.field === 'texture' ||
    column.field === 'acne' ||
    column.field === 'brown_spot' ||
    column.field === 'red_spot' ||
    column.field === 'uv_spot' ||
    column.field === 'face_light_analysis'
  ) {
    return `click-cell`
  }
}
const headerCellClassName = ({ column }) => {
  if (
    column.field === 'pore_num' ||
    column.field === 'pore_mean_area' ||
    column.field === 'pore_all_area' ||
    column.field === 'pore_area_rate' ||
    column.field === 'wrinkle_num' ||
    column.field === 'wrinkle_area' ||
    column.field === 'wrinkle_area_mean' ||
    column.field === 'wrinkle_length' ||
    column.field === 'wrinkle_length_mean' ||
    column.field === 'dark_circle_rate' ||
    column.field === 'dark_circle_gray_mean' ||
    column.field === 'eyelash_length_mean' ||
    column.field === 'eyelash_num' ||
    column.field === 'porphyrin_num' ||
    column.field === 'porphyrin_area' ||
    column.field === 'porphyrin_density' ||
    column.field === 'porphyrin_num_red' ||
    column.field === 'porphyrin_area_red' ||
    column.field === 'porphyrin_density_red' ||
    column.field === 'porphyrin_num_blue' ||
    column.field === 'porphyrin_area_blue' ||
    column.field === 'porphyrin_density_blue' ||
    column.field === 'acne_num' ||
    column.field === 'acne_scar_num' ||
    column.field === 'acne_diameter_mean' ||
    column.field === 'brown_num' ||
    column.field === 'brown_area_all' ||
    column.field === 'brown_area_mean' ||
    column.field === 'brown_area_rate' ||
    column.field === 'brownLAB' ||
    column.field === 'red_num' ||
    column.field === 'red_area_all' ||
    column.field === 'red_area_mean' ||
    column.field === 'red_area_rate' ||
    column.field === 'redLAB' ||
    column.field === 'uv_num' ||
    column.field === 'area_all' ||
    column.field === 'area_mean' ||
    column.field === 'areauv_rate' ||
    column.field === 'uv_gray_mean' ||
    column.field === 'var' ||
    column.field === 'light' ||
    column.field === 'ITA' ||
    column.field === 'Hue_std' ||
    column.field === 'linear_light_degree' ||
    column.field === 'LAB'
  ) {
    return 'children-header'
  }
}

// table组件数据
let totalCount = ref(0)
const dimensionDrawer = ref(false)
const drawerTitle = ref('')
const drawerData = ref()
const drawerImgPath = ref('')
const drawerRenderIndex = ref(0)
const showCaret = ref(false)
const currentDimension = ref([])
const fields = ref([
  'pore',
  'wrinkle',
  'dark_circle',
  'eyelash',
  'porphyrin',
  // 'texture',
  'acne',
  'brown_spot',
  'red_spot',
  'uv_spot',
  'face_light_analysis',
])
let showMoreObj = ref({
  pore: false,
  wrinkle: false,
  dark_circle: false,
  eyelash: false,
  porphyrin: false,
  // texture: false,
  acne: false,
  brown_spot: false,
  red_spot: false,
  uv_spot: false,
  face_light_analysis: false,
})
const SetTableHeader = ref(false)
const openDrawer = (key, data) => {
  console.log('测试')
  allRenderList.value.forEach((item, index) => {
    if (item.key === key) {
      drawerTitle.value = item.label
      drawerRenderIndex.value = index
    }
  })
  drawerData.value = data[key]
  //drawerImgPath.value = data.image_path.find((path) => {
  //  if (key === 'face_light_analysis') return path.includes('white');
  //  else return path.includes(key);
  // });
  dimensionDrawer.value = true
}

const MyshowColumn = (field) => {
  console.log(123456789)
  const $table = xTable.value
  showMoreObj.value[field] = true
  drawerRenderList.value[field].forEach((field) => {
    $table.showColumn(field.key)
  })
}
const MyhideColumn = (field) => {
  const $table = xTable.value
  showMoreObj.value[field] = false
  drawerRenderList.value[field].forEach((field) => {
    $table.hideColumn(field.key)
  })
}
const allRenderList = ref([
  {
    key: 'pore',
    label: '毛孔',
    showMore: false,
    scoreKey: 'pore_score',
    numberKey: 'pore_num',
    rankKey: 'pore_rank',
  },
  {
    key: 'wrinkle',
    label: '皱纹',
    showMore: false,
    scoreKey: 'wrinkle_score',
    numberKey: 'wrinkle_num',
    rankKey: 'wrinkle_rank',
  },
  {
    key: 'dark_circle',
    label: '黑眼圈',
    showMore: false,
    numberKey: '',
    scoreKey: 'dark_circle_score',
    rankKey: 'dark_circle_rank',
  },
  {
    key: 'eyelash',
    label: '睫毛',
    showMore: false,
    scoreKey: 'eyelash_score',
    numberKey: 'eyelash_num',
    rankKey: 'eyelash_rank',
  },
  {
    key: 'porphyrin',
    label: '紫外荧光',
    showMore: false,
    scoreKey: 'porphyrin_score',
    numberKey: 'porphyrin_num',
    rankKey: 'porphyrin_rank',
  },

  {
    key: 'texture',
    label: '纹理',
    showMore: false,
    numberKey: '',
    scoreKey: 'texture_score',
    rankKey: 'texture_rank',
  },
  {
    key: 'acne',
    label: '痘痘与痘印',
    showMore: false,
    scoreKey: 'acne_score',
    numberKey: 'acne_num',
    rankKey: 'acne_rank',
  },
  {
    key: 'brown_spot',
    label: '黑素与色斑',
    showMore: false,
    scoreKey: 'brown_score',
    numberKey: 'brown_num',
    rankKey: 'brown_rank',
  },
  {
    key: 'red_spot',
    label: '血红素',
    showMore: false,
    scoreKey: 'red_score',
    numberKey: 'red_num',
    rankKey: 'red_rank',
  },
  {
    key: 'uv_spot',
    label: '紫外发色团',
    showMore: false,
    scoreKey: 'uv_score',
    numberKey: 'uv_num',
    rankKey: 'uv_rank',
  },
  {
    key: 'face_light_analysis',
    label: '肤色及均匀度',
    showMore: false,
    numberKey: '',
    scoreKey: 'skin_score',
    rankKey: 'skin_rank',
  },
])
const drawerRenderList = ref({
  pore: [
    { label: '毛孔个数', key: 'pore_num', Unit: '（个）' },
    { label: '毛孔总面积', key: 'pore_mean_area', Unit: '（mm²）' },
    { label: '毛孔平均面积', key: 'pore_all_area', Unit: '（mm²）' },
    { label: '毛孔密度', key: 'pore_area_rate', Unit: '（%）' },
  ],
  wrinkle: [
    { label: '皱纹数量', key: 'wrinkle_num', Unit: '（条）' },
    { label: '皱纹总面积', key: 'wrinkle_area', Unit: '（mm²）' },
    { label: '皱纹平均面积', key: 'wrinkle_area_mean', Unit: '（mm²）' },
    { label: '皱纹总长度', key: 'wrinkle_length', Unit: '（mm）' },
    { label: '皱纹平均长度', key: 'wrinkle_length_mean', Unit: '（mm）' },
    // { label: '皱纹最大深度（μm）', key: '' },
    // { label: '皱纹平均深度（μm）', key: '' },
  ],
  dark_circle: [
    { label: '黑眼圈密度', key: 'dark_circle_rate', Unit: '（%）' },
    { label: '灰度值均值', key: 'dark_circle_gray_mean' },
  ],
  eyelash: [
    { label: '睫毛平均长度', key: 'eyelash_length_mean', Unit: '（mm）' },
    { label: '睫毛根数', key: 'eyelash_num', Unit: '（根）' },
  ],
  porphyrin: [
    { label: '紫外荧光点数量', key: 'porphyrin_num', Unit: '（个）' },
    { label: '紫外荧光点总面积', key: 'porphyrin_area', Unit: '（mm²）' },
    { label: '紫外荧光点密度', key: 'porphyrin_density', Unit: '（%）' },
    { label: '红色荧光点数量', key: 'porphyrin_num_red', Unit: '（个）' },
    { label: '红色荧光点总面积', key: 'porphyrin_area_red', Unit: '（mm²）' },
    { label: '红色荧光点密度', key: 'porphyrin_density_red', Unit: '（%）' },
    { label: '蓝色荧光点数量', key: 'porphyrin_num_blue', Unit: '（个）' },
    { label: '蓝色荧光点总面积', key: 'porphyrin_area_blue', Unit: '（mm²）' },
    { label: '蓝色荧光点密度', key: 'porphyrin_density_blue', Unit: '（%）' },
  ],
  acne: [
    { label: '痘痘数量', key: 'acne_num', Unit: '（个）' },
    { label: '痘印数量', key: 'acne_scar_num', Unit: '（个）' },
    { label: '痘痘平均直径', key: 'acne_diameter_mean', Unit: '（mm）' },
  ],
  brown_spot: [
    { label: '色斑数量', key: 'brown_num', Unit: '（个）' },
    { label: '色斑总面积', key: 'brown_area_all', Unit: '（mm²）' },
    { label: '色斑平均面积', key: 'brown_area_mean', Unit: '（mm²）' },
    { label: '色斑密度', key: 'brown_area_rate', Unit: '（%）' },
    {
      label: '色斑L*a*b均值',
      key: 'brownLAB',
      children: [
        { label: 'L', key: 'L' },
        { label: 'a', key: 'A' },
        { label: 'b', key: 'B' },
      ],
    },
  ],
  red_spot: [
    { label: '血红素数量', key: 'red_num', Unit: '（个）' },
    { label: '血红素总面积', key: 'red_area_all', Unit: '（mm²）' },
    { label: '血红素平均面积', key: 'red_area_mean', Unit: '（mm²）' },
    { label: '血红素密度', key: 'red_area_rate', Unit: '（%）' },
    {
      label: '血红素L*a*b均值',
      key: 'redLAB',
      children: [
        { label: 'L', key: 'L' },
        { label: 'a', key: 'A' },
        { label: 'b', key: 'B' },
      ],
    },
  ],
  uv_spot: [
    { label: '发色团数量', key: 'uv_num', Unit: '（个）' },
    { label: '发色团总面积', key: 'area_all', Unit: '（mm²）' },
    { label: '发色团平均面积', key: 'area_mean', Unit: '（mm²）' },
    { label: '发色团密度', key: 'areauv_rate', Unit: '（%）' },
    { label: '发色团灰度值均值', key: 'uv_gray_mean' },
  ],
  face_light_analysis: [
    { label: '皮肤均匀度', key: 'var' },
    { label: '光泽度', key: 'light' },
    { label: 'ITA角', key: 'ITA' },
    { label: 'Hue-std值', key: 'Hue_std' },
    { label: '线性光强度', key: 'linear_light_degree' },
    {
      label: 'L*a*b均值',
      key: 'LAB',
      children: [
        { label: 'L', key: 'L' },
        { label: 'a', key: 'A' },
        { label: 'b', key: 'B' },
      ],
    },
  ],
})
const ClickActive = ref(0)
// 查看/修改仪器信息
let Details = ref(false)
let DetailsMsg = ref({})
// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([])

// 切换状态true/false
const ChangeStatus = ref(false)
// 要切换的值是:
const change_status_values = ref('')
const is_dialogShow = ref(true)
// todo
// 批量操作  true/false 盒子
let BatchBox = ref(false)
// 批量删除盒子 BatchDelete
let BatchDelete = ref(false)
// 单个删除仪器名字
const DeleteMsg = ref({
  Name: '',
  ID: '',
})

//   当前显示的详情数据id
let currentID = ref('')
// 新建仪器按钮
const is_CreatedDeviceDialogShow = ref(false)
//  每一行的 ... 点击
let ClickMoreOptions = ref(false)
const CMoreOptions = ref(null) //获取MoreOptions盒子的ref
let OptionsData = ref()
//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  loading: true,
  list: [],
  gender: '',
  age: '',
})
const gender = ['男', '女']
const ageList = ['不限年龄', '18~25', '26~40', '40~49', '≥50']
let Volunteerdetails = ref(false) // !查看志愿者详情
// sort排序字段
let SORT = ref({ createdAt: -1 })
// 查看详情的数据
let information = ref([])
let Volunteereditor = ref(false)
const SelectValue = ref([])
const Options = [
  {
    value: '0',
    label: '毛孔',
  },
  {
    value: '1',
    label: '皱纹',
  },
  {
    value: '2',
    label: '黑眼圈',
  },
  {
    value: '3',
    label: '睫毛',
  },
  {
    value: '4',
    label: '紫外荧光',
  },
  {
    value: '5',
    label: '纹理',
  },
  {
    value: '6',
    label: '痘痘与痘印',
  },
  {
    value: '7',
    label: '黑素与色斑',
  },
]
// 模糊搜索 判断是否是第一次
const EventSearch = ref(false)
// 模糊Age 判断是否是第一次
const AgeSearch = ref(false)
// 模糊Gender 判断是否是第一次
const GenderSearch = ref(false)

// 高级搜索初始数据
const initialize = reactive(null)
// ! 显示按钮
let isShow = ref(false)
// ! 线上报名 身份证数据
let IDnumber = ref(null)
// 高级搜索数据存储
const Volunteers = reactive({
  volun_number: null, // 志愿者id
  volun_name: null, // 姓名
  volun_gender: null, // 性别
  idNumber: null, //身份证号
  volun_phone: null, // 联系电话
  referee: null, //推荐人
  volun_birthday: null, // 出生日期
  volun_age: null, //  年龄
  address: null, //地址
  monthly_income: null, //  月收入
  marital_history: null, // 结婚
  three_phase: null, //三期
  tags: null, //标签
  remark: null, //备注
  createdAt: null, //第一次注册时间
  reg_total: null, //历史项目
  project_total: null, //正在进行
  parting_total: null, // 正在参与项目
  skin_texture: null, // 肤质
  skin_type: null, //皮肤类型
  skin_issues: null, //皮肤问题
  skin_color: null, //肤色
  use_products: null, //使用过的产品
  smoke: null,
  drink: null,
  underlying_disease: null, //健康
})
const VolunteersOne = reactive({
  volun_number: '', // 志愿者id
  volun_name: '', // 姓名
  volun_gender: '', // 性别
  idNumber: '', //身份证号
  volun_phone: '', // 联系电话
  referee: '', //推荐人
  volun_birthday: '', // 出生日期
  volun_age: '', //  年龄
  address: '', //地址
  monthly_income: '', //  月收入
  marital_history: '', // 结婚
  three_phase: '', //三期
  tags: [], //标签
  remark: '', //备注
  createdAt: '', //第一次注册时间
  reg_total: '', //历史项目
  project_total: '', //正在进行
  parting_total: '', // 正在参与项目
  skin_texture: '', // 肤质
  skin_type: '', //皮肤类型
  skin_issues: [], //皮肤问题
  skin_color: '', //肤色
  use_products: [], //使用过的产品
  smoke: '',
  drink: '',
  underlying_disease: '', //健康
  account_name: '',
  account_number: '',
  bank: '',
  reservation_time: '2001-05-02',
})
//显示功能的字段设置
let AllShowHeader = ref([])
// 全部显示里被隐藏的
let AllHidenHeader = ref([])
const SetShow = ref(false)
const ShowBox = ref(null) //获取Show盒子的ref
const ShowCheck = ref([])
watch(currentDimension, (newValue, oldValue) => {
  const $table = xTable.value
  fields.value.forEach((value) => {
    $table.hideColumn(value)
  })
  newValue.forEach((value) => {
    $table.showColumn(value)
  })
  if (newValue.length < oldValue.length) {
    const [field] = oldValue.filter((item) => !newValue.includes(item))
    showMoreObj.value[field] = false
    drawerRenderList.value[field].forEach((field) => {
      $table.hideColumn(field.key)
    })
  }
  if (newValue.length !== 0) showCaret.value = true
  else {
    showCaret.value = false
    fields.value.forEach((value) => {
      $table.showColumn(value)
    })
  }
  if (newValue.length === 1) {
    showMoreObj.value[newValue[0]] = true
    drawerRenderList.value[newValue[0]].forEach((field) => {
      $table.showColumn(field.key)
    })
  }
  if (newValue.length === 2 && newValue.length > oldValue.length) {
    newValue.forEach((field) => {
      showMoreObj.value[newValue[0]] = false
      drawerRenderList.value[field].forEach((field) => {
        $table.hideColumn(field.key)
      })
    })
  }
})
// 监听--->删除弹窗的显示隐藏
watch(
  () => BatchDelete.value,
  (newValue) => {
    if (newValue == false) {
      DeleteMsg.value.Name = ''
      DeleteMsg.value.ID = ''
    }
  }
)
// 监听 批量操作--->切换状态的状态值
watch(
  () => change_status_values,
  (newValue) => {
    console.log('最新的状态码', newValue)
  }
)
//切换/未开始/招募中/进行中/已停止/全部项目
watch(
  () => ClickActive.value,
  async (newValue) => {
    let Project = ''
    if (newValue == 0) {
      Project = '全脸分析'
      centerList()
    } else if (newValue == 1) {
      Project = 'AOI区域分析'
    }

    console.log('Project--->', newValue)

    if (newValue == 0) {
      AOISHOW.value = false
      centerList()
    } else if (newValue == 1) {
      AOISHOW.value = true
      const res = await http.post('/instrument/fetchRecord-instrument', {
        page: page5.currentPage,
        size: page5.pageSize,
        sort: SORT.value,
        content: demo1.filterName,
        status: Project,
      })
      console.log('res--->', res)
      demo1.tableData = res.findOutFullFace
      page5.totalResult = res.totalCount
    }
  }
)
// 监听-->关闭修改页面
watch(
  () => is_CreatedDeviceDialogShow.value,
  (newVale) => {
    if (newVale == false && information.value.length != 0) {
      console.log('修改关闭 :>> ', '修改关闭')
      reqProjectList()
      ResetInDetails()
    } else {
      console.log('修改 :>> ', '修改')
      reqProjectList()
    }
  }
)
//表头字段设置--->设置表头的勾选数据
let SetInstrumentListArr = ref([
  { fixed: '', field: 'analysis_time', checked: true, lable: '测试时间' },
  { fixed: '', field: 'project_number', checked: true, lable: '项目编号' },
  { fixed: '', field: 'user_name', checked: true, lable: '用户姓名' },
  { fixed: '', field: 'user_age', checked: true, lable: '年龄' },
  { fixed: '', field: 'skin_age', checked: true, lable: '肤龄' },
  { fixed: '', field: 'pore', checked: true, lable: '毛孔' },
  { fixed: '', field: 'wrinkle', checked: true, lable: '皱纹' },
  { fixed: '', field: 'dark_circle', checked: true, lable: '黑眼圈' },
  { fixed: '', field: 'eyelash', checked: true, lable: '睫毛' },
  { fixed: '', field: 'porphyrin', checked: true, lable: '紫外荧光' },
  { fixed: '', field: 'texture', checked: true, lable: '纹理' },
  { fixed: '', field: 'acne', checked: true, lable: '痘痘与痘印' },
  { fixed: '', field: 'brown_spot', checked: true, lable: '黑素与色斑' },
  { fixed: '', field: 'red_spot', checked: true, lable: '血红素' },
  { fixed: '', field: 'uv_spot', checked: true, lable: '紫外发色团' },
  { fixed: '', field: 'face_light_analysis', checked: true, lable: '肤色及均匀度' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
])
// 表头字段设置--->恢复默认字段
let DefaultHeaderData = ref([
  { fixed: '', field: 'analysis_time', checked: true, lable: '测试时间' },
  { fixed: '', field: 'project_number', checked: true, lable: '项目编号' },
  { fixed: '', field: 'user_name', checked: true, lable: '用户姓名' },
  { fixed: '', field: 'user_age', checked: true, lable: '年龄' },
  { fixed: '', field: 'skin_age', checked: true, lable: '肤龄' },
  { fixed: '', field: 'pore', checked: true, lable: '毛孔' },
  { fixed: '', field: 'wrinkle', checked: true, lable: '皱纹' },
  { fixed: '', field: 'dark_circle', checked: true, lable: '黑眼圈' },
  { fixed: '', field: 'eyelash', checked: true, lable: '睫毛' },
  { fixed: '', field: 'porphyrin', checked: true, lable: '紫外荧光' },
  { fixed: '', field: 'texture', checked: true, lable: '纹理' },
  { fixed: '', field: 'acne', checked: true, lable: '痘痘与痘印' },
  { fixed: '', field: 'brown_spot', checked: true, lable: '黑素与色斑' },
  { fixed: '', field: 'red_spot', checked: true, lable: '血红素' },
  { fixed: '', field: 'uv_spot', checked: true, lable: '紫外发色团' },
  { fixed: '', field: 'face_light_analysis', checked: true, lable: '肤色及均匀度' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
])

// 表头字段设置----> 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true
  TemporaryData.value = JSON.parse(JSON.stringify(SetInstrumentListArr.value))

  //console.log('SetTableHeader', SetTableHeader.value);
}
// 表头字段设置----> 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false
  SetInstrumentListArr.value = JSON.parse(JSON.stringify(TemporaryData.value))
}
//  表头字段设置----> 恢复默认字段
const RecoverData = () => {
  console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value)
  // let defaultdata = readonly(DefaultHeaderData)
  SetInstrumentListArr.value = JSON.parse(JSON.stringify(DefaultHeaderData.value))
}
// 默认展示的字段/不可取消
let defaultHeader = ref(['操作'])
// 表头阻断设置---->的点击复选框
const CheckHeader = (i) => {
  console.log('SetTableHeaderArr(value.checked) :>> ', SetInstrumentListArr.value[i], i)
  // console.log('isReactive(SetTableHeaderArr) :>> ', isRef(SetTableHeaderArr));

  if (defaultHeader.value.indexOf(SetInstrumentListArr.value[i].lable) != -1) {
    message({
      message: `${SetInstrumentListArr.value[i].lable}不能删除`,
      Showclose: true,
      type: 'error',
    })
    SetInstrumentListArr.value[i].checked = true
  }
}
// ShowHidenColumn 设置column的的列表(确认按钮)
const ShowHidenColumn = () => {
  const $table = xTable.value
  const column = $table.getColumns()

  //console.log('$table.getColumns :>> ', $table.getColumns());

  for (let i = 0; i < SetInstrumentListArr.value.length; i++) {
    if (SetInstrumentListArr.value[i].checked == true) {
      // //console.log('object :>> ', SetInstrumentListArr[i].field);
      $table.showColumn(SetInstrumentListArr.value[i].field)
    }
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetInstrumentListArr.value[i].lable) {
        if (SetInstrumentListArr.value[i].checked == false) {
          $table.hideColumn(column[j].field)
        }
      }
    }
  }

  $table.refreshColumn()
  //console.log('$table.getColumns :>> ', $table.getColumns());
  SetTableHeader.value = false
}

//->全部数据
const reqProjectList = async () => {
  const res = await http.post('/instrument/fetchRecord-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
  })
  console.log('1111', res)
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.findOutFullFace
    page5.totalResult = res.totalCount

    console.table('res :>> ', res)
  }
  ChangeStatus.value = false
}

//->正脸数据
const centerList = async () => {
  const res = await http.post('/instrument/fetchRecord-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    faceDirection: faceDirection.value[0],
  })
  console.log('center', res)
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.findOutFullFace
    page5.totalResult = res.totalCount
    console.table('res :>> ', res)
  } else {
    console.log('Hhhhhhhh')
  }
  ChangeStatus.value = false
}
//->左脸数据
const leftList = async () => {
  const res = await http.post('/instrument/fetchRecord-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    faceDirection: faceDirection.value[1],
  })
  console.log('left', res)
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.findOutFullFace
    page5.totalResult = res.totalCount
    console.table('res :>> ', res)
  }
  ChangeStatus.value = false
}
//->右脸数据
const rightList = async () => {
  const res = await http.post('/instrument/fetchRecord-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    faceDirection: faceDirection.value[2],
  })
  console.log('right', res)
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.findOutFullFace
    page5.totalResult = res.totalCount

    console.table('res :>> ', res)
  }
  ChangeStatus.value = false
}

// 监听项目id
watch(
  () => store.state.Project.ProjectId,
  (newValue) => {
    reqProjectList()
  }
)
// 设置新建志愿者组件显示隐藏
let CreatedVolunteerShow = ref(false)
// ! 控制批量操作的显示
let isshowOperation = ref(false)
// ! 导出志愿信息弹框
let isDerived = ref(false)
// ! 导出志愿信息弹框   失败
let isDerived_delect = ref(false)
// ! 线上报名
let Onlineregistration = ref(false)
// ! 下拉实时更新数据
let options = ref([])
//  !输入框数据
let selectID = ref([])
// ! 报名弹窗
let Register = ref(false)
// ! 搜索的数据
let remark = ref(true)
let oneVolunteerdata = ref([])
// !  实时搜索数据
const remoteMethod = (query) => {
  console.log('刚开始的数据oneVolunteerdata', oneVolunteerdata.value.length)
  if (query) {
    setTimeout(async () => {
      const { result } = await http.post('/instrument/fetchRecord-instrument', {
        page: 1,
        size: 10,
        sort: { createdAt: -1 },
        regex: query,
        sex: {},
        age: '',
      })

      if (result.length > 1) {
        console.log(result[0].idNumber, findOutFullFace[0].volun_name)
        options.value = result.map(({ idNumber, volun_name }) => {
          return {
            value: idNumber,
            label: `${idNumber} ${volun_name}`,
          }
        })
      } else {
        options.value = [
          {
            value: query,
            label: query,
          },
        ]
      }
    }, 200)
  } else {
    options.value = []
  }
}
// ! 点击数据进行查询
const search_one_data = async () => {
  remark.value = false
  let id = selectID.value
  console.log(id, 'id')
  try {
    const { results } = await http.post('/volunteer/find-users', {
      size: 1,
      Volunteers: { idNumber: id },
      page: 1,
      sort: { updatedAt: -1 },
    })
    oneVolunteerdata.value = results
    console.log(oneVolunteerdata, 'oneVolunteerdata')
    console.log('刚开始的数据oneVolunteerdata', oneVolunteerdata.value.length)
  } catch (error) {
    console.log('失败')
  }
}

// 点击新建志愿者
const CreatedVolunteerChange = () => {
  CreatedVolunteerShow.value = !CreatedVolunteerShow.value
  //   //console.log('点击新建志愿者');
}
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params
  //   //console.log(columns); // 打印排序后的表头列数组对象
}
// note 表内导航栏
const ProjectState = ref('')
// ProjectState.value = ['全脸分析', 'AOI区域分析'];
ProjectState.value = ['全脸分析']
const changeShow = ref(false)
const reqAllData = async () => {
  const res = await http.post('/user/findAll-user', {})
  //   //console.log('全部返回的数据是:', res);
}
//!高级搜索
const reqAllSelectData = async () => {
  // ! 取消null的字段
  for (let prop in Volunteers) {
    if (Volunteers[prop] === null) {
      delete Volunteers[prop]
    }
  }
  //   //console.log('Volunteers', Volunteers);
  const res = await http.post('/instrument/fetchRecord-instrument', {
    size: page5.pageSize,
    Volunteer: Volunteers,
    page: page5.currentPage,
    sort: { updatedAt: -1 },
    Project: store.state.Project.ProjectId,
  })
  console.log('全部返回的数据是:', res)
  demo1.tableData = res.findOutFullFace
  page5.totalResult = res.totalCount

  changeShow.value = false
}
// !重置按钮
const reset = () => {
  demo1.age = ''
  demo1.gender = ''
  demo1.filterName = ''
  EventSearch.value = false
  GenderSearch.value = false
  AgeSearch.value = false
  ClickActive.value = 0
  page5.pageSize = 10
  page5.currentPage = 1
  const $table = xTable.value
  const records = $table.clearCheckboxRow()
  checkedArr.value = []
  currentDimension.value = []
  reqProjectList()
}
// ! 退出按钮
const unSelect = () => {
  changeShow.value = false
}
// !批量按钮显示
const showOperation = () => {
  isshowOperation.value = true
}
// ! 导出
const Export = () => {
  console.log(selectedRows)
  console.log(SetVolunteerHeaderArr.value)

  if (selectedRows.length !== 0) {
    console.log(SetVolunteerHeaderArr.value)
    isshowOperation.value = false
    isshowDerived()
  } else {
    // ElMessage('一条都没有');
  }
  // 判断值，进行展示
}

//todo ! todo Batch删除按钮
const delect = () => {
  if (checkedArr.value.length !== 0) {
    BatchDelete.value = true
  } else {
    message({
      message: '请先选择数据',
      ShowClose: true,
      type: 'error',
    })
  }
}
// 批量删除
const DelteProject = async () => {
  const res = await http.delete('/instrument/delete-Instrument', {
    deleteData: checkedArr.value,
  })
  if (res.status == 0) {
    BatchDelete.value = false
    reqProjectList()
    message({
      message: '删除成功',
      ShowClose: true,
      type: 'success',
    })
  }
}

// ! !导出志愿者信息弹窗  失败  确定
const isshowDerived_delect_true = async () => {
  // !删除对应数据，并刷新页面
  const ids = selectedRows.map((obj) => obj._id)
  console.log(ids, 'idddddd') // [1, 2, 3]
  try {
    const res = await http.delete('/volunteer/delete-user', {
      deleteData: ids,
    })
    isDerived_delect.value = !isDerived_delect.value
    reqProjectList()
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    })
  } catch (error) {
    message({
      message: '删除失败',
      grouping: true,
      type: 'error',
    })
  }
}
// !显示选择框
const showSelect = () => {
  isShow.value = !isShow.value
}
// ! 创建志愿者
// const Createvolunteers = async () => {
//   try {
//     await http.post('/Recruit_Form/created-Recruit_Form', VolunteersOne);
//     message({
//       message: '创建成功',
//       grouping: true,
//       type: 'success',
//     });
//     Onlineregistration.value = !Onlineregistration.value;
//     selectID.value = [];
//     remark.value = true;
//     oneVolunteerdata.value = [];
//     Register.value = false;
//     reqProjectList();
//   } catch (error) {
//     message({
//       message: '创建失败',
//       grouping: true,
//       type: 'error',
//     });
//   }
// };
// todo
const EditOpen = () => {
  is_CreatedDeviceDialogShow.value = true
}
// 点击...
const ClickMethods = (row, event) => {
  // console.log(row);
  OptionsData.value = row
  // console.log(DelectData.value);
  ClickMoreOptions.value = false
  ClickMoreOptions.value = true

  // console.log('event :>> ', event, CMoreOptions.value);

  CMoreOptions.value.style.left = event.clientX - 120 + 'px'
  CMoreOptions.value.style.top = event.clientY + 30 + 'px'
  // console.log('event :>> ', ClickMoreOptions.value);
}

//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
})

// 分页--->分页
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false
  //console.log('点击点击 :>> ');
}

//分页--->跳转页面显示数据处理
const pageChange = async () => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()

  if (demo1.filterName == '') {
    const res = await http.post('/instrument/fetchRecord-instrument', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
      faceDirection: activeIndex.value == 1 ? 'Center' : activeIndex.value == 2 ? 'left' : activeIndex.value == 1 ? 'right' : null,
    })
    console.log('res123 :>> ', res)
    page5.totalResult = res.totalCount
    demo1.tableData = res.findOutFullFace
  } else {
    const res = await http.post('/instrument/fetchRecord-instrument', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
    })
    console.log(res, '1233456')
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.findOutFullFace
      page5.totalResult = res.totalCount
    }
  }
}

//导出弹窗显示隐藏
let dialogShow = ref(false)
//导出方法
const exportDataEvent = () => {
  // const $table = xTable.value;
  // $table.exportData({ type: 'csv' });
  console.log('123')

  dialogShow.value = true
}
//导入
let dialogShow1 = ref(false)
const uploadData = () => {
  console.log(123333)
  dialogShow1.value = true
}
// 点击下载模板
const downloadXlsx = async () => {
  const xlsxUrl = 'https://newstore.vynior.com/cems_web/vplus%E5%AF%BC%E5%87%BA%E6%95%B0%E6%8D%AE.xlsx'
  const response = await fetch(xlsxUrl)
  const blob = await response.blob()
  // 创建下载链接
  const url = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.href = url
  link.download = '仪器测试记录-CN-JK-001  Vplus 智能皮肤分析系统-上传数据.xlsx'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  // 释放 Blob URL
  window.URL.revokeObjectURL(url)
}

// 导入志愿者错误返回的路径
let errorPath = ref()
// 导入志愿者错误的数据量
let errFileNum = ref(null)
// 上传的文件名
let fileName = ref('')
// 上传文件名
let uploadFileName = ref('')
// 是否上传错误
let uploadFileErr = ref(false)
//导入失败显示隐藏
const errdialogShow = ref(false)
// 上传事件
const uploadFile = async (params) => {
  console.log(params)
  const file = params.file
  // fileName.value=file.name
  const lastIndex = file.name.lastIndexOf('.')
  fileName.value = file.name.substring(0, lastIndex)
  const form = new FormData()
  form.append('file', file)
  console.log(params)
  await http
    .post('/upload/upload-instrument', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async (res) => {
      console.log(res)
      if (res.status == 0) {
        dialogShow1.value = false
        reqProjectList()
        ElMessage({
          showClose: true,
          message: '上传数据成功',
          type: 'success',
        })
      } else if (res.status == 1) {
        ElMessage({
          showClose: true,
          message: '上传文件为空，请重新上传',
          type: 'error',
        })
      }
      if (res.status == 2) {
        dialogShow.value = false
        errorPath.value = res.errorPath
        errdialogShow.value = true
        errFileNum.value = res.errorNumber
      }
    })
}
// 点击下载错误模板
const downloadErrFile = async () => {
  axios
    .post('https://cems.fuhuan.ltd/api/upload/downloadError-volunteer', { errorPath: errorPath.value }, { responseType: 'arraybuffer' })
    .then((result) => {
      console.log(result, '返回的错误数据')
      const blob = new Blob([result.data], { type: 'application/vnd.ms-excel' })
      let name = fileName.value + '-报错.xlsx'
      const downloadElement = document.createElement('a')
      let classificationhref = window.URL.createObjectURL(blob)
      downloadElement.href = classificationhref
      downloadElement.download = `${name}`
      // // console.log("blob :>> "，blob);
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(classificationhref)
    })
}
const handlePreviews = (file) => {
  console.log(file, 'handlePreviews')
}
const uploadFileSuccess = (res) => {
  console.log('上传成功')
  console.log(res)
}

const handleRemoves = (file, fileList) => {
  console.log(file, fileList, 'handleRemoves')
}
//复选框数组
var selectedRows = reactive([])
const checkedArr = ref([])

// 复选框点击事件
const selectChangeEvent1 = (row) => {
  // console.log('点击的id:', row.rowid);
  console.log('row :>> ', row)

  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records

  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid)
  } else {
    let index = checkedArr.value.indexOf(row.rowid)
    checkedArr.value.splice(index, 1)
  }
  console.log('最新:', checkedArr.value)
}
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  console.log(checked ? '所有勾选事件' : '所有取消事件', records)
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id)
      }
    }
    console.log('最新:', checkedArr.value)
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = []

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id)
    })

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item)
    })
    console.log('最新:', checkedArr.value)
  }
}
// 模糊查询

const searchEvent2 = async () => {
  demo1.loading = true
  const res = await http.post('/instrument/fetchRecord-instrument', {
    page: (page5.currentPage = 1),
    size: page5.pageSize,
    sort: { createdAt: -1 },
    content: demo1.filterName,
    faceDirection: activeIndex.value == 1 ? 'Center' : activeIndex.value == 2 ? 'left' : activeIndex.value == 1 ? 'right' : null,
  })
  console.log(res, '1233456')
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.findOutFullFace
    page5.totalResult = res.totalCount
  } else {
    console.log('查询失败')
  }
}
let CancelFixed = ref(false) //控制每个表头下拉箭头 固定和取消固定的高亮
//  More -->下拉选择中的固定方法
const toggleFixedColumn = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
const toggleFixedColumns = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
// More -->升序/降序
const AscendingSort = async (index, type) => {
  console.log('我的type: :>> ', type)
  demo1.loading = true

  // console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  // console.log('固定信息:', tableColumns);
  const ColumnId = tableColumns[index].field
  const sorts = {}
  if (type == 'Asc') {
    sorts[ColumnId] = 1
  } else {
    sorts[ColumnId] = -1
  }

  SORT.value = sorts
  console.log(SORT.value, 'SORT.value')
  const res = await http.post('/instrument/fetchRecord-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    faceDirection: activeIndex.value == 1 ? 'Center' : activeIndex.value == 2 ? 'left' : activeIndex.value == 1 ? 'right' : null,
  })
  console.log(res, '升降序返回的值')
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.findOutFullFace
    page5.totalResult = res.totalCount

    console.table('res :>> ', res)
  } else {
    console.log('hahhahaha')
  }
}
//
// 获取当前的全部数据
const TransferData = () => {
  // 将全部显示的数据  放到 AllShowHeader中
  SetInstrumentListArr.value.forEach((item, index) => {
    if (item.checked == true) {
      AllShowHeader.value.push(item)
    }
  })
  console.log('AllShowHeader.value :>> ', AllShowHeader.value)
}
// !恢复目前隐藏的列表----> 获取event 设置show显示框的位置
const SetShowFunction = (event) => {
  SetShow.value = false
  SetShow.value = true
  // console.log('event :>> ', event, ShowBox.value);
  ShowBox.value.style.left = event.clientX - 10 + 'px'
  ShowBox.value.style.top = event.clientY + 20 + 'px'
}
// ! 恢复目前隐藏的列表----> 确认按钮
const ConfirmRecover = () => {
  let TeShowCheck = []
  let TeAllHidenHeader = []
  const $table = xTable.value
  const column = $table.getColumns()
  console.log('ShowCheck.value :>> ', ShowCheck.value)
  for (let i = ShowCheck.value.length; i >= 0; i--) {
    if (ShowCheck.value[i] == true) {
      // // column[index].visible = false;
      // console.log('column :>> ', ShowCheck.value[i], AllHidenHeader.value[i]);

      // 显示列
      $table.showColumn(AllHidenHeader.value[i].field)
      // 同步删除 数据 隐藏列/checked
      AllHidenHeader.value.splice(i, 1)
      ShowCheck.value.splice(i, 1)
      // console.log('column :>> ', column, $table);
      // console.log('column :>> ', $table.showColumn());
      // console.log('column :>> ', ShowCheck.value, AllHidenHeader.value);
    }
  }
  SetShow.value = false
}
// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false
  const $table = xTable.value
  const column = $table.getColumns()
  // console.log('column[index] :>> ', column[index]);
  column[index].visible = false
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value.push(column[index])
  ShowCheck.value.push(false)
  $table.refreshColumn() //刷新列表
  console.log('ShowChcke.value :>> ', ShowCheck.value)
  // console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
}
// 每一行的查看详情
const ShowDetails = (row) => {
  console.log('row :>> ', row)
  currentID.value = row._id
  Volunteerdetails.value = !Volunteerdetails.value
  Volunteereditor.value = true
  demo1.tableData.forEach((item, index) => {
    if (item._id == row._id) {
      information.value = item
    }
  })
}
//
// 每一行的删除
const DeleteVolun = async (row) => {
  console.log('row :>> ', row)
  DeleteMsg.value.Name = row.instr_name
  DeleteMsg.value.ID = row._id
  BatchDelete.value = true
  // demo1.loading = true;
}
const SingleDelete = async () => {
  const res2 = await http.delete('/instrument/delete-Instrument', {
    deleteData: [DeleteMsg.value.ID],
  })
  if (res2.status == 0) {
    BatchDelete.value = false
    message({
      message: '删除成功',
      ShowClose: true,
      type: 'success',
    })
  }
  reqProjectList()
}
// 关闭修改页面请求新的详情数据
const ResetInDetails = async () => {
  const res = await http.post('/instrument/fetchRecord-instrument', {
    page: 1,
    size: 10,
    sort: SORT.value,
    content: demo1.filterName,
  })
  console.log('res :>> ', res)
  information.value = JSON.parse(JSON.stringify(res.findOutFullFace[0]))
}

// 点击表头的下拉箭头
const OperationBox = ref(null) //获取更多操作盒子的ref
const showIdOptions = ref(false) //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1)
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  //   //console.log('value :>> ', value);
  const table = xTable.value
  const FixedList = table.getColumns()

  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index
      console.log('item :>> ', item, item.fixed)
      if (item.fixed) {
        CancelFixed.value = false
        // console.log('有值 :>> ');
      } else {
        CancelFixed.value = true
        // console.log('无值 :>> ');
      }
    }
  })
  //console.log('FixedIndex下标为 :>> ', FixedIndex);

  showIdOptions.value = false
  showIdOptions.value = true
  // 设置位置
  const Event = event
  OperationBox.value.style.left = event.clientX - 100 + 'px'
}

// ! 高级显示
const ischangeShow = () => {
  changeShow.value = !changeShow.value
  for (let prop in Volunteers) {
    Volunteers[prop] = null
  }
}
// 高级搜索 下拉
const change_category_val = (e) => {
  // console.log(e);
  if (e.includes('都没有')) {
    // used_category_val.value = ['都没有'];
    const newValueWithoutAll = ['都没有']
    Volunteers.use_products = newValueWithoutAll
  }
}
// 高级搜索 下拉

const change_basic_illness = (e) => {
  // console.log(e);
  if (e.includes('无任何以上提到的疾病')) {
    const newValueWithoutAll = ['无任何以上提到的疾病']
    Volunteers.underlying_disease = newValueWithoutAll
  }
}
// note 全局加载完毕之后执行此事件
nextTick(() => {
  setHeaderColumn()
  // 执行 列表显示/隐藏
  ShowHidenColumn()
  // 固定列表本地数据
  FixedColumn()
  // 转移数据
  TransferData()
})

// 检查是否有本地数据()
const setHeaderColumn = () => {
  if (localStorage.getItem('InstrumentTestRecord') == null) {
    // ////console.log('"第一次" :>> ', '第一次');
  } else {
    // ////console.log('"第二次" :>> ', '第二次');
    SetInstrumentListArr.value = JSON.parse(localStorage.getItem('InstrumentTestRecord'))
  }
}

// FixedColumn 设置column的固定列(初始化本地数据)
const FixedColumn = () => {
  const $table = xTable.value
  const column = $table.getColumns()

  //console.log('column:>> ', column);
  //console.log('SetInstrumentListArr', SetInstrumentListArr.value);
  for (let i = 0; i < SetInstrumentListArr.value.length; i++) {
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetInstrumentListArr.value[i].lable) {
        if (SetInstrumentListArr.value[i].fixed != '') {
          toggleFixedColumn(j, SetInstrumentListArr.value[i].fixed)
        }
      }
    }
  }

  $table.refreshColumn()
  // //console.log('$table.getColumns :>> ', $table.getColumns());
  // SetTableHeader.value = false;
}

//  操作

// 处理出生日期的时间戳
const birthday = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD')
  }
})
// 处理注册时间戳
const createdAt = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD HH:mm')
  }
})
const currentPage = ref(1)
const pageSize = ref(15)
const totoalCount = ref(100)
const hanlerSizeChange = () => {
  fetchFullFaceList()
}
const handlerCurrnetChange = () => {
  fetchFullFaceList()
}
// mounted
onMounted(() => {
  //reqProjectList();
  fetchFullFaceList()
  centerList()
  console.log(faceDirection.value[1], 1111)
  // 初始话存储数据下
  //console.log('demo1.tableData :>> ', demo1.tableData);
  //处理点击更多操作盒子 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false
      }
    }
  })
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (isshowOperation.value == true) {
      if (e.target.id != 'operationBox') {
        isshowOperation.value = false
      }
    }
  })
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (isShow.value == true) {
      if (e.target.id != 'show_box') {
        isShow.value = false
      }
    }
    // 操作...
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false
      }
      // console.log(ClickMoreOptions.value);
    }

    // 批量操作
    if (BatchBox.value == true) {
      if (e.target.id != 'operationBox') {
        BatchBox.value = false
      }
      // console.log(ClickMoreOptions.value);
    }
  })
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false
      }
    }
  })
})

//note 组件销毁
onBeforeUnmount(() => {
  const table = xTable.value
  console.log('组件销毁时的数值 :>> ', table)
  const FixedList = table.getColumns()
  // console.log('销毁时的列顺序', FixedList);
  for (let i = 1; i < FixedList.length; i++) {
    for (let j = 0; j < SetInstrumentListArr.value.length; j++) {
      if (FixedList[i].field == SetInstrumentListArr.value[j].field) {
        SetInstrumentListArr.value[j].fixed = FixedList[i].fixed
      }
    }
  }

  localStorage.setItem('InstrumentTestRecord', JSON.stringify(SetInstrumentListArr.value))
})
</script>

<style lang="less" scoped>
@import url('@/style/components/Fullinstrument/vplusInstrument.less');
</style>
