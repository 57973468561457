<template>
  <div id="HomeView123456789">
    <div class="home">
      <div class="home_top">
        <div class="home_top_item">
          <div class="text_text_text">所有项目(项)</div>
          <div class="num_num_num">{{ ProjectDataBoardInfo.projectCount }}</div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">招募中的项目(项)</div>
          <div class="num_num_num">
            {{ ProjectDataBoardInfo.projectRecruiting }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">进行中的项目(项)</div>
          <div class="num_num_num">
            {{ ProjectDataBoardInfo.projectInprogress }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">已完成的项目(项)</div>
          <div class="num_num_num">
            {{ ProjectDataBoardInfo.projectFinished }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">已停止的项目(项)</div>
          <div class="num_num_num">{{ ProjectDataBoardInfo.projectAbort }}</div>
        </div>
      </div>
      <div class="home_dataProject">
        <div class="tableOutSide">
          <div class="project_box1">
            <div class="projectName_title">项目排期表</div>
            <div class="lookAllProjectTable" @click="lookAllTableData">查看完整排期表</div>
          </div>

          <div class="tableBox">
            <vxe-table
              v-show="demo1.tableData.length != 0"
              border
              ref="xTable"
              auto-resize
              :cell-class-name="cellClassName"
              show-overflow
              :loading="demo1.loading"
              :data="demo1.tableData"
              :tooltip-config="tooltipConfig"
              :column-config="{ resizable: true, useKey: 'id' }"
              :row-config="{
                isHover: true,
                keyField: 'id',
                height: linewidth <= 1280 ? 32 : 48,
                minHeight: 32,
                useKey: 'id',
              }"
              row-id="_id"
              :checkbox-config="{ reserve: true }"
              @header-cell-dragend="handleHeaderDragend">
              <vxe-column field="number" type="html" title="项目编号" width="140" :fixed="demo1.colFixeds.col1" min-width="120">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === '项目编号'">
                    <span class="name">项目编号</span>

                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('项目编号', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="name" type="html" title="项目名称" width="224" :fixed="demo1.colFixeds.col2" min-width="150"> </vxe-column>

              <vxe-column
                v-for="(item, index) in demo1.DateTitle"
                :key="index"
                :title="item"
                :field="'status' + index"
                type="html"
                width="60"
                :fixed="demo1.colFixeds.col3"
                min-width="58">
                <!-- 自定义项目状态的样式 -->
                <template #default="{ row }">
                  <div class="Status">
                    <span v-if="false">{{ row }}}</span>
                    <!-- <span class="Full" :class="row.data[index] ? 'Fulls' : 'notFulls'">{{}}</span> -->
                    <el-tooltip placement="top" show-after="300" v-if="getStatus(row, index)">
                      <template #content>
                        {{ '项目开始时间:' + dayjs(row.projectStart_time).format('YYYY-MM-DD') }}<br />{{
                          '项目结束时间:' + dayjs(row.projectEnd_time).format('YYYY-MM-DD')
                        }}
                      </template>
                      <span
                        class="Full"
                        :class="[
                          getStatus(row, index) ? 'Fulls' : '',
                          getStatus(row, index - 1) ? '' : 'Action',
                          getStatus(row, index + 1) ? '' : 'End',
                          getStatus(row, index + 1) != true && getStatus(row, index - 1) != true ? 'Midle' : '',
                        ]"></span>
                    </el-tooltip>
                  </div>
                </template>
              </vxe-column>

              <template #empty>
                <span style="color: red">
                  <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
                  <p>暂无数据</p>
                </span>
              </template>
            </vxe-table>

            <!--            v-show="dataTrendEchartsValArr.length == 0"-->
            <div class="noData" v-show="demo1.tableData.length == 0">暂无数据</div>
          </div>
        </div>

        <div class="data_project">
          <div class="project_head">
            <div class="head_title">我的项目</div>
            <div class="head_all" @click="allProjectChange">全部项目</div>
          </div>
          <div class="project_main" v-show="myProjectRes.length != 0">
            <div class="main_item" v-for="(item, index) in myProjectRes" :key="index">
              <div class="item_name">{{ item.name }}</div>
              <div class="item_status">
                <div
                  class="status_box"
                  :class="
                    item.status == '进行中'
                      ? 'status_progress'
                      : item.status == '已完成'
                      ? 'status_completed'
                      : item.status == '招募中'
                      ? 'status_Recruitment'
                      : 'status_incomplete'
                  ">
                  {{ item.status }}
                </div>
              </div>
              <div class="item_speed">
                <div class="speed">
                  <div class="speed_back" :style="{ width: item.progress + '%' }"></div>
                </div>
                <div class="speed_val">{{ item.progress }}%</div>
              </div>
              <div class="item_time">
                {{ item.projectStartTime.substring(0, 10) + '~' + item.projectEndTime.substring(0, 10) }}
              </div>
            </div>

            <!-- <div class="main_item">
              <div class="item_name">wodsdfsdfsdfsdfsdfsdfsfdsdfsdfsdfemz</div>
              <div class="item_status">
                <div class="status_box status_incomplete">weikais</div>
              </div>
              <div class="item_speed">
                <div class="speed">
                  <div class="speed_back" style="width: 10%"></div>
                </div>
                <div class="speed_val">10%</div>
              </div>
              <div class="item_time">12346656564</div>
            </div> -->
          </div>
          <div class="noData" v-if="myProjectRes.length == 0">暂无数据</div>
        </div>
      </div>
    </div>
    <div id="reset-password">
      <el-dialog v-model="resetPwd" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
        <template #header>修改初始密码</template>
        <template #default>
          <div class="content">
            <div class="title">为了您的账号安全，首次登录或重置密码后请修改初始密码</div>
            <div class="input-newPwd">
              <div class="input-item">
                <span>新密码</span>
                <div class="input_pwd">
                  <el-input
                    @input="pwd_verify"
                    :class="is_pwd_verify ? 'error_input' : ''"
                    v-model="new_pwd1"
                    type="password"
                    show-password
                    placeholder="请填写新密码" />
                </div>
              </div>
              <div class="pwd-length-hint">
                <span v-if="is_pwd_verify" class="error-hint">
                  {{ first_pwd_hint }}
                </span>
                <span v-else> 密码长度为8-16位，不少于8位;应至少包含数字、大小写字母及特殊字符中的两种 </span>
              </div>
              <div class="input-item">
                <span>确认密码</span>
                <div class="input_pwd">
                  <el-input
                    @input="sec_pwd_verify"
                    :class="is_pwd_verify2 ? 'error_input' : ''"
                    v-model="new_pwd2"
                    type="password"
                    show-password
                    placeholder="请再次输入新密码" />
                </div>
              </div>
              <div class="pwd-length-hint">
                <span v-if="is_pwd_verify2" class="error-hint">
                  {{ sec_pwd_hint }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <el-button type="primary" @click="submit"> 确定 </el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script setup>
import { VxeTableInstance } from 'vxe-table';
import dayjs from 'dayjs';

import { defineComponent, ref, reactive, onBeforeMount, onMounted, onBeforeUnmount } from 'vue';
import { Lock } from '@element-plus/icons-vue';
import { message } from '@/utils/resetMessage';
import http from '@/utils/http';
import * as echarts from 'echarts';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

const getCurrentMonth = () => {
  const now = new Date(); // 获取当前时间
  const year = now.getFullYear(); // 获取当前年份
  const month = now.getMonth() + 1; // 获取当前月份，注意月份是从0开始计数的，所以要加1
  return `${year}-${month}`; // 返回格式化后的当月字符串
};

const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
// 获取初始数据
const ReqFist = async () => {
  demo1.loading = true;
  const res = await http.post('/project/getProjectSchdule-project', {
    // content: SearchData.value.Content,
    // monthString: SearchData.value.DateValue,
    // monthString: "2023-7",
    monthString: getCurrentMonth(),

    // sort: SORT.value,
  });
  console.log('项目排期初始数据返回结果', res);
  if (res.status == 0) {
    demo1.tableData = res.schdules;

    demo1.loading = false;
  }
};
//查看完整排期表
const lookAllTableData = () => {
  router.push({
    name: 'ProjectSchedule',
  });
};

const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
    {
      number: '001',
      name: '4周小棕瓶',
      status0: true,
      status1: true,
      status2: true,
      status3: true,
      status4: true,
      status5: true,
      status6: true,
      status7: true,
      status8: false,
      status9: false,
      status10: false,
      status11: false,
      status12: false,
      status13: false,
      status14: true,
      status15: true,
      status16: true,
      status17: false,
      status18: false,
      status19: false,
      status20: false,
      status21: false,
      status22: false,
      status23: false,
      status24: true,
      status25: true,
      status26: true,
      status27: true,
      status28: true,
      status29: true,
    },
    {
      number: '002',
      name: '2周小棕瓶',
      status0: false,
      status1: false,
      status2: false,
      status3: false,
      status4: true,
      status5: true,
      status6: true,
      status7: true,
      status8: false,
      status9: false,
      status10: false,
      status11: false,
      status12: false,
      status13: false,
      status14: true,
      status15: true,
      status16: true,
      status17: false,
      status18: false,
      status19: false,
      status20: false,
      status21: false,
      status22: false,
      status23: false,
      status24: true,
      status25: true,
      status26: false,
      status27: false,
      status28: false,
      status29: false,
    },
    {
      number: '003',
      name: '3周小棕瓶',
      status0: false,
      status1: false,
      status2: false,
      status3: true,
      status4: true,
      status5: true,
      status6: true,
      status7: true,
      status8: false,
      status9: false,
      status10: false,
      status11: false,
      status12: false,
      status13: false,
      status14: true,
      status15: true,
      status16: true,
      status17: false,
      status18: false,
      status19: false,
      status20: false,
      status21: false,
      status22: false,
      status23: false,
      status24: false,
      status25: false,
      status26: false,
      status27: false,
      status28: false,
      status29: false,
    },
    {
      number: '004',
      name: '1周小棕瓶',
      status0: false,
      status1: false,
      status2: false,
      status3: false,
      status4: false,
      status5: false,
      status6: false,
      status7: false,
      status8: false,
      status9: false,
      status10: false,
      status11: true,
      status12: true,
      status13: true,
      status14: false,
      status15: false,
      status16: false,
      status17: false,
      status18: false,
      status19: false,
      status20: false,
      status21: false,
      status22: false,
      status23: false,
      status24: false,
      status25: true,
      status26: true,
      status27: true,
      status28: true,
      status29: true,
    },
    {
      number: '005',
      name: '5周小棕瓶',

      status0: false,
      status1: false,
      status2: false,
      status3: false,
      status4: false,
      status5: false,
      status6: false,
      status7: false,
      status8: false,
      status9: false,
      status10: false,
      status11: false,
      status12: false,
      status13: false,
      status14: true,
      status15: true,
      status16: true,
      status17: false,
      status18: false,
      status19: false,
      status20: false,
      status21: false,
      status22: false,
      status23: false,
      status24: true,
      status25: true,
      status26: false,
      status27: false,
      status28: false,
      status29: false,
    },
    {
      number: '006',
      name: '6周小棕瓶',

      status0: false,
      status1: false,
      status2: false,
      status3: false,
      status4: false,
      status5: false,
      status6: false,
      status7: false,
      status8: false,
      status9: false,
      status10: false,
      status11: false,
      status12: false,
      status13: false,
      status14: true,
      status15: true,
      status16: true,
      status17: true,
      status18: true,
      status19: true,
      status20: true,
      status21: true,
      status22: false,
      status23: false,
      status24: true,
      status25: true,
      status26: false,
      status27: false,
      status28: false,
      status29: false,
    },
  ],
  filterName: '',
  loading: false,
  list: [],
  week: '', //切换 0 日
  DateValue: [
    [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
      true,
      false,
      false,
      false,
      true,
      true,
    ],
    [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    [
      true,
      true,
      false,
      false,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
      true,
      false,
      false,
    ],
    [
      true,
      false,
      false,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    [
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      true,
      true,
      true,
    ],
  ],
  DateTitle: [],
});

const myProjectRes = ref([]);
// 获取我的项目数据
const getMyProject = async () => {
  let isSuper = false;
  const result = await http.post('/user/findInfo-user', {
    userId: localStorage.getItem('_id'),
  });
  console.log(result, '获取个人信息');
  if (result.user.role.includes('超级管理员')) {
    isSuper = true;
  }
  const res = await http.post('/dashboard/myproject', {
    userId: localStorage.getItem('_id'),
    isSuper: isSuper,
  });
  myProjectRes.value = res.myProject;
  console.log(res, '获取的我的项目的数据');
};

function getDayOfWeek(year, month, day) {
  // month参数从0开始，所以需要减去1
  let date = new Date(year, month, day);
  let dayOfWeek = date.getDay(); // 返回值为0（星期日）到6（星期六）
  return dayOfWeek;
}
// 获取每个单元格的值
function getStatus(row, index) {
  // console.log('row[status+index] :>> ', row['status' + index]);
  return row['status' + index];
}
// 示例用法
let year = 2023;
let month = 3; // 0表示1月，1表示2月，以此类推
let daysInMonth = new Date(year, month + 1, 0).getDate(); // 获取该月的天数

for (let day = 1; day <= daysInMonth; day++) {
  let dayOfWeek = getDayOfWeek(year, month, day);

  //   console.log(day + '号是星期' + dayOfWeek);
  switch (dayOfWeek) {
    case 1:
      demo1.week = '一';
      break;
    case 2:
      demo1.week = '二';
      break;
    case 3:
      demo1.week = '三';
      break;
    case 4:
      demo1.week = '四';
      break;
    case 5:
      demo1.week = '五';
      break;
    case 6:
      demo1.week = '六';
      break;
    case 0:
      demo1.week = '日';
      break;
    default:
      break;
  }
  //   console.log(day + '号是星期' + demo1.week);
  demo1.DateTitle.push(day + ' ' + demo1.week);
}

const cellClassName = ({ row, column }) => {
  // console.log("column :>> ", row, row[column.field]);
  // console.log("column :>> ", column);

  if (column.title.includes('日') || column.title.includes('六')) {
    return 'weedDay';
  }
  if (row[column.field] == true) {
    return 'FULLS';
  }
  return null;
};

//获取数据看板 数据
let ProjectDataBoardInfo = ref({});
const getProjectDataBoard = async () => {
  let res = await http.post('/dashboard/project-dashboard', {});
  console.log('项目数据看板res', res);
  ProjectDataBoardInfo.value = res.ProjectData;
};
onMounted(async () => {
  await getProjectDataBoard();
  await getMyProject();
  await ReqFist();

  // let myChart = echarts.init(document.getElementById("treadEcharts"));
  // // 绘制图表
  // myChart.setOption({
  //   xAxis: {
  //     type: "category",
  //     data: [
  //       "2023-12-03",
  //       "2023-12-03",
  //       "2023-12-31",
  //       "2023-12-13",
  //       "2023-12-23",
  //       "2023-12-11",
  //     ],
  //     // boundaryGap: false,
  //     axisLine: {
  //       show: false,
  //       lineStyle: {
  //         color: "#8F94B2",
  //       },
  //     },
  //     axisTick: {
  //       show: false,
  //     },
  //     axisLabel: {
  //       // interval: interval,
  //       // interval: 1,
  //       // axisLabel: {
  //       formatter: function (value) {
  //         return value.substring(5); // 提取从索引5开始的子字符串，即月-日部分
  //       },
  //       // },
  //     },
  //   },
  //   yAxis: {
  //     type: "value",
  //     axisLine: {
  //       show: false,
  //       lineStyle: {
  //         color: "#8F94B2",
  //       },
  //     },
  //     axisTick: {
  //       show: false,
  //     },
  //     splitLine: {
  //       show: true,
  //       lineStyle: {
  //         // type: 'dashed',
  //       },
  //     },
  //   },
  //   tooltip: {
  //     show: true,
  //     trigger: "axis",
  //     // 自定义显示
  //     // formatter: '{b0}<br />新增用户数   {c0} 个',
  //     formatter: function (params) {
  //       // console.log(params[0].value);
  //       return (
  //         params[0].name +
  //         "<br />新增用户数&nbsp; " +
  //         params[0].value +
  //         "&nbsp;个&nbsp;"
  //       );
  //     },
  //   },
  //   series: [
  //     {
  //       name: "新增用户",
  //       type: "line",
  //       data: [5, 20, 36, 10, 10, 20],
  //       areaStyle: { type: "default" },
  //       smooth: true,
  //       itemStyle: {
  //         color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
  //           { offset: 0, color: "rgba(0,74,218, 0.5)" },
  //           { offset: 0.5, color: "rgba(0,74,218, 0.3)" },
  //           { offset: 1, color: "rgba(0,74,218, 0.1)" },
  //         ]),
  //         borderColor: "#F19195",
  //         borderWidth: 10,
  //         borderType: "solid",
  //       },
  //       showAllSymbol: "auto",
  //       symbolSize: 10,
  //       //折线拐点的类型
  //       // symbol: 'star',
  //       // 改变线条样式 lineStyle
  //       lineStyle: {
  //         color: "#004ADA",
  //         type: "solid",
  //         with: 2,
  //       },
  //     },
  //   ],
  // });
  // window.onresize = function () {
  //   //自适应大小
  //   myChart.resize();
  // };
});

// 点击全部项目进入到项目列表页
const allProjectChange = () => {
  router.push({
    name: 'ProjectList',
  });
};

// 数据趋势头部导航数组
const dataTrendArr = ref(['项目', '志愿者', '仪器', '问卷', '样品', '专家']);
// 数据趋势头部导航数组选中的值
const dataTrendArrIndex = ref(1);
// 点击头部导航选择展示的数据
const dataTrendArrIndexChange = (val) => {
  dataTrendArrIndex.value = val;
};
// 数据趋势日期数据
const dataTrenTime = ref([]);
// 数据趋势日期数据触发事件
const dataTrenTimeChange = (val) => {
  console.log(val);
};
// 数据趋势 二级导航数据
const dataTrenitemArr = ref([[], ['新增志愿者人数', '累计志愿者人数', '新增志愿者报名人次', '累计志愿者报名人次']]);
// 选中数据趋势二级导航的值
const dataTrenitemArrIndex = ref(0);
// 数据趋势 二级导航数据选中的事件
const dataTrenitemArrChange = (val) => {
  dataTrenitemArrIndex.value = val;
  console.log(val);
};

onBeforeMount(() => {});
</script>

<style lang="less">
@import url('@/style/views/ProjectManagement/ProjectManagement_DataBoard.less');

// #reset-password {
//   .el-dialog {
//     width: 650px;
//     .el-dialog__header {
//       text-align: left;
//       padding: 12px 24px;
//       margin: 0;
//       font-size: 16px;
//       font-family: Source Han Sans CN-Medium, Source Han Sans CN;
//       font-weight: 500;
//       color: #313233;
//       border-bottom: 1px #bfbfbf solid;
//     }
//     .el-dialog__body {
//       padding: 8px 24px 8px 20px;
//       .content {
//         .title {
//           font-size: 14px;
//           font-family: Source Han Sans CN-Regular, Source Han Sans CN;
//           font-weight: 400;
//           color: #626366;
//           text-align: left;
//           margin-bottom: 20px;
//         }
//         .input-newPwd {
//           .input-item {
//             display: flex;
//             height: 36px;
//             align-items: center;
//             & > span {
//               font-size: 14px;
//               font-family: Source Han Sans CN-Regular, Source Han Sans CN;
//               font-weight: 400;
//               color: #626366;
//               width: 75px;
//               flex-grow: 1;
//               text-align: right;
//               margin-right: 6px;
//             }
//             .input_pwd {
//               .el-input {
//                 width: 528px;
//                 height: 36px;
//                 background: #ffffff;
//                 .el-input__wrapper {
//                   box-shadow: none;
//                   border: 1px solid #bfbfbf;
//                   border-radius: 2px;
//                   height: 36px;
//                 }
//                 .el-icon {
//                   width: 20px;
//                   height: 20px;
//                   svg {
//                     width: 20px;
//                     height: 20px;
//                   }
//                 }
//                 .el-input__wrapper.is-focus {
//                   border: 1px #027aff solid;
//                   .el-input__prefix {
//                     .el-icon {
//                       color: #027aff;
//                     }
//                   }
//                 }
//               }
//               // 报错提示
//               .error_input {
//                 .el-input__wrapper {
//                   border: 1px solid #ff3200;
//                   &.is-focus {
//                     border: 1px solid #ff3200;
//                     .el-input__prefix {
//                       .el-icon {
//                         color: #ff3200;
//                       }
//                     }
//                   }
//                   .el-input__prefix {
//                     .el-icon {
//                       color: #ff3200;
//                     }
//                   }
//                 }
//               }
//             }
//           }
//           .pwd-length-hint {
//             min-height: 20px;
//             font-size: 14px;
//             font-family: Source Han Sans CN-Regular, Source Han Sans CN;
//             font-weight: 400;
//             color: #bfbfbf;
//             line-height: 22px;
//             text-align: left;
//             display: flex;
//             margin-left: 78px;
//             margin-bottom: 12px;
//           }
//         }
//         // 错误提示语
//         .error-hint {
//           font-size: 14px;
//           font-family: Source Han Sans CN-Regular, Source Han Sans CN;
//           font-weight: 400;
//           color: #ff3200;
//           line-height: 20px;
//         }
//       }
//     }
//     .el-dialog__footer {
//       .el-button {
//         width: 80px;
//         height: 36px;
//         background: #027aff;
//         border-radius: 2px;
//         span {
//           font-size: 14px;
//           font-family: Source Han Sans CN-Regular, Source Han Sans CN;
//           font-weight: 400;
//           color: #ffffff;
//           line-height: 22px;
//         }
//       }
//     }
//   }
// }
</style>
