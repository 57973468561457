<!--
 * @Author: ZhengJianYang
 * @Date: 2023-05-29 11:44:34
 * @LastEditors: ZhengJianYang
 * @LastEditTime: 2023-07-21 17:16:26
 * @Description: 新建仪器 抽屉组件
-->
<template>
  <div id="lllsss" class="lllsss">
    <el-drawer
      :visible="false"
      :model-value="is_dialogShow"
      :show-close="false"
      :close-on-click-modal="false"
      title="I am the title"
      class="volunteer_dialogss"
      :modal="true">
      <template #header>
        <div class="volunteer_title">
          <span v-if="props.Change.length == 0">新建仪器</span>

          <span v-if="props.Change.length != 0">仪器信息</span>
        </div>
      </template>
      <template #default>
        <div class="volunteer_main">
          <!-- 仪器基础信息 -->
          <div class="main_info">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">仪器基础信息</div>
            </div>
            <div class="info_main">
              <!-- 仪器编号 -->
              <div class="info_l1">
                <div class="l_text">
                  <span class="text_top">*</span>
                  仪器编号
                </div>
                <div class="l_main">
                  <el-input
                    v-model="inputDeviceNumber"
                    class="l_inp"
                    placeholder="请输入仪器编号"
                    suffix-icon=""
                    :class="errObj.inputDeviceNumber ? 'inp_err' : ''"
                    @focus="errObj.inputDeviceNumber = false" />
                  <div class="err_text">
                    <span v-if="errObj.inputDeviceNumber">{{ errNumber }}</span>
                  </div>
                </div>
              </div>
              <!-- 仪器名称 -->
              <div class="info_r info_r2">
                <div class="r_text">
                  <span class="text_top">*</span>
                  <span>仪器名称</span>
                </div>
                <div class="r_main">
                  <el-input
                    v-model="inputDeviceName"
                    class="r_select"
                    placeholder="请输入仪器名称"
                    suffix-icon=""
                    :class="errObj.inputDeviceName ? 'inp_err' : ''"
                    @focus="errObj.inputDeviceName = false" />

                  <div class="err_text">
                    <span v-if="errObj.inputDeviceName">{{ errName }}</span>
                  </div>
                </div>
              </div>
              <!-- 仪器状态 -->
              <div class="info_l">
                <div class="l_text">
                  <span class="text_top">*</span>
                  仪器状态
                </div>
                <div class="l_main">
                  <el-select
                    v-model="selectDeviceStatus"
                    class="l_inp"
                    placeholder="请选择"
                    size="large"
                    @change="deviceStatusValueChange"
                    :class="errObj.selectDeviceStatus ? 'inp_err' : ''"
                    @focus="errObj.selectDeviceStatus = false">
                    <el-option v-for="item in deviceStatusAll" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                  <div class="err_text">
                    <span v-if="errObj.selectDeviceStatus">仪器状态不可为空</span>
                  </div>
                </div>
              </div>
              <!-- 仪器简称 -->
              <div class="info_r">
                <div class="phone_text r_text">
                  <!-- <span class="text_top">*</span> -->
                  仪器简称
                </div>
                <div class="r_main">
                  <el-input
                    v-model="inputDeviceAbbreviation"
                    class="r_inp"
                    placeholder="请输入仪器简称"
                    suffix-icon=""
                    maxlength="20"
                    :class="errObj.phone ? 'inp_err' : ''"
                    @focus="errObj.phone = false" />
                  <div class="err_text">
                    <span v-if="errObj.phone">{{ phone_error }}</span>
                  </div>
                </div>
              </div>
              <!-- 仪器组别 -->
              <div class="info_l">
                <div class="l_text">
                  <!-- <span class="text_top">*</span> -->
                  仪器组别
                </div>
                <div class="l_main">
                  <el-select
                    v-model="selectDeviceGroup"
                    class="l_inp"
                    placeholder="请选择"
                    size="large"
                    @change="deviceGroupValueChange"
                    :class="errObj.sex ? 'inp_err' : ''"
                    @focus="errObj.sex = false">
                    <el-option
                      v-for="item in deviceGroupAll"
                      :key="item"
                      :label="item"
                      :value="item"
                      :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                  </el-select>

                  <div class="err_text">
                    <span v-if="errObj.referrer">组别不可为空</span>
                  </div>
                </div>
              </div>
              <!-- 测量范围 -->
              <div class="info_r">
                <div class="r_text">测量范围</div>
                <div class="r_main" :class="errObj.date ? 'inp_err' : ''">
                  <!-- <el-date-picker
                  class="l_picker"
                  v-model="selectMeasuringRange"
                  type="date"
                  placeholder="请选择测量范围"
                  format="YYYY-MM-DD"
                  value-format="YYYY-MM-DD"
                  @focus="errObj.date = false" /> -->

                  <el-input
                    maxlength="20"
                    v-model="selectMeasuringRange"
                    class="r_inp"
                    placeholder="请输入测量范围"
                    suffix-icon=""
                    :class="errObj.phone ? 'inp_err' : ''"
                    @focus="errObj.phone = false" />
                </div>
              </div>
              <!-- 仪器类型 -->
              <div class="info_type">
                <div class="l_text">
                  <span class="text_top">*</span>
                  仪器类型
                </div>
                <div class="l_main">
                  <el-select
                    v-model="selectType"
                    class="l_inp"
                    placeholder="请选择"
                    size="large"
                    @change="selectTypeChange"
                    filterable
                    :class="errObj.selectType ? 'inp_err' : ''"
                    @focus="errObj.selectType = false">
                    <el-option v-for="item in selectTypeArr" :key="item.label" :label="item.label" :value="item.value" />
                  </el-select>
                  <div class="err_text">
                    <span v-if="errObj.selectType">仪器类型不可为空</span>
                  </div>
                </div>
              </div>
              <!-- 测量原理 -->
              <div class="info_r">
                <div class="r_text2">测量原理</div>
                <div class="r_main2" :class="errObj.date ? 'inp_err' : ''">
                  <el-input
                    v-model="inputMeasuringPrinciple"
                    class="r_inp2"
                    type="textarea"
                    resize="none"
                    placeholder="请输入测量原理"
                    suffix-icon=""
                    :class="errObj.phone ? 'inp_err' : ''"
                    @focus="errObj.phone = false" />
                </div>
              </div>

              <!-- 参数解释 -->
              <div class="info_r22">
                <div class="r_text2">参数解释</div>
                <div class="r_main2" :class="errObj.date ? 'inp_err' : ''">
                  <el-input
                    v-model="inputParameterInterpretation"
                    class="r_inp2"
                    type="textarea"
                    resize="none"
                    placeholder="请输入参数解释"
                    suffix-icon=""
                    :class="errObj.phone ? 'inp_err' : ''"
                    @focus="errObj.phone = false" />
                </div>
              </div>

              <!-- 生产厂家 -->
              <div class="info_l22">
                <div class="l_text">
                  <!-- <span class="text_top">*</span> -->
                  生产厂家
                </div>
                <div class="l_main">
                  <el-input v-model="inputManufacturer" maxlength="20" class="l_inp" placeholder="请输入生产厂家" suffix-icon="" />
                  <!-- <div class="err_text">
                  <span v-if="errObj.phone">{{ phone_error }}</span>
                </div> -->
                </div>
              </div>

              <!-- 出厂编号 -->
              <div class="info_r">
                <div class="r_text">
                  <!-- <span class="text_top">*</span> -->
                  出厂编号
                </div>
                <div class="r_main">
                  <el-input v-model="inputFactoryNumber" maxlength="20" class="r_inp" placeholder="请输入出厂编号" suffix-icon="" />
                  <!-- <div class="err_text">
                  <span v-if="errObj.phone">{{ phone_error }}</span>
                </div> -->
                </div>
              </div>

              <!-- 规格型号 -->
              <div class="info_l22">
                <div class="l_text">
                  <!-- <span class="text_top">*</span> -->
                  规格型号
                </div>
                <div class="l_main">
                  <el-input v-model="inputSpecificationModel" maxlength="20" class="l_inp" placeholder="请输入规格型号" suffix-icon="" />
                  <!-- <div class="err_text">
                  <span v-if="errObj.phone">{{ phone_error }}</span>
                </div> -->
                </div>
              </div>

              <!-- 溯源方式 -->
              <div class="info_r">
                <div class="r_text">
                  <!-- <span class="text_top">*</span> -->
                  溯源方式
                </div>
                <div class="r_main">
                  <el-input v-model="inputTraceabilityMethod" maxlength="20" class="r_inp" placeholder="请输入溯源方式" suffix-icon="" />
                  <!-- <div class="err_text">
                  <span v-if="errObj.phone">{{ phone_error }}</span>
                </div> -->
                </div>
              </div>

              <!-- 仪器管理员 -->
              <div class="info_l22">
                <div class="l_text">
                  <!-- <span class="text_top">*</span> -->
                  仪器管理员
                </div>
                <div class="l_main">
                  <el-select
                    v-model="selectInstrumentAdministrator"
                    class="l_inp"
                    placeholder="请选择"
                    size="large"
                    @change="selectInstrumentAdministratorValueChange">
                    <el-option v-for="item in selectInstrumentAdministratorAll" :key="item.name" :label="item.name" :value="item._id" />
                  </el-select>

                  <!-- <div class="err_text">
                  <span v-if="errObj.phone">{{ phone_error }}</span>
                </div> -->
                </div>
              </div>

              <!-- 所属实验室 -->
              <div class="info_r">
                <div class="r_text">
                  <!-- <span class="text_top">*</span> -->
                  所属实验室
                </div>
                <div class="r_main">
                  <!-- <el-input
                  v-model="inputTraceabilityMethod"
                  class="l_inp"
                  placeholder="请输入溯源方式"
                  suffix-icon=""
                  :class="errObj.phone ? 'inp_err' : ''"
                  @focus="errObj.phone = false" /> -->

                  <el-select
                    v-model="selectAffiliatedLaboratory"
                    class="r_inp"
                    placeholder="请选择"
                    size="large"
                    @change="selectAffiliatedLaboratoryValueChange">
                    <el-option v-for="item in selectAffiliatedLaboratoryAll" :key="item.value" :label="item.label" :value="item.label" />
                  </el-select>

                  <!-- <div class="err_text">
                  <span v-if="errObj.phone">{{ phone_error }}</span>
                </div> -->
                </div>
              </div>

              <!-- 备注 -->
              <div class="info_r">
                <div class="r_text2">备注</div>
                <div class="r_main3">
                  <el-input maxlength="20" type="text" v-model="Remark" class="r_inp2" placeholder="请输入备注" suffix-icon="" />
                </div>
              </div>
            </div>
          </div>
          <!-- 仪器图片 -->
          <div class="main_info22">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">仪器图片<span class="moreFivePic">最多上传5张图片</span></div>
            </div>
            <div class="info_main">
              <div class="info-item">
                <div v-for="(imgUrl, index) in imgsFileArr" :key="index" class="img">
                  <img class="deleteIcon" @click="deleteImage(index)" src="https://newstore.vynior.com/cems_web/1%E9%94%99%E8%AF%AF%402x.png" />
                  <img class="showImg" :src="imgUrl" />
                </div>
              </div>
              <div class="uploadImgBox">
                <el-upload
                  action="https://cems.fuhuan.ltd/api/upload/file-upload"
                  class="Upload"
                  :limit="5"
                  v-if="imgsArr.length < 5"
                  multiple
                  :headers="{ Authorization: access_token }"
                  list-type="picture-card"
                  :accept="'.png,.jpg,jpeg'"
                  :on-preview="handlePictureCardPreview"
                  :data="{ type: 'image' }"
                  :on-success="handleAvatarSuccess"
                  :on-remove="handleRemove"
                  :show-file-list="false">
                  <el-icon><Plus /></el-icon>
                </el-upload>

                <!-- <el-upload
                                    v-if="imgsArr.length < 5"
                                    limit="5"
                                    class="Upload"
                                    ref="videoRef"
                                    :http-request="uploadsFile"
                                    :show-file-list="false"
                                    :disabled="operate_state">
                                    <img src="https://newstore.vynior.com/cems_web/%E4%B8%8A%E4%BC%A0%402x.png" alt="" />
                                    <span>上传附件 </span>
                                </el-upload> -->
              </div>
              <!-- 
              <el-dialog v-model="dialogVisible">
                <img w-full :src="dialogImageUrl" alt="预览图片" />
              </el-dialog> -->
            </div>
          </div>

          <!-- 上传附件 -->
          <div class="main_info">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">文件<span class="more-20MB">单个文件不能超过20MB</span></div>
            </div>
            <div class="info_main">
              <!--                          multiple
                                :on-success="onSuccess"
                                :before-upload="handleBeforeUpload" -->
              <!-- <div class="uploadBox"> -->
              <div class="files">
                <el-upload :limit="30" :http-request="uploadsFile" :show-file-list="false">
                  <!-- <el-button type="primary" plain class="uploadIconBox"> -->
                  <img class="uploadIcon" src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%E7%AE%AD%E5%A4%B4%402x.png" alt="" />
                  <span>上传附件</span>
                  <!-- </el-button> -->
                </el-upload>
              </div>
              <!-- </div> -->
            </div>
            <div class="Table" v-show="filesArr?.length">
              <el-table :data="uploadTableData" border stripe style="width: 1032px">
                <el-table-column type="index" label="序号" width="56" min-width="55" />
                <el-table-column prop="fileName" label="文件名称" width="470" min-width="469">
                  <template #default="scope">
                    <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                      {{ scope.row.fileName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="upLoadTime" label="上传时间" width="208" min-width="207">
                  <template #default="scope">
                    <div style="color: #626366">
                      {{ scope.row.upLoadTime }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="300" min-width="299">
                  <template #default="scope">
                    <!-- <el-button link type="primary" size="small">预览</el-button> -->
                    <el-button
                      link
                      type="primary"
                      @click="downloadTheFiles(scope.row)"
                      size="small"
                      style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                      >下载</el-button
                    >
                    <el-button
                      link
                      type="primary"
                      @click="deleteTheFiles(scope.row)"
                      size="small"
                      style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- <el-table
              :data="uploadTableData"
              style="width: 100%; margin-top: 11px"
              :header-cell-style="{

                'font-size': '16px',
                background: '#F8F8F8 !important',
                color: '#313233',
                'font-weight': 400,
                border: '1px solid #eeeeee !important',
              }"
              :cell-style="{
                'font-size': '16px',
              
                color: '#313233',
                'font-weight': 400,
                border: '1px solid #eeeeee !important',
              }">
              <el-table-column label="序号" width="70">
                <template #default="scope">
                  <div style="text-align: center">{{ scope.$index + 1 }}</div>
      
                </template>
              </el-table-column>
              <el-table-column label="文件名称" width="380">
                <template #default="scope">
                  <div style="color: #027aff">{{ scope.row.fileName }}</div>
                </template>
              </el-table-column>
              <el-table-column label="上传时间" width="350">
                <template #default="scope">
                  <div style="color: #626366">{{ scope.row.upLoadTime }}</div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="205">
                <template #default="scope">
                  <div style="display: flex; justify-content: space-around; color: #027aff">
                    <div @click="downloadTheFiles(scope.row.data)" style="cursor: pointer">
                      {{ scope.row.action[0] }}
                    </div>
                    <div @click="deleteTheFiles(scope.row)" style="cursor: pointer">
                      {{ scope.row.action[1] }}
                    </div>
                  </div>
                </template>
              </el-table-column>
            </el-table> -->
          </div>

          <!-- 仪器校准信息 -->
          <div class="main_info">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">仪器校准信息</div>
            </div>
            <div class="info_main">
              <div class="info-item">
                <div class="item-title">校准日期</div>
                <div class="item-content">
                  <el-date-picker
                    class="l_picker"
                    v-model="selectFirstCalibrationDate"
                    type="date"
                    placeholder=""
                    @change="getOneTimeValue"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    @focus="errObj.date = false" />
                </div>
              </div>
              <div class="info-item">
                <div class="item-title">校验周期</div>
                <div class="item-content">
                  <el-select v-model="verificationCycle" placeholder="请选择" @change="verificationCycleValueChange">
                    <el-option v-for="item in verificationCycleAll" :key="item.value" :label="item.label" :value="item.label" />
                  </el-select>
                </div>
              </div>
              <div class="info-item">
                <div class="item-title">下次校准日期</div>
                <div class="item-content">
                  <el-date-picker
                    class="l_picker"
                    v-model="selectSecondCalibrationDate"
                    disabled
                    type="date"
                    placeholder=""
                    @change="getSeconTimedValue"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    @focus="errObj.date = false" />
                </div>
              </div>
              <div class="info-item">
                <div class="item-title">校准提醒</div>
                <div class="item-content2">
                  <div class="info_main_item">
                    <!--  main_item_buts-->
                    <div class="main_item_but" :class="butInfoValueArr[0] == 1 ? 'item_but_back' : ''" @click.stop="infoChange(1)">
                      <div v-if="butInfoValueArr[0] == 1" class="item_but_back"></div>
                      <!-- <img  src="" alt=""> -->
                    </div>
                    <div class="main_item_text">不提醒</div>
                  </div>

                  <div class="info_main_item">
                    <!-- main_item_buts -->
                    <div class="main_item_but" :class="butInfoValueArr[0] == 2 ? 'item_but_back' : ''" @click.stop="infoChange(2)">
                      <div v-if="butInfoValueArr[0] == 2" class="item_but_back"></div>
                      <!-- <img  src="" alt=""> -->
                    </div>
                    <div class="main_item_text">提醒</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 仪器维护信息 -->
          <div class="main_info">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">仪器维护信息</div>
            </div>

            <!-- <template> -->
            <div class="Table1">
              <el-table :data="tableData" border stripe style="width: 1032px">
                <el-table-column type="index" prop="index" label="序号" width="65" min-width="50" />
                <el-table-column label="维护原因" width="448" min-width="440">
                  <template #default="scope">
                    <el-input class="resonInp" placeholder="请输入维护原因" maxlength="20" v-model="scope.row.reason" />
                    <!-- <div>{{ scope.row }}</div> -->
                  </template>
                </el-table-column>
                <el-table-column label="维护时间段" width="356" min-width="350">
                  <template #default="scope">
                    <el-date-picker
                      class="timeInp"
                      v-model="scope.row.time"
                      type="daterange"
                      range-separator="-"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      format="YYYY-MM-DD"
                      value-format="YYYY-MM-DD" />
                  </template>
                </el-table-column>
                <el-table-column label="维护负责人" width="172" min-width="170">
                  <template #default="scope">
                    <el-input class="personInp" placeholder="请输入" maxlength="20" v-model="scope.row.director" />
                    <!-- <div>{{ scope.row }}</div> -->
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <!-- <el-table
              :data="tableData"
              style="width: 100%; margin-top: 11px"
              :header-cell-style="{
            

                'font-size': '16px',
                background: '#F8F8F8 !important',
                color: '#313233',
                'font-weight': 400,
                border: '1px solid #eeeeee !important',
              }"
              :cell-style="{
                'font-size': '16px',
               
                color: '#313233',
                'text-align': 'center',
                'font-weight': 400,
                border: '1px solid #eeeeee !important',
              }">
              <el-table-column label="序号" width="70">
                <template #default="scope">
                  <div>{{ scope.row.index }}</div>
               
                </template>
              </el-table-column>
              <el-table-column label="维护原因" width="380">
                <template #default="scope">
                  <el-input
                    maxlength="20"
                    v-model="scope.row.reason"
                    class="l_inp"
                    placeholder="请输入维护原因"
                    suffix-icon=""
                    :class="errObj.phone ? 'inp_err' : ''"
                    @focus="errObj.phone = false" />
                </template>
              </el-table-column>
              <el-table-column label="维护时间段" width="356">
                <template #default="scope">
                  <el-date-picker
                    @change="changeDataValue(scope.row)"
                    class="l_picker2"
                    style="
                      .el-date-editor.el-input__wrapper {
                        width: 328px;
                      }
                    "
                    v-model="scope.row.time"
                    type="daterange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    @focus="errObj.date = false" />
                </template>
              </el-table-column>
              <el-table-column label="维护联系人" width="205">
                <template #default="scope">
               

                  <el-input
                    maxlength="20"
                    v-model="scope.row.director"
                    class="l_inp"
                    placeholder="请输入"
                    suffix-icon=""
                    :class="errObj.sex ? 'inp_err' : ''"
                    @focus="errObj.sex = false" />


                </template>
              </el-table-column>
            </el-table> -->
            <!-- </template> -->
            <div class="addDeviceInfo" @click="addDeviceInfo">新增维护信息</div>
          </div>

          <!-- 文件 -->
        </div>
      </template>
      <template #footer>
        <div style="flex: auto">
          <el-button @click="handleClose">取消</el-button>
          <el-button v-if="props.Change.length == 0" type="primary" @click="createdUser">确定</el-button>
          <el-button v-if="props.Change.length != 0" type="primary" @click="ChangeMsg">确定</el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>
<script setup>
import Dates from '@/utils/CurrentDate'

import http from '@/utils/http'
import { message } from '@/utils/resetMessage'
import { Plus } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import { defineEmits, defineProps, onMounted, reactive, ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

let access_token = ref('Bearer' + ' ' + localStorage.getItem('access_token'))
let filesArr = ref([])
// 上传附件返回信息
const attachmentList = ref([])
// 上传附件事件
const uploadsFile = async (params) => {
  //                                 action="https://cems.fuhuan.ltd/api/upload/file-upload"

  // filesArr.value.push(uploadFiles.file);

  console.log('parmas :>> ', params)
  const file = params.file
  console.log('file :>> ', file)
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'attachment')
  const response = await http.post('/upload/file-upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  console.log('response :>> ', response)
  const { status, file: fileData } = response
  if (status === 0) {
    attachmentList.value.push(fileData)
    filesArr.value.push(fileData)
    console.log('发给后端的filesArr数组', filesArr.value)

    const t = formatTime(response.file.uploadTime)
    console.log('t', t) // 2023-05-16 14:57
    let obj = {
      id: uploadTableDeviceIndex.value++,
      fileName: response.file.fileName,
      upLoadTime: t,
      data: response.file,
      // action: ["预览", "下载", "删除"],
      action: ['下载', '删除'],
    }
    uploadTableData.value.push(obj)

    message({
      message: '附件上传成功',
      // showClose: true,
      showClose: true,
      type: 'success',
    })
  } else {
    message({
      message: '附件上传失败，请稍后再试',
      // showClose: true,
      showClose: true,
      type: 'warning',
    })
  }
}

const emits = defineEmits(['update:modelValue'])
//关闭的点击事件
const handleClose = () => {
  emits('update:modelValue', false)
  // is_dialogShow.value = false
}
const formatTime = (timeString) => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')
  const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return currentDateTime
}

//附件--下载

const downloadTheFiles = async (data) => {
  console.log('附件-下载-data', data)
  console.log('点击了附件下载')
  const { downloadPath, filePath } = data

  const repsonse = await http.post(
    'https://cems.fuhuan.ltd/api/upload/attachmentDownload',
    { downloadPath: downloadPath ? downloadPath : filePath },
    { responseType: 'arraybuffer' }
  )

  const blob = new Blob([repsonse], {
    type: 'application/x-zip-compressed;charset=utf-8',
  })
  let name = '附件下载.zip'
  const downloadElement = document.createElement('a')
  let classificationhref = window.URL.createObjectURL(blob)
  downloadElement.href = classificationhref
  downloadElement.download = `${name}`
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(classificationhref)
}

//附件--删除

const deleteTheFiles = (data) => {
  console.log('点击了附件删除data', data.fileName)
  console.log(filesArr.value[0].File, 'filesArr')
  // const fileDeleteIndex = filesArr.value.findIndex((item) => {
  //     console.log('item is ', item);
  //     item.name == data.fileName;
  // });
  let fileDeleteIndex = ''
  filesArr.value.forEach((item, index) => {
    if (item.name == data.fileName) {
      console.log('12312312')
      fileDeleteIndex = index
    }
  })
  console.log('fileDeleteIndex', fileDeleteIndex)
  if (fileDeleteIndex > -1) {
    filesArr.value.splice(fileDeleteIndex, 1)
    console.log('附件删除后的数组', filesArr.value)

    const deleteWhichItem = uploadTableData.value.findIndex((item22) => item22.id === data.id)
    if (deleteWhichItem > -1) {
      uploadTableData.value.splice(deleteWhichItem, 1)
      console.log('uploadTableData删除成功')
      console.log('uploadTableData删除成功后的uploadTableData', uploadTableData.value)

      //To Do  删除成功提示   别忘了
      ElMessage({
        showClose: true,
        message: '删除成功',
        type: 'success',
      })
    }
  }
}

//上传附件 上传成功回调
const onSuccess = (uploadFiles, uploadFiles2) => {
  // console.log('uploadFiles', uploadFiles);
  // console.log('uploadFiles.file', uploadFiles.file);

  // filesArr.value.push(uploadFiles.file);
  // console.log('附件上传成功后的file数组', filesArr.value);

  // console.log('uploadFiles2', uploadFiles2);

  const {
    name,
    raw: { lastModifiedDate },
    response: { file },
  } = uploadFiles2

  console.log('name', name)
  console.log('lastModifiedDate', lastModifiedDate)
  console.log('file', file)
  const t = formatTime(lastModifiedDate)
  console.log('t', t) // 2023-05-16 14:57
  let obj = {
    id: uploadTableDeviceIndex.value++,
    fileName: name,
    upLoadTime: t,
    // data: file,
    // action: ["预览", "下载", "删除"],
    action: ['下载', '删除'],
  }
  uploadTableData.value.push(obj)
}
const maxFileSize = ref(20 * 1024 * 1024) // 限制最大文件大小为 20MB
// 文件上传 之前的 回调  用于判断文件大小是否超过 20mb
const handleBeforeUpload = (file) => {
  console.log('file上传之222前', file)
  if (file.size > maxFileSize.value) {
    console.log('当前上传的文件超过20mb，阻止上传', file.size)
    ElMessage({
      showClose: true,
      message: '当前上传的文件超过20mb，请重新选择',
      type: 'error',
    })
    return false // 返回 false 阻止文件上传
  }
  console.log('当前上传的文件符合要求，允许上传', file.size)

  return true // 允许文件上传
}

//表格 序号  维护序列1、2、3、4
let uploadTableDeviceIndex = ref(1)
const uploadTableData = ref([])

//表格 序号  维护序列1、2、3、4
let tableDeviceIndex = ref(1)

//   仪器维护信息
const tableData = ref([
  {
    index: tableDeviceIndex.value,
    reason: '',
    startDate: '',
    endDate: '',
    director: '',
  },
])
//点了新增维护信息
const addDeviceInfo = () => {
  console.log('点了新增维护信息')

  let obj1 = {
    index: ++tableDeviceIndex.value,
    reason: '',
    startDate: '',
    endDate: '',
    director: '',

    // Alldirector: [
    //   { label: "负责人1", value: 1 },
    //   { label: "负责人2", value: 2 },
    // ],
  }
  tableData.value.push(obj1)
}

//新建志愿者显示
const is_dialogShow = ref(true)
// 仪器编号
let inputDeviceNumber = ref('')

//测量原理
let inputMeasuringPrinciple = ref('')

//参数解释
let inputParameterInterpretation = ref('')

//仪器组别
let selectDeviceGroup = ref('')
//   仪器组别  所有选项
let deviceGroupAll = ref([])
// 仪器类型选中的值
const selectType = ref('')
// 仪器类型的可选项
const selectTypeArr = ref([])
// 仪器类型触发事件
const selectTypeChange = (val) => {
  console.log(val)
}
const getTypeRes = async () => {
  const res = await http.post('/instrument/get-instrumentAllType')
  console.log(res, '获取全部类型可选项')
  selectTypeArr.value = res.instrumentTypes
}
// // 类型搜索事件
// const remoteMethod = async (query) => {
//   console.log(query);
//   const
// };
//仪器组别  选中的值
//   let selectDeviceGroup = ref('');
//仪器组别 选中事件
const deviceGroupValueChange = (val) => {
  // console.log(val);
  selectDeviceGroup.value = val
}

//   维护时间段  二次修改

const changeDataValue = (obj) => {
  console.log('obj1', obj)
  obj.start_time = obj.time[0]
  obj.end_time = obj.time[1]
  console.log('obj2', obj)
}

//   仪器状态  所有选项
let deviceStatusAll = ref([
  { label: '正常', value: '正常' },
  { label: '维护中', value: '维护中' },
  { label: '待维护', value: '待维护' },
  { label: '报废', value: '报废' },
])
//仪器状态  选中的值
let selectDeviceStatus = ref('')
//仪器状态 选中事件
const deviceStatusValueChange = (val) => {
  // console.log(val);
  selectDeviceStatus.value = val
}

//仪器名称
let inputDeviceName = ref('')
//仪器简称
let inputDeviceAbbreviation = ref('')

//生产厂家
let inputManufacturer = ref('')

//规格型号
let inputSpecificationModel = ref('')

//  检查    校准日期  和    下次校准日期   的合法性
const compareDates = (date1, date2) => {
  console.log(date1, date2)

  // 将输入的字符串类型时间转换为时间戳
  var timestamp1 = Date.parse(date1)
  var timestamp2 = Date.parse(date2)

  // 比较两个   校准日期   时间的先后顺序
  if (timestamp1 < timestamp2) {
    // return date1 + ' 在 ' + date2 + ' 之前';
    return '下次校准日期 需 小于首次校准日期，请重新选择'
  } else if (timestamp1 > timestamp2) {
    // return date1 + ' 在 ' + date2 + ' 之后';
    return '下次校准日期 需 大于首次校准日期，请重新选择'
  } else {
    // return date1 + ' 和 ' + date2 + ' 是相同的日期';
    return '下次校准日期 不能与 首次校准日期相同，请重新选择'
  }
}

//获取当下时间
const getCurrentDate = () => {
  var today = new Date()
  var year = today.getFullYear() // 获取当前年份
  var month = ('0' + (today.getMonth() + 1)).slice(-2) // 获取当前月份（补 0）
  var day = ('0' + today.getDate()).slice(-2) // 获取当前日期（补 0）
  return year + '-' + month + '-' + day
}

//第一次 选择校准日期
let selectFirstCalibrationDate = ref('')
const getOneTimeValue = (val) => {
  console.log(val)
  let currentTime = getCurrentDate()
  console.log('Current', currentTime)

  let isTimeTrue = compareDates(currentTime, val)

  // if (isTimeTrue == '下次校准日期 需 大于首次校准日期，请重新选择') {
  //   selectFirstCalibrationDate.value = '';
  //   ElMessage({
  //     showClose: true,
  //     message: '校准日期需大于等于当前时间，请重新选择',
  //     type: 'error',
  //   });
  // } else {
  //   // 什么都不做
  // }
  if (selectFirstCalibrationDate.value !== '') {
    if (verificationCycle.value) {
      let date = new Date(selectFirstCalibrationDate.value)
      let num = parseInt(verificationCycle.value)
      let year = date.getFullYear()
      let month = date.getMonth()
      date.setMonth(month + num)
      selectSecondCalibrationDate.value = date.toISOString().slice(0, 10)
      console.log(selectSecondCalibrationDate.value)
    }
  } else {
    selectSecondCalibrationDate.value = ''
  }
}

//第二次 选择校准日期
let selectSecondCalibrationDate = ref('')
// 获取第二次  选择校准日期

// 已选首次校准日期，对第二次校准日期进行校准
const getSeconTimedValue = (val) => {
  console.log('第二次所选时间', val)

  if (selectFirstCalibrationDate.value) {
    let isTimeTrue = compareDates(selectFirstCalibrationDate.value, val)

    if (isTimeTrue == '下次校准日期 需 大于首次校准日期，请重新选择') {
      ElMessage({
        showClose: true,
        message: isTimeTrue,
        type: 'error',
      })
      selectSecondCalibrationDate.value = ''
    } else if (isTimeTrue == '下次校准日期 不能与 首次校准日期相同，请重新选择') {
      ElMessage({
        showClose: true,
        message: isTimeTrue,
        type: 'error',
      })
      selectSecondCalibrationDate.value = ''
    } else {
    }
  } else {
    // 若未选首次日期，选择下次日期
    selectSecondCalibrationDate.value = ''
    ElMessage({
      showClose: true,
      message: '请先选择校准日期',
      type: 'error',
    })
  }

  // selectSecondCalibrationDate.value = val;
}

//校验周期
let verificationCycle = ref('')
//   校验周期  所有选项
let verificationCycleAll = ref([
  { label: '6个月', value: 1 },
  { label: '12个月', value: 2 },
  { label: '18个月', value: 3 },
  { label: '24个月', value: 4 },
  { label: '30个月', value: 5 },
  { label: '36个月', value: 6 },
])
//校验周期  选中的值
//   let selectDevic1eStatus = ref('');
//校验周期 选中事件
const verificationCycleValueChange = (item) => {
  console.log(item)
  // console.log(item.value);
  verificationCycle.value = item
  if (selectFirstCalibrationDate.value !== '') {
    if (verificationCycle.value) {
      let date = new Date(selectFirstCalibrationDate.value)
      let num = parseInt(verificationCycle.value)
      let year = date.getFullYear()
      let month = date.getMonth()
      date.setMonth(month + num)
      selectSecondCalibrationDate.value = date.toISOString().slice(0, 10)
      console.log(selectSecondCalibrationDate.value)
    }
  } else {
    selectSecondCalibrationDate.value = ''
  }
}
// getSeconTimedValue

//仪器管理员
let selectInstrumentAdministrator = ref('')
//   仪器管理员  所有选项
let selectInstrumentAdministratorAll = ref([])
// 获取仪器管理员
onMounted(async () => {
  try {
    const res = await http.post('/user/findInstrumentOptions-user', {})
    if (res.status == 0) {
      const admins = res.userOptions
      selectInstrumentAdministratorAll.value = admins
    } else {
      console.log('获取失败')
    }
  } catch (error) {
    console.error(error)
    // 处理错误
  }
  ReqDefault()
  getTypeRes()
})
//仪器管理员  选中的值
//   let selectDevic1eStatus = ref('');
//仪器管理员 选中事件
const selectInstrumentAdministratorValueChange = (val) => {
  // console.log(val);
  selectInstrumentAdministrator.value = val
}

//所属实验室
let selectAffiliatedLaboratory = ref('')
//   所属实验室  所有选项
let selectAffiliatedLaboratoryAll = ref([
  { label: '产品功效实验室1', value: 1 },
  { label: '产品功效实验室2', value: 2 },
  { label: '产品功效实验室3', value: 3 },
])
//所属实验室  选中的值
//   let selectDevic1eStatus = ref('');
//所属实验室 选中事件
const selectAffiliatedLaboratoryValueChange = (val) => {
  // console.log(val);
  selectAffiliatedLaboratory.value = val
}

const dialogImageUrl = ref('')
const dialogVisible = ref(false)

let imgsArr = ref([])

// 图片上传成功
const imgsFileArr = ref([])
const handleAvatarSuccess = (response, uploadFile, uploadFiles) => {
  console.log('上传成功了。。')
  console.log('response', response)
  console.log('response', response.fileSavePath)
  imgsFileArr.value.push(response.accessPath)
  imgsArr.value.push(response.fileSavePath)

  console.log('resArr', imgsArr.value)

  console.log('uploadFile', uploadFile)
  console.log('uploadFiles', uploadFiles)
}
const deleteImage = (index) => {
  console.log('imgsArr', imgsArr.value)
  console.log('index', index)

  imgsArr.value.splice(index, 1)
  imgsFileArr.value.splice(index, 1)
  console.log('删除后的照片数组', imgsArr.value)
}
// 照片墙
const handlePictureCardPreview = (uploadFile) => {
  console.log('是否执行')
  console.log(' uploadFile', uploadFile)
  dialogImageUrl.value = uploadFile.response.accessPath

  dialogVisible.value = true
}
const handleRemove = (uploadFile, uploadFiles) => {
  console.log('点击了删除')
  console.log('删除图片', uploadFile, uploadFiles)
  // console.log('删除图片2', uploadFile.response.fileSavePath);

  const deleteIndex = imgsArr.value.indexOf(uploadFile.response.fileSavePath)
  if (deleteIndex > -1) {
    imgsArr.value.splice(deleteIndex, 1)
  }
  console.log('删除后的arr', imgsArr.value)
}
//是否提醒是否选中
let butInfoValueArr = ref([])
butInfoValueArr.value[0] = 0
//点击提醒收集选项
const infoChange = (i) => {
  if (butInfoValueArr.value[0] == i) {
    // butInfoValueArr.value.length ==0
    butInfoValueArr.value[0] = -1
    return
  }

  if (butInfoValueArr.value.length == 0) {
    // butInfoValueArr.value.push(i)
    butInfoValueArr.value[0] = i
    console.log('butInfoValueArr1 is', butInfoValueArr)
  } else {
    // butInfoValueArr.value.splice(0, butInfoValueArr.value.length);    //1 不提醒， 2 提醒
    // butInfoValueArr.value.length =0
    // butInfoValueArr.value.push(i);
    butInfoValueArr.value[0] = i
    console.log('butInfoValueArr2 is', butInfoValueArr)
  }
  console.log('最终选择是否提醒', butInfoValueArr.value[0])
}

//生产编号
let inputFactoryNumber = ref('')

//溯源方式
let inputTraceabilityMethod = ref('')

// 测量范围
let selectMeasuringRange = ref()
// 接收要修改的数据
const props = defineProps({
  Change: Array,
})
console.log('Change :>> ', props.Change, props)

// 备注
const Remark = ref('')

// 测试场所
const test_site_val = ref('')
const test_site = ref(['北京', '上海', '沈阳', 'SGS上海测试中心'])

//必选项是否为空提示
let errObj = reactive({
  inputDeviceNumber: false,
  inputDeviceName: false,
  selectDeviceStatus: false,
  selectType: false,
})
const is_inputName = () => {
  if (inputDeviceNumber.value === '') {
    errObj.name = true
  } else {
    errObj.name = false
  }
}

// watch(
//   () => selectFirstCalibrationDate.value,
//   (newValue) => {
//     if (newValue != '') {
//       let Current = Dates(1);
//       // console.log('当前时间是Current :>> ', Current);
//       if (newValue < Current) {
//         selectFirstCalibrationDate.value = '';
//         message({
//           type: 'warning',
//           showClose: true,
//           message: '校准时间需要大于或者等于今天',
//         });
//       }
//     }
//   },
// );

const is_inputSex = () => {
  if (selectDeviceStatus.value === '') {
    errObj.sex = true
  } else {
    errObj.sex = false
  }
}

// 判断测试场所是否勾选
const test_site_error = ref('测试场所不可为空')
const is_testError = ref(false)
const is_inputTestSite = () => {
  if (test_site_val.value === '') {
    test_site_error.value = '测试场所不可为空'
    is_testError.value = true
  } else {
    test_site_error.value = ''
    is_testError.value = false
  }
}
// ! 给页面复制
watch(
  () => props.Change,
  (newValue) => {
    console.log('接入数据 :>> ', '接入数据', newValue)
    if (newValue.length != 0) {
      tableData.value.splice(tableData.value.length, 0)
      inputDeviceNumber.value = newValue.instr_number
      inputDeviceName.value = newValue.instr_name
      inputParameterInterpretation.value = newValue.parameter_interpretation
      inputSpecificationModel.value = newValue.specification
      inputTraceabilityMethod.value = newValue.traceability
      selectDeviceStatus.value = newValue.instr_status
      verificationCycle.value = newValue.calibration_period
      selectAffiliatedLaboratory.value = newValue.laboratory

      inputManufacturer.value = newValue.manufacturer
      selectDeviceGroup.value = newValue.group
      selectMeasuringRange.value = newValue.measure_range
      selectFirstCalibrationDate.value = newValue.calibration_date
      selectSecondCalibrationDate.value = newValue.calibration_nextdate
      Remark.value = newValue.remark
      inputDeviceAbbreviation.value = newValue.instr_abbre
      inputFactoryNumber.value = newValue.manufacturing_no
      inputMeasuringPrinciple.value = newValue.measure_principle
      tableData.value.push(newValue.maintain_info)

      // // 附件信息
      // files: [],

      // // 图片
      // imgs: [],
    }
  },
  { immediate: true, deep: true }
)
const errName = ref('')
//   提醒  勿删
const is_inputDeviceName = () => {
  if (inputDeviceName.value === '') {
    errObj.inputDeviceName = true
    errName.value = '仪器名称不可为空'
  } else if (inputDeviceName.value.length > 50) {
    errObj.inputDeviceName = true
    errName.value = '仪器名称格式错误'
  } else {
    errObj.inputDeviceName = false
  }
}
const errNumber = ref('')
//   提醒  勿删
const is_inputDeviceNumber = () => {
  if (inputDeviceNumber.value === '') {
    errObj.inputDeviceNumber = true
    errNumber.value = '仪器编号不可为空'
  } else if (inputDeviceNumber.value.length > 20) {
    errObj.inputDeviceNumber = true
    errNumber.value = '仪器编号格式错误'
  } else {
    errObj.inputDeviceNumber = false
  }
}

//   提醒  勿删
const is_selectDeviceStatus = () => {
  if (selectDeviceStatus.value === '') {
    errObj.selectDeviceStatus = true
  } else {
    errObj.selectDeviceStatus = false
  }
}
// 效验  仪器类型
const is_selectType = () => {
  if (selectType.value === '') {
    errObj.selectType = true
  } else {
    errObj.selectType = false
  }
}
// 新建仪器确定按钮
const createdUser = async () => {
  console.log(selectInstrumentAdministrator.value)
  console.log(butInfoValueArr.value[0])
  console.log(selectFirstCalibrationDate.value)
  console.log(imgsArr.value)
  console.log(imgsFileArr.value)
  if (selectInstrumentAdministrator.value) {
    console.log(selectInstrumentAdministrator.value)
    const correspondingValue = selectInstrumentAdministratorAll.value.find((item) => item.label === selectInstrumentAdministrator.value)?.value
    console.log(correspondingValue)
    is_inputDeviceNumber()
    is_inputDeviceName()
    is_selectDeviceStatus()
    is_selectType()
    // if (test_site_val.value !== '') {
    if (errObj.inputDeviceName == false && errObj.inputDeviceNumber == false && errObj.selectDeviceStatus == false && errObj.selectType == false) {
      await http
        .post('/instrument/create-instrument', {
          // Instrument: {
          // 仪器名字
          instr_name: inputDeviceName.value,
          // 仪器编号
          instr_number: inputDeviceNumber.value,
          // 维护信息
          maintain_info: tableData.value,
          instr_type: selectType.value,
          // 附件信息
          files: filesArr.value || [],
          // 图片
          imgs: imgsArr.value || [],
          // 参数解释
          parameter_interpretation: inputParameterInterpretation.value,
          // 规格型号
          specification: inputSpecificationModel.value,
          // 溯源方式
          traceability: inputTraceabilityMethod.value,
          // 仪器状态
          instr_status: selectDeviceStatus.value,
          // 校验周期
          calibration_period: verificationCycle.value,
          // 所属实验室
          laboratory: selectAffiliatedLaboratory.value,
          // 生产厂家
          manufacturer: inputManufacturer.value,
          // 仪器组别
          group: selectDeviceGroup.value,
          // 测量范围
          measure_range: selectMeasuringRange.value,
          // 校准日期
          calibration_date: selectFirstCalibrationDate.value,
          // 下次校准日期
          calibration_nextdate: selectSecondCalibrationDate.value,
          // 备注
          remark: Remark.value,
          // 校准提醒  不提醒false      提醒true
          calibration_alarm: butInfoValueArr.value[0] == 1 ? false : butInfoValueArr.value[0] == 2 ? true : null,
          //  仪器简称
          instr_abbre: inputDeviceAbbreviation.value,
          // 出厂编号
          manufacturing_no: inputFactoryNumber.value,
          // 测量原理
          measure_principle: inputMeasuringPrinciple.value,
          // 仪器管理员
          management: selectInstrumentAdministrator.value,
        })
        .then((res) => {
          console.log('ress', res)
          if (res.status === 0) {
            //   IDS = res.volunteer_id;
            ElMessage({
              showClose: true,
              message: '新建仪器成功',
              type: 'success',
            })
            //   application(IDS);
            handleClose()
          } else if (res.status === 1) {
            ElMessage({
              showClose: true,
              message: '仪器编号重复',
              type: 'warning',
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  } else {
    console.log(selectInstrumentAdministrator.value)
    const correspondingValue = selectInstrumentAdministratorAll.value.find((item) => item.label === selectInstrumentAdministrator.value)?.value
    console.log(correspondingValue)
    is_inputDeviceNumber()
    is_inputDeviceName()
    is_selectDeviceStatus()
    is_selectType()
    // if (test_si  te_val.value !== '') {
    if (errObj.inputDeviceName == false && errObj.inputDeviceNumber == false && errObj.selectDeviceStatus == false && errObj.selectType == false) {
      await http
        .post('/instrument/create-instrument', {
          // Instrument: {
          // 仪器名字
          instr_name: inputDeviceName.value,
          // 仪器编号
          instr_number: inputDeviceNumber.value,
          // 维护信息
          maintain_info: tableData.value,
          instr_type: selectType.value,
          // 附件信息
          files: filesArr.value || [],
          // 图片
          imgs: imgsArr.value || [],
          // 参数解释
          parameter_interpretation: inputParameterInterpretation.value,
          // 规格型号
          specification: inputSpecificationModel.value,
          // 溯源方式
          traceability: inputTraceabilityMethod.value,
          // 仪器状态
          instr_status: selectDeviceStatus.value,
          // 校验周期
          calibration_period: verificationCycle.value,
          // 所属实验室
          laboratory: selectAffiliatedLaboratory.value,
          // 生产厂家
          manufacturer: inputManufacturer.value,
          // 仪器组别
          group: selectDeviceGroup.value,
          // 测量范围
          measure_range: selectMeasuringRange.value,
          // 校准日期
          calibration_date: selectFirstCalibrationDate.value,
          // 下次校准日期
          calibration_nextdate: selectSecondCalibrationDate.value,
          // 备注
          remark: Remark.value,
          // 校准提醒  不提醒false      提醒true
          calibration_alarm: butInfoValueArr.value[0] == 1 ? false : butInfoValueArr.value[0] == 2 ? true : false,
          //  仪器简称
          instr_abbre: inputDeviceAbbreviation.value,
          // 出厂编号
          manufacturing_no: inputFactoryNumber.value,
          // 测量原理
          measure_principle: inputMeasuringPrinciple.value,
        })
        .then((res) => {
          console.log('ress', res)
          if (res.status === 0) {
            //   IDS = res.volunteer_id;
            ElMessage({
              showClose: true,
              message: '新建仪器成功',
              type: 'success',
            })
            //   application(IDS);
            handleClose()
          } else if (res.status === 1) {
            ElMessage({
              showClose: true,
              message: '仪器编号重复',
              type: 'warning',
            })
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  // } else {
  //   console.log('测试场所不能为空');
  // }
}
//修改仪器确定按钮
const ChangeMsg = async () => {
  is_inputDeviceNumber()
  is_inputDeviceName()
  is_selectDeviceStatus()
  is_selectType()
  // if (test_site_val.value !== '') {
  if (errObj.inputDeviceName == false && errObj.inputDeviceNumber == false && errObj.selectDeviceStatus == false && errObj.selectType == false) {
    await http
      .post(
        '/instrument/update-Instrument',

        {
          // 仪器名字
          instr_name: inputDeviceName.value,
          // 仪器编号
          instr_number: inputDeviceNumber.value,

          // 维护信息
          maintain_info: tableData.value,
          instr_type: selectType.value,
          // 附件信息
          files: [],

          // 图片
          imgs: [],

          // 参数解释
          parameter_interpretation: inputParameterInterpretation.value,
          // 规格型号
          specification: inputSpecificationModel.value,
          // 溯源方式
          traceability: inputTraceabilityMethod.value,

          // 仪器状态
          instr_status: selectDeviceStatus.value,

          // 校验周期
          calibration_period: parseInt(verificationCycle.value),

          // 所属实验室
          laboratory: selectAffiliatedLaboratory.value,
          // 生产厂家
          manufacturer: inputManufacturer.value,

          // 仪器组别
          group: selectDeviceGroup.value,
          // 测量范围
          measure_range: selectMeasuringRange.value,

          // 校准日期
          calibration_date: selectFirstCalibrationDate.value,

          // 下次校准日期
          calibration_nextdate: selectSecondCalibrationDate.value,

          // 备注
          remark: Remark.value,

          // 校准提醒  不提醒false      提醒true
          calibration_alarm: butInfoValueArr.value[0] == 1 ? false : butInfoValueArr.value[0] == 2 ? true : false,

          //  仪器简称
          instr_abbre: inputDeviceAbbreviation.value,

          // 出厂编号
          manufacturing_no: inputFactoryNumber.value,

          // 测量原理
          measure_principle: inputMeasuringPrinciple.value,

          _id: props.Change._id,
        }
      )
      .then((res) => {
        console.log('测试', res)
        if (res.status === 0) {
          //   IDS = res.volunteer_id;
          ElMessage({
            showClose: true,
            message: '仪器修改成功',
            type: 'success',
          })

          handleClose()
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // } else {
  //   console.log('测试场所不能为空');
  // }
}

// 请求标签 数据
const ReqDefault = async () => {
  const res = await http.post('/setting/fetch-setting', {
    type: 'instrument',
  })

  console.log('res请求初始数据为 :>> ', res)
  if (res.status == 0) {
    console.log('res请求初始数据为 :>> ', res)

    res.groups.forEach((item, index) => {
      if (item.value != false) {
        deviceGroupAll.value.push(item.name)
      }
    })

    if (deviceGroupAll.value.length == 0) {
      deviceGroupAll.value.push('暂无标签，请前往【自定义设置】新建')
    }
    console.log('获取的最终数据', deviceGroupAll.value)
  }
}
// 报名方法
// const application = async (val) => {
//   let res = await http.post('/Recruit_Form/created-Recruit_Form', {
//     project: store.state.Project.ProjectId,
//     volunteer: val,
//     reservation_time: '2023-05-25',
//   });
//   console.log(res);
// };

// 图片上传

// const fileList = ref([
//   {
//     name: 'food.jpeg',
//     url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
//   },
//   {
//     name: 'plant-1.png',
//     url: '/images/plant-1.png',
//   },
//   {
//     name: 'food.jpeg',
//     url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
//   },
//   {
//     name: 'plant-2.png',
//     url: '/images/plant-2.png',
//   },
//   {
//     name: 'food.jpeg',
//     url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
//   },
//   {
//     name: 'figure-1.png',
//     url: '/images/figure-1.png',
//   },
//   {
//     name: 'food.jpeg',
//     url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100',
//   },
//   {
//     name: 'figure-2.png',
//     url: '/images/figure-2.png',
//   },
// ])
</script>

<style lang="less" scoped>
@import url('@/style/components/InstrumentMangement/createInstrument.less');
</style>
