<template>
  <div id="HomeView">
    <div class="home">
      <div class="home_detail">
        <div class="detail_title">数据概览</div>
        <div class="detail_main">
          <!-- 项目 -->
          <div class="main_item datails_project">
            <div class="item_title">项目</div>
            <div class="item_nums">
              <div class="nums_text">累计总数</div>
              <div class="nums_val">{{ dataDetail.project.Totalize }}</div>
            </div>
            <div class="item_num">
              <div class="num_text">完成总数</div>
              <div class="num_val">{{ dataDetail.project.TotalCompleted }}</div>
            </div>
          </div>
          <!-- 志愿者 -->
          <div class="main_item datails_volunteer">
            <div class="item_title">志愿者</div>
            <div class="item_nums">
              <div class="nums_text">累计总数</div>
              <div class="nums_val">{{ dataDetail.volunteer.Totalize }}</div>
            </div>
            <div class="item_num">
              <div class="num_text">今日新增</div>
              <div class="num_val">{{ dataDetail.volunteer.NewToday }}</div>
            </div>
          </div>
          <!-- 仪器 -->
          <div class="main_item datails_instrument">
            <div class="item_title">仪器</div>
            <div class="item_nums">
              <div class="nums_text">累计总数</div>
              <div class="nums_val">{{ dataDetail.instrument.Totalize }}</div>
            </div>
            <div class="item_num">
              <div class="num_text">今日新增</div>
              <div class="num_val">{{ dataDetail.instrument.NewToday }}</div>
            </div>
          </div>
          <!-- 问卷 -->
          <div class="main_item datails_querstion">
            <div class="item_title">问卷</div>
            <div class="item_nums">
              <div class="nums_text">累计总数</div>
              <div class="nums_val">{{ dataDetail.question.Totalize }}</div>
            </div>
            <div class="item_num">
              <div class="num_text">今日新增</div>
              <div class="num_val">{{ dataDetail.question.NewToday }}</div>
            </div>
          </div>
          <!-- 样品 -->
          <div class="main_item datails_sample">
            <div class="item_title">样品</div>
            <div class="item_nums">
              <div class="nums_text">累计总数</div>
              <div class="nums_val">{{ dataDetail.sample.Totalize }}</div>
            </div>
            <div class="item_num">
              <div class="num_text">今日新增</div>
              <div class="num_val">{{ dataDetail.sample.NewToday }}</div>
            </div>
          </div>
          <!-- 专家 -->
          <div class="main_item datails_expert">
            <div class="item_title">专家</div>
            <div class="item_nums">
              <div class="nums_text">累计总数</div>
              <div class="nums_val">{{ dataDetail.expert.Totalize }}</div>
            </div>
            <div class="item_num">
              <div class="num_text">今日新增</div>
              <div class="num_val">{{ dataDetail.expert.NewToday }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="home_dataProject">
        <div class="data_trend">
          <div class="trend_head">
            <div class="head_title">数据趋势</div>
            <div
              class="head_nav"
              :class="dataTrendArrIndex == index ? 'head_click' : ''"
              @click="dataTrendArrIndexChange(index)"
              v-for="(item, index) in dataTrendArr"
              :key="index">
              {{ item }}
            </div>
            <div class="head_time">
              <el-date-picker
                v-model="dataTrenTime"
                type="daterange"
                unlink-panels
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                range-separator=""
                start-placeholder="开始时间"
                @change="dataTrenTimeChange"
                end-placeholder="结束时间" />
            </div>
          </div>
          <div class="trend_heads">
            <div class="heads_item" @click="dataTrenitemArrChange(index)" v-for="(item, index) in dataTrenitemArr[dataTrendArrIndex]" :key="index">
              <img
                class="item_img"
                :src="
                  dataTrenitemArrIndex == index
                    ? 'https://newstore.vynior.com/cems_web/%E6%A4%AD%E5%9C%86%20341%402x.png'
                    : 'https://newstore.vynior.com/cems_web/%E6%A4%AD%E5%9C%86%20341%402x%20%281%29.png'
                "
                alt="" />
              <div class="item_text">{{ item }}</div>
            </div>
          </div>
          <div v-show="dataTrendEchartsValArr.length > 0" id="treadEcharts"></div>
          <div class="noData" v-show="dataTrendEchartsValArr.length == 0">暂无数据</div>
        </div>
        <div class="data_project">
          <div class="project_head">
            <div class="head_title">我的项目</div>
            <div class="head_all" @click="allProjectChange">全部项目</div>
          </div>
          <div class="project_main">
            <div class="main_item" v-for="(item, index) in myProjectRes" :key="index">
              <div class="item_name">{{ item.name }}</div>
              <div class="item_status">
                <div
                  class="status_box"
                  :class="
                    item.status == '进行中'
                      ? 'status_progress'
                      : item.status == '已完成'
                      ? 'status_completed'
                      : item.status == '招募中'
                      ? 'status_Recruitment'
                      : 'status_incomplete'
                  ">
                  {{ item.status }}
                </div>
              </div>
              <div class="item_speed">
                <div class="speed">
                  <div class="speed_back" :style="{ width: item.progress + '%' }"></div>
                </div>
                <div class="speed_val">{{ item.progress }}%</div>
              </div>
              <div class="item_time">
                {{ item.projectStartTime.substring(0, 10) + '~' + item.projectEndTime.substring(0, 10) }}
              </div>
            </div>

            <div class="noData" v-if="myProjectRes.length == 0">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <div id="reset-password">
      <el-dialog v-model="resetPwd" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
        <template #header>修改初始密码</template>
        <template #default>
          <div class="content">
            <div class="title">为了您的账号安全，首次登录或重置密码后请修改初始密码</div>
            <div class="input-newPwd">
              <div class="input-item">
                <span>新密码</span>
                <div class="input_pwd">
                  <el-input
                    @input="pwd_verify"
                    :class="is_pwd_verify ? 'error_input' : ''"
                    v-model="new_pwd1"
                    type="password"
                    show-password
                    placeholder="请填写新密码" />
                </div>
              </div>
              <div class="pwd-length-hint">
                <span v-if="is_pwd_verify" class="error-hint">
                  {{ first_pwd_hint }}
                </span>
                <span v-else> 密码长度为8-16位，不少于8位;应至少包含数字、大小写字母及特殊字符中的两种 </span>
              </div>
              <div class="input-item">
                <span>确认密码</span>
                <div class="input_pwd">
                  <el-input
                    @input="sec_pwd_verify"
                    :class="is_pwd_verify2 ? 'error_input' : ''"
                    v-model="new_pwd2"
                    type="password"
                    show-password
                    placeholder="请再次输入新密码" />
                </div>
              </div>
              <div class="pwd-length-hint">
                <span v-if="is_pwd_verify2" class="error-hint">
                  {{ sec_pwd_hint }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <el-button type="primary" @click="submit"> 确定 </el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script setup>
import { defineComponent, ref, onBeforeMount, onMounted } from 'vue';
import { Lock } from '@element-plus/icons-vue';
import { message } from '@/utils/resetMessage';
import http from '@/utils/http';
import * as echarts from 'echarts';
import { useRouter, useRoute } from 'vue-router';

const router = useRouter();
const route = useRoute();

//获取本月的  第一天 和最后一天
const getCurrentMonthRange = () => {
  const now = new Date(); // 获取当前时间
  const year = now.getFullYear(); // 获取当前年份
  const month = now.getMonth() + 1; // 获取当前月份（注意，JavaScript 中月份是从 0 开始计数的，因此需要加 1）
  const firstDay = new Date(year, month - 1, 1); // 构造本月第一天的日期对象
  const lastDay = new Date(year, month, 0); // 构造本月最后一天的日期对象
  const firstDayStr = formatDate(firstDay); // 将本月第一天的日期格式化为字符串
  const lastDayStr = formatDate(lastDay); // 将本月最后一天的日期格式化为字符串
  console.log('当月的,', [firstDayStr, lastDayStr]);
  dataTrenTime.value = [firstDayStr, lastDayStr];
  startData.value = firstDay;
  endData.value = lastDay;
  return [firstDayStr, lastDayStr]; // 返回包含两个字符串的数组
};
const formatDate = (date) => {
  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  return `${year}-${month}-${day}`;
};
const padZero = (num) => {
  return num < 10 ? '0' + num : num.toString();
};

//日期范围选择器  选中的值
let startData = ref('');
let endData = ref('');
onMounted(async () => {
  await getCurrentMonthRange();

  await getdataDetail();
  // await getdataTrenitem()
  await getMyProject();

  await getdataTrenitem();

  // await treadEchartsChange()

  // await resetPasswordChange()
});
//  获取主页面板数据
const getHomePanelData = async () => {
  const res = await http.post('/dashboard/homapage-dashboard', {
    projectId: '64992347ca5843ebf040ced0',
  });
  console.log('获取主页面板数据', res);
  // dataDetail.value = res.result;
};

// 数据概览
const dataDetail = ref({
  project: {
    Totalize: null,
    TotalCompleted: null,
  },
  volunteer: {
    Totalize: null,
    NewToday: null,
  },
  instrument: {
    Totalize: null,
    NewToday: null,
  },
  question: {
    Totalize: null,
    NewToday: null,
  },
  sample: {
    Totalize: null,
    NewToday: null,
  },
  expert: {
    Totalize: null,
    NewToday: null,
  },
});

// 获取数据概览数据
const getdataDetail = async () => {
  const res = await http.post('/dashboard/homapage-dashboard');
  console.log(res, '返回的数据概览');
  dataDetail.value = res.result;
};
// 折线图
const treadEchartsChange = () => {
  let myChart = echarts.init(document.getElementById('treadEcharts'));
  // 绘制图表
  myChart.setOption({
    xAxis: {
      type: 'category',
      data: dataTrendEchartsValArr.value,
      // boundaryGap: false,
      axisLine: {
        show: false,
        lineStyle: {
          color: '#8F94B2',
        },
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        // interval: interval,
        // interval: 1,
        // axisLabel: {
        formatter: function (value) {
          return value.substring(5); // 提取从索引5开始的子字符串，即月-日部分
        },
        // },
      },
    },
    yAxis: {
      type: 'value',
      axisLine: {
        show: false,
        lineStyle: {
          color: '#8F94B2',
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: true,
        lineStyle: {
          // type: 'dashed',
        },
      },
    },
    tooltip: {
      show: true,
      trigger: 'axis',
      // 自定义显示
      // formatter: '{b0}<br />新增用户数   {c0} 个',
      formatter: function (params) {
        // console.log(params[0].value);
        return (
          params[0].name +
          '<br />' +
          dataTrenitemArr.value[dataTrendArrIndex.value][dataTrenitemArrIndex.value] +
          '&nbsp; ' +
          params[0].value +
          '&nbsp;个&nbsp;'
        );
      },
    },
    series: [
      {
        name: dataTrenitemArr.value[dataTrendArrIndex.value][dataTrenitemArrIndex.value],
        type: 'line',
        data: dataTrendEchartsDateArr.value[dataTrenitemArrIndex.value],
        areaStyle: { type: 'default' },
        smooth: true,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: 'rgba(0,74,218, 0.5)' },
            { offset: 0.5, color: 'rgba(0,74,218, 0.3)' },
            { offset: 1, color: 'rgba(0,74,218, 0.1)' },
          ]),
          borderColor: '#004ADA',
          borderWidth: 1,
          borderType: 'solid',
        },
        symbol: 'circle',
        showAllSymbol: 'auto',
        symbolSize: 10,
        //折线拐点的类型
        // symbol: 'star',
        // 改变线条样式 lineStyle
        lineStyle: {
          color: '#004ADA',
          type: 'solid',
          with: 2,
        },
        emphasis: {
          symbolSize: 0,
          //   itemStyle: {
          //     // 鼠标悬浮时拐点样式
          //     borderColor: "#004ADA",
          //     borderWidth: 10,
          //     borderType: "solid",
          // },
        },
      },
    ],
  });
  window.onresize = function () {
    //自适应大小
    myChart.resize();
  };
};
// 环形图
const resetPasswordChange = () => {
  let myChart = echarts.init(document.getElementById('project_ecarts'));
  // 绘制图表
  myChart.setOption({
    // legend: {
    legend: {
      top: '85%',
      left: 'center',
    },
    // grid: {
    // 				top: '30%', // 等价于 y: '16%'
    // 				left: '3%',
    // 				right: '0%',
    // 				bottom: '20%',
    // 				containLabel: true
    // 			},
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        type: 'pie',
        avoidLabelOverlap: false,
        data: [
          {
            itemStyle: { color: '#004ADA' },
            name: '18-25',
            value: 12,
          },
          {
            itemStyle: { color: '#38BDD7' },
            name: '25-35',
            value: 12,
          },
          {
            itemStyle: { color: '#FC7A94' },
            name: '35-45',
            value: 12,
          },
          {
            itemStyle: { color: '#FFD47D' },
            name: '45以上',
            value: 12,
          },
        ],
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 10,
        },
        // 数值显示
        label: {
          show: false,
        },
        radius: ['50%', '80%'],
        center: ['50%', '40%'],
        // itemStyle: {
        //   borderRadius: 8,
        //   borderColor: '#fff',
        //   borderWidth: 4,
        // },
      },
    ],
  });
  window.onresize = function () {
    //自适应大小
    myChart.resize();
  };
};
const myProjectRes = ref([]);
// 获取我的项目数据
const getMyProject = async () => {
  let isSuper = false;
  const result = await http.post('/user/findInfo-user', {
    userId: localStorage.getItem('_id'),
  });
  console.log(result, '获取个人信息');
  if (result.user.role.includes('超级管理员')) {
    isSuper = true;
  }
  const res = await http.post('/dashboard/myproject', {
    userId: localStorage.getItem('_id'),
    isSuper: isSuper,
  });
  myProjectRes.value = res.myProject;
  console.log(res, '获取的我的项目的数据');

  // const res = await http.post('/dashboard/myproject', {
  //   userId: localStorage.getItem('_id'),
  // });
  // myProjectRes.value = res.myProject;
  // console.log(res, '获取的我的项目的数据');
};
// 数据趋势头部导航数组
const dataTrendArr = ref(['项目', '志愿者', '仪器', '问卷', '样品', '专家']);
// 数据趋势头部导航数组选中的值
const dataTrendArrIndex = ref(1);
// 图表数据
const dataTrendEchartsValArr = ref([]);
const dataTrendEchartsDateArr = ref([]);
// 获取数据趋势数据
const getdataTrenitem = async () => {
  console.log(dataTrendArrIndex.value, 'dataTrendArrIndex.value');
  if (dataTrendArrIndex.value == 1) {
    const res = await http.post('/dashboard/volunteerData', {
      // startDate: dataTrenTime.value[0],
      // endDate: dataTrenTime.value[1],
      startDate: startData.value,
      endDate: endData.value,
    });
    console.log(res, '获取数据趋势');
    dataTrendEchartsValArr.value = [];
    dataTrendEchartsDateArr.value = [[], [], [], []];
    res.DataTrends.volunteer.forEach((item, index) => {
      dataTrendEchartsValArr.value.push(item.range);
      dataTrendEchartsDateArr.value[0].push(item.volunteerNew);
      dataTrendEchartsDateArr.value[1].push(item.volunteerTotal);
      dataTrendEchartsDateArr.value[2].push(item.recruitnew);
      // dataTrendEchartsDateArr.value[3].push(item.volunteerTotal);
      dataTrendEchartsDateArr.value[3].push(item.recruitTotal);
    });
    console.log(dataTrendEchartsDateArr.value);
    await treadEchartsChange();
  } else {
    const res = await http.post(
      dataTrendArrIndex.value == 0
        ? '/dashboard/projectData'
        : dataTrendArrIndex.value == 2
        ? '/dashboard/instrumentData'
        : dataTrendArrIndex.value == 3
        ? '/dashboard/questionnaireData'
        : dataTrendArrIndex.value == 4
        ? '/dashboard/sampleData'
        : dataTrendArrIndex.value == 5
        ? '/dashboard/expertData'
        : '',
      {
        startDate: dataTrenTime.value[0],
        endDate: dataTrenTime.value[1],
      },
    );
    console.log(res, '获取数据趋势', dataTrendArrIndex.value);
    dataTrendEchartsValArr.value = [];
    dataTrendEchartsDateArr.value = [[], []];
    if (dataTrendArrIndex.value == 0) {
      res.DataTrends.project.forEach((item, index) => {
        dataTrendEchartsValArr.value.push(item.range);
        dataTrendEchartsDateArr.value[0].push(item.projectNew);
        dataTrendEchartsDateArr.value[1].push(item.projectTotal);
      });
    } else if (dataTrendArrIndex.value == 2) {
      res.DataTrends.instrument.forEach((item, index) => {
        dataTrendEchartsValArr.value.push(item.range);
        dataTrendEchartsDateArr.value[0].push(item.instrumentNew);
        dataTrendEchartsDateArr.value[1].push(item.instrumentTotal);
      });
    } else if (dataTrendArrIndex.value == 3) {
      res.DataTrends.questionnaire.forEach((item, index) => {
        dataTrendEchartsValArr.value.push(item.range);
        dataTrendEchartsDateArr.value[0].push(item.questionnaireNew);
        dataTrendEchartsDateArr.value[1].push(item.questionnaireTotal);
      });
    } else if (dataTrendArrIndex.value == 4) {
      res.DataTrends.sample.forEach((item, index) => {
        dataTrendEchartsValArr.value.push(item.range);
        dataTrendEchartsDateArr.value[0].push(item.sampleNew);
        dataTrendEchartsDateArr.value[1].push(item.sampleTotal);
      });
    } else if (dataTrendArrIndex.value == 5) {
      res.DataTrends.expert.forEach((item, index) => {
        dataTrendEchartsValArr.value.push(item.range);
        dataTrendEchartsDateArr.value[0].push(item.expertNew);
        dataTrendEchartsDateArr.value[1].push(item.expertTotal);
      });
    }
    console.log(dataTrendEchartsDateArr.value);
    await treadEchartsChange();
  }
};
// 点击头部导航选择展示的数据
const dataTrendArrIndexChange = (val) => {
  dataTrendArrIndex.value = val;
  console.log(dataTrendArrIndex.value, val);
  dataTrenitemArrIndex.value = 0;
  if (dataTrenTime.value.length != 0) {
    getdataTrenitem();
  }
};
// 数据趋势日期数据
const dataTrenTime = ref([]);
// 数据趋势日期数据触发事件
const dataTrenTimeChange = async (val) => {
  console.log('所选日期为', val);
  startData.value = val[0];
  endData.value = val[1];
  await getdataTrenitem();
  console.log(val);
};
// 数据趋势 二级导航数据
const dataTrenitemArr = ref([
  ['新增项目个数', '累计项目个数'],
  ['新增志愿者人数', '累计志愿者人数', '新增志愿者报名人次', '累计志愿者报名人次'],
  ['新增仪器个数', '累计仪器个数'],
  ['新增问卷个数', '累计问卷个数'],
  ['新增样品个数', '累计样品个数'],
  ['新增专家个数', '累计专家个数'],
]);
// 选中数据趋势二级导航的值
const dataTrenitemArrIndex = ref(0);
// 数据趋势 二级导航数据选中的事件
const dataTrenitemArrChange = async (val) => {
  dataTrenitemArrIndex.value = val;
  console.log(dataTrenTime.value);
  if (dataTrenTime.value.length != 0) {
    getdataTrenitem();
  }
  console.log(val);
};

const resetPwd = ref(false);
const is_resetPwd = () => {
  // console.log(JSON.parse(localStorage.getItem('reset_password')));
  if (localStorage.getItem('reset_password')) {
    resetPwd.value = JSON.parse(localStorage.getItem('reset_password'));
    // console.log('重置密码');
  }
};

// 设置新密码
const new_pwd1 = ref('');
const new_pwd2 = ref('');
const is_hint = ref(false);

const is_pwd_verify = ref(false);
const first_pwd_hint = ref('新密码不能为空');
const pwd_verify = () => {
  let judgeFn = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&/*()_+|{}:"?]{8,16}$/);
  if (new_pwd1.value === '') {
    is_pwd_verify.value = true;
    is_hint.value = true;
  } else if (!judgeFn.test(new_pwd1.value)) {
    is_pwd_verify.value = true;
    is_hint.value = true;
    first_pwd_hint.value = '密码强度不符合要求';
  } else {
    is_pwd_verify.value = false;
    is_hint.value = false;
  }
};
const is_pwd_verify2 = ref(false);
const sec_pwd_hint = ref('两次密码输入不一致');
const sec_pwd_verify = () => {
  let judgeFn = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&/*()_+|{}:"?]{8,16}$/);
  if (new_pwd2.value === '') {
    is_pwd_verify2.value = true;
    sec_pwd_hint.value = '确认密码不能为空';
  } else if (new_pwd2.value === new_pwd1.value) {
    if (!judgeFn.test(new_pwd2.value)) {
      is_pwd_verify2.value = true;
      is_hint.value = true;
      sec_pwd_hint.value = '密码强度不符合要求';
    } else {
      is_pwd_verify2.value = false;
    }
  } else {
    is_pwd_verify2.value = true;
    sec_pwd_hint.value = '两次密码输入不一致';
  }
};
// 点击全部项目进入到项目列表页
const allProjectChange = () => {
  router.push({
    name: 'ProjectList',
  });
};
// 确认设置新密码，接口修改密码
const submit = async () => {
  pwd_verify();
  sec_pwd_verify();
  let judgeFn = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&*()_+|{}:"?]{8,16}$/);
  if (new_pwd1.value !== '' && new_pwd2.value !== '' && new_pwd2.value === new_pwd1.value) {
    if (new_pwd1.value.length != 0) {
      if (!judgeFn.test(new_pwd1.value)) {
        is_pwd_verify.value = true;
        is_hint.value = true;
        first_pwd_hint.value = '密码强度不符合要求';
        is_pwd_verify2.value = true;
        sec_pwd_hint.value = '';
      } else {
        const res = await http.post('/user/resetPassword-user', {
          _id: localStorage.getItem('_id'),
          password: new_pwd1.value,
        });
        console.log(localStorage.getItem('_id'), new_pwd1.value);
        console.log(res);
        if (res.status === 0) {
          router.push('/');
          message({
            type: 'success',
            message: '密码修改成功，请重新登录',
            showClose: true,
          });
          localStorage.removeItem('access_token');
          localStorage.removeItem('_id');
          localStorage.removeItem('pwd_free_Login');
          localStorage.removeItem('reset_password');
        }
        console.log('提交新密码');
      }
    }
  }
};

onBeforeMount(() => {
  is_resetPwd();
});
</script>

<style lang="less">
@import url('@/style/views/Home/HomeView.less');

#reset-password {
  .el-dialog {
    width: 650px;
    .el-dialog__header {
      text-align: left;
      padding: 12px 24px;
      margin: 0;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #313233;
      border-bottom: 1px #bfbfbf solid;
    }
    .el-dialog__body {
      padding: 8px 24px 8px 20px;
      .content {
        .title {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #626366;
          text-align: left;
          margin-bottom: 20px;
        }
        .input-newPwd {
          .input-item {
            display: flex;
            height: 36px;
            align-items: center;
            & > span {
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #626366;
              width: 75px;
              flex-grow: 1;
              text-align: right;
              margin-right: 6px;
            }
            .input_pwd {
              .el-input {
                width: 528px;
                height: 36px;
                background: #ffffff;
                .el-input__wrapper {
                  box-shadow: none;
                  border: 1px solid #bfbfbf;
                  border-radius: 2px;
                  height: 36px;
                }
                .el-icon {
                  width: 20px;
                  height: 20px;
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
                .el-input__wrapper.is-focus {
                  border: 1px #027aff solid;
                  .el-input__prefix {
                    .el-icon {
                      color: #027aff;
                    }
                  }
                }
              }
              // 报错提示
              .error_input {
                .el-input__wrapper {
                  border: 1px solid #ff3200;
                  &.is-focus {
                    border: 1px solid #ff3200;
                    .el-input__prefix {
                      .el-icon {
                        color: #ff3200;
                      }
                    }
                  }
                  .el-input__prefix {
                    .el-icon {
                      color: #ff3200;
                    }
                  }
                }
              }
            }
          }
          .pwd-length-hint {
            min-height: 20px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #bfbfbf;
            line-height: 22px;
            text-align: left;
            display: flex;
            margin-left: 78px;
            margin-bottom: 12px;
          }
        }
        // 错误提示语
        .error-hint {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ff3200;
          line-height: 20px;
        }
      }
    }
    .el-dialog__footer {
      .el-button {
        width: 80px;
        height: 36px;
        background: #027aff;
        border-radius: 2px;
        span {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }
  }
}
</style>
