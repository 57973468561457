// 项目列表

const state = {
  PL_AllData: [],
  //显示隐藏的初始化数据
  PL_SetTableHeaderArr: [
    { field: 'number', checked: true, lable: '项目编号' },
    { field: 'name', checked: true, lable: '项目名称' },
    { field: 'product', checked: true, lable: '项目产品' },
    { field: 'client', checked: false, lable: '项目客户' },
    { field: 'director_name', checked: true, lable: '项目负责人' },
    { field: 'projectStart_time', checked: true, lable: '项目开始日期' },
    { field: 'projectEnd_time', checked: true, lable: '项目结束日期' },
    { field: 'createdAt', checked: false, lable: '项目创建时间' },
    { field: 'status', checked: true, lable: '项目状态' },
    { field: 'progress', checked: true, lable: '项目完成进度' },
    { field: 'checked', checked: true, lable: '操作' },
    { field: 'recruitStart_time', checked: false, lable: '招募开始时间' },
    { field: 'recruitEnd_time', checked: false, lable: '招募结束时间' },
    { field: 'peopleNumber', checked: false, lable: '招募人数' },
    { field: 'subsidy', checked: false, lable: '补助金额' },
    { field: 'testArea', checked: false, lable: '测试部位' },
    { field: 'testAddress', checked: false, lable: '测试地址' },
    { field: 'ageRequirement', checked: false, lable: '测试年龄限制' },
    { field: 'sexRequirement', checked: false, lable: '测试性别要求' },
    { field: 'admissionCondition', checked: false, lable: '测试入选条件' },
    { field: 'exclusionCondition', checked: false, lable: '测试排除条件' },
    { field: 'prompt', checked: false, lable: '测试提示' },
    { field: 'plan', checked: false, lable: '测试计划' },
    { field: 'other', checked: false, lable: '其他' },
  ],
};

const mutations = {
  set_PL_AllData(state, newValue) {
    state.PL_AllData = newValue;
  },
  set_PL_SetTableHeaderArr(state, newValue) {
    state.PL_SetTableHeaderArr = newValue;
  },
};

//导出
export default {
  state,
  mutations,
};
