<template>
  <div id="Createsample" v-if="props.ChangeTite">
    <el-drawer :model-value="props.ChangeTite" :show-close="false" :close-on-click-modal="false" title="I am the title" id="sample_dialog123321">
      <template #header>
        <div class="sample_title">
          <span v-if="!props.ChangeTite">新建样品</span>
        </div>
      </template>
      <template #default>
        <div class="sample_main">
          <!-- 样品基础信息 -->
          <div class="main_info">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">样品基础信息</div>
            </div>
            <div class="info_main">
              <div class="list_line">
                <!-- 样品编号 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span class="text_top">*</span>
                      样品编号
                    </div>
                    <div class="main">
                      <el-input
                        v-model="inputNumberValue"
                        class="inp"
                        placeholder="请输入样品编号"
                        suffix-icon=""
                        :class="errObj.number ? 'inp_err' : ''"
                        @focus="errObj.number = false" />
                      <div class="ertext">
                        <span v-if="errObj.number">样品编号不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 样品名称 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <span class="text_top">*</span>
                      样品名称
                    </div>
                    <div class="main">
                      <el-input
                        v-model="inputNameValue"
                        class="inp"
                        placeholder="请输入样品名称"
                        suffix-icon=""
                        :class="errObj.name ? 'inp_err' : ''"
                        @focus="errObj.name = false" />
                      <div class="ertext">
                        <span v-if="errObj.name">样品名称不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list_line">
                <!-- 样品数量 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span class="text_top">*</span>
                      <span>样品总数量</span>
                    </div>
                    <div class="main">
                      <el-input
                        v-model="inputCountValue"
                        class="inp"
                        placeholder="请输入样品总数量"
                        suffix-icon=""
                        :class="errObj.count ? 'inp_err' : ''"
                        @focus="errObj.count = false" />
                      <div class="ertext">
                        <span v-if="errObj.count">{{ ErrCountText }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 样品状态  -->
                <!-- 后改成 可发放数量 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <!-- <span class="text_top">*</span> -->
                      可发放数量
                    </div>
                    <div class="main">
                      <!--                     <div :class="inputCountValue === '' ? 'none_data ' : 'val'">
 -->
                      <div :class="inputCountValue === '' ? 'val ' : 'val'">
                        {{ inputCountValue === '' ? '系统根据已发放数量自动生成' : inputCountValue }}
                      </div>
                    </div>
                    <!-- <div class="main">
                    <el-select
                      v-model="status_val"
                      placeholder="请选择"
                      :class="is_statusError ? 'inp_err' : ''"
                    >
                      <el-option
                        v-for="item in statusArr"
                        :key="item"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                    <div class="err_text status_error" v-if="is_statusError">
                      <span>样品状态不可为空</span>
                    </div>
                  </div> -->
                  </div>
                </div>
              </div>
              <div class="list_line">
                <!-- 样品标签 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span>样品标签</span>
                    </div>
                    <div class="main">
                      <el-select v-model="inputLableValue" placeholder="请选择" multiple collapse-tags collapse-tags-tooltip>
                        <el-option
                          v-for="item in SampleTags"
                          :key="item"
                          :label="item"
                          :value="item"
                          :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                      </el-select>
                    </div>
                  </div>
                </div>
                <!-- 样品规格 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <span>样品规格</span>
                    </div>
                    <div class="main">
                      <el-input v-model="inputSpecsValue" class="inp" placeholder="请输入样品规格" suffix-icon="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="list_line">
                <!-- 样品性状 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span>样品性状</span>
                    </div>
                    <div class="main">
                      <el-input v-model="inputCharacterValue" class="inp" placeholder="请输入样品性状" suffix-icon="" />
                    </div>
                  </div>
                </div>
                <!-- 样品批号 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <span>样品批号</span>
                    </div>
                    <div class="main">
                      <el-input v-model="inputBatchNumberValue" class="inp" placeholder="请输入样品批号" suffix-icon="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="list_line">
                <!-- 储存条件 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span>储存条件</span>
                    </div>
                    <div class="main">
                      <el-input v-model="inputSaveConditionValue" class="inp" placeholder="请输入存储条件" suffix-icon="" />
                    </div>
                  </div>
                </div>
                <!-- 样品接收日期 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">样品接收日期</div>
                    <div class="main" :class="errObj.date ? 'inp_err' : ''">
                      <el-date-picker
                        class="r_picker"
                        v-model="receiveDateValue"
                        type="date"
                        placeholder="请选择"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        @focus="errObj.receiveDate = false" />
                      <div class="ertext">
                        <span v-if="errObj.date">必选项不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list_line">
                <!-- 限制使用日期 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">限制使用日期</div>
                    <div class="main" :class="errObj.date ? 'inp_err' : ''">
                      <el-date-picker
                        class="l_picker"
                        v-model="limitedUsedDateValue"
                        type="date"
                        placeholder="请选择"
                        format="YYYY-MM-DD"
                        value-format="YYYY-MM-DD"
                        @change="is_Expire"
                        :disabled-date="disabledDate">
                      </el-date-picker>
                      <div class="ertext">
                        <span v-if="errObj.date">必选项不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 是否过期 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <span>是否过期</span>
                    </div>
                    <div class="main">
                      <!-- <div :class="sExpire === '' ? 'none_data ' : 'val'"> -->
                      <div :class="isExpire === '' ? 'val' : 'val'">
                        {{ isExpire === '' ? '系统根据限制使用日期自动生成' : isExpire }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list_line">
                <!-- 是否销毁 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span>是否销毁</span>
                    </div>
                    <div class="main">
                      <el-select v-model="isDestoryArrValue" class="selectInp" placeholder="请选择" size="large">
                        <el-option v-for="item in isDestoryArr" :key="item.label" :label="item.label" :value="item.label" />
                      </el-select>
                      <div class="ertext">
                        <span v-if="errObj.isDestory">必选项不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 建议检测项目类别 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <span>建议检测项目类别</span>
                    </div>
                    <div class="main">
                      <el-input v-model="inputAdviceTestTypeValue" class="inp" placeholder="请输入建议检测项目类别" suffix-icon="" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- 样品使用方法 -->
              <div class="list_line">
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <span>样品使用方法</span>
                    </div>
                    <div class="main">
                      <el-input
                        v-model="inputMethodValue"
                        class="bigInp"
                        type="textarea"
                        resize="none"
                        :autosize="{ minRows: 4, maxRows: 4 }"
                        placeholder="请输入样品使用方法"
                        suffix-icon="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="list_line">
                <!-- 仪器管理员 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span>样品管理员</span>
                    </div>
                    <div class="main">
                      <el-select v-model="adminArrValue" class="select" placeholder="请选择" size="large">
                        <el-option v-for="item in userOptions" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                      <div class="ertext">
                        <span v-if="errObj.admin">必选项不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 备注 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text" id="shortTe">
                      <span>备注</span>
                    </div>
                    <div class="main">
                      <el-input v-model="inputNotesValue" class="inp" placeholder="请输入备注" suffix-icon="" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="list_line">
                <!-- 仪器管理员 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span>是否留样</span>
                    </div>
                    <div class="main">
                      <el-select v-model="ReserveSampleValue" class="select" placeholder="请选择" size="large">
                        <el-option v-for="item in ReserveSampleArr" :key="item.value" :label="item.label" :value="item.value" />
                      </el-select>
                      <div class="ertext">
                        <span v-if="errObj.admin">必选项不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 留样数量 -->
                <div class="info" v-show="ReserveSampleValue == 1">
                  <div class="list_content">
                    <div class="text" id="shortTe">
                      <span>留样数量</span>
                    </div>
                    <div class="main">
                      <el-input v-model="ReserveSampleNumber" class="inp" placeholder="请输入留样数量" suffix-icon="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 文件 -->
          <div class="upload_info">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">文件</div>
            </div>
            <div class="file">
              <el-upload
                class="div"
                ref="videoRef"
                :http-request="uploadsFile"
                :on-preview="handlePreviews"
                :on-remove="handleRemoves"
                :show-file-list="false">
                <!-- <el-icon class="el-icon--right"><Upload /></el-icon> -->
                <img class="uploadIcon" src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%E7%AE%AD%E5%A4%B4%402x.png" alt="" />
                <!-- //!样式 -->
                <span>上传附件</span>
              </el-upload>
            </div>
            <div class="Table">
              <el-table :data="attachmentList" border stripe v-show="attachmentList.length" width="1034">
                <el-table-column type="index" width="56" min-width="55" label="序号" />
                <el-table-column prop="fileName" label="文件名称" width="470" min-width="469">
                  <template #default="scope">
                    <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                      {{ scope.row.fileName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="uploadTime" label="上传时间" width="208" min-width="207">
                  <template #default="scope">
                    <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #626366">
                      {{ formatTime(scope.row.uploadTime) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="300" align="center" min-width="299">
                  <template #default="scope">
                    <!-- <div class="download" v-if="Volunteereditor" style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">下载</div>
                    <div class="delete" v-if="Volunteereditor" style="font-size: 14px; margin-left:16px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">删除</div> -->
                    <el-button
                      @click="downloadAttachment(scope.row)"
                      style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                      >下载</el-button
                    >
                    <el-button
                      @click="deleteFile(scope.row.uuid)"
                      style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 样品检测项目信息 -->
          <div class="main_info">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">样品检测项目信息</div>
            </div>
            <div class="test_main">
              <div class="test_list">
                <div class="info-item">
                  <div class="item-title">样品检测项目编号</div>
                  <div class="item-content">
                    <span>{{ testNumber }}</span>
                  </div>
                </div>
                <div class="info-item">
                  <div class="item-title">样品检测项目名称</div>
                  <div class="item-content">
                    <span>{{ testName }}</span>
                  </div>
                </div>
              </div>
              <div class="test_list">
                <div class="info-item" id="bottom_item">
                  <div class="item-title">样品检测状态</div>
                  <div class="item-content">
                    <span>{{ testStatus }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- !样品分发记录信息 -->
          <div class="main_info">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">样品分发记录信息<span class="text_test">根据发放时的输入内容自动填入</span></div>
            </div>

            <div class="Table1">
              <el-table
                border
                :data="uploadTableData"
                style="width: 1032px; margin-top: 11px; margin-left: 32px"
                :header-cell-style="{
                  // 'text-align': 'center',
                  'font-size': '16px',
                  background: '#F8F8F8 !important',
                  color: '#313233',
                  'font-weight': '400',
                  'font-family': 'Source Han Sans CN-Regular, Source Han Sans CN',
                  border: '1px solid #eeeeee !important',
                }"
                :cell-style="{
                  'font-size': '16px',
                  // 'background': '#F8F8F8 !important',
                  color: '#313233',
                  'font-weight': '400',
                  'font-family': 'Source Han Sans CN-Regular, Source Han Sans CN',
                  border: '1px solid #eeeeee !important',
                }">
                <el-table-column label="序号" width="56">
                  <template #default="scope">
                    <div style="text-align: center">{{ scope.$index + 1 }}</div>
                    <!-- </div> -->
                  </template>
                </el-table-column>
                <el-table-column label="发放日期" width="180">
                  <template #default="scope">
                    <div style="color: #626366">
                      {{ scope.row.value1 }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="发放对象" width="300">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.value1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="发放数量" width="180">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.value1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="备注" width="316">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.value1 }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- !样品回收记录信息 -->
          <div class="main_info">
            <div class="info_title">
              <div class="title_color"></div>
              <div class="title_text">样品回收记录信息<span class="text_test">根据回收时的输入内容自动填入</span></div>
            </div>

            <div class="Table1">
              <el-table
                border
                :data="uploadTableData"
                style="width: 1032px; margin-top: 11px; margin-left: 32px; margin-bottom: 42px"
                :header-cell-style="{
                  // 'text-align': 'center',
                  'font-size': '16px',
                  background: '#F8F8F8 !important',
                  color: '#313233',
                  'font-weight': '400',
                  'font-family': 'Source Han Sans CN-Regular, Source Han Sans CN',
                  border: '1px solid #eeeeee !important',
                }"
                :cell-style="{
                  'font-size': '16px',

                  color: '#313233',
                  'font-weight': '400',
                  'font-family': 'Source Han Sans CN-Regular, Source Han Sans CN',
                  border: '1px solid #eeeeee !important',
                }">
                <el-table-column label="序号" width="56">
                  <template #default="scope">
                    <div style="text-align: center">{{ scope.$index + 1 }}</div>
                    <!-- </div> -->
                  </template>
                </el-table-column>
                <el-table-column label="回收日期" width="180">
                  <template #default="scope">
                    <div style="color: #626366">
                      {{ scope.row.value1 }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="回收对象" width="300">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.value1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="回收数量" width="180">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.value1 }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="备注" width="316">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.value1 }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div style="flex: auto">
          <el-button @click="handleClose">取消</el-button>
          <el-button v-if="!props.ChangeTite" type="primary" @click="createdSample">确定</el-button>
          <el-button v-if="props.ChangeTite" type="primary" @click="createdSample">确定</el-button>
        </div>
      </template>
    </el-drawer>
  </div>
</template>
<script setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import { defineEmits, defineProps, defineComponent, ref, reactive, nextTick, toRaw, toRefs, onMounted, computed, watch } from 'vue'
import cityData from '@/utils/citydata'
import { Select, CloseBold, Upload } from '@element-plus/icons-vue'
import http from '@/utils/http'
import { message } from '@/utils/resetMessage'

import { useStore } from 'vuex'
let store = useStore()

let emits = defineEmits(['update:modelValue'])
//关闭的点击事件
let handleClose = () => {
  emits('updateChange', false)
  // is_dialogShow.value = false
}
let isShowExpire = () => {
  if (!limitedUsedDateValue.value) {
    isExpire = ''
  }
}
let props = defineProps({
  ChangeTite: Boolean,
})

watch(
  () => props.ChangeTite,
  () => {
    console.log(props.ChangeTite, 'props.ChangeTite')
  }
)
const formatTime = (timeString) => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')
  const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return currentDateTime
}
let filesArr = ref([])
// 上传附件返回信息
const attachmentList = ref([])
//文件名
const fileName = ref('')
// 上传附件事件
const uploadsFile = async (params) => {
  const file = params.file
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'attachment')
  const response = await http.post('/upload/file-upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  const { status, file: fileData } = response
  if (status === 0) {
    filesArr.value.push(fileData)
    console.log('发给后端的filesArr数组', filesArr.value)
    const t = formatTime(response.file.uploadTime)
    console.log('t', t) // 2023-05-16 14:57
    let obj = {
      fileName: response.file.fileName,
      upLoadTime: t,
      data: response.file,
    }
    attachmentList.value.push(obj)

    ElMessage({
      message: '附件上传成功',
      // grouping: true,
      showClose: true,
      type: 'success',
    })
  } else {
    ElMessage({
      message: '附件上传失败，请稍后再试',
      // grouping: true,
      showClose: true,
      type: 'warning',
    })
  }
}
const handlePreviews = (file) => {
  console.log(file, 'handlePreviews')
}
const uploadFileSuccess = (res) => {
  console.log('上传成功')
  console.log(res)
}
const downloadAttachment = async (data) => {
  console.log('附件-下载-data', data)
  console.log('点击了附件下载')
  const { downloadPath, filePath } = data
  const repsonse = await http.post(
    'https://cems.fuhuan.ltd/api/upload/attachmentDownload',
    { downloadPath: downloadPath ? downloadPath : filePath },
    { responseType: 'arraybuffer' }
  )

  const blob = new Blob([repsonse], {
    type: 'application/x-zip-compressed;charset=utf-8',
  })
  let name = '附件下载.zip'
  const downloadElement = document.createElement('a')
  let classificationhref = window.URL.createObjectURL(blob)
  downloadElement.href = classificationhref
  downloadElement.download = `${name}`
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(classificationhref)
}
const handleRemoves = (file, fileList) => {
  console.log(file, fileList, 'handleRemoves')
}
// 被删除附件（已保存）
const deleteFiles = ref([])
// 删除附件
const deleteFile = (data) => {
  console.log('点击了附件删除', data.fileName)
  console.log(filesArr.value[0].File, 'filesArr')
  // const fileDeleteIndex = filesArr.value.findIndex((item) => {
  //     console.log('item is ', item);
  //     item.name == data.fileName;
  // });
  let fileDeleteIndex = ''
  filesArr.value.forEach((item, index) => {
    if (item.name == data.fileName) {
      console.log('12312312')
      fileDeleteIndex = index
    }
  })
  console.log('fileDeleteIndex', fileDeleteIndex)
  if (fileDeleteIndex > -1) {
    filesArr.value.splice(fileDeleteIndex, 1)
    console.log('附件删除后的数组', filesArr.value)

    const deleteWhichItem = uploadTableData.value.findIndex((item22) => item22.id === data.id)
    if (deleteWhichItem > -1) {
      uploadTableData.value.splice(deleteWhichItem, 1)
      console.log('uploadTableData删除成功')
      console.log('uploadTableData删除成功后的uploadTableData', uploadTableData.value)

      //To Do  删除成功提示   别忘了
      ElMessage({
        showClose: true,
        message: '删除成功',
        type: 'success',
      })
    }
  }
}
//新建样品显示
let is_dialogShow = ref(false)
// 样品名称
let inputNameValue = ref('')
// 样品编号
let inputNumberValue = ref('')
// 样品数量
let inputCountValue = ref('')
// 样品状态
let statusArr = ref(['可用', '已发放', '已回收'])
//测样品状态选中的值
let status_val = ref('')
//样品标签
let inputLableValue = ref([])
let lableArr = ref(['成分好', '已发放', '已回收'])
//样品规格
let inputSpecsValue = ref('')
//样品性状
let inputCharacterValue = ref('')
//样品批号
let inputBatchNumberValue = ref('')
//存储条件
let inputSaveConditionValue = ref('')
// 样品接收日期
let receiveDateValue = ref('')
// 限制使用日期
let limitedUsedDateValue = ref('')
// ! 表格数据
const uploadTableData = ref([
  {
    value1: '-----',
  },
])

// 是否过期
let currentDate = ''
let isExpire = ''
let is_Expire = () => {
  console.log('object :>> ', limitedUsedDateValue.value, receiveDateValue.value)
  if (receiveDateValue.value != '') {
    if (limitedUsedDateValue.value < receiveDateValue.value) {
      limitedUsedDateValue.value = ''
      message({
        showClose: true,
        message: '限制日期不可小于接收日期',
        type: 'error',
      })
    }

    if (new Date(limitedUsedDateValue.value) < new Date(currentDate)) {
      isExpire = '是'
    } else {
      isExpire = '否'
    }
  } else {
    limitedUsedDateValue.value = ''
    message({
      showClose: true,
      message: '请先填写“样品接收日期”',
      type: 'warning',
    })
  }
}
watch(() => {
  // is_Expire();
})
// 是否销毁
let isDestoryArr = ref([
  { label: '是', value: 1 },
  { label: '否', value: 2 },
])
// 留样
const ReserveSampleArr = ref([
  { label: '是', value: true },
  { label: '否', value: false },
])
const ReserveSampleValue = ref('')
const ReserveSampleNumber = ref()
let isDestoryArrValue = ref()
// let isDestoryArrValueChange = () =>{
// if (isDestoryArrValue.value==1)
// }
// 建议检测类别
let inputAdviceTestTypeValue = ref('')
// 样品使用方法
let inputMethodValue = ref('')
// 仪器管理员
let userOptions = ref([])
let adminArrValue = ref('')
// 获取管理员信息
let getUserOptions = async () => {
  let res = await http.post('/user/getSampleManagerOptions', {
    company: localStorage.getItem('company'),
  })
  console.log(res, '获取的所有用户信息')
  if (res.status == 0) {
    userOptions.value = res.result
  } else {
    console.log('获取失败')
  }
}
// 备注
let inputNotesValue = ref('')

// === 样品检测项目信息 ===
// 样品检测项目编号
let testNumber = ref('在【项目】-【样品】中链入后自动填入')
// 样品检测项目名称
let testName = ref('在【项目】-【样品】中链入后自动填入')
// 样品检测状态
let testStatus = ref('在样品流转记录中链入后自动填入')

//必选项是否为空提示
let errObj = reactive({
  number: false,
  name: false,
  count: false,
  status: false,
})
let is_inputNumber = () => {
  if (inputNumberValue.value === '') {
    errObj.number = true
  } else {
    errObj.number = false
  }
}
watch(
  () => inputNumberValue.value,
  () => {
    is_inputNumber()
  }
)
let is_inputName = () => {
  if (inputNameValue.value === '') {
    errObj.name = true
  } else {
    errObj.name = false
  }
}

const disabledDate = (time) => {
  const today = new Date() // 当前日期
  const day = 1000 * 60 * 60 * 24 // 一天的毫秒数
  const yesterday = new Date(today.getTime() - day) // 昨天的日期
  return time.getTime() <= yesterday.getTime()
}

watch(
  () => inputNameValue.value,
  () => {
    is_inputName()
  }
)
const ErrCountText = ref()
let is_inputCount = () => {
  if (inputCountValue.value === '') {
    errObj.count = true
    ErrCountText.value = '样品数量不能为空'
  } else if (
    !isNaN(inputCountValue.value) == false ||
    !Number.isInteger(parseInt(inputCountValue.value)) ||
    parseInt(inputCountValue.value) < 0 ||
    inputCountValue.value.includes('.') ||
    inputCountValue.value.includes('-')
  ) {
    errObj.count = true
    ErrCountText.value = '样品数量格式错误'
  } else {
    errObj.count = false
  }
}
watch(
  () => inputCountValue.value,
  () => {
    is_inputCount()
    let obj = {}
  }
)

watch(
  () => limitedUsedDateValue.value,
  (newValue) => {
    if (!newValue) {
      console.log('更改数据', newValue)
    }
  },
  { immediate: true, deep: true }
)
// 判断样品状态是否勾选
let status_error = ref('样品状态不可为空')
let is_statusError = ref(false)
let is_inputStatus = () => {
  if (status_val.value === '') {
    status_error.value = '样品状态不可为空'
    is_statusError.value = true
  } else {
    status_error.value = ''
    is_statusError.value = false
  }
}
watch(
  () => status_val.value,
  () => {
    is_inputStatus()
  }
)
// 新建样品确定按钮
let createdSample = async () => {
  console.log(isExpire)
  let IDS = '' //此次样品的id
  is_inputName()
  is_inputNumber()
  // is_inputStatus();
  is_inputCount()
  console.log(errObj)
  // if (status_val.value !== "") {
  if (errObj.name == false && errObj.number == false && errObj.count == false && is_statusError.value == false) {
    await http
      .post('/sample/create', {
        // 样品基础信息
        //样品名称
        name: inputNameValue.value,
        //样品编号
        code: inputNumberValue.value,
        // 样品数量
        total: Number(inputCountValue.value),
        // 可发放数量
        available_quantity: Number(inputCountValue.value),
        // 样品状态
        // // sample_status: status_val.value,
        // files: filesArr.value || [],
        //  样品规格
        specs: inputSpecsValue.value,
        //  样品标签
        tag: inputLableValue.value,
        //  样品性状
        properties: inputCharacterValue.value,
        //  样品批号
        batch_code: inputBatchNumberValue.value,
        // 存储条件
        storage_conditions: inputSaveConditionValue.value,
        // 样品接收日期
        acceptance_date: receiveDateValue.value,
        // 限制使用日期
        expiration_date: limitedUsedDateValue.value,
        // 是否过期
        isexpired: isExpire == '是' ? true : false,
        // 是否销毁
        isdestory: isDestoryArrValue.value == 1 ? true : false,
        // 建议检测类别
        testProject_suggest: inputAdviceTestTypeValue.value,
        // 样品使用方法
        usage_instructions: inputMethodValue.value,
        // 样品管理员
        manager: adminArrValue.value,
        // 备注
        remark: inputNotesValue.value,
        // 是否留样
        isretained: ReserveSampleValue.value == '' ? false : ReserveSampleValue.value,
        //留样数量
        retentionNum: Number(ReserveSampleNumber.value),
      })
      .then((res) => {
        console.log(res, '111')
        if (res.status === 0) {
          IDS = res.sample_id
          ElMessage({
            showClose: true,
            message: '新建样品成功',
            type: 'success',
          })
          // application(IDS);
          handleClose()
        } else if (res.status === 1) {
          ElMessage({
            showClose: true,
            message: '样品编号重复',
            type: 'warning',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // } else {
  //   is_statusError.value=true;
  //   console.log("样品状态不能为空");
  // }
}

let SampleTags = ref([])
// 请求初始数据
const ReqDefault = async () => {
  const res = await http.post('/setting/fetch-setting', {
    type: 'sample',
  })
  if (res.status == 0) {
    console.log('res请求初始数据为 :>> ', res)

    res.tags.forEach((item, index) => {
      if (item.value != false) {
        SampleTags.value.push(item.name)
      }
    })

    if (SampleTags.value.length == 0) {
      SampleTags.value.push('暂无标签，请前往【自定义设置】新建')
    }
    console.log('获取的最终数据', SampleTags.value)
  }
}

onMounted(() => {
  getUserOptions()
  isShowExpire()
  ReqDefault()
  const date = new Date()
  currentDate = date.toISOString().slice(0, 10)
  console.log(currentDate)
})
</script>

<style lang="less" scoped>
@import url('@/style/components/sampleManagement/Createdsample.less');
</style>
