<template>
  <div id="TestRecords">
    <!-- <h2>排期表</h2> -->
    <div class="project_main" v-if="(!route.query._id || store.state.Project.ProjectId) && projectSchemeData.length != 0">
      <div class="project_main_state">
        <!-- 筛选status状态按钮 -->
        <div
          class="main_state_item"
          :class="item.day == ClickActive ? 'click-active' : ''"
          v-for="(item, index) in projectSchemeData.plan"
          :key="index"
          @click="ProjectStateChange(item.day)">
          <div class="state_item_index">
            {{ ProjectState[index] }}
          </div>
          <div class="state_item_name">
            {{ item.day || item.day == 0 ? '(D' + item.day + ')' : '第一次' }}
            <!-- {{ demo1.tableData[0].check1 }} -->
          </div>
        </div>
      </div>

      <div class="ProjectListBox">
        <div class="vxetable">
          <!-- 表格头部 工具按钮 -->
          <vxe-toolbar>
            <template #buttons>
              <vxe-input
                class="Search"
                v-model="demo1.filterName"
                type="search"
                placeholder="请输入志愿者编号或姓名进行搜索"
                @change="searchProjectChange"></vxe-input>
              <vxe-button @click="ResetTable" class="Reset"><span class="reset-icon">重置</span></vxe-button>

              <vxe-button class="CreateProject" @click="subsetArrChange"></vxe-button>

              <vxe-button @click="isShowExport" class="Import"></vxe-button>
            </template>
          </vxe-toolbar>
          <!-- 表格 -->
          <div class="tableOutSide" v-if="demo1.tableData.length != 0">
            <vxe-table
              border
              stripe
              ref="xTable"
              height="auto"
              auto-resize
              show-overflow
              :loading="demo1.loading"
              :data="demo1.tableData"
              :column-config="{ resizable: true }"
              :tooltip-config="tooltipConfig"
              :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
              row-id="_id"
              :checkbox-config="{ reserve: true }"
              @header-cell-dragend="handleHeaderDragend"
              @checkbox-all="selectAllChangeEvent1"
              @checkbox-change="selectChangeEvent1"
              >>
              <vxe-column field="checked" type="checkbox" width="41" min-width="40" :fixed="demo1.colFixeds.col0"></vxe-column>
              <vxe-column field="checked" type="seq" title="序号" width="50" :fixed="demo1.colFixeds.col0"></vxe-column>
              <vxe-column
                field="number"
                type="html"
                title="志愿者编号"
                :width="options.length < 3 ? 200 : 184"
                :visible="ColumnVisible[0]"
                :fixed="demo1.colFixeds.col1"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === '志愿者编号'">
                    <el-tooltip class="box-item" effect="dark" content="志愿者编号" placement="top">
                      <span class="name">志愿者编号</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('志愿者编号', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="name" type="html" title="姓名" :width="options.length < 3 ? 250 : 100" :fixed="demo1.colFixeds.col2" min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === '姓名'">
                    <el-tooltip class="box-item" effect="dark" content="姓名" placement="top">
                      <span class="name">姓名</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('姓名', $event)"></i></span>
                  </div> </template
              ></vxe-column>
              <!-- 组别 -->
              <vxe-column
                field="group"
                type="html"
                title="组别"
                :width="options.length < 3 ? 250 : 100"
                :fixed="demo1.colFixeds.col5"
                min-width="150">
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '组别'">
                    <span class="name">组别</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('组别', $event)"></i></span>
                  </div>
                </template>

                <template #default="{ row }">
                  {{ row.group === '' ? '——' : row.group }}
                </template>
              </vxe-column>
              <!-- 知情同意书 -->
              <vxe-column
                field="zhiqing"
                type="html"
                title="知情同意书"
                :width="options.length < 3 ? 220 : 100"
                :fixed="demo1.colFixeds.col5"
                min-width="150">
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '知情同意书'">
                    <span class="name">知情同意书</span>
                    <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('知情同意书', $event)"></i></span> -->
                  </div>
                </template>
                <template #default="{ row }">
                  <div class="project_consent" @click="lookZhiQing(row)">
                    <div class="demo-image__preview" v-if="row.informed_content == '' || row.informed_content == undefined">
                      <img src="https://newstore.vynior.com/%E7%BB%84%20110127%402x%20%281%29.png" alt="" />
                    </div>

                    <!-- <div v-else>
      <img src="https://newstore.vynior.com/%E7%BB%84%20110127%402x%20%281%29.png" alt="">
  </div> -->

                    <div v-else>
                      <el-image
                        src="https://newstore.vynior.com/%E7%BB%84%20110127%402x.png"
                        :zoom-rate="1.2"
                        :preview-src-list="[row.informed_content]"
                        :initial-index="4"
                        fit="cover" />
                    </div>

                    <!-- <img
                    :src="
                      row.zhiqing
                        ? 'https://newstore.vynior.com/%E7%BB%84%20110127%402x.png'
                        : 'https://newstore.vynior.com/%E7%BB%84%20110127%402x%20%281%29.png'
                    " /> -->
                  </div>
                </template>
              </vxe-column>
              <!-- 环节 -->
              <vxe-column
                v-for="(item, index) in options"
                :key="index"
                :field="item.checkeds"
                type="html"
                :title="item.names"
                :width="options.length < 3 ? 220 : 150"
                :fixed="demo1.colFixeds.col5"
                min-width="150">
                <template #header="{ column }">
                  <div class="Address-option" v-if="column.title === item.names">
                    <p class="names">{{ item.names }}</p>
                    <p class="name">{{ item.name }}</p>
                    <!-- <p><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('知情同意书', $event)"></i></p> -->
                  </div>
                </template>
                <template #default="{ row }">
                  <div class="vxe_check">
                    <vxe-checkbox
                      class=""
                      :disabled="row.status"
                      @change="optionsCheckChange(row, index)"
                      v-model="row['check' + (index + 1)]"
                      size="mini"></vxe-checkbox>
                  </div>
                </template>
              </vxe-column>
              <vxe-column title="操作" width="300" :fixed="demo1.colFixeds.col9" min-width="250">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Operation-header" v-if="column.title === '操作'">
                    <span class="name">操作</span>
                    <!-- <p><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></p> -->
                  </div>
                </template>
                <template #default="{ row }">
                  <!-- <template #default="{ row }"> -->
                  <div class="Address-set">
                    <el-tooltip class="box-item" effect="dark" content="分组" placement="top">
                      <span class="set_subset" @click="subSetChange(row)">分组</span>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="查看详情" placement="top">
                      <span class="set_detail" @click="testDetailsChange(row)">查看详情</span>
                    </el-tooltip>

                    <el-tooltip class="box-item" effect="dark" content="确认完成" placement="top">
                      <span class="set_ok" @click="testOkShowChange(row)" v-if="row.ok && !row.status">确认完成</span>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="确认完成" placement="top">
                      <span class="set_no" v-if="!row.ok && !row.status">确认完成</span>
                    </el-tooltip>
                    <el-tooltip class="box-item" effect="dark" content="已完成" placement="top">
                      <span class="set_no" v-if="row.status">已完成</span>
                    </el-tooltip>
                  </div>
                  <!-- <vxe-button class="detail" @click="ShowDetails(row)">查看详情</vxe-button> -->
                  <!-- <vxe-button class="copy">导出</vxe-button> -->
                  <!-- <vxe-button class="delete" @click="DeleteVolun(row)">删除</vxe-button> -->
                </template>
              </vxe-column>
              <template #empty>
                <span style="color: red" v-if="demo1.tableData.length == 0">
                  <!-- <img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif" /> -->
                  <p>没有更多数据了！</p>
                </span>
              </template>
            </vxe-table>
          </div>

          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
              </ul>
            </div>
          </div>
          <!-- 批量志愿者信息  导出 -->
          <div class="Derived_volunteer" v-if="isExport">
            <div class="Derived_volunteer_box">
              <!-- 导出一个 -->
              <div v-if="checkedArr.length == 1">
                <div class="Derived_title">
                  确定导出
                  <span class="Derived_name">{{ selectedRows[0].name == ' ' ? '无名字' : selectedRows[0].name }}</span>
                  的测试记录信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="exportDataEvent">确认</div>
                </div>
              </div>
              <!-- 导出多个 -->
              <div v-if="checkedArr.length > 1">
                <div class="Derived_title">
                  已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>测试记录信息，确认导出吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="exportDataEvent">确认</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 导出一条没有的时候 -->
          <div class="Derived_volunteer_null" v-if="null_reminder_export">
            <!-- 导出 -->
            <div class="Derived_volunteer_box">
              <div class="Derived_title">
                <div class="Derived_name">提示</div>
                <div><img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
              </div>
              <div class="Derived_body">
                <div><img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" /></div>
                <div class="Derived_body_text">请选择需要导出的测试记录信息！</div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <p v-show="demo1.tableData.length != 0">
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                  >条/共{{ page5.totalResult }}条
                </div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
      <!-- // 空数据页面 -->
      <div class="EmptyProject" v-if="demo1.tableData.length == 0">
        <span>
          <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
          <p>暂无数据</p>
        </span>
      </div>
      <!-- 表头字段设置 -->
      <div class="SetTableHeader" v-if="SetTableHeader">
        <div class="main">
          <div class="Header">
            <div class="text">表头字段设置</div>
          </div>
          <!-- 设置表头的数据盒子 -->
          <div class="SetBox">
            <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
            <div class="LeftBox">
              <div class="text-header">项目列表预设字段</div>
              <div class="ProjectRowField">
                <div class="text-header1">【项目基本信息】</div>
                <!-- 复选框数组 -->
                <div class="CheckBox">
                  <div class="Checkbox" v-for="(item, index) in SetTableHeaderArr.slice(0, 11)" :key="index">
                    <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                  </div>
                </div>
              </div>
              <!-- 【项目招募信息】 -->
              <div class="ProjectRecruitMSG">
                <div class="text-header2">【项目招募信息】</div>
                <!-- 复选框数组 -->
                <div class="CheckBox">
                  <div class="Checkbox" v-for="(item, index) in SetTableHeaderArr.slice(11, 24)" :key="index">
                    <el-checkbox v-model="item.checked" :label="item.lable" size="large" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 右边盒子 拖动 -->
            <div class="RightBox">
              <div class="text-header">项目列表预设字段</div>
              <div style="font-size:16px; color='black';margin-top:150px">此功能正在开发中,敬请期待</div>
            </div>
          </div>
          <!-- 底部 -->
          <div class="Bottom">
            <div class="add">
              <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
            </div>
            <div class="recover">
              <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
            </div>
            <div class="cancel" @click="SetTableHeader = false">
              <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" />
            </div>
            <div class="save" @click="ShowHidenColumn">
              <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 未选择项目时 -->
    <div class="test_mains" v-if="!route.query._id && !store.state.Project.ProjectId">
      <div class="mains_title">
        <div class="title_item">
          <div class="item_text">
            <span>第一次</span>
          </div>
          <div class="item_val">(D0)</div>
        </div>
        <div class="mains_box">
          <div class="box_inp">
            <el-input class="inp" v-model="demo1.filterName" placeholder="搜索" />
            <span class="inp_img"></span>
          </div>
          <div class="box_resetting">重置</div>
          <div class="box_quantity"></div>
          <div class="box_export"></div>
        </div>
      </div>
      <!-- 空页面 -->
      <div class="Empty">
        <div class="Img">
          <img src="https://newstore.vynior.com/12%402x.png" alt="" />
        </div>
        <div class="Text">
          <p>请选择项目,查看对应的测试记录</p>
        </div>
      </div>
    </div>
    <!-- 项目没有方案时 -->
    <div class="test_mains" v-if="(route.query._id || store.state.Project.ProjectId) && projectSchemeData.length == 0">
      <div class="mains_title">
        <div class="title_item">
          <div class="item_text">
            <span>第一次</span>
          </div>
          <div class="item_val">(D0)</div>
        </div>
      </div>
      <div class="mains_box">
        <div class="box_inp">
          <el-input class="inp" v-model="demo1.filterName" placeholder="搜索" />
          <span class="inp_img"></span>
        </div>
        <div class="box_resetting">重置</div>
        <div class="box_quantity"></div>
        <div class="box_export"></div>
      </div>
      <div class="mains_tip">
        测试环节信息不完整，无法生成表单，去
        <span class="tip" @click="toProjectPlanChange">完善</span>
      </div>
    </div>
    <div class="test_subset" v-if="subsetShow">
      <el-dialog
        v-model="subsetShow"
        title="分组"
        align-center="true"
        class="subset_dialog"
        :show-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <div class="subset_main">
          <div class="main_title">
            已选中<span class="title_val">{{ subsetName }}</span
            >的志愿者信息，进行分组：<span class="title_tip">（可在自定义设置中新建组别）</span>
          </div>
          <div class="main_select">
            <el-select
              v-model="subsetArrValue"
              popper-class="sebset_selects"
              :popper-append-to-body="false"
              class="subset_select"
              placement="bottom"
              placeholder="请选择"
              size="large">
              <el-option class="subset_option" v-for="item in subsetArr" :key="item" :label="item" :value="item" />
            </el-select>
          </div>
          <div class="main_but">
            <el-button class="el_cancel" @click="subsetCancelChange">取消</el-button>
            <el-button class="el_primary" @click="subsetOkChange" type="primary">完成</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 批量分组 -->
    <div class="test_subset" v-if="subsetArrShow">
      <el-dialog
        v-model="subsetArrShow"
        title="批量分组"
        align-center="true"
        class="subset_dialog"
        :show-close="true"
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <div class="subset_main">
          <div class="main_title">
            已选中<span class="title_val">{{ checkedArr.length }}条</span>志愿者信息，进行批量分组：<span class="title_tip"
              >（可在自定义设置中新建组别）</span
            >
          </div>
          <div class="main_select">
            <el-select v-model="subsetArrValue" class="subset_select" popper-class="sebset_selects" placeholder="请选择" size="large">
              <el-option class="123456" v-for="item in subsetArr" :key="item" :label="item" :value="item" />
              <!-- <div slot="content" class="content123"></div> -->
            </el-select>
          </div>
          <div class="main_but">
            <el-button class="el_cancel" @click="subsetArrCancelChange">取消</el-button>
            <el-button class="el_primary" @click="subsetArrOkChange" type="primary">完成</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
    <div id="TestRecordsDetails">
      <el-drawer
        v-model="TestRecordsDetailsShow"
        title="测试记录"
        class="test_drawer"
        :close-on-click-modal="true"
        :close-on-press-escape="false"
        :show-close="false">
        <div class="drawer_main">
          <div class="main_info">
            <div class="info_tile">
              <div class="title_color"></div>
              <div class="title_val">志愿者信息</div>
            </div>
            <div class="info_main">
              <div class="main_number">
                <div class="number_text">志愿者编号</div>
                <div class="number_val">{{ volunteerMsg.number }}</div>
              </div>
              <div class="main_name">
                <div class="name_text">姓名</div>
                <div class="name_val">{{ volunteerMsg.name }}</div>
              </div>
              <div class="main_subset">
                <div class="subset_text">组别</div>
                <div class="subset_val">{{ volunteerMsg.group }}</div>
              </div>
            </div>
            <div class="info_tiles">
              <div class="title_color"></div>
              <div class="title_val">测试记录详情</div>
            </div>
            <div class="info_test">
              <el-table border :data="options" stripe style="width: 100%" class="T-Header">
                <el-table-column class-name="table_segment" prop="names" width="72" label="环节" />
                <el-table-column class-name="table_box" prop="name" width="252" label="具体内容" />
                <el-table-column class-name="table_director" prop="director" width="180" label="负责人" />
                <el-table-column class-name="table_finish" prop="check" width="148" label="完成情况">
                  <template #default="scope">
                    <div class="img">
                      <img v-if="scope.row.check == true" src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110249%402x.png" alt="" />
                      <img v-else src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110249%402x%20%281%29.png" alt="" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column class-name="table_ok" prop="director" width="180" label="确认人" />
                <el-table-column class-name="table_oktime" prop="ComfirmTime" width="200" label="确认时间" />
              </el-table>
            </div>
            <!-- <div class="info_but">
              <el-button class="but_el" @click="DetailsShowChange">关闭</el-button>
            </div> -->
          </div>
        </div>
        <template #footer>
          <el-button class="Footer" @click="DetailsShowChange">关闭</el-button>
        </template>
      </el-drawer>
    </div>
    <div class="BatchConfirm" v-if="testOkShow">
      <div class="ConfirmBox">
        <div class="Title">确认测试完成？</div>
        <div class="Content">
          <div class="img">
            <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" alt="" />
          </div>
          <div class="text">确认测试完成后不可修改</div>
        </div>
        <div class="Buttons">
          <button class="Cancel" @click="testOkShow = false">取消</button>
          <button class="Confirm" @click="testOkChange">确定</button>
        </div>
      </div>
    </div>
    <!-- <TestRecordsDetails></TestRecordsDetails> -->
  </div>
</template>
<script setup>
import { computed, nextTick, onMounted, onBeforeUnmount, reactive, ref, watch, watchEffect } from 'vue';
import XEUtils from 'xe-utils';
// import TestRecordsDetails from '@/components/VolunteerManagement/TestRecordsDetails.vue'
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
import { ElMessage, dayjs } from 'element-plus';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import * as xlsx from 'xlsx';

const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

//API接收
const store = useStore();
const router = useRouter();
const route = useRoute();
// 展示的环节
const options = ref([]);
const isExport = ref(false);
const null_reminder_export = ref(false);
//note--->demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  //  展示的数据
  tableData: [{ check1: false, ok: false }],
  filterName: '',
  loading: true,
  list: [],
});

// 测试记录志愿者信息
const volunteerMsg = reactive({
  number: '',
  name: '',
  group: '',
});

// 测试记录详情默认隐藏
let TestRecordsDetailsShow = ref(false);

// ! 测试的表格数据
const tableData = ref([
  {
    segment: '环节一',
    main: '签署知情同意书',
    director: 'xu',
    finish: false,
    ok: 'xu',
    oktime: '2023-05-20  13:30',
  },
  {
    segment: '环节二',
    main: '签署知情同意书',
    director: 'xu',
    finish: false,
    ok: 'xu',
    oktime: '2023-05-20  13:30',
  },
  {
    segment: '环节二',
    main: '脸部清洗及静候30min',
    director: 'xu',
    finish: false,
    ok: 'xu',
    oktime: '2023-05-20  13:30',
  },
  {
    segment: '环节三',
    main: '面部图像拍摄',
    director: 'xu',
    finish: false,
    ok: 'xu',
    oktime: '2023-05-20  13:30',
  },
  {
    segment: '环节四',
    main: '面部图像拍摄',
    director: 'xu',
    finish: false,
    ok: 'xu',
    oktime: '2023-05-20  13:30',
  },
  {
    segment: '环节五',
    main: '探头测量',
    director: 'xu',
    finish: false,
    ok: 'xu',
    oktime: '2023-05-20  13:30',
  },
  {
    segment: '环节六',
    main: '产品使用讲解',
    director: 'xu',
    finish: false,
    ok: 'xu',
    oktime: '2023-05-20  13:30',
  },
  {
    segment: '最终复核',
    main: '测试完成',
    director: 'xu',
    finish: false,
    ok: 'xu',
    oktime: '2023-05-20  13:30',
  },
]);

// 查看详情
const testDetailsChange = (row) => {
  console.log('row :>> ', row);
  TestRecordsDetailsShow.value = true;
  volunteerMsg.number = row.number;
  volunteerMsg.group = row.group;
  volunteerMsg.name = row.name;

  options.value.forEach((item, index) => {
    let i = index + 1;
    item.check = row.status;
    // item.ComfirmTime = row.ComfirmTime[index];
  });
  console.log('options :>> ', options.value);
};
// 确认测试完成弹窗
const testOkShow = ref(false);
// 确认测试完成的数据
let testOkDate = ref();
// 点击确认完成
const testOkShowChange = async (row) => {
  if (!store.state.role_info.includes('volunteer-testRecords-confirmCompletion')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  testOkShow.value = true;
  testOkDate.value = row._id;
  console.log(row, '点击确认完成');
  // const result = await http.post('/Test/finish-test', {
  //   test_id: row._id,
  //   // project:'6461d922b8effe541ef4bbc4'
  // });
  // console.log(result,'确认完成返回数据');
};
// 点击确认完成的确认
const testOkChange = async (res) => {
  // console.log(row,'点击确认完成');
  const result = await http.post('/test/finishOverall-test', {
    testRecordId: testOkDate.value,
    // project:'6461d922b8effe541ef4bbc4'
  });
  testOkShow.value = false;
  console.log(result, '确认完成返回数据');
  projectTestRecordsDataChange(ClickActive.value);
};
const DetailsShowChange = () => {
  TestRecordsDetailsShow.value = false;
};
onMounted(() => {
  ReqDefault();
  projectTestRecordsDataChange(ClickActive.value);
  projectSchemeDataChange();
  console.log('demo1.tableData :>> ', demo1.tableData);
  //处理点击更多操作盒子 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
    }
  });
  store.commit('Set_CurrentProjectName', '测试记录');
});
watch(
  () => route.query._id,
  () => {
    console.log('项目id变化');
    console.log(route.query._id);

    projectTestRecordsDataChange(ClickActive.value);
    projectSchemeDataChange();
  },
);

watch(
  () => store.state.Project.ProjectId,
  (newValue) => {
    ClickActive.value = 0;
    const $table = xTable.value;
    console.log($table, '$table ');
    if ($table) {
      console.log('123');
      $table.clearCheckboxRow();
    }
    checkedArr.value.splice(0, checkedArr.value.length);
  },
);
// 全部的测试记录
let projectData = ref([]);
// 展示的测试记录
let projectDetailsData = ref([]);
// 项目方案
let projectSchemeData = ref([]);
// 获取项目方案
const projectSchemeDataChange = async () => {
  console.log(route.query._id);
  if (!route.query._id && !store.state.Project.ProjectId) {
    return;
  }
  const result = await http.post('/project/findSchemeById-project', {
    projectId: route.query._id ? route.query._id : store.state.Project.ProjectId,
    // project:'6461d922b8effe541ef4bbc4'
  });
  console.log(result, '报告的测试方案');
  if (result.scheme == [] || result.scheme.length == 0) {
    projectSchemeData.value = [];
    console.log('没有测试方案');
    return;
  }
  projectSchemeData.value = result.scheme;
  console.log(projectSchemeData.value, 'projectSchemeData.value');
  const $table = xTable.value;
  await nextTick();
  // await $table.refreshColumn();
};
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 1,
});

// 路由query参数发生变化，测试记录数据重新获取
watch(
  () => route.query._id,
  () => {
    console.log('路由query参数发生变化', route.query._id);
  },
);

// 获取所有测试环节状态
const projectTestRecordsDataChange = async (val) => {
  // console.log(route.query._id);
  const result = await http.post('/test/findTestRecord-test', {
    page: page5.currentPage,
    size: page5.pageSize,
    content: demo1.filterName,
    // Test: {
    // project: route.query._id,
    project: route.query._id ? route.query._id : store.state.Project.ProjectId,
    currnetPlan: val,
    sort: {
      createdAt: -1,
    },
    // },
  });
  console.log(result, '项目的测试记录');
  page5.totalResult = result.totalCount;
  if (!result.testRecords) {
    return;
  }
  page5.totalResult = result.totalCount;
  projectData.value = result;
  console.log('projectData.value', projectData.value);
  let arr = [];
  let obj = {};
  let arrs = [];
  projectData.value.testRecords.forEach((item, index) => {
    if (index === 0) {
      console.log(item, 'item');
      item.detection_proces.forEach((items, indexs) => {
        let objs = {};
        objs.name = items.name;
        objs.names = '环节' + toChinesNum(indexs + 1);
        objs.checkeds = 'checked' + indexs;
        objs.director = items.director;
        objs.ComfirmTime = items.confirm_time;
        objs.confirm_user = items.confirm_user;
        arrs.push(objs);
      });
      options.value = arrs;
      console.log(options.value, 'option');
    }
    obj = {};
    obj.informed_content = item.informed_consent; //知情同意书地址
    obj.number = item.volun_id;
    obj.name = item.volun_name;
    obj.group = item.group;
    // obj.zhiqing = item.detection_process[0];
    // console.log(item,'item');
    if (item.informed_content) {
      obj.zhiqing = true;
    }
    // console.log(item.informed_content,'item.informed_content');
    obj._id = item._id;
    obj.ComfirmTime = item.confirm_time;
    let info = true;
    obj.status = item.status;
    item.detection_proces.forEach((item, index) => {
      obj['check' + (index + 1)] = item.status;
      if (item.status == false) {
        info = false;
      }
      obj.ok = info;
    });
    arr.push(obj);
  });
  demo1.tableData = arr;
  console.log(demo1.tableData, 'demo1.tableData');
};
// 测试记录的所有方案文字
const ProjectState = ref('');
ProjectState.value = ['第一次', '第二次', '第三次', '第四次', '第五次', '第六次', '第七次', '第八次', '第九次', '第十次'];
// 点击切换不同方案时，页面更新
const ProjectStateChange = async (val) => {
  ClickActive.value = val;
  projectTestRecordsDataChange(ClickActive.value);
};
// 点击切换环节状态时
const optionsCheckChange = async (val, ind) => {
  if (!store.state.role_info.includes('volunteer-testRecords-confirmCompletion')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  console.log(val, ind);
  const result = await http.post('/test/confirmOrCancel-test', {
    testID: val._id,
    value: val['check' + (ind + 1)],
    index: ind,
    user: localStorage.getItem('_id'),
  });
  console.log(result, 'result');
  projectTestRecordsDataChange(ClickActive.value);
};

let inputValue = ref('');
const getinptChange = () => {
  console.log('123');
};
// 将数值换成中文
const toChinesNum = (num) => {
  let changeNum = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  let unit = ['', '十', '百', '千', '万'];
  num = parseInt(num);
  let getWan = (temp) => {
    let strArr = temp.toString().split('').reverse();
    let newNum = '';
    let newArr = [];
    strArr.forEach((item, index) => {
      newArr.unshift(item === '0' ? changeNum[item] : changeNum[item] + unit[index]);
    });
    let numArr = [];
    newArr.forEach((m, n) => {
      if (m !== '零') numArr.push(n);
    });
    if (newArr.length > 1) {
      newArr.forEach((m, n) => {
        if (newArr[newArr.length - 1] === '零') {
          if (n <= numArr[numArr.length - 1]) {
            newNum += m;
          }
        } else {
          newNum += m;
        }
      });
    } else {
      newNum = newArr[0];
    }

    return newNum;
  };
  let overWan = Math.floor(num / 10000);
  let noWan = num % 10000;
  if (noWan.toString().length < 4) {
    noWan = '0' + noWan;
  }
  return overWan ? getWan(overWan) + '万' + getWan(noWan) : getWan(num);
};

// 分组对话框显示与隐藏
let subsetShow = ref(false);
// 下拉菜单数组
let subsetArr = ref([]);
// 下拉菜单选中的值
let subsetArrValue = ref('');
// 点击完成后的值
let subsetArrOkValue = ref('');
// 选中分组的志愿者名称
let subsetName = ref();
// 点击分组的数据
let subsetVolunteerData = ref({});
// 点击志愿者的分组按钮
const subSetChange = (res) => {
  if (!store.state.role_info.includes('volunteer-testRecords-singleGroup')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  subsetArrValue.value = '';
  console.log(res);
  subsetVolunteerData.value = res;
  subsetName.value = res.name;
  subsetShow.value = true;
};
// 点击分组的取消按钮
const subsetCancelChange = () => {
  console.log('点击取消');
  subsetShow.value = false;
  subsetArrValue.value = JSON.parse(JSON.stringify(subsetArrOkValue.value));
};
// 点击分组完成
const subsetOkChange = async () => {
  console.log('点击完成');
  if (subsetArrValue.value == '') {
    ElMessage({
      showClose: true,
      message: '请选择分组',
      type: 'error',
    });
    return;
  }
  subsetShow.value = false;
  console.log(subsetArrValue.value, '下拉菜单选中的值');
  subsetArrOkValue.value = JSON.parse(JSON.stringify(subsetArrValue.value));
  console.log(subsetVolunteerData.value, '选中用户的数据');
  let arr = [];
  arr.push(subsetVolunteerData.value._id);
  const result = await http.post('/test/batchGroup-test', {
    testList: arr,
    // Test: {
    group: subsetArrValue.value,
    // },
    // project:'6461d922b8effe541ef4bbc4'
  });
  ElMessage({
    showClose: true,
    message: '分组成功',
    type: 'success',
  });
  console.log(result, '返回的数据');
  console.log('完成分组');
  projectTestRecordsDataChange(ClickActive.value);
};

// 复选框数组
const checkedArr = ref([]);
// 批量分组显示隐藏
let subsetArrShow = ref(false);
// 点击批量分组
const subsetArrChange = () => {
  if (!store.state.role_info.includes('volunteer-testRecords-batchGroup')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  if (checkedArr.value.length < 2) {
    ElMessage({
      showClose: true,
      message: '请选择两条以上的志愿者',
      type: 'error',
    });
    return;
  }
  subsetArrValue.value = '';
  console.log('点击批量分组');
  subsetArrShow.value = true;
};
// 点击批量分组的取消按钮
const subsetArrCancelChange = () => {
  console.log('点击取消');
  subsetArrShow.value = false;
  subsetArrValue.value = JSON.parse(JSON.stringify(subsetArrOkValue.value));
};
// 点击批量分组完成
const subsetArrOkChange = async () => {
  console.log('点击完成');
  if (subsetArrValue.value == '') {
    // ElMessage({
    //   showClose: true,
    //   message: '请选择分组',
    //   type: 'error',
    // });
    return;
  }
  subsetArrShow.value = false;
  console.log(subsetArrValue.value, '下拉菜单选中的值');
  subsetArrOkValue.value = JSON.parse(JSON.stringify(subsetArrValue.value));
  console.log(subsetVolunteerData.value, '选中用户的数据');
  console.log(checkedArr.value, '批量选中的数组');
  const result = await http.post('/test/batchGroup-test', {
    // batchData: {
    group: subsetArrValue.value,
    testList: checkedArr.value,
    // },
  });
  console.log(result, '返回的数据');
  console.log('完成分组');
  projectTestRecordsDataChange(ClickActive.value);
  setTimeout(() => {
    message({
      message: '批量分组成功',
      showClose: true,
      type: 'success',
    });
  }, 100);
};
//跳转页面显示数据处理
const pageChange = async () => {
  console.log('pageChange', page5, ClickActive.value);
  const result = await http.post('/test/findTestRecord-test', {
    page: page5.currentPage,
    size: page5.pageSize,
    content: demo1.filterName,
    // Test: {
    // project: route.query._id,
    project: route.query._id ? route.query._id : store.state.Project.ProjectId,
    currnetPlan: ClickActive.value,
    sort: SORT.value,
    // },
  });
  console.log(result, '项目的测试记录');
  page5.totalResult = result.totalCount;
  if (!result.testRecords) {
    return;
  }
  page5.totalResult = result.totalCount;
  projectData.value = result;
  console.log('projectData.value', projectData.value);
  let arr = [];
  let obj = {};
  let arrs = [];
  projectData.value.testRecords.forEach((item, index) => {
    if (index === 0) {
      console.log(item, 'item');
      item.detection_proces.forEach((items, indexs) => {
        let objs = {};
        objs.name = items.name;
        objs.names = '环节' + toChinesNum(indexs + 1);
        objs.checkeds = 'checked' + indexs;
        objs.director = items.director;
        objs.ComfirmTime = items.confirm_time;
        objs.confirm_user = items.confirm_user;
        arrs.push(objs);
      });
      options.value = arrs;
      console.log(options.value, 'option');
    }
    obj = {};
    obj.informed_content = item.informed_content; //知情同意书地址
    obj.number = item.volun_id;
    obj.name = item.volun_name;
    obj.group = item.group;
    // obj.zhiqing = item.detection_process[0];
    // console.log(item,'item');
    if (item.informed_content) {
      obj.zhiqing = true;
    }
    // console.log(item.informed_content,'item.informed_content');
    obj._id = item._id;
    obj.ComfirmTime = item.confirm_time;
    let info = true;
    obj.status = item.status;
    item.detection_proces.forEach((item, index) => {
      obj['check' + (index + 1)] = item.status;
      if (item.status == false) {
        info = false;
      }
      obj.ok = info;
    });
    arr.push(obj);
  });
  demo1.tableData = arr;
  console.log(demo1.tableData, '查询的数据');
};
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  console.log(checked ? '所有勾选事件' : '所有取消事件', records);
  selectedRows = records;
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id);
      }
    }
    console.log('最新:', checkedArr.value);
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = [];

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id);
    });

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item);
    });
    console.log('最新:', checkedArr.value);
  }
};
// 复选框点击事件
const selectChangeEvent1 = (row, checked) => {
  // console.log('最新:', row);

  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records;
  console.log(checkedArr.value);
  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid);
  } else {
    let index = checkedArr.value.indexOf(row.rowid);
    checkedArr.value.splice(index, 1);
  }
  console.log('最新:', checkedArr.value);
};
//收索测试记录
const searchProjectChange = async () => {
  const result = await http.post('/test/findTestRecord-test', {
    page: page5.currentPage,
    size: page5.pageSize,
    content: demo1.filterName,
    // Test: {
    // project: route.query._id,
    project: route.query._id ? route.query._id : store.state.Project.ProjectId,
    currnetPlan: ClickActive.value,
    sort: {
      createdAt: -1,
    },
    // },
  });
  console.log(result, '项目的测试记录');
  page5.totalResult = result.totalCount;
  if (!result.testRecords) {
    return;
  }
  page5.totalResult = result.totalCount;
  projectData.value = result;
  console.log('projectData.value', projectData.value);
  let arr = [];
  let obj = {};
  let arrs = [];
  projectData.value.testRecords.forEach((item, index) => {
    if (index === 0) {
      console.log(item, 'item');
      item.detection_proces.forEach((items, indexs) => {
        let objs = {};
        objs.name = items.name;
        objs.names = '环节' + toChinesNum(indexs + 1);
        objs.checkeds = 'checked' + indexs;
        objs.director = items.director;
        objs.ComfirmTime = items.confirm_time;
        objs.confirm_user = items.confirm_user;
        arrs.push(objs);
      });
      options.value = arrs;
      console.log(options.value, 'option');
    }
    obj = {};
    obj.informed_content = item.informed_content; //知情同意书地址
    obj.number = item.volun_id;
    obj.name = item.volun_name;
    obj.group = item.group;
    // obj.zhiqing = item.detection_process[0];
    // console.log(item,'item');
    if (item.informed_content) {
      obj.zhiqing = true;
    }
    // console.log(item.informed_content,'item.informed_content');
    obj._id = item._id;
    obj.ComfirmTime = item.confirm_time;
    let info = true;
    obj.status = item.status;
    item.detection_proces.forEach((item, index) => {
      obj['check' + (index + 1)] = item.status;
      if (item.status == false) {
        info = false;
      }
      obj.ok = info;
    });
    arr.push(obj);
  });
  demo1.tableData = arr;
  console.log(demo1.tableData, '查询的数据');
};
// 点击完善
const toProjectPlanChange = () => {
  store.commit('set_CurrentPath', 'ProjectList');
  console.log('点击完善');
  router.push({
    name: 'ProjectPlan',
    params: {
      _id: store.state.Project.ProjectId,
    },
  });
};

//确认完成数组
let okArr = [false];
//监听翻页之后展示的最新数据
watch(
  () => demo1.tableData,
  (newValue, oldValue) => {
    console.log(demo1.tableData, '发生改变');
    demo1.tableData.forEach((item, index) => {
      let info = true;
      options.value.forEach((items, index) => {
        // console.log('check' + (index + 1));
        let str = 'check' + (index + 1);
        if (item['check' + (index + 1)] == false) {
          info = false;
        }
      });
      okArr[index] = info;
      if (info == true) {
        // console.log('全部环节已完成');
      }
    });
    // console.log('$table :>> ', $table);
    // console.log('selectedRows :>> ', selectedRows);
  },
);

//项目列表是否未选择
let ProjectSelect = ref(true);

// console.log(route.name);
let allData = reactive([]);
let totalCount = ref(0);
const xTable = ref();
//复选框数组
var selectedRows = reactive([]);

const ColumnVisible = reactive([true]);
const SetTableHeader = ref(false);
console.log('store.state.user.CurrentPage', store.state.user.CurrentPage);
//设置表头的勾选数据
let SetTableHeaderArr = ref([
  { fixed: '', field: 'number', checked: true, lable: '志愿者编号' },
  { fixed: '', field: 'name', checked: true, lable: '名字' },
  { fixed: '', field: 'product', checked: true, lable: '组别' },
  { fixed: '', field: 'projectEnd_time', checked: true, lable: '到访日期' },
  { fixed: '', field: 'createdAt', checked: false, lable: '到访时间' },
  { fixed: '', field: 'status', checked: true, lable: '项目状态' },
  { fixed: '', field: 'progress', checked: true, lable: '项目完成进度' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
]);
// note 全局加载完毕之后执行此事件
nextTick(() => {
  //   setHeaderColumn();
  // 执行 列表显示/隐藏
  //   ShowHidenColumn();
  // 固定列表本地数据
  //   FixedColumn();
});
// 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true;
};

const CheckHeader = (i) => {
  // console.log('SetTableHeaderArr(value.checked) :>> ', SetTableHeaderArr.value[i]);
  // console.log('isReactive(SetTableHeaderArr) :>> ', isRef(SetTableHeaderArr));
};
const ClickActive = ref('0');
// const ChangeActive = () => {};

// 监听
watchEffect(() => {
  if (demo1.tableData.length != 0) {
    demo1.loading = false;
    // searchEvent1();
  }
});

// sort排序字段
let SORT = ref({ createdAt: -1 });
// 表格升降序
const AscendingSort = async (index, type) => {
  console.log('我的type: :>> ', type);

  console.log('我的下标是: :>> ', index);
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  // console.log('固定信息:', tableColumns);
  let ColumnId = tableColumns[index].field;
  console.log(ColumnId, 'ColumnId》》》');

  if (ColumnId == 'number') {
    ColumnId = 'recruitRecord.volun_id';
  } else if (ColumnId == 'name') {
    ColumnId = 'volunteer.volun_name';
  } else if (ColumnId == 'group') {
    ColumnId = 'recruitRecord.group';
  }
  console.log(ColumnId, 'ColumnId');
  const sorts = {};
  if (type == 'Asc') {
    sorts[ColumnId] = 1;
  } else {
    sorts[ColumnId] = -1;
  }

  SORT.value = sorts;
  console.log(SORT.value, 'SORT.value');
  const result = await http.post('/test/findTestRecord-test', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    currnetPlan: ClickActive.value,
    // content: demo1.filterName,
    project: route.query._id ? route.query._id : store.state.Project.ProjectId,
    // stage: ClickActive.value,
  });
  showIdOptions.value = false;
  console.log(result, '项目的测试记录');
  page5.totalResult = result.totalCount;
  if (!result.testRecords) {
    return;
  }
  page5.totalResult = result.totalCount;
  projectData.value = result;
  console.log('projectData.value', projectData.value);
  let arr = [];
  let obj = {};
  let arrs = [];
  projectData.value.testRecords.forEach((item, index) => {
    console.log(index, 'index>>>>>>>>>>');
    if (index === 0) {
      console.log(item, 'item');
      item.detection_proces.forEach((items, indexs) => {
        let objs = {};
        objs.name = items.name;
        objs.names = '环节' + toChinesNum(indexs + 1);
        objs.checkeds = 'checked' + indexs;
        objs.director = items.director;
        objs.ComfirmTime = items.confirm_time;
        objs.confirm_user = items.confirm_user;
        arrs.push(objs);
      });
      options.value = arrs;
      console.log(options.value, 'option');
    }
    obj = {};
    obj.informed_content = item.informed_consent; //知情同意书地址
    obj.number = item.volun_id;
    obj.name = item.volun_name;
    obj.group = item.group;
    // obj.zhiqing = item.detection_process[0];
    // console.log(item,'item');
    if (item.informed_content) {
      obj.zhiqing = true;
    }
    // console.log(item.informed_content,'item.informed_content');
    obj._id = item._id;
    obj.ComfirmTime = item.detection_proces.confirm_time;
    let info = true;
    obj.status = item.status;
    item.detection_proces.forEach((item, index) => {
      obj['check' + (index + 1)] = item.status;
      if (item.status == false) {
        info = false;
      }
      obj.ok = info;
    });
    arr.push(obj);
  });
  demo1.tableData = arr;
  console.log(demo1.tableData, 'demo1.tableData');
};
const FixedList = reactive([]);
// 点击操作的设置icon
const SetRow = () => {
  // alert(123);
};
const CreateProjectShowChange = () => {
  CreateProjectShow.value = false;
};

// 每一行的查看详情
const ShowDetails = (row) => {
  console.log('row :>> ', row);

  store.commit('Set_ProjectId', row._id);
  store.commit('Set_ProjectNumber', row.number);
  router.push({
    name: 'ProjectDetails',
    params: {
      _id: row._id,
    },
  });

  ProjectDetailsShow.value = true;
  store.commit('Set_ProjectTitle', 'projectDetails');
};
//项目详情
let ProjectDetailsShow = ref(false);
// let ProjectDetailsShow = ref(false);
//新建项目点击完成项目详情展示
const ProjectDetailsChange = () => {
  ProjectDetailsShow.value = true;
  CreateProjectShow.value = false;
};
const OperationBox = ref(null); //获取更多操作盒子的ref
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1);
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value :>> ', value);
  const table = xTable.value;
  const FixedList = table.getColumns();
  // const FixedList = reactive([]);
  console.log('当前的列顺序为', FixedList);

  console.log('FixedIndex :>> ', FixedIndex);
  // } else {
  FixedList.forEach((item, index) => {
    if (item.title == value) {
      // console.log('value的位置在,index :>> ', value, '的位置在', index);
      FixedIndex.value = index;
    }
  });
  console.log('FixedIndex下标为 :>> ', FixedIndex);
  // }

  showIdOptions.value = false;
  showIdOptions.value = true;

  const Event = event;

  OperationBox.value.style.left = event.clientX - 130 + 'px';
};
const handleButtonClick = (btn) => {
  console.log('btn :>> ', btn);
};
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false;
  console.log('点击点击 :>> ');
};

// 重置表格
var ResetTable = async () => {
  demo1.filterName = '';
  projectTestRecordsDataChange(ClickActive.value);
};
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params;
  console.log(columns); // 打印排序后的表头列数组对象
};

const addColumn = () => {
  const uniqueId = XEUtils.uniqueId();
  demo1.tableColumn.push({
    field: `new_${uniqueId}`,
    title: `新列_${uniqueId}`,
    width: 100,
  });
};

//打印方法
const printEvent1 = () => {
  const $table = xTable.value;
  $table.print();
};
// ! 关闭提醒弹窗
const Closereminder = () => {
  null_reminder_export.value = false;
};
const isShowExport = () => {
  if (checkedArr.value.length >= 1) {
    null_reminder_export.value = false;
    isExport.value = true;
  } else {
    null_reminder_export.value = true;
  }
};
//导出方法
const exportDataEvent = async () => {
  if (!store.state.role_info.includes('volunteer-testRecords-export')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  if (checkedArr.value.length != 0) {
    const res = await http.post('/upload/export-test', checkedArr.value);
    if (res.status == 0) {
      xlsx.writeFileXLSX(res.xlsxData, `志愿者测试记录 ${store.state.Project.ProjectNumber} ${dayjs().format('YYYYMMDD')}.xlsx`);
      isExport.value = false;
      message({
        message: '导出成功',
        grouping: true,
        type: 'success',
      });
    }
  } else {
    message({
      message: '请先选择要导出的数据',
      grouping: true,
      type: 'error',
    });
  }
};

const removeColumn = () => {
  demo1.tableColumn.pop();
};

const updateSexFilter = async () => {
  const $table = xTable.value;
  const column = $table.getColumnByField('sex');
  if (column) {
    // 修改筛选列表，并默认设置为选中状态
    await $table.setFilter(column, [
      { value: '1', label: '男' },
      { value: '0', label: '女', checked: true },
    ]);
    // 修改条件之后，需要手动调用 updateData 处理表格数据
    await $table.updateData();
  }
};
// 固定列表
const toggleFixedColumn = (index, type) => {
  console.log('我的下标是: :>> ', index);
  showIdOptions.value = false;
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  // console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  // console.log('固定信息2:', fxColumn);
  // console.log('固定信息:', tableColumns);

  // console.log('SetTableHeaderArr :>> ', SetTableHeaderArr.value);
  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};

// 全部显示里被隐藏的
let AllHidenHeader = ref([]);
const SetShow = ref(false);
const ShowBox = ref(null); //获取Show盒子的ref
const ShowCheck = ref([]);

const updateWidthColumn = async (index, value) => {
  const $table = xTable.value;
  demo1.tableColumn[index].width = value;
  // 更改了列属性，需要手动刷新列
  await nextTick();
  await $table.refreshColumn();
};

// 项目状态的样式
// const status_style = ref('');
const status_style = computed(() => (e) => {
  // console.log(e);
  if (e === '未开始') {
    return 'status_style-1';
  } else if (e === '招募中') {
    return 'status_style-2';
  } else if (e === '进行中') {
    return 'status_style-3';
  } else if (e === '已完成') {
    return 'status_style-4';
  } else if (e === '已停止') {
    return 'status_style-5';
  } else {
    return '';
  }
});

// note 全局搜索
const searchEvent2 = async () => {
  if (demo1.filterName != '') {
    const res = await http.post('/test/findTestRecord-test', {
      page: 1,
      size: 10,
      sort: { createdAt: 1 },
      // content: demo1.filterName,
      project: route.query._id,
      // stage: ClickActive.value,
    });
    console.log(res, '项目的测试记录');
    if (!res.Tests) {
      return;
    }
    projectData.value = res;
    let arr = [];
    let obj = {};
    projectData.value.Tests.forEach((item, index) => {
      obj = {};
      // console.log(item.detection_process)
      obj.number = item.volun_id;
      obj.name = item.volunteer.volun_name;
      obj.group = item.group;
      obj.zhiqing = item.detection_process[0];
      obj._id = item._id;
      obj.ComfirmTime = item.confirm_time;
      item.detection_process.forEach((item, index) => {
        obj['check' + (index + 1)] = item;
      });
      arr.push(obj);
      // demo1.tableData[index]=obj
    });
    console.log(arr, 'arr');
    demo1.tableData = arr;
  }
};

// 查看知情同意书
const lookZhiQing = (isImgUpload) => {
  console.log('点击了查看知情同意书', isImgUpload); //isImgUpload  true 则有图片， underfined 则没图片
};
// tip tootip提示方法
const tooltipConfig = reactive({
  showAll: true,
  enterable: true,
  contentMethod: ({ type, column, row, items, _columnIndex }) => {
    const { field } = column;

    // column 判断 column.title 是否为'操作'
    // 重写默认的提示内容
    if (column.title == '操作') {
      return (tooltipConfig.showAll = false);
    } else if (field === 'rate') {
      // 返回空字符串，控制单元格不显示提示内容
      return '';
    } else {
      tooltipConfig.showAll = true;
    }
    // 其余的单元格使用默认行为

    return null;
  },
});

// subsetArr

// 请求初始数据
const ReqDefault = async () => {
  const res = await http.post('/setting/fetch-setting', {
    type: 'volunteer',
  });
  console.log('标签请求返回的数据是', res);
  // labelList
  if (res.status == 0) {
    console.log('res请求初始数据为 :>> ', res);

    res.groups.forEach((item, index) => {
      item.forEach((j) => {
        if (j.value != false) {
          subsetArr.value.push(j.name);
        }
      });
    });

    if (subsetArr.value.length == 0) {
      subsetArr.value.push('暂无组别，请前往【自定义设置】新建');
    }
    console.log('获取的最终数据', subsetArr.value);
  }
};
</script>
<style lang="less" scoped>
@import url('@/style/views/VolunteerManagement/TestRecords.less');
</style>
