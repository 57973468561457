<template>
  <div id="ClockinView">
    <div class="project_main">
      <!-- 表格主体 -->
      <div class="ProjectListBox">
        <div class="vxetable">
          <!-- 表格头部 工具按钮 -->
          <vxe-toolbar>
            <template #buttons>
              <vxe-input
                class="Search"
                v-model="demo1.Search"
                type="search"
                placeholder="请输入志愿者编号或姓名进行搜索"
                @Blur="searchEvent2"></vxe-input>
              <vxe-button @click="ResetTable" class="Reset"><span class="reset-icon">重置</span></vxe-button>
              <div class="BatchDownLoad" @click="BeforeJudge('批量')">
                <button>
                  <img src="https://newstore.vynior.com/cems_web/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230704172211.png" />
                  批量下载
                </button>
              </div>

              <vxe-button @click="isShowExport" class="Import"></vxe-button>
            </template>
          </vxe-toolbar>
          <!-- 表格 -->
          <div class="tableOutSide" v-if="store.state.Project.ProjectId != '' && demo1.tableData.length != 0">
            <vxe-table
              border
              height="auto"
              auto-resize
              show-overflow
              ref="xTable"
              keep-source
              :tooltip-config="tooltipConfig"
              :column-config="{ resizable: true }"
              :loading="demo1.loading"
              :data="demo1.tableData"
              :edit-config="{ trigger: 'manual', mode: 'row', showIcon: false, autoClear: false }"
              @checkbox-all="Allin"
              @checkbox-change="selectChangeEvent1">
              <vxe-column field="checked" type="checkbox" width="41" min-width="40" fixed="left"></vxe-column>

              <vxe-column type="seq" title="序号" width="60" fixed="left"></vxe-column>
              <vxe-column field="volun_id" title="志愿者编号" min-width="180" fixed="left">
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === '志愿者编号'">
                    <el-tooltip class="box-item" effect="dark" content="志愿者编号" placement="top">
                      <span class="name">志愿者编号</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('志愿者编号', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="volun_name" title="姓名" min-width="100" fixed="left">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === '姓名'">
                    <el-tooltip class="box-item" effect="dark" content="姓名" placement="top">
                      <span class="name">姓名</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('姓名', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="group" title="组别" min-width="100">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === '组别'">
                    <el-tooltip class="box-item" effect="dark" content="组别" placement="top">
                      <span class="name">组别</span>
                    </el-tooltip>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('组别', $event)"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  {{ row.group === '' ? '——' : row.group }}
                </template>
              </vxe-column>
              <!-- 循环列 -->
              <vxe-column v-for="(item, index) in options" :key="index" :title="item" min-width="120" width="120">
                <template #default="{ row }">
                  <div class="Two-Times" style="display: flex; justify-content: space-around">
                    <!-- 一天一次打卡 -->
                    <div class="vxe_check" v-if="ClockinTimes == '每日一次' || ClockinTimes == '隔日一次' || ClockinTimes == '每周一次'">
                      <div class="dayu">
                        <img src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402x.png" v-show="row.clockins[index].status == '已打卡'" />
                        <img src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402x.png" v-show="row.clockins[index].status == '未打卡'" />
                        <img
                          src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402x%20%281%29.png"
                          v-show="row.clockins[index].status == '待打卡'" />
                      </div>
                    </div>
                    <!-- 一天两次 -->
                    <div class="vxe_check" v-if="ClockinTimes == '每日两次'">
                      <div class="dayu" v-for="(ite, indexs) in row.clockins[index].status" :key="indexs">
                        <img src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402x.png" v-show="ite == '已打卡'" />
                        <img src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402x.png" v-show="ite == '未打卡'" />
                        <img src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402x%20%281%29.png" v-show="ite == '待打卡'" />
                      </div>
                    </div>
                  </div>
                </template>
              </vxe-column>

              <vxe-column title="操作" width="170" fixed="right" :resizable="false">
                <template #default="{ row }">
                  <el-tooltip class="box-item" effect="dark" content="查看详情" placement="top">
                    <vxe-button class="Save" @click="CheckDetails(row)">查看详情</vxe-button>
                  </el-tooltip>
                  <el-tooltip class="box-item" effect="dark" content="下载" placement="top">
                    <vxe-button class="CancelSave" @click="BeforeJudge(row.volunteer_id)">下载</vxe-button>
                  </el-tooltip>
                </template>
              </vxe-column>
            </vxe-table>
          </div>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
                <!-- <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')">
                  <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" />固定
                </li>
                <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" style="color: #bfbfbf">
                  <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" />取消固定
                </li> -->

                <!-- <li id="MoreOptions" @click="showIdOptions = false"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li> -->
              </ul>
            </div>
          </div>
          <!-- 批量志愿者信息  导出 -->
          <div class="Derived_volunteer" v-if="isExport">
            <div class="Derived_volunteer_box">
              <!-- 导出一个 -->
              <div v-if="checkedArr.length == 1">
                <div class="Derived_title">
                  确定导出
                  <span class="Derived_name">{{ selectedRows[0].volun_name == ' ' ? '无名字' : selectedRows[0].volun_name }}</span>
                  的打卡记录信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="exportDataEvent">确认</div>
                </div>
              </div>
              <!-- 导出多个 -->
              <div v-if="checkedArr.length > 1">
                <div class="Derived_title">
                  已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>打卡记录信息，确认导出吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="exportDataEvent">确认</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 导出一条没有的时候 -->
          <div class="Derived_volunteer_null" v-if="null_reminder_export">
            <!-- 导出 -->
            <div class="Derived_volunteer_box">
              <div class="Derived_title">
                <div class="Derived_name">提示</div>
                <div><img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
              </div>
              <div class="Derived_body">
                <div><img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" /></div>
                <div class="Derived_body_text">请选择需要导出的打卡记录信息！</div>
              </div>
            </div>
          </div>
          <!-- 分页 -->
          <p v-if="store.state.Project.ProjectId != '' && demo1.tableData.length != 0">
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                  >条/共{{ page5.totalResult }}条
                </div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
      <!-- 空页面 -->
      <div class="Empty" v-if="store.state.Project.ProjectId == ''">
        <div class="Img">
          <img src="https://newstore.vynior.com/12%402x.png" alt="" />
        </div>
        <div class="Text">
          <p>请选择项目,查看对应的打卡记录</p>
        </div>
      </div>
      <!-- 空数据页面 -->
      <div class="EmptyProject" v-if="demo1.tableData.length == 0 && store.state.Project.ProjectId != ''">
        <span>
          <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
          <p>暂无数据</p>
        </span>
      </div>
      <!-- 查看详情抽屉 -->
      <div id="ClockinsDetails">
        <el-drawer
          v-model="ClockinDetailsShow"
          title="打卡记录"
          class="test_drawer"
          :close-on-click-modal="true"
          :close-on-press-escape="false"
          :show-close="false">
          <div class="drawer_main">
            <div class="main_info">
              <div class="info_tile">
                <div class="title_color"></div>
                <div class="title_val">志愿者信息</div>
              </div>
              <div class="info_main">
                <div class="main_number">
                  <div class="number_text">志愿者编号</div>
                  <div class="number_val">{{ volunteerMsg.id }}</div>
                </div>
                <div class="main_name">
                  <div class="name_text">姓名</div>
                  <div class="name_val">{{ volunteerMsg.name }}</div>
                </div>
                <div class="main_subset">
                  <div class="subset_text">组别</div>
                  <div class="subset_val">{{ volunteerMsg.group }}</div>
                </div>
              </div>
              <div class="info_tiles">
                <div class="title_color"></div>
                <div class="title_val">打卡记录详情</div>
              </div>
              <div class="info_test">
                <el-table
                  border
                  :data="tableData"
                  stripe
                  style="width: 100%"
                  :span-method="ClockinTimes == '每日两次' ? objectSpanMethod : ''"
                  class="T-Header">
                  <el-table-column class-name="table_segment" prop="clockin_date" width="120" label="打卡日期" />
                  <el-table-column class-name="table_box" prop="status" width="148" label="打卡情况">
                    <template #default="scope">
                      <div class="One" v-if="ClockinTimes == '每日一次' || ClockinTimes == '隔日一次' || ClockinTimes == '每周一次'">
                        <!-- {{ scope.row.clockin_time.substring(6) }} -->
                        <img src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402x.png" v-show="scope.row.status == '已打卡'" />
                        <img src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402x.png" v-show="scope.row.status == '未打卡'" />
                        <img src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402x%20%281%29.png" v-show="scope.row.status == '待打卡'" />
                      </div>
                      <div class="Two" v-if="ClockinTimes == '每日两次'">
                        <img src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402x.png" v-show="scope.row.status == '已打卡'" />
                        <img src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402x.png" v-show="scope.row.status == '未打卡'" />
                        <img src="https://newstore.vynior.com/cems_web/%E6%88%90%E5%8A%9F%402x%20%281%29.png" v-show="scope.row.status == '待打卡'" />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column class-name="table_director" prop="confirmDate" width="120" label="打卡时间">
                    <!-- <template #default="scope">
                      {{ scope.row.confirmDate == null ? '' : scope.row.clockin_time }}
                    </template> -->
                  </el-table-column>
                  <el-table-column class-name="table_finish" prop="upload_files" width="144" label="打卡图片" v-if="ProjectMSG.clockin_imgupload">
                    <template #default="scope">
                      <!-- 打卡图片为0 -->
                      <div class="ImgList" v-if="scope.row.imgs_path.length == 0">
                        <img src="https://newstore.vynior.com/cems_web/%E5%9B%BE%E5%83%8F%402x%20%281%29.png" alt="" />
                        <img src="https://newstore.vynior.com/cems_web/%E5%9B%BE%E5%83%8F%402x%20%281%29.png" alt="" />
                        <img src="https://newstore.vynior.com/cems_web/%E5%9B%BE%E5%83%8F%402x%20%281%29.png" alt="" />
                      </div>
                      <!-- 打卡图片为1 -->
                      <div class="ImgList" v-if="scope.row.imgs_path.length == 1">
                        <img src="https://newstore.vynior.com/cems_web/yout.png" alt="" />
                        <img src="https://newstore.vynior.com/cems_web/%E5%9B%BE%E5%83%8F%402x%20%281%29.png" alt="" />
                        <img src="https://newstore.vynior.com/cems_web/%E5%9B%BE%E5%83%8F%402x%20%281%29.png" alt="" />
                      </div>
                      <!-- 打卡图片为1 -->
                      <div class="ImgList" v-if="scope.row.imgs_path.length == 2">
                        <img src="https://newstore.vynior.com/cems_web/yout.png" alt="" />
                        <img src="https://newstore.vynior.com/cems_web/yout.png" alt="" />
                        <img src="https://newstore.vynior.com/cems_web/%E5%9B%BE%E5%83%8F%402x%20%281%29.png" alt="" />
                      </div>
                      <!-- 打卡图片为1 -->
                      <div class="ImgList" v-if="scope.row.imgs_path.length == 3">
                        <img src="https://newstore.vynior.com/cems_web/yout.png" alt="" />
                        <img src="https://newstore.vynior.com/cems_web/yout.png" alt="" />
                        <img src="https://newstore.vynior.com/cems_web/yout.png" alt="" />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column class-name="table_director" prop="clockin_date" width="92" label="打卡视频" v-if="ProjectMSG.clockin_videoupload">
                    <template #default="scope">
                      <img
                        :src="
                          scope.row.video_path != '' && scope.row.video_path != undefined
                            ? 'https://newstore.vynior.com/cems_web/%E8%A7%86%E9%A2%91%402x%20%281%29.png'
                            : 'https://newstore.vynior.com/cems_web/%E8%A7%86%E9%A2%91%402x.png'
                        "
                        alt="" />
                    </template>
                  </el-table-column>
                  <el-table-column class-name="table_ok" prop="ok" width="120" label="操作">
                    <template #default="scope">
                      <!-- 预览 -->
                      <el-button
                        link
                        size="small"
                        :style="scope.row.video_path != undefined || scope.row.imgs_path.length != 0 ? 'color:#027AFF' : ''"
                        @click="SHOWS(scope.row)"
                        :disabled="
                          (scope.row.video_path == '' && scope.row.imgs_path.length == 0) ||
                          (scope.row.video_path == undefined && scope.row.imgs_path.length == 0)
                            ? true
                            : false
                        "
                        >预览</el-button
                      >
                      <!--   @click="SHOWS(scope.row)" -->
                      <!-- ! 下载 -->
                      <el-button
                        link
                        size="small"
                        :style="scope.row.video_path != undefined || scope.row.imgs_path.length != 0 ? 'color:#027AFF' : ''"
                        @click="singleData(scope.row)"
                        :disabled="
                          (scope.row.video_path == '' && scope.row.imgs_path.length == 0) ||
                          (scope.row.video_path == undefined && scope.row.imgs_path.length == 0)
                            ? true
                            : false
                        "
                        >下载</el-button
                      >
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <!-- todo -->

              <!-- <div class="info_but">
              <el-button class="but_el" @click="DetailsShowChange">关闭</el-button>
            </div> -->
            </div>
          </div>
          <template #footer>
            <el-button class="Footer" @click="DetailsShowChange">关闭</el-button>
          </template>
        </el-drawer>
      </div>
      <!-- 预览图片 -->
      <div class="demo-image__preview">
        <el-image
          v-show="ClockinDetailsShow"
          id="IMGVIEWS"
          style="width: 100px; height: 100px; margin-left: 400px"
          :src="ImgView.url"
          :zoom-rate="1.2"
          :preview-src-list="ImgView.srcList"
          :initial-index="0"
          fit="cover" />
      </div>
      <!-- 预览对话框 -->
      <div class="PreiewBox">
        <el-dialog v-model="outerVisible" title="请选择预览的内容">
          <template #default>
            <el-dialog v-model="innerVisible" width="30%" title="用户上传的视频" append-to-body>
              <video :src="ImgView.video" controls autoplay style="width: 500px; height: 500px"></video>
            </el-dialog>
            <el-button type="primary" @click="ClickA" :disabled="ImgView.srcList.length == 0 ? true : false">预览图片 </el-button>
            <el-button type="primary" @click="innerVisible = true" :disabled="ImgView.video == '' ? true : false"> 预览视频 </el-button>
          </template>

          <template #footer>
            <div class="dialog-footer">
              <el-button @click="outerVisible = false">关闭</el-button>
            </div>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineComponent, ref, reactive, nextTick, toRaw, toRefs, onMounted, watchEffect, watch, computed, onBeforeUnmount, isRef } from 'vue'
import { VXETable, VxeTableInstance } from 'vxe-table'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { message } from '@/utils/resetMessage'
import * as xlsx from 'xlsx'
import axios from 'axios'

import http from '@/utils/http'
import dayjs from 'dayjs'
import Dates from '@/utils/CurrentDate'
// 导出的当前时间
let ExportDate = ref('')

const xTable = ref()
//API接收
const store = useStore()
const router = useRouter()
//循环的数据(表头)
let options = reactive([])
// 展示的数据
const demo1 = reactive({
  loading: false,
  tableData: [],
  Search: '',
})
const isExport = ref(false)
//复选框数组
var selectedRows = reactive([])
let null_reminder_export = ref(false) // ! 没有勾选志愿者数据导出
// ! 关闭提醒弹窗
const Closereminder = () => {
  null_reminder_export.value = false
}
// 当前项目的信息
let ProjectMSG = ref({})
// 预览的点击框 boolean
const outerVisible = ref(false)
const innerVisible = ref(false)
// 备份没处理的数据 用与查看详情
let Back = ref([])
// 获取当前月份
const CurrentTime = ref('')
// sort排序字段
let SORT = ref({ createdAt: -1 })
// 获取时间
const CurrentTims = ref('')
// 点击表头的下拉箭头
const OperationBox = ref(null) //获取更多操作盒子的ref
const showIdOptions = ref(false) //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1)
// 预览数据
let ImgViews = ref(false)
let ImgView = ref({
  url: 'https://newstore.vynior.com/cems_web/white.png',
  srcList: [],
  video: '',
})
// 打卡频次
let ClockinTimes = ref('')
// 测试记录志愿者信息
const volunteerMsg = reactive({
  id: '',
  name: '',
  group: '',
})
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
})

// 查看详情的show/false
let ClockinDetailsShow = ref(false)
// 复选框数组
var selectedRows = reactive([])

// 复选框数组
const checkedArr = ref([])

// 一天打卡两次的数据
let DoubleTimes = ref([])
// ! 测试的表格数据
let tableData = reactive([])
// 切换项目ID请求新项目
watch(
  () => store.state.Project.ProjectId,
  async (newvalue) => {
    reqProjectList()
    demo1.tableData = []
    options = []
  }
)

// 监听打卡频次  每日两次  每日一次
watch(
  () => ClockinTimes.value,
  (newValue) => {
    // console.log('newValue :>> ', newValue)
  }
)

const isActiveStatus = (row) => {
  const $table = xTable.value
  return $table.isEditByRow(row)
}
// 每一行的编辑
const editRowEvent = (row) => {
  const $table = xTable.value
  $table.setEditRow(row)
}
const objectSpanMethod = ({ row, column, rowIndex, columnIndex }) => {
  if (columnIndex === 0) {
    if (rowIndex % 2 === 0) {
      return {
        rowspan: 2,
        colspan: 1,
      }
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      }
    }
  }
}
// 每一行的查看详情
const CheckDetails = async (row) => {
  // BeforeJudge();
  // console.log('row :>> ', row);
  ClockinDetailsShow.value = true
  volunteerMsg.id = row.volun_id
  volunteerMsg.group = row.group
  volunteerMsg.name = row.volun_name
  //   组合数据
  tableData = []
  let Data = 'clockins'
  if (ClockinTimes.value == '每日两次') {
    row.BackUps.forEach((item) => {
      item.clockin_date = dayjs(item.clockin_date).format('YYYY-MM-DD')
      tableData.push(item)
    })
  } else {
    row.clockins.forEach((item) => {
      item.clockin_date = dayjs(item.clockin_date).format('YYYY-MM-DD')
      tableData.push(item)
    })
  }

  // console.log('tableData>>', tableData)
}
// 详情页面的关闭
const DetailsShowChange = () => {
  ClockinDetailsShow.value = false
}
// 每一行的取消
const cancelRowEvent = (row) => {
  const $table = xTable.value
  $table.clearEdit().then(() => {
    // 还原行数据
    $table.revertData(row)
  })
}
// 每一行的确认
const confirm = async (row) => {
  const res = await http.put('/Schedule/update-Schedule', {
    _id: row._id,
    Schedule: {
      status: 'true',
    },
  })
  // console.log(res)
  if (res.status == 0) {
    reqProjectList()
    message({
      message: '确认成功',
      grouping: true,
      type: 'success',
    })
  }
}

// 每一行的取消确认
const CancelConfirm = async (row) => {
  const res = await http.put('/Schedule/update-Schedule', {
    _id: row._id,
    Schedule: {
      status: 'false',
    },
  })
  // console.log(res)
  if (res.status == 0) {
    reqProjectList()
    message({
      message: '取消确认成功',
      grouping: true,
      type: 'success',
    })
  }
}
// 预览数据
const SHOWS = (val) => {
  outerVisible.value = true
  ImgView.value.video = ''
  ImgView.value.srcList = []
  // console.log('val :>> ', val)
  ImgView.value.srcList = []
  val.imgs_path.forEach((item) => {
    ImgView.value.srcList.push(item)
  })
  if (val.video_path != undefined) {
    ImgView.value.video = val.video_path
  }
  console.log('ImgView :>> ', ImgView.value)
}
const ClickA = () => {
  let a = document.getElementById('IMGVIEWS')
  // console.log('"点击" :>> ', val, ImgView.value.srcList);
  a.click()
}
//todo 切换项目请求数据
const reqProjectList = async () => {
  options = []
  demo1.loading = true
  const res = await http.post('/clockin/find-clockin', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.Search,
    project: store.state.Project.ProjectId,
  })

  if (res.status == 0 && res.clockin != null) {
    console.log('初次请求数据的结果 :>> ', res)
    demo1.loading = false
    page5.totalResult = res.totalCount
    // 拿到项目数据
    ProjectMSG.value = res.clockin
    // 数据备份
    Back.value = JSON.parse(JSON.stringify(res.result))
    // console.log('Back :>> ', Back.value);

    // 获取打卡频次
    ClockinTimes.value = res.clockin.clockin_frequency
    if (res.result.length != 0) {
      // 处理表头 如果是每日两次就要去重/ 每日一次直接导入
      if (res.clockin.clockin_frequency == '每日两次') {
        res.tableHeader.forEach((item, index) => {
          if (index % 2 == 0) {
            // console.log('item :>> ', item)
            options.push(item)
          }
        })
        console.log('每日两次的表头options :>> ', options)
      } else {
        options = res.tableHeader
        console.log('每日一次的表头options :>> ', options)
      }
    }

    // 导入整体数据
    demo1.tableData = res.result
  } else {
    demo1.loading = false

    options = []
    demo1.tableData = []
  }
  //   console.log('option :>> ', options);

  // console.log('demo1.tableData :>> ', demo1.tableData);
  // console.log('demo1.tableData :>> ', demo1.tableData.clockins);
  if (ClockinTimes.value == '每日两次') {
    for (const data of demo1.tableData) {
      const arr = []

      data.clockins.forEach((item, index, array) => {
        if (index < array.length - 1 && item.clockin_date === array[index + 1].clockin_date) {
          arr[index / 2] = {
            ...item,
            status: [item.status, array[index + 1].status],
            clockin_time: [item.clockin_time, array[index + 1].clockin_time],
          }
        }
      })

      data.clockins = arr
      data.BackUps = Back.value[demo1.tableData.indexOf(data)].clockins
      // data.volun_id = Back.value[demo1.tableData.indexOf(data)].clockins[0].volun_id;
    }
  }
  // } else if (ClockinTimes.value == '每日一次') {
  //   console.log('111次 :>> ', demo1.tableData);
  //   demo1.tableData.forEach((item, index) => {
  //     item.volun_id = Back.value[index].clockins[0].volun_id;
  //   });
  // }
  console.log('demo1.tableData :>> ', demo1.tableData)
}

// 计算差值日期
function getDatesInRange(startDate, endDate) {
  let dateArray = []
  let currentDate = new Date(startDate)
  let endDateObj = new Date(endDate)

  // 循环迭代，增加日期并添加到数组中
  while (currentDate <= endDateObj) {
    dateArray.push(currentDate.toISOString().split('T')[0])
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return dateArray
}

// 复选框点击事件
const selectChangeEvent1 = (row) => {
  console.log('row :>> ', row)
  console.log('row :>> ', row.row.volunteer_id)
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records

  if (checkedArr.value.indexOf(row.row.volunteer_id) == -1) {
    checkedArr.value.push(row.row.volunteer_id)
  } else {
    let index = checkedArr.value.indexOf(row.row.volunteer_id)
    checkedArr.value.splice(index, 1)
  }
  console.log('最新:', checkedArr.value)
}
// 复选框全选事件
const Allin = ({ checked }) => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  console.log(checked ? '所有勾选事件' : '所有取消事件', records)
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i].volunteer_id) == -1) {
        checkedArr.value.push(records[i].volunteer_id)
      }
    }
    console.log('最新:', checkedArr.value)
  } else {
    console.log('demo1.tableData:', demo1.tableData)
    let current_id = []

    demo1.tableData.forEach((item, index) => {
      current_id.push(item.volunteer_id)
    })

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item)
    })
    console.log('取消全选:', checkedArr.value)
  }
}
// 模糊搜索
const searchEvent2 = async () => {
  reqProjectList()
  // options = [];
  // demo1.tableData = [];
  // demo1.loading = true;
  // const res = await http.post('/clockin/find-clockin', {
  //   page: page5.currentPage,
  //   size: page5.pageSize,
  //   sort: { createdAt: -1 },
  //   content: demo1.Search,
  //   project: store.state.Project.ProjectId,
  // });
  // if (res.status == 0 && res.findClockins.length != 0) {
  //   demo1.loading = false;
  //   page5.totalResult = res.totalCount;
  //   res.findClockins.forEach((item) => {
  //     demo1.tableData.push(item);
  //   });
  // } else {
  //   demo1.loading = false;

  //   options = [];
  //   demo1.tableData = [];
  // }
}
// 重置模糊搜索
const ResetTable = () => {
  demo1.Search = ''
  demo1.tableData = []
  options = []
  reqProjectList()
}
// 翻页操作
const pageChange = async () => {
  // 翻页
  reqProjectList()
}
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false
}
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  const table = xTable.value
  const FixedList = table.getColumns()

  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index
    }
  })

  showIdOptions.value = false
  showIdOptions.value = true
  // 设置位置
  const Event = event
  OperationBox.value.style.left = event.clientX - 50 + 'px'
  OperationBox.value.style.top = event.clientY + 10 + 'px'
}

// 取消固定列
const toggleFixedColumns = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
// 固定列
const toggleFixedColumn = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}

// More -->升序/降序
const AscendingSort = async (index, type) => {
  console.log('index,type :>> ', index, type)
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  const ColumnId = tableColumns[index].field
  console.log(ColumnId, 'ColumnId')
  const sorts = {}
  if (type == 'Asc') {
    sorts[ColumnId] = 1
  } else {
    sorts[ColumnId] = -1
  }

  SORT.value = sorts
  console.log(SORT.value, 'SORT.value')
  reqProjectList()
  // const res = await http.post('/clockin/find-clockin', {
  //   page: page5.currentPage,
  //   size: page5.pageSize,
  //   sort: SORT.value,
  //   content: demo1.Search,
  //   project: store.state.Project.ProjectId,
  // });
  // demo1.tableData = res.result;
  // page5.totalResult = res.totalCount;
}
// 获取当前月份
function Times() {
  let currentTime = new Date()
  //
  //   let times = currentTime.toLocaleString().substring(0, 10);
  // 获取年
  let year = currentTime.getFullYear()
  //   获取月
  let month = (currentTime.getMonth() + 1).toString().padStart(2, '0')
  //   获取日
  let day = currentTime.getDate().toString().padStart(2, '0')
  //   获取时间
  let formattedDate = year + '-' + month + '-' + day
  return formattedDate
}
// 获取当前时间
function TimesHHMMSS() {
  // 创建一个Date对象，表示当前时间
  let currentDate = new Date()

  // let获取小时
  let hours = currentDate.getHours().toString().padStart(2, '0')

  // let获取分钟
  let minutes = currentDate.getMinutes().toString().padStart(2, '0')

  // let获取秒钟
  let seconds = currentDate.getSeconds().toString().padStart(2, '0')

  // let拼接成所需的格式
  //   let formattedTime = hours + ':' + minutes + ':' + seconds;
  let formattedTime = hours + ':' + minutes

  return formattedTime
}

const isShowExport = () => {
  if (checkedArr.value.length >= 1) {
    null_reminder_export.value = false
    isExport.value = true
  } else {
    null_reminder_export.value = true
  }
}
// 导出打卡信息
const exportDataEvent = async () => {
  console.log(checkedArr.value, 'checkedArr.value')
  let arr = []
  demo1.tableData.forEach((item, index) => {
    if (item.volunteer_id == checkedArr.value[index]) {
      arr.push(item.recruitRecord_id)
    }
  })
  console.log(arr, 'arr')
  if (checkedArr.value.length != 0) {
    const res = await http.post('/upload/export-clockin', {
      project: store.state.Project.ProjectId,
      clockinList: arr,
    })
    console.log(res, '导出打卡记录返回值')
    if (res.status == 0) {
      xlsx.writeFileXLSX(res.xlsxData, '志愿者打卡记录' + store.state.Project.ProjectName + ExportDate.value + '.xlsx')

      message({
        message: '导出成功',
        grouping: true,
        type: 'success',
      })
      isExport.value = false
    }
  } else {
    message({
      message: '请先选择要导出的数据',
      grouping: true,
      type: 'error',
    })
  }
}
//  单个下载打卡文件
const SingleDown = async (val) => {
  console.log('val :>> ', val)
  axios
    .post(
      'https://cems.fuhuan.ltd/api/upload/bulkdownload-clockin',
      { projectId: store.state.Project.ProjectId, volunteerList: [val] },
      { responseType: 'arraybuffer' }
    )
    .then((result) => {
      console.log(result, '返回的错误数据')
      const blob = new Blob([result.data], { type: 'application/x-zip-compressed;charset=utf-8' })

      let name = '打卡记录下载.zip'
      const downloadElement = document.createElement('a')
      let classificationhref = window.URL.createObjectURL(blob)
      downloadElement.href = classificationhref
      downloadElement.download = `${name}`
      // // console.log("blob :>> "，blob);
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(classificationhref)
    })
}
// 批量下载打卡文件
const DownLoadFile = async () => {
  if (checkedArr.value.length != 0) {
    axios
      .post(
        'https://cems.fuhuan.ltd/api/upload/bulkdownload-clockin',
        { projectId: store.state.Project.ProjectId, volunteerList: checkedArr.value },
        { responseType: 'arraybuffer' }
      )
      .then((result) => {
        console.log(result, '返回的错误数据')
        const blob = new Blob([result.data], { type: 'application/x-zip-compressed;charset=utf-8' })

        let name = '打卡记录下载.zip'
        const downloadElement = document.createElement('a')
        let classificationhref = window.URL.createObjectURL(blob)
        downloadElement.href = classificationhref
        downloadElement.download = `${name}`
        // // console.log("blob :>> "，blob);
        document.body.appendChild(downloadElement)
        downloadElement.click()
        document.body.removeChild(downloadElement)
        window.URL.revokeObjectURL(classificationhref)
      })
  } else {
    message({
      message: '请先选择要下载的数据',
      grouping: true,
      type: 'error',
    })
  }
}
// 查看详情里的 下载当天的打卡 文件
const singleData = async (val) => {
  axios.post('https://cems.fuhuan.ltd/api/upload/download-clockin', { clockinId: val._id }, { responseType: 'arraybuffer' }).then((result) => {
    console.log(result, '返回的错误数据')
    const blob = new Blob([result.data], { type: 'application/x-zip-compressed;charset=utf-8' })
    let name = '查看详情里的下载.zip'
    const downloadElement = document.createElement('a')
    let classificationhref = window.URL.createObjectURL(blob)
    downloadElement.href = classificationhref
    downloadElement.download = `${name}`
    // // console.log("blob :>> "，blob);
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(classificationhref)
  })
}

// 下载前的确认
const BeforeJudge = async (row) => {
  console.log(row)
  if (row != '批量') {
    console.log('走单个')

    const res = await http.post('/upload/bulkdownloadexistsSync-clockin', {
      projectId: store.state.Project.ProjectId,
      volunteerList: [row],
    })
    console.log('res :>> ', res)

    if (res.status == 0) {
      SingleDown(row)
    } else {
      message({
        message: '没有打卡记录',
        grouping: true,
        type: 'error',
      })
    }
  } else {
    console.log('走批量')

    const res = await http.post('/upload/bulkdownloadexistsSync-clockin', {
      projectId: store.state.Project.ProjectId,
      volunteerList: checkedArr.value,
    })
    if (res.status == 0) {
      DownLoadFile()
    } else {
      message({
        message: '没有打卡记录',
        grouping: true,
        type: 'error',
      })
    }
  }
}
// tip tootip提示方法
const tooltipConfig = reactive({
  showAll: true,
  enterable: true,
  contentMethod: ({ type, column, row, items, _columnIndex }) => {
    const { field } = column

    // column 判断 column.title 是否为'操作'
    // 重写默认的提示内容
    if (column.title == '操作') {
      return (tooltipConfig.showAll = false)
    } else if (field === 'rate') {
      // 返回空字符串，控制单元格不显示提示内容
      return ''
    } else {
      tooltipConfig.showAll = true
    }
    // 其余的单元格使用默认行为

    return null
  },
})
onMounted(() => {
  ExportDate.value = Dates() //获取当前时间
  reqProjectList()
  //   获取年月日
  CurrentTime.value = Times()
  //   获取时分秒
  CurrentTims.value = TimesHHMMSS()
  let b = '2023-05-30'
  let c = '2023-05-30'
  // console.log('CurrentTime :>> ', b > CurrentTime.value, CurrentTime.value);
  // console.log('CurrentTime :>> ', b == CurrentTime.value);

  let a = '6:00~12:00'
  // console.log('object :>> ', a.substring(5));
  // console.log('a.substring(5)>CurrentTims :>> ', a.substring(5) > CurrentTims.value, CurrentTims.value);
  // 点击下拉
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false
      }
    }
  })
  store.commit('Set_CurrentProjectName', '打卡记录')
})
</script>
<style lang="less" scoped>
@import url('@/style/views/VolunteerManagement/ClockinView.less');
.demo-image__error .image-slot {
  font-size: 30px;
}
.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}
.demo-image__error .el-image {
  width: 100%;
  height: 200px;
}
</style>
