const state = {
  CurrentProjectName: '',
  // 项目标题
  ProjectTitle: '',
  //查询的项目id
  ProjectId: '',
  //项目是否是编辑完成
  UpdateProject: 0,
  // 是否是新建项目跳转到概览
  CreatedProject: 0,
  // 是否是项目列表跳转到概览
  ProjectDetails: 0,
  InstrumentName: { lable: '', value: '' },
  sample_status: 1, //样品状态
  ProjectNumber: '', //项目名称
  instrProject: '', //仪器下的项目
};

const mutations = {
  Set_ProjectNumber(state, newValue) {
    state.ProjectNumber = newValue;
  },
  Set_CurrentProjectName(state, newValue) {
    state.CurrentProjectName = newValue;
  },
  Set_ProjectTitle(state, newValue) {
    state.ProjectTitle = newValue;
  },
  Set_ProjectId(state, newValue) {
    state.ProjectId = newValue;
  },
  // Set_ShareProjectId(state,newValue){
  //     state.ShareProjectId=newValue
  // },
  Set_UpdateProject(state, newValue) {
    state.UpdateProject = newValue;
  },
  Set_CreatedProject(state, newValue) {
    state.CreatedProject = newValue;
  },
  Set_ProjectDetails(state, newValue) {
    state.ProjectDetails = newValue;
  },
  Set_InstrumentName(state, newValue) {
    state.InstrumentName = newValue;
  },
  Set_sample_status(state, newValue) {
    state.sample_status = newValue;
  },
  Set_instrProject(state, newValue) {
    state.instrProject = newValue;
  },
};
// 项目标题
// const ProjectTitle={
//     Set_CurrentProjectName(state,newValue){
//         state.Set_CurrentProjectName=newValue
//     }
// }

export default {
  state,
  mutations,
};
