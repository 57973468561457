<template>
  <div id="Appadmin">
    <div class="Appadmin_main">
      <div class="main_share">
        <div class="share_text">分享小程序</div>
        <div class="share_img">
          <!-- <img src="https://newstore.vynior.com/cems_web/tiyanerwm.jpg" alt="" /> -->
          <qrcode-vue :value="codeValue" id="img_code" class="img_code"></qrcode-vue>
          <div class="share_download" @click="downloadImgChange">下载图片</div>
        </div>
      </div>
      <div class="main_preview">
        <div class="preview_text">小程序界面预览<span class="text_color">（可预览小程序首页和每个项目的详情界面）</span></div>
        <div class="preview_main">
          <div class="main_app" v-if="!projectDetails">
            <div class="app_title">
              <div class="title">CEMS志愿者招募</div>
            </div>
            <div class="app_select">
              <div class="select_sex">
                <span>不限性别</span>
                <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110407%402x.png" alt="" />
              </div>
              <!-- <div class="select_age">
                <span>不限年龄</span>
                <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110407%402x.png" alt="" />
              </div> -->
              <div class="select_area">
                <span>不限部位</span>
                <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110407%402x.png" alt="" />
              </div>
            </div>
            <div class="app_main">
              <!-- {{projectResult  }} -->
              <div class="main_item" v-for="(item, index) in projectResult" :key="index" @click="ProjectChange(item)">
                <div class="item_title">
                  【{{ item.ageRequirement == '不限' ? item.ageRequirement + '年龄' : item.ageRequirement
                  }}{{ item.sexRequirement == '不限' ? item.sexRequirement + '性别' : item.sexRequirement }}】{{ item.name }}
                </div>
                <div class="item_number">
                  <div class="number_img">
                    <img src="https://newstore.vynior.com/cems_web/creditcard.png" alt="" />
                  </div>
                  <span>补助金额：{{ item.subsidy }}元</span>
                </div>
                <div class="item_people">
                  <div class="people_img">
                    <img src="https://newstore.vynior.com/cems_web/user.png" alt="" />
                  </div>
                  <span>招募人数：{{ item.recruitedPeopleNumber + '/' + item.peopleNumber }}人</span>
                </div>
                <div class="item_time">
                  <div class="time_img">
                    <img src="https://newstore.vynior.com/cems_web/calendar.png" alt="" />
                  </div>
                  <span>测试时间：{{ item.plan[item.plan.length - 1][0] }}~ {{ item.plan[item.plan.length - 1][1] }}</span>
                </div>
                <div class="item_date">
                  <div class="date_img">
                    <img src="https://newstore.vynior.com/cems_web/time.png" alt="" />
                  </div>
                  <span>剩余报名时间：{{ item.resttime[0] + '天' + item.resttime[1] + '小时' + item.resttime[2] + '分钟' }}</span>
                </div>
                <div class="item_area">
                  <div class="area_box">面部</div>
                </div>
              </div>
            </div>
            <img class="app_tab" src="https://newstore.vynior.com/cems_web/tab.png" alt="" />
            <img class="app_daka" src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110427%402x.png" alt="" />
          </div>
          <div class="main_details" v-if="projectDetails">
            <div class="details_title">
              <div class="title">
                <img @click="projectDetails = false" src="https://newstore.vynior.com/cems_web/%E7%BB%84%201453%402x%20%281%29.png" alt="" />
                <div class="title_text">CEMS志愿者招募</div>
              </div>
            </div>
            <div class="details_main">
              <div class="details_name">
                <div class="name">
                  {{ projectInfo.name }}
                </div>
              </div>
              <div class="details_box">
                <div class="box_number">
                  <div class="number_img">
                    <img src="https://newstore.vynior.com/cems_web/creditcard.png" alt="" />
                  </div>
                  <span>补助金额：{{ projectInfo.subsidy }}元</span>
                </div>
                <div class="box_people">
                  <div class="people_img">
                    <img src="https://newstore.vynior.com/cems_web/user.png" alt="" />
                  </div>
                  <span>招募人数：{{ projectResult[projectIndex].recruitedPeopleNumber }} 人</span>
                </div>
                <div class="box_time">
                  <div class="time_img">
                    <img src="https://newstore.vynior.com/cems_web/calendar.png" alt="" />
                  </div>
                  <span
                    >测试时间：{{ projectInfo.plan[projectInfo.plan.length - 1][0] }}~ {{ projectInfo.plan[projectInfo.plan.length - 1][1] }}</span
                  >
                </div>
                <div class="box_address">
                  <div class="address_img">
                    <img src="https://newstore.vynior.com/cems_web/location.png" alt="" />
                  </div>
                  <div class="address_t">
                    <div class="t_title">参与地址：</div>
                    <div class="t_val">
                      {{ projectInfo.testAddress }}
                    </div>
                  </div>
                </div>
                <div class="box_card">
                  <div class="card_sex">
                    <div class="sex_title">性别要求</div>
                    <div class="sex_val">
                      {{ projectInfo.sexRequirement == '不限' ? projectInfo.sexRequirement + '性别' : '仅' + projectInfo.sexRequirement }}
                    </div>
                  </div>
                  <div class="card_age">
                    <div class="age_title">年龄限制</div>
                    <div class="age_val">
                      {{ projectInfo.ageRequirement == '不限' ? projectInfo.ageRequirement + '年龄' : '仅' + projectInfo.ageRequirement }}
                    </div>
                  </div>
                  <div class="card_area">
                    <div class="area_title">测试部位</div>
                    <div class="area_val">
                      {{ projectInfo.testArea }}
                    </div>
                  </div>
                </div>
                <div class="box_tip" v-if="projectInfo.prompt != ''">
                  <img src="https://newstore.vynior.com/cems_web/%E6%84%9F%E5%8F%B9%E5%8F%B7.png" alt="" />
                  提示：{{ projectInfo.prompt }}
                </div>
              </div>
              <div class="details_info">
                <div class="info_title">
                  <div class="title_back"></div>
                  <div class="title">测试计划</div>
                </div>
                <div class="info_val">
                  <div class="val_plan" v-for="(item, index) in projectInfoTime" :key="index">
                    <!-- {{ projectTime[index] }} <span v-for="(items,indexs) in item" :key="indexs"></span> -->
                    <div class="plan_title">
                      {{ projectTime[index] }}
                    </div>
                    <div class="plan_time">
                      <span v-for="(items, indexs) in item" :key="indexs">
                        {{ index < item.length - 1 ? items + '、' : items }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="details_info">
                <div class="info_title">
                  <div class="title_back"></div>
                  <div class="title">入选条件</div>
                </div>
                <div class="info_val">
                  {{ projectInfo.admissionCondition }}
                </div>
              </div>
              <div class="details_info">
                <div class="info_title">
                  <div class="title_back"></div>
                  <div class="title">排除条件</div>
                </div>
                <div class="info_val">
                  {{ projectInfo.exclusionCondition }}
                </div>
              </div>
              <div class="details_info">
                <div class="info_title">
                  <div class="title_back"></div>
                  <div class="title">其他信息</div>
                </div>
                <div class="info_val">
                  {{ projectInfo.other }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import QrcodeVue from 'qrcode.vue'
import html2canvas from 'html2canvas'
import { defineComponent, ref, reactive, nextTick, toRaw, toRefs, onMounted, watchEffect, watch, computed, onBeforeUnmount, isRef } from 'vue'
import { VxeTableInstance } from 'vxe-table'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import http from '@/utils/http'
import { ElMessage, ElMessageBox } from 'element-plus'
import { get } from 'lodash-es'
import dayjs from 'dayjs'
const store = useStore()
// 在生命周期里面请求项目数据
onMounted(async () => {
  await getProject()
  // await getProjectInfo()
  store.commit('Set_CurrentProjectName', '小程序管理')
})
const codeValue = computed(() => {
  let company = localStorage.getItem('company')
  return `https://cems.fuhuan.ltd/#/company=${company}`
})
let projectTime = ref([
  '第一次到访时间：',
  '第二次到访时间：',
  '第三次到访时间：',
  '第四次到访时间：',
  '第五次到访时间：',
  '第六次到访时间：',
  '第七次到访时间：',
  '第八次到访时间：',
  '第九次到访时间：',
  '第十次到访时间：',
])
// 所有项目信息
let projectResult = ref([
  {
    recruitedPeopleNumber: '',
    resttime: [],
    peopleNumber: '',
    plan: [{ day: '0', times: [] }, { day: '1', times: [] }, ['2023-05-01', '2023-05-20']],
  },
])
let projectIndex = ref(0)
// 获取所有项目信息
const getProject = async () => {
  let company = [localStorage.getItem('company')]
  const result = await http.post('/project/find-MiniAPP-project', {
    page: 1,
    size: 5,
    Volunteer: '',
    company: company,
  })
  console.log(result)
  projectResult.value = result.projectlist
  console.log(projectResult.value, '获取的项目信息')
  let earliestDate = Infinity
  let latestDate = -Infinity
  projectResult.value.forEach((item, index) => {
    // console.log(item);
    item.plan.forEach((items) => {
      //   // 遍历当前日期数组中的每个日期字符串
      items.times.forEach((dateString) => {
        let date = toDate(dateString)
        if (date < earliestDate) {
          earliestDate = date
        }
        if (date > latestDate) {
          latestDate = date
        }
      })
      // console.log(earliestDate);
      // console.log(latestDate);
      let arr = [dayjs(earliestDate).format('YYYY-MM-DD'), dayjs(latestDate).format('YYYY-MM-DD')]
      // console.log(arr,'arr');
      item.plan[item.plan.length] = arr
    })
  })
  console.log(projectResult.value)
}
// 单个项目信息
let projectInfo = ref({
  recruitedPeopleNumber: '',
  peopleNumber: '',
  plan: [{ day: '0', times: [] }, { day: '1', times: [] }, ['2023-05-01', '2023-05-20']],
})
const ProjectChange = async (val) => {
  console.log(val, 'val')
  await getProjectInfo(val._id)
  projectDetails.value = true
}
let projectInfoTime = ref([])
// 获取单个项目信息
const getProjectInfo = async (val) => {
  const result = await http.post('/project/findInfo-project', {
    projectId: val,
  })
  console.log(result, '获取的单个项目信息')
  projectInfo.value = result.project[0]
  console.log(result)
  let earliestDate = Infinity
  let latestDate = -Infinity
  projectInfo.value.plan.forEach((items, indexs) => {
    let arr = []
    items.times.forEach((dateString, index) => {
      // console.log(dateString,'dateString');
      // peojectInfoTime.value[indexs][index]=dateString
      arr.push(dateString)
      let date = toDate(dateString)
      if (date < earliestDate) {
        earliestDate = date
      }
      if (date > latestDate) {
        latestDate = date
      }
    })
    projectInfoTime.value[indexs] = arr
  })
  console.log(projectInfoTime.value)
  let arr = [dayjs(earliestDate).format('YYYY-MM-DD'), dayjs(latestDate).format('YYYY-MM-DD')]
  // projectDetails
  projectInfo.value.plan[projectInfo.value.plan.length] = arr
  console.log(projectInfo.value, '返回的单个项目信息')
}
// 获取标准时间
const toDate = (val) => {
  return new Date(val)
}
// 点击下载下载图片
const downloadImgChange = async () => {
  var shareContent = document.getElementById('img_code')
  var width = shareContent.offsetWidth
  var height = shareContent.offsetHeight
  var canvas = document.createElement('canvas')
  var context = canvas.getContext('2d')
  var scale = 1 // 将canvas的容器扩大PixelRatio倍，再将画布缩放，将图像放大PixelRatio倍。
  canvas.width = width * scale
  canvas.height = height * scale
  canvas.style.width = width + 'px'
  canvas.style.height = height + 'px'
  context.scale(scale, scale)

  var opts = {
    scale: 1,
    canvas: canvas,
    dpi: window.devicePixelRatio,
    // 获取元素宽高 让其以一个规律或者固定宽高伸展
    width: shareContent,
    height: shareContent,
    // 将截图部分 移入中心位置
    scrollX: 260,
    scrollY: 260,
  }
  // 解决图片超出显示区后底色变成黑色的问题
  context.fillStyle = '#fff'

  context.fillRect(0, 0, canvas.width, canvas.height)
  var dataUrl1
  setTimeout(async () => {
    dataUrl1 = await html2canvas(shareContent, opts).then(function (canvas) {
      context.imageSmoothingEnabled = false
      context.webkitImageSmoothingEnabled = false
      context.msImageSmoothingEnabled = false
      context.imageSmoothingEnabled = false
      return canvas.toDataURL('image/jpeg', 1.0)
    })
    console.log(dataUrl1)
    // dataUrl.value = dataUrl1;
    // const dataUrl = canvas.toDataURL('image/png');
    const link = document.createElement('a')
    link.href = dataUrl1
    link.download = '小程序二维码.png'
    link.click()
  }, 1000)
  // const imgUrl = 'https://newstore.vynior.com/cems_web/tiyanerwm.jpg';
  // const response = await fetch(imgUrl);
  // const blob = await response.blob();
  // // 创建下载链接
  // const url = window.URL.createObjectURL(blob);
  // const link = document.createElement('a');
  // link.href = url;
  // link.download = '小程序二维码';
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
  // // 释放 Blob URL
  // window.URL.revokeObjectURL(url);
}
//项目详情显示隐藏
let projectDetails = ref(false)
</script>
<style lang="less" scoped>
@import url('@/style/views/VolunteerManagement/AppAdmin.less');
</style>
