<template>
  <!-- 专家评估 -->
  <div id="Project_sharing">
    <!-- <ShareMe></ShareMe> -->
    <!-- <MyShare></MyShare> -->
    <!-- <div class="un_data">
        <img src="@/assets/该页面正在开发中，敬请期待@2x.png" alt="">
     </div> -->
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
const route = useRoute();
const router = useRouter();

onMounted(() => {
  if (route.name == 'Project_sharing') {
    router.push({
      name: 'ShareMe',
    });
  }
});
</script>

<style lang="less" scoped>
@import url('@/style/views/ProjectManagement/Project_sharing.less');
</style>
