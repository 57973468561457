
const state={
    Type:"",

}

const mutations={
    set_Type(state,newValue){
        state.Type=newValue
    },

}


//导出
export default {
    state,
    mutations
}