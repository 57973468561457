<template>
  <div id="Project_sample">
    <div class="VolunteerBox" v-if="store.state.Sample.isVolunteer_tabel == false && !!store.state.Sample.project_id != false">
      <!-- 表内导航栏(筛选条件) -->
      <div class="project_main_state">
        <div
          :class="index == ClickActive ? 'click-active  main_state_item' : 'main_state_item'"
          v-for="(item, index) in ProjectState"
          :key="index"
          @click="isClickActive(index)">
          <div class="sample">{{ item.name }}</div>
          <div class="project_id">{{ item.code }}</div>
        </div>
      </div>
      <!-- 表体 -->
      <div class="VolunteerTable">
        <div class="vxetable">
          <vxe-toolbar>
            <template #buttons>
              <!-- 数量 -->
              <div class="sample_num">样品数量：{{ ProjectState[ClickActive]?.take_total }}</div>
              <!-- 规格 -->
              <div class="sample_size">样品规格：{{ ProjectState[ClickActive]?.specs }}</div>
              <!-- 批量 -->
              <img
                class="New_voluntee"
                @click="isBulk_distributionChange"
                src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B682324%402x.png"
                alt="" />

              <!-- 导出 -->
              <img class="batch" @click="expoet_data_box" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B682323%402x.png" alt="" />
            </template>
          </vxe-toolbar>
          <vxe-table
            border
            stripe
            ref="xTable"
            height="672"
            show-overflow
            row-id="_id"
            :data="demo1.tableData"
            :tooltip-config="tooltipConfig"
            :column-config="{ resizable: true, useKey: 'id' }"
            :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
            :checkbox-config="{ reserve: true }"
            :loading="demo1.loading"
            @header-cell-dragend="handleHeaderDragend"
            @checkbox-all="selectAllChangeEvent1"
            @checkbox-change="selectChangeEvent1">
            <vxe-column field="checked" type="checkbox" width="50" min-width="50" :fixed="demo1.colFixeds.col0"></vxe-column>
            <vxe-column field="record_code" type="html" title="样品分发编号" width="240" :fixed="demo1.colFixeds.col1" min-width="260">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="ID-header" v-if="column.title === '样品分发编号'">
                  <span class="name">样品分发编号</span>
                  <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('样品分发编号', $event)"></i></span>
                </div>
              </template>
              <template #default="{ row }">
                <span v-if="row.record_code">{{ row.record_code }}</span>
                <span v-if="row.record_code === undefined || !!row.record_code == false">——</span>
              </template>
            </vxe-column>
            <vxe-column field="status" type="html" title="样品检测状态" width="160" :fixed="demo1.colFixeds.col3" min-width="166">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Role-header" v-if="column.title === '样品检测状态'">
                  <span class="name">样品检测状态</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('样品检测状态', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <!-- <span :class="row.status === '收集中' ? 'sample_status_green' : row.status === '未发布' ? 'sample_status_yellow' : row.status === '已暂停' ? 'sample_status_blue' : ''" >{{ row.status }}</span> -->
                <span
                  :class="
                    row.status === '待检'
                      ? 'sample_status_blue'
                      : row.status === '在检'
                      ? 'sample_status_yellow'
                      : row.status === '检毕'
                      ? 'sample_status_green'
                      : row.status === '留样'
                      ? 'sample_status_un'
                      : ''
                  ">
                  {{ row.status }}</span
                >
              </template>
            </vxe-column>
            <vxe-column field="initval_weight" type="html" title="样品初始重量" width="160" :fixed="demo1.colFixeds.col3" min-width="166">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Role-header" v-if="column.title === '样品初始重量'">
                  <span class="name">样品初始重量</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('样品初始重量', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <span v-if="row.initval_weight === undefined">——</span>
                <span v-if="row.initval_weight">{{ row.initval_weight }}</span>
              </template>
            </vxe-column>
            <vxe-column field="recipient_name:" type="html" title="分发对象" width="160" :fixed="demo1.colFixeds.col4" min-width="206">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '分发对象'">
                  <span class="name">分发对象</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('分发对象', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <span>{{ !!row.recipient_name == false ? '——' : row.recipient_name }}</span>
              </template>
            </vxe-column>
            <vxe-column field="distribute_num" type="html" title="分发数量" width="160" :fixed="demo1.colFixeds.col5" min-width="207">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Address-header" v-if="column.title === '分发数量'">
                  <span class="name">分发数量</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('分发数量', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <span>{{ !!row.distribute_num == false ? '——' : row.distribute_num }}</span>
              </template>
            </vxe-column>
            <vxe-column field="distribute_date" type="html" title="样品分发日期" width="200" :fixed="demo1.colFixeds.col7" min-width="220">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Address-header" v-if="column.title === '样品分发日期'">
                  <span class="name">样品分发日期</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('样品分发日期', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <span>{{ !!row.distribute_date == false ? '——' : dayjs(row.distribute_date).format('YYYY-MM-DD') }}</span>
              </template>
            </vxe-column>
            <vxe-column field="recent_weight" type="html" title="样品最近重量" width="200" :fixed="demo1.colFixeds.col4" min-width="229">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '样品最近重量'">
                  <span class="name">样品最近重量</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('样品最近重量', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <span v-if="row.recent_weight === undefined || row.recent_weight == ''">——</span>
                <span v-if="row.recent_weight">{{ row.recent_weight }}g</span>
              </template>
            </vxe-column>
            <vxe-column field="return_date" type="html" title="样品回收日期" width="200" :fixed="demo1.colFixeds.col4" min-width="229">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Sex-header" v-if="column.title === '样品回收日期'">
                  <span class="name">样品回收日期</span>
                  <!-- <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('样品回收日期', $event)"></i></span> -->
                </div>
              </template>
              <template #default="{ row }">
                <span v-if="row.return_date">{{ row.return_date.slice(0, 10) }}</span>
                <span v-if="row.return_date === undefined || !!row.return_date == false">——</span>
              </template>
            </vxe-column>
            <vxe-column title="操作" width="285" :fixed="demo1.colFixeds.col9" :resizable="false">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Operation-header" v-if="column.title === '操作'">
                  <span class="name">操作</span>
                </div>
              </template>
              <template #default="{ row }">
                <div style="display: flex" class="more_item">
                  <span>
                    <!-- <el-tooltip class="box-item" effect="dark" content="查看详情" placement="top"> -->
                    <vxe-button class="detail" @click="ShowDetails(row)">查看详情</vxe-button>
                    <!-- </el-tooltip> -->
                  </span>
                  <span>
                    <!-- <el-tooltip class="box-item" effect="dark" content="分发样品" placement="top"> -->
                    <vxe-button v-if="row.status === '待检'" class="detail" @click="Distribute_samples(row)">分发样品</vxe-button>
                    <!-- </el-tooltip> -->
                  </span>
                  <span>
                    <!-- <el-tooltip class="box-item" effect="dark" content="回收样品" placement="top"> -->
                    <vxe-button
                      v-if="row.status === '在检' || row.status === '检毕' || row.status === '留样'"
                      :style="row.status === '检毕' || row.status === '留样' ? 'pointer-events: none;' : ''"
                      :class="row.status === '检毕' || row.status === '留样' ? 'detail_un' : 'detail'"
                      @click="Sample_recovery(row)"
                      >回收样品</vxe-button
                    >
                    <!-- </el-tooltip> -->
                  </span>
                  <span>
                    <!-- <el-tooltip class="box-item" effect="dark" content="留样" placement="top"> -->
                    <!-- <div style="pointer-events: none" v-if="row.status === '留样'" @click="window.log(1)" class="item">留样</div> -->
                    <vxe-button
                      :style="row.status === '留样' ? 'pointer-events: none;' : ''"
                      :class="row.status === '留样' ? 'detail_un' : 'detail'"
                      class="detail"
                      @click="Sample_retention(row)"
                      >留样</vxe-button
                    >
                    <!-- </el-tooltip> -->
                  </span>
                </div>
              </template>
            </vxe-column>
          </vxe-table>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
              </ul>
            </div>
          </div>
          <p v-if="demo1.tableData?.length > 0">
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                  >条/共{{ page5.totalResult }}条
                </div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
    </div>
    <!-- 空数据页面 -->
    <div class="EmptyProject" v-if="demo1.tableData?.length == 0 && !store.state.Sample.isVolunteer_tabel">
      <span>
        <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
        <p>暂无数据</p>
      </span>
    </div>

    <!-- ! 编辑-->
    <el-drawer v-model="Volunteereditor" :close-on-click-modal="Volunteereditor" title="I am the title" :with-header="false" size="57%">
      <div class="drawer">
        <div class="deawer_title">
          <div class="deawer_title_text">样品详情</div>
        </div>
        <div class="drawer_body">
          <!-- 编辑抽屉显示 -->
          <div class="information_box">
            <div class="item_title_box"></div>
            <div class="item_main_box">
              <div class="item1">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品分发编号</div>
                      <div class="item_main_text_value">{{ information.record_code }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text item_mian_text_right">样品状态</div>
                      <div class="item_main_text_value">{{ information.status }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1">样品分发对象</div>
                      <div class="item_main_text_value">{{ information.recipient_name }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1 item_mian_text_right">样品分发数量</div>
                      <div class="item_main_text_value">{{ information.distribute_num }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1">样品分发日期</div>
                      <div class="item_main_text_value">{{ dayjs(information.distribute_date).format('YYYY-MM-DD') }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="line"></div>

              <div class="item_title_box">
                <div class="line"></div>
                <div class="item_title">样品称重信息</div>
                <span class="icon" v-if="information.status == '在检'" @click="addRow" style="cursor: pointer">新增称重</span>
              </div>
              <div class="TableContent">
                <div class="History_item_table">
                  <el-table
                    border
                    :data="information.weights"
                    :row-config="{
                      isHover: true,
                      keyField: 'id',
                      height: linewidth <= 1280 ? 32 : 48,
                      minHeight: 36,
                      useKey: 'id',
                    }"
                    stripe
                    style="width: 1032px"
                    class="T-Header">
                    <el-table-column type="index" label="序号" align="left" width="56" />
                    <el-table-column prop="weight" label="样品重量" width="252">
                      <template #default="scope">
                        <div class="table-text" v-if="!scope.row.isAdd">{{ !!scope.row.weight ? scope.row.weight : '——' }}</div>
                        <el-input v-if="scope.row.isAdd" v-model="scope.row.weight" :placeholder="'请输入样品重量'" clearable />
                      </template>
                    </el-table-column>
                    <el-table-column prop="date" align="left" label="称重日期" width="324">
                      <template #default="scope">
                        <div class="table-text" v-if="!scope.row.isAdd">{{ !!scope.row.date ? scope.row.date : '——' }}</div>
                        <el-date-picker
                          v-if="scope.row.isAdd"
                          format="YYYY-MM-DD"
                          value-format="YYYY-MM-DD"
                          v-model="scope.row.date"
                          type="date"
                          placeholder="请选择"
                          size="size" />
                      </template>
                    </el-table-column>
                    <el-table-column prop="remark" align="left" label="备注" width="400">
                      <template #default="scope">
                        <div class="table-text" v-if="!scope.row.isAdd">{{ !!scope.row.remark ? scope.row.remark : '——' }}</div>
                        <el-input v-if="scope.row.isAdd" v-model="scope.row.remark" :placeholder="'请输入备注'" clearable />
                      </template>
                    </el-table-column>
                  </el-table>
                  <!-- <img class="delect" src="@/assets/删除@2x.png" alt=""> -->
                </div>

                <img
                  @click="reduceRow"
                  v-show="information.weights ? information.weights[information.weights.length - 1]?.isAdd == true : false"
                  src="https://newstore.vynior.com/cems_web/%E5%88%A0%E9%99%A4%402x111.png" />
              </div>

              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1">样品是否回收</div>
                      <div class="item_main_text_value">{{ information.isreturn == false ? '否' : '是' }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1">样品回收日期</div>
                      <div class="item_main_text_value">
                        {{ information.return_date ? dayjs(return_date).format('YYYY-MM_DD') : '' }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1">样品是否留样</div>
                      <div class="item_main_text_value">{{ information.isreserved ? '否' : '是' }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1">样品留样日期</div>

                      <div class="item_main_text_value">
                        {{ information.reservedDate ? information.reservedDate.substring(0, 10) : '' }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1">样品分发对象</div>
                      <div class="item_main_text_value">{{ information.recipient_name }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text1">样品分发数量</div>
                      <div class="item_main_text_value">{{ information.distribute_num }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <!-- 抽屉底部 -->
        <div class="drawer_footer" v-if="isAdd">
          <div class="null">
            <img @click="cancelWeightClick" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next">
            <img @click="push_Weighing_information" src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" />
          </div>
        </div>
      </div>
    </el-drawer>
    <!--  分发样品 -->
    <div class="Distribute_samples" v-if="isDistribute_samples">
      <div class="Distribute_samples_box">
        <div class="top">
          <div class="top_text">样品分发</div>
          <img src="https://new.vynior.com/%E7%BB%84110366%402x.png" @click="isDistribute_samples = !isDistribute_samples" />
        </div>
        <div class="main">
          <div class="item">
            <div class="item_text">样品分发编号</div>
            <div class="item_text_data">{{ click_data.record_code }}</div>
          </div>
          <div class="item" style="display: flex; flex-direction: column">
            <div style="display: flex">
              <div class="item_text"><span class="star">*</span>样品初始重量</div>
              <el-input
                :class="errObj.weight ? 'inp_err' : ''"
                placeholder="请输入重量数值及单位..."
                @focus="errObj.weight = false"
                v-model="Distribute_samples_data.flow_weight" />
            </div>
            <div class="ertext">
              <span v-if="errObj.weight">必选项不可为空</span>
            </div>
          </div>
          <div class="item" style="display: flex; flex-direction: column">
            <div style="display: flex">
              <div class="item_text"><span class="star">*</span>分发对象</div>
              <el-select
                :class="errObj.user ? 'inp_err' : ''"
                v-model="Distribute_samples_data.provide_volun"
                @focus="errObj.user = false"
                class="m-2"
                placeholder="请输入姓名搜索或者下拉选择"
                :remote-method="getVolunMethod"
                filterable
                remote
                size="large">
                <el-option v-for="item in Distribute_samples_options" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </div>
            <div class="ertext">
              <span v-if="errObj.user">必选项不可为空</span>
            </div>
          </div>
          <div class="hint" v-show="errObj.user == false && Distribute_samples_data.provide_volun == ''">
            没有找到分发对象？请联系该项目的招募管理负责人。<br />
            默认分发对象为该项目通过线上初审的志愿者名单。
          </div>
          <div class="item">
            <div class="item_text"><span class="star">*</span>分发数量</div>
            <div class="item_text_data">{{ Distribute_samples_data.provideNum }}</div>
          </div>
          <div class="item" style="display: flex; flex-direction: column">
            <div style="display: flex">
              <div class="item_text"><span class="star">*</span>分发日期</div>
              <el-date-picker
                :style="errObj.date ? 'border:1px solid #ff3200' : ''"
                :class="errObj.date ? 'inp_err' : ''"
                @focus="errObj.date = false"
                v-model="Distribute_samples_data.provideDate"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="请选择"
                :default-value="new Date(2023, 6, 29)" />
            </div>
            <div class="ertext">
              <span v-if="errObj.date">必选项不可为空</span>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="null" @click="isDistribute_samples = !isDistribute_samples">
            <img src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next" @click="true_Distribute_samples"><img src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" /></div>
        </div>
      </div>
    </div>
    <!-- 批量分发 -->
    <div class="Distribute_samples" v-if="isBulk_distribution">
      <div class="Distribute_samples_box">
        <div class="top">
          <div class="top_text">批量分发</div>
          <img src="https://new.vynior.com/%E7%BB%84110366%402x.png" @click="isBulk_distribution = !isBulk_distribution" />
        </div>
        <div class="main">
          <div class="main-top">
            注意：批量分发会随机匹配样品分发编号与分发对象（即已通过初审的志愿者名单），且“样品<br />
            初始重量”、“分发数量”、“分发日期”的信息将会保持一致。
          </div>
          <el-form ref="ruleFormRef" :model="Bulk_distribution_data" :rules="rules" class="demo-ruleForm">
            <div class="item">
              <div class="item_text"><span class="star">*</span>样品初始重量</div>
              <el-form-item prop="flow_weight">
                <el-input placeholder="请输入重量数值及单位..." v-model="Bulk_distribution_data.flow_weight" />
              </el-form-item>
            </div>
            <div class="item">
              <div class="item_text"><span class="star">*</span>分发数量</div>
              <div class="item_text_data">{{ Bulk_distribution_data.provideNum }}</div>
            </div>
            <div class="item">
              <div class="item_text"><span class="star">*</span>分发日期</div>
              <el-date-picker
                v-model="Bulk_distribution_data.provideDate"
                type="date"
                value-format="YYYY-MM-DD"
                placeholder="Pick a date"
                :default-value="new Date(2023, 6, 29)" />
            </div>
          </el-form>
        </div>
        <div class="footer">
          <div class="null" @click="isBulk_distribution = !isBulk_distribution">
            <img src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next" @click="Submit(ruleFormRef)">
            <img src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 留样 -->
    <div class="Distribute_samples" v-if="isSample_retention">
      <div class="Distribute_samples_box">
        <div class="top">
          <div class="top_text">样品留样</div>
          <img src="https://new.vynior.com/%E7%BB%84110366%402x.png" @click="isSample_retention = !isSample_retention" />
        </div>
        <div class="main">
          <div class="item">
            <div class="item_text">样品分发编号</div>
            <div class="item_text_data">{{ click_data.record_code }}</div>
          </div>
          <div class="item">
            <div class="item_text">分发对象</div>
            <div class="item_text_data">{{ click_data.recipient_name }}</div>
          </div>
          <div class="item">
            <div class="item_text">留样原因</div>
            <el-input placeholder="请输入留样原因" v-model="Sample_retention_data.reser" />
          </div>
          <div class="item">
            <div class="item_text"><span class="star">*</span>留样日期</div>
            <el-date-picker
              v-model="Sample_retention_data.reservedDate"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="Pick a date"
              :default-value="new Date(2023, 6, 29)" />
          </div>
        </div>
        <div class="footer">
          <div class="null" @click="isSample_retention = !isSample_retention">
            <img src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next" @click="true_Sample_retention"><img src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" /></div>
        </div>
      </div>
    </div>
    <!-- 回收样品 -->
    <div class="Distribute_samples" v-if="isSample_recovery">
      <div class="Distribute_samples_box">
        <div class="top">
          <div class="top_text">样品回收</div>
          <img src="https://new.vynior.com/%E7%BB%84110366%402x.png" @click="isSample_recovery = !isSample_recovery" />
        </div>
        <div class="main">
          <div class="item">
            <div class="item_text">样品分发编号</div>
            <div class="item_text_data">{{ click_data.record_code }}</div>
          </div>
          <div class="item">
            <div class="item_text"><span class="star">*</span>样品最终重量</div>
            <el-input placeholder="请输入重量数值及单位..." v-model="Sample_recovery_data.final_weight" />
          </div>
          <div class="item">
            <div class="item_text"><span class="star">*</span>分发对象</div>
            <div class="item_text_data">{{ click_data.recipient_name }}</div>
          </div>
          <div class="item">
            <div class="item_text"><span class="star">*</span>回收数量</div>
            <div class="item_text_data">1</div>
          </div>
          <div class="item">
            <div class="item_text"><span class="star">*</span>回收日期</div>
            <el-date-picker
              v-model="Sample_recovery_data.returnDate"
              type="date"
              value-format="YYYY-MM-DD"
              placeholder="Pick a date"
              :default-value="new Date(2023, 6, 29)" />
          </div>
        </div>
        <div class="footer">
          <div class="null" @click="isSample_recovery = !isSample_recovery">
            <img src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next" @click="true_Sample_recovery"><img src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" /></div>
        </div>
      </div>
    </div>
    <!-- !志愿者名单 -->
    <div class="VolunteerTable" v-if="store.state.Sample.isVolunteer_tabel">
      <div class="vxetable">
        <vxe-toolbar> </vxe-toolbar>
        <!-- ！志愿者数据 -->
        <div class="volunteer_box">
          <vxe-table
            border
            stripe
            ref="xTable"
            height="672"
            show-overflow
            row-id="_id"
            :data="demo2.tableData"
            :tooltip-config="tooltipConfig"
            :column-config="{ resizable: true, useKey: 'id' }"
            :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
            :checkbox-config="{ reserve: true }"
            :loading="demo1.loading"
            @header-cell-dragend="handleHeaderDragend">
            <vxe-column field="number" type="html" title="志愿者编号" width="240" :fixed="demo1.colFixeds.col1" min-width="260">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="ID-header" v-if="column.title === '志愿者编号'">
                  <span class="name">志愿者编号</span>
                </div>
              </template></vxe-column
            >
            <vxe-column field="volun_name" type="html" title="姓名" width="160" :fixed="demo1.colFixeds.col3" min-width="166">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Role-header" v-if="column.title === '姓名'">
                  <span class="name">姓名</span>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="gender" type="html" title="性别" width="160" :fixed="demo1.colFixeds.col3" min-width="166">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Role-header" v-if="column.title === '性别'">
                  <span class="name">性别</span>
                </div>
              </template>
            </vxe-column>
            <vxe-column field="age" type="html" title="年龄" width="160" :fixed="demo1.colFixeds.col3" min-width="166">
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="Role-header" v-if="column.title === '年龄'">
                  <span class="age">年龄</span>
                </div>
              </template>
            </vxe-column>
          </vxe-table>
        </div>
      </div>
    </div>
    <!-- 无数据导出 -->
    <div class="Derived_volunteer_null" v-if="null_reminder_export">
      <div class="Derived_volunteer_box">
        <div class="Derived_title">
          <div class="Derived_name">提示</div>
          <div>
            <img @click="null_reminder_export = !null_reminder_export" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
          </div>
        </div>
        <div class="Derived_body">
          <div><img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" /></div>
          <div class="Derived_body_text">请选择需要导出的项目样品流转数据!</div>
        </div>
      </div>
    </div>
    <!-- !导出的提示 -->
    <div class="Derived_volunteer" v-if="isDerived">
      <div class="Derived_volunteer_box">
        <!-- 导出一个 -->
        <div v-if="checkedArr.length == 1">
          <div class="Derived_title">
            已选中<span class="Derived_name">{{ selectedRows[0].recordNumber == ' ' ? '无名字' : selectedRows[0].recordNumber }}</span
            >样品流转单，确认导出吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">导出后可在excel表格中进行查看</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isDerived = !isDerived">取消</div>
            <div class="ok" @click="expoet_data">确认</div>
          </div>
        </div>
        <!-- 导出多个 -->
        <div v-if="checkedArr.length > 1">
          <div class="Derived_title">
            已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>样品流转单，确认导出吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">导出后可在excel表格中进行查看</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isDerived = !isDerived">取消</div>
            <div class="ok" @click="expoet_data">确认</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import data from '@/utils/CurrentDate';
import { replaceEmptyValues } from '@/utils/emptyArray';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
import dayjs from 'dayjs';
import { onMounted, reactive, onBeforeUnmount, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import * as xlsx from 'xlsx';
//API接收
const store = useStore();
const router = useRouter();
const xTable = ref();
const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};
onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

// table组件数据
let totalCount = ref(0);
const SetTableHeader = ref(false);
const project_data = ref([]); //项目样品流转记录
//demo 显示数据
const tableData = computed(() => {
  return replaceEmptyValues(demo1.tableData);
});
// ! 表的数据

const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [{}],
  loading: false,
  list: [],
  rename: '',
});
//必选项是否为空提示
let errObj = reactive({
  weigth: false,
  user: false,
  count: false,
  date: false,
});
//  表内导航栏
const ProjectState = ref([]); //导航 标题
const ProjectState_id = ref([]); //导航 编号
const ProjectState_flow_id = ref([]); //全部样品id
const cilck_ProjectState_flow_id = ref([]);
let ClickActive = ref(0);
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
});
let SORT = ref({ createdAt: 1 }); // sort排序字段
// 点击表头的下拉箭头
const OperationBox = ref(null); //获取更多操作盒子的ref
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1);
let ClickMoreOptions = ref(false);
const CMoreOptions = ref(null); //获取MoreOptions盒子的ref
let OptionsData = ref();
let EventSearch = ref(false); // 搜索
let Volunteereditor = ref(false); // 新建
const checkedArr = ref([]); //多选框
let isDerived = ref(false);
var selectedRows = reactive([]); //复选框数组
const isDistribute_samples = ref(false); //Distribute_samples 分发样品
const isBulk_distribution = ref(false); //批量分发
const isSample_retention = ref(false); //样品留样
const isSample_recovery = ref(false); //样品回收
const null_reminder_export = ref(false);
const click_data = ref([]); //点击的数据
const Distribute_samples_data = ref({
  //! 样品分发
  _id: click_data.value._id, //编号
  provide_volun: '', // 分发对象
  flow_weight: '', //重量
  provideDate: data(1), //分发日期
  provideNum: 1, // 分发数量
});
const Distribute_samples_options = ref([]); //项目分发对象
const Sample_recovery_data = ref({
  //! 样品回收
  _id: '', //id
  final_weight: '', //回收重量
  provide_volun: '', //分发对象
  returnNum: 1, //回收数量
  returnDate: data(1), //回收日期
});
const Sample_retention_data = ref({
  //!样品留样
  _id: '',
  recordNumber: '',
  provide_volun: '', //分发对象
  reservedCause: '', //留样原因
  reservedDate: data(1), //日期
});
// 批量分发的ref
const ruleFormRef = ref();

// 批量分发 的校验规则
const rules = reactive({
  flow_weight: [{ required: true, message: '请输入重量数值及单位...', trigger: 'blur' }],
});
const Bulk_distribution_data = ref({
  //!批量分发
  flow_id: '', //流转单ID
  projectId: '', //项目ID
  flow_weight: '', //重量
  provideDate: data(1), // 日期
  provideNum: 1, //数量
});
// ! 单行详细信息
let information = ref({
  _id: '', //样品分发账号
  status: '', //样品状态
  provide_volun: '', //样品分发对象
  provideNum: '', //样品分发数量
  provideDate: '', //样品分发日期
  //重量信息
  isreturn: '', //样品是否回收
  returnDate: '', //样品回收日期
  isreserved: '', //样品是否留样
  reservedDate: '', //样品留样日期
  reservedCause: '', //样品留样原因
});
// 项目样品流转记录的样品详情编辑状态
const isAdd = ref(false);
// !点击的志愿者信息
const oneDerivedData = ref('');
//导出弹窗显示隐藏
let dialogShow = ref(false);
let uploadPercentage = ref(0);
const fileLists = ref([]);
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params;
  console.log(columns, '表头的顺序');
  // // 打印排序后的表头列数组对象
};
// !项目样品流转记录详情表格数据
const Weighing_information = ref([
  {
    weight: '',
    date: '',
    remark: '',
  },
]);
// ! 下拉箭头
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value :>> ', value);
  const table = xTable.value;
  const FixedList = table.getColumns();
  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index;
    }
  });
  //console.log('FixedIndex下标为 :>> ', FixedIndex);
  showIdOptions.value = false;
  showIdOptions.value = true;
  // 设置位置
  const Event = event;
  OperationBox.value.style.left = event.clientX - 100 + 'px';
};
// tip tootip提示方法
const tooltipConfig = reactive({
  showAll: true,
  enterable: true,
  contentMethod: ({ type, column, row, items, _columnIndex }) => {
    const { field } = column;
    // column 判断 column.title 是否为'操作'
    // 重写默认的提示内容
    if (column.title == '操作') {
      return (tooltipConfig.showAll = false);
    } else if (field === 'rate') {
      // 返回空字符串，控制单元格不显示提示内容
      return '';
    } else {
      tooltipConfig.showAll = true;
    }
    // 其余的单元格使用默认行为
    return null;
  },
});
// !复选框点击
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  console.log(checked ? '所有勾选事件' : '所有取消事件', records);
  selectedRows = records;
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id);
      }
    }
    console.log('最新:', checkedArr.value);
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = [];

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id);
    });

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item);
    });
    console.log('最新:', checkedArr.value);
  }
};
// !复选框点击
const selectChangeEvent1 = (row) => {
  // console.log('点击的id:', row.rowid);
  console.log('rowidididid :>> ', row, row.row._id);

  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records;

  if (checkedArr.value.indexOf(row.row._id) == -1) {
    checkedArr.value.push(row.row._id);
  } else {
    let index = checkedArr.value.indexOf(row.row._id);
    checkedArr.value.splice(index, 1);
  }
  console.log('最新:', checkedArr.value);
};
const hiddenOperationBox = () => {
  showIdOptions.value = false;
  //console.log('点击点击 :>> ');
};
// ! More -->升序/降序
const AscendingSort = async (index, type) => {
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  const ColumnId = tableColumns[index].field;
  console.log(ColumnId, 'ColumnId');
  const sorts = {};
  if (type == 'Asc') {
    sorts[ColumnId] = 1;
  } else {
    sorts[ColumnId] = -1;
  }
  let flow_id;
  if (cilck_ProjectState_flow_id.value == '') {
    flow_id = ProjectState_flow_id.value[0];
  } else {
    flow_id = cilck_ProjectState_flow_id.value;
  }

  SORT.value = sorts;
  console.log(SORT.value, 'SORT.value');
  get_table_data();
};
// ! 项目框找数据
watch(
  () => ClickActive.value,
  () => {
    console.log('找数据');
    get_table_data();
  },
);
const currentSample = ref('');
// ! 导航栏点击
const isClickActive = async (index) => {
  ClickActive.value = index;
  currentSample.value = ProjectState.value[index];
  console.log('currentSample 导航栏点击:>> ', currentSample);
  cilck_ProjectState_flow_id.value = ProjectState_flow_id.value[index]; //点击第二个表单
  //  !调接口

  // try {
  //   let { records, totalCount } = await http.post('/flow/fetchRecordList-flow', {
  //     page: page5.currentPage,
  //     size: page5.pageSize,
  //     sort: SORT.value,
  //     flow_id: cilck_ProjectState_flow_id.value,
  //   });

  //   demo1.tableData = records;
  //   page5.totalResult = totalCount;
  // } catch (error) {
  //   console.log(error);
  // }
};
// !1分页--->跳转页面显示数据处理
const pageChange = async () => {
  // 判断flow_id
  let flow_id;
  if (cilck_ProjectState_flow_id.value == '') {
    flow_id = ProjectState_flow_id.value[0];
  } else {
    flow_id = cilck_ProjectState_flow_id.value;
  }
  console.log('跳转页面显示数据处理 :>> ');
  get_table_data();
};
const handlePreviews = (file) => {
  console.log(file, 'handlePreviews');
};
const infoWeights = ref([]);
// !每一行的查看详情
const ShowDetails = (row) => {
  // console.log('固定信息2:', row);
  //    id.value = row._id
  //    console.log(row)
  // information.value.weights[0].isAdd = false;
  information.value = row;
  // console.log(information.value.weights);
  if (information.value.weights) {
    for (let item of information.value.weights) {
      item.isAdd = false;
    }
  }
  information.value.weights.forEach((item) => {
    item.isAdd = false;
  });
  infoWeights.value = JSON.parse(JSON.stringify(information.value.weights));
  console.log(information.value, 'row');
  // Volunteerdetails.value = !Volunteerdetails.value;
  Volunteereditor.value = true;
};
// !详情点击添加
const addRow = () => {
  if (!store.state.role_info.includes('sample-flowRecords-appendWeightRecord')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  information.value.weights.push({
    weight: '',
    date: '',
    remark: '',
    isAdd: true,
  });
  isAdd.value = true;
};
const reduceRow = () => {
  if (!store.state.role_info.includes('sample-flowRecords-appendWeightRecord')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  information.value.weights.length = information.value.weights.length - 1;
};
// !志愿者数据展示
const demo2 = ref({
  tableData: [{}],
});
// !分发样品
const Distribute_samples = (row) => {
  if (!store.state.role_info.includes('sample-flowRecords-distribute')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  Distribute_samples_data.value.flow_weight = '';
  Distribute_samples_data.value.provide_volun;
  Distribute_samples_data.value.provideDate = data(1);
  isDistribute_samples.value = true;
  click_data.value = row;
  console.log(click_data.value, 'click_data.value');
  Distribute_samples_data.value._id = row._id;
  // !请求数据
  people();
};
// ! 获取方法对象
const people = async () => {
  try {
    let { options } = await http.post('/recruit/getTestVolunteer-recruit', {
      projectId: store.state.Sample.project_id,
    });
    console.log('请求成功', options);
    Distribute_samples_options.value = [];
    options.forEach((item, index) => {
      Distribute_samples_options.value.push({
        value: options[index]._id,
        label: options[index].volun_name,
      });
    });
  } catch (err) {
    console.log(err);
  }
};
// 搜索获取项目下志愿者
const getVolunMethod = async (val) => {
  const { options } = await http.post('/recruit/getTestVolunteer-recruit', {
    filter: val,
    projectId: store.state.Sample.project_id,
  });
  console.log(options, '远程搜索返回');
  Distribute_samples_options.value = [];
  options.forEach((item, index) => {
    Distribute_samples_options.value.push({
      value: options[index]._id,
      label: options[index].volun_name,
    });
  });
};
let is_weight = () => {
  if (Distribute_samples_data.value.flow_weight === '') {
    errObj.weight = true;
  } else {
    errObj.weight = false;
  }
};
let is_user = () => {
  if (Distribute_samples_data.value.provide_volun === '') {
    errObj.user = true;
  } else {
    errObj.user = false;
  }
};
let is_date = () => {
  console.log(Distribute_samples_data.value.provideDate);
  if (Distribute_samples_data.value.provideDate === null) {
    errObj.date = true;
  } else {
    errObj.date = false;
  }
};
const true_Distribute_samples = async () => {
  is_weight();
  is_user();
  is_date();
  //!点击确认分发样品
  if (errObj.date == false && errObj.user == false && errObj.weight == false) {
    const res = await http.post('/sample/distribute', {
      _id: click_data.value._id,
      recipient: Distribute_samples_data.value.provide_volun,
      weight: Distribute_samples_data.value.flow_weight,
      date: Distribute_samples_data.value.provideDate,
    });

    console.log(res, '分发样品返回');
    if (res.status == 0) {
      get_table_data();
      message({
        message: '分发样品成功',
        ShowClose: true,
        type: 'success',
      });
    } else {
      message({
        message: '分发样品失败',
        ShowClose: true,
        type: 'warning',
      });
    }
    // try {
    //   await http.post('/flow/distribute-flow', Distribute_samples_data.value);
    //   get_table_data();
    //   message({
    //     message: '分发样品成功',
    //     ShowClose: true,
    //     type: 'success',
    //   });
    //   Distribute_samples_data.value.flow_weight = '';
    //   Distribute_samples_data.value.provideDate = '';
    //   Distribute_samples_data.value.provideNum = '';
    //   Distribute_samples_data.value.provide_volun = '';
    // } catch (err) {
    //   console.log(err);
    //   message({
    //     message: '分发样品失败',
    //     ShowClose: true,
    //     type: 'warning',
    //   });
    // }
    isDistribute_samples.value = !isDistribute_samples.value;
  }
};
// ! 回收样品
const Sample_recovery = (row) => {
  if (!store.state.role_info.includes('sample-flowRecords-return')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  console.log(row, 'row');
  isSample_recovery.value = true;
  click_data.value = row;
  Sample_recovery_data.value._id = row._id;
  Sample_recovery_data.value.provide_volun = row.provide_volun;
};
// ! 确认回收
const true_Sample_recovery = async () => {
  const res = await http.post('/sample/returnSample', {
    _id: Sample_recovery_data.value._id,
    weight: Sample_recovery_data.value.final_weight,
    date: Sample_recovery_data.value.returnDate,
  });
  console.log(res, '回收样品返回');
  if (res.status == 0) {
    get_table_data();
    message({
      message: '回收样品成功',
      ShowClose: true,
      type: 'success',
    });
  } else {
    message({
      message: '回收样品失败',
      ShowClose: true,
      type: 'warning',
    });
  }
  isSample_recovery.value = !isSample_recovery.value;
};
// !留样
const Sample_retention = (row) => {
  console.log(row);
  if (!store.state.role_info.includes('sample-flowRecords-reserved')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  isSample_retention.value = true;
  Sample_retention_data.value.reser = '';
  Sample_retention_data.value.reservedDate = data(1);
  click_data.value = row;
  Sample_retention_data.value._id = row._id;
  Sample_retention_data.value.recordNumber = row.recordNumber;
  Sample_retention_data.value.provide_volun = row.provide_volun;
};
// ! 确认留样
const true_Sample_retention = async () => {
  if (!!Sample_retention_data.value.reservedDate == true) {
    const res = await http.post('/sample/retained', {
      _id: Sample_retention_data.value._id,
      retained_reason: Sample_retention_data.value.reser,
      date: Sample_retention_data.value.reservedDate,
    });
    if (res.status == 0) {
      get_table_data();
      message({
        message: '样品留样成功',
        ShowClose: true,
        type: 'success',
      });
    } else {
      message({
        message: '样品留样失败',
        ShowClose: true,
        type: 'warning',
      });
    }
  } else {
    message({
      message: '请选择必填项',
      ShowClose: true,
      type: 'warning',
    });
  }

  isSample_retention.value = !isSample_retention.value;
};
// ! 批量分发
const true_Bulk_distribution = async () => {
  if (demo1.tableData.length === 0) {
    message({
      message: '该项目无流转记录',
      ShowClose: true,
      type: 'warning',
    });
    return;
  }
  console.log(ProjectState.value[ClickActive.value].value);
  console.log(store.state.Sample.project_id);
  const res = await http.post('/sample/batchDistribute', {
    formId: ProjectState.value[ClickActive.value].value,
    project: store.state.Sample.project_id,
    weight: Bulk_distribution_data.value.flow_weight,
    date: Bulk_distribution_data.value.provideDate,
  });
  console.log(res, '批量分发返回');
  if (res.status) {
    message({
      message: '批量分发成功',
      ShowClose: true,
      type: 'success',
    });
    isBulk_distribution.value = !isBulk_distribution.value;
    get_table_data();
  } else {
    message({
      message: '批量分发失败',
      ShowClose: true,
      type: 'error',
    });
  }
  // try {
  //   let flow_id;
  //   if (cilck_ProjectState_flow_id.value == '') {
  //     flow_id = ProjectState_flow_id.value[0];
  //   } else {
  //     flow_id = cilck_ProjectState_flow_id.value;
  //   }
  //   Bulk_distribution_data.value.projectId = store.state.Sample.project_id;
  //   Bulk_distribution_data.value.flow_id = flow_id;
  //   await http.post('/flow/batchdistribute-flow', Bulk_distribution_data.value);
  //   message({
  //     message: '批量分发成功',
  //     ShowClose: true,
  //     type: 'success',
  //   });
  //   isBulk_distribution.value = !isBulk_distribution.value;
  //   get_table_data();
  // } catch (error) {
  //   console.log(error);
  // }
};
// ! 批量分发的校验方法
const Submit = async (formEl) => {
  if (!formEl) return;
  await formEl.validate((valid, fields) => {
    if (valid) {
      console.log('submit!');
      true_Bulk_distribution();
    } else {
      console.log('error submit!', fields);
    }
  });
};
// 取消新增称重
const cancelWeightClick = () => {
  information.value.weights = JSON.parse(JSON.stringify(infoWeights.value));
  isAdd.value = false;
};
// !添加称重记录
const push_Weighing_information = async () => {
  let isNull = false;
  if (information.value.weights) {
    for (let item of information.value.weights) {
      if (item.weight == '' && item.date == '') {
        isNull = true;
      }
    }
  }
  console.log(isNull);
  console.log(information.value.weights);

  const weightRecord = information.value.weights.filter((obj) => obj.hasOwnProperty('isAdd') && obj.isAdd === true);
  const uploadWeight = weightRecord.map(({ ['isAdd']: _, ...rest }) => rest);
  console.log(uploadWeight);
  console.log(weightRecord, 'weightRecord');
  if (isNull == false) {
    const res = await http.post('/sample/addWeightRecord', {
      _id: information.value._id,
      weightRecords: uploadWeight,
    });
    console.log(res, '新增称重返回');
    information.value.weights.forEach((item) => [(item.isAdd = false)]);
    infoWeights.value = information.value.weights;
    isAdd.value = false;
    get_table_data();
    // try {
    //   await http.post('/flow/addweightRecord-flow', {
    //     _id: information.value._id,
    //     weights: weightRecord,
    //   });
    //   message({
    //     message: '添加成功',
    //     grouping: true,
    //     type: 'success',
    //   });
    //   Volunteereditor.value = false;
    // } catch (error) {
    //   message({
    //     message: '添加失败',
    //     grouping: true,
    //     type: 'error',
    //   });
    //   console.log(error);
    // }
  } else {
    message({
      message: '请添加样品称重信息',
      grouping: true,
      type: 'error',
    });
  }
};
// !导出
const expoet_data = async () => {
  try {
    let res = await http.post('/upload/export-flowRecord', checkedArr.value);
    let projectNumber = '';
    store.state.Sample.project_data.forEach((data) => {
      if (data.projectId === store.state.Sample.project_id) {
        const [number, name] = data.project.split(' ');
        projectNumber = number;
      }
    });
    xlsx.writeFileXLSX(res.xlsxData, `样品流转记录 ${projectNumber} ${currentSample.value} ${dayjs().format('YYYYMMDD')}.xlsx`);
    isDerived.value = !isDerived.value;
    message({
      message: '导出成功',
      ShowClose: true,
      type: 'success',
    });
  } catch (error) {
    message({
      message: '导出失败',
      ShowClose: true,
      type: 'error',
    });
  }
};
// 点击批量分发
const isBulk_distributionChange = () => {
  if (!store.state.role_info.includes('sample-flowRecords-distribute')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  Bulk_distribution_data.value.flow_weight = '';
  Bulk_distribution_data.value.provideDate = data(1);
  isBulk_distribution.value = true;
};
// 点击导出
const expoet_data_box = () => {
  return;
  if (checkedArr.value.length == 0) {
    null_reminder_export.value = !null_reminder_export.value;
  } else {
    isDerived.value = !isDerived.value;
  }
};
const handlerDialog = () => {
  dialogFormVisible.value = true;
};
// ! 获取表单数据
const get_table_data = async () => {
  // !第一次没点击  是空  后面点击就用点击的
  let flow_id = '';
  if (cilck_ProjectState_flow_id.value == '' || cilck_ProjectState_flow_id.value == undefined) {
    flow_id = ProjectState_flow_id.value[0];
  } else {
    flow_id = cilck_ProjectState_flow_id.value;
  }
  console.log('获取表单数据 cilck_ProjectState_flow_id:>> ', ProjectState.value, ClickActive.value);
  const res = await http.post('/sample/findCirculateRecord', {
    formId: ProjectState.value[ClickActive.value].value,
    sort: SORT.value,
    size: page5.pageSize,
    page: page5.currentPage,
  });
  console.log(res, '获取样品下的表格');
  if (!res.status) {
    console.log('res :>> ', res);
  }
  demo1.tableData = res.result.records;
  console.log(demo1.tableData);
  page5.totalResult = res.result.totalCount;
};
// ! 获取志愿者数据
const get_Volunteer_data = async () => {
  let res = await http.post('/recruit/getTestVolunteer-recruit', {
    projectId: store.state.Sample.project_id,
  });
  demo2.value.tableData = res.options;
  console.log(demo2.value.tableData);
};
// ！获取样品数量
const SampleData = ref({});
const getSamepleNum = async () => {
  SampleData.value = {};
  const res = await http.post('/flow/fetchList-flow', {
    page: 1,
    size: 100,
    sort: {
      createdAt: -1,
    },
  });
  if (!res.status) {
    SampleData.value = res.result.find((item) => item._id == ProjectState_flow_id.value[ClickActive.value]);
    console.log('ProjectState_flow_idProjectState_flow_id', SampleData.value, ProjectState_flow_id.value);
    // 获取四级表头数据
    let arr = store.state.Sample.project_data; //下拉选择
  }
};

watch(
  () => store.state.Sample.isVolunteer_tabel,
  () => {
    if (store.state.Sample.isVolunteer_tabel == true) {
      get_Volunteer_data();
    }
  },
);
// bug 修改跳转没有值的问题，与下面的监听数据重叠
// ! 监听流转输入的的变化
watch(
  () => store.state.Sample.project_id,
  async () => {
    console.log('调用接口，第一次数据改变了');
    // ! 全部的数据   项目样品流转记录
    project_data.value = store.state.Sample.project_data;
    const res = await http.post('/sample/getCirculateFormSampleOptions', {
      projectId: store.state.Sample.project_id,
    });
    console.log(res, '获取项目下的样品');
    ProjectState.value = res.result;
    get_table_data();

    // let arr = store.state.Sample.project_data;
    // console.log(arr);
    // // !先清除
    // ProjectState.value = [];
    // ProjectState_id.value = [];
    // ProjectState_flow_id.value = [];
    // console.log('arr数据', project_data.value, arr);

    // let sample_id = '';
    // arr.forEach((item, index) => {
    //   if (arr[index].projectId === store.state.Sample.project_id) {
    //     arr[index].sample.forEach((item, index) => {
    //       ProjectState.value.push(item.sample_name);
    //       ProjectState_id.value.push(item.sample_number);
    //       ProjectState_flow_id.value.push(item.flow_id);
    //     });
    //   }
    // });
    // if (ProjectState_flow_id.value.length != 0) {
    //   getSamepleNum(); //获取样品数量和样品规格
    //   // ! 变化之后  调用接口  请求
    //   // get_table_data();
    //   isClickActive(0);
    // }
    console.log(ProjectState.value, ProjectState_id.value, ProjectState_flow_id.value);
  },
);

// !监听从流转全部 跳转到项目样品流转
watch(
  () => store.state.Sample.Click_projectdata,
  async () => {
    console.log('我从全部过来');
    // ! 全部的数据   项目样品流转记录
    project_data.value = store.state.Sample.project_data; //下拉选择
    console.log(store.state.Sample.Click_projectdata, 'store.state.Sample.Click_projectdata');
    console.log('仓库里面的数据', store.state.Sample.project_data);
    if (!store.state.Sample.Click_projectdata.project) {
      return;
    }
    const res = await http.post('/sample/getCirculateFormSampleOptions', {
      projectId: store.state.Sample.Click_projectdata.project,
    });
    console.log(res, '获取项目下的样品');
    ProjectState.value = res.result;
    res.result.forEach((item, index) => {
      if (item._id == store.state.Sample.Click_projectdata._id) {
        ClickActive.value = index;
      }
    });
    get_table_data();
    // !先清除
    // ProjectState.value = []; //名字
    // ProjectState_id.value = []; //名字
    // ProjectState_flow_id.value = []; //样品id
    // console.log('arr数据', project_data.value, arr, '仓库里面的数据', store.state.Sample.project_data);
    // arr.forEach((item, index) => {
    //   // 查找跳转过来的数据
    //   if (arr[index].projectId == store.state.Sample.Click_projectdata.projectId) {
    //     arr[index].sample.forEach((item, index) => {
    //       ProjectState.value.push(item.sample_name);
    //       ProjectState_id.value.push(item.sample_number);
    //       ProjectState_flow_id.value.push(item.flow_id);
    //     });
    //   }
    // });
    // // ! 变化之后  调用接口  请求
    // if (ProjectState_flow_id.value != '') {
    //   getSamepleNum(); //获取样品数量和样品规格
    //   get_table_data();
    // }
    console.log('全部数据请求');
  },
  { deep: true, immediate: true },
);

onMounted(async () => {
  console.log(project_data.value, 'project_data.value');
  data(1);
  store.commit('Set_CurrentProjectName', '样品流转记录');
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // 操作
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false;
      }

      console.log(ClickMoreOptions.value);
    }
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
      console.log(ClickMoreOptions.value);
    }
  });
  console.log(store.state.Sample.project_id, 'project_ID_value');
  if (!store.state.Sample.project_id) {
    return;
  }
  const res = await http.post('/sample/getCirculateFormSampleOptions', {
    projectId: store.state.Sample.project_id,
  });
  console.log(res, '获取项目下的样品');
  ProjectState.value = res.result;
  res.result.forEach((item, index) => {
    if (item._id == store.state.Sample.Click_projectdata._id) {
      ClickActive.value = index;
    }
  });
  get_table_data();
});
</script>
<style lang="less" scoped>
@import url('@/style/views/SampleManagement/Project_sample.less');
</style>
