<template>
  <div id="ReportApproval">
    <el-drawer
      :show-close="false"
      :close-on-click-modal="false"
      model-value="isReportApproval"
      title="I am the title"
      :with-header="false"
      size="40%">
      <div class="Deawer">
        <div class="top">
          <div class="top_text">报告审批</div>
        </div>
        <div class="main">
          <div class="item1">
            <div class="item_title">报告名称</div>
            <div class="item_value">{{ click_tableData.report_name }}</div>
          </div>
          <div class="item">
            <div class="item_title">报告编号</div>
            <div class="item_value">{{ click_tableData.report_number }}</div>
          </div>
          <div class="item">
            <div class="item_title">检测项目</div>
            <div class="item_value">{{ click_tableData.project_number }}</div>
          </div>
          <div class="item">
            <div class="item_title">项目负责人</div>
            <div class="item_value">{{ click_tableData.project_director }}</div>
          </div>
          <div class="item">
            <div class="item_title">报告提交时间</div>
            <div class="item_value">{{ dayjs(click_tableData.createdAt).format('YYYY-MM-DD') }}</div>
          </div>
          <div class="item">
            <div class="item_title"><span class="star">*</span>审批结果</div>
            <div :class="checked[1] == true ? 'item_value_textarea' : 'item_value'">
              <!-- !选择框 -->
              <div>
                <el-radio v-model="checked[0]" :class="errStatus ? 'errLable' : ''" @change="checkNum(true)" label="审批通过" size="large" />
                <el-radio v-model="checked[1]" :class="errStatus ? 'errLable' : ''" @change="checkNum(false)" label="审批拒绝" size="large" />
              </div>
              <div class="err" v-if="errStatus">审批结果不能为空</div>
              <el-input
                v-if="checked[1] == true"
                v-model="textarea"
                maxlength="30"
                placeholder="请输入审批拒绝原因（选填）"
                show-word-limit
                type="textarea" />
            </div>
          </div>
          <div class="item">
            <div class="item_title">上传电子签章</div>
            <div class="item_value1">
              <div class="img_upload" @click="isUoloat_imgChange">
                <img class="img_upload_img" src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%4022x.png" alt="" v-if="!Signature.Confirm" />
                <div class="img_upload_text" v-if="!Signature.Confirm">上传电子签章</div>
                <img :src="ShowPcture" class="picture" alt="" v-if="Signature.Confirm" />
                <img
                  @click.stop="ClearImg"
                  class="delete"
                  v-if="Signature.Confirm"
                  src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402xss.png"
                  alt="" />
              </div>
              <!-- <div class="img_upload" v-show="Signature.Confirm">
                <img :src="Signature.Upload" alt="" />
              </div> -->
              <div class="img_text">*该签章将呈现于报告首页</div>
            </div>
          </div>
          <!-- bug 需要拿到用户名字 -->
          <div class="item2">
            <div class="item_title">报告审批员</div>
            <div class="item_value">{{ click_tableData.project_director }}</div>
          </div>
        </div>

        <div class="footer">
          <div class="null">
            <img @click="handleClose" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next">
            <img @click="submit" src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" />
          </div>
        </div>
        <!-- 是否通过/拒绝 -->
        <div class="ChangeS" v-if="isdialogVisible">
          <el-dialog v-model="isdialogVisible" width="30%">
            <template #header="{ titleId, titleClass }">
              <div class="my-header">
                <div :id="titleId" v-if="checked[0] != false" :class="titleClass">是否通过审批?</div>
              </div>
              <div class="my-header">
                <div :id="titleId" v-if="checked[0] == false" :class="titleClass">是否拒绝审批?</div>
              </div>
            </template>
            <div class="Body" v-if="checked[0] != false">
              <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />通过后无法撤回！
            </div>
            <div class="Body" v-if="checked[0] == false">
              <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />拒绝后无法撤回！
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Cancel" @click="isdialogVisible = false">取消</el-button>
                <el-button class="Delete" @click="submit1"> 确认 </el-button>
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </el-drawer>
    <!-- // 提交图片-->
    <div class="uploat_box" v-if="isUoloat_img">
      <div class="uploat_main">
        <div class="top">
          <div class="top_text">从本地上传</div>
          <img class="cancel_img" src="https://newstore.vynior.com/quxiaos.png" alt="" @click="isUoloat_img = !isUoloat_img" />
        </div>
        <div class="main">
          <div class="left">
            <div class="left_text">选择系统电子印章</div>
            <div class="main">
              <!-- 一个没有 -->
              <div class="text_none" v-if="img.length === 0">
                暂无电子签章，请前往签章管理<span style="color: #027aff; cursor: pointer" @click="GoPath">上传</span>
              </div>
              <!-- 一个居中 -->
              <div class="img_one" v-if="img.length === 1">
                <div
                  class="Item"
                  :class="img_index[0] === 0 ? 'text_one click' : 'text_one'"
                  v-for="(item, index) in img"
                  :key="index"
                  @click="click_imgBox(index)">
                  <img class="ImgPicture" :src="item" alt="" />

                  <img v-if="img_index[0] === 0" class="click_img" src="https://newstore.vynior.com/%E7%BB%8411559%402x.png" alt="" />
                </div>
              </div>
              <!-- 大于一个  滑动选择 -->
              <div class="img_more" v-if="img.length > 1">
                <div
                  class="Item"
                  :class="img_index[index] === index ? ' click' : ''"
                  v-for="(item, index) in img"
                  :key="index"
                  @click="click_imgBox(index)">
                  <img class="ImgPicture" :src="item" alt="" />
                  <img v-if="img_index[index] === index" class="click_img" src="https://newstore.vynior.com/%E7%BB%8411559%402x.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="line"></div>
          <div class="right">
            <div class="right_text">
              <div class="title">本地上传</div>
              <div class="value">支持图片格式：jpg、jpeg、png</div>
            </div>
            <div class="right_box">
              <el-upload
                class="avatar-uploader"
                :http-request="uploadFile"
                :on-preview="handlePreviews"
                :on-remove="handleRemoves"
                :file-list="fileLists"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar" />

                <div class="img_upload" v-show="!Signature.UpShow">
                  <img class="img_upload_img" src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%4022x.png" alt="" />
                  <div class="img_upload_text">上传电子签章</div>
                </div>
                <div class="img_upload" v-show="Signature.UpShow">
                  <img class="picture" :src="Signature.Upload" alt="" />
                  <img @click.stop="ClearImg" class="delete" src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402xss.png" alt="" />
                </div>
              </el-upload>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="null">
            <img @click="isUoloat_img = !isUoloat_img" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next">
            <img @click="identify()" src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, ref, defineProps, reactive, nextTick, toRaw, toRefs, provide, onMounted, computed, onBeforeUnmount, watch } from 'vue';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
import dayjs from 'dayjs';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
//API接收
const store = useStore();
const router = useRouter();
let TypeValue = ref('签章');
let Signature = ref({
  Upload: '', //本地上传的图片地址或者是选择的图片地址
  UpShow: false,
  Confirm: false, //确认本地本地
  ResetImg: '',
});
let textarea = ref(''); //拒绝数据
const isdialogVisible = ref(false);
let ShowPcture = ref(''); //显示上传的确认签章
const isReportApproval = ref(false); //!抽屉显示
const checked = ref([false, false]);
const img = ref([]); //!图片数据
const img_index = ref([]); //!选择的对应下标
const isUoloat_img = ref(false);
const errStatus = ref(false);
const tableData = ref([]);
const click_tableData = ref({}); //!展示数据
// ! 审批结果
const checkNum = (status) => {
  errStatus.value = false;
  if (status === true) {
    checked.value[1] = false;
  } else if (status === false) {
    checked.value[0] = false;
  }
};
const isUoloat_imgChange = () => {
  if (!store.state.role_info.includes('project-examine-upload')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    });
    return;
  }
  isUoloat_img.value = !isUoloat_img.value;
};
// 审批确认按钮
const identify = () => {
  isUoloat_img.value = !isUoloat_img.value;
  Signature.value.UpShow = false;
  if (Signature.value.Upload != '') {
    ShowPcture.value = Signature.value.Upload; //点击确认 赋值显示;
    Signature.value.Confirm = true;
  } else {
    Signature.value.Confirm = false;
  }
};

// ! 照片选择
const click_imgBox = (index) => {
  console.log('img_index.value :>> ', img_index.value);
  if (img_index.value[index] == -1) {
    img_index.value.forEach((item, index) => {
      img_index.value[index] = -1;
    });
    img_index.value[index] = index;
    // 将选择的图片地址 赋值
    Signature.value.Upload = img.value[index];
  } else {
    img_index.value[index] = -1;
    Signature.value.Upload = '';
  }
};
// 上传限制
const beforeAvatarUpload = (rawFile) => {
  console.log('rawFile.type :>> ', rawFile.type);
  if (rawFile.type !== 'image/png' && rawFile.type !== 'image/jpg' && rawFile.type !== 'image/jpeg') {
    message.error('上传文件必须为:JPG,JPEG,PNG格式!');
    return false;
  }
  return true;
};
//! 提交审批
const submit = async () => {
  // num 为true时 两个都为false； 需要提示 不为空；
  // num为 false时，有选项，判断同意和拒绝；
  const num = checked.value.every((item) => item === false);
  console.log('num :>> ', num);
  if (num) {
    errStatus.value = true;
    return;
  } else {
    isdialogVisible.value = true;
  }

  // emits('ReportApproval', false, 'end');
};
const submit1 = async () => {
  isdialogVisible.value = true;
  console.log('发送请求 :>> ');
  const res = await http.post('/report/approval-report', {
    reportId: store.state.Report.Details._id,
    userId: localStorage.getItem('_id'),
    is_approval: checked.value[0] != false ? true : false,
    refusal_cause: textarea.value,
    signaturePath: Signature.value.Upload,
  });
  console.log('审批通过的返回结果res :>> ', res);
  if (res.status == 0) {
    isdialogVisible.value = false;
  }
  emits('ReportApproval', false, 'end');
};
// 上传
const uploadFile = async (params) => {
  const file = params.file;

  const form = new FormData();

  form.append('userId', localStorage.getItem('_id'));
  form.append('type', '签章');
  form.append('file', file);
  console.log(params);
  await http
    .post('/upload/upload-user', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async (res) => {
      console.log(res);
      if (res.status == 0) {
        Signature.value.UpShow = true;
        // 清空系统选择
        img_index.value.forEach((item, index) => {
          img_index.value[index] = -1;
        });
        reqSing('本地上传');
        message({
          showClose: true,
          message: '上传文件成功',
          type: 'success',
        });
      } else if (res.status == 1) {
        message({
          showClose: true,
          message: '上传文件失败',
          type: 'error',
        });
      }
    });
};
// !点击的关闭事件
let emits = defineEmits(['ReportApproval']);
const handleClose = () => {
  emits('ReportApproval', false);
  // is_dialogShow.value = false
};

// 请求本地图片
const reqSing = async (val) => {
  const res = await http.post('/user/findFile-user', {
    userId: localStorage._id,
    type: TypeValue.value,
  });

  console.log('签章管理的请求数据res :>> ', res);
  if (res.status == 0) {
    if (val != '本地上传') {
      res.file.forEach((item, index) => {
        img.value.push(item.accessPath);
        img_index.value.push(-1);
      });
    } else {
      Signature.value.Upload = res.file[res.file.length - 1].accessPath;
    }
    console.log('    img_index.valu :>> ', img_index.value);
    // ImgList.value = res.file;
  }
};

// 清除img
const ClearImg = () => {
  ShowPcture.value = '';
  Signature.value.Confirm = false;
  Signature.value.Upload = '';
  Signature.value.UpShow = false;
};

// 跳转到签章管理
const GoPath = () => {
  router.push('/SignatureManagement');
};
onMounted(() => {
  // !获取数据
  click_tableData.value = store.state.Report_details.Report_data;

  reqSing();
});
</script>

<style lang="less" scoped>
@import url('@/style/components/Reportapproval/Reportapproval');
</style>
