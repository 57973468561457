<template>
  <div class="projectList">
    <router-view></router-view>
    <div class="project_main" v-if="route.name !== 'ProjectDetails' && route.path !== '/ProjectList/CreateProject'">
      <div class="project_main_state">
        <!-- 筛选status状态按钮 -->
        <div
          class="main_state_item"
          :class="index == ClickActive ? 'click-active' : ''"
          v-for="(item, index) in ProjectState"
          :key="index"
          @click="ClickActive = index">
          {{ item }}
        </div>
      </div>

      <div class="ProjectListBox">
        <div class="vxetable">
          <!-- 表格头部 工具按钮 -->
          <vxe-toolbar>
            <template #buttons>
              <el-input class="Search" v-model="demo1.filterName" placeholder="请输入项目编号、名称、负责人进行搜索" placeholder-style="color:red">
                <template #suffix>
                  <el-icon @click="searchEvent2">
                    <Search />
                  </el-icon>
                </template>
              </el-input>
              <vxe-button @click="ResetTable" class="Reset"><span class="reset-icon">重置</span></vxe-button>
              <vxe-button class="Show" :class="SetShow ? 'ShowHiden' : ''"
                ><span @click.stop="SetShowFunction" class="show-icon">显示</span></vxe-button
              >
              <div class="CreateProject" @click="CreateProjectChange">
                <button>
                  <img src="https://newstore.vynior.com/cems_web/add1.png" />
                  <span>新建项目</span>
                </button>
              </div>
              <div class="Import" @click="isShowExport">
                <button>
                  <img src="https://newstore.vynior.com/%E5%AF%BC%E5%87%BA%4022x.png" />
                  <span>导出</span>
                </button>
              </div>
            </template>
          </vxe-toolbar>
          <!-- 表格 -->
          <div class="tableOutSide" v-show="demo1.tableData.length != 0">
            <vxe-table
              border
              stripe
              ref="xTable"
              height="auto"
              auto-resize
              show-overflow
              :loading="demo1.loading"
              :data="demo1.tableData"
              :tooltip-config="tooltipConfig"
              :column-config="{ resizable: true, useKey: 'id' }"
              :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
              row-id="_id"
              :checkbox-config="{ reserve: true }"
              @header-cell-dragend="handleHeaderDragend"
              @checkbox-all="selectAllChangeEvent1"
              @checkbox-change="selectChangeEvent1">
              <vxe-column
                field="checked"
                type="checkbox"
                height="48"
                min-height="36"
                width="41"
                min-width="40"
                :fixed="demo1.colFixeds.col0"></vxe-column>
              <vxe-column
                v-for="(item, index) in allDatas"
                :key="index"
                :title="item.lable"
                :field="item.field"
                type="html"
                :width="item.width"
                min-height="36"
                :fixed="item.fixed"
                :min-width="item.min_width">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === item.lable">
                    <span class="name">{{ item.lable }}</span>

                    <span v-if="item.lable != '操作'"
                      ><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item.lable, $event)"></i
                    ></span>
                    <span v-else><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <div class="rows" v-if="item.lable == '操作'">
                    <el-tooltip class="box-item" effect="dark" content="查看详情" placement="top">
                      <vxe-button class="detail" :class="row.status == '已完成' || row.status == '进行中' ? 'Empty' : ''" @click="ShowDetails(row)"
                        >查看详情</vxe-button
                      >
                    </el-tooltip>
                    <template v-if="row.status == '未开始' || row.status == '未发布'">
                      <el-tooltip class="box-item" effect="dark" content="发布" placement="top">
                        <vxe-button class="issue" @click="Release(row)">发布</vxe-button>
                      </el-tooltip>
                    </template>
                    <template v-else-if="row.status == '招募中'">
                      <el-tooltip class="box-item" effect="dark" content="停止" placement="top">
                        <vxe-button class="stop">停止</vxe-button>
                      </el-tooltip>
                    </template>
                    <template v-else-if="row.status == '已停止'">
                      <el-tooltip class="box-item" effect="dark" content="重启" placement="top">
                        <vxe-button class="restart" title="重启">重启</vxe-button>
                      </el-tooltip>
                    </template>

                    <span class="More" id="CMoreOption" @click="ClickMethods(row, $event)">···</span>
                  </div>
                  <div class="rows" v-else-if="item.lable == '项目完成进度'">
                    <div class="project-progress">
                      <span class="show-text">{{ Number(row.progress).toFixed(0) }}%</span>
                      <el-progress :percentage="row.progress" :show-text="false" />
                    </div>
                  </div>
                  <div class="rows" v-else-if="item.lable == '项目开始日期'">
                    {{ format_time(row.projectStart_time) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '项目结束日期'">
                    {{ format_time(row.projectEnd_time) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '招募结束时间'">
                    {{ format_time(row.recruitEnd_time) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '招募开始时间'">
                    {{ format_time(row.recruitStart_time) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '项目创建时间'">
                    {{ format_time(row.createdAt) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '项目产品'">
                    {{ JSON.stringify(row.product).replace(/"|\[|\]/g, '') }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '测试部位'">
                    {{ JSON.stringify(row.testArea).replace(/"|\[|\]/g, '') }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '测试提示'">
                    {{ row.prompt ? row.prompt : '———' }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '其他'">
                    {{ row.other ? row.other : '———' }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '测试年龄限制'">
                    {{ JSON.stringify(row.ageRequirement).replace(/"|\[|\]/g, '') }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '项目状态'">
                    <div class="project-status">
                      <span class="status_style" :class="status_style(row.status)">{{ row.status == '未发布' ? '未开始' : row.status }}</span>
                    </div>
                  </div>

                  <div class="rows" v-else>
                    {{ row[item.field] }}
                  </div>
                </template>
              </vxe-column>

              <template #empty>
                <span style="color: red">
                  <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
                  <p>暂无数据</p>
                </span>
              </template>
            </vxe-table>
          </div>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex)">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="DescendingSort(FixedIndex)">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
                <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')" :class="!CancelFixed ? 'light' : ''">
                  <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" v-show="CancelFixed" />
                  <img src="https://newstore.vynior.com/cems_web/%E9%94%81%E5%AE%9A%402xs.png" alt="" v-show="!CancelFixed" />固定
                </li>
                <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" :class="CancelFixed ? 'light' : ''">
                  <img src="https://newstore.vynior.com/cems_web/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="!CancelFixed" />
                  <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="CancelFixed" />取消固定
                </li>

                <li id="MoreOptions" @click="HiddenColumn(FixedIndex)"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li>
              </ul>
            </div>
          </div>
          <!-- 批量志愿者信息  导出 -->
          <div class="Derived_volunteer" v-if="isExport">
            <div class="Derived_volunteer_box">
              <!-- 导出一个 -->
              <div v-if="checkedArr.length == 1">
                <div class="Derived_title">
                  确定导出<span class="Derived_name">{{ selectedRows[0].name == ' ' ? '无名字' : selectedRows[0].name }}</span>
                  的项目信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="exportDataEvent">确认</div>
                </div>
              </div>
              <!-- 导出多个 -->
              <div v-if="checkedArr.length > 1">
                <div class="Derived_title">
                  已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>项目信息，确认导出吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="exportDataEvent">确认</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 导出一条没有的时候 -->
          <div class="Derived_volunteer_null" v-if="null_reminder_export">
            <!-- 导出 -->
            <div class="Derived_volunteer_box">
              <div class="Derived_title">
                <div class="Derived_name">提示</div>
                <div><img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
              </div>
              <div class="Derived_body">
                <div><img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" /></div>
                <div class="Derived_body_text">请选择需要导出的项目信息！</div>
              </div>
            </div>
          </div>
          <!-- 每一行的 ... -->
          <div class="Options" id="CMoreOption" ref="CMoreOptions" v-show="ClickMoreOptions">
            <div class="Share" @click="ShareProject">分享</div>
            <div class="Delete" @click="DeleteProject">删除</div>
          </div>
          <!-- 分页 -->
          <p class="Pager" v-show="demo1.tableData.length != 0">
            <vxe-pager
              class="Buttons"
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                  >条/共{{ page5.totalResult }}条
                </div>
              </template>
            </vxe-pager>
          </p>
          <!-- 空项目 -->
          <div class="EmptyProject" v-show="demo1.tableData.length == 0">
            <span>
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
              <p>暂无数据</p>
            </span>
          </div>
        </div>
      </div>
      <!-- 表头字段设置 -->
      <div class="SetTableHeader" v-if="SetTableHeader">
        <div class="main">
          <div class="Header">
            <div class="text">
              表头字段设置<img @click="CancelShowHiden" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402x.png" alt="" />
            </div>
          </div>
          <!-- 设置表头的数据盒子 -->
          <div class="SetBox">
            <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
            <div class="LeftBox">
              <div class="text-header">项目列表预设字段</div>
              <div class="ProjectRowField">
                <div class="text-header1">【项目基本信息】</div>
                <!-- 复选框数组 -->
                <div class="CheckBox">
                  <div
                    class="Checkbox"
                    v-for="(item, index) in SetTableHeaderArr.slice(0, 11)"
                    :key="index"
                    :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                    <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                  </div>
                </div>
              </div>
              <!-- 【项目招募信息】 -->
              <div class="ProjectRecruitMSG">
                <div class="text-header2">【项目招募信息】</div>
                <!-- 复选框数组 -->
                <div class="CheckBox">
                  <div class="Checkbox" v-for="(item, index) in SetTableHeaderArr.slice(11, 24)" :key="index">
                    <el-checkbox
                      v-model="item.checked"
                      :label="item.lable"
                      size="large"
                      @change="CheckHeader(index + 11)"
                      :class="item.lable.indexOf(defaultHeader) != -1 ? 'default' : ''" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 右边盒子 拖动 -->
            <div class="RightBox">
              <div class="text-header">项目列表预设字段</div>
              <!-- 调整数据 -->
              <div class="switchBox">
                <div id="xxx" class="demo">
                  <draggable
                    :list="dragDemo"
                    ghost-class="ghost"
                    :force-fallback="true"
                    chosen-class="chosenClass"
                    animation="300"
                    handle=".move"
                    delay="100"
                    @end="onEnd">
                    <template #item="{ element }">
                      <div class="item move">
                        <div class="drag"></div>
                        {{ element.lable }}
                        <!-- -->
                        <img
                          class="top"
                          @click="backTop(element, 'top')"
                          src=" https://newstore.vynior.com/cems_web/%E7%BB%841112%402x.png   "
                          alt="" />
                        <img
                          class="delete"
                          @click="backTop(element, 'delete')"
                          :src="
                            defaultHeader.indexOf(element.lable) != -1
                              ? 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88s%402x.png'
                              : 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88221%402x.png'
                          "
                          alt="" />
                      </div>
                    </template>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
          <!-- 底部 -->
          <div class="Bottom">
            <div class="add" @click="CustomBoxDataChange">
              <img src="https://newstore.vynior.com/%E7%BB%84%20110130%402x.png" alt="" />
              添加自定义拓展字符
            </div>
            <div class="recover" @click="RecoverData">
              <img src="https://newstore.vynior.com/%E5%9B%9E%E5%A4%8D%E6%81%A2%E5%A4%8D.png" alt="" />
              恢复默认字符
            </div>
            <div class="cancel" @click="CancelShowHiden">
              <!-- <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" /> -->
              取消
            </div>
            <div class="save" @click="ShowHidenColumn">
              <!-- <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" /> -->
              保存
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 删除弹窗 -->
    <div class="Derived_volunteer_delect" v-if="isShowDelect">
      <div class="Derived_volunteer_box">
        <!-- !单个删除操作 -->
        <div>
          <div class="Derived_title">
            确认删除<span class="Derived_name">{{ DelectData.name }}</span
            >的志愿者信息吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived_delect">取消</div>
            <div class="ok" @click="oneDeleteVolun">删除</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分享弹窗 -->
    <div class="ShareBox" v-if="isShareProject">
      <el-dialog v-model="isShareProject" title="项目分享" width="640" heigt="360" align-center>
        <div class="share">
          <span class="share_title">通过账号分享</span>
          <!-- <el-input v-model="shareInput" placeholder="请输入用户账号或姓名进行搜索" /> -->
          <!-- <el-select v-model="shareInput" filterable placeholder="请输入用户账号或姓名进行搜索">
            <el-option v-for="item in userNameArr" :key="item._id" :label="item.name" :value="item.name" />
          </el-select> -->

          <el-input v-model="shareInput" placeholder="请输入用户账号" class="shareInput" />
          <span class="input_notice">请在项目分享前，确认账户是否存在</span>
          <div class="share_notice">
            <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x1.png" alt="" />
            <span>被分享的用户仅有项目“可查看”的权限</span>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="cancel" @click="isShareProject = false">取消</el-button>
            <el-button class="sure" type="primary" @click="shareSearch"> 确定 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 显示功能弹窗 -->
    <div class="SetShowsBox" ref="ShowBox" v-show="SetShow" id="SetShowsBoxs">
      <div class="Titles" id="SetShowsBoxs">字段显示</div>
      <div class="ContentBox" :class="AllHidenHeader.length == 0 ? 'Emptys' : ''" id="SetShowsBoxs">
        <span class="Empty" v-if="AllHidenHeader.length == 0" id="SetShowsBoxs">暂无隐藏字段</span>
        <div class="ListBox" v-if="AllHidenHeader.length != 0" id="SetShowsBoxs">
          <div class="List" v-for="(item, index) in AllHidenHeader" :key="index" @click="ShowCheck[index] = !ShowCheck[index]" id="SetShowsBoxs">
            <div class="item" id="SetShowsBoxs">{{ item.title }}</div>

            <div class="Active" v-show="ShowCheck[index]" id="SetShowsBoxs">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110500%402x.png" alt="" />
            </div>
          </div>
          <div
            class="Button"
            @click="ConfirmRecover"
            id="SetShowsBoxs"
            :class="AllHidenHeader.length == 1 ? 'Buttons' : AllHidenHeader.length == 2 ? 'Buttonss' : ''">
            确认
          </div>
        </div>
      </div>
    </div>
    <!-- custom自定义弹窗 -->

    <div class="CustomBox" id="CustomBoxs">
      <el-dialog v-model="CustomBoxData.CustomBoxShow" title="自定义扩展字段" class="DIALOG">
        <div class="InputBox">
          <div class="field">
            <div class="title"><span>*</span>字段标题</div>
            <div class="content" :class="CustomBoxData.FieldError == true ? 'FieldError' : ''">
              <el-input v-model="CustomBoxData.FieldTopic" placeholder="请输入" />
              <div class="Error">必填项不可为空</div>
            </div>
          </div>
          <!-- 输入类型 -->
          <div class="Type">
            <div class="title"><span>*</span>输入类型</div>
            <div class="content" :class="CustomBoxData.TypeError == true ? 'TypEerror' : ''">
              <el-select v-model="CustomBoxData.InputType" placeholder="请选择">
                <el-option label="单项选择" value="单项选择" />
                <el-option label="多项选择" value="多项选择" />
                <el-option label="数字" value="数字" />
                <el-option label="日期" value="日期" />
                <el-option label="文本" value="文本" />
                <el-option label="文件" value="文件" />
                <el-option label="富文本" value="富文本" />
              </el-select>
              <div class="Error">必填项不可为空</div>
            </div>
          </div>

          <!-- 注释 -->
          <div class="Note">
            <div class="title">注释</div>
            <div class="content">
              <el-input
                v-model="CustomBoxData.NoteText"
                maxlength="20"
                placeholder="请输入对于字段标题的注释，当鼠标悬停在表头字段时该信息会呈现"
                show-word-limit
                resize="none"
                type="textarea" />
            </div>
          </div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="CustomBoxData.CustomBoxShow = false">取消</el-button>
            <el-button type="primary" @click="AddCustom"> 完成 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script setup>
import Date from '@/utils/CurrentDate'
import ase from '@/utils/cryptoUtils'
import { message } from '@/utils/resetMessage'
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, onBeforeMount, ref, watch, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as xlsx from 'xlsx-js-style'
import draggable from 'vuedraggable'
import { Search } from '@element-plus/icons-vue'
import http from '@/utils/http'
// import { debounce, throttle } from 'lodash';
import dayjs from 'dayjs'
const linewidth = ref(window.innerWidth)
const handleResize = () => {
  linewidth.value = window.innerWidth
}
const isExport = ref(false)
const null_reminder_export = ref(false)
// ! 关闭提醒弹窗
const Closereminder = () => {
  null_reminder_export.value = false
}
onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})
//tip新数据结构------------------：
// Vue.createApp(xxx).mount('#xxx');
const onEnd = () => {}

let dragDemo = ref([])
let allDatas = ref([
  // { fixed: '', field: 'number', checked: true, lable: '项目编号' },
  // { fixed: '', field: 'name', checked: true, lable: '项目名称' },
  // { fixed: '', field: 'product', checked: true, lable: '项目产品' },
  // { fixed: '', field: 'director_name', checked: true, lable: '项目负责人' },
  // { fixed: '', field: 'projectStart_time', checked: true, lable: '项目开始日期' },
  // { fixed: '', field: 'projectEnd_time', checked: true, lable: '项目结束日期' },
  // { fixed: '', field: 'status', checked: true, lable: '项目状态' },
  // { fixed: '', field: 'progress', checked: true, lable: '项目完成进度' },
  // { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
])
// 置顶/删除方法
const backTop = (e, val) => {
  console.log('val :>> ', e)
  let indexItem = -1
  dragDemo.value.forEach((item, index) => {
    if (item.lable == e.lable) {
      indexItem = index
    }
  })
  console.log('下标是： :>> ', indexItem)
  // 置顶方法
  if (val == 'top') {
    if (indexItem != 0) {
      dragDemo.value.unshift(dragDemo.value.splice(indexItem, 1)[0])
    }
  } else {
    // 不能删除的操作
    if (defaultHeader.value.indexOf(e.lable) != -1) {
      message({
        message: `${e.lable}不能删除`,
        showClose: true,
        type: 'error',
      })
    } else {
      // 可以删除的操作 同步删除左侧勾选；
      // 删除方法
      dragDemo.value.splice(indexItem, 1)
      const index = SetTableHeaderArr.value.findIndex((item) => item.lable === e.lable)
      SetTableHeaderArr.value[index].checked = false
    }
  }

  console.log(' dragDemo.value; :>> ', dragDemo.value)
}

// tip-------------------------
//API接收
const store = useStore()
const router = useRouter()
const route = useRoute()
// //console.log(route.name);
let allData = reactive([])
let totalCount = ref(0)
const xTable = ref()
// 每一行的 ... 点击
let ClickMoreOptions = ref(false)
//复选框数组
var selectedRows = reactive([])
const checkedArr = ref([])
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
})
// sort排序字段
let SORT = ref({ createdAt: -1 })
//当前时间
let CurrentDate = ref('')
//项目详情
let ProjectDetailsShow = ref(false)
// 固定列
const FixedList = reactive([])
// 项目状态
const ProjectState = ref('')
ProjectState.value = ['全部项目', '未开始', '招募中', '进行中', '已完成', '已停止']
let inputValue = ref('')
// 切换stage
const ClickActive = ref(0)
//note--->demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  loading: true,
  list: [],
})
// 控制删除
const isShowDelect = ref(false)
// 控制分享
const isShareProject = ref(false)
//分享输入框
const shareInput = ref('')
// 存储删除信息
const DelectData = ref()
//bug 自定义字段设置框
let CustomBoxData = ref({
  CustomBoxShow: false,
  FieldTopic: '', //字段标题
  InputType: '', //输入类型
  NoteText: '', //注释
  FieldError: false, //错误提示
  TypeError: false, //错误提示
})
const AddCustom = () => {
  //console.log('进入方法', CustomBoxData.value);

  if (CustomBoxData.value.FieldTopic != '' && CustomBoxData.value.InputType != '') {
    CustomBoxData.value.CustomBoxShow = false
    CustomBoxData.value.FieldError = false
    CustomBoxData.value.TypeError = false
    //console.log('都不为空');
  } else if (CustomBoxData.value.FieldError == '' && CustomBoxData.value.InputType != '') {
    //console.log('文件为空', CustomBoxData.value);

    CustomBoxData.value.FieldError = true
  } else if (CustomBoxData.value.FieldError != '' && CustomBoxData.value.InputType == '') {
    //console.log('属性为空', CustomBoxData.value);

    CustomBoxData.value.TypeError = true
  } else if (CustomBoxData.value.FieldError == '' && CustomBoxData.value.InputType == '') {
    CustomBoxData.value.FieldError = true
    CustomBoxData.value.TypeError = true
  }
}
//新建项目
let CreateProjectShow = ref(false)
// 列表的显示隐藏
const ColumnVisible = reactive([true])
const SetTableHeader = ref(false)

// //console.log('store.state.user.CurrentPage', store.state.user.CurrentPage);
let CancelFixed = ref(false) //控制每个表头下拉箭头 固定和取消固定的高亮
// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([])
// 表头字段设置--->设置表头的勾选数据
let SetTableHeaderArr = ref([
  { fixed: '', field: 'number', checked: true, lable: '项目编号', width: '252', min_width: '252' },
  { fixed: '', field: 'name', checked: true, lable: '项目名称', width: '208', min_width: '208' },
  { fixed: '', field: 'product', checked: true, lable: '项目产品', width: '168', min_width: '168' },
  { fixed: '', field: 'client', checked: false, lable: '项目客户', width: '168', min_width: '168' },
  { fixed: '', field: 'director_name', checked: true, lable: '项目负责人', width: '148', min_width: '148' },
  { fixed: '', field: 'projectStart_time', checked: true, lable: '项目开始日期', width: '148', min_width: '148' },
  { fixed: '', field: 'projectEnd_time', checked: true, lable: '项目结束日期', width: '148', min_width: '148' },
  { fixed: '', field: 'createdAt', checked: false, lable: '项目创建时间', width: '148', min_width: '148' },
  { fixed: '', field: 'status', checked: true, lable: '项目状态', width: '128', min_width: '128' },
  { fixed: '', field: 'progress', checked: true, lable: '项目完成进度', width: '168', min_width: '168' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作', width: '198', min_width: '198' },
  { fixed: '', field: 'recruitStart_time', checked: false, lable: '招募开始时间', width: '148', min_width: '148' },
  { fixed: '', field: 'recruitEnd_time', checked: false, lable: '招募结束时间', width: '148', min_width: '148' },
  { fixed: '', field: 'peopleNumber', checked: false, lable: '招募人数', width: '128', min_width: '128' },
  { fixed: '', field: 'subsidy', checked: false, lable: '补助金额', width: '128', min_width: '128' },
  { fixed: '', field: 'testArea', checked: false, lable: '测试部位', width: '128', min_width: '128' },
  { fixed: '', field: 'testAddress', checked: false, lable: '测试地址', width: '148', min_width: '148' },
  { fixed: '', field: 'ageRequirement', checked: false, lable: '测试年龄限制', width: '148', min_width: '148' },
  { fixed: '', field: 'sexRequirement', checked: false, lable: '测试性别要求', width: '148', min_width: '148' },
  { fixed: '', field: 'admissionCondition', checked: false, lable: '测试入选条件', width: '168', min_width: '168' },
  { fixed: '', field: 'exclusionCondition', checked: false, lable: '测试排除条件', width: '168', min_width: '168' },
  { fixed: '', field: 'prompt', checked: false, lable: '测试提示', width: '168', min_width: '168' },
  { fixed: '', field: 'plan', checked: false, lable: '测试计划', width: '252', min_width: '252' },
  { fixed: '', field: 'other', checked: false, lable: '其他', width: '148', min_width: '148' },
])

// 表头字段设置--->恢复默认字段
let DefaultHeaderData = ref([
  { width: '252', min_width: '252', fixed: '', field: 'number', checked: true, lable: '项目编号' },
  { width: '208', min_width: '208', fixed: '', field: 'name', checked: true, lable: '项目名称' },
  { width: '168', min_width: '168', fixed: '', field: 'product', checked: true, lable: '项目产品' },
  { width: '168', min_width: '168', fixed: '', field: 'client', checked: false, lable: '项目客户' },
  { width: '148', min_width: '148', fixed: '', field: 'director_name', checked: true, lable: '项目负责人' },
  { width: '148', min_width: '148', fixed: '', field: 'projectStart_time', checked: true, lable: '项目开始日期' },
  { width: '148', min_width: '148', fixed: '', field: 'projectEnd_time', checked: true, lable: '项目结束日期' },
  { width: '148', min_width: '148', fixed: '', field: 'createdAt', checked: false, lable: '项目创建时间' },
  { width: '128', min_width: '128', fixed: '', field: 'status', checked: true, lable: '项目状态' },
  { width: '168', min_width: '168', fixed: '', field: 'progress', checked: true, lable: '项目完成进度' },
  { width: '198', min_width: '198', fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  { width: '148', min_width: '148', fixed: '', field: 'recruitStart_time', checked: false, lable: '招募开始时间' },
  { width: '148', min_width: '148', fixed: '', field: 'recruitEnd_time', checked: false, lable: '招募结束时间' },
  { width: '128', min_width: '128', fixed: '', field: 'peopleNumber', checked: false, lable: '招募人数' },
  { width: '128', min_width: '128', fixed: '', field: 'subsidy', checked: false, lable: '补助金额' },
  { width: '128', min_width: '128', fixed: '', field: 'testArea', checked: false, lable: '测试部位' },
  { width: '148', min_width: '148', fixed: '', field: 'testAddress', checked: false, lable: '测试地址' },
  { width: '148', min_width: '148', fixed: '', field: 'ageRequirement', checked: false, lable: '测试年龄限制' },
  { width: '148', min_width: '148', fixed: '', field: 'sexRequirement', checked: false, lable: '测试性别要求' },
  { width: '168', min_width: '168', fixed: '', field: 'admissionCondition', checked: false, lable: '测试入选条件' },
  { width: '168', min_width: '168', fixed: '', field: 'exclusionCondition', checked: false, lable: '测试排除条件' },
  { width: '168', min_width: '168', fixed: '', field: 'prompt', checked: false, lable: '测试提示' },
  { width: '252', min_width: '252', fixed: '', field: 'plan', checked: false, lable: '测试计划' },
  { width: '148', min_width: '148', fixed: '', field: 'other', checked: false, lable: '其他' },
])

// 显示功能的字段设置Y
let AllShowHeader = ref([])
let defaultHeader = ref(['项目编号', '项目名称', '项目产品', '项目负责人', '项目开始日期', '项目结束日期', '项目状态', '项目完成进度', '操作'])

// 全部显示里被隐藏的
let AllHidenHeader = ref([])
const SetShow = ref(false)
const ShowBox = ref(null) //获取Show盒子的ref
const CMoreOptions = ref(null) //获取MoreOptions盒子的ref
const ShowCheck = ref([])
// 模糊搜索 判断是否是第一次
const EventSearch = ref(false)
// 监听排序
watch(
  () => SORT.value,
  (newValue) => {
    //console.log('SORT.newValue :>> ', newValue);
  }
)
//切换/未开始/招募中/进行中/已停止/全部项目
watch(
  () => ClickActive.value,
  async (newValue) => {
    const Project = {}
    if (newValue == 1) {
      Project.status = '未发布'
    } else if (newValue == 2) {
      Project.status = '招募中'
    } else if (newValue == 3) {
      Project.status = '进行中'
    } else if (newValue == 4) {
      Project.status = '已完成'
    } else if (newValue == 5) {
      Project.status = '已停止'
    } else if (newValue == 0) {
      Project.status = '全部项目'
    }
    page5.currentPage = 1
    //console.log('Project--->', Project);
    ClearCheck()
    const res = await http.post('/project/find-project', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: '',
      status: Project.status,
    })
    // //console.log('res--->', res);
    demo1.tableData = res.result
    page5.totalResult = res.totalCount
  }
)
//监听翻页之后展示的最新数据
watch(
  () => demo1.tableData,
  (newValue, oldValue) => {
    const $table = xTable.value
    // //console.log('$table :>> ', $table);
    // //console.log('selectedRows :>> ', selectedRows);
  }
)

// 监听
watchEffect(() => {
  if (demo1.tableData.length != 0) {
    demo1.loading = false
    // searchEvent1();
  }
})

// NEXTTICK
nextTick(() => {
  setHeaderColumn()
  // 执行 列表显示/隐藏

  ShowHidenColumn()
  // 固定列表本地数据
  FixedColumn()
  // 获取拖拽数据
  GetDrag()
  // 转移数据
  TransferData()
})

// 切换页面清空多选数据
const ClearCheck = () => {
  // 清除数据
  const $table = xTable.value
  $table.clearCheckboxRow()
  checkedArr.value.splice(0, checkedArr.value.length)
}
// 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true
  TemporaryData.value = JSON.parse(JSON.stringify(SetTableHeaderArr.value))
  // //console.log('TemporaryData :>> ', TemporaryData.value);
}
// 表头字段设置 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false
  SetTableHeaderArr.value = JSON.parse(JSON.stringify(TemporaryData.value))
}
// 表头阻断设置  的点击复选框
const CheckHeader = (i) => {
  // 默认展示的字段/不可取消

  if (defaultHeader.value.indexOf(SetTableHeaderArr.value[i].lable) != -1) {
    message({
      message: `${SetTableHeaderArr.value[i].lable}不能删除`,
      showClose: true,
      type: 'error',
    })
    SetTableHeaderArr.value[i].checked = true

    return
  }
  // console.log('object :>> ', SetTableHeaderArr.value[i]);
  // 如果是true ，push进dragdemo
  if (SetTableHeaderArr.value[i].checked) {
    dragDemo.value.push(SetTableHeaderArr.value[i])
  } else {
    //如果是false，从dragdemo中删除
    dragDemo.value = dragDemo.value.filter((item) => item.lable !== SetTableHeaderArr.value[i].lable)
  }
  console.log('dragDemo.value :>> ', dragDemo.value)
}
// 添加自定义字段设置
const CustomBoxDataChange = () => {
  if (!store.state.role_info.includes('project-list-addStr')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  CustomBoxData.value.CustomBoxShow = true
}

// 表头字段设置  恢复默认字段
const RecoverData = () => {
  //console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value);
  // let defaultdata = readonly(DefaultHeaderData)
  SetTableHeaderArr.value = JSON.parse(JSON.stringify(DefaultHeaderData.value))
  // 恢复默认 拖拽数据
  dragDemo.value = []
  SetTableHeaderArr.value.forEach((item, index) => {
    if (item.checked) {
      dragDemo.value.push(item)
    }
  })
}
// 获取当前的全部数据
const TransferData = () => {
  // 将全部显示的数据  放到 AllShowHeader中
  SetTableHeaderArr.value.forEach((item, index) => {
    if (item.checked == true) {
      AllShowHeader.value.push(item)
    }
  })
  //console.log('AllShowHeader.value :>> ', AllShowHeader.value);
}
// ! 获取event 设置show显示框的位置
const SetShowFunction = (event) => {
  SetShow.value = false
  SetShow.value = true

  // //console.log('event :>> ', event, ShowBox.value);
  ShowBox.value.style.left = event.clientX - 10 + 'px'
  ShowBox.value.style.top = event.clientY + 20 + 'px'
}
// ! 恢复目前隐藏的列表 确认按钮
const ConfirmRecover = () => {
  const $table = xTable.value
  const column = $table.getColumns()

  for (let i = ShowCheck.value.length; i >= 0; i--) {
    if (ShowCheck.value[i] == true) {
      // // column[index].visible = false;
      // //console.log('column :>> ', ShowCheck.value[i], AllHidenHeader.value[i]);

      // 显示列
      $table.showColumn(AllHidenHeader.value[i].field)
      // 同步删除 数据 隐藏列/checked
      AllHidenHeader.value.splice(i, 1)
      ShowCheck.value.splice(i, 1)
      // //console.log('column :>> ', column, $table);
      // //console.log('column :>> ', $table.showColumn());
      // //console.log('column :>> ', ShowCheck.value, AllHidenHeader.value);
    }
  }
  SetShow.value = false
}

// todo
// 点击...
const ClickMethods = (row, event) => {
  // //console.log(row);
  DelectData.value = row
  // //console.log(DelectData.value);
  ClickMoreOptions.value = false
  ClickMoreOptions.value = true

  // //console.log('event :>> ', event, CMoreOptions.value);

  CMoreOptions.value.style.left = event.clientX - 100 + 'px'
  CMoreOptions.value.style.top = event.clientY + 35 + 'px'
  // console.log('event :>> ', ClickMoreOptions.value);
}
//全部数据
const reqProjectList = async () => {
  let Project = {}
  if (ClickActive.value == 1) {
    Project.status = '未发布'
  } else if (ClickActive.value == 2) {
    Project.status = '招募中'
  } else if (ClickActive.value == 3) {
    Project.status = '进行中'
  } else if (ClickActive.value == 4) {
    Project.status = '已完成'
  } else if (ClickActive.value == 5) {
    Project.status = '已停止'
  } else if (ClickActive.value == 0) {
    Project.status = '全部项目'
  }
  const res = await http.post('/project/find-project', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: '',
    status: Project.status,
  })
  // //console.log('res :>> ', res);
  demo1.tableData = res.result
  page5.totalResult = res.totalCount
}
// 检查是否有本地数据()
const setHeaderColumn = () => {
  if (localStorage.getItem('ShowHiddenArr') == null) {
    // //console.log('"第一次" :>> ', '第一次');
  } else {
    // //console.log('"第二次" :>> ', '第二次');
    SetTableHeaderArr.value = ase.decrypt(localStorage.getItem('ShowHiddenArr'))
  }
  SetTableHeaderArr.value.forEach((item, index) => {
    if (item.checked == true) {
      allDatas.value.push(SetTableHeaderArr.value[index])
    }
  })
  console.log('最终渲染的全部true数据', allDatas.value)
  // 赋值给dragDemo；
  dragDemo.value.splice(0, dragDemo.value.length)
  dragDemo.value = JSON.parse(JSON.stringify(allDatas.value))
}
// 检查本地
const GetDrag = () => {
  if (localStorage.getItem('ProductDrag') == null) {
    // //console.log('"第一次" :>> ', '第一次');
  } else {
    // //console.log('"第二次" :>> ', '第二次');
    allDatas.value = ase.decrypt(localStorage.getItem('ProductDrag'))
    dragDemo.value = JSON.parse(JSON.stringify(allDatas.value))
  }
}
// ShowHidenColumn 设置column的的列表(确认按钮)
const ShowHidenColumn = () => {
  // 把drag字段放到 列表里；
  allDatas.value = JSON.parse(JSON.stringify(dragDemo.value))
  //drag end
  const $table = xTable.value
  const column = $table.getColumns()

  // //console.log('$table.getColumns :>> ', $table.getColumns());

  for (let i = 0; i < SetTableHeaderArr.value.length; i++) {
    if (SetTableHeaderArr.value[i].checked == true) {
      // console.log('object :>> ', SetTableHeaderArr[i]);
      $table.showColumn(SetTableHeaderArr.value[i].field)
    }
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetTableHeaderArr.value[i].lable) {
        if (SetTableHeaderArr.value[i].checked == false) {
          $table.hideColumn(column[j].field)
        }
      }
    }
  }

  $table.refreshColumn()
  console.log('$table.getColumns :>> ', $table.getColumns())
  // tip 处理数据
  let endData = $table.getColumns()
  let data = []
  endData.forEach((item, index) => {
    if (index > 0) {
      //排除第一个多选从1开始；

      let obj = {}
      obj.title = item.title
      obj.field = item.field
      obj.fixed = item.fixed
      data.push(obj)
      // console.log(item.title);
    }
  })
  console.log('最终渲染的数据是', data)
  SetTableHeader.value = false
}
// FixedColumn 设置column的固定列(初始化本地数据)
const FixedColumn = () => {
  const $table = xTable.value
  const column = $table.getColumns()

  // //console.log('column:>> ', column);
  console.log('SetTableHeaderArr', SetTableHeaderArr.value)
  for (let i = 0; i < SetTableHeaderArr.value.length; i++) {
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetTableHeaderArr.value[i].lable) {
        if (SetTableHeaderArr.value[i].fixed != '') {
          toggleFixedColumn(j, SetTableHeaderArr.value[i].fixed)
        }
      }
    }
  }

  $table.refreshColumn()
  console.log('$table.getColumns :>> ', $table.getColumns())
  // SetTableHeader.value = false;
}
// 复选框点击事件
const selectChangeEvent1 = (row) => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  // //console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records
  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid)
  } else {
    let index = checkedArr.value.indexOf(row.rowid)
    checkedArr.value.splice(index, 1)
  }
  //console.log('最新:', checkedArr.value);
}
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  //console.log(checked ? '所有勾选事件' : '所有取消事件', records);
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id)
      }
    }
    //console.log('最新:', checkedArr.value);
  } else {
    // //console.log('demo1.tableData:', demo1.tableData);
    let current_id = []

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id)
    })

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item)
    })
    //console.log('最新:', checkedArr.value);
  }
}
//分页---->跳转页面显示数据处理
const pageChange = async () => {
  let Project = {}
  if (ClickActive.value == 1) {
    Project.status = '未发布'
  } else if (ClickActive.value == 2) {
    Project.status = '招募中'
  } else if (ClickActive.value == 3) {
    Project.status = '进行中'
  } else if (ClickActive.value == 4) {
    Project.status = '已完成'
  } else if (ClickActive.value == 5) {
    Project.status = '已停止'
  } else if (ClickActive.value == 0) {
    Project.status = '全部项目'
  }
  if (demo1.filterName == '') {
    const res = await http.post('/project/find-project', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: '',
      status: Project.status,
    })
    demo1.tableData = res.result
    page5.totalResult = res.totalCount
  } else {
    searchEvent2()
  }

  //console.log('page,size :>> ', SORT.value);
}

// const ChangeActive = () => {};

const getinptChange = () => {
  //console.log('123');
}
// note升序降序
const DescendingSort = async (index) => {
  const $table = xTable.value
  const tableColumns = $table.getColumns()

  const ColumnId = tableColumns[index].field

  const sorts = {}
  sorts[ColumnId] = -1
  SORT.value = sorts
  const Project = {}
  if (ClickActive.value == 1) {
    Project.status = '未发布'
  } else if (ClickActive.value == 2) {
    Project.status = '招募中'
  } else if (ClickActive.value == 3) {
    Project.status = '进行中'
  } else if (ClickActive.value == 4) {
    Project.status = '已完成'
  } else if (ClickActive.value == 5) {
    Project.status = '已停止'
  } else if (ClickActive.value == 0) {
    Project.status = '全部项目'
  }
  const res = await http.post('/project/find-project', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: sorts,
    content: '',
    status: Project.status,
  })
  demo1.tableData = res.result
}
const AscendingSort = async (index) => {
  const Project = {}
  if (ClickActive.value == 1) {
    Project.status = '未发布'
  } else if (ClickActive.value == 2) {
    Project.status = '招募中'
  } else if (ClickActive.value == 3) {
    Project.status = '进行中'
  } else if (ClickActive.value == 4) {
    Project.status = '已完成'
  } else if (ClickActive.value == 5) {
    Project.status = '已停止'
  } else if (ClickActive.value == 0) {
    Project.status = '全部项目'
  }
  //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  //console.log('固定信息:', tableColumns);
  const ColumnId = tableColumns[index].field
  const sorts = {}
  sorts[ColumnId] = 1
  SORT.value = sorts
  const res = await http.post('/project/find-project', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: '',
    status: Project.status,
  })
  demo1.tableData = res.result
}

// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false
  const $table = xTable.value
  const column = $table.getColumns()
  // //console.log('column[index] :>> ', column[index]);
  column[index].visible = false
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value.push(column[index])
  ShowCheck.value.push(false)
  $table.refreshColumn() //刷新列表
  // //console.log('ShowChcke.value :>> ', ShowCheck.value);
  // //console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
}

const CreateProjectChange = () => {
  if (!store.state.role_info.includes('project-list-created')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  CreateProjectShow.value = true
  //console.log('新建项目');
  router.push({ path: '/ProjectList/CreateProject' })
  // store.commit('Set_CurrentProjectName', '新建项目');
}

const CreateProjectShowChange = () => {
  CreateProjectShow.value = false
}
// 每一行的发布
const Release = async (row) => {
  if (!store.state.role_info.includes('project-list-status')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  const result = await http.post('/project/release-project', {
    projectId: row._id,
  })

  if (result.status == 0) {
    message({
      showClose: true,
      message: '发布成功',
      type: 'success',
    })
    reqProjectList()
  } else {
    message({
      showClose: true,
      message: '发布失败,请先设置方案',
      type: 'error',
    })
  }
}
// 每一行的查看详情
const ShowDetails = async (row) => {
  //console.log('row :>> ', row);
  // 进入概览在vuex存值。头部导航监听变化并重新获取数据
  store.commit('Set_CreatedProject', row._id)
  store.commit('Set_ProjectId', row._id)
  store.commit('Set_ProjectNumber', row.number)
  ProjectDetailsShow.value = true
  store.commit('Set_ProjectTitle', 'projectDetails')
  store.commit('Set_ProjectDetails', row._id)
  setTimeout(() => {
    router.push({
      name: 'ProjectDetails',
      params: {
        _id: row._id,
      },
    })
  }, 100)
}
// 每一行的删除信息
// !删除先弹窗
// 取消
const isshowDerived_delect = () => {
  isShowDelect.value = !isShowDelect.value
}
// 删除
const oneDeleteVolun = async () => {
  //console.log(DelectData.value._id);
  try {
    const res = await http.delete('/project/delete-project', [DelectData.value._id])
    //console.log('删除结果 :>> ', res);
    reqProjectList()
    message({
      message: '删除成功',
      showClose: true,
      type: 'success',
    })
    // 清理勾选数组
    let index = checkedArr.value.indexOf(DelectData.value._id)
    //console.log(index, '已存在id的下标');
    if (index != -1) {
      checkedArr.value.splice(index, 1)

      //console.log(checkedArr.value, '单个删除之后的数据');
    }
  } catch (error) {
    message({
      message: '删除失败',
      showClose: true,
      type: 'error',
    })
  }
  isShowDelect.value = !isShowDelect.value
}
// !点击删除项目
const DeleteProject = () => {
  if (!store.state.role_info.includes('project-list-delete')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  isShowDelect.value = !isShowDelect.value
  // DelectData.value = row;
}
//点击分享项目
const ShareProject = () => {
  if (!store.state.role_info.includes('project-list-share')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  isShareProject.value = true
}

//新建项目点击完成项目详情展示
const ProjectDetailsChange = () => {
  ProjectDetailsShow.value = true
  CreateProjectShow.value = false
}
const OperationBox = ref(null) //获取更多操作盒子的ref
const showIdOptions = ref(false) //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1)
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  //console.log('value :>> ', value);
  const table = xTable.value
  const FixedList = table.getColumns()
  //console.log('当前的列顺序为', FixedList);
  //console.log('FixedIndex :>> ', FixedIndex.value);

  FixedList.forEach((item, index) => {
    if (item.title == value) {
      // //console.log('value的位置在,index :>> ', value, '的位置在', index);
      FixedIndex.value = index
      //console.log('item :>> ', item, item.fixed);
      if (item.fixed) {
        CancelFixed.value = false
        // //console.log('有值 :>> ');
      } else {
        CancelFixed.value = true
        // //console.log('无值 :>> ');
      }
    }
  })
  //console.log('FixedIndex下标为 :>> ', FixedIndex.value);

  showIdOptions.value = false
  showIdOptions.value = true

  const Event = event

  OperationBox.value.style.left = event.clientX - 100 + 'px'
}
const handleButtonClick = (btn) => {
  //console.log('btn :>> ', btn);
}
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false
  //console.log('点击点击 :>> ');
}

// 重置表格
const ResetTable = () => {
  ClearCheck()
  demo1.filterName = ''
  EventSearch.value = false
  ;(page5.currentPage = 1), (page5.pageSize = 10), reqProjectList()
}
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params
  //console.log(columns); // 打印排序后的表头列数组对象
}
const isShowExport = () => {
  if (checkedArr.value.length >= 1) {
    null_reminder_export.value = false
    isExport.value = true
  } else {
    null_reminder_export.value = true
  }
}
//导出方法
const exportDataEvent = async () => {
  // const $table = xTable.value;
  // $table.exportData({ type: 'csv' })
  if (!store.state.role_info.includes('project-list-export')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  if (checkedArr.value.length != 0) {
    const res = await http.post('/upload/export-project', { projectList: checkedArr.value, sort: SORT.value })
    //console.log('res :>> ', res);
    if (res.status == 0) {
      xlsx.writeFileXLSX(res.xlsxData, `项目列表 ${dayjs().format('YYYYMMDD')}.xlsx`)
      message({
        message: '导出成功',
        showClose: true,
        type: 'success',
      })
    }
  } else {
    message({
      message: '请选择要导出的项目',
      showClose: true,
      type: 'error',
    })
  }
}

const updateSexFilter = async () => {
  const $table = xTable.value
  const column = $table.getColumnByField('sex')
  if (column) {
    // 修改筛选列表，并默认设置为选中状态
    await $table.setFilter(column, [
      { value: '1', label: '男' },
      { value: '0', label: '女', checked: true },
    ])
    // 修改条件之后，需要手动调用 updateData 处理表格数据
    await $table.updateData()
  }
}
// 固定列表
const toggleFixedColumn = (index, type) => {
  //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  // //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  // //console.log('固定信息2:', fxColumn);
  // //console.log('固定信息:', tableColumns);

  // //console.log('SetTableHeaderArr :>> ', SetTableHeaderArr.value);
  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
const toggleFixedColumns = (index, type) => {
  // //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  // //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}

const updateWidthColumn = async (index, value) => {
  const $table = xTable.value
  demo1.tableColumn[index].width = value
  // 更改了列属性，需要手动刷新列
  await nextTick()
  await $table.refreshColumn()
}
// 处理时间戳
const format_time = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD')
  }
})
const format_time1 = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD HH:mm:ss')
  }
})
// 项目状态的样式
const status_style = computed(() => (e) => {
  // //console.log(e);
  if (e === '未开始' || e === '未发布') {
    return 'status_style-1'
  } else if (e === '招募中') {
    return 'status_style-2'
  } else if (e === '进行中') {
    return 'status_style-3'
  } else if (e === '已完成') {
    return 'status_style-4'
  } else if (e === '已停止') {
    return 'status_style-5'
  } else {
    return ''
  }
})

//note 全局搜索
// const searchEvent1 = () => {
//   const filterName = XEUtils.toValueString(demo1.filterName).trim().toLowerCase();
//   if (filterName) {
//     const filterRE = new RegExp(filterName, 'gi');
//     const searchProps = ['name', 'number', 'director_name'];
//     const rest = demo1.tableData.filter((item) => searchProps.some((key) => XEUtils.toValueString(item[key]).toLowerCase().indexOf(filterName) > -1));
//     demo1.list = rest.map((row) => {
//       const item = Object.assign({}, row);
//       searchProps.forEach((key) => {
//         item[key] = XEUtils.toValueString(item[key]).replace(filterRE, (match) => `<span class="keyword-lighten">${match}</span>`);
//       });
//       return item;
//     });
//   } else {
//     demo1.list = demo1.tableData;
//   }
// //console.log('全局搜索里的 demolist :>> ', demo1.list);
// };
// 全局搜索//模糊搜索
const searchEvent2 = async () => {
  if (demo1.filterName != '') {
    if (EventSearch.value == false) {
      page5.currentPage = 1
    }
    EventSearch.value = true
    const res = await http.post('/project/find-project', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
    })
    //console.log('全局搜索的返回结果res :>> ', res);
    demo1.tableData = res.result
    page5.totalResult = res.totalCount
  }
}
//  HACK  共用组件
onMounted(() => {
  // store.commit("Set_ProjectTitle", "")
  reqProjectList()
  getUserId()
  CurrentDate.value = Date()
  //console.log('当前时间是:>> ', CurrentDate.value);
  demo1.tableData = allData.slice(0, page5.pageSize)
  //console.log('demo1.tableData :>> ', demo1.tableData);

  //处理点击更多操作盒子 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false
      }
    }
  })
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false
      }
    }
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false
      }
      //console.log(ClickMoreOptions.value);
    }
  })
  store.commit('Set_CurrentProjectName', '项目列表')
})
// tip tootip提示方法
const tooltipConfig = reactive({
  showAll: true,
  enterable: true,
  contentMethod: ({ type, column, row, items, _columnIndex }) => {
    const { field } = column
    // //console.log('type,column,row,items,_columnIndex :>> ', column);
    // column 判断 column.title 是否为'操作'
    // 重写默认的提示内容
    if (column.title == '操作') {
      return (tooltipConfig.showAll = false)
    } else if (field === 'rate') {
      // 返回空字符串，控制单元格不显示提示内容
      return ''
    } else {
      tooltipConfig.showAll = true
    }
    // 其余的单元格使用默认行为

    return null
  },
})
const userNameArr = ref('')
const getUserId = async () => {
  const res = await http.post('/user/findOptions-user')
  //console.log('用户信息·', res.userOptions);
  userNameArr.value = res.userOptions
  //console.log(userNameArr.value);
}
const shareSearch = async () => {
  //console.log(DelectData.value);
  //console.log(localStorage.getItem('_id'));
  //console.log(DelectData.value._id);

  if (shareInput.value != '') {
    const res = await http.post('/user/shareProject-user', {
      shareUserId: localStorage.getItem('_id'),
      projectId: DelectData.value._id,
      shareWithUserEmail: shareInput.value,
    })
    //console.log(res);
    if (res.status == 0) {
      shareInput.value = ''
      isShareProject.value = false
      message({
        message: '分享成功',
        showClose: true,
        type: 'success',
      })
    } else {
      shareInput.value = ''
      message({
        message: '分享失败',
        showClose: true,
        type: 'error',
      })
    }
  } else {
    message({
      message: '请输入用户账号或姓名',
      showClose: true,
      type: 'error',
    })
  }
}
//note 组件销毁
onBeforeUnmount(() => {
  const table = xTable.value
  //console.log('组件销毁时的数值 :>> ', table);
  const FixedList = table.getColumns()
  // //console.log('销毁时的列顺序', FixedList);
  for (let i = 1; i < FixedList.length; i++) {
    for (let j = 0; j < SetTableHeaderArr.value.length; j++) {
      if (FixedList[i].field == SetTableHeaderArr.value[j].field) {
        SetTableHeaderArr.value[j].fixed = FixedList[i].fixed
      }
    }
  }

  localStorage.setItem('ShowHiddenArr', ase.encrypt(SetTableHeaderArr.value))

  localStorage.setItem('ProductDrag', ase.encrypt(dragDemo.value))
})
</script>
<style lang="less" scoped>
@import url('@/style/views/ProjectManagement/ProjectList.less');

.itxst {
  width: 600px;
  display: flex;
}

.itxst > div:nth-of-type(1) {
  flex: 1;
}

.itxst > div:nth-of-type(2) {
  width: 270px;
  padding-left: 20px;
}

.item {
  // border: solid 1px #eee;
  padding: 6px 10px;
  text-align: left;
}

.item:hover {
  cursor: move;
}

.item + .item {
  // margin-top: 10px;
}

.ghost {
  // 跟随鼠标的样式
  border: solid 1px #027aff !important;
  background: #fff !important;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.chosenClass {
  // 鼠标拉动的样式。
  background-color: #fff;
  opacity: 1;
  border: solid 1px #027aff;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.fallbackClass {
  background-color: aquamarine;
}
</style>
