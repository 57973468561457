<template>
  <div
    class="Nav"
    v-if="
      route.name != 'CreatedQuestionnaire' &&
      route.name != 'TextImport' &&
      route.name != 'ShareQuestion' &&
      route.name != 'H5QuestionPreview' &&
      route.name != 'WebQuestionPreview' &&
      route.name != 'EditQuestion'
    ">
    <el-row class="tac">
      <el-col :span="12">
        <!-- <h5 class="mb-2">CEMS系统</h5> -->
        <div class="System">
          <img class="icon" src="https://newstore.vynior.com/cems_web/4061700717842_.pic.jpg" alt="" />
        </div>
        <el-menu ref="menu" router class="el-menu-vertical-demo" :default-active="RouterPath" @open="handleOpen" @close="handleClose">
          <el-menu-item index="/Home" @click="ClickItem('主页')" calss="Home" v-if="store.state.role_info.includes('home')">
            <template #title>
              <i class="icon-home"></i>
              <span>主页</span>
            </template>
          </el-menu-item>
          <!-- <el-sub-menu index="1" v-if="!store.state.role_info.includes('project')"> -->
          <el-sub-menu index="1" v-if="store.state.role_info.includes('project')">
            <template #title>
              <i class="icon-project"></i>

              <span>项目管理</span>
            </template>
            <el-menu-item v-if="store.state.role_info.includes('project-data')" index="/ProjectManagement_DataBoard" @click="ClickItem('数据看板')"
              >数据看板</el-menu-item
            >

            <el-menu-item index="/ProjectList" v-if="store.state.role_info.includes('project-list')" @click="ClickItem('项目列表')">
              项目列表</el-menu-item
            >
            <el-menu-item index="/Project_sharing" v-if="store.state.role_info.includes('project-share')" @click="ClickItem('项目分享')">
              项目分享</el-menu-item
            >
            <el-menu-item index="/ProjectSchedule" v-if="store.state.role_info.includes('project-scheduling')" @click="ClickItem('项目排期表')"
              >项目排期表</el-menu-item
            >
            <el-menu-item index="/ReportApproval" v-if="store.state.role_info.includes('project-examine')" @click="ClickItem('报告审批')">
              报告审批</el-menu-item
            >
            <!-- <el-menu-item index="/Report_management" v-if="store.state.role_info.includes('project-manage')" @click="ClickItem('报告管理')"
              >报告管理</el-menu-item
            > -->
          </el-sub-menu>
          <el-sub-menu index="/VolunteerBank" v-if="store.state.role_info.includes('volunteer')">
            <template #title>
              <i class="icon-volunteer"></i>
              <span>志愿者管理</span>
            </template>
            <el-menu-item index="/VolunteerDataBoard" v-if="store.state.role_info.includes('volunteer-data')" @click="ClickItem('数据看板')"
              >数据看板</el-menu-item
            >

            <el-menu-item index="/VolunteerBank" v-if="store.state.role_info.includes('volunteer-bank')" @click="ClickItem('志愿者库')"
              >志愿者库</el-menu-item
            >
            <el-menu-item index="/RecruitVolunteer" v-if="store.state.role_info.includes('volunteer-manage')" @click="ClickItem('志愿者招募管理')"
              >志愿者招募管理</el-menu-item
            >
            <el-menu-item index="/VolunteerSchedule" v-if="store.state.role_info.includes('volunteer-scheduling')" @click="ClickItem('志愿者排期表')"
              >志愿者排期表</el-menu-item
            >
            <el-menu-item index="/TestRecords" v-if="store.state.role_info.includes('volunteer-testRecords')" @click="ClickItem('测试记录')"
              >测试记录</el-menu-item
            >
            <el-menu-item index="/ClockinView" v-if="store.state.role_info.includes('volunteer-clockRecords')" @click="ClickItem('打卡记录')"
              >打卡记录</el-menu-item
            >
            <el-menu-item index="/AppAdmin" v-if="store.state.role_info.includes('volunteer-appManage')" @click="ClickItem('小程序管理')"
              >小程序管理</el-menu-item
            >
            <el-menu-item
              index="/volunteer_Custom_Settings"
              v-if="store.state.role_info.includes('volunteer-customSettings')"
              @click="ClickItem('自定义设置')"
              >自定义设置</el-menu-item
            >
          </el-sub-menu>
          <el-sub-menu index="3" v-if="store.state.role_info.includes('instrument')">
            <template #title>
              <i class="icon-instrument"></i>
              <span>仪器管理</span>
            </template>
            <el-menu-item index="/InstrumentDataBoard" v-if="store.state.role_info.includes('instrument-data')" @click="ClickItem('数据看板')"
              >数据看板</el-menu-item
            >
            <el-menu-item index="/InstrumentType" @click="ClickItem('仪器类型')">仪器类型</el-menu-item>
            <el-menu-item index="/InstrumentList" v-if="store.state.role_info.includes('instrument-list')" @click="ClickItem('仪器列表')"
              >仪器列表</el-menu-item
            >
            <el-menu-item
              index="/InstrumentTestRecord"
              v-if="store.state.role_info.includes('instrument-testRecords')"
              @click="ClickItem('仪器测试记录')"
              >仪器测试记录</el-menu-item
            >
            <!-- <el-menu-item
              index="/InstrumentStatisticalAnalysis"
              v-if="store.state.role_info.includes('instrument-dataStatistics')"
              @click="ClickItem('数据统计&分析')"
              >数据统计&分析</el-menu-item
            > -->
            <el-menu-item
              index="/Lnstrument_Custom_Settings"
              v-if="store.state.role_info.includes('instrument-customSettings')"
              @click="ClickItem('自定义设置')"
              >自定义设置</el-menu-item
            >
          </el-sub-menu>
          <el-sub-menu index="4" v-if="store.state.role_info.includes('question')">
            <template #title>
              <i class="icon-questionnaire"></i>
              <span>问卷管理</span>
            </template>

            <!-- <el-menu-item index="4-1">问卷数据看板</el-menu-item> -->
            <el-menu-item index="/QuestionnaireList" v-if="store.state.role_info.includes('question-list')" @click="ClickItem('问卷列表')"
              >问卷列表</el-menu-item
            >
            <!-- <el-menu-item index="4-3">问卷模板库</el-menu-item> -->
            <!-- <el-menu-item index="4-4">自定义设置</el-menu-item> -->
          </el-sub-menu>
          <el-sub-menu index="5" v-if="store.state.role_info.includes('sample')">
            <template #title>
              <i class="icon-sample"></i>
              <span>样品管理</span>
            </template>

            <el-menu-item index="/SampleList" v-if="store.state.role_info.includes('sample-list')" @click="ClickItem('样品列表')"
              >样品列表</el-menu-item
            >
            <el-menu-item index="/SampleFlowRecord" v-if="store.state.role_info.includes('sample-flowRecords')" @click="ClickItem('样品流转记录')"
              >样品流转记录</el-menu-item
            >
            <!-- <el-menu-item index="/CustomSettings"  @click="ClickItem('自定义设置')">自定义设置</el-menu-item> -->
            <!-- <el-menu-item index="5-2">样品流转记录</el-menu-item> -->
            <el-menu-item
              index="/Sample_Custom_Settings"
              @click="ClickItem('自定义设置')"
              v-if="store.state.role_info.includes('sample-customSettings')"
              >自定义设置</el-menu-item
            >
          </el-sub-menu>
          <el-sub-menu index="6" v-if="store.state.role_info.includes('expert')">
            <template #title>
              <i class="icon-expert"></i>

              <span>专家管理</span>
            </template>

            <el-menu-item index="/ExpertList" v-if="store.state.role_info.includes('expert-list')" @click="ClickItem('专家列表')"
              >专家列表</el-menu-item
            >
            <!-- <el-menu-item
              index="ExpertEvaluationRecords"
              v-if="store.state.role_info.includes('expert_evaluationRecord')"
              @click="ClickItem('专家评估记录')"
              >专家评估记录</el-menu-item
            > -->
            <el-menu-item index="/CustomSettings" v-if="store.state.role_info.includes('expert-customSettings')" @click="ClickItem('自定义设置')"
              >自定义设置</el-menu-item
            >
            <!-- <el-menu-item index="6-1">item one</el-menu-item>
            <el-menu-item index="6-2">item two</el-menu-item> -->
          </el-sub-menu>
          <el-sub-menu index="7" v-if="store.state.role_info.includes('system')">
            <template #title>
              <i class="icon-system"></i>

              <span>系统管理</span>
            </template>
            <el-menu-item index="/personalCenter" v-if="store.state.role_info.includes('system-personalCenter')" @click="ClickItem('个人中心')"
              >个人中心</el-menu-item
            >
            <el-menu-item index="/UserPower" v-if="store.state.role_info.includes('system-user')" @click="ClickItem('用户管理')"
              >用户&权限管理</el-menu-item
            >
            <el-menu-item index="/SignatureManagement" v-if="store.state.role_info.includes('system-stamp')" @click="ClickItem('签章管理')"
              >签章管理</el-menu-item
            >
            <!-- <el-menu-item index="7-1">item one</el-menu-item>
            <el-menu-item index="7-2">item two</el-menu-item> -->
          </el-sub-menu>

          <el-menu-item index="/OperationLog" v-if="store.state.role_info.includes('log')" @click="ClickItem('操作日志')">
            <template #title>
              <i class="icon-homes"></i>
              <span>操作日志</span>
            </template>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { Document, Menu as IconMenu, Location, Setting } from '@element-plus/icons-vue'
import { defineComponent, ref, nextTick, watchEffect, computed, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'

//API接收
const store = useStore()
const router = useRouter()
const route = useRoute()

onMounted(() => {
  console.log(store.state.Project.CurrentProjectName, 'CurrentProjectName')
  console.log(localStorage.getItem('CurrentProjectName'), 'CurrentProjectName')
  if (!store.state.Project.CurrentProjectName) {
    store.state.Project.CurrentProjectName = localStorage.getItem('CurrentProjectName')
  }
  // console.log(store.state.role_info, 'role_inforole_info');
})
// const RouterPath = ref(store.state.user.CurrentPath);
const RouterPath = computed(() => {
  const route = router.currentRoute.value
  const { meta, path } = route
  // 在路由配置文件中设置自定义的路由路径到meta.activeMenu属性中，来控制菜单自定义高亮显示
  // meta中 有activeMenu 字段的子页面，都会显示高亮
  // console.log('当前路径是>>>>>sum', meta);

  if (meta.activeMenu) {
    // console.log('当前路径是>>>>>1', meta.activeMenu);
    return meta.activeMenu
  }
  // console.log('当前路径是>>>>>2', path);

  return path
})

//   return {
//     onRoutes,
//   };
// }),
// console.log('object :>> ', router.currentRoute.value.params.uid);
const ClickItem = (value) => {
  console.log('event :>> ', value)
  store.commit('Set_CurrentProjectName', value)
  localStorage.setItem('CurrentProjectName', value)
  switch (value) {
    case '问卷列表':
      router.replace({ path: '/QuestionnaireList' })
      break
    case '新建项目':
      // router.push({ path: '/CreateProject' });
      break
    case '志愿者库':
      // router.push({ path: '/VolunteerBank' });
      break
    case '志愿者招募管理':
      // router.push({ path: '/RecruitVolunteer' });
      break
    case '主页':
      // router.push({ path: '/Home' });

      break
  }
}
const menuRef = ref(null)

const handleOpen = (key, keyPath) => {
  console.log(key, keyPath)
}
const handleClose = (key, keyPath) => {
  console.log(key, keyPath)
}
</script>
<style lang="less" scoped>
@import url(../style/components/NavBar.less);
</style>
