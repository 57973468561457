<template>
  <div id="RecruitVolunteer">
    <!-- 表格主体 -->
    <div class="VolunteerBox">
      <!-- 表内导航栏(筛选条件) -->
      <div class="project_main_state">
        <!-- 筛选status状态按钮 -->
        <div
          class="main_state_item"
          :class="index == ClickActive ? 'click-active' : ''"
          v-for="(item, index) in ProjectState"
          :key="index"
          @click="ClickActive = index">
          {{ item }}
        </div>
      </div>
      <!-- 表体 -->
      <div class="VolunteerTable">
        <div class="vxetable">
          <vxe-toolbar>
            <template #buttons>
              <span class="top_text_selsct">性别</span>
              <el-select v-model="demo1.gender" placeholder="不限性别" class="Gender">
                <el-option v-for="item in gender" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
              <span class="top_text_selsct">年龄</span>
              <el-select v-model="demo1.age" placeholder="所有年龄" class="Age">
                <el-option v-for="item in ageList" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
              <vxe-input
                class="Search"
                v-model="demo1.filterName"
                type="search"
                placeholder="请输入志愿者ID或姓名进行搜索"
                @Blur="searchEvent2"></vxe-input>
              <vxe-button class="Reset"><span class="reset-icon" @click="reset">重置</span></vxe-button>
              <div class="Select" @click="ischangeShow">
                <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110428%402x.png" alt="" />
              </div>

              <vxe-button class="Show" :class="SetShow ? 'ShowHiden' : ''"
                ><span @click.stop="SetShowFunction" class="show-icon">显示</span></vxe-button
              >
              <!-- 线上报名 -->
              <div class="Apply" v-show="store.state.Project.ProjectId == '' || Limit.Status == '未发布'">
                <img src="https://newstore.vynior.com/%E7%BB%84110130%402x.png" alt="" />
                <div class="Apply_text">线上报名</div>
              </div>
              <!-- 线上报名 -->
              <div class="Applys" @click="isOnlineregistration" v-show="store.state.Project.ProjectId != '' && Limit.Status != '未发布'">
                <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2077%402x.png" alt="" />
              </div>
              <div class="batch_operation" @click.stop="isshowOperation = !isshowOperation" :class="{ clicked_batch: isshowOperation }">
                <span class="text">批量操作</span>
                <div class="arrow" v-show="!isshowOperation">
                  <img src="https://newstore.vynior.com/%E4%B8%8B%E6%8B%89.png" alt="" />
                </div>
                <div class="arrow" v-show="isshowOperation">
                  <img src="https://newstore.vynior.com/cems_web/blue.png" alt="" />
                </div>
              </div>
              <!-- 批量操作下拉框 -->
              <div class="batch_operation_box" id="operationBox" v-if="isshowOperation">
                <div class="item_box">
                  <div class="downImg"></div>

                  <!-- <img src="https://newstore.vynior.com/%E5%88%87%E6%8D%A2%402x.png" alt="" /> -->
                  <div class="item_one_text" @click="ChangeStatus = true">切换状态</div>
                </div>
                <div class="item_box1" @click="isShowExport">
                  <div class="downImg"></div>
                  <!-- <img src="https://newstore.vynior.com/%E5%AF%BC%E5%87%BA%402x.png" alt="" /> -->
                  <div class="item_one_text">导出</div>
                </div>
                <div class="item_box2" @click="checkedArr.length != 0 ? (centerDialogVisible = true) : ErrorTip()">
                  <img src="https://newstore.vynior.com/%E5%88%A0%E9%99%A4%402x.png" alt="" />
                  <div class="item_two_text" style="margin-top: 0px">删除</div>
                </div>
              </div>
            </template>
          </vxe-toolbar>
          <div class="tableOutSide" v-show="demo1.tableData.length != 0">
            <vxe-table
              border
              stripe
              ref="xTable"
              height="auto"
              auto-resize
              show-overflow
              row-id="_id"
              :data="demo1.tableData"
              :column-config="{ resizable: true, useKey: 'id' }"
              :tooltip-config="tooltipConfig"
              :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
              :checkbox-config="{ reserve: true }"
              :loading="demo1.loading"
              @header-cell-dragend="handleHeaderDragend"
              @checkbox-all="selectAllChangeEvent1"
              @checkbox-change="selectChangeEvent1">
              <vxe-column field="checked" type="checkbox" width="41" min-width="40" :fixed="demo1.colFixeds.col0"></vxe-column>
              <vxe-column
                v-for="(item, index) in allDatas"
                :key="index"
                :title="item.lable"
                :field="item.field"
                type="html"
                :width="item.width"
                min-height="36"
                :fixed="item.fixed"
                :min-width="item.min_width">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === item.lable && column.title != '操作'">
                    <span class="name">{{ item.lable }}</span>

                    <span v-if="item.lable != '操作'"
                      ><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item.lable, $event)"></i
                    ></span>
                  </div>
                  <div class="Operation-header" v-if="column.title === '操作'">
                    <span class="name names">操作</span>
                    <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                  </div>
                </template>
                <!-- 单个数据设置 -->
                <template #default="{ row }">
                  <div class="rows" v-if="item.lable == '操作'">
                    <el-tooltip class="box-item" effect="dark" content="查看详情" placement="top">
                      <vxe-button
                        class="detail"
                        @click="ShowDetails(row)"
                        :class="row.status == '拒绝' || row.status == '已完成' || row.status == '已退出' ? 'Empty' : ''"
                        >查看详情</vxe-button
                      >
                    </el-tooltip>
                    <template v-if="row.status == '已初审'">
                      <el-tooltip class="box-item" effect="dark" content="通过复审" placement="top">
                        <!-- <vxe-button class="copy" @click="SinglePass(row)">通过复审</vxe-button> -->
                        <vxe-button class="copy" @click="Changestatus('通过复审', row)">通过复审</vxe-button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="拒绝" placement="top">
                        <!-- <vxe-button class="delete" @click="SingleRefuse(row)">拒绝</vxe-button> -->
                        <vxe-button class="delete" @click="Changestatus('拒绝', row)">拒绝</vxe-button>
                      </el-tooltip>
                    </template>
                    <template v-if="row.status == '测试中'">
                      <el-tooltip class="box-item" effect="dark" content="测试完成" placement="top">
                        <!-- <vxe-button class="copy" @click="SingleComplete(row)">测试完成</vxe-button> -->
                        <vxe-button class="copy" @click="Changestatus('测试完成', row)">测试完成</vxe-button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="中途退出" placement="top">
                        <!-- <vxe-button class="delete" @click="SingleExit(row)">中途退出</vxe-button> -->
                        <vxe-button class="delete" @click="Changestatus1('中途退出', row)">中途退出</vxe-button>
                      </el-tooltip>
                    </template>
                    <template v-if="row.status == '已报名'">
                      <el-tooltip class="box-item" effect="dark" content="通过初审" placement="top">
                        <!-- <vxe-button class="copy" @click="SingleFirst(row)">通过初审</vxe-button> -->
                        <vxe-button class="copy" @click="Changestatus('通过初审', row)">通过初审</vxe-button>
                      </el-tooltip>
                      <el-tooltip class="box-item" effect="dark" content="拒绝" placement="top">
                        <!-- <vxe-button class="delete" @click="SingleRefuse(row)">拒绝</vxe-button> -->
                        <vxe-button class="delete" @click="Changestatus('拒绝', row)">拒绝</vxe-button>
                      </el-tooltip>
                    </template>
                    <!-- todo -->
                    <span class="More" id="CMoreOption" @click="ClickMethods(row, $event)">···</span>
                  </div>
                  <div class="rows" v-else-if="item.lable == '首次注册时间'">
                    {{ formatterDatas(row.volunteer.createdAt === undefined ? '——' : dayjs(row.volunteer.createdAt).format('YYYY-MM-DD')) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '出生日期'">
                    {{ formatterDatas(row.volunteer.volun_birthday === undefined ? '——' : dayjs(row.volunteer.volun_birthday).format('YYYY-MM-DD')) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '标签'">
                    {{ formatterDatas(JSON.stringify(row.volunteer.tags).replace(/"|\[|\]/g, '')) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '皮肤问题'">
                    {{ formatterDatas(JSON.stringify(row.volunteer.skin_issues).replace(/"|\[|\]/g, '')) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '使用过的产品类别'">
                    {{ formatterDatas(JSON.stringify(row.volunteer.use_products).replace(/"|\[|\]/g, '')) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '是否有基础疾病'">
                    {{ formatterDatas(JSON.stringify(row.volunteer.underlying_disease).replace(/"|\[|\]/g, '')) }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '是否抽烟'">
                    {{ row.volunteer.smoke == true ? '是' : '否' }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '是否饮酒'">
                    {{ row.volunteer.drink == true ? '是' : '否' }}
                  </div>
                  <div class="rows" v-else-if="item.lable == '志愿者状态'">
                    <div class="project-status">
                      <span
                        class="status_style"
                        :class="
                          row.status == '已初审'
                            ? 'blue'
                            : row.status == '已报名'
                            ? 'gray'
                            : row.status == '测试中'
                            ? 'yellow'
                            : row.status == '已完成'
                            ? 'green'
                            : row.status == '已退出'
                            ? 'brown'
                            : ''
                        "
                        >{{ row.status }}</span
                      >
                    </div>
                  </div>
                  <div class="rows" v-else>
                    {{ formatterDatas([item.field.split('.').reduce((obj, key) => obj[key], row)][0]) }}
                  </div>
                </template>
              </vxe-column>

              <template #empty>
                <span style="color: red">
                  <!-- <img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif" /> -->
                  <p>没有更多数据了！</p>
                </span>
              </template>
            </vxe-table>
          </div>

          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
                <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')" :class="!CancelFixed ? 'light' : ''">
                  <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" v-show="CancelFixed" />
                  <img src="https://newstore.vynior.com/cems_web/%E9%94%81%E5%AE%9A%402xs.png" alt="" v-show="!CancelFixed" />固定
                </li>
                <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" :class="CancelFixed ? 'light' : ''">
                  <img src="https://newstore.vynior.com/cems_web/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="!CancelFixed" />
                  <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="CancelFixed" />取消固定
                </li>

                <li id="MoreOptions" @click="HiddenColumn(FixedIndex)"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li>
              </ul>
            </div>
          </div>
          <!-- 批量志愿者信息  导出 -->
          <div class="Derived_volunteer" v-if="isExport">
            <div class="Derived_volunteer_box">
              <!-- 导出一个 -->
              <div v-if="checkedArr.length == 1">
                <div class="Derived_title">
                  确定导出<span class="Derived_name">{{
                    selectedRows[0].volunteer.volun_name == ' ' ? '无名字' : selectedRows[0].volunteer.volun_name
                  }}</span
                  >的志愿者招募信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="BatchExport">确认</div>
                </div>
              </div>
              <!-- 导出多个 -->
              <div v-if="checkedArr.length > 1">
                <div class="Derived_title">
                  已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>志愿者招募信息，确认导出吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isExport = false">取消</div>
                  <div class="ok" @click="BatchExport">确认</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 导出一条没有的时候 -->
          <div class="Derived_volunteer_null" v-if="null_reminder_export">
            <!-- 导出 -->
            <div class="Derived_volunteer_box">
              <div class="Derived_title">
                <div class="Derived_name">提示</div>
                <div><img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
              </div>
              <div class="Derived_body">
                <div><img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" /></div>
                <div class="Derived_body_text">请选择需要导出的志愿者招募信息！</div>
              </div>
            </div>
          </div>
          <!-- todo -->
          <!-- 每一行的 ... -->
          <div class="Options" id="CMoreOption" ref="CMoreOptions" v-show="ClickMoreOptions">
            <div class="Copy" @click="showOneExport">导出</div>
            <div class="Delete" @click="DeleteVolun(OptionsData)">删除</div>

            <!-- <vxe-button class="detail" @click="ExportData(row)">导出</vxe-button>

                <vxe-button class="detail" @click="DeleteVolun(row)">删除</vxe-button> -->
          </div>
          <!-- 导出一个 -->
          <div class="Derived_volunteer" v-if="isshowOneExport">
            <div class="Derived_volunteer_box" v-if="isshowOneExport">
              <div v-if="isshowOneExport">
                <div class="Derived_title">
                  确定导出<span class="Derived_name">{{ name }}</span
                  >的志愿者信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isshowOneExport = false">取消</div>
                  <div class="ok" @click="ExportData">确认</div>
                </div>
              </div>
            </div>
          </div>
          <!-- 空项目 -->
          <div class="EmptyProject" v-show="demo1.tableData.length == 0">
            <span>
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110334%402x.png" />
              <p>暂无数据</p>
            </span>
          </div>
          <!-- 分页 -->
          <p>
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                  >条/共{{ page5.totalResult != '' ? page5.totalResult : 0 }}条
                </div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
    </div>
    <!-- ! 高级搜索 -->
    <div class="Select_box" v-if="changeShow">
      <div class="Select_main">
        <!-- 头部退出 -->
        <div class="Select_top">
          <div class="Select_titel">高级搜索</div>
          <div><img @click="changeShow = !changeShow" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" /></div>
        </div>
        <!-- 中间表单 -->
        <div class="Select_body">
          <!-- 个人基础信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">个人基础信息</div>
            </div>
            <el-form>
              <el-form-item :model="Volunteers">
                <el-form-item id="add" label="志愿者ID" class="box1">
                  <el-input v-model="Volunteers._id" placeholder="请输入志愿者ID" />
                </el-form-item>
                <el-form-item label="姓名">
                  <el-input v-model="Volunteers.volun_name" placeholder="请输入姓名" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="性别">
                  <el-select v-model="Volunteers.volun_gender" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.volun_gender" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="身份证号">
                  <el-input v-model="Volunteers.idNumber" placeholder="请输入身份证号" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="联系电话">
                  <el-input v-model="Volunteers.volun_phone" placeholder="请输入联系电话" />
                </el-form-item>
                <el-form-item label="推荐人">
                  <el-input v-model="Volunteers.referee" placeholder="请输入推荐人" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="出生日期" class="birthday">
                  <el-date-picker
                    v-model="Volunteers.volun_birthday"
                    type="date"
                    placeholder="请选择"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    :size="size" />

                  <!-- <el-select v-model="Volunteers.volun_birthday" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.date_birth" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
                <el-form-item label="年龄">
                  <el-input v-model="Volunteers.volun_age" placeholder="请输入年龄" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="所在地址">
                  <!-- <el-select v-model="Volunteers.address" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.address" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                  <el-cascader
                    class="l_cascader"
                    v-model="Volunteers.address"
                    :options="cityData"
                    @change="handleChanges"
                    :suffix-icon="CaretBottom" />
                </el-form-item>
                <el-form-item label="月收入范围">
                  <el-select v-model="Volunteers.monthly_income" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.salary" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="婚育史">
                  <el-select v-model="Volunteers.marital_history" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.marry" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="是否三期">
                  <el-select v-model="Volunteers.three_phase" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.three_times" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="标签" class="Tags">
                  <el-select
                    v-model="Volunteers.tags"
                    class="l_select"
                    placeholder="请选择"
                    size="large"
                    multiple
                    collapse-tags
                    collapse-tags-tooltip>
                    <el-option v-for="item in Totalinitialdata.tag" :key="item" :label="item" :value="item" />
                  </el-select>
                  <!-- <el-select v-model="Volunteers.tags" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.tag" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
                <el-form-item label="备注">
                  <el-input v-model="Volunteers.remark" placeholder="请输入备注" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="志愿者状态" class="Tags">
                  <el-select v-model="Volunteers.status" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.volun_status" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <!-- 系统信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">系统信息</div>
            </div>
            <el-form>
              <el-form-item :model="Volunteers">
                <el-form-item label="首次注册时间" class="fistLogin">
                  <el-date-picker v-model="Volunteers.createdAt" type="date" placeholder="请选择" :size="size" />

                  <!-- <el-select v-model="Volunteers.createdAt" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.sign" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
                <el-form-item label="历史报名项目数量">
                  <!-- <el-select v-model="Volunteers.reg_total" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.historical_project" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                  <el-input v-model="Volunteers.reg_total" class="m-2" placeholder="请输入历史报名项目数量" size="large" />
                  <!-- <el-option v-for="item in Totalinitialdata.historical_project" :key="item.value" :label="item.label" :value="item.value" /> -->
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="历史完成项目数量">
                  <!-- <el-select v-model="Volunteers.project_total" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.finish_project" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                  <el-input v-model="Volunteers.project_total" class="m-2" placeholder="请输入历史完成项目数量" size="large" />
                  <!-- <el-option v-for="item in Totalinitialdata.finish_project" :key="item.value" :label="item.label" :value="item.value" /> -->
                </el-form-item>
                <el-form-item label="正在参与项目数量">
                  <!-- <el-select v-model="Volunteers.parting_total" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.doing_project" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                  <el-input v-model="Volunteers.parting_total" class="m-2" placeholder="请输入正在参与项目数量" size="large" />
                  <!-- <el-option v-for="item in Totalinitialdata.doing_project" :key="item.value" :label="item.label" :value="item.value" /> -->
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <!-- 皮肤信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">皮肤信息</div>
            </div>
            <el-form>
              <el-form-item :model="Volunteers">
                <el-form-item label="皮肤肤质">
                  <el-select v-model="Volunteers.skin_texture" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.skin_type" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="皮肤类型">
                  <el-select v-model="Volunteers.skin_type" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.type" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="皮肤问题">
                  <el-select placeholder="请选择" v-model="Volunteers.skin_issues" multiple collapse-tags collapse-tags-tooltip>
                    <el-option v-for="item in Totalinitialdata.skin_problem" :key="item" :label="item" :value="item" />
                  </el-select>
                  <!-- <el-select v-model="Volunteers.skin_issues" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.skin_problem" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
                <el-form-item label="皮肤肤色">
                  <el-select v-model="Volunteers.skin_color" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.skin_color" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="使用过的产品类别">
                  <el-select
                    @change="change_category_val"
                    placeholder="请选择"
                    v-model="Volunteers.use_products"
                    multiple
                    collapse-tags
                    collapse-tags-tooltip>
                    <el-option v-for="item in Totalinitialdata.product_type" :key="item" :label="item" :value="item" />
                  </el-select>
                  <!-- <el-select v-model="Volunteers.use_products" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.product_type" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <!-- 健康信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">健康信息</div>
            </div>
            <el-form>
              <el-form-item :model="Volunteers">
                <el-form-item label="是否抽烟">
                  <el-select v-model="Volunteers.smoke" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.smoking" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="是否饮酒">
                  <el-select v-model="Volunteers.drink" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.drink" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="Volunteers">
                <el-form-item label="身体健康情况">
                  <el-select
                    placeholder="请选择"
                    @change="change_basic_illness"
                    v-model="Volunteers.underlying_disease"
                    multiple
                    collapse-tags
                    collapse-tags-tooltip>
                    <el-option v-for="item in Totalinitialdata.fitness" :key="item" :label="item" :value="item" />
                  </el-select>
                  <!-- <el-select v-model="Volunteers.underlying_disease" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.fitness" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select> -->
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 底部按钮 -->
        <div class="Select_footer">
          <div class="null" @click="unSelect"><img src="https://new.vynior.com/%E7%BB%84%E4%BB%B6879%402x.png" alt="" /></div>
          <div class="onselect" @click="reqAllSelectData"><img src="https://new.vynior.com/%E7%BB%84%E4%BB%B6869%402x.png" alt="" /></div>
        </div>
      </div>
    </div>
    <!-- !表头字段设置 -->
    <div class="SetTableHeader" v-if="SetTableHeader">
      <div class="main">
        <div class="Header">
          <div class="text">
            表头字段设置<img @click="CancelShowHiden" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402x.png" alt="" />
          </div>
        </div>
        <!-- 设置表头的数据盒子 -->
        <div class="SetBox">
          <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
          <div class="LeftBox">
            <div class="text-header">志愿者库预设字段</div>
            <div class="ProjectRowField">
              <div class="text-header1">【个人基本信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetRecruitVolunteerArr.slice(0, 16)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                </div>
              </div>
            </div>
            <!-- 【系统信息】 -->
            <div class="ProjectRecruitMSG">
              <div class="text-header2">【系统信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetRecruitVolunteerArr.slice(16, 20)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 16)" />
                </div>
              </div>
            </div>

            <!-- 【皮肤信息】 -->
            <div class="SkinMsg">
              <div class="text-header3">【皮肤信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetRecruitVolunteerArr.slice(20, 25)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 20)" />
                </div>
              </div>
            </div>
            <!-- 【健康信息】 -->
            <div class="HealthMsg">
              <div class="text-header4">【健康信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetRecruitVolunteerArr.slice(25, 28)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 25)" />
                </div>
              </div>
            </div>
            <!-- 【收款信息】 -->
            <div class="PayeeMsg">
              <div class="text-header5">【收款信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetRecruitVolunteerArr.slice(28, 31)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 28)" />
                </div>
              </div>
            </div>
            <!-- 【项目基本信息】 -->
            <div class="ProjectMsg">
              <div class="text-header6">【项目基本信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetRecruitVolunteerArr.slice(31, 35)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 31)" />
                </div>
              </div>
            </div>
            <div class="Self-Defined">自定义扩展字段</div>
          </div>
          <!-- 右边盒子 拖动 -->
          <div class="RightBox">
            <div class="text-header">列表页展示字段</div>
            <!-- 调整数据 -->
            <div class="switchBox">
              <div id="xxx" class="demo">
                <draggable
                  :list="dragDemo"
                  ghost-class="ghost"
                  :force-fallback="true"
                  chosen-class="chosenClass"
                  animation="300"
                  handle=".move"
                  delay="100"
                  @end="onEnd">
                  <template #item="{ element }">
                    <div class="item move">
                      <div class="drag"></div>
                      {{ element.lable }}
                      <!-- -->
                      <img
                        class="top"
                        @click="backTop(element, 'top')"
                        src=" https://newstore.vynior.com/cems_web/%E7%BB%841112%402x.png   "
                        alt="" />
                      <img
                        class="delete"
                        @click="backTop(element, 'delete')"
                        :src="
                          defaultHeader.indexOf(element.lable) != -1
                            ? 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88s%402x.png'
                            : 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88221%402x.png'
                        "
                        alt="" />
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="Bottom">
          <div class="add">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
          </div>
          <div class="recover" @click="RecoverData">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
          </div>
          <div class="cancel" @click="CancelShowHiden">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" />
          </div>
          <div class="save" @click="ShowHidenColumn">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <el-dialog class="volunteer_dialog" v-model="dialogShow" title="Shipping address">
      <div class="dialog_title">
        <span>导入志愿者</span>
        <div class="title_cancel" @click="dialogShow = false"></div>
      </div>
      <div class="dialog_main">
        <div class="main_download">
          <div class="download_title">1.下载模板，填写志愿者信息</div>
          <div class="download_img"></div>
          <div class="upload_name"></div>
          <div class="upload_err"></div>
          <div class="download_but">
            <button>下载模板</button>
          </div>
        </div>
        <div class="main_upload">
          <div class="upload_title">2.上传填写好的文件</div>
          <div class="upload_img"></div>
          <div class="upload_name">
            <span v-if="uploadFileName">{{ uploadFileName }}123</span>
          </div>
          <div class="upload_tip">
            <span v-if="uploadFileErr">文件格式错误，请重新上传</span>
            <span class="tip_up" v-if="uploadFileName && !uploadFileErr">重新选择</span>
          </div>
          <div class="upload_but">
            <el-upload
              class="upload_xlsx"
              ref="videoRef"
              action="https://cems.fuhuan.ltd/api/volunteer/upload-user"
              :multiple="false"
              :show-file-list="false"
              :http-request="uploadFile"
              :auto-upload="false"
              :on-success="uploadFileSuccess">
              <button>上传文件</button>
            </el-upload>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 线上报名 -->
    <el-drawer v-model="Onlineregistration" title="I am the title" :with-header="false" size="57%">
      <div class="drawer">
        <div class="drawer_top">
          <div class="drawer_top_text">线上报名</div>
          <!-- v-if="oneVolunteerdata.length==0" -->
          <el-select
            v-if="!Register"
            v-model="selectID"
            filterable
            clearable
            remote
            reserve-keyword
            @change="search_one_data"
            placeholder="搜索志愿者身份证号码"
            :remote-method="remoteMethod">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <div class="SearchIcon">
            <img src="https://newstore.vynior.com/cems_web/%E6%90%9C%E7%B4%A2%402x.png" alt="" />
          </div>
        </div>
        <!-- 初始状态 -->
        <div class="drawer_body_initial" v-if="remark">
          <img src="https://newstore.vynior.com/121211.png" alt="" />
          <p>先去搜索志愿者~</p>
        </div>
        <!--  搜索不到信息  -->
        <div class="drawer_body_none" v-if="oneVolunteerdata.length == 0 && remark == false && !Register">
          <div class="drawer_body_none_text">不存在该志愿者信息，去<span class="new_project" @click="GoCreate">新建</span></div>
        </div>
        <!--  存在的志愿者 -->
        <div class="drawer_body" v-if="oneVolunteerdata != '' && !Register">
          <!-- 个人基础信息  查看状态 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">个人基础信息</div>
            </div>
            <div class="item_main_box">
              <div class="item1">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">志愿者ID</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata._id }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>姓名</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.volun_name }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>性别</div>
                      <div class="item_main_text_value">
                        {{ oneVolunteerdata.volun_gender }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>身份证号</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.idNumber }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>联系电话</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.volun_phone }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>推荐人</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.referee }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">出生日期</div>
                      <div class="item_main_text_value">
                        {{ oneVolunteerdata.volun_birthday ? oneVolunteerdata.volun_birthday.slice(0, 10) : '' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">年龄</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.volun_age }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">所在地址</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.address }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">月收入范围</div>
                      <div class="item_main_text_value">
                        {{ oneVolunteerdata.monthly_income }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">婚育史</div>
                      <div class="item_main_text_value">
                        {{ oneVolunteerdata.marital_history }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否三期</div>
                      <div class="item_main_text_value">
                        {{ oneVolunteerdata.three_phase }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">标签</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.tags.join(',') }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">备注</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.remark }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 系统信息 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">系统信息</div>
            </div>
            <div class="item_main_box">
              <div class="item2" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">首次注册时间</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.createdAt ? oneVolunteerdata.createdAt.slice(0, 10) : '' }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">历史报名项目数量</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.reg_total }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item2">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">历史完成项目数量</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.project_total }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">正在参与项目数量</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.test_total }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 历史完成项目 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">历史完成项目</div>
            </div>
            <div class="History_item_table" v-if="finish_projects.length != 0">
              <el-table border :data="finish_projects" stripe style="width: 100%">
                <el-table-column type="index" label="序号" align="center" width="60" />
                <el-table-column prop="project.number" label="项目编号" width="250" />
                <el-table-column prop="project.name" label="项目名称" width="190" />
                <el-table-column prop="testArea" label="测试场所" width="225" />
                <el-table-column prop="project.projectStart_time" label="项目开始日期" width="145">
                  <template #default="scope">
                    {{ dayjs(scope.row.project.projectStart_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
                <el-table-column prop="project.projectEnd_time" label="项目结束日期" width="145">
                  <template #default="scope">
                    {{ dayjs(scope.row.project.projectEnd_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="EmptyData" v-if="finish_projects.length == 0">该志愿者无历史完成项目</div>
          </div>
          <!-- 正在参与项目 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">正在参与项目</div>
            </div>
            <div class="History_item_table" v-if="attend_projects.length != 0">
              <el-table stripe border :data="attend_projects" style="width: 100%" class="T-Header">
                <el-table-column type="index" label="序号" align="center" width="60" />
                <el-table-column prop="number" label="项目编号" width="252" />
                <el-table-column prop="name" label="项目名称" width="196" />
                <el-table-column prop="testArea" label="测试场所" width="144" />
                <el-table-column prop="status" label="项目状态" width="88" />
                <el-table-column prop="projectStart_time" label="项目开始日期" width="140" />
                <el-table-column prop="projectStart_time" label="项目结束日期" width="140" />
              </el-table>
            </div>
            <div class="EmptyData" v-if="attend_projects.length == 0">该志愿者无正在参与项目</div>
          </div>
          <!-- 皮肤信息  查看状态 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">皮肤信息</div>
            </div>
            <div class="item_main_box">
              <div class="item1" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>皮肤肤质</div>
                      <div class="item_main_text_value" id="ClearSelect">
                        <!-- {{ oneVolunteerdata.skin_texture }} -->

                        <el-select v-model="oneVolunteerdata.skin_texture" placeholder="请选择" size="large">
                          <el-option v-for="item in Supplement.SkinTypeValue" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤类型</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.skin_type }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤问题</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.skin_issues.join(',') }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤肤色</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.skin_color }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 健康信息 查看状态 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">健康信息</div>
            </div>
            <div class="item_main_box">
              <div class="item2" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否抽烟</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.smoke == true ? '是' : '否' }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否饮酒</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.drink == true ? '是' : '否' }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item2">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否有基础疾病</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.underlying_disease == true ? '是' : '否' }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 收款信息 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">收款信息</div>
            </div>
            <div class="item_main_box">
              <div class="item" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款户名</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.account_name }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款账号</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.account_number }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款银行</div>
                      <div class="item_main_text_value">{{ oneVolunteerdata.bank }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!--  -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">报名项目信息补充</div>
            </div>
            <div class="item_main_box visitData">
              <div class="item" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>到访时间</div>
                      <div class="item_main_text_value" id="ClearSelect">
                        <el-select v-model="Supplement.VisitDate" placeholder="请选择" size="large">
                          <el-option v-for="item in Supplement.OperationValue" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <!-- bug -->
        <!-- 抽屉底部 -->
        <div class="drawer_footer" v-if="!remark && oneVolunteerdata.length != 0">
          <div class="null"><img @click="isOnlineregistration" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" /></div>
          <div class="next"><img @click="Createvolunteers" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B68629%402x.png" alt="" /></div>
        </div>
      </div>
    </el-drawer>

    <!-- 切换状态 -->
    <div class="ChangeStatus" v-if="ChangeStatus">
      <div class="ChangeStatusBox">
        <div class="Title">
          批量切换状态 <img @click="ChangeStatus = false" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402x.png" />
        </div>
        <div class="tips">
          已选中 <span class="num">{{ checkedArr.length }}条</span>状态为未开始的志愿者信息，进行批量操作：
        </div>
        <div class="SelectStatus">
          <el-select v-model="change_status_values" placeholder="请选择">
            <el-option label="通过初审" value="0" />
            <el-option label="通过复审" value="1" />
            <el-option label="测试完成" value="2" />
            <el-option label="中途退出" value="3" />
            <el-option label="拒绝" value="4" />
          </el-select>
        </div>

        <div class="StatusButtons">
          <button class="Cancel" @click="ChangeStatus = false">取消</button>
          <button class="Comfirm" @click="ComfirmStatus">完成</button>
        </div>
      </div>
    </div>
    <!-- 抽屉志愿者详情 -->
    <el-drawer v-model="Volunteerdetails" :close-on-click-modal="true" title="I am the title" :with-header="false" size="57%">
      <div class="drawer">
        <div class="deawer_title">
          <div class="deawer_title_text">志愿者信息</div>
          <div>
            <!-- <img @click="isVolunteereditor" v-if="Volunteereditor" src="https://newstore.vynior.com/%E7%BC%96%E8%BE%91.png" alt="" /> -->
          </div>
        </div>
        <div class="drawer_body">
          <!-- 个人基础信息  查看状态 -->
          <div class="information_box" v-if="Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">个人基础信息</div>
            </div>
            <div class="item_main_box">
              <div class="item1">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">志愿者ID</div>
                      <div class="item_main_text_value">{{ information._id }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>姓名</div>
                      <div class="item_main_text_value">{{ information.volunteer.volun_name }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>性别</div>
                      <div class="item_main_text_value">
                        {{ information.volunteer.volun_gender }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>身份证号</div>
                      <div class="item_main_text_value">{{ information.volunteer.idNumber }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>联系电话</div>
                      <div class="item_main_text_value">{{ information.volunteer.volun_phone }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>推荐人</div>
                      <div class="item_main_text_value">{{ information.volunteer.referee }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">出生日期</div>
                      <div class="item_main_text_value">{{ dayjs(information.volunteer.volun_birthday).format('YYYY-MM-DD') }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">年龄</div>
                      <div class="item_main_text_value">{{ information.volunteer.volun_age }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">所在地址</div>
                      <div class="item_main_text_value">{{ information.volunteer.address }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">月收入范围</div>
                      <div class="item_main_text_value">
                        {{ information.volunteer.monthly_income }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">婚育史</div>
                      <div class="item_main_text_value">
                        {{ information.volunteer.marital_history }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否三期</div>
                      <div class="item_main_text_value">
                        {{ information.volunteer.three_phase }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">标签</div>
                      <div class="item_main_text_value">
                        <div v-if="information.volunteer.tags.length !== 0" class="itemBox">
                          <span v-for="(item, index) in information.volunteer.tags" :key="item">
                            {{ item }}{{ index === information.volunteer.tags.length - 1 ? ' ' : ' 、' }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">备注</div>
                      <div class="item_main_text_value">{{ information.volunteer.remark }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>

          <!-- 系统信息 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">系统信息</div>
            </div>
            <div class="item_main_box">
              <div class="item2" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">首次注册时间</div>
                      <div class="item_main_text_value">{{ dayjs(information.createdAt).format('YYYY-MM-DD HH:mm:ss') }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">历史报名项目数量</div>
                      <div class="item_main_text_value">{{ information.volunteer.reg_total }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item2">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">历史完成项目数量</div>
                      <div class="item_main_text_value">{{ information.volunteer.project_total }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">正在参与项目数量</div>
                      <div class="item_main_text_value">{{ information.volunteer.parting_total }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <!-- 历史完成项目 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">历史完成项目</div>
            </div>
            <div class="History_item_table">
              <el-table border :data="finish_projects" v-if="finish_projects.length != 0" stripe style="width: 100%" class="T-Header">
                <el-table-column type="index" label="序号" align="center" width="60" />
                <el-table-column prop="number" label="项目编号" width="250" />
                <el-table-column prop="name" label="项目名称" width="190" />
                <el-table-column prop="testAddress" label="测试场所" width="225" />
                <el-table-column prop="projectStart_time" label="项目开始日期" width="145">
                  <template #default="scope">
                    {{ dayjs(scope.row.projectStart_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
                <el-table-column prop="projectEnd_time" label="项目结束日期" width="145">
                  <template #default="scope">
                    {{ dayjs(scope.row.projectEnd_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="EmptyData" v-if="finish_projects.length == 0">该志愿者无历史完成项目</div>
          </div>
          <!-- 正在参与项目 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">正在参与项目</div>
            </div>
            <div class="History_item_table" v-if="attend_projects.length != 0">
              <el-table stripe border :data="attend_projects" style="width: 100%" class="T-Header">
                <el-table-column type="index" label="序号" align="center" width="60" />
                <el-table-column prop="number" label="项目编号" width="252" />
                <el-table-column prop="name" label="项目名称" width="196" />
                <el-table-column prop="testAddress" label="测试场所" width="144" />
                <el-table-column prop="status" label="项目状态" width="88" />
                <el-table-column prop="projectStart_time" label="项目开始日期" width="140">
                  <template #default="scope">
                    {{ dayjs(scope.row.projectStart_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
                <el-table-column prop="projectEnd_time" label="项目结束日期" width="140">
                  <template #default="scope">
                    {{ dayjs(scope.row.projectEnd_time).format('YYYY-MM-DD') }}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="EmptyData" v-if="attend_projects.length == 0">该志愿者无正在参与项目</div>
          </div>
          <!-- 皮肤信息  查看状态 -->
          <div class="information_box" v-if="Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">皮肤信息</div>
            </div>
            <div class="item_main_box">
              <div class="item1" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤肤质</div>
                      <div class="item_main_text_value">{{ information.volunteer.skin_texture }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤类型</div>
                      <div class="item_main_text_value">{{ information.volunteer.skin_type }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤问题</div>
                      <div class="item_main_text_value">
                        <span v-for="(item, index) in information.volunteer.skin_issues" :key="index"
                          >{{ item }}{{ index === information.skin_issues.length - 1 ? ' ' : ' 、' }}</span
                        >
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">皮肤肤色</div>
                      <div class="item_main_text_value">{{ information.volunteer.skin_color }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>

          <!-- 健康信息 查看状态 -->
          <div class="information_box" v-if="Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">健康信息</div>
            </div>
            <div class="item_main_box">
              <div class="item2" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否抽烟</div>
                      <div class="item_main_text_value">
                        {{ information.volunteer.smoke == true ? '是' : '否' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否饮酒</div>
                      <div class="item_main_text_value">
                        {{ information.volunteer.drink == true ? '是' : '否' }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item2">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">身体健康情况</div>
                      <div class="item_main_text_value">
                        <span v-for="(item, index) in information.volunteer.underlying_disease" :key="item"
                          >{{ item }}{{ index === information.volunteer.underlying_disease.length - 1 ? ' ' : ' 、' }}</span
                        >
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>

          <!-- 收款信息 -->
          <div class="information_box" v-if="Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">收款信息</div>
            </div>
            <div class="item_main_box">
              <div class="item" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款户名</div>
                      <div class="item_main_text_value">{{ information.volunteer.account_name }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款账号</div>
                      <div class="item_main_text_value">{{ information.volunteer.account_number }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">收款银行</div>
                      <div class="item_main_text_value">{{ information.volunteer.bank }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>

          <!-- 文件 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">文件</div>
            </div>
            <div class="files" v-if="Volunteereditor">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%208111%402x.png" alt="" />
            </div>
            <!-- <div class="file"><img src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%E7%AE%AD%E5%A4%B4%402x.png" alt="" />上传附件</div> -->
          </div>
        </div>
        <!-- 抽屉底部 -->
        <!-- <div class="drawer_footer">
          <div class="null"><img @click="ShowDetails" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" /></div>
          <div class="next"><img @click="updatedata" src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" /></div>
        </div> -->
      </div>
    </el-drawer>
    <!-- 显示功能弹窗 -->
    <div class="SetShowsBox" ref="ShowBox" v-show="SetShow" id="SetShowsBoxs">
      <div class="Titles" id="SetShowsBoxs">字段显示</div>
      <div class="ContentBox" :class="AllHidenHeader.length == 0 ? 'Emptys' : ''" id="SetShowsBoxs">
        <span class="Empty" v-if="AllHidenHeader.length == 0" id="SetShowsBoxs">暂无隐藏字段</span>
        <div class="ListBox" v-if="AllHidenHeader.length != 0" id="SetShowsBoxs">
          <div class="List" v-for="(item, index) in AllHidenHeader" :key="index" @click="ShowCheck[index] = !ShowCheck[index]" id="SetShowsBoxs">
            <div class="item" id="SetShowsBoxs">{{ item.title }}</div>

            <div class="Active" v-show="ShowCheck[index]" id="SetShowsBoxs">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110500%402x.png" alt="" />
            </div>
          </div>
          <div
            class="Button"
            @click="ConfirmRecover"
            id="SetShowsBoxs"
            :class="AllHidenHeader.length == 1 ? 'Buttons' : AllHidenHeader.length == 2 ? 'Buttonss' : ''">
            确认
          </div>
        </div>
      </div>
    </div>
    <!-- 批量删除弹窗 -->
    <div class="DeleteConfit">
      <el-dialog v-model="centerDialogVisible" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass">
              已选中 <span class="num">&nbsp;{{ checkedArr.length }}条&nbsp;</span>志愿者信息，确认删除吗？
            </div>
          </div>
        </template>
        <div class="Body">
          <div class="content"><img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />删除后将无法恢复</div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="Cancel" @click="centerDialogVisible = false">取消</el-button>
            <el-button class="Delete" @click="DelteProject"> 删除 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 单个删除弹窗 -->
    <div class="DeleteConfit">
      <el-dialog v-model="DeleteName.Status" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass">
              确认删除 <span class="num NameLength">&nbsp;{{ DeleteName.Name }}&nbsp;</span>志愿者信息吗？
            </div>
          </div>
        </template>
        <div class="Body">
          <div class="content"><img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />删除后将无法恢复</div>
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="Cancel" @click="DeleteName.Status = false">取消</el-button>
            <el-button class="Delete" @click="SingleDelete(DeleteName.ID)"> 删除 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>

    <!-- 切换状态弹窗 -->
    <div class="ChangeS">
      <el-dialog v-model="ChangeStatusData.Show" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass">
              确认<span v-if="ChangeStatusData.Status == '拒绝'">{{ ChangeStatusData.Status }}</span
              ><span class="num">&nbsp;{{ ChangeStatusData.Name }}&nbsp;</span>
              <span v-if="ChangeStatusData.Status != '拒绝' && ChangeStatusData.Status != '中途退出'"></span>
              <span v-if="ChangeStatusData.Status != '拒绝'">{{ ChangeStatusData.Status }}</span
              >吗?
            </div>
          </div>
        </template>
        <div class="Body"><img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />切换后无法撤回，可在志愿者状态栏进行查看</div>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="Cancel" @click="ChangeStatusData.Show = false">取消</el-button>
            <el-button class="Delete" @click="SingleFirst(ChangeStatusData.row)" v-if="ChangeStatusData.Status == '通过初审'"> 确认 </el-button>
            <el-button class="Delete" @click="SinglePass(ChangeStatusData.row)" v-if="ChangeStatusData.Status == '通过复审'"> 确认 </el-button>

            <el-button class="Delete" @click="SingleExit(ChangeStatusData.row)" v-if="ChangeStatusData.Status == '中途退出'"> 确认 </el-button>

            <el-button class="Delete" @click="SingleRefuse(ChangeStatusData.row)" v-if="ChangeStatusData.Status == '拒绝'"> 确认 </el-button>
            <el-button class="Delete" @click="SingleComplete(ChangeStatusData.row)" v-if="ChangeStatusData.Status == '测试完成'"> 确认 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 中途退出弹窗 -->
    <div class="ChangeS1" v-if="isShowQuit">
      <el-dialog v-model="isShowQuit" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass">
              确认<span>{{ quitPerson }}</span
              >退出吗？
            </div>
          </div>
        </template>
        <div class="Body">
          <div class="text">
            <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />
            切换后无法撤回，可在志愿者状态栏进行查看
          </div>
          <div class="quitReason">
            退出原因
            <el-input v-model="quitReason" placeholder="请输入退出原因，如回访没时间、违反项目要求" />

            <!-- <el-input v-model="quitReason" placeholder="请输入退出原因，如回访没时间、违反项目要求" /> -->
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <div class="Cancel" @click="isShowQuit = false">取消</div>
            <div class="Delete" @click="SingleExit(ChangeStatusData.row)">确认</div>
          </span>
        </template>
      </el-dialog>
    </div>
    <CreatedVolunteer v-model="is_dialogShow" v-if="is_dialogShow" :ChangeTite="is_dialogShow"></CreatedVolunteer>
  </div>
</template>
<script setup>
import CreatedVolunteer from '@/components/VolunteerManagement/CreatedVolunteer.vue'
import Date from '@/utils/CurrentDate'
import http from '@/utils/http'
import { message } from '@/utils/resetMessage'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)
import { computed, nextTick, onBeforeUnmount, onMounted, provide, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import * as xlsx from 'xlsx'
import cityData from '@/utils/citydata'
import draggable from 'vuedraggable'
import ase from '@/utils/cryptoUtils'
const linewidth = ref(window.innerWidth)
const isExport = ref(false)
const null_reminder_export = ref(false)
// ! 关闭提醒弹窗
const Closereminder = () => {
  null_reminder_export.value = false
}
const handleResize = () => {
  linewidth.value = window.innerWidth
}

onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

// 导出的当前时间
let ExportDate = ref('')
// import { message } from '@/utils/resetMessage';
//API接收
const store = useStore()
const router = useRouter()
const xTable = ref()
// table组件数据
let totalCount = ref(0)
const SetTableHeader = ref(false)

// 补充信息
let Supplement = ref({
  OperationValue: [],
  VisitDate: '',
  SkinType: '',
  SkinTypeValue: [
    { value: '干性', label: '干性' },
    { value: '油性', label: '油性' },
    { value: '中性', label: '中性' },
    { value: '混合偏干', label: '混合偏干' },
    { value: '混合偏油', label: '混合偏油' },
    { value: '不确定', label: '不确定' },
    { value: '其他', label: '其他' },
  ],
})
const ClickActive = ref(0)
// 删除的名字
let DeleteName = ref({
  Status: false,
  Name: '',
  ID: '',
})

// 是否高级搜索
let HeightSearch = ref(false)
// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([])
//表头字段设置--->设置表头的勾选数据
let SetRecruitVolunteerArr = ref([
  { width: '136', min_width: '136', fixed: '', field: 'volunteer._id', checked: true, lable: '志愿者ID' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.volun_name', checked: true, lable: '姓名' },
  { width: '88', min_width: '88', fixed: '', field: 'volunteer.volun_gender', checked: true, lable: '性别' },
  { width: '148', min_width: '148', fixed: '', field: 'volunteer.idNumber', checked: true, lable: '身份证号' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.volun_phone', checked: true, lable: '联系电话' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.referee', checked: false, lable: '推荐人' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.volun_birthday', checked: true, lable: '出生日期' },
  { width: '88', min_width: '88', fixed: '', field: 'volunteer.volun_age', checked: true, lable: '年龄' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.address', checked: false, lable: '所在地址' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.monthly_income', checked: false, lable: '月收入范围' },
  { width: '100', min_width: '100', fixed: '', field: 'volunteer.marital_history', checked: false, lable: '婚育史' },
  { width: '100', min_width: '100', fixed: '', field: 'volunteer.three_phase', checked: false, lable: '是否三期' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.tags', checked: false, lable: '标签' },
  { width: '148', min_width: '148', fixed: '', field: 'remark', checked: false, lable: '备注' },
  { width: '160', min_width: '160', fixed: '', field: 'status', checked: true, lable: '志愿者状态' },
  { width: '330', min_width: '330', fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.createdAt', checked: false, lable: '首次注册时间' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.reg_total', checked: false, lable: '历史报名项目数量' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.project_total', checked: false, lable: '历史完成项目数量' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.parting_total', checked: false, lable: '正在参与项目数量' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.skin_texture', checked: false, lable: '皮肤肤质' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.skin_type', checked: false, lable: '皮肤类型' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.skin_issues', checked: false, lable: '皮肤问题' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.skin_color', checked: false, lable: '皮肤肤色' },
  { width: '220', min_width: '220', fixed: '', field: 'volunteer.use_products', checked: false, lable: '使用过的产品类别' },
  { width: '100', min_width: '100', fixed: '', field: 'volunteer.smoke', checked: false, lable: '是否抽烟' },
  { width: '100', min_width: '100', fixed: '', field: 'volunteer.drink', checked: false, lable: '是否饮酒' },
  { width: '220', min_width: '220', fixed: '', field: 'volunteer.underlying_disease', checked: false, lable: '是否有基础疾病' },
  { width: '148', min_width: '148', fixed: '', field: 'volunteer.account_name', checked: false, lable: '收款户名' },
  { width: '148', min_width: '148', fixed: '', field: 'volunteer.account_number', checked: false, lable: '收款账号' },
  { width: '148', min_width: '148', fixed: '', field: 'volunteer.bank', checked: false, lable: '收款银行' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.testAddress', checked: false, lable: '测试场所' },
  { width: '200', min_width: '200', fixed: '', field: 'project.number', checked: true, lable: '项目编号' },
  { width: '224', min_width: '224', fixed: '', field: 'project.name', checked: true, lable: '项目名称' },
  { width: '176', min_width: '176', fixed: '', field: 'project.status', checked: true, lable: '项目状态' },
])
let defaultHeader = ref(['志愿者ID', '姓名', '志愿者状态', '操作', '项目编号', '项目名称', '项目状态'])

// 表头字段设置--->恢复默认字段
let DefaultHeaderData = ref([
  { width: '136', min_width: '136', fixed: '', field: 'volunteer._id', checked: true, lable: '志愿者ID' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.volun_name', checked: true, lable: '姓名' },
  { width: '88', min_width: '88', fixed: '', field: 'volunteer.volun_gender', checked: false, lable: '性别' },
  { width: '148', min_width: '148', fixed: '', field: 'volunteer.idNumber', checked: false, lable: '身份证号' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.volun_phone', checked: false, lable: '联系电话' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.referee', checked: false, lable: '推荐人' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.volun_birthday', checked: false, lable: '出生日期' },
  { width: '88', min_width: '88', fixed: '', field: 'volunteer.volun_age', checked: false, lable: '年龄' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.address', checked: false, lable: '所在地址' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.monthly_income', checked: false, lable: '月收入范围' },
  { width: '100', min_width: '100', fixed: '', field: 'volunteer.marital_history', checked: false, lable: '婚育史' },
  { width: '100', min_width: '100', fixed: '', field: 'volunteer.three_phase', checked: false, lable: '是否三期' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.tags', checked: false, lable: '标签' },
  { width: '148', min_width: '148', fixed: '', field: 'remark', checked: false, lable: '备注' },
  { width: '160', min_width: '160', fixed: '', field: 'status', checked: true, lable: '志愿者状态' },
  { width: '330', min_width: '330', fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  { width: '136', min_width: '136', fixed: '', field: 'volunteer.createdAt', checked: false, lable: '首次注册时间' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.reg_total', checked: false, lable: '历史报名项目数量' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.project_total', checked: false, lable: '历史完成项目数量' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.parting_total', checked: false, lable: '正在参与项目数量' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.skin_texture', checked: false, lable: '皮肤肤质' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.skin_type', checked: false, lable: '皮肤类型' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.skin_issues', checked: false, lable: '皮肤问题' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.skin_color', checked: false, lable: '皮肤肤色' },
  { width: '220', min_width: '220', fixed: '', field: 'volunteer.use_products', checked: false, lable: '使用过的产品类别' },
  { width: '100', min_width: '100', fixed: '', field: 'volunteer.smoke', checked: false, lable: '是否抽烟' },
  { width: '100', min_width: '100', fixed: '', field: 'volunteer.drink', checked: false, lable: '是否饮酒' },
  { width: '220', min_width: '220', fixed: '', field: 'volunteer.underlying_disease', checked: false, lable: '是否有基础疾病' },
  { width: '148', min_width: '148', fixed: '', field: 'volunteer.account_name', checked: false, lable: '收款户名' },
  { width: '148', min_width: '148', fixed: '', field: 'volunteer.account_number', checked: false, lable: '收款账号' },
  { width: '148', min_width: '148', fixed: '', field: 'volunteer.bank', checked: false, lable: '收款银行' },
  { width: '200', min_width: '200', fixed: '', field: 'volunteer.testAddress', checked: false, lable: '测试场所' },
  { width: '200', min_width: '200', fixed: '', field: 'project.number', checked: true, lable: '项目编号' },
  { width: '224', min_width: '224', fixed: '', field: 'project.name', checked: true, lable: '项目名称' },
  { width: '176', min_width: '176', fixed: '', field: 'project.status', checked: true, lable: '项目状态' },
])
// 切换状态true/false
const ChangeStatus = ref(false)
// 要切换的值是:
const change_status_values = ref('')
//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  loading: true,
  list: [],
  gender: '',
  age: '',
})
const gender = [
  {
    value: '1',
    label: '男',
  },
  {
    value: '2',
    label: '女',
  },
]
const ageList = [
  {
    value: '不限年龄',
    label: '不限年龄',
  },
  {
    value: '18~25',
    label: '18~25',
  },
  {
    value: '26~40',
    label: '26~40',
  },
  {
    value: '40~49',
    label: '40~49',
  },
  {
    value: '≥50',
    label: '≥50',
  },
]
let Volunteerdetails = ref(false) // !查看志愿者详情
// 删除弹窗
const centerDialogVisible = ref(false)
// sort排序字段
let SORT = ref({ createdAt: -1 })
// 查看详情的数据
let information = ref([])
let Volunteereditor = ref(false)
// ! 测试的表格数据
const tableData = [
  {
    date: '2',
    name: 'Tom',
    address: 'No. 189,',
  },
  {
    date: '2',
    name: 'Tom',
    address: 'No. 189, ',
  },
  {
    date: '2',
    name: 'Tom',
    address: 'No. 189,',
  },
  {
    date: '2',
    name: 'Tom',
    address: 'No. 189,',
  },
]

const Limit = ref({
  Age: [],
  Gender: '',
  Status: '',
})
// 模糊搜索 判断是否是第一次
const EventSearch = ref(false)
// 模糊Age 判断是否是第一次
const AgeSearch = ref(false)
// 模糊Gender 判断是否是第一次
const GenderSearch = ref(false)
// 查看详情里的长在参与项目
const attend_projects = ref([])
// 查看详情里的历史完成项目
const finish_projects = ref([])
// 高级搜索初始数据
const initialize = reactive(null)
// ! 显示按钮
let isShow = ref(false)
// ! 线上报名 身份证数据
let IDnumber = ref(null)
// 高级搜索数据存储
const Volunteers = reactive({
  volun_number: null, // 志愿者id
  volun_name: null, // 姓名
  volun_gender: null, // 性别
  idNumber: null, //身份证号
  volun_phone: null, // 联系电话
  referee: null, //推荐人
  volun_birthday: null, // 出生日期
  volun_age: null, //  年龄
  address: null, //地址
  monthly_income: null, //  月收入
  marital_history: null, // 结婚
  three_phase: null, //三期
  tags: null, //标签
  remark: null, //备注
  createdAt: null, //第一次注册时间
  reg_total: null, //历史项目
  project_total: null, //正在进行
  parting_total: null, // 正在参与项目
  skin_texture: null, // 肤质
  skin_type: null, //皮肤类型
  skin_issues: null, //皮肤问题
  skin_color: null, //肤色
  use_products: null, //使用过的产品
  smoke: null,
  drink: null,
  underlying_disease: null, //健康
  status: null,
})
const VolunteersOne = reactive({
  volun_number: '', // 志愿者id
  volun_name: '', // 姓名
  volun_gender: '', // 性别
  idNumber: '', //身份证号
  volun_phone: '', // 联系电话
  referee: '', //推荐人
  volun_birthday: '', // 出生日期
  volun_age: '', //  年龄
  address: '', //地址
  monthly_income: '', //  月收入
  marital_history: '', // 结婚
  three_phase: '', //三期
  tags: [], //标签
  remark: '', //备注
  createdAt: '', //第一次注册时间
  reg_total: '', //历史项目
  project_total: '', //正在进行
  parting_total: '', // 正在参与项目
  skin_texture: '', // 肤质
  skin_type: '', //皮肤类型
  skin_issues: [], //皮肤问题
  skin_color: '', //肤色
  use_products: [], //使用过的产品
  smoke: '',
  drink: '',
  underlying_disease: '', //健康
  account_name: '',
  account_number: '',
  bank: '',
  reservation_time: '2001-05-02',
})

let CancelFixed = ref(false) //控制每个表头下拉箭头 固定和取消固定的高亮

//显示功能的字段设置
let AllShowHeader = ref([])
// 全部显示里被隐藏的
let AllHidenHeader = ref([])
const SetShow = ref(false)
const ShowBox = ref(null) //获取Show盒子的ref
const ShowCheck = ref([])
// 每一行的 ... 点击
let ClickMoreOptions = ref(false)
const CMoreOptions = ref(null) //获取MoreOptions盒子的ref
let OptionsData = ref()
// !高级搜索数据
// 性别
const Totalinitialdata = {
  volun_status: [
    {
      value: '已报名',
      label: '已报名',
    },
    {
      value: '已初审',
      label: '已初审',
    },
    {
      value: '测试中',
      label: '测试中',
    },
    {
      value: '已退出',
      label: '已退出',
    },
    {
      value: '已完成',
      label: '已完成',
    },
  ],
  volun_gender: [
    {
      value: '男',
      label: '男',
    },
    {
      value: '女',
      label: '女',
    },
  ],
  date_birth: [
    {
      value: '1',
      label: '1',
    },
    {
      value: '1',
      label: '1',
    },
  ],
  address: [
    {
      value: '上海',
      label: '上海',
    },
    {
      value: '北京',
      label: '北京',
    },
  ],
  salary: [
    {
      value: '5千以下',
      label: '5千以下',
    },
    {
      value: '5千-1万',
      label: '5千-1万',
    },
    {
      value: '1万-5万',
      label: '1万-5万',
    },
    {
      value: '4',
      label: '5万以上',
    },
  ],
  marry: [
    {
      value: '未婚',
      label: '未婚',
    },
    {
      value: '已婚未育',
      label: '已婚未育',
    },
    {
      value: '已婚已育',
      label: '已婚已育',
    },
  ],
  three_times: [
    {
      value: '孕期',
      label: '孕期',
    },
    {
      value: '产期',
      label: '产期',
    },
    {
      value: '哺乳期',
      label: '哺乳期',
    },
  ],
  tag: ['依从性好', '依从性差', '爽约严重', '不正常使用产品', '打卡率高', '打卡率低', '工作日来访', '周末来访'],
  sign: [
    {
      value: '12.12',
      label: '12.12',
    },
    {
      value: '11.11',
      label: '11.11',
    },
  ],
  historical_project: [
    {
      value: '5',
      label: '5',
    },
    {
      value: '1',
      label: '1',
    },
  ],
  finish_project: [
    {
      value: '5',
      label: '5',
    },
    {
      value: '1',
      label: '1',
    },
  ],
  doing_project: [
    {
      value: '5',
      label: '5',
    },
    {
      value: '1',
      label: '1',
    },
  ],
  skin_type: [
    {
      value: '干性',
      label: '干性',
    },
    {
      value: '油性',
      label: '油性',
    },
    {
      value: '中性',
      label: '中性',
    },
    {
      value: '混合偏干',
      label: '混合偏干',
    },
    {
      value: '混合偏油',
      label: '混合偏油',
    },
    {
      value: '不确定',
      label: '不确定',
    },
    {
      value: '其他',
      label: '其他',
    },
  ],
  type: [
    {
      value: '敏感',
      label: '敏感',
    },
    {
      value: '非敏感',
      label: '非敏感',
    },
    {
      value: '不确定',
      label: '不确定',
    },
    {
      value: '其他',
      label: '其他',
    },
  ],
  skin_problem: [
    '毛孔粗大',
    '有炎性痘痘',
    '有闭口粉刺',
    '有色斑',
    '有痘印',
    '敏感泛红',
    '肤色暗沉',
    '有黑头',
    '黑眼圈',
    '眼袋',
    '皱纹细纹',
    '皮肤松弛',
    '其他',
  ],
  skin_color: [
    {
      value: '偏白',
      label: '偏白',
    },
    {
      value: '偏黄',
      label: '偏黄',
    },
    {
      value: '偏黑',
      label: '偏黑',
    },
    {
      value: '偏红',
      label: '偏红',
    },
    {
      value: '中等',
      label: '中等',
    },
  ],
  product_type: ['护肤品', '防晒霜', '粉底', '口红', '眼线笔', '睫毛膏', '脱毛仪', '都没有', '其他'],

  smoking: [
    {
      value: true,
      label: '是',
    },
    {
      value: false,
      label: '否',
    },
  ],
  drink: [
    {
      value: true,
      label: '是',
    },
    {
      value: false,
      label: '否',
    },
  ],
  fitness: [
    '高血压',
    '高血脂',
    '非胰岛素依赖性糖尿病',
    '胰岛素依赖性糖尿病',
    '免疫性疾病',
    '皮肤病史',
    '过敏史',
    '肝肾功能疾病',
    '心脑血管疾病',
    '哮喘患者',
    '其他呼吸系统疾病',
    '入院治疗史',
    '无任何以上提到的疾病',
  ],
}

// todo 切换状态
let ChangeStatusData = ref({
  Show: false,
  Name: '',
  Status: '',
  row: '',
})
// 监听 批量操作--->切换状态的状态值
watch(
  () => change_status_values,
  (newValue) => {
    console.log('最新的状态码', newValue)
  }
)
const Project = reactive({ status: '全部' })
//切换/未开始/招募中/进行中/已停止/全部项目
watch(
  () => ClickActive.value,
  async (newValue) => {
    if (newValue == 1) {
      Project.status = '已报名'
    } else if (newValue == 2) {
      Project.status = '已初审'
    } else if (newValue == 3) {
      Project.status = '测试中'
    } else if (newValue == 4) {
      Project.status = '已完成'
    } else if (newValue == 5) {
      Project.status = '已退出'
    } else if (newValue == 0) {
      Project.status = '全部'
    }

    console.log('Project--->', Project)
    const res = await http.post('/recruit/findRecord-recruit', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      Project: store.state.Project.ProjectId,

      status: Project.status,
    })
    // 清除勾选
    const $table = xTable.value
    $table.clearCheckboxRow()
    checkedArr.value.splice(0, checkedArr.value.length)
    // 清除条件筛选
    demo1.filterName = ''
    demo1.gender = ''
    demo1.age = ''

    if (res.status == 0) {
      demo1.tableData = res.recruit_form
      page5.totalResult = res.totalCount
      if (res.recruit_form.length == 0) {
        page5.totalResult = 0
      }
    }
    // console.log('res--->', res);
  }
)
// 监听gender
watch(
  () => demo1.gender,
  async (newvalue) => {
    console.dir(newvalue)
    if (newvalue != '') {
      if (GenderSearch.value == false) {
        page5.currentPage = 1
      }
      GenderSearch.value = true
      page5.currentPage = 1
      SearchData()
    }
  }
)
// // 监听age
watch(
  () => demo1.age,
  async (newvalue) => {
    console.dir(newvalue)
    if (AgeSearch.value == false) {
      page5.currentPage = 1
    }
    AgeSearch.value = true
    SearchData()
  }
)
// 监听项目id获取项目的第一次到访时间
watch(
  () => store.state.Project.ProjectId,
  (newValue) => {
    ClickActive.value = 0
    const $table = xTable.value
    $table.clearCheckboxRow()
    checkedArr.value.splice(0, checkedArr.value.length)
    ReqVisitDate()
    reqLimit()
  }
)
// 筛选表头数据
const SearchData = async () => {
  demo1.loading = true
  let gender = ''
  let age = 0
  // 获取性别
  if (demo1.gender == '1') {
    gender = '男'
  } else if (demo1.gender == '2') {
    gender = '女'
  } else {
    gender = '全部'
  }
  // 获取年龄
  switch (demo1.age) {
    case '不限年龄':
      age = 0
      break
    case '18~25':
      age = 2
      break
    case '26~40':
      age = 3
      break
    case '40~49':
      age = 4
      break
    case '≥50':
      age = 5
      break
  }

  const res = await http.post('/recruit/findRecord-recruit', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    Project: store.state.Project.ProjectId,
    Content: demo1.filterName,
    sex: gender,
    age: age,
  })

  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.recruit_form
    page5.totalResult = res.totalCount
  }
}
//tip新数据结构------------------：
// Vue.createApp(xxx).mount('#xxx');
const onEnd = () => {
  let $table = xTable.value
  // $table.refreshColumn();
}
let dragDemo = ref([])
let allDatas = ref([])
// 置顶/删除方法
const backTop = (e, val) => {
  console.log('val :>> ', e)
  let indexItem = -1
  dragDemo.value.forEach((item, index) => {
    if (item.lable == e.lable) {
      indexItem = index
    }
  })
  console.log('下标是： :>> ', indexItem)
  // 置顶方法
  if (val == 'top') {
    if (indexItem != 0) {
      dragDemo.value.unshift(dragDemo.value.splice(indexItem, 1)[0])
    }
  } else {
    // 不能删除的操作
    if (defaultHeader.value.indexOf(e.lable) != -1) {
      message({
        message: `${e.lable}不能删除`,
        showClose: true,
        type: 'error',
      })
    } else {
      // 可以删除的操作 同步删除左侧勾选；
      // 删除方法
      dragDemo.value.splice(indexItem, 1)
      const index = SetRecruitVolunteerArr.value.findIndex((item) => item.lable === e.lable)
      SetRecruitVolunteerArr.value[index].checked = false
    }
  }

  console.log(' dragDemo.value; :>> ', dragDemo.value)
}

// tip-------------------------
//todo--->全部数据
const reqProjectList = async (val) => {
  if (val == '批量删除') {
    page5.currentPage = 1
    checkedArr.value.splice(0, checkedArr.value.length)
  }
  const res = await http.post('/recruit/findRecord-recruit', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    Content: '',
    Project: store.state.Project.ProjectId,
    status: Project.status,
  })

  if (res.status == 0) {
    demo1.loading = false

    demo1.tableData = res.recruit_form
    page5.totalResult = res.totalCount
    if (res.recruit_form.length == 0) {
      page5.totalResult = 0
    }
    console.table('res :>> ', res)
  }
  ChangeStatus.value = false
}
// 监听项目id
watch(
  () => store.state.Project.ProjectId,
  (newValue) => {
    reqProjectList()
  }
)

// 新建志愿者
let is_dialogShow = ref(false)
// 设置新建志愿者组件显示隐藏
let CreatedVolunteerShow = ref(false)
// ! 控制批量操作的显示
let isshowOperation = ref(false)
// ! 导出志愿信息弹框
let isDerived = ref(false)
// ! 导出志愿信息弹框   失败
let isDerived_delect = ref(false)
// ! 线上报名
let Onlineregistration = ref(false)
// ! 下拉实时更新数据
let options = ref([])
//  !输入框数据
let selectID = ref([])
// ! 报名弹窗
let Register = ref(false)
// ! 搜索的数据
let remark = ref(true)
let oneVolunteerdata = ref([])
// !  实时搜索数据
const remoteMethod = (query) => {
  console.log(query, '--->')
  // console.log('刚开始的数据oneVolunteerdata', oneVolunteerdata.value.length);
  if (query) {
    setTimeout(async () => {
      const result = await http.post('/volunteer/getOptions-volunteer', {
        content: query,
      })

      if (result.options.length != 0) {
        console.log(result, '实时搜索返回数据')
        options.value = result.options
      } else {
        console.log('实时搜索没有返回数据')
        options.value = [
          {
            value: query,
            label: query,
          },
        ]
      }
    }, 200)
  } else {
    options.value = []
  }
}
// 监听是否创建页面关闭
watch(
  () => is_dialogShow,
  (newValue) => {
    console.log('创建页面是否关闭', newValue)
  }
)
// ! 点击数据进行查询
const search_one_data = async (query) => {
  remark.value = false
  let id = selectID.value

  console.log(id, 'id', query)
  try {
    const results = await http.post('/volunteer/findInfo-volunteer', {
      volunteerId: id,
    })
    console.log(results, 'results--results')
    if (results.status == 0) {
      oneVolunteerdata.value = results.volunteer
    }

    // console.log(oneVolunteerdata.value, 'oneVolunteerdata');
    // console.log(oneVolunteerdata.value[0]._id, 'oneVolunteerdata');
    // console.log('刚开始的数据oneVolunteerdata', oneVolunteerdata.value.length);
    // 参加项目数据请求
    console.log(oneVolunteerdata.value, 'oneVolunteerdata.value[0]._id')
    SingleVolun(oneVolunteerdata.value._id)
  } catch (error) {
    console.log(error)
  }
}
// ! 线上报名弹窗
const isOnlineregistration = () => {
  Onlineregistration.value = !Onlineregistration.value
  selectID.value = []
  remark.value = true
  oneVolunteerdata.value = []
  Register.value = false
}
// 点击新建志愿者
const CreatedVolunteerChange = () => {
  // CreatedVolunteerShow.value = !CreatedVolunteerShow.value;
  Onlineregistration.value = false

  //   //console.log('点击新建志愿者');
}
const GoCreate = () => {
  Onlineregistration.value = false
  is_dialogShow.value = true
}
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params
  //   //console.log(columns); // 打印排序后的表头列数组对象
}
// note 表内导航栏
const ProjectState = ref('')
ProjectState.value = ['全部', '已报名', '已初审', '测试中', '已完成', '已退出']
const changeShow = ref(false)
const reqAllData = async () => {
  const res = await http.post('/user/findAll-user', {})
  //   //console.log('全部返回的数据是:', res);
}
//!高级搜索
const reqAllSelectData = async () => {
  console.log(Volunteers)
  // ! 取消null的字段
  for (let prop in Volunteers) {
    if (Volunteers[prop] === null) {
      delete Volunteers[prop]
    }
  }
  console.log(Volunteers)
  console.log(store.state.Project.ProjectId, 'idididididid')
  // 为高级搜索
  HeightSearch.value = true
  // //   //console.log('Volunteers', Volunteers);
  if (Volunteers.volun_birthday) {
    Volunteers.volun_birthday = dayjs(Volunteers.volun_birthday).add(8, 'hours').toDate()
    // Volunteers.volun_birthday = dayjs(Volunteers.volun_birthday);
  }
  console.log('Volunteers.volun_birthday :>> ', Volunteers.volun_birthday)
  const res = await http.post('/recruit/advancedfind-recruit', {
    size: page5.pageSize,
    findParams: Volunteers,
    page: (page5.currentPage = 1),
    // sort: SORT.value,
    projectId: store.state.Project.ProjectId,
  })
  if (res.status == 0) {
    const $table = xTable.value
    $table.clearCheckboxRow()
    checkedArr.value.splice(0, checkedArr.value.length)
    console.log('全部返回的数据是:', res)
    demo1.tableData = res.recruit_form
    page5.totalResult = res.totalCount

    changeShow.value = false
  }
}
// !重置按钮
const reset = () => {
  reqProjectList()
  const $table = xTable.value
  $table.clearCheckboxRow()
  checkedArr.value.splice(0, checkedArr.value.length)
  demo1.age = ''
  demo1.gender = ''
  demo1.filterName = ''
  EventSearch.value = false
  GenderSearch.value = false
  AgeSearch.value = false
  HeightSearch.value = false //重置高级搜索状态
}
// ! 退出按钮
const unSelect = () => {
  changeShow.value = false
}
// !批量按钮显示
const showOperation = () => {
  isshowOperation.value = true
}
// ! 导出
const Export = () => {
  console.log(selectedRows)
  console.log(SetRecruitVolunteerArr.value)

  if (selectedRows.length !== 0) {
    console.log(SetRecruitVolunteerArr.value)
    isshowOperation.value = false
    isshowDerived()
  } else {
    ElMessage('一条都没有')
  }
  // 判断值，进行展示
}
// ! 删除
const delect = () => {
  if (selectedRows.length !== 0) {
    isshowOperation.value = false
    isshowDerived_delect()
  } else {
    ElMessage('一条都没有')
  }
}
// ! !导出志愿者信息弹窗  失败  确定
const isshowDerived_delect_true = async () => {
  // !删除对应数据，并刷新页面
  const ids = selectedRows.map((obj) => obj._id)
  // console.log(ids, 'idddddd'); // [1, 2, 3]
  try {
    const res = await http.delete('/volunteer/delete-user', {
      deleteData: ids,
    })
    isDerived_delect.value = !isDerived_delect.value
    reqProjectList()
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    })
  } catch (error) {
    message({
      message: '删除失败',
      grouping: true,
      type: 'error',
    })
  }
}
// !显示选择框
const showSelect = () => {
  isShow.value = !isShow.value
}
//创建志愿者
const Createvolunteers = async () => {
  console.log('-------', oneVolunteerdata.value.volun_gender, Limit.value.Gender)
  // 判断到访时间
  if (Supplement.value.VisitDate != '' && oneVolunteerdata.value.skin_texture != '') {
    // 判断志愿者年龄

    if (
      isCanJoinInByAge(Limit.value.Age, oneVolunteerdata.value.volun_age) &&
      (Limit.value.Gender == '不限' || oneVolunteerdata.value.volun_gender + '性' == Limit.value.Gender)
    ) {
      try {
        let res = await http.post('/recruit/webcreateRecord-recruit', {
          project: store.state.Project.ProjectId,
          volunteer: oneVolunteerdata.value._id,
          reservation_time: Supplement.value.VisitDate,
        })
        if (res.status == 0) {
          message({
            message: '线上报名成功',
            showClose: true,
            type: 'success',
          })
          Onlineregistration.value = !Onlineregistration.value

          reqProjectList()
        } else if (res.status == 2) {
          message({
            message: '该项目已招募完成',
            showClose: true,
            type: 'error',
          })
        } else if (res.status == 3) {
          message({
            message: '志愿者十四天内不能报名相同测试部位相同的项目',
            showClose: true,
            type: 'error',
          })
        } else {
          message({
            message: '创建失败,该志愿者已存在',
            showClose: true,
            type: 'error',
          })
        }
      } catch (error) {
        message({
          message: '创建失败',
          grouping: true,
          type: 'error',
        })
      }
    } else {
      message({
        type: 'warning',
        showClose: true,
        message: '报名失败,志愿者不符合年龄或者性别要求',
      })
    }
  } else {
    message({
      type: 'warning',
      showClose: true,
      message: '请填写到访时间和皮肤肤质',
    })
  }
}
//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
})

// 分页--->分页
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false
  //console.log('点击点击 :>> ');
}

//分页--->跳转页面显示数据处理
const pageChange = async () => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()

  //console.log('page,size :>> ', page, size);
  if (demo1.filterName == '' && demo1.age == '' && demo1.gender == '' && !HeightSearch.value) {
    const res = await http.post('/recruit/findRecord-recruit', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      content: '',
      Project: store.state.Project.ProjectId,
    })
    // //console.log('res :>> ', res);
    page5.totalResult = res.totalCount
    demo1.tableData = res.recruit_form
  } else if (HeightSearch.value) {
    reqAllSelectData()
  } else {
    SearchData()
  }
}

//导出弹窗显示隐藏
let dialogShow = ref(false)
//导出方法
const exportDataEvent = () => {
  // const $table = xTable.value;
  // $table.exportData({ type: 'csv' });
  console.log('123')

  dialogShow.value = true
}

//复选框数组
var selectedRows = reactive([])
const checkedArr = ref([])

// 复选框点击事件
const selectChangeEvent1 = (row) => {
  // console.log('点击的id:', row.rowid);
  console.log('row :>> ', row)

  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records

  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid)
  } else {
    let index = checkedArr.value.indexOf(row.rowid)
    checkedArr.value.splice(index, 1)
  }
  console.log('最新:', checkedArr.value)
}
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  console.log(checked ? '所有勾选事件' : '所有取消事件', records)
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id)
      }
    }
    console.log('最新:', checkedArr.value)
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = []

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id)
    })

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item)
    })
    console.log('最新:', checkedArr.value)
  }
}
// 模糊查询
const searchEvent2 = async () => {
  let gender = ''
  let age = 0
  if (EventSearch.value == false) {
    page5.currentPage = 1
  }
  EventSearch.value = true
  if (demo1.gender == '男') {
    gender = '男'
  } else if (demo1.gender == '女') {
    gender = '女'
  } else {
    gender = '全部'
  }
  switch (demo1.age) {
    case '不限年龄':
      age = 0
      break
    case '18~25':
      age = 2
      break
    case '26~40':
      age = 3
      break
    case '40~49':
      age = 4
      break
    case '≥50':
      age = 5
      break
  }
  if (demo1.filterName) {
    demo1.loading = true
    // if (gender != '') {
    const res = await http.post('/recruit/findRecord-recruit', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      Project: store.state.Project.ProjectId,
      Content: demo1.filterName,
      sex: gender,
      age: age,
      status: Project.status,
    })
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.recruit_form
      page5.totalResult = res.totalCount
      if (res.recruit_form.length == 0) {
        page5.totalResult = 0
      }
    }
    // } else {
    //   const res = await http.post('/Recruit_Form/find-regexRecruit_Forms', {
    //     page: page5.currentPage,
    //     size: page5.pageSize,
    //     sort: { createdAt: 1 },
    //     Project: store.state.Project.ProjectId,
    //     Content: demo1.filterName,
    //     sex: {
    //       // volun_gender: gender,
    //     },
    //     age: age,
    //   });
    //   if (res.status == 0) {
    //     demo1.loading = false;
    //     demo1.tableData = res.recruit_form;
    //     page5.totalResult = res.totalCount;
    //   }
    // }
  }
}
//  More -->下拉选择中的固定方法
const toggleFixedColumn = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
const toggleFixedColumns = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
// More -->升序/降序
const AscendingSort = async (index, type) => {
  let gender = ''
  let age = 0

  if (demo1.gender == '男') {
    gender = '男'
  } else if (demo1.gender == '女') {
    gender = '女'
  } else {
    gender = '全部'
  }
  switch (demo1.age) {
    case '不限年龄':
      age = 0
      break
    case '18~25':
      age = 2
      break
    case '26~40':
      age = 3
      break
    case '40~49':
      age = 4
      break
    case '≥50':
      age = 5
      break
  }
  // console.log('我的type: :>> ', type);
  demo1.loading = true

  // console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  // console.log('固定信息:', tableColumns);
  const ColumnId = tableColumns[index].field
  const sorts = {}
  if (type == 'Asc') {
    sorts[ColumnId] = 1
  } else {
    sorts[ColumnId] = -1
  }

  SORT.value = sorts
  const res = await http.post('/recruit/findRecord-recruit', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    Content: demo1.filterName,
    Project: store.state.Project.ProjectId,
    sex: gender,
    age: age,
  })
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.recruit_form
    page5.totalResult = res.totalCount

    console.table('res :>> ', res)
  }
}

// 获取当前的全部数据
const TransferData = () => {
  // 将全部显示的数据  放到 AllShowHeader中
  SetRecruitVolunteerArr.value.forEach((item, index) => {
    if (item.checked == true) {
      AllShowHeader.value.push(item)
    }
  })
  console.log('AllShowHeader.value :>> ', AllShowHeader.value)
}
// !恢复目前隐藏的列表----> 获取event 设置show显示框的位置
const SetShowFunction = (event) => {
  SetShow.value = false
  SetShow.value = true
  // console.log('event :>> ', event, ShowBox.value);
  ShowBox.value.style.left = event.clientX - 10 + 'px'
  ShowBox.value.style.top = event.clientY + 20 + 'px'
}
// ! 恢复目前隐藏的列表----> 确认按钮
const ConfirmRecover = () => {
  let TeShowCheck = []
  let TeAllHidenHeader = []
  const $table = xTable.value
  const column = $table.getColumns()
  console.log('ShowCheck.value :>> ', ShowCheck.value)
  for (let i = ShowCheck.value.length; i >= 0; i--) {
    if (ShowCheck.value[i] == true) {
      // // column[index].visible = false;
      // console.log('column :>> ', ShowCheck.value[i], AllHidenHeader.value[i]);

      // 显示列
      $table.showColumn(AllHidenHeader.value[i].field)
      // 同步删除 数据 隐藏列/checked
      AllHidenHeader.value.splice(i, 1)
      ShowCheck.value.splice(i, 1)
      // console.log('column :>> ', column, $table);
      // console.log('column :>> ', $table.showColumn());
      // console.log('column :>> ', ShowCheck.value, AllHidenHeader.value);
    }
  }
  SetShow.value = false
}
// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false
  const $table = xTable.value
  const column = $table.getColumns()
  // console.log('column[index] :>> ', column[index]);
  column[index].visible = false
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value.push(column[index])
  ShowCheck.value.push(false)
  $table.refreshColumn() //刷新列表
  console.log('ShowChcke.value :>> ', ShowCheck.value)
  // console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
}
// 每一行的查看详情
const ShowDetails = (row) => {
  console.log('row :>> ', row)
  SingleVolun(row.volunteer._id)

  Volunteerdetails.value = !Volunteerdetails.value
  Volunteereditor.value = true
  information.value = row
}
const isshowOneExport = ref(false)
const name = ref()
let exportOneID = ''
const showOneExport = (id) => {
  exportOneID = OptionsData.value._id
  name.value = OptionsData.value.project.name
  isshowOneExport.value = true
}
// 每一行的导出
const ExportData = async (row) => {
  console.log('row :>> ', row)
  isshowOneExport.value = false
  const res = await http.post('/upload/export-recruit', { recruitList: [exportOneID], sort: SORT.value })
  console.log('res :>> ', res)
  if (res.status == 0) {
    const name =
      store.state.Project.ProjectId === ''
        ? `志愿者招募信息 ${dayjs().format('YYYYMMDD')}.xlsx`
        : `志愿者招募信息 ${store.state.Project.ProjectNumber} ${dayjs().format('YYYYMMDD')}.xlsx`
    xlsx.writeFileXLSX(res.xlsxData, name)
    message({
      message: '操作成功',
      grouping: true,
      type: 'success',
    })
  }
}
// 查询单个志愿者的接口
const SingleVolun = async (val) => {
  const res = await http.post('/volunteer/findInfo-volunteer', {
    volunteerId: val,
  })
  console.log('搜索已有志愿者的请求结果', res)

  //finish_projects.value = res.volunteer.history_projects;
  //attend_projects.value = res.volunteer.parting_projects;

  // if (res.volunteer[0].testAddress != undefined) {
  //   attend_projects.value.forEach((item, index) => {
  //     item.testArea = res.volunteer[0].testAddress;
  //   });
  //   finish_projects.value.forEach((item, index) => {
  //     item.testArea = res.volunteer[0].testAddress;
  //   });
  // }

  console.log('最后的正在参加项目数据', attend_projects.value, finish_projects.value)
}
// 每一行的删除
const DeleteVolun = async (row) => {
  console.log('1111', row)

  DeleteName.value.Status = true
  DeleteName.value.Name = row.volunteer.volun_name
  DeleteName.value.ID = row._id
}
// 删除执行方法
const SingleDelete = async (val) => {
  demo1.loading = true
  // console.log('row :>> ', row);
  const res2 = await http.post('/recruit/deleteRecord-recruit', [val])
  if (res2.status == 0) {
    DeleteName.value.Status = false
    // 清理勾选数组
    let index = checkedArr.value.indexOf(val)
    console.log(index, '已存在id的下标')
    if (index != -1) {
      checkedArr.value.splice(index, 1)

      console.log(checkedArr.value, '单个删除之后的数据')
    }
    demo1.loading = false
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    })
    reqProjectList()
  }
}
// 点击表头的下拉箭头
const OperationBox = ref(null) //获取更多操作盒子的ref
const showIdOptions = ref(false) //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1)
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  //   //console.log('value :>> ', value);
  const table = xTable.value
  const FixedList = table.getColumns()

  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index
      if (item.fixed) {
        CancelFixed.value = false
        // console.log('有值 :>> ');
      } else {
        CancelFixed.value = true
        // console.log('无值 :>> ');
      }
    }
  })
  //console.log('FixedIndex下标为 :>> ', FixedIndex);

  showIdOptions.value = false
  showIdOptions.value = true
  // 设置位置
  const Event = event
  OperationBox.value.style.left = event.clientX - 100 + 'px'
}

// ! 高级显示
const ischangeShow = () => {
  changeShow.value = !changeShow.value
  for (let prop in Volunteers) {
    Volunteers[prop] = null
  }
}
// 高级搜索 下拉
const change_category_val = (e) => {
  // console.log(e);
  if (e.includes('都没有')) {
    // used_category_val.value = ['都没有'];
    const newValueWithoutAll = ['都没有']
    Volunteers.use_products = newValueWithoutAll
  }
}
// 高级搜索 下拉

const change_basic_illness = (e) => {
  // console.log(e);
  if (e.includes('无任何以上提到的疾病')) {
    const newValueWithoutAll = ['无任何以上提到的疾病']
    Volunteers.underlying_disease = newValueWithoutAll
  }
}
// 点击...
const ClickMethods = (row, event) => {
  console.log(row)
  OptionsData.value = row
  // console.log(DelectData.value);
  ClickMoreOptions.value = false
  ClickMoreOptions.value = true

  // console.log('event :>> ', event, CMoreOptions.value);

  CMoreOptions.value.style.left = event.clientX - 120 + 'px'
  CMoreOptions.value.style.top = event.clientY + 30 + 'px'
  // console.log('event :>> ', ClickMoreOptions.value);
}
// note 全局加载完毕之后执行此事件
nextTick(() => {
  setHeaderColumn()
  // 执行 列表显示/隐藏
  ShowHidenColumn()
  // 固定列表本地数据
  FixedColumn()
  // 获取拖拽数据
  GetDrag()
  // 转移数据
  TransferData()
})
const ErrorTip = () => {
  message({
    message: '请先勾选数据,再进行操作',
    grouping: true,
    type: 'error',
  })
}
// 批量删除---》DelteProject
const DelteProject = async () => {
  const res = await http.post('/recruit/deleteRecord-recruit', checkedArr.value)
  if (res.status == 0) {
    centerDialogVisible.value = false
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    })
    reqProjectList('批量删除')
  }
}
const isShowExport = () => {
  if (checkedArr.value.length >= 1) {
    null_reminder_export.value = false
    isExport.value = true
  } else {
    null_reminder_export.value = true
    isExport.value = false
  }
}
// 批量导出
const BatchExport = async () => {
  if (checkedArr.value.length != 0) {
    const res = await http.post('/upload/export-recruit', { recruitList: checkedArr.value, sort: SORT.value })
    console.log('res :>> ', res)
    if (res.status == 0) {
      const name =
        store.state.Project.ProjectId === ''
          ? `志愿者招募信息 ${dayjs().format('YYYYMMDD')}.xlsx`
          : `志愿者招募信息 ${store.state.Project.ProjectNumber} ${dayjs().format('YYYYMMDD')}.xlsx`
      xlsx.writeFileXLSX(res.xlsxData, name)
      message({
        message: '操作成功',
        ShowClose: true,
        type: 'success',
      })
    }
  } else {
    message({
      message: '请选择要导出的数据',
      ShowClose: true,
      type: 'error',
    })
  }
}
// 检查是否有本地数据()
const setHeaderColumn = () => {
  if (localStorage.getItem('SetReruit_Volun') == null) {
    // ////console.log('"第一次" :>> ', '第一次');
  } else {
    // ////console.log('"第二次" :>> ', '第二次');
    SetRecruitVolunteerArr.value = ase.decrypt(localStorage.getItem('SetReruit_Volun'))
  }
  SetRecruitVolunteerArr.value.forEach((item, index) => {
    if (item.checked == true) {
      allDatas.value.push(SetRecruitVolunteerArr.value[index])
    }
  })

  console.log('最终渲染的全部true数据', allDatas.value)
  // 赋值给dragDemo；
  dragDemo.value.splice(0, dragDemo.value.length)
  dragDemo.value = JSON.parse(JSON.stringify(allDatas.value))
}

// 检查本地拖拽顺序
const GetDrag = () => {
  if (localStorage.getItem('VolRecruitDrag') == null) {
    // //console.log('"第一次" :>> ', '第一次');
  } else {
    // //console.log('"第二次" :>> ', '第二次');

    allDatas.value = ase.decrypt(localStorage.getItem('VolRecruitDrag'))
    dragDemo.value = JSON.parse(JSON.stringify(allDatas.value))
  }
}
// 表头字段设置----> 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true
  TemporaryData.value = JSON.parse(JSON.stringify(SetRecruitVolunteerArr.value))

  //console.log('SetTableHeader', SetTableHeader.value);
}
// 表头字段设置----> 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false
  SetRecruitVolunteerArr.value = JSON.parse(JSON.stringify(TemporaryData.value))
}
//  表头字段设置----> 恢复默认字段
const RecoverData = () => {
  console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value)
  // let defaultdata = readonly(DefaultHeaderData)
  SetRecruitVolunteerArr.value = JSON.parse(JSON.stringify(DefaultHeaderData.value))
  //重置拖拽数据
  dragDemo.value = []
  SetRecruitVolunteerArr.value.forEach((item, index) => {
    if (item.checked) {
      dragDemo.value.push(item)
    }
  })
  console.log(dragDemo.value)
}
// 表头阻断设置---->的点击复选框
const CheckHeader = (i) => {
  // 默认展示的字段/不可取消
  if (defaultHeader.value.indexOf(SetRecruitVolunteerArr.value[i].lable) != -1) {
    message({
      message: `${SetRecruitVolunteerArr.value[i].lable}不能删除`,
      grouping: true,
      type: 'error',
    })
    SetRecruitVolunteerArr.value[i].checked = true
    return
  }
  // 拖拽左边勾选，右边增加；右边删除，左边取消勾选
  // 如果是true ，push进dragdemo
  if (SetRecruitVolunteerArr.value[i].checked) {
    dragDemo.value.push(SetRecruitVolunteerArr.value[i])
  } else {
    //如果是false，从dragdemo中删除
    dragDemo.value = dragDemo.value.filter((item) => item.lable !== SetRecruitVolunteerArr.value[i].lable)
  }
}
// ShowHidenColumn 设置column的的列表(确认按钮)
const ShowHidenColumn = () => {
  allDatas.value = JSON.parse(JSON.stringify(dragDemo.value))

  const $table = xTable.value
  const column = $table.getColumns()

  //console.log('$table.getColumns :>> ', $table.getColumns());

  for (let i = 0; i < SetRecruitVolunteerArr.value.length; i++) {
    if (SetRecruitVolunteerArr.value[i].checked == true) {
      // //console.log('object :>> ', SetRecruitVolunteerArr[i].field);
      $table.showColumn(SetRecruitVolunteerArr.value[i].field)
    }
    // for (let j = 1; j < column.length; j++) {
    //   if (column[j].title == SetRecruitVolunteerArr.value[i].lable) {
    //     if (SetRecruitVolunteerArr.value[i].checked == false) {
    //       $table.hideColumn(column[j].field);
    //     }
    //   }
    // }
  }

  $table.refreshColumn()
  //console.log('$table.getColumns :>> ', $table.getColumns());
  SetTableHeader.value = false
}
// FixedColumn 设置column的固定列(初始化本地数据)
const FixedColumn = () => {
  const $table = xTable.value
  const column = $table.getColumns()

  //console.log('column:>> ', column);
  //console.log('SetRecruitVolunteerArr', SetRecruitVolunteerArr.value);
  for (let i = 0; i < SetRecruitVolunteerArr.value.length; i++) {
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetRecruitVolunteerArr.value[i].lable) {
        if (SetRecruitVolunteerArr.value[i].fixed != '') {
          toggleFixedColumn(j, SetRecruitVolunteerArr.value[i].fixed)
        }
      }
    }
  }

  $table.refreshColumn()
  // //console.log('$table.getColumns :>> ', $table.getColumns());
  // SetTableHeader.value = false;
}
// // 判断是否
// const is_output = computed(() => (e) => {
//   // console.log('吸烟', e);
//   if (e === false) {
//     return '否';
//   } else if (e === true) {
//     return '是';
//   } else {
//     return '';
//   }
// });

//  操作===================================================
// 添加中途退出原因
const AddRefuse = async (val) => {
  const res = await http.post('/recruit/addExit-recruit', {
    recrodId: val._id,
    exit: quitReason.value,
  })
  if (!res.status) {
    console.log('中途退出原因增加返回是：', res)
  }
}
// 通过复审
const SinglePass = async (row) => {
  console.log(row)
  const res = await http.post('/recruit/batchReexamine-recruit', [row._id])
  if (res.status == 0) {
    message({
      message: '操作成功',
      grouping: true,
      type: 'success',
    })
    reqStatus()

    ChangeStatusData.value.Show = false
  }
}
// 单个通过初审
const SingleFirst = async (row) => {
  console.log(row)
  const res = await http.post('/recruit/batchExamine-recruit', [row._id])
  if (res.status == 0) {
    message({
      message: '操作成功',
      ShowClose: true,
      type: 'success',
    })
    reqStatus()
    ChangeStatusData.value.Show = false
  } else {
    message({
      message: '初审失败,招募人数已满',
      ShowClose: true,
      type: 'error',
    })
  }
}
// 单个中途退出
const SingleExit = async (row) => {
  AddRefuse(row) //调用添加拒绝信息
  console.log(row)
  const res = await http.post('/recruit/batchExit-recruit', [row._id])
  if (res.status == 0) {
    message({
      message: '操作成功',
      grouping: true,
      type: 'success',
    })
    reqStatus()
    ChangeStatusData.value.Show = false
    isShowQuit.value = false
  }
}
// 单个拒绝
const SingleRefuse = async (row) => {
  console.log(row)
  const res = await http.post('/recruit/batchReject-recruit', [row._id])
  if (res.status == 0) {
    message({
      message: '操作成功',
      grouping: true,
      type: 'success',
    })
    ChangeStatusData.value.Show = false

    reqStatus()
  }
}
// 单个测试完成
const SingleComplete = async (row) => {
  console.log(row)
  const res = await http.post('/recruit/batchFinish-recruit', [row._id])
  if (res.status == 0) {
    message({
      message: '操作成功',
      grouping: true,
      type: 'success',
    })
    reqStatus()
    ChangeStatusData.value.Show = false
  }
}
const reqStatus = async () => {
  let newValue = ClickActive.value
  const Project = {}
  if (newValue == 1) {
    Project.status = '已报名'
  } else if (newValue == 2) {
    Project.status = '已初审'
  } else if (newValue == 3) {
    Project.status = '测试中'
  } else if (newValue == 4) {
    Project.status = '已完成'
  } else if (newValue == 5) {
    Project.status = '已退出'
  } else if (newValue == 0) {
    Project.status = '全部'
  }

  console.log('Project--->', Project)
  const res = await http.post('/recruit/findRecord-recruit', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    Project: store.state.Project.ProjectId,

    status: Project.status,
  })
  // 清除勾选
  const $table = xTable.value
  $table.clearCheckboxRow()
  checkedArr.value.splice(0, checkedArr.value.length)
  // 清除条件筛选
  demo1.filterName = ''
  demo1.gender = ''
  demo1.age = ''

  if (res.status == 0) {
    demo1.tableData = res.recruit_form
    page5.totalResult = res.totalCount
    if (res.recruit_form.length == 0) {
      page5.totalResult = 0
    }
  }
}

// todo
// 判断弹窗数据
const Changestatus = (val, row) => {
  ChangeStatusData.value.Name = row.volunteer.volun_name
  ChangeStatusData.value.Show = true
  ChangeStatusData.value.row = row
  ChangeStatusData.value.Status = val

  // if (val == '通过初审') {
  //   ChangeStatusData.value.Status = '通过初审';
  // } else if (val == '通过复审') {
  //   ChangeStatusData.value.Status = '通过复审';
  // } else if (val == '中途退出') {
  //   ChangeStatusData.value.Status = '中途退出';
  // } else if (val == '拒绝') {
  //   ChangeStatusData.value.Status = '拒绝';
  // }else if(val=='测试完成'){
  //   ChangeStatusData.value.Status = '拒绝';

  // }
}
// 中途退出弹窗
const isShowQuit = ref(false)
const quitReason = ref('')
const quitPerson = ref('')
const Changestatus1 = (val, row) => {
  ChangeStatusData.value.Name = row.volunteer.volun_name
  // ChangeStatusData.value.Show = true;
  ChangeStatusData.value.row = row
  ChangeStatusData.value.Status = val

  console.log(row.volunteer.volun_name)
  quitPerson.value = row.volunteer.volun_name
  quitReason.value = ''
  isShowQuit.value = true
}
// 批量操作的确认按钮
const ComfirmStatus = async () => {
  if (checkedArr.value.length != 0) {
    // 0 初审 1复审 2完成 3退出 4拒绝
    console.log('我的状态是', change_status_values.value)
    if (change_status_values.value == 1) {
      const res = await http.post('/recruit/batchReexamine-recruit', checkedArr.value)
      if (res.status == 0) {
        message({
          message: '操作成功',
          grouping: true,
          type: 'success',
        })
        reqProjectList()
      } else {
        message({
          message: '操作失败,勾选数据中的状态不符合要求,请重新选择',
          grouping: true,
          type: 'error',
        })
      }
    } else if (change_status_values.value == 0) {
      const res = await http.post('/recruit/batchExamine-recruit', checkedArr.value)
      if (res.status == 0) {
        message({
          message: '操作成功',
          grouping: true,
          type: 'success',
        })
        reqProjectList()
      } else {
        message({
          message: '操作失败,勾选数据中的状态不符合要求,请重新选择',
          grouping: true,
          type: 'error',
        })
      }
    } else if (change_status_values.value == 2) {
      const res = await http.post('/recruit/batchFinish-recruit', checkedArr.value)
      if (res.status == 0) {
        message({
          message: '操作成功',
          grouping: true,
          type: 'success',
        })
        reqProjectList()
      } else {
        message({
          message: '操作失败,勾选数据中的状态不符合要求,请重新选择',
          grouping: true,
          type: 'error',
        })
      }
    } else if (change_status_values.value == 3) {
      const res = await http.post('/recruit/batchExit-recruit', checkedArr.value)
      if (res.status == 0) {
        message({
          message: '操作成功',
          grouping: true,
          type: 'success',
        })
        reqProjectList()
      } else {
        message({
          message: '操作失败,勾选数据中的状态不符合要求,请重新选择',
          grouping: true,
          type: 'error',
        })
      }
    } else if (change_status_values.value == 4) {
      const res = await http.post('/recruit/batchReject-recruit', checkedArr.value)
      if (res.status == 0) {
        message({
          message: '操作成功',
          grouping: true,
          type: 'success',
        })
        reqProjectList()
      } else {
        message({
          message: '操作失败,勾选数据中的状态不符合要求,请重新选择',
          grouping: true,
          type: 'error',
        })
      }
    }
  } else {
    message({
      message: '请先勾选数据之后,再进行操作',
      grouping: true,
      type: 'error',
    })
  }
}
// 处理出生日期的时间戳
const birthday = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD')
  }
})
// 处理注册时间戳
const createdAt = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD HH:mm')
  }
})
const formatterData = ({ cellValue }) => {
  console.log(cellValue)
  return cellValue === undefined || cellValue === '' || cellValue === null ? '——' : cellValue
}
// tip tootip提示方法
const tooltipConfig = reactive({
  showAll: true,
  enterable: true,
  contentMethod: ({ type, column, row, items, _columnIndex }) => {
    const { field } = column

    // column 判断 column.title 是否为'操作'
    // 重写默认的提示内容
    if (column.title == '操作') {
      return (tooltipConfig.showAll = false)
    } else if (field === 'rate') {
      // 返回空字符串，控制单元格不显示提示内容
      return ''
    } else {
      tooltipConfig.showAll = true
    }
    // 其余的单元格使用默认行为

    return null
  },
})
// 获取排期时间
const ReqVisitDate = async () => {
  if (store.state.Project.ProjectId != '') {
    Supplement.value.OperationValue.splice(0, Supplement.value.OperationValue.length)
    const res = await http.post('/test/getTimeOptions-test', {
      projectId: store.state.Project.ProjectId,
    })
    console.log('获取排期时间返回的数据--》res :>> ', res)
    if (res.status == 0) {
      let obj = {}
      res.times[0].forEach((item, index) => {
        let obj = {}
        obj.lable = item
        obj.value = item
        Supplement.value.OperationValue.push(obj)
      })
    }
  }
}

// 数据--格式化
const formatterDatas = (val) => {
  return val === undefined || val === '' || val === null ? '——' : val
}
// 拿取项目的年龄限制和性别限制;
const reqLimit = async () => {
  const res = await http.post('/project/findInfo-project', {
    projectId: store.state.Project.ProjectId,
  })
  if (res.status == 0) {
    Limit.value.Age = res.project[0].ageRequirement
    Limit.value.Gender = res.project[0].sexRequirement
    Limit.value.Status = res.project[0].status
  }
}

function isCanJoinInByAge(ageRange, age) {
  console.log('age', age)
  console.log('ageRange', ageRange)

  if (ageRange.includes('不限')) {
    return true
  } else {
    if (ageRange.includes('＞50')) {
      console.log('有50岁的')
      let res = age > 50
      console.log('res', res)
      if (res) {
        // age 大于50
        return true
      } else {
        // age 小于 50
        // 剪切50岁，再循环
        let newArr = ageRange.filter((item) => item != '＞50')
        if (newArr.length > 0) {
          return this.isCanJoinInByAge(newArr, age)
        } else {
          return false
        }
      }
    } else {
      for (let i = 0; i < ageRange.length; i++) {
        let range = ageRange[i].split('-') // 将字符串范围拆分成两个数字
        let min = parseInt(range[0])
        let max = parseInt(range[1])
        if (age >= min && age <= max) {
          return true // 如果数字在范围内，返回 true
        }
      }
    }
  }
}

//note mounted
onMounted(() => {
  reqProjectList()
  ReqVisitDate()
  reqLimit() //获取该项目的限制数据
  ExportDate.value = Date() //获取当前时间
  // 初始话存储数据下
  //console.log('demo1.tableData :>> ', demo1.tableData);
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    //处理点击更多操作盒子 以外的地方隐藏盒子

    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false
      }
    }
    if (isshowOperation.value == true) {
      if (e.target.id != 'operationBox') {
        isshowOperation.value = false
      }
    }
    if (isShow.value == true) {
      if (e.target.id != 'show_box') {
        isShow.value = false
      }
    }
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false
      }
    }
    // 操作
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false
      }
      console.log(ClickMoreOptions.value)
    }
  })

  store.commit('Set_CurrentProjectName', '志愿者招募管理')

  // 暴露方法
  provide('parentMethod', reqProjectList)
})

// note 组件销毁
onBeforeUnmount(() => {
  const table = xTable.value
  console.log('组件销毁时的数值 :>> ', table)
  const FixedList = table.getColumns()
  // console.log('销毁时的列顺序', FixedList);
  for (let i = 1; i < FixedList.length; i++) {
    for (let j = 0; j < SetRecruitVolunteerArr.value.length; j++) {
      if (FixedList[i].field == SetRecruitVolunteerArr.value[j].field) {
        SetRecruitVolunteerArr.value[j].fixed = FixedList[i].fixed
      }
    }
  }

  localStorage.setItem('SetReruit_Volun', ase.encrypt(SetRecruitVolunteerArr.value))

  localStorage.setItem('VolRecruitDrag', ase.encrypt(dragDemo.value))
})
</script>

<style lang="less" scoped>
@import url('@/style/views/VolunteerManagement/RecruitVolunteer.less');
.itxst {
  width: 600px;
  display: flex;
}

.itxst > div:nth-of-type(1) {
  flex: 1;
}

.itxst > div:nth-of-type(2) {
  width: 270px;
  padding-left: 20px;
}

.item {
  // border: solid 1px #eee;
  padding: 6px 10px;
  text-align: left;
}

.item:hover {
  cursor: move;
}

.item + .item {
  // margin-top: 10px;
}

.ghost {
  // 跟随鼠标的样式
  border: solid 1px #027aff !important;
  background: #fff !important;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.chosenClass {
  // 鼠标拉动的样式。
  background-color: #fff;
  opacity: 1;
  border: solid 1px #027aff;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.fallbackClass {
  background-color: aquamarine;
}
</style>
