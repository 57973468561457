<template>
  <div id="Login">
    <div id="login_input_form">
      <!-- 登录表单 -->
      <div id="login_input" v-if="is_login">
        <div class="From">
          <p>登录</p>
          <div class="name">
            <el-input
              v-model="email"
              placeholder="请输入邮箱"
              :prefix-icon="Message"
              @blur="validEmail(email)"
              :class="[email_errorMessage !== '' ? 'error_input' : '']" />
            <div class="UserName">{{ email_errorMessage }}</div>
          </div>
          <div class="pwd">
            <el-input
              v-model="pwd"
              type="password"
              placeholder="请输入密码"
              :prefix-icon="Lock"
              show-password
              :class="password_errorMessage !== '' ? 'error_input' : ''" />
            <div class="UserPsw">{{ password_errorMessage }}</div>
          </div>
          <div class="verify-box" v-if="login_status === 1">
            <el-input v-model="verify_code" placeholder="" />
            <el-button type="primary" :class="is_sendCode ? '' : 'is-disabled'" :disabled="!is_sendCode">
              <span v-if="is_sendCode" @click="send_verifyCode">发送验证码</span>
              <span v-else>{{ countdown_time }}s后重新发送</span>
            </el-button>
          </div>
          <div class="passwordOperation">
            <div class="Remember">
              <el-checkbox v-model="checked1" size="large" />
              <div class="text" @click="checked1 = !checked1">7天免登录</div>
            </div>

            <div class="ForgetPSW" @click="forgetPwd">
              <div class="text">忘记密码?</div>
            </div>
          </div>
          <button
            type="button"
            @click="login"
            @keydown.enter="login"
            class="loginBtn0"
            :class="{ loginBtn: email && pwd && email_errorMessage == '' && password_errorMessage == '' }"
            style="cursor: pointer">
            登录
          </button>
          <!-- |
      <button @click="req">注册</button> -->
        </div>
        <!-- 滑块验证 -->
        <el-dialog v-model="LoginSuccess" title="请先完成安全验证" class="slide_">
          <div class="" id="verify" v-if="LoginSuccess">
            <slide-verify
              class="silde_box"
              ref="block"
              :l="42"
              :r="10"
              :w="400"
              :h="200"
              :imgs="img"
              :slider-text="text"
              :accuracy="accuracy"
              @again="onAgain"
              @success="onSuccess"
              @fail="onFail"
              @refresh="onRefresh"></slide-verify>
            <div>{{ msg }}</div>
          </div>
        </el-dialog>
      </div>
      <!-- 忘记密码页面 -->
      <div class="ForgetPassword" v-if="ClickForget">
        <!-- 输入忘记密码的账号 -->
        <div class="box" v-if="box">
          <div class="text">找回密码</div>
          <div class="Input">
            <!-- @blur="validEmail(ForgetCount)" -->
            <el-input
              clearable
              @input="verify_email(ForgetCount)"
              :class="find_pwd_hint ? 'error_input' : ''"
              :prefix-icon="Message"
              v-model="ForgetCount"
              placeholder="请输入邮箱" />
            <span class="find_pwd_hint">{{ find_pwd_hint }}</span>
          </div>
          <!-- <JcRange status="status"></JcRange> -->
          <Slider></Slider>
          <el-button @click="VerifyForgetPsw" :disabled="!isDisabled" :class="isDisabled ? '' : 'is-disabled'">发送验证码</el-button>
          <div class="back" @click="backLogin">返回</div>
        </div>
        <div class="VerifyCode" v-if="VerifyCodes">
          <div class="text">请输入验证码</div>
          <div class="Input">
            <el-input v-model="VerifyCode" placeholder="验证码" />
          </div>
          <button @click="VerifyNumber">下一步</button>
        </div>
        <div class="ChangePassword" v-if="ChangePassword">
          <div class="text">修改密码</div>
          <div class="Input">
            <el-input v-model="NewPassword" type="password" show-password placeholder="请输入新密码" @blur="VerifyNewPwd" />
          </div>
          <div class="Input">
            <el-input v-model="ConfirmPassword" type="password" show-password placeholder="请再次输入密码" @blur="ConfirmPsw" />
          </div>
          <button @click="CompletePsw">完成</button>
        </div>
      </div>
      <router-view />
    </div>
  </div>
</template>
<script setup>
import { Message, Lock } from '@element-plus/icons-vue';
import JcRange from '@/components/JcRange.vue';
import Slider from '@/components/SliderVerify.vue';
import SlideVerify, { SlideVerifyInstance } from 'vue3-slide-verify';
import 'vue3-slide-verify/dist/style.css';
import { ElMessage, ElMessageBox } from 'element-plus';
import { message } from '../utils/resetMessage';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { defineComponent, ref, onMounted, watch, reactive, onBeforeMount } from 'vue';
import http from '@/utils/http';
import { onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router';

//API接收
const store = useStore();
// console.log(store.state.slider_state);
const router = useRouter();
const route = useRoute();
const d = {
  '@/views/ProjectManagement/ProjectList.vue': () => import('@/views/ProjectManagement/ProjectList.vue'),
  '@/views/ProjectManagement/ProjectSchedule.vue': () => import('@/views/ProjectManagement/ProjectSchedule.vue'),
  '@/views/ProjectManagement/ReportApproval.vue': () => import('@/views/ProjectManagement/ReportApproval.vue'),
  '@/components/Reportapprovalchild/ReportDetails.vue': () => import('@/components/Reportapprovalchild/ReportDetails.vue'),
  '@/components/ProjectManagement/CommentCpn.vue': () => import('@/components/ProjectManagement/CommentCpn.vue'),
  '@/views/ProjectManagement/Report_management.vue': () => import('@/views/ProjectManagement/Report_management.vue'),
  '@/views/VolunteerManagement/volunteer_Custom_Settings.vue': () => import('@/views/VolunteerManagement/volunteer_Custom_Settings.vue'),
  '@/views/SampleManagement/Sample_Custom_Settings.vue': () => import('@/views/SampleManagement/Sample_Custom_Settings.vue'),
  '@/views/InstrumentMangement/Lnstrument_Custom_Settings.vue': () => import('@/views/InstrumentMangement/Lnstrument_Custom_Settings.vue'),
  '@/views/ProjectManagement/Project_sharing.vue': () => import('@/views/ProjectManagement/Project_sharing.vue'),
  '@/components/ProjectManagement/ShareMe.vue': () => import('@/components/ProjectManagement/ShareMe.vue'),
  '@/components/ProjectManagement/MyShare.vue': () => import('@/components/ProjectManagement/MyShare.vue'),
  '@/components/ProjectManagement/ShareDetails.vue': () => import('@/components/ProjectManagement/ShareDetails.vue'),
  '@/components/ProjectManagement/SharePlan.vue': () => import('@/components/ProjectManagement/SharePlan.vue'),
  '@/components/ProjectManagement/ShareMember.vue': () => import('@/components/ProjectManagement/ShareMember.vue'),
  '@/components/ProjectManagement/ShareQuestion.vue': () => import('@/components/ProjectManagement/ShareQuestion.vue'),
  '@/components/ProjectManagement/ShareInstrumentPage.vue': () => import('@/components/ProjectManagement/ShareInstrumentPage.vue'),
  '@/components/ProjectManagement/ShareVolunteerData.vue': () => import('@/components/ProjectManagement/ShareVolunteerData.vue'),
  '@/components/ProjectManagement/ShareSample.vue': () => import('@/components/ProjectManagement/ShareSample.vue'),
  '@/components/ProjectManagement/ShareDocument.vue': () => import('@/components/ProjectManagement/ShareDocument.vue'),
  '@/components/ProjectManagement/ShareReport.vue': () => import('@/components/ProjectManagement/ShareReport.vue'),
  '@/components/ProjectManagement/ProjectDetails.vue': () => import('@/components/ProjectManagement/ProjectDetails.vue'),
  '@/components/ProjectManagement/ProjectPlan.vue': () => import('@/components/ProjectManagement/ProjectPlan.vue'),
  '@/components/ProjectManagement/ProjectMember.vue': () => import('@/components/ProjectManagement/ProjectMember.vue'),
  '@/components/ProjectManagement/ProjectQuestion.vue': () => import('@/components/ProjectManagement/ProjectQuestion.vue'),
  '@/components/ProjectManagement/InstrumentPage.vue': () => import('@/components/ProjectManagement/InstrumentPage.vue'),
  '@/components/ProjectManagement/VolunteerData.vue': () => import('@/components/ProjectManagement/VolunteerData.vue'),
  '@/components/ProjectManagement/ProjectSample.vue': () => import('@/components/ProjectManagement/ProjectSample.vue'),
  '@/components/ProjectManagement/ProjectDocument.vue': () => import('@/components/ProjectManagement/ProjectDocument.vue'),
  '@/components/ProjectManagement/ProjectReport.vue': () => import('@/components/ProjectManagement/ProjectReport.vue'),
  '@/components/ProjectManagement/CreateProject.vue': () => import('@/components/ProjectManagement/CreateProject.vue'),
  '@/views/VolunteerManagement/VolunteerBank.vue': () => import('@/views/VolunteerManagement/VolunteerBank.vue'),
  '@/views/VolunteerManagement/ClockinView.vue': () => import('@/views/VolunteerManagement/ClockinView.vue'),
  '@/views/VolunteerManagement/RecruitVolunteer.vue': () => import('@/views/VolunteerManagement/RecruitVolunteer.vue'),
  '@/views/InstrumentMangement/InstrumentList.vue': () => import('@/views/InstrumentMangement/InstrumentList.vue'),
  '@/views/VolunteerManagement/VolunteerSchedule.vue': () => import('@/views/VolunteerManagement/VolunteerSchedule.vue'),
  '@/views/InstrumentMangement/InstrumentTestRecord.vue': () => import('@/views/InstrumentMangement/InstrumentTestRecord.vue'),
  '@/views/VolunteerManagement/TestRecords.vue': () => import('@/views/VolunteerManagement/TestRecords.vue'),
  '@/views/QuestionnaireManagement/QuestionnaireList.vue': () => import('@/views/QuestionnaireManagement/QuestionnaireList.vue'),
  '@/components/QuestionnaireManagement/QuestionStatisticAnalysis.vue': () =>
    import('@/components/QuestionnaireManagement/QuestionStatisticAnalysis.vue'),
  '@/components/InstrumentMangement/InstrumentStatisticalAnalysis.vue': () =>
    import('@/components/InstrumentMangement/InstrumentStatisticalAnalysis.vue'),
  '@/components/QuestionnaireManagement/QuestionReport.vue': () => import('@/components/QuestionnaireManagement/QuestionReport.vue'),
  '@/views/QuestionnaireManagement/ShareQuestion.vue': () => import('@/views/QuestionnaireManagement/ShareQuestion.vue'),
  '@/views/SampleManagement/SampleList.vue': () => import('@/views/SampleManagement/SampleList.vue'),
  '@/views/SampleManagement/SampleFlowRecord.vue': () => import('@/views/SampleManagement/SampleFlowRecord.vue'),
  '@/views/SampleManagement/CustomSettings.vue': () => import('@/views/SampleManagement/CustomSettings.vue'),
  '@/views/VolunteerManagement/AppAdmin.vue': () => import('@/views/VolunteerManagement/AppAdmin.vue'),
  '@/components/QuestionnaireManagement/CreatedQuestionnaire.vue': () => import('@/components/QuestionnaireManagement/CreatedQuestionnaire.vue'),
  '@/components/QuestionnaireManagement/EditQuestion.vue': () => import('@/components/QuestionnaireManagement/EditQuestion.vue'),
  '@/components/QuestionnaireManagement/TextImport.vue': () => import('@/components/QuestionnaireManagement/TextImport.vue'),
  '@/components/QuestionnaireManagement/previewQuestion.vue': () => import('@/components/QuestionnaireManagement/previewQuestion.vue'),
  '@/components/QuestionnaireManagement/webPreviewQuestion.vue': () => import('@/components/QuestionnaireManagement/webPreviewQuestion.vue'),
  '@/views/ExpertManagement/ExpertList.vue': () => import('@/views/ExpertManagement/ExpertList.vue'),
  '@/views/ExpertManagement/CustomSettings.vue': () => import('@/views/ExpertManagement/CustomSettings.vue'),
  '@/views/ExpertManagement/ExpertEvaluationRecords.vue': () => import('@/views/ExpertManagement/ExpertEvaluationRecords.vue'),
  '@/views/systemManagement/personalCenter.vue': () => import('@/views/systemManagement/personalCenter.vue'),
  '@/views/systemManagement/UserPower.vue': () => import('@/views/systemManagement/UserPower.vue'),
  '@/views/systemManagement/SignatureManagement.vue': () => import('@/views/systemManagement/SignatureManagement.vue'),
  '@/components/systemManagement/UserInfo.vue': () => import('@/components/systemManagement/UserInfo.vue'),
  '@/components/systemManagement/PowerMange.vue': () => import('@/components/systemManagement/PowerMange.vue'),
  '@/views/Home/HomeView.vue': () => import('@/views/Home/HomeView.vue'),
  '@/views/OperationLog/OperationLog.vue': () => import('@/views/OperationLog/OperationLog.vue'),
  '@/views/ProjectManagement/ProjectManagement_DataBoard.vue': () => import('@/views/ProjectManagement/ProjectManagement_DataBoard.vue'),
  '@/views/VolunteerManagement/VolunteerDataBoard.vue': () => import('@/views/VolunteerManagement/VolunteerDataBoard.vue'),
  '@/views/InstrumentMangement/InstrumentDataBoard.vue': () => import('@/views/InstrumentMangement/InstrumentDataBoard.vue'),
  '@/views/QuestionnaireManagement/H5QuestionPreview.vue': () => import('@/views/QuestionnaireManagement/H5QuestionPreview.vue'),
  '@/views/QuestionnaireManagement/WebQuestionPreview.vue': () => import('@/views/QuestionnaireManagement/WebQuestionPreview.vue'),
  '@/components/QuestionnaireManagement/QuestionDefaultReport.vue': () => import('@/components/QuestionnaireManagement/QuestionDefaultReport.vue'),
};
const status = ref(false);
//邮箱/密码声明
const email = ref(''); //邮箱
const pwd = ref(''); //密码
const email_errorMessage = ref(''); //邮箱错误信息
const password_errorMessage = ref(''); //密码错误信息

//邮箱验证方法
const validEmail = (email) => {
  // console.log('email :>> ', email);
  const emailRegExp = new RegExp(/[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/i);
  if (!email) {
    email_errorMessage.value = '请输入邮箱';
  } else if (!emailRegExp.test(email)) {
    email_errorMessage.value = '请输入正确的邮箱格式';
  } else {
    email_errorMessage.value = '';
  }
};

const validPassword = (password) => {
  if (!password) {
    password_errorMessage.value = '请输入密码';
  } else {
    password_errorMessage.value = '';
  }
};
//监听输入账号持续调用校验方法
watch(email, (newValue) => {
  validEmail(newValue);
});

watch(pwd, (newValue) => {
  validPassword(newValue);
});
const checked1 = ref(false); //记住密码

//登录方法
const pwdError_number = ref(0);
const login_status = ref(0);
const login = async () => {
  localStorage.clear();
  sessionStorage.clear();
  validEmail(email.value);
  validPassword(pwd.value);
  if (email_errorMessage.value == '' && password_errorMessage.value == '') {
    if (login_status.value === 0) {
      const res = await http.post('/auth/login', {
        email: email.value,
        password: pwd.value,
      });
      console.log('登录返回--->', res);
      if (res.status == 0) {
        localStorage.setItem('loginChecked', true);
        const result = await http.post('/user/getUserMessage-user', {
          userId: res.user._id,
        });
        localStorage.setItem('access_token', res.access_token);
        localStorage.setItem('_id', res.user._id);
        localStorage.setItem('company', result.user.company);
        store.commit('set_reset_password', res.reset_password);
        if (result.status === 0) {
          window.sessionStorage.setItem('permission_list', result.user.permission_list);
          store.commit('SET_ROLEINFO', result.user.permission_list);
          LoginSuccess.value = true;

          if (checked1.value == true) {
            // 七天免密登录
            localStorage.setItem('pwd_free_Login', true);
          }
          if (pwd.value === '12345') {
            // 首次登录提示强制修改密码
            localStorage.setItem('reset_password', true);
          } else {
            console.log('删除reset_password');
            localStorage.removeItem('reset_password');
          }

          localStorage.setItem('cemsAccount', email.value);
          localStorage.setItem('userName', result.user.name);
          localStorage.setItem('userEmail', result.user.email);
          localStorage.setItem('userGender', result.user.gender);
          store.commit('set_UserName', email.value);
        }
      } else if (res.status == 1) {
        if (res.message === 'This email is not registered') {
          email_errorMessage.value = '邮箱未注册';
        } else {
          login_status.value = res.status;
        }
      } else if (res.status == 2) {
        password_errorMessage.value = '密码不正确';
        pwdError_number.value++;
        // console.log(pwdError_number.value);
      } else if (res.status == 3) {
        message({
          type: 'error',
          message: '密码错误5次,当日不可再登录！',
          showClose: true,
        });
        pwdError_number.value = 0;
      } else if (res.status == 4) {
        console.log('不是可信设备');
        const result = await http.post('/user/getUserMessage-user', {
          userId: res.user._id,
        });
        localStorage.setItem('access_token', res.access_token);
        localStorage.setItem('_id', res.user._id);
        localStorage.setItem('company', result.user.company);
        store.commit('set_reset_password', res.reset_password);
        console.log(result, '登录成功获取用户信息');
        if (result.status === 0) {
          window.sessionStorage.setItem('permission_list', result.user.permission_list);
          store.commit('SET_ROLEINFO', result.user.permission_list);

          if (checked1.value == true) {
            // 七天免密登录
            localStorage.setItem('pwd_free_Login', true);
          }
          if (pwd.value === '12345') {
            // 首次登录提示强制修改密码
            localStorage.setItem('reset_password', true);
          } else {
            console.log('删除reset_password');
            localStorage.removeItem('reset_password');
          }

          localStorage.setItem('cemsAccount', email.value);
          localStorage.setItem('userName', result.user.name);
          localStorage.setItem('userEmail', result.user.email);
          localStorage.setItem('userGender', result.user.gender);
          store.commit('set_UserName', email.value);
        }
        login_status.value = 1;
      }
    } else if (login_status.value === 1 && pwdError_number.value < 5) {
      const res = await http.post('/auth/validLoginVerifyCode', {
        email: email.value,
        LoginverifyCode: verify_code.value,
        password: pwd.value,
      });
      console.log('验证登陆验证码==>', res);
      // 验证成功保存数据
      if (res.status === 0) {
        if (checked1.value == true) {
          localStorage.setItem('pwd_free_Login', true);
        }
        localStorage.setItem('access_token', res.access_token);
        localStorage.setItem('_id', res.userId);

        if (pwd.value === '12345') {
          // 首次登录提示强制修改密码
          localStorage.setItem('reset_password', true);
        } else {
          console.log('删除reset_password');
          localStorage.removeItem('reset_password');
        }
        // 消息弹出框 是否将本设备加入可信设备
        ElMessageBox.confirm('是否将本设备加入可信设备', '', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const res = await http.post('/auth/updatedUserTrustedDevices', {
              id: localStorage.getItem('_id'),
            });
            console.log(res);
            if (res.status === 0) {
              ElMessage({
                type: 'success',
                message: '成功加入可信设备',
              });
              const ress = await http.post('/user/getUserMessage-user', {
                userId: localStorage.getItem('_id'),
              });
              localStorage.setItem('company', ress.user.company);
              localStorage.setItem('userName', ress.user.name);
              localStorage.setItem('userEmail', ress.user.email);
              localStorage.setItem('userGender', resss.user.gender);
              const routess = router.getRoutes();
              // routess.forEach((item) => {
              //     if (item.name != 'about' && item.name != 'login') {
              //         router.removeRoute(item.name);
              //     }
              // });
              let obj = [];
              ress.user.route.forEach((item, index) => {
                obj.push(item.component);
                console.log(item, 'item');
                const processedRoute = {};
                processedRoute.path = item.path;
                processedRoute.name = item.name;
                processedRoute['component'] = d[item.component];
                processedRoute.meta = item.meta;

                router.addRoute(processedRoute);
              });
              if (!ress.user.permission_list.includes('home')) {
                store.commit('Set_CurrentProjectName', '项目分享');
                router.push('/Project_sharing');
              } else {
                store.commit('Set_CurrentProjectName', '主页');

                router.push('/Home');
              }
              // LoginSuccess.value = true;
            } else {
              ElMessage({
                type: 'error',
                message: '加入可信设备失败',
              });
            }
          })
          .catch(async () => {
            // ElMessage({
            //   type: 'info',
            //   message: '取消加入可信设备',
            // });
            const ress = await http.post('/user/getUserMessage-user', {
              userId: localStorage.getItem('_id'),
            });
            const routess = router.getRoutes();
            localStorage.setItem('userName', ress.user.name);
            localStorage.setItem('userEmail', ress.user.email);
            localStorage.setItem('userGender', ress.user.gender);
            localStorage.setItem('company', ress.user.company);
            // routess.forEach((item) => {
            //     if (item.name != 'about' && item.name != 'login') {
            //         router.removeRoute(item.name);
            //     }
            // });
            let obj = [];
            ress.user.route.forEach((item, index) => {
              obj.push(item.component);
              console.log(item, 'item');
              const processedRoute = {};
              processedRoute.path = item.path;
              processedRoute.name = item.name;
              processedRoute['component'] = d[item.component];
              processedRoute.meta = item.meta;

              router.addRoute(processedRoute);
            });
            if (!ress.user.permission_list.includes('home')) {
              store.commit('Set_CurrentProjectName', '项目分享');
              router.push('/Project_sharing');
            } else {
              store.commit('Set_CurrentProjectName', '主页');
              router.push('/Home');
            }
            // LoginSuccess.value = true;
            console.log('取消加入可信设备');
          });
      } else if (res.status === 3) {
        message({
          type: 'error',
          message: '验证码错误',
          showClose: true,
        });
      }
    }
  }
};
//滑块数据
const block = ref();
const ErrorNum = ref(0);
const msg = ref('');
const LoginSuccess = ref(false);
const text = ref('向右滑动滑块!');
const verifyWidth = ref('400');
const img = ['https://newstore.vynior.com/bb.jpg', 'https://newstore.vynior.com/aa.jpg', 'https://newstore.vynior.com/cc.jpg'];
const reload = ref(false); //用于v-if让组件SlideVerify刷新，如果不刷新，页面宽度改变的情况下，组件SlideVerify中canvas绘制的图片宽度不会改变

const onAgain = () => {
  // msg.value = '检测到非人为操作的哦！ try again';
  // 刷新
  block.value?.refresh();
};
//滑块成功
const onSuccess = async (times) => {
  // msg.value = `login success, 耗时${(times / 1000).toFixed(1)}s`;
  const ress = await http.post('/user/getUserMessage-user', {
    userId: localStorage.getItem('_id'),
  });
  const routess = router.getRoutes();
  // routess.forEach((item) => {
  //     if (item.name != 'about' && item.name != 'login') {
  //         router.removeRoute(item.name);
  //     }
  // });
  let obj = [];
  ress.user.route.forEach((item, index) => {
    obj.push(item.component);
    const processedRoute = {};
    processedRoute.path = item.path;
    processedRoute.name = item.name;
    processedRoute['component'] = d[item.component];
    processedRoute.meta = item.meta;

    router.addRoute(processedRoute);
  });
  if (!ress.user.permission_list.includes('home')) {
    store.commit('Set_CurrentProjectName', '项目分享');
    router.push('/Project_sharing');
  } else {
    store.commit('Set_CurrentProjectName', '主页');
    router.push('/Home');
  }
};
//滑块失败
const onFail = () => {
  // msg.value = '验证不通过';
  ErrorNum.value++;
  console.log('ErrorNum :>> ', ErrorNum);
  if (ErrorNum.value == 5) {
    message({
      type: 'error',
      message: '校验失败次数上线,请重新登录!',
      showClose: true,
    });
    localStorage.removeItem('token');
    localStorage.removeItem('access_token');
    router.go(0);
  }
};
//滑块刷新
const onRefresh = () => {
  // msg.value = '点击了刷新小图标';
};
//滑块自适应
// onMounted(() => {
//   setWerifyWidth();
//   window.onresize = () => {
//     reload.value = false;
//     setWerifyWidth();
//   };
// });
// async function setWerifyWidth() {
//   let verify = await getverify();
//   verifyWidth.value = verify.offsetWidth;
//   reload.value = true;
// }
// function getverify() {
//   return new Promise((resolve, reject) => {
//     //要写在定时器里面才能够获取得到document.getElementById('verify')的值
//     //本例子这里是id为verify的元素宽度是自适应的，获取到这个宽度，将验证模块的宽度也设为这个宽度
//     //具体方法具体实现，可参考本例子
//     let timer = setTimeout(function () {
//       let verify = document.getElementById('verify');
//       clearTimeout(timer);
//       resolve(verify);
//     }, 1);
//   });
// }

// 忘记密码页面操作数据
const ForgetCount = ref(''); //忘记密码的账号/手机号
const VerifyCode = ref(''); //验证码
const ReturnVerifyCode = ref(''); //返回的验证码
const NewPassword = ref(''); //新密码
const ConfirmPassword = ref(''); //再次输入新密码
const ClickForget = ref(false); //显示找回密码盒子
const ChangePassword = ref(false); //显示输入新密码盒子
const VerifyCodes = ref(false); //显示验证码盒子
const box = ref(false);
const PassVerify = ref(false); //是否符合更改密码条件
const PassConfirm = ref(false);

// 找回密码提示语
const find_pwd_hint = ref('');
//校验账号
const VerifyForgetPsw = async () => {
  if (ForgetCount.value !== '' && find_pwd_hint.value === '') {
    const res = await http.post('/auth/getVerifyCode', {
      email: ForgetCount.value,
    });
    console.log(res);
    if (res.status == 0) {
      box.value = false;
      VerifyCodes.value = false;
      ReturnVerifyCode.value = res.verifyCode;
      // alert('验证码是:', res.VerifyCode);
      // 跳转到验证码已发送的页面
      ClickForget.value = false;
      router.push({
        path: '/verify',
        query: {
          // 邮箱作为参数，base64加密
          mail: btoa(ForgetCount.value),
        },
      });
    } else {
      // message({
      //   type: 'error',
      //   showClose: true,
      //   message: '此账号未注册!',
      // });
      find_pwd_hint.value = '邮箱未注册';
    }
    console.log('ReturnVerifyCode.value :>> ', ReturnVerifyCode.value);
  } else if (ForgetCount.value === '') {
    find_pwd_hint.value = '请输入邮箱';
  } else if (find_pwd_hint.value !== '') {
    find_pwd_hint.value = '请输入正确的邮箱格式';
  }
};
//校验验证码
const VerifyNumber = () => {
  if (ReturnVerifyCode.value == VerifyCode.value) {
    VerifyCodes.value = false;
    ChangePassword.value = true;
  } else {
    message({
      type: 'error',
      showClose: true,
      message: '验证码错误,请重新输入!',
    });
  }
};

// 校验密码强度
const VerifyNewPwd = () => {
  // console.log('NewPwd.value.length :>> ', NewPwd.value.length);
  let judgeFn = new RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&/*()_+|{}:"?]{8,16}$/);
  // 根据newpwd的数据长度来判断,0就不判断;
  if (NewPassword.value.length != 0) {
    if (!judgeFn.test(NewPassword.value)) {
      PassVerify.value = false;
      message({
        type: 'warning',
        message: '密码强度不符合要求',
        showClose: true,
      });
    } else {
      PassVerify.value = true;
    }
  }
};
//校验两次密码一致
const ConfirmPsw = () => {
  if (NewPassword.value != ConfirmPassword.value) {
    message({
      type: 'error',
      message: '两次输入的密码不一致',
      showClose: true,
    });
    PassConfirm.value = false;
  } else {
    PassConfirm.value = true;
  }
};
//设置新密码
const CompletePsw = async () => {
  // console.log(PassConfirm.value);
  if (PassConfirm.value == true && PassVerify.value == true) {
    const res = await http.post('/user/resetPassword-user', {
      _id: localStorage.getItem('_id'),
      password: NewPassword.value,
    });
    console.log('初始化密码返回--->', res);
    if (res.status == 0) {
      ClickForget.value = false;
      message({
        type: 'success',
        message: '修改密码成功!',
        showClose: true,
      });
      router.push('/');
    } else {
      message({
        type: 'error',
        message: '无效用户,请联系管理员!',
        showClose: true,
      });
    }
  } else {
    ConfirmPsw();
    VerifyNewPwd();
    console.log('123');
    console.log('PassConfirm,PassVerify :>> ', PassConfirm.value, PassVerify.value);
  }
};
// 校验token
const verifyToken = async () => {
  const res = await http.post('/auth/tokenLogin', {
    access_token: localStorage.getItem('access_token'),
  });

  if (res.status == 0) {
    // router.push(' /Home');
  }
};

const is_login = ref(true);
// 登录表单验证码
const verify_code = ref('');

const is_sendCode = ref(true);
const countdown_time = ref(60);
// 发送验证码之后，倒计时
const send_verifyCode = async () => {
  // 获取登录验证码
  const res = await http.post('/auth/getLoginVerifyCode', {
    email: email.value,
  });
  console.log(res);
  if (res.status !== 1) {
    is_sendCode.value = false;
    countdown_time.value = 60;
    var times = setInterval(() => {
      countdown_time.value--; //递减
      if (countdown_time.value <= 0) {
        is_sendCode.value = true;
        clearInterval(times);
        countdown_time.value = 60;
      }
      // console.log(countdown_time.value);
    }, 1000); //1000
  } else if (res.status === 1) {
    message({
      type: 'error',
      message: '邮箱未注册',
      showClose: true,
    });
  }
};

// 忘记密码事件
const forgetPwd = () => {
  ClickForget.value = true;
  box.value = true;
  is_login.value = false;
};

const is_login_show = () => {
  // console.log(route.path);
  // console.log(router.currentRoute.value.path);
  if (route.path === '/verify' || route.path === '/set_newpassword') {
    is_login.value = false;
    ClickForget.value = false;
  }
};

// 找回密码模块返回登录页面
const backLogin = () => {
  is_login.value = true;
  ClickForget.value = false;
  slider_state.value = false;
  store.state.slider_state = false;
  ForgetCount.value = '';
};

// 控制发送验证码的disabled
const isDisabled = ref(false);
const slider_state = ref(false);
const regEmail = new RegExp(/[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/i);

watch(
  () => store.state.slider_state,
  () => {
    slider_state.value = store.state.slider_state;
    console.log(slider_state.value);
    if (regEmail.test(ForgetCount.value) && slider_state.value === true) {
      find_pwd_hint.value = '';
      isDisabled.value = true;
    }
  },
);
watch(
  () => ForgetCount.value,
  () => {
    if (ForgetCount.value === '') {
      store.state.slider_state = false;
    }
  },
);

// 找回密码页面校验邮箱
const verify_email = (e) => {
  const regEmail = new RegExp(/[a-zA-Z0-9]+([-_.][A-Za-zd]+)*@([a-zA-Z0-9]+[-.])+[A-Za-zd]{2,5}$/i);
  // console.log(e);
  if (e !== '') {
    if (regEmail.test(e)) {
      find_pwd_hint.value = '';
      if (slider_state.value === true) {
        find_pwd_hint.value = '';
        isDisabled.value = true;
      }
    } else {
      find_pwd_hint.value = '请输入正确的邮箱格式';
      isDisabled.value = false;
    }
  } else {
    find_pwd_hint.value = '请输入邮箱';
    isDisabled.value = false;
  }
};

onMounted(() => {
  verifyToken();
  // console.log(document.body.clientHeight);
  // console.log(document.body.clientWidth);

  // console.log(window.screen.availHeight);
  // console.log(window.screen.availWidth);
});
// 7天免登陆事件，直接进入系统
const pwd_free_login = async () => {
  let Login_free = localStorage.getItem('pwd_free_Login');
  let access_token = localStorage.getItem('access_token');
  // console.log(Login_free);
  if (Login_free === 'true') {
    const res = await http.post('/auth/tokenLogin', {
      access_token: access_token,
    });
    console.log(res);
    if (res.status === 0) {
      const ress = await http.post('/user/getUserMessage-user', {
        userId: localStorage.getItem('_id'),
      });
      localStorage.setItem('company', ress.user.company);
      localStorage.setItem('userName', ress.user.name);
      localStorage.setItem('userEmail', ress.user.email);
      localStorage.setItem('userGender', ress.user.gender);
      const routess = router.getRoutes();
      // routess.forEach((item) => {
      //     if (item.name != 'about' && item.name != 'login') {
      //         router.removeRoute(item.name);
      //     }
      // });
      let obj = [];
      ress.user.route.forEach((item, index) => {
        obj.push(item.component);
        console.log(item, 'item');
        const processedRoute = {};
        processedRoute.path = item.path;
        processedRoute.name = item.name;
        processedRoute['component'] = d[item.component];
        processedRoute.meta = item.meta;

        router.addRoute(processedRoute);
      });
      if (!ress.user.permission_list.includes('home')) {
        store.commit('Set_CurrentProjectName', '项目分享');
        router.push('/Project_sharing');
      } else {
        store.commit('Set_CurrentProjectName', '主页');
        router.push('/Home');
      }
    }
  }
};

onBeforeMount(() => {
  is_login_show();
  pwd_free_login();
});
onBeforeRouteUpdate((to, from) => {
  // 在当前路由改变，但是该组件被复用时调用
  // console.log(to, from);
  login_status.value = 0;
  is_sendCode.value = true;
  if (to.path === '/' && from.path === '/verify') {
    is_login.value = false;
    ClickForget.value = true;
    box.value = true;
  } else if (to.path === '/verify' && from.path === '/set_newpassword') {
    is_login.value = false;
    ClickForget.value = false;
    box.value = false;
  } else if (to.path === '/' && from.path === '/set_newpassword') {
    is_login.value = true;
    ClickForget.value = false;
    box.value = false;
  }
});
onBeforeRouteLeave((to, from) => {
  // 在导航离开渲染该组件的对应路由时调用
  // console.log(to, from);
  store.commit('set_CurrentPath', to.path);
});
</script>
<style lang="less">
@import url('../style/views/LoginView.less');
</style>
