import * as CryptoJS from 'crypto-js';

const key = CryptoJS.enc.Utf8.parse('cemsiKey');
const iv = CryptoJS.enc.Utf8.parse('cemsiv');

export default {
  encrypt(word) {
    const data = JSON.stringify(word);
    const utfData = CryptoJS.enc.Utf8.parse(data);
    const encrypted = CryptoJS.AES.encrypt(utfData, key, { iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.ciphertext.toString();
  },
  decrypt(word) {
    const encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    const base64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(base64Str, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedStr);
  },
};
