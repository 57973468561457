<template>
  <div id="shareQuestion">
    <div class="share_head">问卷标题</div>
    <div class="share_box">
      <div class="box_head">
        <span>问卷链接与二维码</span>
        <span class="tip">(问卷发布后，链接与二维码始终不变)</span>
      </div>
      <div class="box_main">
        <div class="main_text">答题链接</div>
        <div class="main_lj">
          <div class="lj_fz">{{ questionSrc }}</div>
          <div class="lj_but" @click="copyChange">复制链接</div>
        </div>
        <div class="main_text">答题二维码 <span class="col" @click="uploadImgChange">下载</span></div>
        <div class="box_code">
          <qrcode-vue :value="questionSrc" class="qrcode" ref="qrcodeRef"></qrcode-vue>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Select, CloseBold, Upload, ArrowRightBold } from '@element-plus/icons-vue'
// import { DomEditor } from '@wangeditor/editor'
import { ElMessage } from 'element-plus'
import { ArrowDown } from '@element-plus/icons-vue'
import QrcodeVue from 'qrcode.vue'
import clipboard3 from 'vue-clipboard3'
import { ref, onMounted, nextTick, computed, watch, reactive, shallowRef, onBeforeUnmount, provide } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { message } from '@/utils/resetMessage'
import draggable from 'vuedraggable'
import http from '@/utils/http'
import dayjs from 'dayjs'
import html2canvas from 'html2canvas'
//API接收
const store = useStore()
const router = useRouter()
const route = useRoute()
onMounted(() => {
  questionSrc.value = `https://cems.fuhuan.ltd/questionnaire/#/?id=${route.params._id}`
})
// 问卷链接
const questionSrc = ref('https://cems.fuhuan.ltd/questionnaire/#/')
// 二维码的ref
const qrcodeRef = ref(null)
// 点击下载
const uploadImgChange = async () => {
  await nextTick() // 确保二维码已经被正确渲染到页面上

  const qrcode = qrcodeRef.value.$el
  const canvasDataUrl = await html2canvas(qrcode).then(function (renderedCanvas) {
    return renderedCanvas.toDataURL()
  })

  const link = document.createElement('a')
  link.href = canvasDataUrl
  link.download = '问卷二维码'
  link.click()
}
// 点击复制链接
const { toClipboard } = clipboard3()
const copyChange = async () => {
  try {
    await toClipboard(questionSrc.value)
    // alert("复制的text: ")
    console.log('复制成功')
    message({
      message: '复制成功',
      showClose: true,
      type: 'success',
    })
  } catch (error) {
    //    alert("复制失败!!")
    console.log('复制失败 ')
    message({
      message: '复制失败',
      showClose: true,
      type: 'error',
    })
  }
}
</script>
<style lang="less" scoped>
@import url('@/style/views/QuestionnaireManagement/ShareQuestion.less');
</style>
