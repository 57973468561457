<template>
  <div id="TiVi">
    <div class="VolunteerTable_main">
      <div class="VolunteerTable">
        <div class="vxetable">
          <vxe-toolbar>
            <template #buttons>
              <div class="topList">
                <!-- <vxe-input
                  class="Search"
                  v-model="demo1.filterName"
                  type="search"
                  placeholder="请输入项目编号或志愿者编号进行搜索"
                  @Blur="searchEvent2">
                </vxe-input>
                <vxe-button @click="reset" class="Reset"><span class="reset-icon">重置</span></vxe-button> -->
                <vxe-button class="Show" :class="SetShow ? 'ShowHiden' : ''">
                  <span @click.stop="SetShowFunction" class="show-icon">显示</span>
                </vxe-button>
                <!-- <div class="btn_list">
                  <img class="uploading" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B68319%402x.png" alt="" />
                  <img class="export" @click="exportToExcel" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B682270%402x.png" alt="" />
                </div> -->
                <!-- </div> -->
              </div>
            </template>
          </vxe-toolbar>
          <div class="ContentBox">
            <vxe-table
              border
              stripe
              ref="xTable"
              height="678"
              show-overflow
              show-header-overflow
              row-id="_id"
              :scroll-y="{ mode: 'default' }"
              :data="tableData"
              :column-config="{ resizable: true }"
              :checkbox-config="{ reserve: true }"
              :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
              :loading="demo1.loading"
              @checkbox-all="selectAllChangeEvent1"
              @checkbox-change="selectChangeEvent1">
              <vxe-column type="checkbox" width="56" :fixed="demo1.colFixeds.col0"> </vxe-column>
              <vxe-column
                field="test_time"
                title="测试时间"
                :fixed="demo1.colFixeds.col0"
                :visible="true"
                :rowspan-key="'test_time'"
                width="146"
                :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '测试时间'">
                    <span class="name">测试时间</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('测试时间', $event)"></i></span>
                  </div>
                </template>
                <!-- <vxe-merge-cells :option="mergeOptions"></vxe-merge-cells> -->
              </vxe-column>
              <vxe-column
                field="project_number"
                title="项目编号"
                :fixed="demo1.colFixeds.col0"
                :visible="true"
                width="150"
                :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '项目编号'">
                    <span class="name">项目编号</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('项目编号', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                field="volun_number"
                title="志愿者编号"
                :fixed="demo1.colFixeds.col0"
                :visible="true"
                width="160"
                :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '志愿者编号'">
                    <span class="name">志愿者编号</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('志愿者编号', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="num1" title="照片" :fixed="demo1.colFixeds.col0" :visible="true" width="72" :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '照片'">
                    <span class="name">照片</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('照片', $event)"></i></span>
                  </div>
                </template>
                <!-- <template #default="{ row }">
                                    <div>{{ rowIndex + 1 }}</div>
                                </template> -->
              </vxe-column>
              <vxe-column field="ROI" title="ROI" :fixed="demo1.colFixeds.col0" :visible="true" width="172" :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header roi" v-if="column.title === 'ROI'">
                    <!-- <span class="name">ROI</span> -->
                    <div class="roi_select">
                      <el-select v-model="ROIIndex" placeholder="请选择要展示的ROI">
                        <el-option v-for="(item, index) in AOIArr" :key="index" :label="item.label" :value="item.value" />
                      </el-select>
                    </div>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('ROI', $event)"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <div>{{ row.path1 }}</div>
                </template>
              </vxe-column>
              <vxe-column
                :field="'Mean' + ROIIndex"
                title="平均值"
                :fixed="demo1.colFixeds.col1"
                :visible="true"
                width="144"
                :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '平均值'">
                    <span class="name">平均值</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Mean', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                field="Median1"
                title="中值"
                :fixed="demo1.colFixeds.col1"
                :visible="true"
                width="144"
                :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '中值'">
                    <span class="name">中值</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Mean', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                field="Area1"
                title="区域值"
                :fixed="demo1.colFixeds.col1"
                :visible="true"
                width="144"
                :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '区域值'">
                    <span class="name">区域值</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Mean', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                field="SD1"
                title="标准偏差"
                :fixed="demo1.colFixeds.col1"
                :visible="true"
                width="144"
                :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '标准偏差'">
                    <span class="name">标准偏差</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Mean', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                field="Min1"
                title="最低要求"
                :fixed="demo1.colFixeds.col1"
                :visible="true"
                width="144"
                :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '最低要求'">
                    <span class="name">最低要求</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Mean', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column field="Max1" title="最大" :fixed="demo1.colFixeds.col1" :visible="true" width="144" :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '最大'">
                    <span class="name">最大</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Mean', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                field="Time1"
                title="经过时间"
                :fixed="demo1.colFixeds.col1"
                :visible="true"
                width="144"
                :edit-render="{ placeholder: '-----' }">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === '经过时间'">
                    <span class="name">经过时间</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Mean', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column title="操作" align="center" width="100" min-width="99" fixed="right" :visible="true" :resizable="false">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Operation-header" style="display: flex; width: 100px; justify-content: space-between" v-if="column.title === '操作'">
                    <span class="name">操作</span>
                    <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <!-- <vxe-button class="detail" @click="ChangeStatus('标记无效',row)">标记无效</vxe-button> -->
                  <!-- <span class="More" id="CMoreOption" @click="ClickMethods(row, $event)">···</span> -->
                  <!-- <el-tooltip class="box-item" effect="dark" content="标记无效" placement="top"> -->
                  <el-button class="copy" @click="isShowRemark(isRemark, row)">{{ row.remark ? row.remark : '标记无效' }}</el-button>
                  <!-- </el-tooltip> -->
                </template>
              </vxe-column>

              <template #empty>
                <span style="color: red">
                  <p>没有更多数据了！</p>
                </span>
              </template>
            </vxe-table>
            <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
              <div class="contentBox" id="MoreOptions">
                <ul class="list" id="MoreOptions">
                  <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                    <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                  </li>
                  <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                    <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                  </li>
                  <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')" :class="!CancelFixed ? 'light' : ''">
                    <!-- <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" /> -->
                    <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" v-show="CancelFixed" />
                    <img src="https://newstore.vynior.com/cems_web/%E9%94%81%E5%AE%9A%402xs.png" alt="" v-show="!CancelFixed" />固定
                  </li>
                  <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" style="color: #bfbfbf" :class="CancelFixed ? 'light' : ''">
                    <!-- <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" />取消固定 -->
                    <img src="https://newstore.vynior.com/cems_web/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="!CancelFixed" />
                    <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="CancelFixed" />
                    取消固定
                  </li>

                  <li id="MoreOptions" @click="HiddenColumn(FixedIndex)"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li>
                </ul>
              </div>
            </div>
            <p>
              <vxe-pager
                class="Buttons"
                background
                @page-change="pageChange"
                v-model:current-page="page5.currentPage"
                v-model:page-size="page5.pageSize"
                :total="page5.totalResult"
                :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
                <template #left>
                  <div class="Total">
                    已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                    >条/共{{ page5.totalResult }}条
                  </div>
                </template>
              </vxe-pager>
            </p>
            <!-- 显示功能弹窗 -->
            <div class="SetShowsBox" ref="ShowBox" v-show="SetShow" id="SetShowsBoxs">
              <div class="Titles" id="SetShowsBoxs">字段显示</div>
              <div class="ContentBoxs" :class="AllHidenHeader.length == 0 ? 'Emptys' : ''" id="SetShowsBoxs">
                <span class="Empty" v-if="AllHidenHeader.length == 0" id="SetShowsBoxs">暂无隐藏字段</span>
                <div class="ListBox" v-if="AllHidenHeader.length != 0" id="SetShowsBoxs">
                  <div
                    class="List"
                    v-for="(item, index) in AllHidenHeader"
                    :key="index"
                    @click="ShowCheck[index] = !ShowCheck[index]"
                    id="SetShowsBoxs">
                    <div class="item" id="SetShowsBoxs">{{ item.title }}</div>

                    <div class="Active" v-show="ShowCheck[index]" id="SetShowsBoxs">
                      <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110500%402x.png" alt="" />
                    </div>
                  </div>
                  <div
                    class="Button"
                    @click="ConfirmRecover"
                    id="SetShowsBoxs"
                    :class="AllHidenHeader.length == 1 ? 'Buttons' : AllHidenHeader.length == 2 ? 'Buttonss' : ''">
                    确认
                  </div>
                </div>
              </div>
            </div>
            <!-- !表头字段设置 -->
            <div class="SetTableHeader" v-if="SetTableHeader">
              <div class="main">
                <div class="Header">
                  <div class="text">表头字段设置</div>
                </div>
                <!-- 设置表头的数据盒子 -->
                <div class="SetBox">
                  <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
                  <div class="LeftBox">
                    <div class="text-header">仪器测试记录列表预设字段</div>
                    <div class="ProjectRowField">
                      <!-- <div class="text-header1">【仪器基本信息】</div> -->
                      <!-- 复选框数组 -->
                      <div class="CheckBox">
                        <div
                          class="Checkbox"
                          v-for="(item, index) in SetInstrumentListArr.slice(0, 37)"
                          :key="index"
                          :class="defaultHeader?.indexOf(item.lable) != -1 ? 'default' : ''">
                          <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                        </div>
                      </div>
                    </div>
                    <!-- 【系统信息】 -->
                    <!-- <div class="ProjectRecruitMSG">
              <div class="text-header2">【仪器校准信息】</div>
           
              <div class="CheckBox">
                <div class="Checkbox" v-for="(item, index) in SetInstrumentListArr.slice(18, 22)" :key="index">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 18)" />
                </div>
              </div>
            </div> -->

                    <div class="Self-Defined">自定义扩展字段</div>
                  </div>
                  <!-- 右边盒子 拖动 -->
                  <div class="RightBox">
                    <div class="text-header">列表页展示字段</div>
                    <div style="font-size:16px; color='black';margin-top:348px">此功能正在开发中,敬请期待</div>
                  </div>
                </div>
                <!-- 底部 -->
                <div class="Bottom">
                  <div class="add">
                    <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
                  </div>
                  <div class="recover" @click="RecoverData">
                    <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
                  </div>
                  <div class="cancel" @click="CancelShowHiden">
                    <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" />
                  </div>
                  <div class="save" @click="ShowHidenColumn">
                    <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <!-- 切换状态弹窗 -->
            <div class="ChangeS" v-if="isShowToInvalid">
              <el-dialog v-model="isShowToInvalid" width="30%">
                <template #header="{ titleId, titleClass }">
                  <div class="my-header">
                    <div :id="titleId" :class="titleClass">确认将该数据标记为无效数据吗？</div>
                  </div>
                </template>
                <div class="Body">
                  <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />将数据标记无效后可以在操作栏恢复为有效数据
                </div>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button class="Cancel" @click="isShowToInvalid = false">取消</el-button>
                    <el-button class="Delete" @click="ChangeStatus(status, row)"> 确认 </el-button>
                  </span>
                </template>
              </el-dialog>
            </div>
            <div class="ChangeS" v-if="isShowToEffective">
              <el-dialog v-model="isShowToEffective" width="30%">
                <template #header="{ titleId, titleClass }">
                  <div class="my-header">
                    <div :id="titleId" :class="titleClass">确认将该数据恢复为有效数据吗？</div>
                  </div>
                </template>
                <div class="Body">
                  <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />将数据恢复有效后可以在操作栏标记为无效数据
                </div>
                <template #footer>
                  <span class="dialog-footer">
                    <el-button class="Cancel" @click="isShowToEffective = false">取消</el-button>
                    <el-button class="Delete" @click="ChangeStatus(status, row)"> 确认 </el-button>
                  </span>
                </template>
              </el-dialog>
            </div>
          </div>
          <div
            class="EmptyPage"
            style="
              width: 100%;
              height: 100%;
              background-color: #f8f8f8f8;
              /* padding: 8px 12px; */
              display: flex;
              justify-content: center;
              align-items: center;
            "
            v-show="tableData.length == 0">
            <div
              class="pageContent"
              style="
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background: #ffffff;
                border-radius: 2px 2px 2px 2px;
                opacity: 1;
              ">
              <img src="https://newstore.vynior.com/cems_web/%E6%9A%82%E6%97%A0%E6%95%B0%E6%8D%AE111.png" alt="" />
              <span style="font-size: 16px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #626366"
                >暂无数据</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { Document, Menu as IconMenu, Location, Setting } from '@element-plus/icons-vue';
import InstrumentChange from '@/components/InstrumentMangement/InstrumentChange.vue';
import createInstrument from '@/components/InstrumentMangement/createInstrument.vue';
import { defineComponent, isReactive, ref, reactive, nextTick, toRaw, toRefs, onMounted, watchEffect, onBeforeUnmount, watch, computed } from 'vue';
import { VxeTableInstance } from 'vxe-table';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { CaretLeft, CaretRight, Upload } from '@element-plus/icons-vue';
import { message } from '@/utils/resetMessage';
import dayjs from 'dayjs';
import http from '@/utils/http';
import XLSX from 'xlsx';
import FileSaver from 'file-saver';
import { replaceEmptyValues } from '@/utils/emptyArray';
// !表单空白处理
const tableData = computed(() => {
  return replaceEmptyValues(demo1.tableData);
});

const store = useStore();
const router = useRouter();
const xTable = ref();
const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};
const isRemark = ref('标记无效');
onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  loading: true,
  list: [],
  gender: '',
  age: '',
  maxLength: '',
});

// sort排序字段
let SORT = ref({ createdAt: -1 });
const roiCount = ref();
const DataArr = ref();
const Mean = ref();
// 模糊搜索 判断是否是第一次
const EventSearch = ref(false);
// 模糊Age 判断是否是第一次
const AgeSearch = ref(false);
// 模糊Gender 判断是否是第一次
const GenderSearch = ref(false);
//复选框数组
var selectedRows = reactive([]);
const checkedArr = ref([]);

// 复选框点击事件
const selectChangeEvent1 = (row) => {
  // console.log('点击的id:', row.rowid);
  console.log('row :>> ', row);

  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records;

  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid);
  } else {
    let index = checkedArr.value.indexOf(row.rowid);
    checkedArr.value.splice(index, 1);
  }
  console.log('最新:', checkedArr.value);
};
// table组件数据
let totalCount = ref(0);
const SetTableHeader = ref(false);
// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([]);
//表头字段设置--->设置表头的勾选数据
let SetInstrumentListArr = ref([
  { fixed: '', field: 'test_time', checked: true, lable: '测试时间' },
  { fixed: '', field: 'Probe', checked: true, lable: '项目编号' },
  { fixed: '', field: 'volun_number', checked: true, lable: '志愿者编号' },
  { fixed: '', field: 'num1', checked: true, lable: '照片' },
  { fixed: '', field: 'ROI', checked: true, lable: 'ROI' },
  { fixed: '', field: "'Mean' + ROIIndex", checked: true, lable: '平均值' },
  { fixed: '', field: 'Median1', checked: true, lable: '中值' },
  { fixed: '', field: 'Area1', checked: true, lable: '区域值' },
  { fixed: '', field: 'SD1', checked: true, lable: '标准偏差' },
  { fixed: '', field: 'Min1', checked: true, lable: '最低要求' },
  { fixed: '', field: 'Max1', checked: true, lable: '最大' },
  { fixed: '', field: 'Time1', checked: true, lable: '经过时间' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
]);
// 表头字段设置--->恢复默认字段
let DefaultHeaderData = ref([
  { fixed: '', field: 'test_time', checked: true, lable: '测试时间' },
  { fixed: '', field: 'Probe', checked: true, lable: '项目编号' },
  { fixed: '', field: 'volun_number', checked: true, lable: '志愿者编号' },
  { fixed: '', field: 'num1', checked: true, lable: '照片' },
  { fixed: '', field: 'ROI', checked: true, lable: 'ROI' },
  { fixed: '', field: "'Mean' + ROIIndex", checked: true, lable: '平均值' },
  { fixed: '', field: 'Median1', checked: true, lable: '中值' },
  { fixed: '', field: 'Area1', checked: true, lable: '区域值' },
  { fixed: '', field: 'SD1', checked: true, lable: '标准偏差' },
  { fixed: '', field: 'Min1', checked: true, lable: '最低要求' },
  { fixed: '', field: 'Max1', checked: true, lable: '最大' },
  { fixed: '', field: 'Time1', checked: true, lable: '经过时间' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
]);
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  console.log(checked ? '所有勾选事件' : '所有取消事件', records);
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id);
      }
    }
    console.log('最新:', checkedArr.value);
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = [];

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id);
    });

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item);
    });
    console.log('最新:', checkedArr.value);
  }
};
// 模糊查询

const searchEvent2 = async () => {
  demo1.loading = true;
  page5.currentPage = 1;
  reqProjectList();
};
let CancelFixed = ref(false); //控制每个表头下拉箭头 固定和取消固定的高亮
//  More -->下拉选择中的固定方法
const toggleFixedColumn = (index, type) => {
  showIdOptions.value = false;
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
const toggleFixedColumns = (index, type) => {
  showIdOptions.value = false;
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
// More -->升序/降序
const AscendingSort = async (index, type) => {
  console.log('我的type: :>> ', type);
  demo1.loading = true;

  // console.log('我的下标是: :>> ', index);
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  // console.log('固定信息:', tableColumns);
  const ColumnId = tableColumns[index].field;
  const sorts = {};
  if (type == 'Asc') {
    sorts[ColumnId] = 1;
  } else {
    sorts[ColumnId] = -1;
  }

  SORT.value = sorts;
  reqProjectList();
  console.log(SORT.value, '123456789990');
};
const reset = () => {
  // demo1.age = '';666665
  // demo1.gender = '';
  demo1.filterName = '';
  page5.pageSize = 10;
  page5.currentPage = 1;
  reqProjectList();
  // EventSearch.value = false;
  // GenderSearch.value = false;
  // AgeSearch.value = false;
  // ClickActive.value = 0;
  //   reqProjectList();
};
//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
});
// 点击表头的下拉箭头
const OperationBox = ref(null); //获取更多操作盒子的ref
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1);
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  //   //console.log('value :>> ', value);
  const table = xTable.value;
  const FixedList = table.getColumns();

  FixedList.forEach((item, index) => {
    console.log(item);
    if (item.title == value) {
      FixedIndex.value = index;
      if (item.fixed) {
        CancelFixed.value = false;
        // console.log('有值 :>> ');
      } else {
        CancelFixed.value = true;
        // console.log('无值 :>> ');
      }
    }
  });
  //console.log('FixedIndex下标为 :>> ', FixedIndex);

  showIdOptions.value = false;
  showIdOptions.value = true;
  // 设置位置
  const Event = event;
  OperationBox.value.style.left = event.clientX - 370 + 'px';
};
// 分页--->分页
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false;
  //console.log('点击点击 :>> ');
};
//分页--->跳转页面显示数据处理
const pageChange = async () => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  reqProjectList();
};
// ROI选中的值
const ROIIndex = ref(1);
// AOI下拉数组
const AOIArr = ref([]);
//->全部数据
const reqProjectList = async () => {
  const res = await http.post('/instrument/fetchTiVi700Record-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
  });
  demo1.loading = false;
  console.log(res, '获取TIVI的全部数据');
  if (res.status == 0) {
    AOIArr.value = [];
    demo1.loading = false;
    demo1.tableData = res.result;
    page5.totalResult = res.totalCount;
    demo1.maxLength = res.maxLength;
    for (let a = 1; a <= res.maxLength; a++) {
      let obj = {};
      obj.label = 'ROI' + a;
      obj.value = a;
      AOIArr.value.push(obj);
    }
    console.log(AOIArr.value, 'AOIArr');
    // roiCount.value = res.maxLength;
    // // DataArr.value = res.result[0].results[0].datas;

    // console.table('res :>> ', res);
    // console.log(DataArr.value);
    // Mean.value = DataArr.value.map((item) => item.Mean);
    // console.log('Mean:', Mean.value);
  }
};
// 检查是否有本地数据()
const setHeaderColumn = () => {
  if (localStorage.getItem('MPA580ShowHiddenArr') == null) {
    // ////console.log('"第一次" :>> ', '第一次');
  } else {
    // ////console.log('"第二次" :>> ', '第二次');
    SetInstrumentListArr.value = JSON.parse(localStorage.getItem('MPA580ShowHiddenArr'));
  }
};
// 表头字段设置----> 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true;
  TemporaryData.value = JSON.parse(JSON.stringify(SetInstrumentListArr.value));

  //console.log('SetTableHeader', SetTableHeader.value);
};
// 表头字段设置----> 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false;
  SetInstrumentListArr.value = JSON.parse(JSON.stringify(TemporaryData.value));
};
//  表头字段设置----> 恢复默认字段
const RecoverData = () => {
  console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value);
  // let defaultdata = readonly(DefaultHeaderData)
  SetInstrumentListArr.value = JSON.parse(JSON.stringify(DefaultHeaderData.value));
};
// 默认展示的字段/不可取消
let defaultHeader = ref(['操作']);
// 表头阻断设置---->的点击复选框
const CheckHeader = (i) => {
  console.log('SetTableHeaderArr(value.checked) :>> ', SetInstrumentListArr.value[i], i);
  // console.log('isReactive(SetTableHeaderArr) :>> ', isRef(SetTableHeaderArr));

  if (defaultHeader.value.indexOf(SetInstrumentListArr.value[i].lable) != -1) {
    message({
      message: `${SetInstrumentListArr.value[i].lable}不能删除`,
      Showclose: true,
      type: 'error',
    });
    SetInstrumentListArr.value[i].checked = true;
  }
};
// ShowHidenColumn 设置column的的列表(确认按钮)
const ShowHidenColumn = () => {
  const $table = xTable.value;
  const column = $table.getColumns();

  //console.log('$table.getColumns :>> ', $table.getColumns());

  for (let i = 0; i < SetInstrumentListArr.value.length; i++) {
    if (SetInstrumentListArr.value[i].checked == true) {
      // //console.log('object :>> ', SetInstrumentListArr[i].field);
      $table.showColumn(SetInstrumentListArr.value[i].field);
    }
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetInstrumentListArr.value[i].lable) {
        if (SetInstrumentListArr.value[i].checked == false) {
          $table.hideColumn(column[j].field);
        }
      }
    }
  }

  $table.refreshColumn();
  //console.log('$table.getColumns :>> ', $table.getColumns());
  SetTableHeader.value = false;
};
//标记无效、恢复有效
const isShowToInvalid = ref(false);
const isShowToEffective = ref(false);
const Remark = ref('');
const isShowRemark = (status, row) => {
  Remark.value = row;
  if (status == '标记无效') {
    row.remark = '标记无效';
    isShowToInvalid.value = true;
    isShowToEffective.value = false;
  } else {
    row.remark = '恢复有效';
    isShowToEffective.value = true;
    isShowToInvalid.value = false;
  }
};
const ChangeStatus = async () => {
  // console.log('调用 ChangeStatus 时传入的 status 值为:', status)
  if (Remark.value.remark == '标记无效') {
    const res = await http.post('/instrument/settivi700invalid', {
      _id: Remark.value._id,
      num: Remark.value.num,
      ROIindex: 'R0I' + ROIIndex.value + '',
      invalid: true,
    });
    if (res.status == 0) {
      // status='恢复有效'
      Remark.value.remark = '恢复有效';
      isRemark.value = '恢复有效';
      console.log(Remark.value.remark);
      isShowToInvalid.value = false;

      // console.log(status+"变了");
    }
  } else if (Remark.value.remark == '恢复有效') {
    const res = await http.post('/instrument/settivi700invalid', {
      _id: Remark.value._id,
      num: Remark.value.num,
      ROIindex: 'R0I' + ROIIndex.value + '',
      invalid: false,
    });
    if (res.status == 0) {
      // status='标记无效'
      Remark.value.remark = '标记无效';
      isRemark.value = '标记无效';
      console.log(Remark.value.remark);
      isShowToEffective.value = false;
    }
  }
};

onMounted(() => {
  reqProjectList();
  //处理点击升降序 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
    }
  });
  //处理显示 功能
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false;
      }
    }
  });
});
// 将相同的列数据合并为一行
// const rowspanMethod=({ row, _rowIndex, column, visibleData })=>{
//   // 需要合并的列字段
//   let fields = ['test_time','project_number','volun_number','num1','ROI'];
//   const cellValue = row[column.property];
//   console.log(column.property)
//   if (cellValue && fields.includes(column.property)) {
//     const prevRow = visibleData[_rowIndex - 1];
//     let nextRow = visibleData[_rowIndex + 1];
//     if (prevRow && prevRow[column.property] === cellValue) {
//       return { rowspan: 0, colspan: 0 };
//     } else {
//       let countRowspan = 1;
//       while (nextRow && nextRow[column.property] === cellValue) {
//         nextRow = visibleData[++countRowspan + _rowIndex];}
//         if (countRowspan > 1) {return { rowspan: countRowspan, colspan: 1 };
//       }
//     }
//   }
// }
// const mergeOptions = {
//   col: ['test_time'],
//   rowspanMethod({ row, rowIndex, columnIndex, $rowIndex, $columnIndex }) {
//     if (rowIndex === 0 || row.test_time !== this.mergedTest_time) {
//       this.mergedTest_time = row.test_time;
//       return 1;
//     } else {
//       return 0;
//     }
//   }
// };

// 全部显示里被隐藏的
let AllHidenHeader = ref([]);
const SetShow = ref(false);
const ShowBox = ref(null); //获取Show盒子的ref
const ShowCheck = ref([]);
// 点击显示
const SetShowFunction = (event) => {
  SetShow.value = false;
  SetShow.value = true;
  // console.log('event :>> ', event, ShowBox.value);
  ShowBox.value.style.left = event.clientX - 330 + 'px';
  // ShowBox.value.style.top = event.clientY - 80 + 'px';
};
// note 组件销毁
onBeforeUnmount(() => {
  const table = xTable.value;
  console.log('组件销毁时的数值 :>> ', table);
  const FixedList = table.getColumns();
  // console.log('销毁时的列顺序', FixedList);
  for (let i = 1; i < FixedList.length; i++) {
    for (let j = 0; j < SetInstrumentListArr.value.length; j++) {
      if (FixedList[i].field == SetInstrumentListArr.value[j].field) {
        SetInstrumentListArr.value[j].fixed = FixedList[i].fixed;
      }
    }
  }

  localStorage.setItem('TiVi700HiddenArr', JSON.stringify(SetInstrumentListArr.value));
});
</script>
<style lang="less" scoped>
@import url('@/style/components/Fullinstrument/TiVi700Instrument.less');
</style>
