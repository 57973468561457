<template>
  <!-- <div id="expert_dialog" > -->
  <el-drawer :model-value="is_dialogShow" :show-close="false" :close-on-click-modal="false" title="I am the title" id="expert_dialog">
    <template #header>
      <div class="expert_title">
        <span v-if="!props.ChangeTite">新建专家</span>
      </div>
    </template>
    <template #default>
      <div class="expert_main">
        <!-- 基础信息 -->
        <div class="main_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">基础信息</div>
          </div>
          <div class="info_main">
            <div class="list_line_top">
              <div class="list_line">
                <!-- 专家编号 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span class="text_top">*</span>
                      专家编号
                    </div>
                    <div class="main">
                      <el-input
                        v-model="inputNumberValue"
                        class="inp"
                        placeholder="请输入专家编号"
                        suffix-icon=""
                        :class="errObj.number ? 'inp_err' : ''"
                        @focus="errObj.number = false" />
                      <div class="err_text">
                        <span v-if="errObj.number">专家编号不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 专家性别 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span class="text_top">*</span>
                      专家性别
                    </div>
                    <div class="main">
                      <el-select class="select" v-model="sex_val" placeholder="请选择" :class="is_sexError ? 'inp_err' : ''">
                        <el-option v-for="item in sexArr" :key="item" :label="item" :value="item" />
                      </el-select>
                      <div class="err_text sex_error" v-if="is_sexError">
                        <span>专家性别不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 专家状态 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span class="text_top">*</span>
                      专家状态
                    </div>
                    <div class="main">
                      <el-select v-model="status_val" placeholder="请选择" class="select" :class="is_statusError ? 'inp_err' : ''">
                        <el-option v-for="item in statusArr" :key="item" :label="item" :value="item" />
                      </el-select>
                      <div class="err_text status_error" v-if="is_statusError">
                        <span>专家状态不可为空</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 标签 -->
                <div class="info">
                  <div class="list_content" id="left_content">
                    <div class="text">
                      <span>标签</span>
                    </div>
                    <div class="main">
                      <el-select
                        v-model="labelArrValue"
                        class="select"
                        placeholder="请选择"
                        size="large"
                        multiple
                        collapse-tags
                        collapse-tags-tooltip>
                        <el-option
                          v-for="item in labelArr"
                          :key="item"
                          :label="item"
                          :value="item"
                          :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                      </el-select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="list_line">
                <!-- 专家姓名 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <span class="text_top">*</span>
                      专家姓名
                    </div>
                    <div class="main">
                      <el-input
                        v-model="inputNameValue"
                        class="inp"
                        placeholder="请输入专家姓名"
                        suffix-icon=""
                        :class="errObj.name ? 'inp_err' : ''"
                        @focus="errObj.name = false" />
                      <div class="err_text">
                        <span v-if="errObj.name">{{ nameError }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- 联系方式 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <span class="text_top">*</span>
                      <span>联系方式</span>
                    </div>
                    <div class="main">
                      <el-input
                        v-model="inputPhoneValue"
                        class="inp"
                        placeholder="请输入联系方式"
                        suffix-icon=""
                        :class="errObj.phone ? 'inp_err' : ''"
                        @focus="errObj.phone = false" />
                      <div class="err_text">
                        <span v-if="errObj.phone">{{ phoneError }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 注册时间 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text">
                      <span>注册时间</span>
                    </div>
                    <div class="main">
                      <div class="val">{{ registrationTime }}</div>
                    </div>
                  </div>
                </div>
                <!-- 备注 -->
                <div class="info">
                  <div class="list_content">
                    <div class="text" id="shortTe">
                      <span>备注</span>
                    </div>
                    <div class="main">
                      <el-input maxlength="20" v-model="inputNotesValue" class="inp" placeholder="请输入备注" suffix-icon="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 专家介绍 -->
            <div class="list_line">
              <div class="info">
                <div class="list_content">
                  <div class="text">
                    <span>专家介绍</span>
                  </div>
                  <div class="main">
                    <el-input
                      v-model="inputIntroduceValue"
                      class="bigInp"
                      type="textarea"
                      resize="none"
                      placeholder="请输入专家介绍"
                      suffix-icon="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 文件 -->
        <div class="upload_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">文件</div>
          </div>
          <div class="files">
            <el-upload class="upload_xlsx" ref="videoRef" :http-request="uploadsFile" :show-file-list="false" :disabled="Volunteereditor">
              <img src="https://newstore.vynior.com/cems_web/%E4%B8%8A%E4%BC%A0%402x.png" alt="" />
              <span>上传附件</span>
            </el-upload>
            <!-- <el-upload
              class="div"
              ref="videoRef"
              :http-request="uploadFile"
              :on-preview="handlePreviews"
              :on-remove="handleRemoves"
              :file-list="fileLists"
              :show-file-list="false">
              <el-button type="primary" plain>
                <img class="uploadIcon" src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%E7%AE%AD%E5%A4%B4%402x.png" alt="" />
                上传附件
              </el-button>
            </el-upload> -->
          </div>
          <div class="Table1" v-show="attachmentList?.length">
            <el-table :data="attachmentList" border stripe style="width: 688px">
              <el-table-column type="index" label="序号" width="56" min-width="55" />
              <el-table-column prop="fileName" label="文件名称" width="260" min-width="259">
                <template #default="scope">
                  <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                    {{ scope.row.fileName }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="upLoadTime" label="上传时间" width="208" min-width="207">
                <template #default="scope">
                  <div style="color: #626366">
                    {{ formatTime(scope.row.uploadTime) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="164" min-width="163">
                <template #default="scope">
                  <!-- <el-button link type="primary" size="small">预览</el-button> -->
                  <el-button
                    link
                    type="primary"
                    @click="downloadTheFiles(scope.row)"
                    size="small"
                    style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                    >下载</el-button
                  >
                  <el-button
                    link
                    type="primary"
                    @click="deleteTheFiles(scope.row.uuid)"
                    size="small"
                    style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <!-- 参与项目信息 -->
        <div class="main_info">
          <div class="info_title">
            <div class="title_color"></div>
            <div class="title_text">参与项目信息</div>
            <div class="remindText"><span>在【项目】-【专家】中链入后自动填入</span></div>
          </div>
          <div class="project_table">
            <el-table :data="tableData" border style="width: 998px">
              <el-table-column prop="orderNumber" label="序号" width="56" />
              <el-table-column prop="projectNumber" label="项目编号" width="482" />
              <el-table-column prop="projectName" label="项目名称" width="460" />
            </el-table>
          </div>
        </div>
      </div>
    </template>
    <template #footer>
      <div style="flex: auto">
        <el-button @click="handleClose">取消</el-button>
        <el-button v-if="!props.ChangeTite" type="primary" @click="createdexpert"> 确定 </el-button>
      </div>
    </template>
  </el-drawer>

  <!-- </div> -->
</template>
<script setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import { defineEmits, defineProps, defineComponent, ref, reactive, nextTick, toRaw, toRefs, onMounted, computed, watch } from 'vue'
import cityData from '@/utils/citydata'
import { Select, CloseBold, Upload } from '@element-plus/icons-vue'
import http from '@/utils/http'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import data from '@/utils/CurrentDate'
let store = useStore()

let emits = defineEmits(['update:modelValue'])
//关闭的点击事件
let handleClose = () => {
  emits('update:modelValue', false)
  // is_dialogShow.value = false
}
let props = defineProps({
  ChangeTite: Boolean,
})
// const tableData = [
//   {
//     orderNumber: "001",
//     projectNumber: "HT-CD-202x-xxx-R-1-xxx",
//     projectName: "4周功效测试-V1",
//   },
//   {
//     orderNumber: "002",
//     projectNumber: "HT-CD-202x-xxx-R-1-xxx",
//     projectName: "4周功效测试-V1",
//   },
// ];
//新建专家显示
let is_dialogShow = ref(false)
// 专家名称
let inputNameValue = ref('')
// 专家编号
let inputNumberValue = ref('')
// 联系方式
let inputPhoneValue = ref('')
// 专家性别
let sexArr = ref(['男', '女'])
//测专家性别选中的值
let sex_val = ref('')
// 专家状态
let statusArr = ref(['全职', '兼职'])
//测专家状态选中的值
let status_val = ref('')
// 专家介绍
let inputIntroduceValue = ref('')
// 注册时间
let registrationTime = ref('系统自动生成')
// 备注
let inputNotesValue = ref('')
//专家标签
let labelArr = ref([])
let inputLableValue = ref('')
let labelArrValue = ref([])
// let labelArrValueChange = () =>{
// if (labelArrValue.value==1)
// }

// // 获取管理员信息
// let getUserOptions = async () => {
//   let res = await http.post("/user/findOptions-user", {});
//   console.log(res, "获取的所有用户信息");
//   if (res.status == 0) {
//     userOptions.value = res.userOptions;
//   } else {
//     console.log("获取失败");
//   }
// };
const formatTime = (timeString) => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')
  const currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  return currentDateTime
}
//必选项是否为空提示
let errObj = reactive({
  number: false,
  name: false,
  phone: false,
  status: false,
  sex: false,
})
// 判断专家编号是否填写
let is_inputNumber = () => {
  if (inputNumberValue.value === '') {
    errObj.number = true
  } else {
    errObj.number = false
  }
}
watch(
  () => inputNumberValue.value,
  () => {
    is_inputNumber()
  }
)
// 判断专家姓名
let nameError = ref('')
let is_inputName = () => {
  if (inputNameValue.value === '') {
    errObj.name = true
    nameError.value = '专家姓名不可为空'
  } else if (inputNameValue.value.length < 2 || inputNameValue.value.length > 10) {
    errObj.name = true
    nameError.value = '专家姓名不符合格式'
  } else {
    nameError.value = ''
    errObj.name = false
  }
}

watch(
  () => inputNameValue.value,
  () => {
    is_inputName()
  }
)
// 判断专家电话
const phone = /^1([358][0-9]|4[579]|66|7[0135678]|9[89])[0-9]{8}$/
let phoneError = ref('')
let is_inputPhone = () => {
  if (inputPhoneValue.value === '') {
    phoneError.value = '联系电话不可为空'
    errObj.phone = true
  } else if (!phone.test(inputPhoneValue.value)) {
    phoneError.value = '联系电话不符合格式'
    errObj.phone = true
  } else {
    phoneError.value = ''
    errObj.phone = false
  }
}
watch(
  () => inputPhoneValue.value,
  () => {
    is_inputPhone()
  }
)
// 判断专家性别是否勾选
let sex_error = ref('专家性别不可为空')
let is_sexError = ref(false)
let is_inputSex = () => {
  if (sex_val.value === '') {
    sex_error.value = '专家性别不可为空'
    is_sexError.value = true
  } else {
    sex_error.value = ''
    is_sexError.value = false
  }
}
watch(
  () => sex_val.value,
  () => {
    is_inputSex()
  }
)
// 判断专家状态是否勾选
let status_error = ref('专家状态不可为空')
let is_statusError = ref(false)
let is_inputStatus = () => {
  if (status_val.value === '') {
    status_error.value = '专家状态不可为空'
    is_statusError.value = true
  } else {
    status_error.value = ''
    is_statusError.value = false
  }
}
watch(
  () => status_val.value,
  () => {
    is_inputStatus()
  }
)
// 上传附件返回信息
const attachmentList = ref([])
// 上传附件事件
const uploadsFile = async (params) => {
  const file = params.file
  const formData = new FormData()
  formData.append('file', file)
  formData.append('type', 'attachment')
  const response = await http.post('/upload/file-upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  })
  const { status, file: fileData } = response
  if (status === 0) {
    attachmentList.value.push(fileData)
    ElMessage({
      message: '附件上传成功',
      // grouping: true,
      showClose: true,
      type: 'success',
    })
  } else {
    ElMessage({
      message: '附件上传失败，请稍后再试',
      // grouping: true,
      showClose: true,
      type: 'warning',
    })
  }
}
const downloadTheFiles = async (data) => {
  console.log('附件-下载-data', data)
  console.log('点击了附件下载')
  const { downloadPath, filePath } = data

  const repsonse = await http.post(
    'https://cems.fuhuan.ltd/api/upload/attachmentDownload',
    { downloadPath: downloadPath ? downloadPath : filePath },
    { responseType: 'arraybuffer' }
  )

  const blob = new Blob([repsonse], {
    type: 'application/x-zip-compressed;charset=utf-8',
  })
  let name = '附件下载.zip'
  const downloadElement = document.createElement('a')
  let classificationhref = window.URL.createObjectURL(blob)
  downloadElement.href = classificationhref
  downloadElement.download = `${name}`
  document.body.appendChild(downloadElement)
  downloadElement.click()
  document.body.removeChild(downloadElement)
  window.URL.revokeObjectURL(classificationhref)
}
// 被删除附件（已保存）
const deleteFiles = ref([])
// 删除附件
const deleteTheFiles = (data) => {
  console.log('data iiiiiidddddd:>> ', data)
  attachmentList.value.forEach((attachment, index) => {
    console.log(attachment)
    if (attachment.uuid == data) {
      if (attachment.downloadPath) {
        console.log('删我')
        deleteFiles.value.push(attachment.uuid)
      }
      attachmentList.value.splice(index, 1)
    }
  })
}
// 新建专家确定按钮
let createdexpert = async () => {
  let IDS = '' //此次专家的id
  is_inputName()
  is_inputPhone()
  is_inputNumber()
  is_inputStatus()
  is_inputSex()
  // console.log(errObj);
  // if (sex_val.value !== "") {
  if (errObj.name == false && errObj.number == false && errObj.phone == false && is_sexError.value == false && is_statusError.value == false) {
    await http
      .post('/expert/create-expert', {
        // 专家基础信息
        //专家名称
        expert_name: inputNameValue.value,
        //专家编号
        expert_number: inputNumberValue.value,
        // 专家性别
        expert_gender: sex_val.value,
        //联系方式
        expert_phone: inputPhoneValue.value,
        // 专家状态
        status: status_val.value,
        // 备注
        remark: inputNotesValue.value,
        //  专家标签
        tags: labelArrValue.value,
        //专家介绍
        description: inputIntroduceValue.value,
        //文件
        files: attachmentList.value || [],
      })
      .then((res) => {
        console.log(res, '111')
        if (res.status === 0) {
          IDS = res._id
          ElMessage({
            showClose: true,
            message: '新建专家成功',
            type: 'success',
          })
          // application(IDS);
          handleClose()
        } else if (res.status === 1) {
          ElMessage({
            showClose: true,
            message: '专家编号重复',
            type: 'warning',
          })
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // } else {
  //   is_sexError.value=true;
  //   console.log("专家状态不能为空");
  // }
}

// 请求标签 数据
const ReqDefault = async () => {
  const res = await http.post('/setting/fetch-setting', {
    type: 'expert',
  })

  console.log('res请求初始数据为 :>> ', res)
  if (res.status == 0) {
    console.log('res请求初始数据为 :>> ', res)

    res.tags.forEach((item, index) => {
      if (item.value != false) {
        labelArr.value.push(item.name)
      }
    })

    if (labelArr.value.length == 0) {
      labelArr.value.push('暂无标签，请前往【自定义设置】新建')
    }
    console.log('获取的最终数据', labelArr.value)
  }
}

onMounted(() => {
  ReqDefault()
  // getUserOptions();
  // isShowExpire();
  // const date = new Date();
  // currentDate = date.toISOString().slice(0, 10);
  // console.log(currentDate);
})
</script>

<style lang="less">
@import url('@/style/components/ExpertManagement/CreateExpert.less');
</style>
