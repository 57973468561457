<template>
  <div id="CM825Instrument">
    <!-- 表格主体 -->
    <vxe-toolbar>
      <template #buttons>
        <!-- <vxe-input
          class="Search"
          v-model="demo1.filterName"
          type="search"
          placeholder="请输入项目编号或志愿者编号进行搜索"
          @Blur="searchEvent2"></vxe-input>
        <vxe-button @click="reset" class="Reset"><span class="reset-icon">重置</span></vxe-button> -->
        <vxe-button class="Show" :class="SetShow ? 'ShowHiden' : ''">
          <!-- <img src="https://newstore.vynior.com/cems_web/browse%402x%20%281%29.png"/> -->
          <span @click.stop="SetShowFunction" class="show-icon">显示</span>
        </vxe-button>
        <!-- ！三个分类 -->
        <div class="form_header">
          <img class="uploading" @click="uploadData" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B68319%402x.png" alt="" />
          <img class="export" @click="exportChange" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B682270%402x.png" alt="" />
        </div>
      </template>
    </vxe-toolbar>
    <div class="VolunteerBox" v-show="tableData.length > 0">
      <!-- 表内导航栏(筛选条件) -->
      <div class="VolunteerTable_main">
        <!-- !  子目录结构-->
        <div class="MenuList" :class="{ clicked: !isCollapse }">
          <div class="Title">
            <span v-show="!isCollapse" class="title">子目录</span>
            <img v-show="!isCollapse" @click="isCollapse = !isCollapse" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B61615%402x.png" alt="" />
            <img
              v-show="isCollapse"
              class="close"
              @click="isCollapse = !isCollapse"
              src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%20161%20%E2%80%93%208%402x.png"
              alt="" />
          </div>

          <el-menu
            default-active="1"
            :index="activeIndex"
            @select="handleSelect"
            class="Line"
            :collapse="isCollapse"
            @open="handleOpen"
            @close="handleClose"
            :collapse-transition="false">
            <el-menu-item index="1" class="menu_item">
              <template #title>&nbsp;&nbsp;CM825 Single Avg</template>
            </el-menu-item>
            <el-menu-item index="2" class="menu_item">
              <template #title>&nbsp;&nbsp;CM825 Single</template>
            </el-menu-item>
          </el-menu>
        </div>
        <!-- 表体 -->
        <div class="VolunteerTable" :class="{ VolunteerTable1: isCollapse }">
          <div class="Project">
            <vxe-table
              border
              stripe
              align="left"
              ref="xTable"
              height="726"
              :scroll-y="{ mode: 'default' }"
              show-overflow
              row-id="_id"
              :data="tableData"
              :column-config="{ resizable: true }"
              :row-config="{
                isHover: true,
                keyField: 'id',
                height: linewidth <= 1280 ? 32 : 48,
                minHeight: 32,
                useKey: 'id',
              }"
              :checkbox-config="{ reserve: true }"
              :loading="demo1.loading"
              @checkbox-all="selectAllChangeEvent1"
              @checkbox-change="selectChangeEvent1">
              <vxe-column field="checked" type="checkbox" width="50" :fixed="demo1.colFixeds.col0"></vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Time_DateSession"
                type="html"
                title="Time & Date Session"
                width="192"
                :fixed="demo1.colFixeds.col1"
                min-width="120">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === 'Time & Date Session'">
                    <span class="name">Time & Date Session</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Time & Date Session', $event)"></i></span>
                  </div> </template
              ></vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Probe"
                type="html"
                title="Probe"
                width="146"
                :fixed="demo1.colFixeds.col1"
                min-width="120">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === 'Probe'">
                    <span class="name">Probe</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Probe', $event)"></i></span>
                  </div> </template
              ></vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="ProbeSN"
                type="html"
                title="Probe SN"
                width="146"
                :fixed="demo1.colFixeds.col1"
                min-width="120">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === 'Probe SN'">
                    <span class="name">Probe SN</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Probe SN', $event)"></i></span>
                  </div> </template
              ></vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Operator"
                type="html"
                title="Operator"
                width="112"
                :fixed="demo1.colFixeds.col2"
                min-width="80">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === 'Operator'">
                    <span class="name">Operator</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Operator', $event)"></i></span>
                  </div> </template
              ></vxe-column>

              <vxe-column
                :formatter="formatterRemark"
                field="Study"
                type="html"
                title="Study"
                width="200"
                :fixed="demo1.colFixeds.col4"
                min-width="80">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Sex-header" v-if="column.title === 'Study'">
                    <span class="name">Study</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Study', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Subject"
                type="html"
                title="Subject"
                width="160"
                :fixed="demo1.colFixeds.col5"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Subject'">
                    <span class="name">Subject</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Subject', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column :formatter="formatterRemark" field="T" type="html" title="T" width="60" :fixed="demo1.colFixeds.col3" min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Role-header" v-if="column.title === 'T'">
                    <span class="name">T</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('T', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column :formatter="formatterRemark" field="Tags" type="html" title="Tags" width="80" :fixed="demo1.colFixeds.col3" min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Role-header" v-if="column.title === 'Tags'">
                    <span class="name">Tags</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Tags', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="OrderOfTake"
                type="html"
                title="Order of Take"
                width="148"
                :fixed="demo1.colFixeds.col6"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Order of Take'">
                    <span class="name">Order of Take</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Order of Take', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="NumberofMeasurements"
                type="html"
                title="Number of Measurements"
                width="244"
                :fixed="demo1.colFixeds.col7"
                min-width="80">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Number of Measurements'">
                    <span class="name">Number of Measurements</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Number of Measurements', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>

              <vxe-column
                :formatter="formatterRemark"
                field="Hydration_Avg"
                v-if="activeIndex == 1"
                type="html"
                title="Hydration Avg"
                width="152"
                :fixed="demo1.colFixeds.col8"
                min-width="148">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Hydration Avg'">
                    <span class="name">Hydration Avg</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Hydration Avg', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Hydration_Spread"
                v-if="activeIndex == 1"
                type="html"
                title="Hydration Spread"
                width="185"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Hydration Spread'">
                    <span class="name">Hydration Spread</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Hydration Spread', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>

              <vxe-column
                :formatter="formatterRemark"
                field="OrderofMeasurement"
                type="html"
                title="Order of Measurement"
                v-if="activeIndex == 2"
                width="205"
                :fixed="demo1.colFixeds.col8"
                min-width="180">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Order of Measurement'">
                    <span class="name">Order of Measurement</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Order of Measurement', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Hydration"
                type="html"
                title="Hydration"
                v-if="activeIndex == 2"
                width="125"
                :fixed="demo1.colFixeds.col8"
                min-width="80">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Hydration'">
                    <span class="name">Hydration</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Hydration', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column title="操作" width="101" align="center" :fixed="demo1.colFixeds.col9" min-width="101" :resizable="false">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Operation-header" v-if="column.title === '操作'">
                    <span class="name">操作</span>
                    <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <!-- <vxe-button class="detail" @click="ShowDetails(row)">查看详情</vxe-button> -->
                  <vxe-button class="detail" v-if="row.invaild == false" @click="markChange(row)">标记无效</vxe-button>
                  <vxe-button class="detail" v-if="row.invaild == true" @click="markChanges(row)">恢复有效</vxe-button>
                  <!-- <vxe-button class="detail" @click="ShowDetails(row)">开始维护</vxe-button> -->
                </template>
              </vxe-column>

              <template #empty>
                <span style="color: red">
                  <!-- <img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif" /> -->
                  <p>没有更多数据了！</p>
                </span>
              </template>
            </vxe-table>
            <!-- 下拉箭头的更多操作 -->
            <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
              <div class="contentBox" id="MoreOptions">
                <ul class="list" id="MoreOptions">
                  <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                    <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                  </li>
                  <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                    <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                  </li>
                  <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')">
                    <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" />固定
                  </li>
                  <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" style="color: #bfbfbf">
                    <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" />取消固定
                  </li>

                  <li id="MoreOptions" @click="HiddenColumn(FixedIndex)"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li>
                </ul>
              </div>
            </div>
            <p>
              <vxe-pager
                class="Buttons"
                :style="changeBox ? '' : 'marginRight'"
                background
                @page-change="pageChange"
                v-model:current-page="page5.currentPage"
                v-model:page-size="page5.pageSize"
                :total="page5.totalResult"
                :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
                <template #left>
                  <div class="Total">
                    已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                    >条/共{{ page5.totalResult }}条
                  </div>
                </template>
              </vxe-pager>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="EmptyPage"
      style="width: 100%; height: 100%; background-color: #f8f8f8f8; display: flex; justify-content: center; align-items: center"
      v-show="tableData.length == 0">
      <div
        class="pageContent"
        style="
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
        ">
        <img src="https://newstore.vynior.com/cems_web/%E6%9A%82%E6%97%A0%E6%95%B0%E6%8D%AE111.png" alt="" />
        <span style="font-size: 16px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #626366">暂无数据</span>
      </div>
    </div>
    <!-- 导入 -->
    <el-dialog class="volunteer_dialog" v-model="dialogShow1" title="Shipping address" :align-center="true" :close-on-click-modal="false">
      <div class="dialog_title">
        <span>上传数据</span>
        <div class="title_cancel" @click="dialogShow1 = false"></div>
      </div>
      <div class="dialog_main">
        <div class="main_download">
          <div class="download_title">1.下载模板，填写数据信息</div>
          <div class="download_img"></div>
          <div class="upload_name"></div>
          <div class="upload_err"></div>
          <div class="download_but" @click="downloadXlsx">
            <button>下载模板</button>
          </div>
        </div>
        <div class="main_upload">
          <div class="upload_title">2.上传填写好的文件</div>
          <div class="upload_img"></div>
          <div class="upload_name">
            <span v-if="uploadFileName">{{ uploadFileName }}</span>
          </div>
          <div class="upload_tip">
            <span v-if="uploadFileErr">文件格式错误，请重新上传</span>
            <span class="tip_up" v-if="uploadFileName && !uploadFileErr">重新选择</span>
          </div>
          <div class="upload_but">
            <el-upload
              class="upload_xlsx"
              ref="videoRef"
              accept=".xlsx,.xls"
              :http-request="uploadFile"
              :on-preview="handlePreviews"
              :on-remove="handleRemoves"
              :file-list="fileLists"
              :show-file-list="false">
              <button>上传文件</button>
            </el-upload>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- !表头字段设置 -->
    <div class="SetTableHeader" v-if="SetTableHeader">
      <div class="main">
        <div class="Header">
          <div class="text">表头字段设置</div>
        </div>
        <!-- 设置表头的数据盒子 -->
        <div class="SetBox">
          <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
          <div class="LeftBox">
            <div class="text-header">仪器列表预设字段</div>
            <div class="ProjectRowField">
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div class="Checkbox" v-for="(item, index) in SetInstrumentListArr[activeIndex - 1]" :key="index">
                  <el-checkbox
                    :disabled="item.lable == '操作'"
                    v-model="item.checked"
                    :label="item.lable"
                    size="large"
                    @change="CheckHeader(index)" />
                </div>
              </div>
            </div>

            <div class="Self-Defined">自定义扩展字段</div>
          </div>
          <!-- 右边盒子 拖动 -->
          <div class="RightBox">
            <div class="text-header">列表页展示字段</div>
            <div style="font-size:16px; color='black';margin-top:150px">此功能正在开发中,敬请期待</div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="Bottom">
          <div class="add">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
          </div>
          <div class="recover" @click="RecoverData">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
          </div>
          <div class="cancel" @click="CancelShowHiden">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" />
          </div>
          <div class="save" @click="ShowHidenColumn">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 显示功能弹窗 -->
    <div class="SetShowsBox" ref="ShowBox" v-show="SetShow" id="SetShowsBoxs">
      <div class="Titles" id="SetShowsBoxs">字段显示</div>
      <div class="ContentBox" :class="AllHidenHeader[activeIndex - 1].length == 0 ? 'Emptys' : ''" id="SetShowsBoxs">
        <span class="Empty" v-if="AllHidenHeader[activeIndex - 1].length == 0" id="SetShowsBoxs">暂无隐藏字段</span>
        <div class="ListBox" v-if="AllHidenHeader[activeIndex - 1].length != 0" id="SetShowsBoxs">
          <div
            class="List"
            v-for="(item, index) in AllHidenHeader[activeIndex - 1]"
            :key="index"
            @click="ShowCheck[index] = !ShowCheck[index]"
            id="SetShowsBoxs">
            <div class="item" id="SetShowsBoxs">{{ item.title }}</div>

            <div class="Active" v-show="ShowCheck[index]" id="SetShowsBoxs">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110500%402x.png" alt="" />
            </div>
          </div>
          <div
            class="Button"
            @click="ConfirmRecover"
            id="SetShowsBoxs"
            :class="AllHidenHeader[activeIndex - 1].length == 1 ? 'Buttons' : AllHidenHeader[activeIndex - 1].length == 2 ? 'Buttonss' : ''">
            确认
          </div>
        </div>
      </div>
    </div>
    <div id="del_dialog" v-if="markShow">
      <el-dialog v-model="markShow" :close-on-click-modal="false" :align-center="true" :show-close="false">
        <template #header>
          <div class="title">确定将该数据标记为无效数据吗？</div>
        </template>
        <template #default>
          <div class="Derived_body">
            <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" alt="" />
            <div class="body_text">将数据标记无效后可以在操作栏恢复为有效数据</div>
          </div>
          <div class="Derived_but">
            <el-button class="cancel" @click="markShow = false">取消</el-button>
            <el-button class="sure" type="" @click="OkChange"> 确认 </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    <div id="del_dialog" v-if="nomarkShow">
      <el-dialog v-model="nomarkShow" :close-on-click-modal="false" :align-center="true" :show-close="false">
        <template #header>
          <div class="title">确定将该数据恢复为有效数据吗？</div>
        </template>
        <template #default>
          <div class="Derived_body">
            <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" alt="" />
            <div class="body_text">将数据恢复有效后可以在操作栏标记为无效数据</div>
          </div>
          <div class="Derived_but">
            <el-button class="cancel" @click="nomarkShow = false">取消</el-button>
            <el-button class="sure" type="" @click="OkNoMarkChange"> 确认 </el-button>
          </div>
        </template>
      </el-dialog>
    </div>
    >
    <!-- <InstrumentChange v-model="Details" v-if="Details"></InstrumentChange> -->
    <!-- :ChangeTite="DetailsMsg" -->
  </div>
</template>
<script setup>
import createInstrument from '@/components/InstrumentMangement/createInstrument.vue';
import { message } from '@/utils/resetMessage';
import dayjs from 'dayjs';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import http from '@/utils/http';
import { replaceEmptyValues } from '@/utils/emptyArray';
import * as xlsx from 'xlsx-js-style';

const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};

onMounted(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});

// !表单空白处理
const tableData = computed(() => {
  return replaceEmptyValues(demo1.tableData);
});

//API接收
const store = useStore();
const router = useRouter();
const xTable = ref();
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [],
  filterName: '',
  loading: false,
  list: [],
  gender: '',
  age: '',
});
onMounted(() => {
  getInstRes();
  //处理点击升降序 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
    }
  });
  //处理显示 功能
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false;
      }
    }
  });
});
// 获取仪器数据
const getInstRes = async () => {
  const res = await http.post('/instrument/fetchCM825Record-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    content: demo1.filterName,
    type: activeIndex.value == 1 ? 'CM825 Avg' : 'CM825',
    sort: SORT.value,
  });
  demo1.tableData = res.result;
  page5.totalResult = res.totalCount;
  console.log(res, '获取的全部数据');
  demo1.loading = false;
};
const searchEvent2 = (val) => {
  page5.currentPage = 1;
  console.log(val, '我点击了搜索');
  getInstRes();
};
//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
});
//分页--->跳转页面显示数据处理
const pageChange = async () => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  getInstRes();
};
//导入
let dialogShow1 = ref(false);
const uploadData = () => {
  console.log(123333);
  dialogShow1.value = true;
};
// 点击下载模板
const downloadXlsx = async () => {
  if (activeIndex.value == 2) {
    const xlsxUrl = 'https://newstore.vynior.com/cems_web/CM825%20Single%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx';
    const response = await fetch(xlsxUrl);
    const blob = await response.blob();
    // 创建下载链接
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = '仪器测试记录-363212  皮肤水分测试探头Corneometer CM825-上传数据.xlsx';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // 释放 Blob URL
    window.URL.revokeObjectURL(url);
  }
  if (activeIndex.value == 1) {
    const xlsxUrl = 'https://newstore.vynior.com/cems_web/CM825-Single%20Avg%E5%AF%BC%E5%87%BA%E6%A8%A1%E6%9D%BF.xlsx';
    const response = await fetch(xlsxUrl);
    const blob = await response.blob();
    // 创建下载链接
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = '仪器测试记录-363212  皮肤水分测试探头Corneometer CM825 Avg-上传数据.xlsx';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    // 释放 Blob URL
    window.URL.revokeObjectURL(url);
  }
};
// 上传事件
const uploadFile = async (params) => {
  // if (activeIndex.value == 1) {
  console.log(params);
  const file = params.file;
  const form = new FormData();
  form.append('file', file);
  form.append('type', activeIndex.value == 1 ? 'CM825 Avg' : 'CM825');
  console.log(params);
  await http
    .post('/upload/upload-instrumentRecord', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async (res) => {
      console.log(res);
      if (res.status == 0) {
        dialogShow1.value = false;
        getInstRes();
        message({
          showClose: true,
          message: '上传数据成功',
          type: 'success',
        });
      } else if (res.status == 1) {
        message({
          showClose: true,
          message: '上传文件为空，请重新上传',
          type: 'error',
        });
      }
    });
};
// 点击导出
const exportChange = async () => {
  console.log(checkedArr.value, '导出的id');
  if (checkedArr.value.length > 0) {
    const res = await http.post('/upload/export-instrumentRecord', {
      idList: checkedArr.value,
      type: activeIndex.value == 1 ? 'CM825 Avg' : 'CM825',
    });
    console.log(res, '导出数据返回值');
    if (res.status == 0) {
      xlsx.writeFileXLSX(res.xlsxData, `仪器测试记录 ${dayjs().format('YYYYMMDD')}.xlsx`);
      message({
        message: '导出成功',
        showClose: true,
        type: 'success',
      });
    }
  } else {
    message({
      message: '请选择要导出的数据',
      showClose: true,
      type: 'error',
    });
  }
};
//复选框数组
var selectedRows = reactive([]);
const checkedArr = ref([]);
// 复选框点击事件
const selectChangeEvent1 = (row) => {
  // console.log('点击的id:', row.rowid);
  console.log('row :>> ', row);

  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records;

  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid);
  } else {
    let index = checkedArr.value.indexOf(row.rowid);
    checkedArr.value.splice(index, 1);
  }
  console.log('最新:', checkedArr.value);
};
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  console.log(checked ? '所有勾选事件' : '所有取消事件', records);
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id);
      }
    }
    console.log('最新:', checkedArr.value);
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = [];

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id);
    });

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item);
    });
    console.log('最新:', checkedArr.value);
  }
};
// 目录选中展示的值
let activeIndex = ref(1);
// 目录选中值变化
const handleSelect = async (index) => {
  activeIndex.value = index;
  console.log(index);
  const $table = xTable.value;
  const records = $table.clearCheckboxRow();
  checkedArr.value = [];
  await getInstRes();
  await ShowHidenColumn();
};
// 点击表头的下拉箭头
const OperationBox = ref(null); //获取更多操作盒子的ref
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1);
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  //   //console.log('value :>> ', value);
  const table = xTable.value;
  const FixedList = table.getColumns();

  FixedList.forEach((item, index) => {
    if (item.field == value) {
      FixedIndex.value = index;
    }
  });
  //console.log('FixedIndex下标为 :>> ', FixedIndex);

  showIdOptions.value = false;
  showIdOptions.value = true;
  // 设置位置
  const Event = event;
  OperationBox.value.style.left = event.clientX - 380 + 'px';
};
// 全部显示里被隐藏的
let AllHidenHeader = ref([[], []]);
const SetShow = ref(false);
const ShowBox = ref(null); //获取Show盒子的ref
const ShowCheck = ref([]);
// 点击显示
const SetShowFunction = (event) => {
  SetShow.value = false;
  SetShow.value = true;
  // console.log('event :>> ', event, ShowBox.value);
  ShowBox.value.style.left = event.clientX - 10 + 'px';
  ShowBox.value.style.top = event.clientY + 20 + 'px';
};
// 点击确认显示字段
const ConfirmRecover = () => {
  let TeShowCheck = [];
  let TeAllHidenHeader = [];
  const $table = xTable.value;
  const column = $table.getColumns();
  console.log('ShowCheck.value :>> ', ShowCheck.value);
  console.log(AllHidenHeader.value[activeIndex.value - 1], 'AllHidenHeader');
  for (let i = ShowCheck.value.length; i >= 0; i--) {
    if (ShowCheck.value[i] == true) {
      // // column[index].visible = false;
      // console.log('column :>> ', ShowCheck.value[i], AllHidenHeader.value[i]);

      // 显示列
      $table.showColumn(AllHidenHeader.value[activeIndex.value - 1][i].field);
      // 同步删除 数据 隐藏列/checked
      AllHidenHeader.value[activeIndex.value - 1].splice(i, 1);
      ShowCheck.value.splice(i, 1);
      // console.log('column :>> ', column, $table);
      // console.log('column :>> ', $table.showColumn());
      // console.log('column :>> ', ShowCheck.value, AllHidenHeader.value);
    }
  }
  SetShow.value = false;
};
// !重置按钮
const reset = () => {
  demo1.age = '';
  demo1.gender = '';
  demo1.filterName = '';
  page5.pageSize = 10;
  page5.currentPage = 1;
  const $table = xTable.value;
  const records = $table.clearCheckboxRow();
  checkedArr.value = [];
  getInstRes();
};
//标记无效弹窗显示隐藏
const markShow = ref(false);
// 恢复有效弹窗显示隐藏
const nomarkShow = ref(false);
// 需要编辑无效的数据
const markData = ref();

// 触发编辑无效事件
const markChange = (val) => {
  console.log(val);
  markData.value = val;
  markShow.value = true;
};
// 触发恢复有效事件
const markChanges = (val) => {
  console.log(val);
  markData.value = val;
  nomarkShow.value = true;
};
// 标记为无效
const OkChange = async () => {
  const res = await http.post('/instrument/setinvalid', {
    _id: markData.value._id,
    type: activeIndex.value == 1 ? 'CM825 Avg' : 'CM825',
    invaild: true,
  });
  console.log(res, '编辑无效返回的数据');
  getInstRes();
  // demoData.tableData[markData.value].mark=true
  markShow.value = false;
};
// 恢复为有效
const OkNoMarkChange = async () => {
  const res = await http.post('/instrument/setinvalid', {
    _id: markData.value._id,
    type: activeIndex.value == 1 ? 'CM825 Avg' : 'CM825',
    invaild: false,
  });
  console.log(res, '恢复有效返回的数据');
  getInstRes();
  // demoData.tableData[markData.value].mark=true
  nomarkShow.value = false;
};
// sort排序字段
let SORT = ref({ createdAt: -1 });
// More -->升序/降序
const AscendingSort = async (index, type) => {
  console.log('我的type: :>> ', type, '我的index: :>> ', index);
  demo1.loading = true;

  // console.log('我的下标是: :>> ', index);
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  // console.log('固定信息:', tableColumns);
  const ColumnId = tableColumns[index].field;
  const sorts = {};
  if (type == 'Asc') {
    sorts[ColumnId] = 1;
  } else {
    sorts[ColumnId] = -1;
  }

  SORT.value = sorts;
  console.log(SORT.value, '升降序');
  getInstRes();
};
//  More -->下拉选择中的固定方法
const toggleFixedColumn = (index, type) => {
  showIdOptions.value = false;
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
// 取消固定
const toggleFixedColumns = (index, type) => {
  showIdOptions.value = false;
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
// 字段为空展示——
const formatterRemark = ({ cellValue }) => {
  //console.log('cellValue :>> ', cellValue);
  return cellValue === undefined || cellValue === '' || cellValue === null ? '——' : cellValue;
};
// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false;
  const $table = xTable.value;
  const column = $table.getColumns();
  // console.log('column[index] :>> ', column[index]);
  column[index].visible = false;
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value[activeIndex.value - 1].push(column[index]);
  ShowCheck.value.push(false);
  $table.refreshColumn(); //刷新列表
  console.log('ShowChcke.value :>> ', ShowCheck.value);
  // console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
};
//  ! 收缩导航栏
const isCollapse = ref(false);
// 表头字段展示
const SetTableHeader = ref(false);
//表头字段设置--->设置表头的勾选数据
let SetInstrumentListArr = ref([
  [
    {
      fixed: '',
      field: 'Time_DateSession',
      checked: true,
      lable: 'Time & Date Session',
    },
    { fixed: '', field: 'Probe', checked: true, lable: 'Probe' },
    { fixed: '', field: 'ProbeSN', checked: true, lable: 'Probe SN' },
    { fixed: '', field: 'Operator', checked: true, lable: 'Operator' },
    { fixed: '', field: 'Study', checked: true, lable: 'Study' },
    { fixed: '', field: 'Subject', checked: true, lable: 'Subject' },
    { fixed: '', field: 'T', checked: true, lable: 'T' },
    { fixed: '', field: 'Tags', checked: true, lable: 'Tags' },

    { fixed: '', field: 'OrderOfTake', checked: true, lable: 'Order of Take' },

    {
      fixed: '',
      field: 'NumberofMeasurements',
      checked: true,
      lable: 'Number of Measurements',
    },
    {
      fixed: '',
      field: 'Hydration_Avg',
      checked: true,
      lable: 'Hydration Avg',
    },
    {
      fixed: '',
      field: 'Hydration_Spread',
      checked: true,
      lable: 'Hydration Spread',
    },
    { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  ],
  [
    {
      fixed: '',
      field: 'Time_DateSession',
      checked: true,
      lable: 'Time & Date Session',
    },
    { fixed: '', field: 'Probe', checked: true, lable: 'Probe' },
    { fixed: '', field: 'ProbeSN', checked: true, lable: 'Probe SN' },
    { fixed: '', field: 'Operator', checked: true, lable: 'Operator' },
    { fixed: '', field: 'Study', checked: true, lable: 'Study' },
    { fixed: '', field: 'Subject', checked: true, lable: 'Subject' },
    { fixed: '', field: 'T', checked: true, lable: 'T' },
    { fixed: '', field: 'Tags', checked: true, lable: 'Tags' },
    { fixed: '', field: 'OrderOfTake', checked: true, lable: 'Order of Take' },

    {
      fixed: '',
      field: 'NumberofMeasurements',
      checked: true,
      lable: 'Number of Measurements',
    },
    {
      fixed: '',
      field: 'OrderofMeasurement',
      checked: true,
      lable: 'Order of Measurement',
    },
    { fixed: '', field: 'Hydration', checked: true, lable: 'Hydration' },

    { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  ],
]);
// 表头默认字段
let DefaultHeaderData = ref([
  [
    {
      fixed: '',
      field: 'Time_DateSession',
      checked: true,
      lable: 'Time & Date Session',
    },
    { fixed: '', field: 'Probe', checked: true, lable: 'Probe' },
    { fixed: '', field: 'ProbeSN', checked: true, lable: 'Probe SN' },
    { fixed: '', field: 'Operator', checked: true, lable: 'Operator' },
    { fixed: '', field: 'Study', checked: true, lable: 'Study' },
    { fixed: '', field: 'Subject', checked: true, lable: 'Subject' },
    { fixed: '', field: 'T', checked: true, lable: 'T' },
    { fixed: '', field: 'Tags', checked: true, lable: 'Tags' },

    { fixed: '', field: 'OrderOfTake', checked: true, lable: 'Order of Take' },

    {
      fixed: '',
      field: 'NumberofMeasurements',
      checked: true,
      lable: 'Number of Measurements',
    },
    {
      fixed: '',
      field: 'Hydration_Avg',
      checked: true,
      lable: 'Hydration Avg',
    },
    {
      fixed: '',
      field: 'Hydration_Spread',
      checked: true,
      lable: 'Hydration Spread',
    },
    { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  ],
  [
    {
      fixed: '',
      field: 'Time_DateSession',
      checked: true,
      lable: 'Time & Date Session',
    },
    { fixed: '', field: 'Probe', checked: true, lable: 'Probe' },
    { fixed: '', field: 'ProbeSN', checked: true, lable: 'Probe SN' },
    { fixed: '', field: 'Operator', checked: true, lable: 'Operator' },
    { fixed: '', field: 'Study', checked: true, lable: 'Study' },
    { fixed: '', field: 'Subject', checked: true, lable: 'Subject' },
    { fixed: '', field: 'T', checked: true, lable: 'T' },
    { fixed: '', field: 'Tags', checked: true, lable: 'Tags' },
    {
      fixed: '',
      field: 'NumberofMeasurements',
      checked: true,
      lable: 'Number of Measurements',
    },
    { fixed: '', field: 'GlossAvg', checked: true, lable: 'Gloss Avg' },
    { fixed: '', field: 'DSCAvg', checked: true, lable: 'DSC Avg' },
    { fixed: '', field: 'DSCSpread', checked: true, lable: 'DSC Spread' },
    {
      fixed: '',
      field: 'DiffreflAvg',
      checked: true,
      lable: 'Diff. refl. Avg',
    },
    {
      fixed: '',
      field: 'DiffreflSpread',
      checked: true,
      lable: 'Diff. refl. Spread',
    },
    { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  ],
]);
// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([]);
// 表头字段设置----> 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true;
  TemporaryData.value = JSON.parse(JSON.stringify(SetInstrumentListArr.value[activeIndex.value - 1]));

  //console.log('SetTableHeader', SetTableHeader.value);
};
// 表头阻断设置---->的点击复选框
const CheckHeader = (i) => {
  console.log(SetInstrumentListArr.value[activeIndex.value - 1], 'SetInstrumentListArr');
};
// ShowHidenColumn 设置column的的列表(确认按钮)
const ShowHidenColumn = () => {
  const $table = xTable.value;
  const column = $table.getColumns();

  //console.log('$table.getColumns :>> ', $table.getColumns());

  for (let i = 0; i < SetInstrumentListArr.value[activeIndex.value - 1].length; i++) {
    if (SetInstrumentListArr.value[activeIndex.value - 1][i].checked == true) {
      // //console.log('object :>> ', SetInstrumentListArr[i].field);
      $table.showColumn(SetInstrumentListArr.value[activeIndex.value - 1][i].field);
    }
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetInstrumentListArr.value[activeIndex.value - 1][i].lable) {
        if (SetInstrumentListArr.value[activeIndex.value - 1][i].checked == false) {
          $table.hideColumn(column[j].field);
        }
      }
    }
  }

  $table.refreshColumn();
  //console.log('$table.getColumns :>> ', $table.getColumns());
  SetTableHeader.value = false;
};
// 表头字段设置----> 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false;
  SetInstrumentListArr.value[activeIndex.value - 1] = JSON.parse(JSON.stringify(TemporaryData.value));
};
//  表头字段设置----> 恢复默认字段
const RecoverData = () => {
  console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value);
  SetInstrumentListArr.value[activeIndex.value - 1] = JSON.parse(JSON.stringify(DefaultHeaderData.value[activeIndex.value - 1]));
};
// NEXTTICK
nextTick(() => {
  setHeaderColumn();
  // 执行 列表显示/隐藏
  ShowHidenColumn();
  // // 固定列表本地数据
  // FixedColumn();

  // 转移数据
  // TransferData();
});
const setHeaderColumn = () => {
  if (localStorage.getItem('CM825ShowHiddenArr') == null) {
  } else {
    SetInstrumentListArr.value = JSON.parse(localStorage.getItem('CM825ShowHiddenArr'));
  }
};
//note 组件销毁
onBeforeUnmount(() => {
  const table = xTable.value;
  console.log('组件销毁时的数值 :>> ', table);

  localStorage.setItem('CM825ShowHiddenArr', JSON.stringify(SetInstrumentListArr.value));
});
</script>

<style lang="less" scoped>
@import url('@/style/components/Fullinstrument/CM825Instrument.less');
</style>
