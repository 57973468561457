<template>
  <div id="SampleList">
    <CreatedVolunteer :ChangeTite="is_dialogShow" @updateChange="updateChange" v-if="is_dialogShow"></CreatedVolunteer>
    <div class="VolunteerBox">
      <div class="VolunteerTable">
        <div class="vxetable">
          <vxe-toolbar>
            <template #buttons>
              <!-- <vxe-select v-model="demo1.age" placeholder="所有年龄" size="medium" class="Age">
                <vxe-option v-for="num in ageList" :key="num" :value="num" :label="`${num}`"></vxe-option>
              </vxe-select> -->
              <vxe-input
                class="Search"
                v-model="demo1.filterName"
                type="search"
                placeholder="请输入样品编号或名称进行搜索"
                @keydown.enter="searchSampleListChange"
                @Blur="searchSampleListChange"></vxe-input>
              <vxe-button class="Reset"><span class="reset-icon" @click="reset">重置</span></vxe-button>
              <div class="Select" @click="ischangeShow">
                <img src="https://new.vynior.com/filter%402x.png" alt="" />
                <span class="text">高级搜索</span>
              </div>
              <vxe-button class="Show" :class="SetShow ? 'ShowHiden' : ''"
                ><span @click.stop="SetShowFunction" class="show-icon">显示</span></vxe-button
              >

              <div class="New_voluntee" @click="createdSampleClick">
                <img src="https://newstore.vynior.com/%E7%BB%84110130%402x.png" alt="" />
                <span class="text">新建样品</span>
              </div>

              <div class="Import_volunteers" @click="exportDataEvent">
                <img src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%402x.png" alt="" />
                <span class="text">导入样品</span>
              </div>
              <div class="batch_operation" @click.stop="isshowOperation = !isshowOperation" :class="{ clicked_batch: isshowOperation }">
                <span class="text">批量操作</span>
                <div class="arrow" v-show="!isshowOperation">
                  <img src="https://newstore.vynior.com/%E4%B8%8B%E6%8B%89.png" alt="" />
                </div>
                <div class="arrow" v-show="isshowOperation">
                  <img src="https://newstore.vynior.com/cems_web/blue.png" alt="" />
                </div>
              </div>
              <!-- 批量操作下拉框 -->
              <div class="batch_operation_box" id="operationBox" v-if="isshowOperation">
                <div class="item_box">
                  <div class="downImg"></div>
                  <!-- <img src="https://newstore.vynior.com/%E5%AF%BC%E5%87%BA%402x.png" alt="" /> -->
                  <!-- <div class="item_one_text" @click="Export">导出</div> -->
                  <div class="item_one_text">导出</div>
                </div>
                <div class="item_box1" @click="delect">
                  <img src="https://newstore.vynior.com/%E5%88%A0%E9%99%A4%402x.png" alt="" />
                  <div class="item_two_text" style="margin-top: 0px">删除</div>
                </div>
              </div>

              <!-- 新建样品 -->
            </template>
          </vxe-toolbar>
          <vxe-table
            border
            stripe
            ref="xTable"
            height="672"
            show-overflow
            row-id="_id"
            :data="tableData"
            :tooltip-config="tooltipConfig"
            :column-config="{ resizable: true, useKey: 'id' }"
            :tooltip-method="tooltipMethod"
            :loading-config="demo1.loading"
            :row-config="{
              isHover: true,
              keyField: 'id',
              height: linewidth <= 1280 ? 32 : 48,
              minHeight: 36,
              useKey: 'id',
            }"
            :checkbox-config="{ reserve: true }"
            :loading="demo1.loading"
            @header-cell-dragend="handleHeaderDragend"
            @checkbox-all="selectAllChangeEvent1"
            @checkbox-change="selectChangeEvent1">
            <vxe-column field="checked" type="checkbox" width="41" min-width="40" :fixed="demo1.colFixeds.col0"></vxe-column>
            <vxe-column
              v-for="(item, index) in allDatas"
              :key="index"
              :title="item.lable"
              :field="item.field"
              type="html"
              :width="item.width"
              min-height="36"
              :fixed="item.fixed"
              :min-width="item.min_width">
              <!-- 增加下拉箭头 -->
              <!-- 增加下拉箭头 -->
              <template #header="{ column }">
                <div class="ID-header" v-if="column.title === item.lable && column.title != '操作'">
                  <span class="name">{{ item.lable }}</span>

                  <span v-if="item.lable != '操作'"
                    ><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation(item.lable, $event)"></i
                  ></span>
                </div>
                <div class="Operation-header" v-if="column.title === '操作'">
                  <span class="name names">操作</span>
                  <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                </div>
              </template>
              <template #default="{ row }">
                <div class="rows" v-if="item.lable == '操作'">
                  <el-tooltip class="box-item" effect="dark" content="查看详情" placement="top">
                    <span> <vxe-button class="detail" @click="ShowDetails(row)">查看详情</vxe-button></span>
                  </el-tooltip>
                  <el-tooltip class="box-item" effect="dark" content="发放样品" placement="top">
                    <span
                      ><vxe-button
                        :disabled="checkDate(row.limitDate) || row.destory"
                        style="margin-left: 7px"
                        class="detail"
                        :style="checkDate(row.limitDate) ? activeStyle : row.destory ? activeStyle : ''"
                        @click="Sample_release_way(row)">
                        发放
                      </vxe-button></span
                    >
                  </el-tooltip>

                  <el-tooltip class="box-item" effect="dark" content="回收样品" placement="top">
                    <span
                      ><vxe-button
                        :style="checkDate(row.limitDate) ? activeStyle : row.destory ? activeStyle : ''"
                        :disabled="checkDate(row.limitDate) || row.destory"
                        class="detail"
                        @click="Sample_recovery_way(row)">
                        回收
                      </vxe-button></span
                    >
                  </el-tooltip>

                  <el-tooltip class="box-item" effect="dark" content="···" placement="top">
                    <span><span class="More" id="CMoreOption" style="color: #027aff" @click="ClickMethods(row, $event)">···</span></span>
                  </el-tooltip>
                </div>
                <div class="rows" v-else-if="item.lable == '是否过期'">
                  <span v-if="row.limitDate !== '——'">{{ new Date(row.limitDate) <= new Date(current) ? '是' : '否' }}</span>
                  <span v-else>——</span>
                </div>
                <div class="rows" v-else-if="item.lable == '是否销毁'">
                  <span>{{ formatterData(row.destory == false ? '否' : '是') }}</span>
                </div>
                <div class="rows" v-else-if="item.lable == '样品接收日期'">
                  <!-- {{ dayjs(row.receiptDate).format('YYYYMMDD') }} -->
                  {{ row.receiptDate != '' && row.receiptDate != '——' ? dayjs(row.receiptDate).format('YYYY-MM-DD') : '——' }}
                </div>
                <div class="rows" v-else-if="item.lable == '限制使用日期'">
                  {{ row.limitDate != '' && row.limitDate != '——' ? dayjs(row.limitDate).format('YYYY-MM-DD') : '——' }}
                </div>
                <div class="rows" v-else-if="item.lable == '样品标签'">
                  <!-- {{ row.tag != '——' ? JSON.stringify(row.tag).replace(/"|\[|\]/g, '') : '——' }} -->
                  {{ formatTags(row.tag) }}
                </div>
                <div class="rows" v-else>
                  {{ formatterData(row[item.field]) }}
                </div>
              </template>
            </vxe-column>
          </vxe-table>
          <!-- 下拉箭头的更多操作 -->
          <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
            <div class="contentBox" id="MoreOptions">
              <ul class="list" id="MoreOptions">
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                  <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                </li>
                <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                  <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                </li>
                <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')">
                  <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" />固定
                </li>
                <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" style="color: #bfbfbf">
                  <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" />取消固定
                </li>

                <li id="MoreOptions" @click="HiddenColumn(FixedIndex)"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li>
              </ul>
            </div>
          </div>
          <!-- todo -->
          <!-- 每一行的 ... -->
          <div class="Options" id="CMoreOption" ref="CMoreOptions" v-show="ClickMoreOptions">
            <!-- <div class="Copy" @click="showOneExport">导出</div> -->
            <div class="Copy">导出</div>
            <div class="Delete" @click="DeleteVolun">删除</div>
          </div>
          <!-- 导出一个 -->
          <div class="Derived_volunteer" v-if="isshowOneExport">
            <div class="Derived_volunteer_box" v-if="isshowOneExport">
              <div v-if="isshowOneExport">
                <div class="Derived_title">
                  确定导出<span class="Derived_name">{{ name }}</span
                  >的样品信息吗？
                </div>
                <div class="Derived_body">
                  <img src="https://new.vynior.com/111.png" alt="" />
                  <div class="body_text">导出后可在excel表格中进行查看</div>
                </div>
                <div class="Derived_bottom">
                  <div class="off" @click="isshowOneExport = false">取消</div>
                  <div class="ok" @click="ExportFile">确认</div>
                </div>
              </div>
            </div>
          </div>
          <!-- v-show="demo1.tableData.length != 0 -->
          <p>
            <vxe-pager
              background
              @page-change="pageChange"
              v-model:current-page="page5.currentPage"
              v-model:page-size="page5.pageSize"
              :total="page5.totalResult"
              :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
              <template #left>
                <div class="Total">
                  已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                  >条/共{{ page5.totalResult }}条
                </div>
              </template>
            </vxe-pager>
          </p>
        </div>
      </div>
    </div>
    <!-- ! 高级搜索 -->
    <div class="Select_box" v-if="Show_transform">
      <div class="Select_main">
        <!-- 头部退出 -->
        <div class="Select_top">
          <div class="Select_titel">高级搜索</div>
          <div>
            <img @click="Show_transform = !Show_transform" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
          </div>
        </div>
        <!-- 中间表单 -->
        <div class="Select_body">
          <!-- 个人基础信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">样品基本信息</div>
            </div>
            <el-form>
              <el-form-item :model="sampleSearch">
                <el-form-item id="add" label="样品编号" class="box1">
                  <el-input v-model="sampleSearch.sample_number" placeholder="请输入样品编号" />
                </el-form-item>
                <el-form-item label="样品名称">
                  <el-input v-model="sampleSearch.sample_name" placeholder="请输入样品名称" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="sampleSearch">
                <el-form-item label="样品总数量">
                  <el-input v-model="sampleSearch.sample_total" type="number" clearable suffix-icon="" placeholder="请输入样品总数量" />
                </el-form-item>
                <el-form-item label="可发放数量">
                  <el-input v-model="sampleSearch.available_quantity" type="number" clearable suffix-icon="" placeholder="请输入可发放数量" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="sampleSearch">
                <el-form-item label="样品标签">
                  <el-select v-model="sampleSearch.label" class="m-2" placeholder="请选择" size="large">
                    <el-option
                      v-for="item in SampleTags"
                      :key="item"
                      :label="item"
                      :value="item"
                      :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                  </el-select>
                </el-form-item>
                <el-form-item label="样品规格">
                  <el-input v-model="sampleSearch.spec" placeholder="请输入样品规格" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="sampleSearch">
                <el-form-item label="样品性状">
                  <el-input v-model="sampleSearch.character" placeholder="请输入样品性状" />
                </el-form-item>
                <el-form-item label="样品批号">
                  <el-input v-model="sampleSearch.batchNum" placeholder="请输入样品批号" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="sampleSearch">
                <el-form-item label="储存条件">
                  <el-input v-model="sampleSearch.saveCondition" placeholder="请输入储存条件" />
                </el-form-item>
                <el-form-item label="样品接收日期">
                  <el-date-picker
                    v-model="sampleSearch.receiptDate"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="请选择"
                    :size="size" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="sampleSearch">
                <el-form-item label="限制使用日期">
                  <el-date-picker
                    v-model="sampleSearch.limitDate"
                    type="date"
                    format="YYYY-MM-DD"
                    value-format="YYYY-MM-DD"
                    placeholder="请选择"
                    :size="size" />
                </el-form-item>
                <el-form-item label="是否过期">
                  <el-select v-model="sampleSearch.expired" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.isBoolean" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
              <el-form-item :model="sampleSearch">
                <el-form-item label="是否销毁">
                  <el-select v-model="sampleSearch.destory" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.isBoolean" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="推荐检测项目类别">
                  <el-input v-model="sampleSearch.usefunc" placeholder="请输入建议检测项目类别" />
                </el-form-item>
              </el-form-item>

              <el-form-item :model="sampleSearch">
                <el-form-item label="样品使用方法">
                  <el-input class="inputOfUseFun" v-model="sampleSearch.usefunc" placeholder="请输入样品使用方法" />
                </el-form-item>
              </el-form-item>

              <el-form-item :model="sampleSearch">
                <el-form-item label="样品管理员">
                  <el-select v-model="sampleSearch.management" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in selectInstrumentAdministratorAll" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
                <el-form-item label="备注">
                  <el-input v-model="sampleSearch.notes" placeholder="请输入备注" />
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
          <!-- 样品检测项目信息 -->
          <div class="Select_one">
            <div class="Selelet_one_title">
              <div class="line"></div>
              <div class="text">样品检测项目信息</div>
            </div>
            <el-form>
              <el-form-item :model="sampleSearch">
                <el-form-item id="add" label="样品检测项目编号" class="box1">
                  <el-input v-model="sampleSearch.project_number" placeholder="请输入样品检测项目编号" />
                </el-form-item>
                <el-form-item label="样品检测项目名称">
                  <el-input v-model="sampleSearch.project_name" placeholder="请输入样品检测项目名称" />
                </el-form-item>
              </el-form-item>
              <el-form-item :model="sampleSearch">
                <el-form-item label="样品检测状态">
                  <el-select v-model="sampleSearch.test_status" class="m-2" placeholder="请选择" size="large">
                    <el-option v-for="item in Totalinitialdata.labelArr" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 底部按钮 -->
        <div class="Select_footer">
          <div class="null" @click="unSelect">
            <img src="https://new.vynior.com/%E7%BB%84%E4%BB%B6879%402x.png" alt="" />
          </div>
          <div class="onselect" @click="reqAllSelectData">
            <img src="https://new.vynior.com/%E7%BB%84%E4%BB%B6869%402x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 抽屉样品详情 -->
    <el-drawer v-model="Volunteerdetails" :close-on-click-modal="Volunteereditor" title="I am the title" :with-header="false" size="57%">
      <div class="drawer">
        <div class="deawer_title">
          <div class="deawer_title_text">样品信息</div>
          <div>
            <img @click="isVolunteereditor" v-if="Volunteereditor" src="https://newstore.vynior.com/%E7%BC%96%E8%BE%91.png" alt="" />
          </div>
        </div>
        <div class="drawer_body">
          <!-- 样品基础信息  查看状态 -->
          <div class="information_box" v-if="Volunteereditor">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">样品基本信息</div>
            </div>
            <div class="item_main_box">
              <div class="item1">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>样品编号</div>
                      <div class="item_main_text_value">
                        {{ information.code }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>样品名称</div>
                      <div class="item_main_text_value">
                        {{ information.name }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text"><span class="star">*</span>样品总数量</div>
                      <div class="item_main_text_value">
                        {{ information.total }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">
                        <!--                        <span class="star">*</span>-->
                        可发放数量
                      </div>
                      <div class="item_main_text_value">
                        {{ information.available_quantity }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品标签</div>
                      <div class="item_main_text_value">
                        {{ JSON.stringify(information.tag).replace(/"|\[|\]/g, '') }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品规格</div>
                      <div class="item_main_text_value">
                        {{ information.specs }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品性状</div>
                      <div class="item_main_text_value">
                        {{ information.properties }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品批号</div>
                      <div class="item_main_text_value">
                        {{ information.batch_code }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">储存条件</div>
                      <div class="item_main_text_value">
                        {{ information.storage_conditions }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品接收日期</div>
                      <div class="item_main_text_value">
                        {{ information.acceptance_date }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">限制使用日期</div>
                      <div class="item_main_text_value">
                        {{ information.expiration_date }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否过期</div>
                      <div class="item_main_text_value">
                        {{ new Date(information.expiration_date) < new Date(current) ? '是' : '否' }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否销毁</div>
                      <div class="item_main_text_value">
                        {{ information.isdestory == true ? '是' : '否' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">建议检测项目类别</div>
                      <div class="item_main_text_value">
                        {{ information.testProject_suggest }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="24">
                    <div class="item_box">
                      <div class="item_mian_text">样品使用方法</div>
                      <div class="item_main_text_value">
                        {{ information.usage_instructions }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品管理员</div>
                      <div class="item_main_text_value">
                        {{ information.manager_name }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">备注</div>
                      <div class="item_main_text_value">
                        {{ information.remark }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">是否留样</div>
                      <div class="item_main_text_value">
                        {{ information.isretained == false ? '否' : '是' }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12" v-if="information.isretained">
                    <div class="item_box">
                      <div class="item_mian_text">留样数量</div>
                      <div class="item_main_text_value">
                        {{ information.retained_quantity }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>
          <div class="information_box" v-if="!Volunteereditor">
            <div class="sample_main">
              <!-- 样品基础信息 -->
              <div class="main_info">
                <div class="info_title">
                  <div class="title_color"></div>
                  <div class="title_text">样品基础信息</div>
                </div>
                <div class="info_main">
                  <div class="list_line">
                    <!-- 样品编号 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span class="text_top">*</span>
                          样品编号
                        </div>
                        <div class="main">
                          <el-input
                            v-model="inputNumberValue"
                            class="inp"
                            placeholder="请输入样品编号"
                            suffix-icon=""
                            :class="errObj.number ? 'inp_err' : ''"
                            @focus="errObj.number = false" />
                          <div class="ertext">
                            <span v-if="errObj.number">样品编号不可为空</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 样品名称 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span class="text_top">*</span>
                          样品名称
                        </div>
                        <div class="main">
                          <el-input
                            v-model="inputNameValue"
                            class="inp"
                            placeholder="请输入样品名称"
                            suffix-icon=""
                            :class="errObj.name ? 'inp_err' : ''"
                            @focus="errObj.name = false" />
                          <div class="ertext">
                            <span v-if="errObj.name">样品名称不可为空</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_line">
                    <!-- 样品总数量 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span class="text_top">*</span>
                          <span>样品总数量</span>
                        </div>
                        <div class="main">
                          <el-input
                            v-model="inputCountValue"
                            class="inp"
                            @input="is_inputCount"
                            @change="changeStatus_val"
                            placeholder="请输入样品总数量"
                            suffix-icon=""
                            :class="errObj.count ? 'inp_err' : ''"
                            @focus="errObj.count = false" />
                          <div class="ertext">
                            <span v-if="errObj.count">{{ ErrCountText }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 可发放数量 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <!--                          <span class="text_top">*</span>-->
                          可发放数量
                        </div>
                        <div class="main">
                          <div class="val" style="color: #626366">
                            {{ status_val ? status_val : 0 }}
                          </div>
                          <div class="ertext status_error" v-if="is_statusError">
                            <span>可发放数量不可为空</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_line">
                    <!-- 样品标签 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span>样品标签</span>
                        </div>
                        <div class="main">
                          <el-select v-model="inputLableValue" placeholder="请选择" multiple collapse-tags collapse-tags-tooltip>
                            <el-option
                              v-for="item in SampleTags"
                              :key="item"
                              :label="item"
                              :value="item"
                              :disabled="item == '暂无标签，请前往【自定义设置】新建'" />
                          </el-select>
                        </div>
                      </div>
                    </div>
                    <!-- 样品规格 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span>样品规格</span>
                        </div>
                        <div class="main">
                          <el-input v-model="inputSpecsValue" class="inp" placeholder="请输入样品规格" suffix-icon="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_line">
                    <!-- 样品性状 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span>样品性状</span>
                        </div>
                        <div class="main">
                          <el-input v-model="inputCharacterValue" class="inp" placeholder="请输入样品性状" suffix-icon="" />
                        </div>
                      </div>
                    </div>
                    <!-- 样品批号 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span>样品批号</span>
                        </div>
                        <div class="main">
                          <el-input v-model="inputBatchNumberValue" class="inp" placeholder="请输入样品批号" suffix-icon="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_line">
                    <!-- 储存条件 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span>储存条件</span>
                        </div>
                        <div class="main">
                          <el-input v-model="inputSaveConditionValue" class="inp" placeholder="请输入存储条件" suffix-icon="" />
                        </div>
                      </div>
                    </div>
                    <!-- 样品接收日期 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">样品接收日期</div>
                        <div class="main" :class="errObj.date ? 'inp_err' : ''">
                          <el-date-picker
                            class="r_picker"
                            v-model="receiveDateValue"
                            type="date"
                            placeholder="请选择"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                            @focus="errObj.receiveDate = false" />
                          <div class="ertext">
                            <span v-if="errObj.date">必选项不可为空</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_line">
                    <!-- 限制使用日期 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">限制使用日期</div>
                        <div class="main" :class="errObj.date ? 'inp_err' : ''">
                          <el-date-picker
                            class="l_picker"
                            v-model="limitedUsedDateValue"
                            type="date"
                            placeholder="请选择"
                            format="YYYY-MM-DD"
                            value-format="YYYY-MM-DD"
                            @change="is_Expire"
                            :disabled-date="disabledDate" />
                          <div class="ertext">
                            <span v-if="errObj.date">必选项不可为空</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 是否过期 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span>是否过期</span>
                        </div>
                        <div class="main">
                          <div class="val">{{ isExpire }}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_line">
                    <!-- 是否销毁 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span>是否销毁</span>
                        </div>
                        <div class="main">
                          <el-select v-model="isDestoryArrValue" class="select" placeholder="请选择" size="large">
                            <el-option v-for="item in isDestoryArr" :key="item.label" :label="item.label" :value="item.label" />
                          </el-select>
                          <div class="ertext">
                            <span v-if="errObj.isDestory">必选项不可为空</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 建议检测项目类别 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span>建议检测项目类别</span>
                        </div>
                        <div class="main">
                          <el-input v-model="inputAdviceTestTypeValue" class="inp" placeholder="请输入建议检测项目类别" suffix-icon="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 样品使用方法 -->
                  <div class="list_line">
                    <div class="info">
                      <div class="list_content">
                        <div class="text">
                          <span>样品使用方法</span>
                        </div>
                        <div class="main">
                          <el-input
                            v-model="inputMethodValue"
                            class="bigInp"
                            resize="none"
                            type="textarea"
                            :autosize="{ minRows: 4, maxRows: 4 }"
                            placeholder="请输入样品使用方法"
                            suffix-icon="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_line">
                    <!-- 仪器管理员 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span>样品管理员</span>
                        </div>
                        <div class="main">
                          <el-select v-model="adminArrValue" class="select" placeholder="请选择" size="large">
                            <el-option v-for="item in userOptions" :key="item.value" :label="item.label" :value="item.value" />
                          </el-select>
                          <div class="ertext">
                            <span v-if="errObj.admin">必选项不可为空</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 备注 -->
                    <div class="info">
                      <div class="list_content">
                        <div class="text" id="shortTe">
                          <span>备注</span>
                        </div>
                        <div class="main">
                          <el-input v-model="inputNotesValue" class="inp" placeholder="请输入备注" suffix-icon="" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="list_line">
                    <!-- 仪器管理员 -->
                    <div class="info">
                      <div class="list_content" id="left_content">
                        <div class="text">
                          <span>是否留样</span>
                        </div>
                        <div class="main">
                          <el-select v-model="ReserveSampleValue" class="select" placeholder="请选择" size="large">
                            <el-option v-for="item in ReserveSampleArr" :key="item.value" :label="item.label" :value="item.value" />
                          </el-select>
                          <div class="ertext">
                            <span v-if="errObj.admin">必选项不可为空</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 留样数量 -->
                    <div class="info" v-show="ReserveSampleValue == 1">
                      <div class="list_content">
                        <div class="text" id="shortTe">
                          <span>留样数量</span>
                        </div>
                        <div class="main">
                          <el-input v-model="ReserveSampleNumber" class="inp" placeholder="请输入留样数量" suffix-icon="" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 文件 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">文件</div>
            </div>
            <!-- tips -->
            <!-- <div class="file"><img src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%E7%AE%AD%E5%A4%B4%402x.png" alt="" />上传附件</div> -->
            <div class="files" v-if="Volunteereditor">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%208111%402x.png" alt="" />
            </div>
            <div class="file" v-if="!Volunteereditor">
              <el-upload
                class="div"
                ref="videoRef"
                align="center"
                :disabled="true"
                :http-request="uploadsFile"
                :on-preview="handlePreviews"
                :on-remove="handleRemoves"
                :file-list="fileLists"
                :show-file-list="false">
                <img class="uploadIcon" src="https://newstore.vynior.com/%E4%B8%8A%E4%BC%A0%E7%AE%AD%E5%A4%B4%402x.png" alt="" />
                <!-- //!样式 -->
                <span>上传附件</span>
              </el-upload>
            </div>
            <div class="Table">
              <el-table :data="attachmentList" border stripe v-show="attachmentList.length">
                <el-table-column type="index" align="center" width="56" min-width="55" label="序号" />

                <el-table-column prop="fileName" align="center" label="文件名称" width="470" min-width="259">
                  <template #default="scope">
                    <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff">
                      {{ scope.row.fileName }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="uploadTime" align="center" label="上传时间" width="208" min-width="207">
                  <template #default="scope">
                    <div style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #626366">
                      {{ formatTime(scope.row.uploadTime) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="操作" width="300" align="center" min-width="212">
                  <template #default="scope">
                    <div
                      class="download"
                      v-if="Volunteereditor"
                      @click="downloadAttachment(scope.row)"
                      style="
                        font-size: 14px;
                        cursor: pointer;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #027aff;
                      ">
                      下载
                    </div>
                    <div
                      class="delete"
                      v-if="Volunteereditor"
                      style="
                        font-size: 14px;
                        margin-left: 16px;
                        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                        font-weight: 400;
                        color: #027aff;
                      ">
                      删除
                    </div>
                    <el-button
                      v-if="!Volunteereditor"
                      @click="downloadAttachment(scope.row)"
                      style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                      >下载</el-button
                    >
                    <el-button
                      v-if="!Volunteereditor"
                      @click="deleteFile(scope.row.uuid)"
                      style="font-size: 14px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #027aff"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- 样品检测项目信息 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">样品检测项目信息</div>
            </div>
            <div class="item_main_box">
              <div class="item2" style="margin-top: 0">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品检测项目编号</div>
                      <div class="item_main_text_value" :class="{ beGray: information.testProject_code == '' }">
                        {{ information.testProject_code == '' ? '在【项目】-【样品】中链入后自动填入' : information.testProject_code }}
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品检测项目名称</div>
                      <div class="item_main_text_value" :class="{ beGray: information.testProject_name == '' }">
                        {{ information.testProject_name == '' ? '在【项目】-【样品】中链入后自动填入' : information.testProject_name }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
              <div class="item2">
                <el-row>
                  <el-col :span="12">
                    <div class="item_box">
                      <div class="item_mian_text">样品检测状态</div>
                      <div class="item_main_text_value" :class="{ beGray: information.testing_status == '' }">
                        {{ information.testing_status == '' ? '在样品流转记录中链入后自动填入' : information.testing_status }}
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
          </div>

          <!-- !样品分发记录信息 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">样品分发记录信息<span class="text_test">根据发放时的输入内容自动填入</span></div>
            </div>
            <div class="Table1">
              <el-table
                border
                :data="information.issuance_records == '——' ? [] : information.issuance_records"
                style="width: 1032px; margin-top: 11px; margin-left: 32px"
                :header-cell-style="{
                  // 'text-align': 'center',
                  'font-size': '16px',
                  background: '#F8F8F8 !important',
                  color: '#313233',
                  'font-weight': '400',
                  'font-family': 'Source Han Sans CN-Regular, Source Han Sans CN',
                  border: '1px solid #eeeeee !important',
                }"
                :cell-style="{
                  'font-size': '16px',
                  // 'background': '#F8F8F8 !important',
                  color: '#313233',
                  'font-weight': '400',
                  'font-family': 'Source Han Sans CN-Regular, Source Han Sans CN',
                  border: '1px solid #eeeeee !important',
                }">
                <el-table-column label="序号" width="59">
                  <template #default="scope">
                    <div style="text-align: center">{{ scope.$index + 1 }}</div>
                    <!-- </div> -->
                  </template>
                </el-table-column>
                <el-table-column label="发放日期" width="180">
                  <template #default="scope">
                    <div style="color: #626366">
                      {{ scope.row.date.substring(0, 10) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="发放对象" width="300">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.name_email }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="发放数量" width="180">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.num }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="备注" width="316">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.remark }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- !样品回收记录信息 -->
          <div class="information_box">
            <div class="item_title_box">
              <div class="line"></div>
              <div class="item_title">样品回收记录信息<span class="text_test">根据回收时的输入内容自动填入</span></div>
            </div>
            <div class="Table1">
              <el-table
                border
                :data="information.recycle_records"
                style="width: 1032px; margin-top: 11px; margin-left: 32px; margin-bottom: 42px"
                :header-cell-style="{
                  // 'text-align': 'center',
                  'font-size': '16px',
                  background: '#F8F8F8 !important',
                  'font-family': 'Source Han Sans CN-Regular, Source Han Sans CN',
                  color: '#313233',
                  'font-weight': '400',
                  border: '1px solid #eeeeee !important',
                }"
                :cell-style="{
                  'font-size': '16px',
                  // 'background': '#F8F8F8 !important',
                  color: '#313233',
                  'font-family': 'Source Han Sans CN-Regular, Source Han Sans CN',
                  'font-weight': '400',
                  border: '1px solid #eeeeee !important',
                }">
                <el-table-column label="序号" width="56">
                  <template #default="scope">
                    <div style="text-align: center">{{ scope.$index + 1 }}</div>
                    <!-- </div> -->
                  </template>
                </el-table-column>
                <el-table-column label="回收日期" width="180">
                  <template #default="scope">
                    <div style="color: #626366">
                      {{ scope.row.date.substring(0, 10) }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="回收对象" width="300">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.user }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="回收数量" width="180">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.num }}</div>
                  </template>
                </el-table-column>
                <el-table-column label="备注" width="316">
                  <template #default="scope">
                    <div style="color: #626366">{{ scope.row.remark }}</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <!-- 抽屉底部 -->
        <div class="drawer_footer" v-if="!Volunteereditor">
          <div class="null">
            <img @click="cencelDataChange" src="https://newstore.vynior.com/%E5%8F%96%E6%B6%882.png" alt="" />
          </div>
          <div class="next">
            <img @click="updateSample" src="https://newstore.vynior.com/%E7%A1%AE%E5%AE%9A2.png" alt="" />
          </div>
        </div>
      </div>
    </el-drawer>
    <!-- 批量样品信息  删除 -->
    <div class="Derived_volunteer_delect" v-if="isDerived_delect">
      <div class="Derived_volunteer_box">
        <!-- 批量操作删除一个 -->
        <div v-if="selectedRows.length == 1 && oneDerivedData == null">
          <div class="Derived_title">
            确认删除&nbsp;&nbsp;
            <span class="Derived_name">
              {{ selectedRows[0].name == ' ' ? '无名字' : selectedRows[0].name }}
            </span>
            &nbsp;&nbsp; 的样品信息吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived_delect">取消</div>
            <div class="ok" @click="isshowDerived_delect_true">删除</div>
          </div>
        </div>
        <!-- 批量操作删除多个 -->
        <div v-if="selectedRows.length > 1 && oneDerivedData == null">
          <div class="Derived_title">
            已选中&nbsp;&nbsp;<span class="Derived_name">{{ selectedRows.length }}条</span>&nbsp;&nbsp;样品信息，确认删除吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived_delect">取消</div>
            <div class="ok" @click="isshowDerived_delect_true">删除</div>
          </div>
        </div>
        <!-- !单个删除操作 -->
        <div v-if="oneDerivedData">
          <div class="Derived_title">
            确认删除&nbsp;&nbsp;<span class="Derived_name"> {{ OptionsData.name }} </span>&nbsp;&nbsp;的样品信息吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">删除后将无法恢复</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isshowDerived_delect">取消</div>
            <div class="ok" @click="oneDeleteVolun">删除</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 无数据删除 -->
    <div class="Derived_volunteer_null" v-if="null_reminder_delect">
      <div class="Derived_volunteer_box">
        <div class="Derived_title">
          <div class="Derived_name">提示</div>
          <div>
            <img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
          </div>
        </div>
        <div class="Derived_body">
          <div>
            <img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" />
          </div>
          <div class="Derived_body_text">请选择需要删除的样品信息！</div>
        </div>
      </div>
    </div>
    <!-- !表头字段设置 -->
    <div class="SetTableHeader" v-if="SetTableHeader">
      <div class="main">
        <div class="Header">
          <div class="text">
            表头字段设置
            <img @click="CancelShowHiden" src="https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88%402x.png" alt="" />
          </div>
        </div>
        <!-- 设置表头的数据盒子 -->
        <div class="SetBox">
          <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
          <div class="LeftBox">
            <div class="text-header">样品库预设字段</div>
            <div class="ProjectRowField">
              <div class="text-header1">【样品基本信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetVolunteerHeaderArr.slice(0, 20)"
                  :key="index"
                  :class="defaultHeader.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                </div>
              </div>
            </div>
            <!-- 【系统信息】 -->
            <div class="ProjectRecruitMSG">
              <div class="text-header2">【样品检测项目信息】</div>
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div class="Checkbox" v-for="(item, index) in SetVolunteerHeaderArr.slice(21, 23)" :key="index">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 20)" />
                </div>
              </div>
            </div>
            <div class="Self-Defined">自定义扩展字段</div>
          </div>
          <!-- 右边盒子 拖动 -->
          <div class="RightBox">
            <div class="text-header">项目列表预设字段</div>

            <!-- 调整数据 -->
            <div class="switchBox">
              <div id="xxx" class="demo">
                <draggable
                  :list="dragDemo"
                  ghost-class="ghost"
                  :force-fallback="true"
                  chosen-class="chosenClass"
                  animation="300"
                  handle=".move"
                  delay="100">
                  <template #item="{ element }">
                    <div class="item move">
                      <div class="drag"></div>
                      {{ element.lable }}
                      <!-- -->
                      <img
                        class="top"
                        @click="backTop(element, 'top')"
                        src=" https://newstore.vynior.com/cems_web/%E7%BB%841112%402x.png   "
                        alt="" />
                      <img
                        class="delete"
                        @click="backTop(element, 'delete')"
                        :src="
                          defaultHeader.indexOf(element.lable) != -1
                            ? 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88s%402x.png'
                            : 'https://newstore.vynior.com/cems_web/%E5%8F%96%E6%B6%88221%402x.png'
                        "
                        alt="" />
                    </div>
                  </template>
                </draggable>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="Bottom">
          <div class="add">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
          </div>
          <div class="recover" @click="RecoverData">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
          </div>
          <div class="cancel" @click="CancelShowHiden">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" />
          </div>
          <div class="save" @click="ShowHidenColumn">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 显示功能弹窗 -->
    <div class="SetShowsBox" ref="ShowBox" v-show="SetShow" id="SetShowsBoxs">
      <div class="Titles" id="SetShowsBoxs">字段显示</div>
      <div class="ContentBox" :class="AllHidenHeader.length == 0 ? 'Emptys' : ''" id="SetShowsBoxs">
        <span class="Empty" v-if="AllHidenHeader.length == 0" id="SetShowsBoxs">暂无隐藏字段</span>
        <div class="ListBox" v-if="AllHidenHeader.length != 0" id="SetShowsBoxs">
          <div class="List" v-for="(item, index) in AllHidenHeader" :key="index" @click="ShowCheck[index] = !ShowCheck[index]" id="SetShowsBoxs">
            <div class="item" id="SetShowsBoxs">{{ item.title }}</div>
            <div class="Active" v-show="ShowCheck[index]" id="SetShowsBoxs">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110500%402x.png" alt="" />
            </div>
          </div>
          <div
            class="Button"
            @click="ConfirmRecover"
            id="SetShowsBoxs"
            :class="AllHidenHeader.length == 1 ? 'Buttons' : AllHidenHeader.length == 2 ? 'Buttonss' : ''">
            确认
          </div>
        </div>
      </div>
    </div>
    <!-- 发放样品弹窗 -->
    <div class="Sample_recovery" v-if="isSample_release">
      <div class="Issue_samples_box">
        <div class="top">
          <div class="top_text">样品发放</div>
          <div @click="isSample_release = !isSample_release" class="chacha"></div>
        </div>
        <div class="main">
          <div class="topItem">
            <div class="item">
              <div class="item_box">
                <div class="lable">样品编号</div>
                <div class="key">{{ information.code }}</div>
              </div>
              <div class="item_box">
                <div class="lable">样品总数量</div>
                <div class="key">{{ information.total }}</div>
              </div>
            </div>
            <div class="item1">
              <div class="item_box">
                <div class="lable">样品名称</div>
                <div class="key">{{ information.name }}</div>
              </div>
              <div class="item_box">
                <div class="lable">发放日期</div>
                <div class="key">{{ data(1) }}</div>
              </div>
            </div>
          </div>
          <div>
            <el-form :model="specimen_data">
              <div class="starList">
                <div class="star">*</div>
                <el-form-item label="发放对象">
                  <el-select v-model="specimen_data.samples_Name" filterable class="m-2" placeholder="输入以搜索..." size="large">
                    <el-option v-for="item in options_data" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </div>
              <div class="starList">
                <div class="star">*</div>
                <el-form-item label="发放数量">
                  <el-input
                    type="number"
                    v-model="specimen_data.Sample_recovery_num"
                    :max="information.available_quantity"
                    min="1"
                    @input="recoveryNumInput"
                    placeholder="请输入发放数量" />
                </el-form-item>
              </div>
              <div class="starList1">
                <el-form-item label="备注" label-width="60px">
                  <el-input v-model="specimen_data.Sample_recovery_remark" placeholder="请输入备注" />{{}}
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <div class="footer">
          <div class="cancel" @click="isSample_release = !isSample_release">取消</div>
          <div class="ture" @click="push_Sample_release">确定</div>
        </div>
      </div>
    </div>
    <!--  回收样品弹窗 -->
    <div class="Issue_samples" v-if="isSample_recovery">
      <div class="Issue_samples_box">
        <div class="top">
          <div class="top_text">样品回收</div>
          <div @click="isSample_recovery = !isSample_recovery" class="chacha"></div>
        </div>
        <div class="main">
          <div class="topItem">
            <div class="item">
              <div class="item_box">
                <div class="lable">样品编号</div>
                <div class="key">{{ information.code }}</div>
              </div>
              <div class="item_box">
                <div class="lable">样品发放数量</div>
                <div class="key">
                  {{ information.total - information.available_quantity }}
                </div>
              </div>
            </div>
            <div class="item1">
              <div class="item_box">
                <div class="lable">样品名称</div>
                <div class="key">{{ information.name }}</div>
              </div>
              <div class="item_box">
                <div class="lable">回收日期</div>
                <div class="key">{{ data(1) }}</div>
              </div>
            </div>
          </div>
          <div class="mediaItem">
            <el-form :model="specimen_data">
              <div class="starList">
                <div class="star">*</div>
                <el-form-item label="回收对象">
                  <el-select v-model="specimen_data.samples_Name" @change="samplesNameChange" class="m-2" placeholder="输入以搜索..." size="large">
                    <el-option v-for="item in options_data" :key="item.value" :label="item.label" :value="item.value" />
                  </el-select>
                </el-form-item>
              </div>
              <div class="starList">
                <div class="star">*</div>
                <el-form-item label="回收数量">
                  <el-input v-model="specimen_data.Issue_samples_num" placeholder="请输入发放数量" />
                </el-form-item>
              </div>
              <div class="starList1">
                <el-form-item label="备注" label-width="60px">
                  <el-input v-model="specimen_data.Issue_samples_remark" placeholder="请输入备注" />
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <div class="footer">
          <div class="cancel" @click="isSample_recovery = !isSample_recovery">取消</div>
          <div class="ture" @click="push_Sample_recovery">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import CreatedVolunteer from '@/components/SampleManagement/CreateSample.vue';
import data from '@/utils/CurrentDate';
import { replaceEmptyValues } from '@/utils/emptyArray';
import getFilename from '@/utils/getFilename';
import http from '@/utils/http';
import { message } from '@/utils/resetMessage';
import dayjs from 'dayjs';
import { ElMessage } from 'element-plus';
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import * as xlsx from 'xlsx';
import draggable from 'vuedraggable';
import ase from '@/utils/cryptoUtils';
//API接收
const store = useStore();
const router = useRouter();
const xTable = ref();
// 数据--格式化
const formatterData = (val) => {
  return val === undefined || val === '' || val === null ? '——' : val;
};
// 列表适配处理
const linewidth = ref(window.innerWidth);
const handleResize = () => {
  linewidth.value = window.innerWidth;
};
const checkDate = (dateStr) => {
  const today = new Date(); // 当前日期
  const targetDate = new Date(dateStr); // 目标日期
  // 只比较日期部分，将时分秒设为0
  today.setHours(0, 0, 0, 0);
  targetDate.setHours(0, 0, 0, 0);
  return targetDate < today; // 判断目标日期是否小于等于当前日期
};
//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [],
  filterName: '',
  list: [],
  gender: '',
  age: '',
  loading: true,
});
//分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
});
// sort排序字段
let SORT = ref({ createdAt: -1 });
let ClickMoreOptions = ref(false);
onMounted(() => {
  getNowDate();
  handleResize();
  ReqDefault();
  selectMembersChange(); //获取样品分发员
  getSamplelistRes(); //获取全部数据
  window.addEventListener('resize', handleResize);
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (isshowOperation.value == true) {
      if (e.target.id != 'operationBox') {
        isshowOperation.value = false;
      }
    }
  });
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false;
      }
    }
  });
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false;
      }
    }
    // 操作
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false;
      }
      console.log(ClickMoreOptions.value);
    }
  });
});
//!  ????获取全部样品数据
const getSamplelistRes = async (index) => {
  demo1.loading = true;
  try {
    const res = await http.post('/sample/fetchSampleList', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: { createdAt: -1 },
      filter: demo1.filterName,
    });
    demo1.loading = false;
    page5.totalResult = res.result.totalCount;
    console.log(res.result, '获取全部样品数据');
    demo1.tableData = res.result.sampleList;
    console.log('res3 is', res.result);

    if (res.status == 0) {
      demo1.loading = false;
    }
  } catch (error) {
    console.log(error);
  }
};
// 样品标签数据
let SampleTags = ref([]);
//请求自定义样品标签数据
const ReqDefault = async () => {
  const res = await http.post('/setting/fetch-setting', {
    type: 'sample',
  });
  if (res.status == 0) {
    console.log('res请求初始数据为 :>> ', res);

    res.tags.forEach((item, index) => {
      if (item.value != false) {
        SampleTags.value.push(item.name);
      }
    });

    if (SampleTags.value.length == 0) {
      SampleTags.value.push('暂无标签，请前往【自定义设置】新建');
    }
    console.log('获取的最终数据', SampleTags.value);
  }
};
// 模糊搜索 判断是否是第一次
// const EventSearch = ref(false);
const FixedIndex = ref(-1);
const showIdOptions = ref(false); //更多操作盒子的显示/隐藏
const OperationBox = ref(null); //获取更多操作盒子的ref
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  console.log('value :>> ', value);
  const table = xTable.value;
  const FixedList = table.getColumns();
  FixedList.forEach((item, index) => {
    if (item.title == value) {
      FixedIndex.value = index;
    }
  });
  //console.log('FixedIndex下标为 :>> ', FixedIndex);
  showIdOptions.value = false;
  showIdOptions.value = true;
  // 设置位置
  const Event = event;
  OperationBox.value.style.left = event.clientX - 370 + 'px';
  OperationBox.value.style.top = event.clientY - 40 + 'px';
};
// More -->升序/降序
const AscendingSort = async (index, type) => {
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  const ColumnId = tableColumns[index].field;
  console.log(ColumnId, 'ColumnId');
  const sorts = {};
  if (type == 'Asc') {
    sorts[ColumnId] = 1;
  } else {
    sorts[ColumnId] = -1;
  }
  SORT.value = sorts;
  console.log(SORT.value, 'SORT.value');
  searchSampleListChange();
};
//  More -->固定/取消固定
const toggleFixedColumn = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  console.log(index, type, '获取的表格数据');
  showIdOptions.value = false;
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
// 取消固定
const toggleFixedColumns = (index, type) => {
  //   //console.log('我的下标是: :>> ', index);
  showIdOptions.value = false;
  const $table = xTable.value;
  const tableColumns = $table.getColumns();
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index];
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type;
  }
  // 刷新列
  $table.refreshColumn();
};
// 全部显示里被隐藏的
let AllHidenHeader = ref([]);
const ShowBox = ref(null); //获取Show盒子的ref
const ShowCheck = ref([]);
const SetShow = ref(false);
// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false;
  const $table = xTable.value;
  const column = $table.getColumns();
  // console.log('column[index] :>> ', column[index]);
  column[index].visible = false;
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value.push(column[index]);
  ShowCheck.value.push(false);
  $table.refreshColumn(); //刷新列表
  console.log('ShowChcke.value :>> ', ShowCheck.value);
  // console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
};
// ! 获取event 设置show显示框的位置
const SetShowFunction = (event) => {
  SetShow.value = false;
  SetShow.value = true;
  // console.log('event :>> ', event, ShowBox.value);
  ShowBox.value.style.left = event.clientX - 10 + 'px';
  ShowBox.value.style.top = event.clientY + 20 + 'px';
};
// ! 恢复目前隐藏的列表 确认按钮
const ConfirmRecover = () => {
  let TeShowCheck = [];
  let TeAllHidenHeader = [];
  const $table = xTable.value;
  const column = $table.getColumns();
  console.log('ShowCheck.value :>> ', ShowCheck.value);
  for (let i = ShowCheck.value.length; i >= 0; i--) {
    if (ShowCheck.value[i] == true) {
      // 显示列
      $table.showColumn(AllHidenHeader.value[i].field);
      // 同步删除 数据 隐藏列/checked
      AllHidenHeader.value.splice(i, 1);
      ShowCheck.value.splice(i, 1);
    }
  }
  SetShow.value = false;
};
// 模糊搜索
const searchSampleListChange = async () => {
  // EventSearch.value = true;
  const res = await http.post('/sample/fetchSampleList', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    filter: demo1.filterName,
  });
  console.log('全局搜索的返回结果res :>> ', res);
  if (res.status == 0) {
    demo1.loading = false;
    page5.totalResult = res.result.totalCount;
    demo1.tableData = res.result.sampleList;
  }
};
// ! 样品回收销毁数据
const isSample_recovery = ref(false); //回收
const isSample_release = ref(false); //发放
const specimen_data = reactive({
  //回收和分发
  samples_Name: '',
  Issue_samples_name: '', //回收
  Issue_samples_num: '',
  Issue_samples_remark: '',
  Sample_recovery_name: '', //分发
  Sample_recovery_num: '',
  Sample_recovery_remark: '',
});
// !发放
const Sample_release_way = (row) => {
  console.log('发放的row是', row);
  isSample_release.value = !isSample_release.value;
  information.value = JSON.parse(JSON.stringify(row));
  specimen_data.samples_Name = '';
  specimen_data.Sample_recovery_num = '';
  specimen_data.Sample_recovery_remark = '';
  // demo1.tableData.forEach((item, index) => {
  // if (item._id == row._id) {
  // information.value = JSON.parse(JSON.stringify(item));
  // information1.value = JSON.parse(JSON.stringify(item));
  // }
  // });
};
// 输入发放数量触发事件
const recoveryNumInput = () => {
  if (specimen_data.Sample_recovery_num > information.value.available_quantity) {
    specimen_data.Sample_recovery_num = information.value.available_quantity;
    message({
      message: '发放数量不可大于可发放数量',
      grouping: true,
      type: 'error',
      // close: 'none',
    });
  }
};
// 上传附件事件
const uploadsFile = async (params) => {
  return;
  const file = params.file;
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', 'attachment');
  const response = await http.post('/upload/file-upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  const { status, file: fileData } = response;
  if (status === 0) {
    attachmentList.value.push(fileData);
    ElMessage({
      message: '附件上传成功',
      // grouping: true,
      showClose: true,
      type: 'success',
    });
  } else {
    ElMessage({
      message: '附件上传失败，请稍后再试',
      // grouping: true,
      showClose: true,
      type: 'warning',
    });
  }
};
// ! options 发放对象的数据
const options_data = ref([
  {
    value: '111',
    label: '222',
  },
]);
// 获取样品分发员
const selectMembersChange = async () => {
  const res = await http.post('/user/getSampleDistributorOptions', {
    company: localStorage.getItem('company'),
  });
  console.log(res, '获取样品分发员');
  if (res.status == 0) {
    options_data.value = res.result;
  }
};
// ! 点击发放样品数据
const push_Sample_release = async () => {
  if (specimen_data.Sample_recovery_num == 0) {
    message({
      message: '发放成功',
      grouping: true,
      type: 'success',
    });
    isSample_release.value = !isSample_release.value;

    specimen_data.samples_Name = '';
    specimen_data.Sample_recovery_num = '';
    specimen_data.Sample_recovery_remark = '';
    return;
  }

  const reg = /^[1-9]\d*$/;
  let isTrueOfspecimenName = reg.test(specimen_data.Sample_recovery_num);

  if (!isTrueOfspecimenName) {
    message({
      message: '发放数量只能为正整数',
      grouping: true,
      type: 'error',
    });
    // isSample_recovery.value = !isSample_recovery.value;
    // specimen_data.samples_Name = "";
    // specimen_data.Issue_samples_num = "";
    // specimen_data.Issue_samples_remark = "";
    return;
  }

  if (specimen_data.Sample_recovery_num < 0) {
    message({
      message: '发放数量不能为负数',
      grouping: true,
      type: 'error',
    });
    return;
  }
  if (specimen_data.Sample_recovery_num > information.value.sample_total) {
    message({
      message: '发放数量不能大于样品总数量',
      grouping: true,
      type: 'error',
    });
    return;
  }

  if (specimen_data.Sample_recovery_num > information.value.available_quantity) {
    message({
      message: '发放数量不能大于样品可发放数量',
      grouping: true,
      type: 'error',
    });
    return;
  }
  console.log('specimen_data.samples_Name is ', specimen_data.samples_Name);
  if (specimen_data.samples_Name.length == 0) {
    message({
      message: '请输入样品发放对象',
      grouping: true,
      type: 'error',
    });
    return;
  }

  if (specimen_data.Sample_recovery_num.length == 0) {
    message({
      message: '请输入样品发放数量',
      grouping: true,
      type: 'error',
    });
    return;
  }
  const res = await http.post('/sample/issuanceSample', {
    sampleId: information.value._id,
    date: data(1),
    user: specimen_data.samples_Name,
    num: Number(specimen_data.Sample_recovery_num),
    remark: specimen_data.Sample_recovery_remark,
  });
  if (res.status == 0) {
    // reqProjectList();
    isSample_release.value = !isSample_release.value;
    message({
      message: '发放成功',
      grouping: true,
      type: 'success',
    });
    searchSampleListChange();
  } else {
    message({
      message: '发放失败',
      grouping: true,
      type: 'error',
    });
  }
  // try {
  //   await http.post('/sample/provide-sample', {
  //     sampleId: information.value._id,
  //     date: data(1),
  //     user: specimen_data.samples_Name,
  //     num: Number(specimen_data.Sample_recovery_num),
  //     remark: specimen_data.Sample_recovery_remark,
  //   });
  //   reqProjectList();
  //   isSample_release.value = !isSample_release.value;
  //   message({
  //     message: '发放成功',
  //     grouping: true,
  //     type: 'success',
  //   });
  //   specimen_data.samples_Name = '';
  //   specimen_data.Sample_recovery_num = '';
  //   specimen_data.Sample_recovery_remark = '';
  // } catch (error) {
  //   console.log(error);
  //   message({
  //     message: '发放失败',
  //     grouping: true,
  //     type: 'error',
  //   });
  // }
};
// 样品绑定的项目id
let currentProject_idOfsampleMan = ref(null);
// ! 回收
const Sample_recovery_way = (row) => {
  console.log('回收的row', row);

  currentProject_idOfsampleMan.value = row.project_id;
  if (currentProject_idOfsampleMan.value == '——') {
    message({
      message: '请绑定项目',
      grouping: true,
      type: 'error',
    });
    return;
  }
  // selectMembersChange2();
  console.log('zhixing');
  isSample_recovery.value = !isSample_recovery.value;
  console.log('demo1.tableData', demo1.tableData);
  demo1.tableData.forEach((item, index) => {
    if (item._id == row._id) {
      information.value = JSON.parse(JSON.stringify(item));
      information1.value = JSON.parse(JSON.stringify(item));
    }
  });
};
// 样品回收，回收对象触发事件
const samplesNameChange = (val) => {
  console.log(options_data.value);
  console.log(val);
  console.log(specimen_data.samples_Name);
};
// ! 点击回收样品
const push_Sample_recovery = async () => {
  // if (specimen_data.Issue_samples_num == 0) {
  //   message({
  //     message: '回收成功',
  //     grouping: true,
  //     type: 'success',
  //   });
  //   isSample_recovery.value = !isSample_recovery.value;
  //   specimen_data.samples_Name = '';
  //   specimen_data.Issue_samples_num = '';
  //   specimen_data.Issue_samples_remark = '';
  //   return;
  // }
  const reg = /^[1-9]\d*$/;
  let isTrueOfspecimenName = reg.test(specimen_data.Issue_samples_num);

  if (!isTrueOfspecimenName) {
    message({
      message: '回收数量只能为正整数',
      grouping: true,
      type: 'warning',
    });
    // isSample_recovery.value = !isSample_recovery.value;
    // specimen_data.samples_Name = "";
    // specimen_data.Issue_samples_num = "";
    // specimen_data.Issue_samples_remark = "";
    return;
  }

  if (
    specimen_data.Issue_samples_num < 0

    // specimen_data.Issue_samples_num > information.value.provideRecords[0].num
  ) {
    message({
      message: '回收数量不能小于0',
      grouping: true,
      type: 'error',
    });
    return;
  }

  if (specimen_data.Issue_samples_num > information.value.recyclable_quantity) {
    message({
      message: '回收数量不能大于可回收数量',
      grouping: true,
      type: 'error',
    });
    return;
  }

  console.log('specimen_data.samples_Name is ', specimen_data.samples_Name);
  if (!specimen_data.samples_Name) {
    message({
      message: '请输入样品回收对象',
      grouping: true,
      type: 'error',
    });
    return;
  }

  if (specimen_data.Issue_samples_num.length == 0) {
    message({
      message: '请输入样品回收数量',
      grouping: true,
      type: 'error',
    });
    return;
  }
  const res = await http.post('/sample/recycleSample', {
    sampleId: information.value._id, //样品id
    date: data(1), //发放时间
    user: specimen_data.samples_Name, //用户id
    num: Number(specimen_data.Issue_samples_num), //发放数量
    remark: specimen_data.Issue_samples_remark, //备注
  });
  console.log(res, '样品回收返回');
  if (res.status == 0) {
    console.log('样品回收res', res);
    searchSampleListChange();
    isSample_recovery.value = !isSample_recovery.value;
    message({
      message: '回收成功',
      grouping: true,
      type: 'success',
    });
    specimen_data.samples_Name = '';
    specimen_data.Issue_samples_num = '';
    specimen_data.Issue_samples_remark = '';
  } else {
    message({
      message: '回收失败',
      grouping: true,
      type: 'error',
    });
  }
  // try {
  //   let res = await http.post('/sample/recycleSample', {
  //     sampleId: information.value._id, //样品id
  //     date: data(1), //发放时间
  //     user: specimen_data.samples_Name, //用户id
  //     num: Number(specimen_data.Issue_samples_num), //发放数量
  //     remark: specimen_data.Issue_samples_remark, //备注
  //   });
  //   if (res.status == 0) {
  //     console.log('样品回收res', res);
  //     reqProjectList();
  //     isSample_recovery.value = !isSample_recovery.value;
  //     message({
  //       message: '回收成功',
  //       grouping: true,
  //       type: 'success',
  //     });
  //     specimen_data.samples_Name = '';
  //     specimen_data.Issue_samples_num = '';
  //     specimen_data.Issue_samples_remark = '';
  //   }
  // } catch (error) {
  //   console.log(error);
  //   message({
  //     message: '回收失败',
  //     grouping: true,
  //     type: 'error',
  //   });
  // }
};
// 点击...
let exportOneID = '';
// 每一行的 ... 点击
const CMoreOptions = ref(null); //获取MoreOptions盒子的ref
let OptionsData = ref();
const ClickMethods = (row, event) => {
  OptionsData.value = row;
  exportOneID = row._id;
  console.log('exportOneID :>> ', exportOneID);
  ClickMoreOptions.value = false;
  ClickMoreOptions.value = true;
  //CMoreOptions.value.style.left = event.clientX - 120 + 'px';
  CMoreOptions.value.style.top = event.clientY + 20 + 'px';
};
let isoneDerived_delect = ref(false); // !  单个样品删除
// 每一条确定删除
const oneDeleteVolun = async () => {
  let id = oneDerivedData.value;
  console.log('确定删除的数据', id);
  let arr = [];
  arr[0] = id._id;
  const res = await http.post('/sample/delete', {
    idList: arr,
  });
  console.log(res, '单个删除返回');
  if (res.status == 0) {
    searchSampleListChange();
    isDerived_delect.value = false;
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    });
    checkedArr.value = [];
  } else {
    message({
      message: '删除失败',
      grouping: true,
      type: 'error',
    });
  }
  //   //console.log('row :>> ', row);
  // try {
  //   const res = await http.delete('/sample/delete-sample', [id]);
  //   reqProjectList();
  //   isoneDerived_delect.value = false;
  //   oneDerivedData.value = null;
  //   isDerived_delect.value = false;
  //   if (res.status == 0) {
  //     message({
  //       message: '删除成功',
  //       grouping: true,
  //       type: 'success',
  //     });
  //   }
  // } catch (error) {
  //   message({
  //     message: '删除失败',
  //     grouping: true,
  //     type: 'error',
  //   });
  // }
};

// 每一条的删除
const DeleteVolun = async () => {
  // console.log('点击的数据', row);
  // OptionsData
  isoneDerived_delect.value = true;
  isDerived_delect.value = true;
  oneDerivedData.value = OptionsData.value;
};

// 分页--->跳转页面显示数据处理
const pageChange = async () => {
  // 判断是否是高级搜索模式
  if (Advancedfind.value) {
    reqAllSelectData();
  } else {
    // 不是高级搜索就是普通的数据
    const res = await http.post('/sample/fetchSampleList', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: SORT.value,
      filter: demo1.filterName,
    });
    console.log(res, '获取翻页数据');
    demo1.loading = false;
    page5.totalResult = res.result.totalCount;
    demo1.tableData = res.result.sampleList;
  }
};
// 判断是否是高级搜索模式
let Advancedfind = ref(false);
// !重置按钮
const reset = () => {
  getSamplelistRes();
  const $table = xTable.value;
  Advancedfind.value = false; //重置高级搜索模式
  $table.clearCheckboxRow();
  checkedArr.value.splice(0, checkedArr.value.length); //清空勾选状态
  // EventSearch.value = false; //
  // GenderSearch.value = false;
  // AgeSearch.value = false;
  // 清空输入的搜索数据
  demo1.age = '';
  demo1.gender = '';
  demo1.filterName = '';
};
//复选框数组
var selectedRows = reactive([]);
const checkedArr = ref([]);
// 复选框点击事件
const selectChangeEvent1 = (row, checked) => {
  // console.log('最新:', row);

  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records;
  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid);
  } else {
    let index = checkedArr.value.indexOf(row.rowid);
    checkedArr.value.splice(index, 1);
  }
  console.log('最新:', checkedArr.value);
};
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value;
  const records = $table.getCheckboxRecords();
  console.log(checked ? '所有勾选事件' : '所有取消事件', records);
  selectedRows = records;
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id);
      }
    }
    console.log('最新:', checkedArr.value);
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = [];

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id);
    });

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item);
    });
    console.log('最新:', checkedArr.value);
  }
};
let isshowOperation = ref(false); // ! 控制批量操作的显示
const oneDerivedData = ref('');
let isDerived_delect = ref(false); // ! 删除样品弹窗控制显示隐藏
let null_reminder_delect = ref(false); // ! 没有勾选样品数据删除
// ! 点击批量删除
const delect = () => {
  if (checkedArr.value.length !== 0) {
    isshowOperation.value = false;
    isshowDerived_delect();
  } else {
    null_reminder_delect.value = true;
  }
};
// ! 关闭提醒弹窗
const Closereminder = () => {
  // null_reminder_export.value = false;
  null_reminder_delect.value = false;
};
// !删除样品信息弹窗  失败  取消
const isshowDerived_delect = () => {
  oneDerivedData.value = null;
  isDerived_delect.value = !isDerived_delect.value;
};
// ! !删除样品信息弹窗  失败  确定
const isshowDerived_delect_true = async () => {
  // !删除对应数据，并刷新页面
  const ids = selectedRows.map((obj) => obj._id);
  console.log(ids, 'idddddd'); // [1, 2, 3]
  const res = await http.post('/sample/delete', {
    idList: checkedArr.value,
  });
  if (res.status == 0) {
    searchSampleListChange();
    isDerived_delect.value = false;
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    });
    checkedArr.value = [];
  } else {
    message({
      message: '删除失败',
      grouping: true,
      type: 'error',
    });
  }
};
// 上传附件返回信息
const attachmentList = ref([]);
let information1 = ref({
  _id: '',
  batchNum: '',
  character: '',
  createdAt: '',
  destory: '',
  expired: '',
  files: [],
  isdelete: '',
  limitDate: '',
  management: '',
  parting_projects: {
    _id: '',
    name: '',
    number: '',
    status: '',
  },
  project: '',
  receiptDate: '',
  sample_name: '',
  sample_number: '',
  available_quantity: '',
  sample_total: '',
  tag: [],
  saveCondition: '',
  spec: '',
  suggestTest: '',
  updatedAt: '',
  usefunc: '',
  volun_birthday: '',
  retention: '',
  retentionNum: '',
});
//
let information = ref({
  _id: '',
  batchNum: '',
  character: '',
  createdAt: '',
  destory: '',
  expired: '',
  files: [],
  isdelete: '',
  limitDate: '',
  management: '',
  tag: [],
  parting_projects: {
    _id: '',
    name: '',
    number: '',
    status: '',
  },
  project: '',
  receiptDate: '',
  sample_name: '',
  sample_number: '',
  available_quantity: '', //可发放数量
  sample_total: '',
  saveCondition: '',
  spec: '',
  suggestTest: '',
  updatedAt: '',
  usefunc: '',
  volun_birthday: '',
});
// 样品id
let id = '';
let Volunteerdetails = ref(false); // !查看样品详情
let Volunteereditor = ref(true); // !查看样品详情编辑
// !每一行的查看详情
const ShowDetails = async (row) => {
  console.log('查看详情信息:', row);
  attachmentList.value = Array.isArray(row.files) ? row.files : [];
  information.value = row;
  id = row._id;
  console.log(row);
  Volunteerdetails.value = !Volunteerdetails.value;
  Volunteereditor.value = true;
};
//必选项是否为空提示
let errObj = reactive({
  number: false,
  name: false,
  count: false,
  status: false,
});
// 样品名称
let inputNameValue = ref('');
// 样品编号
let inputNumberValue = ref('');
// 样品总数量
let inputCountValue = ref('');
// 可发放数量
let statusArr = ref(['可用', '已发放', '已回收']);
//测可发放数量选中的值
let status_val = ref('');
//样品标签
let inputLableValue = ref([]);
let lableArr = ref(['成分好', '已发放', '已回收']);
//样品规格
let inputSpecsValue = ref('');
//样品性状
let inputCharacterValue = ref('');
//样品批号
let inputBatchNumberValue = ref('');
//存储条件
let inputSaveConditionValue = ref('');
// 样品接收日期
let receiveDateValue = ref();
// 限制使用日期
let limitedUsedDateValue = ref('');
// 是否销毁;
let isDestoryArrValue = ref();
// / 建议检测类别
let inputAdviceTestTypeValue = ref('');
// 样品使用方法
let inputMethodValue = ref('');
// 仪器管理员
let userOptions = ref([]);
// 样品管理员全部数据
let adminArrValue = ref('');
// 备注
let inputNotesValue = ref('');

let currentDate = '';
// 留样数量
const ReserveSampleNumber = ref();
// 留样
const ReserveSampleValue = ref('');
// 是否留样
const ReserveSampleArr = ref([
  { label: '是', value: 1 },
  { label: '否', value: 2 },
]);
// 是否过期
let isExpire = ref();
// 点击编辑
const isVolunteereditor = async () => {
  let res = await http.post('/user/getSampleManagerOptions', {
    company: localStorage.getItem('company'),
  });
  console.log(res, '获取的所有用户信息');
  if (res.status == 0) {
    userOptions.value = res.result;
  } else {
    console.log('获取失败');
  }
  Volunteereditor.value = !Volunteereditor.value;
  //information.value = JSON.parse(JSON.stringify(informations.value));
  //information.value = information1.value;
  console.log(information.value, '编辑时展示的数据');
  // if(informations.value.address.length>2&&Volunteereditor.value==false){
  // informationsAddress.value = information.value.address;
  // 样品基础信息
  //样品名称
  id = information.value._id;
  inputNameValue.value = information.value.name;
  //样品编号
  inputNumberValue.value = information.value.code;
  // 样品总数量
  inputCountValue.value = information.value.total;
  // 可发放数量information.available_quantity

  status_val.value = information.value.available_quantity;
  //   != inputCountValue.value
  //     ? information.value.available_quantity
  //     : inputCountValue.value - (information.value?.provideRecords[0]?.num ? information.value.provideRecords[0]?.num : 0);
  // console.log(information.value.provideRecords, '分发数量');
  //  样品规格
  inputSpecsValue.value = information.value.specs == '——' ? '' : information.value.specs;
  //  样品标签
  inputLableValue.value = information.value.tag == '——' ? '' : information.value.tag;
  //  样品性状
  inputCharacterValue.value = information.value.properties == '——' ? '' : information.value.properties;
  //  样品批号
  inputBatchNumberValue.value = information.value.batch_code == '——' ? '' : information.value.batch_code;
  // 存储条件
  inputSaveConditionValue.value = information.value.storage_conditions == '——' ? '' : information.value.storage_conditions;
  // 样品接收日期
  receiveDateValue.value = information.value.acceptance_date == '——' ? null : information.value.acceptance_date;
  // 限制使用日期
  limitedUsedDateValue.value = information.value.expiration_date == '——' ? null : information.value.expiration_date;
  // 是否过期
  isExpire.value = information.value.isexpired == true ? '是' : '否';
  // 是否销毁
  isDestoryArrValue.value = information.value.isdestory == true ? '是' : '否';
  // 建议检测类别
  inputAdviceTestTypeValue.value = information.value.testProject_suggest == '——' ? '' : information.value.testProject_suggest;
  // 样品使用方法
  inputMethodValue.value = information.value.usage_instructions == '——' ? '' : information.value.usage_instructions;
  // 样品管理员
  adminArrValue.value = information.value.manager == '——' ? '' : information.value.manager;
  //附件
  files: '';
  //备注
  inputNotesValue.value = information.value.remark == '——' ? '' : information.value.remark;
  // 是否留样
  ReserveSampleValue.value = information.value.isretained == true ? '是' : '否';
  // 留样数量
  ReserveSampleNumber.value = information.value.retained_quantity == '——' ? '' : information.value.retained_quantity;
};
// 编辑样品点击取消
const cencelDataChange = () => {
  // information.value.address = JSON.parse(JSON.stringify(informationsAddress.value));
  Volunteereditor.value = true;
};
// 效验样品编号
let is_inputNumber = () => {
  if (inputNumberValue.value === '') {
    errObj.number = true;
  } else {
    errObj.number = false;
  }
};
// 监听样品编号
watch(
  () => inputNumberValue.value,
  () => {
    is_inputNumber();
  },
);
let is_inputName = () => {
  if (inputNameValue.value === '') {
    errObj.name = true;
  } else {
    errObj.name = false;
  }
};
watch(
  () => inputNameValue.value,
  () => {
    is_inputName();
  },
);
const ErrCountText = ref();
let is_inputCount = () => {
  console.log(inputCountValue.value);
  if (inputCountValue.value === '') {
    errObj.count = true;
    ErrCountText.value = '样品总数量不能为空';
  } else if (
    !isNaN(inputCountValue.value) == false ||
    !Number.isInteger(parseInt(inputCountValue.value)) ||
    parseInt(inputCountValue.value) < 0 ||
    `${inputCountValue.value}`.includes('.') ||
    `${inputCountValue.value}`.includes('-')
  ) {
    errObj.count = true;
    ErrCountText.value = '样品总数量格式错误';
    console.log(123);
  } else {
    errObj.count = false;
  }
};
const changeStatus_val = () => {
  console.log(123456);
  console.log(information.value.provideRecords);
  if (issuance_records == '——') {
    status_val.value = inputCountValue.value;
  } else {
    let num = 0;
    issuance_records.forEach((item) => {
      num += item.num;
    });
    status_val.value = Number(inputCountValue.value) - num > 0 ? Number(inputCountValue.value) - num : 0;
    // status_val.value =
    //   information.value.available_quantity == inputCountValue.value
    //     ? information.value.available_quantity
    //     : inputCountValue.value - (information.value.provideRecords[0].num ? information.value.provideRecords[0].num : 0);
    // console.log(status_val.value);
  }
};
// 判断是否到期
let is_Expire = () => {
  if (new Date(limitedUsedDateValue.value) < new Date(current)) {
    console.log('到期了');
    isExpire.value = '是';
  } else {
    console.log('没到期');

    isExpire.value = '否';
  }
};
// 编辑样品确定按钮
let updateSample = async () => {
  is_inputName();
  is_inputNumber();
  // is_inputStatus();
  is_inputCount();
  const newFiles = [];
  if (errObj.name === false && errObj.number === false && errObj.count === false && status_val.value !== '') {
    // const newFiles = [];
    // attachmentList.value.forEach((attachment) => {
    //   // console.log('object :>> ', object);
    //   if (!attachment.downloadPath) {
    //     newFiles.push(attachment);
    //   }
    // });
    // console.log(newFiles, 'newFile文件');
    const res = await http.post('/sample/edit', {
      _id: information.value._id,
      // 样品基础信息
      //样品名称
      name: inputNameValue.value,
      //样品编号
      code: inputNumberValue.value,
      // 样品总数量
      total: Number(inputCountValue.value),
      // 可发放数量
      available_quantity: status_val.value,
      //  样品规格
      specs: inputSpecsValue.value,
      //  样品标签
      tag: inputLableValue.value,
      //  样品性状
      properties: inputCharacterValue.value,
      //  样品批号
      batch_code: inputBatchNumberValue.value,
      // 存储条件
      storage_conditions: inputSaveConditionValue.value,
      // 样品接收日期
      acceptance_date: receiveDateValue.value == 'Invalid Date' ? '' : receiveDateValue.value,
      // 限制使用日期
      expiration_date: limitedUsedDateValue.value == 'Invalid Date' ? '' : limitedUsedDateValue.value,
      // 是否过期
      isexpired: isExpire.value == '是' ? true : false,
      // 是否销毁
      destory: isDestoryArrValue.value == '是' ? true : false,
      // 建议检测类别
      testProject_suggest: inputAdviceTestTypeValue.value,
      // 样品使用方法
      usage_instructions: inputMethodValue.value,
      // 样品管理员
      manager: adminArrValue.value,
      //附件
      // files: newFiles,
      //备注
      remark: inputNotesValue.value,
      // 删除文件
      // deletefiles: deleteFiles.value,
    });
    console.log(res, '编辑样品返回');
    if (res.status == 0) {
      searchSampleListChange();
      information.value._id = information.value._id;
      information.value.code = inputNumberValue.value;
      information.value.name = '123456';
      information.value.total = inputCountValue.value;
      information.value.available_quantity = status_val.value;
      information.value.tag = inputLableValue.value;
      information.value.specs = inputSpecsValue.value;
      information.value.properties = inputCharacterValue.value;
      information.value.batch_code = inputBatchNumberValue.value;
      information.value.storage_conditions = inputSaveConditionValue.value;
      information.value.acceptance_date = receiveDateValue.value;
      information.value.expiration_date = limitedUsedDateValue.value;
      information.value.isexpired = isExpire.value == '是' ? true : false;
      information.value.destory = isDestoryArrValue.value == '是' ? true : false;
      information.value.testProject_suggest = inputAdviceTestTypeValue.value;
      information.value.usage_instructions = inputMethodValue.value;
      information.value.manager = adminArrValue.value;
      information.value.remark = inputNotesValue.value;
      userOptions.value.forEach((item) => {
        if (item.value == adminArrValue.value) {
          information.value.manager_name = item.label;
        }
      });
      console.log(information.value);
      Volunteereditor.value = true;
      message({
        message: '编辑成功',
        grouping: true,
        type: 'success',
      });
    }
  }
};
// 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true;
  TemporaryData.value = JSON.parse(JSON.stringify(SetVolunteerHeaderArr.value));

  //console.log('SetTableHeader', SetTableHeader.value);
};
// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([]);
// // 表头阻断设置  的点击复选框
const CheckHeader = (i) => {
  if (defaultHeader.value.indexOf(SetVolunteerHeaderArr.value[i].lable) != -1) {
    message({
      message: `${SetVolunteerHeaderArr.value[i].lable}不能删除`,
      grouping: true,
      type: 'error',
    });
    SetVolunteerHeaderArr.value[i].checked = true;
    return;
  }
  // 如果是true ，push进dragdemo
  if (SetVolunteerHeaderArr.value[i].checked) {
    dragDemo.value.push(SetVolunteerHeaderArr.value[i]);
  } else {
    //如果是false，从dragdemo中删除
    dragDemo.value = dragDemo.value.filter((item) => item.lable !== SetVolunteerHeaderArr.value[i].lable);
  }
};
//  表头字段设置  恢复默认字段
const RecoverData = () => {
  console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value);
  // let defaultdata = readonly(DefaultHeaderData)
  SetVolunteerHeaderArr.value = JSON.parse(JSON.stringify(DefaultHeaderData.value));
  // 恢复默认 拖拽数据
  dragDemo.value = [];
  SetVolunteerHeaderArr.value.forEach((item, index) => {
    if (item.checked) {
      dragDemo.value.push(item);
    }
  });
};
// 表头字段设置 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false;
  SetVolunteerHeaderArr.value = JSON.parse(JSON.stringify(TemporaryData.value));
};

// 标签去除数组【】“”
const formatTags = (val) => {
  // console.log('标签数据是：', val);
  if (val == '——') {
    return '——';
  } else if (Array.isArray(val)) {
    return JSON.stringify(val).replace(/"|\[|\]/g, '');
  }
};
// 置顶/删除方法
const backTop = (e, val) => {
  console.log('val :>> ', e);
  let indexItem = -1;
  dragDemo.value.forEach((item, index) => {
    if (item.lable == e.lable) {
      indexItem = index;
    }
  });
  console.log('下标是： :>> ', indexItem);
  // 置顶方法
  if (val == 'top') {
    if (indexItem != 0) {
      dragDemo.value.unshift(dragDemo.value.splice(indexItem, 1)[0]);
    }
  } else {
    // 不能删除的操作
    if (defaultHeader.value.indexOf(e.lable) != -1) {
      message({
        message: `${e.lable}不能删除`,
        showClose: true,
        type: 'error',
      });
    } else {
      // 可以删除的操作 同步删除左侧勾选；
      // 删除方法
      dragDemo.value.splice(indexItem, 1);
      const index = SetVolunteerHeaderArr.value.findIndex((item) => item.lable === e.lable);
      SetVolunteerHeaderArr.value[index].checked = false;
    }
  }

  console.log(' dragDemo.value; :>> ', dragDemo.value);
};

let Show_transform = ref(false); // ! 控制高级搜索显示
// ! 高级显示
const ischangeShow = () => {
  Show_transform.value = !Show_transform.value;
  for (let prop in sampleSearch) {
    sampleSearch[prop] = null;
  }
};
// 高级搜索数据存储
const sampleSearch = reactive({
  sample_number: '', //样品编号
  sample_name: '', //样品名称
  sample_total: '', //样品总数量
  available_quantity: '', //可发放数量
  label: '', //样品标签
  spec: '', //样品规格
  character: '', //样品性状
  batchNum: '', //样品批号
  saveCondition: '', //储存条件
  receiptDate: '', //样品接收日期
  limitDate: '', //限制使用日期
  expired: '', //是否过期
  destory: '', //是否销毁
  suggestTest: '', //建议检测项目类别
  usefunc: '', //样品使用方法
  management: '', //样品管理员
  notes: '', // 备注
  project_number: '', //项目编号
  project_name: '', //项目名称
  test_status: '', //x样品检测状态
});
// 高级搜索初始数据
const initialize = reactive(null);
// !高级搜索数据
const Totalinitialdata = ref({
  // !可发放数量
  available_quantity_opstion: [
    {
      value: '可用',
      label: '可用',
    },
    {
      value: '已发放',
      label: '已发放',
    },
    {
      value: '已回收',
      label: '已回收',
    },
  ],
  // !样品标签
  tag_opstion: [
    {
      value: '成分好',
      label: '成分好',
    },
    {
      value: '已发放',
      label: '已发放',
    },
    {
      value: '已回收',
      label: '已回收',
    },
  ],
  //样品检测状态
  labelArr: [
    {
      value: '待检',
      label: '待检',
    },
    {
      value: '在检',
      label: '在检',
    },
    {
      value: '检毕',
      label: '检毕',
    },
    {
      value: '留样',
      label: '留样',
    },
  ],

  // !  是否过期。是否销毁
  isBoolean: [
    {
      value: true,
      label: '是',
    },
    {
      value: false,
      label: '否',
    },
  ],
  // !
});
//!高级搜索
const reqAllSelectData = async () => {
  // ! 取消null的字段
  for (let prop in sampleSearch) {
    if (sampleSearch[prop] === null) {
      delete sampleSearch[prop];
    }
  }
  const res = await http.post('/sample/advancedSearch', {
    findParams: sampleSearch,
    page: (page5.currentPage = 1),
    size: page5.pageSize,
  });
  console.log('全部返回的数据是:', res);
  if (res.status == 0) {
    Advancedfind.value = true; //已进入高级搜索模式(更新分页)
    demo1.tableData = res.result.sampleList;
    page5.totalResult = res.result.totalCount;
    Show_transform.value = false;
  }
};
// ! 退出高级搜索按钮
const unSelect = () => {
  Show_transform.value = false;
};
// !表单空白处理
const tableData = computed(() => {
  return replaceEmptyValues(demo1.tableData);
});
// 列表
let dragDemo = ref([]);
// 表格表头
let allDatas = ref([]);
let createdSampleShow = ref(false); // 设置新建样品组件显示隐藏
const is_dialogShow = ref(false); //传递给子组件显示隐藏
// 点击新建样品
const createdSampleClick = () => {
  // demo1.filterName = '';
  createdSampleShow.value = !createdSampleShow.value;
  console.log('点击新建样品');
  is_dialogShow.value = true;
  console.log(is_dialogShow.value);
};
// 接收创建样品传递的隐藏抽屉
const updateChange = (val) => {
  console.log(val);
  is_dialogShow.value = false;
  getSamplelistRes();
};
//获取当前时间
const current = ref('');
const getNowDate = () => {
  const now = new Date();
  const year = now.getFullYear(); // 获取年份
  const month = String(now.getMonth() + 1).padStart(2, '0'); // 获取月份，注意要补齐两位数
  const day = String(now.getDate()).padStart(2, '0'); // 获取日期，注意要补齐两位数
  const current1 = `${year}-${month}-${day}`;
  current.value = current1;
  console.log(current.value);
};
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize);
});
// 显示功能的字段设置
let AllShowHeader = ref([]);
// 表头设置显示隐藏
const SetTableHeader = ref(false);
//表头字段设置--->设置表头的勾选数据
let SetVolunteerHeaderArr = ref([
  { width: '164', min_width: '164', fixed: '', field: 'code', checked: true, lable: '样品编号' },
  { width: '112', min_width: '112', fixed: '', field: 'name', checked: true, lable: '样品名称' },
  { width: '132', min_width: '132', fixed: '', field: 'total', checked: true, lable: '样品总数量' },
  { width: '120', min_width: '120', fixed: '', field: 'available_quantity', checked: true, lable: '可发放数量' },
  { width: '132', min_width: '132', fixed: '', field: 'tag', checked: false, lable: '样品标签' },
  { width: '132', min_width: '132', fixed: '', field: 'specs', checked: false, lable: '样品规格' },
  { width: '132', min_width: '132', fixed: '', field: 'properties', checked: false, lable: '样品性状' },
  { width: '164', min_width: '164', fixed: '', field: 'batch_code', checked: false, lable: '样品批号' },
  { width: '179', min_width: '179', fixed: '', field: 'storage_conditions', checked: false, lable: '储存条件' },
  { width: '164', min_width: '164', fixed: '', field: 'acceptance_date', checked: true, lable: '样品接收日期' },
  { width: '164', min_width: '164', fixed: '', field: 'expiration_date', checked: true, lable: '限制使用日期' },
  { width: '112', min_width: '112', fixed: '', field: 'isexpired', checked: false, lable: '是否过期' },
  { width: '112', min_width: '112', fixed: '', field: 'isdestory', checked: false, lable: '是否销毁' },
  { width: '179', min_width: '179', fixed: '', field: 'testProject_suggest', checked: false, lable: '建议检测项目类别' },
  { width: '179', min_width: '179', fixed: '', field: 'usage_instructions', checked: false, lable: '样品使用方法' },
  { width: '120', min_width: '120', fixed: '', field: 'manager_name', checked: true, lable: '样品管理员' },
  { width: '179', min_width: '179', fixed: '', field: 'remark', checked: false, lable: '备注' },
  { width: '179', min_width: '179', fixed: '', field: 'isretained', checked: false, lable: '是否留样' },
  { width: '179', min_width: '179', fixed: '', field: 'retained_quantity', checked: false, lable: '留样数量' },
  { width: '272', min_width: '272', fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  { width: '167', min_width: '167', fixed: '', field: 'testProject_code', checked: true, lable: '样品检测项目编号' },
  { width: '179', min_width: '179', fixed: '', field: 'testProject_name', checked: true, lable: '样品检测项目名称' },
  { width: '172', min_width: '172', fixed: '', field: 'testing_status', checked: true, lable: '样品检测状态' },
]);
// 表头字段设置--->恢复默认字段
let DefaultHeaderData = ref([
  { width: '164', min_width: '164', fixed: '', field: 'code', checked: true, lable: '样品编号' },
  { width: '112', min_width: '112', fixed: '', field: 'name', checked: true, lable: '样品名称' },
  { width: '132', min_width: '132', fixed: '', field: 'total', checked: true, lable: '样品总数量' },
  { width: '120', min_width: '120', fixed: '', field: 'available_quantity', checked: true, lable: '可发放数量' },
  { width: '132', min_width: '132', fixed: '', field: 'tag', checked: false, lable: '样品标签' },
  { width: '132', min_width: '132', fixed: '', field: 'specs', checked: false, lable: '样品规格' },
  { width: '132', min_width: '132', fixed: '', field: 'properties', checked: false, lable: '样品性状' },
  { width: '164', min_width: '164', fixed: '', field: 'batch_code', checked: false, lable: '样品批号' },
  { width: '179', min_width: '179', fixed: '', field: 'storage_conditions', checked: false, lable: '储存条件' },
  { width: '164', min_width: '164', fixed: '', field: 'acceptance_date', checked: true, lable: '样品接收日期' },
  { width: '164', min_width: '164', fixed: '', field: 'expiration_date', checked: true, lable: '限制使用日期' },
  { width: '112', min_width: '112', fixed: '', field: 'isexpired', checked: false, lable: '是否过期' },
  { width: '112', min_width: '112', fixed: '', field: 'isdestory', checked: false, lable: '是否销毁' },
  { width: '179', min_width: '179', fixed: '', field: 'testProject_suggest', checked: false, lable: '建议检测项目类别' },
  { width: '179', min_width: '179', fixed: '', field: 'usage_instructions', checked: false, lable: '样品使用方法' },
  { width: '120', min_width: '120', fixed: '', field: 'manager_name', checked: true, lable: '样品管理员' },
  { width: '179', min_width: '179', fixed: '', field: 'remark', checked: false, lable: '备注' },
  { width: '179', min_width: '179', fixed: '', field: 'isretained', checked: false, lable: '是否留样' },
  { width: '179', min_width: '179', fixed: '', field: 'retained_quantity', checked: false, lable: '留样数量' },
  { width: '272', min_width: '272', fixed: 'right', field: 'checked', checked: true, lable: '操作' },
  { width: '167', min_width: '167', fixed: '', field: 'testProject_code', checked: true, lable: '样品检测项目编号' },
  { width: '179', min_width: '179', fixed: '', field: 'testProject_name', checked: true, lable: '样品检测项目名称' },
  { width: '172', min_width: '172', fixed: '', field: 'testing_status', checked: true, lable: '样品检测状态' },
]);
let defaultHeader = ref(['样品编号', '样品名称', '样品总数量', '可发放数量', '操作']);
// note 全局加载完毕之后执行此事件
nextTick(() => {
  setHeaderColumn();
  // 执行 列表显示/隐藏
  ShowHidenColumn();
  // 固定列表本地数据
  FixedColumn();
  // 获取拖拽数据
  GetDrag();
  // 转移数据
  TransferData();
});
// 检查是否有本地数据()
const setHeaderColumn = () => {
  if (localStorage.getItem('Sample_List') == null) {
    // ////console.log('"第一次" :>> ', '第一次');
  } else {
    // ////console.log('"第二次" :>> ', '第二次');
    SetVolunteerHeaderArr.value = ase.decrypt(localStorage.getItem('Sample_List'));
  }
  // drag数据处理
  SetVolunteerHeaderArr.value.forEach((item, index) => {
    if (item.checked == true) {
      allDatas.value.push(SetVolunteerHeaderArr.value[index]);
    }
  });
  console.log('最终渲染的全部true数据', allDatas.value);
  // 赋值给dragDemo；
  dragDemo.value.splice(0, dragDemo.value.length);
  dragDemo.value = JSON.parse(JSON.stringify(allDatas.value));
};
// 检查本地是否有拖拽数据
const GetDrag = () => {
  if (localStorage.getItem('SampleDrag') == null) {
    // //console.log('"第一次" :>> ', '第一次');
  } else {
    // //console.log('"第二次" :>> ', '第二次');
    allDatas.value = ase.decrypt(localStorage.getItem('SampleDrag'));
    dragDemo.value = JSON.parse(JSON.stringify(allDatas.value));
  }
};
// ShowHidenColumn 设置column的的列表(确认按钮)

const ShowHidenColumn = () => {
  // drag数据处理
  allDatas.value = JSON.parse(JSON.stringify(dragDemo.value));

  const $table = xTable.value;
  const column = $table.getColumns();

  //console.log('$table.getColumns :>> ', $table.getColumns());

  for (let i = 0; i < SetVolunteerHeaderArr.value.length; i++) {
    if (SetVolunteerHeaderArr.value[i].checked == true) {
      // //console.log('object :>> ', SetVolunteerHeaderArr[i].field);
      $table.showColumn(SetVolunteerHeaderArr.value[i].field);
    }
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetVolunteerHeaderArr.value[i].lable) {
        if (SetVolunteerHeaderArr.value[i].checked == false) {
          $table.hideColumn(column[j].field);
        }
      }
    }
  }
  $table.refreshColumn();
  //console.log('$table.getColumns :>> ', $table.getColumns());
  SetTableHeader.value = false;
};
// FixedColumn 设置column的固定列(初始化本地数据)
const FixedColumn = () => {
  const $table = xTable.value;
  const column = $table.getColumns();
  //console.log('column:>> ', column);
  //console.log('SetVolunteerHeaderArr', SetVolunteerHeaderArr.value);
  for (let i = 0; i < SetVolunteerHeaderArr.value.length; i++) {
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetVolunteerHeaderArr.value[i].lable) {
        if (SetVolunteerHeaderArr.value[i].fixed != '') {
          toggleFixedColumn(j, SetVolunteerHeaderArr.value[i].fixed);
        }
      }
    }
  }
  $table.refreshColumn();
  // //console.log('$table.getColumns :>> ', $table.getColumns());
  // SetTableHeader.value = false;
};
// 获取当前的全部数据
const TransferData = () => {
  // 将全部显示的数据  放到 AllShowHeader中
  SetVolunteerHeaderArr.value.forEach((item, index) => {
    if (item.checked == true) {
      AllShowHeader.value.push(item);
    }
  });
  console.log('AllShowHeader.value :>> ', AllShowHeader.value);
};
</script>

<style lang="less" scoped>
@import url('@/style/views/SampleManagement/SampleList.less');
.itxst {
  width: 600px;
  display: flex;
}

.itxst > div:nth-of-type(1) {
  flex: 1;
}

.itxst > div:nth-of-type(2) {
  width: 270px;
  padding-left: 20px;
}

.item {
  // border: solid 1px #eee;
  padding: 6px 10px;
  text-align: left;
}

.item:hover {
  cursor: move;
}

// .item + .item {
//   // margin-top: 10px;
// }

.ghost {
  // 跟随鼠标的样式
  border: solid 1px #027aff !important;
  background: #fff !important;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.chosenClass {
  // 鼠标拉动的样式。
  background-color: #fff;
  opacity: 1;
  border: solid 1px #027aff;
  .drag {
    background-image: url(https://newstore.vynior.com/cems_web/drag%402x.png) !important;
  }
}

.fallbackClass {
  background-color: aquamarine;
}
</style>
