<template>
  <div id="HomeView1122333444">
    <div class="home">
      <div class="home_top">
        <div class="home_top_item">
          <div class="text_text_text">仪器总数 (个)</div>
          <div class="num_num_num">
            {{ InstrumentDataBoardInfo.instrumentTotal }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">待维护的仪器 (个)</div>
          <div class="num_num_num">
            {{ InstrumentDataBoardInfo.instrumentTobeMaintained }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">维护中的仪器 (个)</div>
          <div class="num_num_num">
            {{ InstrumentDataBoardInfo.instrumentMaintaining }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">已报废的仪器 (个)</div>
          <div class="num_num_num">
            {{ InstrumentDataBoardInfo.instrumentUseless }}
          </div>
        </div>
        <div class="home_top_item">
          <div class="text_text_text">所有仪器测试记录 (条)</div>
          <div class="num_num_num">
            <!-- {{ InstrumentDataBoardInfo.instrumentRecord }} -->22
          </div>
        </div>
      </div>

      <div class="home_dataProject">
        <div class="data_trend">
          <div class="trend_head">
            <div class="head_title">仪器历史测试量</div>
            <!-- <div
                class="head_nav"
                :class="dataTrendArrIndex == index ? 'head_click' : ''"
                @click="dataTrendArrIndexChange(index)"
                v-for="(item, index) in dataTrendArr"
                :key="index"
              >
                {{ item }}
              </div> -->
            <div class="head_time">
              <el-date-picker
                v-model="dataTrenTime"
                type="daterange"
                unlink-panels
                format="YYYY-MM-DD"
                value-format="YYYY-MM-DD"
                range-separator=""
                start-placeholder="请选择日期时间 "
                @change="dataTrenTimeChange"
              />
            </div>
          </div>
          <div class="trend_heads">
            <div
              class="trend_item"
              v-for="(item, index) in mockDataOfInstrument"
              :key="index"
            >
              <el-tooltip
                class="box-item"
                effect="dark"
                placement="top"
                :content="item.name"
              >
                <div class="item_left">{{ item.name }}</div>
              </el-tooltip>

              <div class="right">
                <el-tooltip class="box-item" effect="dark" placement="top">
                  <template #content>
                    <div style="text-align: center">
                      2023-07-05~2023-07-31 <br />
                      测试量：227次
                    </div>
                  </template>

                  <!-- <div slot="content">
                    2023-07-05~2023-07-31 <br />
                    测试量：227次
                  </div> -->
                  <div
                    class="bar"
                    :style="{ width: `${(item.count / maxValue) * 100}%` }"
                  ></div>
                </el-tooltip>
              </div>
            </div>
          </div>
          <div class="scaleBar">
            <div
              class="scaleBarItem"
              v-for="(item, index) in scaleArr"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="data_project">
          <div class="project_head">
            <div class="head_title">数据分析</div>
            <div class="head_all">
              <el-select
                v-model="selectvalue"
                class="m-2"
                placeholder="请选择"
                size="large"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div class="project_main" v-if="false">
            <div class="main_item">
              <div class="item_name">wodsdfsdfsdfsdfsdfsdfsfdsdfsdfsdfemz</div>
              <div class="item_status">
                <div class="status_box status_incomplete">weikais</div>
              </div>
              <div class="item_speed">
                <div class="speed">
                  <div class="speed_back" style="width: 10%"></div>
                </div>
                <div class="speed_val">10%</div>
              </div>
              <div class="item_time">12346656564</div>
            </div>
          </div>
          <div id="project_ecarts" v-show="currentData.length != 0"></div>
          <div class="noData" v-show="currentData.length == 0">暂无数据</div>
        </div>
      </div>
    </div>
    <div id="reset_password">
      <el-dialog
        v-model="resetPwd"
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        :show-close="false"
      >
        <template #header>修改初始密码</template>
        <template #default>
          <div class="content">
            <div class="title">
              为了您的账号安全，首次登录或重置密码后请修改初始密码
            </div>
            <div class="input-newPwd">
              <div class="input-item">
                <span>新密码</span>
                <div class="input_pwd">
                  <el-input
                    @input="pwd_verify"
                    :class="is_pwd_verify ? 'error_input' : ''"
                    v-model="new_pwd1"
                    type="password"
                    show-password
                    placeholder="请填写新密码"
                  />
                </div>
              </div>
              <div class="pwd-length-hint">
                <span v-if="is_pwd_verify" class="error-hint">
                  {{ first_pwd_hint }}
                </span>
                <span v-else>
                  密码长度为8-16位，不少于8位;应至少包含数字、大小写字母及特殊字符中的两种
                </span>
              </div>
              <div class="input-item">
                <span>确认密码</span>
                <div class="input_pwd">
                  <el-input
                    @input="sec_pwd_verify"
                    :class="is_pwd_verify2 ? 'error_input' : ''"
                    v-model="new_pwd2"
                    type="password"
                    show-password
                    placeholder="请再次输入新密码"
                  />
                </div>
              </div>
              <div class="pwd-length-hint">
                <span v-if="is_pwd_verify2" class="error-hint">
                  {{ sec_pwd_hint }}
                </span>
              </div>
            </div>
          </div>
        </template>
        <template #footer>
          <el-button type="primary" @click="submit"> 确定 </el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>
<script setup>
import { defineComponent, ref, onBeforeMount, onMounted } from "vue";
import { Lock } from "@element-plus/icons-vue";
import { message } from "@/utils/resetMessage";
import http from "@/utils/http";
import * as echarts from "echarts";
import { useRouter, useRoute } from "vue-router";

const router = useRouter();
const route = useRoute();
const selectvalue = ref("仪器状态");
const options = [
  {
    value: "仪器状态",
    label: "仪器状态",
  },
];

//获取仪器历史测试量
let maxValue = ref(null);
const getInstrumentHistoryTestNum = async () => {
  let res = await http.post("/instrument/getInstrumentHistory");
  console.log("获取仪器历史测试量res", res);

  if (res.status == 0) {
    //   成功, res.counts
    let maxNum = await findMaxCount(res.counts);
    console.log("最大值", maxNum);
    maxValue.value = maxNum;
    let echartsRules = await generateEchartsScaleArray(maxNum);
    console.log("标尺", echartsRules);
    scaleArr.value = echartsRules;
    mockDataOfInstrument.value = res.counts;
  }
};

const findMaxCount = (arr) => {
  let maxCount = 0;

  for (let i = 0; i < arr.length; i++) {
    const obj = arr[i];
    if (obj.hasOwnProperty("count") && obj.count > maxCount) {
      maxCount = obj.count;
    }
  }

  return maxCount;
};

//根据echarts标尺算法，传入测试量最大值，生成动态标尺。

function generateEchartsScaleArray(maxValue) {
  const arraySize = 5; // 数组大小为5
  const interval = Math.ceil(maxValue / arraySize); // 计算每项之间的间隔值
  const array = [];
  for (let i = 1; i <= arraySize; i++) {
    array.push(i * interval);
  }
  array[arraySize - 1] = maxValue;
  return array;
}

//获取数据看板 数据
let InstrumentDataBoardInfo = ref({});
const getInstrumentDataBoard = async () => {
  let res = await http.post("/dashboard/instrument-dashboard", {});
  console.log("仪器数据看板res", res);
  InstrumentDataBoardInfo.value = res.InstrumentData;
};

//环形图当前数据
let currentData = ref([]);

//获取环形图数据
let InstrumentCircleStatus = ref([]);
let currentInstrumentCicleStatusData = ref([]);
const getCircleData = async () => {
  let res = await http.post("/dashboard/instrumentCircleData", {});
  console.log("仪器环形图res", res);
  InstrumentCircleStatus.value = res.instrumentCircleResult.status;
  currentInstrumentCicleStatusData.value = [
    {
      itemStyle: { color: "#004ADA" },
      name: InstrumentCircleStatus.value[0].name,
      value: InstrumentCircleStatus.value[0].num,
    },
    {
      itemStyle: { color: "#38BDD7" },
      name: InstrumentCircleStatus.value[1].name,
      value: InstrumentCircleStatus.value[1].num,
    },
    {
      itemStyle: { color: "#FC7A94" },
      name: InstrumentCircleStatus.value[2].name,
      value: InstrumentCircleStatus.value[2].num,
    },
    {
      itemStyle: { color: "#FFD47D" },
      name: InstrumentCircleStatus.value[3].name,
      value: InstrumentCircleStatus.value[3].num,
    },
  ];

  currentData.value = currentInstrumentCicleStatusData.value;
  resetPasswordChange();
};

onMounted(() => {
  getInstrumentDataBoard();
  getCircleData();
  getInstrumentHistoryTestNum();
  // treadEchartsChange();

  //
});

//动态计算  刻度
let scaleArr = ref([]);
// const computedScale = (maxValue) => {
//   maxValue = 2192;
//   let n = 5;
//   const interval = Math.ceil(maxValue / n);
//   const ticks = [];
//   for (let i = 0; i <= maxValue; i += interval) {
//     ticks.push(i);
//   }
//   scaleArr.value = ticks;
//   console.log("刻度数组为", ticks);
// };

//仪器历史测试量  模拟数据
let mockDataOfInstrument = ref([
  {
    InstrumentName: "皮肤快速三维成像系统PRIMOS lite",
    process: "40%",
  },
  {
    InstrumentName: "Antera 3D® CS 多功能3D皮肤成像分析仪 lite",
    process: "10%",
  },
  {
    InstrumentName: "VISIA 7 面部图像分析仪 lite",
    process: "40%",
  },
  {
    InstrumentName: "MoistureMeter MSC1201  皮肤水分测试仪 lite",
    process: "40%",
  },
  {
    InstrumentName: "皮肤色素测试探头 Mexameter MX18 lite",
    process: "50%",
  },
  {
    InstrumentName: "皮肤光泽度测试探头Glossymeter GL200 lite",
    process: "60%",
  },
  {
    InstrumentName: "MoistureMeter MSC1201 皮肤水分测试仪 lite",
    process: "70%",
  },
  {
    InstrumentName: "皮肤快速三维成像系统PRIMOS lite",
    process: "90%",
  },
  {
    InstrumentName: "皮肤快速三维成像系统PRIMOS lite",
    process: "100%",
  },
]);

// 折线图
const treadEchartsChange = () => {
  let myChart = echarts.init(document.getElementById("treadEcharts"));
  // 绘制图表
  myChart.setOption({
    title: {
      text: "世界人口总量",
      subtext: "数据来自网络",
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: ["2011年", "2012年"],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "value",
      boundaryGap: [0, 0.01],
    },
    yAxis: {
      type: "category",
      data: [
        "巴西11111111111111111111111111111111111",
        "印尼",
        "美国",
        "印度",
        "中国",
        "世界人口(万)",
      ],
    },
    series: [
      {
        name: "2011年",
        type: "bar",
        data: [18203, 23489, 29034, 104970, 131744, 630230],
      },
      {
        name: "2012年",
        type: "bar",
        data: [19325, 23438, 31000, 121594, 134141, 681807],
      },
    ],

    // xAxis: {
    //   type: "category",
    //   data: [
    //     "2023-12-03",
    //     "2023-12-03",
    //     "2023-12-31",
    //     "2023-12-13",
    //     "2023-12-23",
    //     "2023-12-11",
    //   ],
    //   // boundaryGap: false,
    //   axisLine: {
    //     show: false,
    //     lineStyle: {
    //       color: "#8F94B2",
    //     },
    //   },
    //   axisTick: {
    //     show: false,
    //   },
    //   axisLabel: {
    //     // interval: interval,
    //     // interval: 1,
    //     // axisLabel: {
    //     formatter: function (value) {
    //       return value.substring(5); // 提取从索引5开始的子字符串，即月-日部分
    //     },
    //     // },
    //   },
    // },
    // yAxis: {
    //   type: "value",
    //   axisLine: {
    //     show: false,
    //     lineStyle: {
    //       color: "#8F94B2",
    //     },
    //   },
    //   axisTick: {
    //     show: false,
    //   },
    //   splitLine: {
    //     show: true,
    //     lineStyle: {
    //       // type: 'dashed',
    //     },
    //   },
    // },
    // tooltip: {
    //   show: true,
    //   trigger: "axis",
    //   // 自定义显示
    //   // formatter: '{b0}<br />新增用户数   {c0} 个',
    //   formatter: function (params) {
    //     // console.log(params[0].value);
    //     return (
    //       params[0].name +
    //       "<br />新增用户数&nbsp; " +
    //       params[0].value +
    //       "&nbsp;个&nbsp;"
    //     );
    //   },
    // },
    // series: [
    //   {
    //     name: "新增用户",
    //     type: "line",
    //     data: [5, 20, 36, 10, 10, 20],
    //     areaStyle: { type: "default" },
    //     smooth: true,
    //     itemStyle: {
    //       color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
    //         { offset: 0, color: "rgba(0,74,218, 0.5)" },
    //         { offset: 0.5, color: "rgba(0,74,218, 0.3)" },
    //         { offset: 1, color: "rgba(0,74,218, 0.1)" },
    //       ]),
    //       borderColor: "#004ADA",
    //       borderWidth: 1,
    //       borderType: "solid",
    //     },
    //     symbol: "circle",
    //     showAllSymbol: "auto",
    //     symbolSize: 10,
    //     //折线拐点的类型
    //     // symbol: 'star',
    //     // 改变线条样式 lineStyle
    //     lineStyle: {
    //       color: "#004ADA",
    //       type: "solid",
    //       with: 2,
    //     },
    //     emphasis: {
    //       symbolSize: 0,
    //       itemStyle: {
    //         // 鼠标悬浮时拐点样式
    //         borderColor: "#004ADA",
    //         borderWidth: 10,
    //         borderType: "solid",
    //       },
    //     },
    //   },
    // ],
  });
  window.onresize = function () {
    //自适应大小
    myChart.resize();
  };
};
// 环形图
const resetPasswordChange = () => {
  let myChart = echarts.init(document.getElementById("project_ecarts"));
  // 绘制图表
  myChart.setOption({
    // legend: {
    legend: {
      top: "85%",
      left: "center",
      itemGap: 90, // 设置图例项之间的距离为 20 像素
    },

    // grid: {
    // 				top: '30%', // 等价于 y: '16%'
    // 				left: '3%',
    // 				right: '0%',
    // 				bottom: '20%',
    // 				containLabel: true
    // 			},
    tooltip: {
      trigger: "item",
    },
    series: [
      {
        type: "pie",
        avoidLabelOverlap: false,
        data: currentData.value,
        //  [
        //   {
        //     itemStyle: { color: "#004ADA" },
        //     name: "18-25",
        //     value: 12,
        //   },
        //   {
        //     itemStyle: { color: "#38BDD7" },
        //     name: "25-35",
        //     value: 12,
        //   },
        //   {
        //     itemStyle: { color: "#FC7A94" },
        //     name: "35-45",
        //     value: 12,
        //   },
        //   {
        //     itemStyle: { color: "#FFD47D" },
        //     name: "45以上",
        //     value: 12,
        //   },
        // ],
        itemStyle: {
          // borderRadius: 10,
          borderColor: "#fff",
          borderWidth: 10,
        },
        // 数值显示
        label: {
          show: false,
        },
        radius: ["50%", "80%"],
        center: ["50%", "40%"],
        // itemStyle: {
        //   borderRadius: 8,
        //   borderColor: '#fff',
        //   borderWidth: 4,
        // },
      },
    ],
  });
  window.onresize = function () {
    //自适应大小
    myChart.resize();
  };
};
// 数据趋势头部导航数组
const dataTrendArr = ref(["项目", "志愿者", "仪器", "问卷", "样品", "专家"]);
// 数据趋势头部导航数组选中的值
const dataTrendArrIndex = ref(1);
// 点击头部导航选择展示的数据
const dataTrendArrIndexChange = (val) => {
  dataTrendArrIndex.value = val;
};
// 数据趋势日期数据
const dataTrenTime = ref([]);
// 数据趋势日期数据触发事件
const dataTrenTimeChange = (val) => {
  console.log(val);
};
// 数据趋势 二级导航数据
const dataTrenitemArr = ref([
  [],
  [
    "新增仪器个数",
    "累计仪器个数",
    // "新增志愿者报名人次",
    // "累计志愿者报名人次",
  ],
]);
// 选中数据趋势二级导航的值
const dataTrenitemArrIndex = ref(0);
// 数据趋势 二级导航数据选中的事件
const dataTrenitemArrChange = (val) => {
  dataTrenitemArrIndex.value = val;
  console.log(val);
};

const resetPwd = ref(false);
const is_resetPwd = () => {
  // console.log(JSON.parse(localStorage.getItem('reset_password')));
  if (localStorage.getItem("reset_password")) {
    resetPwd.value = JSON.parse(localStorage.getItem("reset_password"));
    // console.log('重置密码');
  }
};

// 设置新密码
const new_pwd1 = ref("");
const new_pwd2 = ref("");
const is_hint = ref(false);

const is_pwd_verify = ref(false);
const first_pwd_hint = ref("新密码不能为空");
const pwd_verify = () => {
  let judgeFn = new RegExp(
    /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&/*()_+|{}:"?]{8,16}$/
  );
  if (new_pwd1.value === "") {
    is_pwd_verify.value = true;
    is_hint.value = true;
  } else if (!judgeFn.test(new_pwd1.value)) {
    is_pwd_verify.value = true;
    is_hint.value = true;
    first_pwd_hint.value = "密码强度不符合要求";
  } else {
    is_pwd_verify.value = false;
    is_hint.value = false;
  }
};
const is_pwd_verify2 = ref(false);
const sec_pwd_hint = ref("两次密码输入不一致");
const sec_pwd_verify = () => {
  let judgeFn = new RegExp(
    /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&/*()_+|{}:"?]{8,16}$/
  );
  if (new_pwd2.value === "") {
    is_pwd_verify2.value = true;
    sec_pwd_hint.value = "确认密码不能为空";
  } else if (new_pwd2.value === new_pwd1.value) {
    if (!judgeFn.test(new_pwd2.value)) {
      is_pwd_verify2.value = true;
      is_hint.value = true;
      sec_pwd_hint.value = "密码强度不符合要求";
    } else {
      is_pwd_verify2.value = false;
    }
  } else {
    is_pwd_verify2.value = true;
    sec_pwd_hint.value = "两次密码输入不一致";
  }
};

// 确认设置新密码，接口修改密码
const submit = async () => {
  pwd_verify();
  sec_pwd_verify();
  let judgeFn = new RegExp(
    /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9a-zA-Z-`=\[\];',.~!@#$%^&*()_+|{}:"?]{8,16}$/
  );
  if (
    new_pwd1.value !== "" &&
    new_pwd2.value !== "" &&
    new_pwd2.value === new_pwd1.value
  ) {
    if (new_pwd1.value.length != 0) {
      if (!judgeFn.test(new_pwd1.value)) {
        is_pwd_verify.value = true;
        is_hint.value = true;
        first_pwd_hint.value = "密码强度不符合要求";
        is_pwd_verify2.value = true;
        sec_pwd_hint.value = "";
      } else {
        const res = await http.post("/user/resetPassword-user", {
          _id: localStorage.getItem("_id"),
          password: new_pwd1.value,
        });
        console.log(localStorage.getItem("_id"), new_pwd1.value);
        console.log(res);
        if (res.status === 0) {
          router.push("/");
          message({
            type: "success",
            message: "密码修改成功，请重新登录",
            showClose: true,
          });
          localStorage.removeItem("access_token");
          localStorage.removeItem("_id");
          localStorage.removeItem("pwd_free_Login");
          localStorage.removeItem("reset_password");
        }
        console.log("提交新密码");
      }
    }
  }
};

onBeforeMount(() => {
  is_resetPwd();
});
</script>

<style lang="less">
@import url("@/style/views/InstrumentMangement/InstrumentDataBoard.less");

#reset-password {
  .el-dialog {
    width: 650px;
    .el-dialog__header {
      text-align: left;
      padding: 12px 24px;
      margin: 0;
      font-size: 16px;
      font-family: Source Han Sans CN-Medium, Source Han Sans CN;
      font-weight: 500;
      color: #313233;
      border-bottom: 1px #bfbfbf solid;
    }
    .el-dialog__body {
      padding: 8px 24px 8px 20px;
      .content {
        .title {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #626366;
          text-align: left;
          margin-bottom: 20px;
        }
        .input-newPwd {
          .input-item {
            display: flex;
            height: 36px;
            align-items: center;
            & > span {
              font-size: 14px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #626366;
              width: 75px;
              flex-grow: 1;
              text-align: right;
              margin-right: 6px;
            }
            .input_pwd {
              .el-input {
                width: 528px;
                height: 36px;
                background: #ffffff;
                .el-input__wrapper {
                  box-shadow: none;
                  border: 1px solid #bfbfbf;
                  border-radius: 2px;
                  height: 36px;
                }
                .el-icon {
                  width: 20px;
                  height: 20px;
                  svg {
                    width: 20px;
                    height: 20px;
                  }
                }
                .el-input__wrapper.is-focus {
                  border: 1px #027aff solid;
                  .el-input__prefix {
                    .el-icon {
                      color: #027aff;
                    }
                  }
                }
              }
              // 报错提示
              .error_input {
                .el-input__wrapper {
                  border: 1px solid #ff3200;
                  &.is-focus {
                    border: 1px solid #ff3200;
                    .el-input__prefix {
                      .el-icon {
                        color: #ff3200;
                      }
                    }
                  }
                  .el-input__prefix {
                    .el-icon {
                      color: #ff3200;
                    }
                  }
                }
              }
            }
          }
          .pwd-length-hint {
            min-height: 20px;
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #bfbfbf;
            line-height: 22px;
            text-align: left;
            display: flex;
            margin-left: 78px;
            margin-bottom: 12px;
          }
        }
        // 错误提示语
        .error-hint {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ff3200;
          line-height: 20px;
        }
      }
    }
    .el-dialog__footer {
      .el-button {
        width: 80px;
        height: 36px;
        background: #027aff;
        border-radius: 2px;
        span {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #ffffff;
          line-height: 22px;
        }
      }
    }
  }
}
.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>
