<template>
  <div id="SignatureManagement">
    <div class="ContentBox">
      <!-- 头部标题 -->
      <div class="Header">
        <div class="Title">{{ store.state.user.Signature }}</div>
        <div class="BatchOperation" id="BatchBox">
          <div class="white" v-show="!BatchData.Shows" @click.stop="BatchData.Shows = true">
            <span>批量操作</span>
            <div class="line"><img src="https://newstore.vynior.com/cems_web/111%E5%B1%95%E5%BC%80%402x.png" alt="" /></div>
          </div>
          <!-- <img
            v-show="!BatchData.Shows"
            @click.stop="BatchData.Shows = true"
            src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%20112%20%E2%80%93%2019%402x.png"
            alt="" /> -->
          <div class="blue" v-show="BatchData.Shows" @click.stop="BatchData.Shows = false">
            <span>批量操作</span>
            <div class="line"><img src="https://newstore.vynior.com/cems_web/blue.png" alt="" /></div>
          </div>
          <!-- <img
            v-show="BatchData.Shows"
            @click.stop="BatchData.Shows = false"
            src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%20112%20%E2%80%93%2028%402x.png"
            alt="" /> -->
          <div class="BatchBox" v-show="BatchData.Shows" id="BatchBox">
            <div class="title1" @click="DownLoad()">
              <div class="downImg"></div>
              <!-- <img src="https://newstore.vynior.com/cems_web/%E5%AF%BC%E5%87%BA%402xs.png" alt="" /> -->
              <div class="content">下载</div>
            </div>
            <div class="title1 title" @click="Delete()">
              <img src="https://newstore.vynior.com/cems_web/%E5%88%A0%E9%99%A4%402x.png" alt="" />
              <div class="content">删除</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 数据盒子 -->
      <div class="DataBox">
        <div class="Upload">
          <el-upload
            class="upload_xlsx"
            ref="videoRef"
            :http-request="uploadFile"
            :on-preview="handlePreviews"
            :on-remove="handleRemoves"
            :file-list="fileLists"
            :show-file-list="false"
            :before-upload="beforeAvatarUpload">
            <div class="img">
              <img src="https://newstore.vynior.com/cems_web/%E4%B8%8A%E4%BC%A0%402xs.png" alt="" />
            </div>
            <div class="title">点击上传</div>
            <div class="tips">支持图片格式：jpg、jpeg、png</div>
          </el-upload>
        </div>
        <!-- <div class="imgs" v-if="ImgList.length != 0"> -->
        <div class="DetailData" v-for="(item, index) in ImgList" :key="index">
          <div class="IMG" @click="Choice(index)" :class="BatchChoice[index] ? 'is_active' : ''">
            <img class="sin" v-show="BatchChoice[index]" src="https://newstore.vynior.com/cems_web/%E7%BB%84%20111559%402x.png" alt="" />
            <img class="content" :src="item.accessPath" alt="" />
          </div>
          <div class="ImgName">{{ item.fileName }}</div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, watch } from 'vue'

import http from '@/utils/http'
import { message } from '@/utils/resetMessage'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

//API接收
const store = useStore()
const router = useRouter()
const route = useRoute()

let ImgList = ref([])
// 批量操作数据
let BatchData = ref({
  Shows: false,
  Delete: [],
  Path: [],
})
let TypeValue = ref('签章')
// 批量选中(控制样式)
let BatchChoice = ref([])

watch(
  () => store.state.user.Signature,
  (newValue) => {
    console.log('监听的签章/签名', newValue)

    if (newValue == '电子签章') {
      TypeValue.value = '签章'
    } else {
      TypeValue.value = newValue
    }
    ReqFistData() // 请求初始输出数据
  }
)

// 选中方法
const Choice = (val) => {
  BatchChoice.value[val] = !BatchChoice.value[val] //切换样式
  let index = BatchData.value.Delete.indexOf(ImgList.value[val].uuid)
  console.log('index :>> ', index)
  if (index == -1) {
    BatchData.value.Delete.push(ImgList.value[val].uuid)
    BatchData.value.Path.push(ImgList.value[val].downloadPath)
  } else {
    BatchData.value.Delete.splice(index, 1)
    BatchData.value.Path.splice(index, 1)
  }

  console.log('  BatchData.value.Delete :>> ', BatchData.value.Delete)
}

// 上传
const uploadFile = async (params) => {
  if (!store.state.role_info.includes('system-stamp-upload')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  const file = params.file

  const form = new FormData()

  form.append('userId', localStorage.getItem('_id'))
  form.append('type', TypeValue.value)
  form.append('file', file)
  console.log(params)
  await http
    .post('/upload/upload-user', form, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(async (res) => {
      console.log(res)
      if (res.status == 0) {
        ReqFistData() // 请求初始输出数据
        message({
          showClose: true,
          message: '上传文件成功',
          type: 'success',
        })
      } else if (res.status == 1) {
        message({
          showClose: true,
          message: '上传文件失败',
          type: 'error',
        })
      }
      // if (res.status == 2) {
      //   dialogShow.value = false;
      //   errorPath.value = res.errorPath;
      //   errdialogShow.value = true;
      //   errFileNum.value = res.errorNumber;
      // }
    })
}
// 删除数据
const Delete = async () => {
  if (!store.state.role_info.includes('system-stamp-delete')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  if (BatchData.value.Delete.length != 0) {
    const res = await http.post('/user/deleteFile-user', {
      userId: localStorage.getItem('_id'),
      type: TypeValue.value,
      uuid: BatchData.value.Delete,
    })
    console.log('删除数据返回的res :>> ', res)
    if (res.status == 0) {
      message({
        type: 'success',
        showClose: true,
        message: '删除成功',
      })
      ReqFistData() // 请求初始输出数据
    }
  } else {
    message({
      type: 'warning',
      showClose: true,
      message: '请先选择数据,再进行操作',
    })
  }
}
// 下载文件
const DownLoad = async () => {
  if (!store.state.role_info.includes('system-stamp-download')) {
    message({
      showClose: true,
      message: '你没有权限完成该操作，请向管理员申请权限',
      type: 'warning',
    })
    return
  }
  if (BatchData.value.Path.length != 0) {
    http.post('https://cems.fuhuan.ltd/api/upload/download', BatchData.value.Path, { responseType: 'arraybuffer' }).then((result) => {
      console.log(result, '返回的错误数据')
      const blob = new Blob([result], { type: 'application/x-zip-compressed;charset=utf-8' })

      message({
        type: 'success',
        showClose: true,
        message: '下载成功',
      })
      let name = '签章.zip'
      const downloadElement = document.createElement('a')
      let classificationhref = window.URL.createObjectURL(blob)
      downloadElement.href = classificationhref
      downloadElement.download = `${name}`
      // // console.log("blob :>> "，blob);
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(classificationhref)
    })
  } else {
    message({
      type: 'warning',
      showClose: true,
      message: '请先选择数据,再进行操作',
    })
  }
}
// 请求初始数据
const ReqFistData = async () => {
  BatchData.value.Delete.splice(0, BatchData.value.Delete.length)
  BatchChoice.value.splice(0, BatchChoice.value.length)
  const res = await http.post('/user/findFile-user', {
    userId: localStorage._id,
    type: TypeValue.value,
  })

  console.log('签章管理的请求数据res :>> ', res)
  if (res.status == 0) {
    ImgList.value = res.file

    for (let index = 0; index < res.file.length; index++) {
      BatchChoice.value.push(false)
    }
  }
}
// 上传限制
const beforeAvatarUpload = (rawFile) => {
  console.log('rawFile.type :>> ', rawFile.type)
  if (rawFile.type !== 'image/png' && rawFile.type !== 'image/jpg' && rawFile.type !== 'image/jpeg') {
    message.error('上传文件必须为:JPG,JPEG,PNG格式!')
    return false
  }
  return true
}
onMounted(() => {
  store.commit('Set_CurrentProjectName', '签章管理')
  ReqFistData() // 请求初始输出数据

  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (BatchData.value.Shows == true) {
      if (e.target.id != 'BatchBox') {
        BatchData.value.Shows = false
      }
    }
  })
})
</script>
<style lang="less" scoped>
@import url('@/style/views/systemManagement/SignatureManagement.less');
</style>
