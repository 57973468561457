<template>
 <div id="Report_management">
     <div class="un_data">
        <img src="@/assets/该页面正在开发中，敬请期待@2x.png" alt="">
     </div>
 </div>
</template>

<script>
</script>

<style lang="less">
#Report_management{
    width: 1648px;
    height: 870px;
    background: #FFFFFF;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>