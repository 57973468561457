<template>
  <div id="MPA580Instrument">
    <!-- 表格主体 -->
    <vxe-toolbar :style="changeBox ? 'marginRight' : ''">
      <template #buttons>
        <!-- <vxe-input
          class="Search"
          v-model="demo1.filterName"
          type="search"
          placeholder="请输入项目编号或志愿者编号进行搜索"
          @Blur="searchEvent2"></vxe-input>
        <vxe-button @click="reset" class="Reset"><span class="reset-icon">重置</span></vxe-button> -->
        <vxe-button class="Show" :class="SetShow ? 'ShowHiden' : ''">
          <!-- <img src="https://newstore.vynior.com/cems_web/browse%402x%20%281%29.png"/> -->
          <span @click.stop="SetShowFunction" class="show-icon">显示</span>
        </vxe-button>
        <!-- ！三个分类 -->
        <div class="form_header">
          <img class="uploading" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B68319%402x.png" @click="uploadData" alt="" />
          <img class="export" @click="isShowExport" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B682270%402x.png" alt="" />
        </div>
      </template>
    </vxe-toolbar>
    <div class="VolunteerBox" v-show="tableData.length > 0">
      <!-- 表内导航栏(筛选条件) -->
      <div class="VolunteerTable_main">
        <!-- !  子目录结构-->
        <div class="MenuList" :class="{ clicked: !isCollapse }">
          <div class="Title">
            <span v-show="!isCollapse" class="title">子目录</span>
            <img v-show="!isCollapse" @click="isCollapse = !isCollapse" src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B61615%402x.png" alt="" />
            <img
              v-show="isCollapse"
              class="close"
              @click="isCollapse = !isCollapse"
              src="https://newstore.vynior.com/cems_web/%E7%BB%84%E4%BB%B6%20161%20%E2%80%93%208%402x.png"
              alt="" />
          </div>

          <el-menu
            default-active="1"
            :index="activeIndex"
            @select="handleSelect"
            class="Line"
            :collapse="isCollapse"
            @open="handleOpen"
            @close="handleClose"
            :collapse-transition="false">
            <el-menu-item index="1" class="menu_item">
              <template #title>&nbsp;&nbsp;Cutometer Mode1 Avg</template>
            </el-menu-item>
            <el-menu-item index="2" class="menu_item">
              <template #title>&nbsp;&nbsp;Cutometer Mode1</template>
            </el-menu-item>
          </el-menu>
        </div>
        <!-- 表体 -->
        <div class="VolunteerTable" :class="{ VolunteerTable1: isCollapse }">
          <div class="Project">
            <vxe-table
              border
              stripe
              align="left"
              ref="xTable"
              height="726"
              :scroll-y="{ mode: 'default' }"
              show-overflow
              row-id="_id"
              :data="tableData"
              :column-config="{ resizable: true, useKey: 'id' }"
              :row-config="{ isHover: true, keyField: 'id', height: linewidth <= 1280 ? 32 : 48, minHeight: 32, useKey: 'id' }"
              :checkbox-config="{ reserve: true }"
              :loading="demo1.loading"
              @header-cell-dragend="handleHeaderDragend"
              @checkbox-all="selectAllChangeEvent1"
              @checkbox-change="selectChangeEvent1">
              <vxe-column field="checked" type="checkbox" width="50" :fixed="demo1.colFixeds.col0"></vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Time_DateSession"
                type="html"
                title="Time & Date Session"
                width="192"
                :fixed="demo1.colFixeds.col1"
                min-width="190">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === 'Time & Date Session'">
                    <span class="name">Time & Date Session</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Time & Date Session', $event)"></i></span>
                  </div> </template
              ></vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Operator"
                type="html"
                title="Operator"
                width="148"
                :fixed="demo1.colFixeds.col2"
                min-width="80">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="name-header" v-if="column.title === 'Operator'">
                    <span class="name">Operator</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Operator', $event)"></i></span>
                  </div> </template
              ></vxe-column>

              <vxe-column
                :formatter="formatterRemark"
                field="Study"
                type="html"
                title="Study"
                width="148"
                :fixed="demo1.colFixeds.col4"
                min-width="80">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Sex-header" v-if="column.title === 'Study'">
                    <span class="name">Study</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Study', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Subject"
                type="html"
                title="Subject"
                width="160"
                :fixed="demo1.colFixeds.col5"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Subject'">
                    <span class="name">Subject</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Subject', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column :formatter="formatterRemark" field="T" type="html" title="T" width="60" :fixed="demo1.colFixeds.col3" min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Role-header" v-if="column.title === 'T'">
                    <span class="name">T</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('T', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column :formatter="formatterRemark" field="Tags" type="html" title="Tags" width="82" :fixed="demo1.colFixeds.col3" min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Role-header" v-if="column.title === 'Tags'">
                    <span class="name">Tags</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Tags', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="OrderOfTake"
                type="html"
                title="Order of Take"
                width="148"
                :fixed="demo1.colFixeds.col6"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Order of Take'">
                    <span class="name">Order of Take</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Order of Tack', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="NumberofMeasurements"
                type="html"
                title="Number of Measurements"
                width="244"
                :fixed="demo1.colFixeds.col7"
                min-width="80">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Number of Measurements'">
                    <span class="name">Number of Measurements</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Number of Measurements', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>

              <vxe-column
                :formatter="formatterRemark"
                field="PressureMode"
                type="html"
                title="Pressure Mode"
                width="168"
                :fixed="demo1.colFixeds.col8"
                min-width="148">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Pressure Mode'">
                    <span class="name">Pressure Mode</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Pressure Mode', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Repetition"
                type="html"
                title="Repetition"
                width="150"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Repetition'">
                    <span class="name">Repetition</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Repetition', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                title="操作"
                width="100"
                align="left"
                :fixed="demo1.colFixeds.col9"
                min-width="90"
                :resizable="false">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Operation-header" v-if="column.title === '操作'">
                    <span class="name">操作</span>
                    <span><i id="MoreOptions" class="vxe-icon-setting setting" @click="SetHeader"></i></span>
                  </div>
                </template>
                <template #default="{ row }">
                  <!-- <vxe-button class="detail" @click="ShowDetails(row)">查看详情</vxe-button> -->
                  <el-button class="copy" @click="isShowRemark(isRemark, row)">{{ row.remark ? row.remark : '标记无效' }}</el-button>

                  <!-- <vxe-button class="detail" @click="ShowDetails(row)">开始维护</vxe-button> -->
                  <span class="More" id="CMoreOption" @click="ClickMethods(row, $event)">···</span>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Probe"
                type="html"
                title="Probe"
                width="146"
                :fixed="demo1.colFixeds.col1"
                min-width="120">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === 'Probe'">
                    <span class="name">Probe</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Probe', $event)"></i></span>
                  </div> </template
              ></vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="ProbeSN"
                type="html"
                title="Probe SN"
                width="146"
                :fixed="demo1.colFixeds.col1"
                min-width="120">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="ID-header" v-if="column.title === 'Probe SN'">
                    <span class="name">Probe SN</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Probe SN', $event)"></i></span>
                  </div> </template
              ></vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="RawData"
                type="html"
                title="Raw Data"
                width="164"
                :fixed="demo1.colFixeds.col7"
                min-width="80">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Raw Data'">
                    <span class="name">Raw Data</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Raw Data', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>

              <vxe-column
                :formatter="formatterRemark"
                field="Pressure"
                type="html"
                title="Pressure [mbar]"
                width="172"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Pressure [mbar]'">
                    <span class="name">Pressure [mbar]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Pressure [mbar]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="OnTime"
                type="html"
                title="On Time [s]"
                width="136"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'On Time [s]'">
                    <span class="name">On Time [s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('On Time [s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="OffTime"
                type="html"
                title="Off Time [s]"
                width="136"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Off Time [s]'">
                    <span class="name">Off Time [s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Off Time [s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Offset"
                type="html"
                title="Avg Offset [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg Offset [mm]'">
                    <span class="name">Avg Offset [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg Offset [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R0"
                type="html"
                title="Avg R0 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R0 [mm]'">
                    <span class="name">Avg R0 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R0 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R1"
                type="html"
                title="Avg R1 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R1 [mm]'">
                    <span class="name">Avg R1 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R1 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R2"
                type="html"
                title="Avg R2 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R2 [mm]'">
                    <span class="name">Avg R2 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R2 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R3"
                type="html"
                title="Avg R3 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R3 [mm]'">
                    <span class="name">Avg R3 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R3 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R4"
                type="html"
                title="Avg R4 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R4 [mm]'">
                    <span class="name">Avg R4 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R4 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R5"
                type="html"
                title="Avg R5 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R5 [mm]'">
                    <span class="name">Avg R5 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R5 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R6"
                type="html"
                title="Avg R6 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R6 [mm]'">
                    <span class="name">Avg R6 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R6 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R7"
                type="html"
                title="Avg R7 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R7 [mm]'">
                    <span class="name">Avg R7 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R7 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R8"
                type="html"
                title="Avg R8 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R8 [mm]'">
                    <span class="name">Avg R8 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R8 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R9"
                type="html"
                title="Avg R9 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R9 [mm]'">
                    <span class="name">Avg R9 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R9 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="R10"
                type="html"
                title="Avg R10 [mm]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg R10 [mm]'">
                    <span class="name">Avg R10 [mm]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg R10 [mm]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="F0"
                type="html"
                title="Avg F0 [mm*s]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg F0 [mm*s]'">
                    <span class="name">Avg F0 [mm*s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg F0 [mm*s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="F1"
                type="html"
                title="Avg F1 [mm*s]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg F1 [mm*s]'">
                    <span class="name">Avg F1 [mm*s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg F1 [mm*s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="F2"
                type="html"
                title="Avg F2 [mm*s]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg F2 [mm*s]'">
                    <span class="name">Avg F2 [mm*s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg F2 [mm*s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="F3"
                type="html"
                title="Avg F3 [mm*s]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg F3 [mm*s]'">
                    <span class="name">Avg F3 [mm*s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg F3 [mm*s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="F4"
                type="html"
                title="Avg F4 [mm*s]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg F4 [mm*s]'">
                    <span class="name">Avg F4 [mm*s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg F4 [mm*s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Q0"
                type="html"
                title="Avg Q0 [mm*s]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg Q0 [mm*s]'">
                    <span class="name">Avg Q0 [mm*s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg Q0 [mm*s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Q1"
                type="html"
                title="Avg Q1 [mm*s]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg Q1 [mm*s]'">
                    <span class="name">Avg Q1 [mm*s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg Q1 [mm*s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Q2"
                type="html"
                title="Avg Q2 [mm*s]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg Q2 [mm*s]'">
                    <span class="name">Avg Q2 [mm*s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg Q2 [mm*s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>
              <vxe-column
                :formatter="formatterRemark"
                field="Q3"
                type="html"
                title="Avg Q3 [mm*s]"
                width="184"
                :fixed="demo1.colFixeds.col8"
                min-width="150">
                <!-- 增加下拉箭头 -->
                <template #header="{ column }">
                  <div class="Address-header" v-if="column.title === 'Avg Q3 [mm*s]'">
                    <span class="name">Avg Q3 [mm*s]</span>
                    <span><i id="MoreOptions" class="vxe-icon-caret-down" @click="MoreOperation('Avg Q3 [mm*s]', $event)"></i></span>
                  </div>
                </template>
              </vxe-column>

              <template #empty>
                <span style="color: red">
                  <!-- <img src="https://n.sinaimg.cn/sinacn17/w120h120/20180314/89fc-fyscsmv5911424.gif" /> -->
                  <p>没有更多数据了！</p>
                </span>
              </template>
            </vxe-table>
            <!-- 下拉箭头的更多操作 -->
            <div id="MoreOptions" v-show="showIdOptions" class="options" ref="OperationBox" @click.self="hiddenOperationBox">
              <div class="contentBox" id="MoreOptions">
                <ul class="list" id="MoreOptions">
                  <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Asc')">
                    <img src="https://newstore.vynior.com/%E5%8D%87%E5%BA%8F.png" alt="" />升序
                  </li>
                  <li id="MoreOptions" @click="AscendingSort(FixedIndex, 'Desc')">
                    <img src="https://newstore.vynior.com/%E9%99%8D%E5%BA%8F.png" alt="" />降序
                  </li>
                  <li id="MoreOptions" @click="toggleFixedColumn(FixedIndex, 'left')" :class="!CancelFixed ? 'light' : ''">
                    <img src="https://newstore.vynior.com/%E9%94%81%E5%AE%9A%402x.png" alt="" v-show="CancelFixed" />
                    <img src="https://newstore.vynior.com/cems_web/%E9%94%81%E5%AE%9A%402xs.png" alt="" v-show="!CancelFixed" />固定
                  </li>
                  <li id="MoreOptions" @click="toggleFixedColumns(FixedIndex, '')" :class="CancelFixed ? 'light' : ''" style="color: #bfbfbf">
                    <img src="https://newstore.vynior.com/cems_web/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="!CancelFixed" />
                    <img src="https://newstore.vynior.com/%E8%A7%A3%E9%94%81%402x.png" alt="" v-show="CancelFixed" />
                    取消固定
                  </li>

                  <li id="MoreOptions" @click="HiddenColumn(FixedIndex)"><img src="https://newstore.vynior.com/browse-off%402x.png" alt="" />隐藏</li>
                </ul>
              </div>
            </div>
            <!-- todo -->
            <!-- 每一行的 ... -->
            <div class="Options" id="CMoreOption" ref="CMoreOptions" v-show="ClickMoreOptions">
              <div class="Copy" @click="ExportData(OptionsData)">导出</div>
              <div class="Delete" @click="DeleteVolun(OptionsData)">删除</div>
            </div>
            <p>
              <vxe-pager
                class="Buttons"
                :style="changeBox ? '' : 'marginRight'"
                background
                @page-change="pageChange"
                v-model:current-page="page5.currentPage"
                v-model:page-size="page5.pageSize"
                :total="page5.totalResult"
                :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes', 'FullJump']">
                <template #left>
                  <div class="Total">
                    已选择<span class="Clicked" style="color: #027aff">{{ checkedArr.length }}</span
                    >条/共{{ page5.totalResult }}条
                  </div>
                </template>
              </vxe-pager>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="EmptyPage"
      style="width: 100%; height: 100%; background-color: #f8f8f8f8; display: flex; justify-content: center; align-items: center"
      v-if="tableData.length == 0">
      <div
        class="pageContent"
        style="
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: #ffffff;
          border-radius: 2px 2px 2px 2px;
          opacity: 1;
        ">
        <img src="https://newstore.vynior.com/cems_web/%E6%9A%82%E6%97%A0%E6%95%B0%E6%8D%AE111.png" alt="" />
        <span style="font-size: 16px; font-family: Source Han Sans CN-Regular, Source Han Sans CN; font-weight: 400; color: #626366">暂无数据</span>
      </div>
    </div>
    <!-- !表头字段设置 -->
    <div class="SetTableHeader" v-if="SetTableHeader">
      <div class="main">
        <div class="Header">
          <div class="text">表头字段设置</div>
        </div>
        <!-- 设置表头的数据盒子 -->
        <div class="SetBox">
          <!-- 左边盒子 项目列表预设字段(显示哪些字段) -->
          <div class="LeftBox">
            <div class="text-header">仪器测试记录列表预设字段</div>
            <div class="ProjectRowField">
              <!-- <div class="text-header1">【仪器基本信息】</div> -->
              <!-- 复选框数组 -->
              <div class="CheckBox">
                <div
                  class="Checkbox"
                  v-for="(item, index) in SetInstrumentListArr.slice(0, 37)"
                  :key="index"
                  :class="defaultHeader?.indexOf(item.lable) != -1 ? 'default' : ''">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index)" />
                </div>
              </div>
            </div>
            <!-- 【系统信息】 -->
            <!-- <div class="ProjectRecruitMSG">
              <div class="text-header2">【仪器校准信息】</div>
           
              <div class="CheckBox">
                <div class="Checkbox" v-for="(item, index) in SetInstrumentListArr.slice(18, 22)" :key="index">
                  <el-checkbox v-model="item.checked" :label="item.lable" size="large" @change="CheckHeader(index + 18)" />
                </div>
              </div>
            </div> -->

            <div class="Self-Defined">自定义扩展字段</div>
          </div>
          <!-- 右边盒子 拖动 -->
          <div class="RightBox">
            <div class="text-header">列表页展示字段</div>
            <div style="font-size:16px; color='black';margin-top:348px">此功能正在开发中,敬请期待</div>
          </div>
        </div>
        <!-- 底部 -->
        <div class="Bottom">
          <div class="add">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2022%402x.png" alt="" />
          </div>
          <div class="recover" @click="RecoverData">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2082%20%E2%80%93%2023%402x.png" alt="" />
          </div>
          <div class="cancel" @click="CancelShowHiden">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2087%20%E2%80%93%2010%402x.png" alt="" />
          </div>
          <div class="save" @click="ShowHidenColumn">
            <img src="https://newstore.vynior.com/%E7%BB%84%E4%BB%B6%2086%20%E2%80%93%2010%402x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <!-- 导入 -->
    <el-dialog class="volunteer_dialog" v-model="dialogShow1" title="Shipping address" :align-center="true" :close-on-click-modal="false">
      <div class="dialog_title">
        <span>上传数据</span>
        <div class="title_cancel" @click="dialogShow1 = false"></div>
      </div>
      <div class="dialog_main">
        <div class="main_download">
          <div class="download_title">1.下载模板，填写数据信息</div>
          <div class="download_img"></div>
          <div class="upload_name"></div>
          <div class="upload_err"></div>
          <div class="download_but" @click="downloadXlsx">
            <button>下载模板</button>
          </div>
        </div>
        <div class="main_upload">
          <div class="upload_title">2.上传填写好的文件</div>
          <div class="upload_img"></div>
          <div class="upload_name">
            <span v-if="uploadFileName">{{ uploadFileName }}</span>
          </div>
          <div class="upload_tip">
            <span v-if="uploadFileErr">文件格式错误，请重新上传</span>
            <span class="tip_up" v-if="uploadFileName && !uploadFileErr">重新选择</span>
          </div>
          <div class="upload_but">
            <el-upload
              class="upload_xlsx"
              ref="videoRef"
              accept=".xlsx,.xls"
              :http-request="uploadFile"
              :on-preview="handlePreviews"
              :on-remove="handleRemoves"
              :file-list="fileLists"
              :show-file-list="false">
              <button>上传文件</button>
            </el-upload>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 导入失败 -->
    <el-dialog class="volunteer_dialogs" :align-center="true" v-model="errdialogShow" title="Shipping address" :close-on-click-modal="false">
      <div class="dialog_title">
        <span>失败</span>
        <div class="title_cancel" @click="errdialogShow = false"></div>
      </div>
      <div class="dialog_main">
        <img class="main_err" src="https://newstore.vynior.com/cems_web/%E9%94%99%E8%AF%AF%402xs.png" alt="" />
        <div class="main_fail">导入失败</div>
        <div class="main_errT1">
          表格中存在<span class="tip">{{ errFileNum }}条</span>有误的数据信息
        </div>
        <div class="main_errT2">请下载报错文件，修改后重新上传数据。</div>
        <div class="main_but" @click="downloadErrFile">
          <button>下载文件</button>
        </div>
      </div>
    </el-dialog>
    <!-- 切换状态弹窗 -->
    <div class="ChangeS" v-if="isShowToInvalid">
      <el-dialog v-model="isShowToInvalid" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass">确认将该数据标记为无效数据吗？</div>
          </div>
        </template>
        <div class="Body">
          <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />将数据标记无效后可以在操作栏恢复为有效数据
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="Cancel" @click="isShowToInvalid = false">取消</el-button>
            <el-button class="Delete" @click.stop="ChangeStatus(status, row)"> 确认 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 导出一条没有的时候 -->
    <div class="Derived_volunteer_null" v-if="null_reminder_export">
      <!-- 导出 -->
      <div class="Derived_volunteer_box">
        <div class="Derived_title">
          <div class="Derived_name">提示</div>
          <div>
            <img @click="Closereminder" src="https://new.vynior.com/%E7%BB%84110366%402x.png" alt="" />
          </div>
        </div>
        <div class="Derived_body">
          <div>
            <img src="https://newstore.vynior.com/%E6%8F%90%E9%86%923.0.png" alt="" />
          </div>
          <div class="Derived_body_text">请选择需要导出的仪器测试记录信息！</div>
        </div>
      </div>
    </div>
    <!-- 批量样品信息  导出 -->
    <div class="Derived_volunteer" v-if="isExport">
      <div class="Derived_volunteer_box" v-if="isExport">
        <!-- 导出一个 -->
        <div v-if="checkedArr.length == 1">
          <div class="Derived_title">
            确定导出<span class="Derived_name">{{ selectedRows[0].Operator == ' ' ? '无名字' : selectedRows[0].Operator }}</span
            >的仪器测试记录信息吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">导出后可在excel表格中进行查看</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isExport = false">取消</div>
            <div class="ok" @click="exportChange">确认</div>
          </div>
        </div>
        <!-- 导出多个 -->
        <div v-if="checkedArr.length > 1">
          <div class="Derived_title">
            已选中<span class="Derived_name">&nbsp;{{ checkedArr.length }}条&nbsp;</span>仪器测试记录信息，确认导出吗？
          </div>
          <div class="Derived_body">
            <img src="https://new.vynior.com/111.png" alt="" />
            <div class="body_text">导出后可在excel表格中进行查看</div>
          </div>
          <div class="Derived_bottom">
            <div class="off" @click="isExport = false">取消</div>
            <div class="ok" @click="exportChange">确认</div>
          </div>
        </div>
      </div>
    </div>
    <div class="ChangeS" v-if="isShowToEffective">
      <el-dialog v-model="isShowToEffective" width="30%">
        <template #header="{ titleId, titleClass }">
          <div class="my-header">
            <div :id="titleId" :class="titleClass">确认将该数据恢复为有效数据吗？</div>
          </div>
        </template>
        <div class="Body">
          <img src="https://newstore.vynior.com/cems_web/%E6%8F%90%E9%86%92%402x.png" />将数据恢复有效后可以在操作栏标记为无效数据
        </div>
        <template #footer>
          <span class="dialog-footer">
            <el-button class="Cancel" @click="isShowToEffective = false">取消</el-button>
            <el-button class="Delete" @click.stop="ChangeStatus(status, row)"> 确认 </el-button>
          </span>
        </template>
      </el-dialog>
    </div>
    <!-- 显示功能弹窗 -->
    <div class="SetShowsBox" ref="ShowBox" v-show="SetShow" id="SetShowsBoxs">
      <div class="Titles" id="SetShowsBoxs">字段显示</div>
      <div class="ContentBox" :class="AllHidenHeader.length == 0 ? 'Emptys' : ''" id="SetShowsBoxs">
        <span class="Empty" v-if="AllHidenHeader.length == 0" id="SetShowsBoxs">暂无隐藏字段</span>
        <div class="ListBox" v-if="AllHidenHeader.length != 0" id="SetShowsBoxs">
          <div class="List" v-for="(item, index) in AllHidenHeader" :key="index" @click="ShowCheck[index] = !ShowCheck[index]" id="SetShowsBoxs">
            <div class="item" id="SetShowsBoxs">{{ item.title }}</div>

            <div class="Active" v-show="ShowCheck[index]" id="SetShowsBoxs">
              <img src="https://newstore.vynior.com/cems_web/%E7%BB%84%20110500%402x.png" alt="" />
            </div>
          </div>
          <div
            class="Button"
            @click="ConfirmRecover"
            id="SetShowsBoxs"
            :class="AllHidenHeader.length == 1 ? 'Buttons' : AllHidenHeader.length == 2 ? 'Buttonss' : ''">
            确认
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { message } from '@/utils/resetMessage'
import dayjs from 'dayjs'
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import http from '@/utils/http'
import { replaceEmptyValues } from '@/utils/emptyArray'
import { ElMessage } from 'element-plus'
import * as xlsx from 'xlsx-js-style'

const linewidth = ref(window.innerWidth)
const handleResize = () => {
  linewidth.value = window.innerWidth
}
const isRemark = ref('标记无效')
onMounted(() => {
  handleResize()
  window.addEventListener('resize', handleResize)
})
let CancelFixed = ref(false) //控制每个表头下拉箭头 固定和取消固定的高亮
onBeforeUnmount(() => {
  window.removeEventListener('resize', handleResize)
})

// !表单空白处理
const tableData = computed(() => {
  return replaceEmptyValues(demo1.tableData)
})

//API接收
const store = useStore()
const router = useRouter()
const xTable = ref()
// ? 盒子宽度
let boxWidth = ref('144')
let changeBox = ref(false)
let marginRight = ref('margin-left:200px;')
//? 子目录样式
const subdirectory_num = ref([0, 0, 0])
//? 子目录点击
const subdirectory_click = (num) => {
  subdirectory_num.value = subdirectory_num.value.map((item, index) => (index === num ? 1 : 0))
}
let activeIndex = ref(1)
const handleSelect = (index) => {
  activeIndex.value = index
  console.log(index)
  if (activeIndex.value == 1) {
    reqProjectList()
  } else if (activeIndex.value == 2) {
    reqProjectList1()
  }
}
// 字段为空展示——
const formatterRemark = ({ cellValue }) => {
  ////console.log('cellValue :>> ', cellValue);
  return cellValue === undefined || cellValue === '' || cellValue === null ? '——' : cellValue
}
// ? 点击盒子变小
const toggleBoxWidth = () => {
  if (boxWidth.value === 144) {
    boxWidth.value = 30 // 改变宽度为200
    changeBox.value = false
  } else {
    boxWidth.value = 144 // 改变宽度为168
    changeBox.value = true
  }
}
//  ! 收缩导航栏
const isCollapse = ref(false)
// table组件数据
let totalCount = ref(0)
const SetTableHeader = ref(false)
// 表头字段设置--->点击设置表头字段的临时数据
let TemporaryData = ref([])
//表头字段设置--->设置表头的勾选数据
let SetInstrumentListArr = ref([
  { fixed: '', field: 'Time_DateSession', checked: true, lable: 'Time & Date Session' },
  { fixed: '', field: 'Probe', checked: true, lable: 'Probe' },
  { fixed: '', field: 'ProbeSN', checked: true, lable: 'Probe SN' },
  { fixed: '', field: 'Operator', checked: true, lable: 'Operator' },
  { fixed: '', field: 'Study', checked: true, lable: 'Study' },
  { fixed: '', field: 'Subject', checked: true, lable: 'Subject' },
  { fixed: '', field: 'T', checked: true, lable: 'T' },
  { fixed: '', field: 'Tags', checked: true, lable: 'Tags' },
  { fixed: '', field: 'OrderOfTake', checked: true, lable: 'Order of Take' },
  { fixed: '', field: 'NumberofMeasurements', checked: true, lable: 'Number of Measurements' },
  { fixed: '', field: 'PressureMode', checked: true, lable: 'Pressure Mode' },
  { fixed: '', field: 'Repetition', checked: true, lable: 'Repetition' },
  { fixed: '', field: 'Pressure', checked: true, lable: 'Pressure [mbar]' },
  { fixed: '', field: 'OnTime', checked: true, lable: 'On Time [s]' },
  { fixed: '', field: 'OffTime', checked: true, lable: 'Off Time [s]' },
  { fixed: '', field: 'Offset', checked: true, lable: 'Avg Offset [mm]' },
  { fixed: '', field: 'R0', checked: true, lable: 'Avg R0 [mm]' },
  { fixed: '', field: 'R1', checked: true, lable: 'Avg R1 [mm]' },
  { fixed: '', field: 'R2', checked: true, lable: 'Avg R2 [mm]' },
  { fixed: '', field: 'R3', checked: true, lable: 'Avg R3 [mm]' },
  { fixed: '', field: 'R4', checked: true, lable: 'Avg R4 [mm]' },
  { fixed: '', field: 'R5', checked: true, lable: 'Avg R5 [mm]' },
  { fixed: '', field: 'R6', checked: true, lable: 'Avg R6 [mm]' },
  { fixed: '', field: 'R7', checked: true, lable: 'Avg R7 [mm]' },
  { fixed: '', field: 'R8', checked: true, lable: 'Avg R8 [mm]' },
  { fixed: '', field: 'R9', checked: true, lable: 'Avg R9 [mm]' },
  { fixed: '', field: 'R10', checked: true, lable: 'Avg R10 [mm]' },
  { fixed: '', field: 'F0', checked: true, lable: 'Avg F0 [mm*s]' },
  { fixed: '', field: 'F1', checked: true, lable: 'Avg F1 [mm*s]' },
  { fixed: '', field: 'F2', checked: true, lable: 'Avg F2 [mm*s]' },
  { fixed: '', field: 'F3', checked: true, lable: 'Avg F3 [mm*s]' },
  { fixed: '', field: 'F4', checked: true, lable: 'Avg F4 [mm*s]' },
  { fixed: '', field: 'Q0', checked: true, lable: 'Avg Q0 [mm*s]' },
  { fixed: '', field: 'Q1', checked: true, lable: 'Avg Q1 [mm*s]' },
  { fixed: '', field: 'Q2', checked: true, lable: 'Avg Q2 [mm*s]' },
  { fixed: '', field: 'Q3', checked: true, lable: 'Avg Q3 [mm*s]' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
])
// 表头字段设置--->恢复默认字段
let DefaultHeaderData = ref([
  { fixed: '', field: 'Time_DateSession', checked: true, lable: 'Time & Date Session' },
  { fixed: '', field: 'Probe', checked: true, lable: 'Probe' },
  { fixed: '', field: 'ProbeSN', checked: true, lable: 'Probe SN' },
  { fixed: '', field: 'Operator', checked: true, lable: 'Operator' },
  { fixed: '', field: 'Study', checked: true, lable: 'Study' },
  { fixed: '', field: 'Subject', checked: true, lable: 'Subject' },
  { fixed: '', field: 'T', checked: true, lable: 'T' },
  { fixed: '', field: 'Tags', checked: true, lable: 'Tags' },
  { fixed: '', field: 'OrderOfTake', checked: true, lable: 'Order of Take' },
  { fixed: '', field: 'NumberofMeasurements', checked: true, lable: 'Number of Measurements' },
  { fixed: '', field: 'PressureMode', checked: true, lable: 'Pressure Mode' },
  { fixed: '', field: 'Repetition', checked: true, lable: 'Repetition' },
  { fixed: '', field: 'Pressure', checked: true, lable: 'Pressure [mbar]' },
  { fixed: '', field: 'OnTime', checked: true, lable: 'On Time [s]' },
  { fixed: '', field: 'OffTime', checked: true, lable: 'Off Time [s]' },
  { fixed: '', field: 'Offset', checked: true, lable: 'Avg Offset [mm]' },
  { fixed: '', field: 'R0', checked: true, lable: 'Avg R0 [mm]' },
  { fixed: '', field: 'R1', checked: true, lable: 'Avg R1 [mm]' },
  { fixed: '', field: 'R2', checked: true, lable: 'Avg R2 [mm]' },
  { fixed: '', field: 'R3', checked: true, lable: 'Avg R3 [mm]' },
  { fixed: '', field: 'R4', checked: true, lable: 'Avg R4 [mm]' },
  { fixed: '', field: 'R5', checked: true, lable: 'Avg R5 [mm]' },
  { fixed: '', field: 'R6', checked: true, lable: 'Avg R6 [mm]' },
  { fixed: '', field: 'R7', checked: true, lable: 'Avg R7 [mm]' },
  { fixed: '', field: 'R8', checked: true, lable: 'Avg R8 [mm]' },
  { fixed: '', field: 'R9', checked: true, lable: 'Avg R9 [mm]' },
  { fixed: '', field: 'R10', checked: true, lable: 'Avg R10 [mm]' },
  { fixed: '', field: 'F0', checked: true, lable: 'Avg F0 [mm*s]' },
  { fixed: '', field: 'F1', checked: true, lable: 'Avg F1 [mm*s]' },
  { fixed: '', field: 'F2', checked: true, lable: 'Avg F2 [mm*s]' },
  { fixed: '', field: 'F3', checked: true, lable: 'Avg F3 [mm*s]' },
  { fixed: '', field: 'F4', checked: true, lable: 'Avg F4 [mm*s]' },
  { fixed: '', field: 'Q0', checked: true, lable: 'Avg Q0 [mm*s]' },
  { fixed: '', field: 'Q1', checked: true, lable: 'Avg Q1 [mm*s]' },
  { fixed: '', field: 'Q2', checked: true, lable: 'Avg Q2 [mm*s]' },
  { fixed: '', field: 'Q3', checked: true, lable: 'Avg Q3 [mm*s]' },
  { fixed: 'right', field: 'checked', checked: true, lable: '操作' },
])
// 批量删除盒子 BatchDelete
let BatchDelete = ref(false)
// 单个删除仪器名字
const DeleteMsg = ref({
  Name: '',
  ID: '',
})
const isExport = ref(false)
const isShowExport = () => {
  console.log('点击了导出', selectedRows)
  if (checkedArr.value.length >= 1) {
    null_reminder_export.value = false
    isExport.value = true
  } else {
    null_reminder_export.value = true
    isExport.value = false
  }
}
let null_reminder_export = ref(false) // ! 没有勾选数据导出
// ! 关闭提醒弹窗
const Closereminder = () => {
  null_reminder_export.value = false
}
// 点击导出
const exportChange = async () => {
  console.log(checkedArr.value, '导出的id')
  if (checkedArr.value.length > 0) {
    const res = await http.post('/upload/export-instrumentRecord', {
      idList: checkedArr.value,
      type: activeIndex.value == 1 ? 'Mode1 Avg' : 'Mode1',
    })
    console.log(res, '导出数据返回值')
    if (res.status == 0) {
      xlsx.writeFileXLSX(res.xlsxData, `仪器测试记录 ${dayjs().format('YYYYMMDD')}.xlsx`)
      message({
        message: '导出成功',
        showClose: true,
        type: 'success',
      })
      isExport.value = false
    }
  } else {
    message({
      message: '请选择要导出的数据',
      showClose: true,
      type: 'error',
    })
  }
}
//  每一行的 ... 点击
let ClickMoreOptions = ref(false)
const CMoreOptions = ref(null) //获取MoreOptions盒子的ref
let OptionsData = ref()
//demo 显示数据
const demo1 = reactive({
  colFixeds: {
    col0: 'left',
    col1: '',
    col2: '',
    col3: '',
    col4: '',
    col5: '',
    col6: '',
    col7: '',
    col8: '',
    col9: 'right',
  },
  tableData: [
    //  展示的数据
  ],
  filterName: '',
  loading: true,
  list: [],
  gender: '',
  age: '',
})
const gender = ['男', '女']
const ageList = ['不限Subject', '18~25', '26~40', '40~49', '≥50']
let Volunteerdetails = ref(false) // !查看志愿者详情
// sort排序字段
let SORT = ref({ createdAt: -1 })
// 查看详情的数据
let information = ref([])
let Volunteereditor = ref(false)
// 模糊搜索 判断是否是第一次
const EventSearch = ref(false)
// 模糊Age 判断是否是第一次
const AgeSearch = ref(false)
// 模糊Gender 判断是否是第一次
const GenderSearch = ref(false)

//显示功能的字段设置
let AllShowHeader = ref([])
// 全部显示里被隐藏的
let AllHidenHeader = ref([])
const SetShow = ref(false)
const ShowBox = ref(null) //获取Show盒子的ref
const ShowCheck = ref([])
//导入
let dialogShow1 = ref(false)
const uploadData = () => {
  console.log(123333)
  dialogShow1.value = true
}
// 点击下载模板
const downloadXlsx = async () => {
  if (activeIndex.value == 2) {
    const xlsxUrl = 'https://newstore.vynior.com/cems_web/Cutometer%20MPA580-Cutometer%20Mode1.xlsx'
    const response = await fetch(xlsxUrl)
    const blob = await response.blob()
    // 创建下载链接
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = '仪器测试记录-16427414 皮肤弹性测试探头Cutometer MPA580-上传数据.xlsx'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    // 释放 Blob URL
    window.URL.revokeObjectURL(url)
  }
  if (activeIndex.value == 1) {
    const xlsxUrl = 'https://newstore.vynior.com/cems_web/Cutometer%20MPA580-Cutometer%20Mode1%20Avg.xlsx'
    const response = await fetch(xlsxUrl)
    const blob = await response.blob()
    // 创建下载链接
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = '仪器测试记录-16427414 皮肤弹性测试探头Cutometer MPA580 Mode1 Avg-上传数据.xlsx'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    // 释放 Blob URL
    window.URL.revokeObjectURL(url)
  }
}

// 导入志愿者错误返回的路径
let errorPath = ref()
// 导入志愿者错误的数据量
let errFileNum = ref(null)
// 上传的文件名
let fileName = ref('')
// 上传文件名
let uploadFileName = ref('')
// 是否上传错误
let uploadFileErr = ref(false)
//导入失败显示隐藏
const errdialogShow = ref(false)
// 上传事件
const uploadFile = async (params) => {
  if (activeIndex.value == 1) {
    console.log(params)
    const file = params.file
    // fileName.value=file.name
    const lastIndex = file.name.lastIndexOf('.')
    fileName.value = file.name.substring(0, lastIndex)
    const form = new FormData()
    form.append('file', file)
    form.append('type', 'Mode1 Avg')
    console.log(params)
    await http
      .post('/upload/upload-instrumentRecord', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(async (res) => {
        console.log(res)
        if (res.status == 0) {
          dialogShow1.value = false
          reqProjectList()
          ElMessage({
            showClose: true,
            message: '上传数据成功',
            type: 'success',
          })
        } else if (res.status == 1) {
          ElMessage({
            showClose: true,
            message: '上传文件为空，请重新上传',
            type: 'error',
          })
        }
        if (res.status == 2) {
          dialogShow1.value = false
          errorPath.value = res.errorPath
          errdialogShow.value = true
          errFileNum.value = res.errorNumber
        }
      })
  }
  if (activeIndex.value == 2) {
    console.log(params)
    const file = params.file
    // fileName.value=file.name
    const lastIndex = file.name.lastIndexOf('.')
    fileName.value = file.name.substring(0, lastIndex)
    const form = new FormData()
    form.append('file', file)
    form.append('type', 'Mode1')
    console.log(params)
    await http
      .post('/upload/upload-instrumentRecord', form, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      .then(async (res) => {
        console.log(res)
        if (res.status == 0) {
          dialogShow1.value = false
          reqProjectList()
          ElMessage({
            showClose: true,
            message: '上传数据成功',
            type: 'success',
          })
        } else if (res.status == 1) {
          ElMessage({
            showClose: true,
            message: '上传文件为空，请重新上传',
            type: 'error',
          })
        }
        if (res.status == 2) {
          dialogShow1.value = false
          errorPath.value = res.errorPath
          errdialogShow.value = true
          errFileNum.value = res.errorNumber
        }
      })
  }
}
// 点击下载错误模板
const downloadErrFile = async () => {
  axios
    .post('https://cems.fuhuan.ltd/api/upload/downloadError-volunteer', { errorPath: errorPath.value }, { responseType: 'arraybuffer' })
    .then((result) => {
      console.log(result, '返回的错误数据')
      const blob = new Blob([result.data], { type: 'application/vnd.ms-excel' })
      let name = fileName.value + '-报错.xlsx'
      const downloadElement = document.createElement('a')
      let classificationhref = window.URL.createObjectURL(blob)
      downloadElement.href = classificationhref
      downloadElement.download = `${name}`
      // // console.log("blob :>> "，blob);
      document.body.appendChild(downloadElement)
      downloadElement.click()
      document.body.removeChild(downloadElement)
      window.URL.revokeObjectURL(classificationhref)
    })
}
const handlePreviews = (file) => {
  console.log(file, 'handlePreviews')
}
const uploadFileSuccess = (res) => {
  console.log('上传成功')
  console.log(res)
}

const handleRemoves = (file, fileList) => {
  console.log(file, fileList, 'handleRemoves')
}
//标记无效、恢复有效
const isShowToInvalid = ref(false)
const isShowToEffective = ref(false)
const Remark = ref('')
const isShowRemark = (status, row) => {
  console.log(status, row)
  Remark.value = row
  if (status == '标记无效') {
    row.remark = '标记无效'
    isShowToInvalid.value = true
    isShowToEffective.value = false
  } else {
    row.remark = '恢复有效'
    isShowToEffective.value = true
    isShowToInvalid.value = false
  }
}
const ChangeStatus = async () => {
  console.log('来切换状态啦')
  console.log(Remark.value.remark)
  let type = ''
  if (activeIndex.value == 1) {
    type = 'Mode1 Avg'
  } else if (activeIndex.value == 2) {
    type = 'Mode1'
  }
  // console.log('调用 ChangeStatus 时传入的 status 值为:', status)
  if (Remark.value.remark == '标记无效') {
    const res = await http.post('/instrument/setinvalid', {
      _id: Remark.value._id,
      type: type,
      invalid: true,
    })
    if (res.status == 0) {
      // status='恢复有效'
      Remark.value.remark = '恢复有效'
      isRemark.value = '恢复有效'
      console.log(Remark.value.remark, '-----切换了的状态')
      isShowToInvalid.value = false
    }
  } else if (Remark.value.remark == '恢复有效') {
    const res = await http.post('/instrument/setinvalid', {
      _id: Remark.value._id,
      type: type,
      invalid: false,
    })
    if (res.status == 0) {
      // status='标记无效'
      Remark.value.remark = '标记无效'
      isRemark.value = '标记无效'
      console.log(Remark.value.remark)
      isShowToEffective.value = false
    }
  }
}
//->全部数据
const reqProjectList = async () => {
  const res = await http.post('/instrument/fetchCutometerRecord-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    type: 'Mode1 Avg',
  })
  console.log('1111')
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.result
    page5.totalResult = res.totalCount

    console.table('res :>> ', res)
  }
}
const reqProjectList1 = async () => {
  const res = await http.post('/instrument/fetchCutometerRecord-instrument', {
    page: page5.currentPage,
    size: page5.pageSize,
    sort: SORT.value,
    content: demo1.filterName,
    type: 'Mode1',
  })
  console.log('1111')
  if (res.status == 0) {
    demo1.loading = false
    demo1.tableData = res.result
    page5.totalResult = res.totalCount

    console.table('res :>> ', res)
  }
}
// 监听项目id
watch(
  () => store.state.Project.ProjectId,
  (newValue) => {
    reqProjectList()
  }
)
// 设置新建志愿者组件显示隐藏
let CreatedVolunteerShow = ref(false)
// ! 控制批量操作的显示
let isshowOperation = ref(false)
// ! 导出志愿信息弹框
let isDerived = ref(false)
// ! 导出志愿信息弹框   失败
let isDerived_delect = ref(false)
// ! 下拉实时更新数据
let options = ref([])
//  !输入框数据
let selectID = ref([])
// ! 报名弹窗
let Register = ref(false)
// ! 搜索的数据
let remark = ref(true)
let oneVolunteerdata = ref([])
// ! 点击数据进行查询
const search_one_data = async () => {
  remark.value = false
  let id = selectID.value
  console.log(id, 'id')
  try {
    const { results } = await http.post('/volunteer/find-users', {
      size: 1,
      Volunteers: { idNumber: id },
      page: 1,
      sort: { updatedAt: -1 },
    })
    oneVolunteerdata.value = results
    console.log(oneVolunteerdata, 'oneVolunteerdata')
    console.log('刚开始的数据oneVolunteerdata', oneVolunteerdata.value.length)
  } catch (error) {
    console.log('失败')
  }
}
// 点击新建志愿者
const CreatedVolunteerChange = () => {
  CreatedVolunteerShow.value = !CreatedVolunteerShow.value
  //   //console.log('点击新建志愿者');
}
// 表头的顺序
const handleHeaderDragend = (params) => {
  const { columns } = params
  //   //console.log(columns); // 打印排序后的表头列数组对象
}
// note 表内导航栏
const ProjectState = ref('')
ProjectState.value = ['全脸分析', 'AOI区域分析']
// !重置按钮
const reset = () => {
  demo1.age = ''
  demo1.gender = ''
  demo1.filterName = ''
  EventSearch.value = false
  GenderSearch.value = false
  AgeSearch.value = false
  page5.pageSize = 10
  page5.currentPage = 1
  const $table = xTable.value
  const records = $table.clearCheckboxRow()
  reqProjectList()
}
// !批量按钮显示
const showOperation = () => {
  isshowOperation.value = true
}
// ! 导出
const Export = () => {
  console.log(selectedRows)
  console.log(SetVolunteerHeaderArr.value)

  if (selectedRows.length !== 0) {
    console.log(SetVolunteerHeaderArr.value)
    isshowOperation.value = false
    isshowDerived()
  } else {
    // ElMessage('一条都没有');
  }
  // 判断值，进行展示
}

// ! !导出志愿者信息弹窗  失败  确定
const isshowDerived_delect_true = async () => {
  // !删除对应数据，并刷新页面
  const ids = selectedRows.map((obj) => obj._id)
  console.log(ids, 'idddddd') // [1, 2, 3]
  try {
    const res = await http.delete('/volunteer/delete-user', {
      deleteData: ids,
    })
    isDerived_delect.value = !isDerived_delect.value
    reqProjectList()
    message({
      message: '删除成功',
      grouping: true,
      type: 'success',
    })
  } catch (error) {
    message({
      message: '删除失败',
      grouping: true,
      type: 'error',
    })
  }
}
const EditOpen = () => {
  is_CreatedDeviceDialogShow.value = true
}
// 点击...
const ClickMethods = (row, event) => {
  // console.log(row);
  OptionsData.value = row
  // console.log(DelectData.value);
  ClickMoreOptions.value = false
  ClickMoreOptions.value = true

  // console.log('event :>> ', event, CMoreOptions.value);

  CMoreOptions.value.style.left = event.clientX - 120 + 'px'
  CMoreOptions.value.style.top = event.clientY + 30 + 'px'
  // console.log('event :>> ', ClickMoreOptions.value);
}

//分页--->分页处理
const page5 = reactive({
  currentPage: 1,
  pageSize: 10,
  totalResult: 0,
})

// 分页--->分页
//点击盒子之外的地方(取消显示)
const hiddenOperationBox = () => {
  showIdOptions.value = false
  //console.log('点击点击 :>> ');
}

//分页--->跳转页面显示数据处理
const pageChange = async () => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()

  if (demo1.filterName == '') {
    if (activeIndex.value == 1) {
      const res = await http.post('/instrument/fetchCutometerRecord-instrument', {
        page: page5.currentPage,
        size: page5.pageSize,
        sort: SORT.value,
        content: demo1.filterName,
        type: 'Mode1 Avg',
      })
      console.log(res, '1233456')
      if (res.status == 0) {
        demo1.loading = false
        demo1.tableData = res.result
        page5.totalResult = res.totalCount
      }
    } else if (activeIndex.value == 2) {
      const res = await http.post('/instrument/fetchCutometerRecord-instrument', {
        page: page5.currentPage,
        size: page5.pageSize,
        sort: SORT.value,
        content: demo1.filterName,
        type: 'Mode1',
      })
      console.log(res, '1233456')
      if (res.status == 0) {
        demo1.loading = false
        demo1.tableData = res.result
        page5.totalResult = res.totalCount
      }
    }
  } else {
    const res = await http.post('/instrument/fetchCutometerRecord-instrument', {
      page: (page5.currentPage = 1),
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
      type: 'Mode1 Avg',
    })
    console.log(res, '1233456')
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    }
  }
}

//导出弹窗显示隐藏
let dialogShow = ref(false)
//导出方法
const exportDataEvent = () => {
  // const $table = xTable.value;
  // $table.exportData({ type: 'csv' });
  console.log('123')

  dialogShow.value = true
}

//复选框数组
var selectedRows = reactive([])
const checkedArr = ref([])

// 复选框点击事件
const selectChangeEvent1 = (row) => {
  // console.log('点击的id:', row.rowid);
  console.log('row :>> ', row)

  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  // console.log(checked ? '勾选事件' : '取消事件', records);
  selectedRows = records

  if (checkedArr.value.indexOf(row.rowid) == -1) {
    checkedArr.value.push(row.rowid)
  } else {
    let index = checkedArr.value.indexOf(row.rowid)
    checkedArr.value.splice(index, 1)
  }
  console.log('最新:', checkedArr.value)
}
// <!--全选 -->
const selectAllChangeEvent1 = ({ checked }) => {
  const $table = xTable.value
  const records = $table.getCheckboxRecords()
  console.log(checked ? '所有勾选事件' : '所有取消事件', records)
  if (records.length != 0) {
    for (let i = 0; i < records.length; i++) {
      if (checkedArr.value.indexOf(records[i]._id) == -1) {
        checkedArr.value.push(records[i]._id)
      }
    }
    console.log('最新:', checkedArr.value)
  } else {
    // console.log('demo1.tableData:', demo1.tableData);
    let current_id = []

    demo1.tableData.forEach((item, index) => {
      current_id.push(item._id)
    })

    checkedArr.value = checkedArr.value.filter(function (item) {
      return !current_id.includes(item)
    })
    console.log('最新:', checkedArr.value)
  }
}
// 模糊查询

const searchEvent2 = async () => {
  demo1.loading = true
  let Project = ''
  if (activeIndex.value == 1) {
    const res = await http.post('/instrument/fetchCutometerRecord-instrument', {
      page: (page5.currentPage = 1),
      size: page5.pageSize,
      sort: { createdAt: -1 },
      content: demo1.filterName,
      type: 'Mode1 Avg',
    })
    console.log(res, '1233456')
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    }
  } else if (activeIndex.value == 2) {
    const res = await http.post('/instrument/fetchCutometerRecord-instrument', {
      page: (page5.currentPage = 1),
      size: page5.pageSize,
      sort: { createdAt: -1 },
      content: demo1.filterName,
      type: 'Mode1',
    })
    console.log(res, '1233456')
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    }
  }
}
//  More -->下拉选择中的固定方法
const toggleFixedColumn = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
const toggleFixedColumns = (index, type) => {
  showIdOptions.value = false
  //   //console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  //   //console.log('固定信息:', tableColumns);
  const fxColumn = tableColumns[index]
  //   //console.log('固定信息2:', fxColumn);

  // console.dir()

  if (fxColumn) {
    fxColumn.fixed = type
  }
  // 刷新列
  $table.refreshColumn()
}
// More -->升序/降序
const AscendingSort = async (index, type) => {
  console.log('我的type: :>> ', type)
  demo1.loading = true

  // console.log('我的下标是: :>> ', index);
  const $table = xTable.value
  const tableColumns = $table.getColumns()
  // FixedList = tableColumns;
  // console.log('固定信息:', tableColumns);
  const ColumnId = tableColumns[index].field
  const sorts = {}
  if (type == 'Asc') {
    sorts[ColumnId] = 1
  } else {
    sorts[ColumnId] = -1
  }

  SORT.value = sorts
  console.log(SORT.value, '123456789990')
  if (activeIndex.value == 1) {
    const res = await http.post('/instrument/fetchCutometerRecord-instrument', {
      page: (page5.currentPage = 1),
      size: page5.pageSize,
      sort: SORT.value,
      content: demo1.filterName,
      type: 'Mode1 Avg',
    })
    console.log(res, '1233456')
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    }
  } else if (activeIndex.value == 2) {
    const res = await http.post('/instrument/fetchCutometerRecord-instrument', {
      page: page5.currentPage,
      size: page5.pageSize,
      sort: createdAt,
      content: demo1.filterName,
      type: 'Mode1',
    })
    console.log(res, '1233456')
    if (res.status == 0) {
      demo1.loading = false
      demo1.tableData = res.result
      page5.totalResult = res.totalCount
    }
  }
}
//
// 获取当前的全部数据
const TransferData = () => {
  // 将全部显示的数据  放到 AllShowHeader中
  SetInstrumentListArr.value.forEach((item, index) => {
    if (item.checked == true) {
      AllShowHeader.value.push(item)
    }
  })
  console.log('AllShowHeader.value :>> ', AllShowHeader.value)
}
// !恢复目前隐藏的列表----> 获取event 设置show显示框的位置
const SetShowFunction = (event) => {
  SetShow.value = false
  SetShow.value = true
  // console.log('event :>> ', event, ShowBox.value);
  ShowBox.value.style.left = event.clientX - 10 + 'px'
  ShowBox.value.style.top = event.clientY + 20 + 'px'
}
// ! 恢复目前隐藏的列表----> 确认按钮
const ConfirmRecover = () => {
  let TeShowCheck = []
  let TeAllHidenHeader = []
  const $table = xTable.value
  const column = $table.getColumns()
  console.log('ShowCheck.value :>> ', ShowCheck.value)
  for (let i = ShowCheck.value.length; i >= 0; i--) {
    if (ShowCheck.value[i] == true) {
      // // column[index].visible = false;
      // console.log('column :>> ', ShowCheck.value[i], AllHidenHeader.value[i]);

      // 显示列
      $table.showColumn(AllHidenHeader.value[i].field)
      // 同步删除 数据 隐藏列/checked
      AllHidenHeader.value.splice(i, 1)
      ShowCheck.value.splice(i, 1)
      // console.log('column :>> ', column, $table);
      // console.log('column :>> ', $table.showColumn());
      // console.log('column :>> ', ShowCheck.value, AllHidenHeader.value);
    }
  }
  SetShow.value = false
}
// More -->隐藏
const HiddenColumn = (index) => {
  showIdOptions.value = false
  const $table = xTable.value
  const column = $table.getColumns()
  // console.log('column[index] :>> ', column[index]);
  column[index].visible = false
  // 把隐藏的列 导入到 AllHidenHeader 中,用与 显示功能
  AllHidenHeader.value.push(column[index])
  ShowCheck.value.push(false)
  $table.refreshColumn() //刷新列表
  console.log('ShowChcke.value :>> ', ShowCheck.value)
  // console.log('AllHidenHeader.value :>> ', AllHidenHeader.value);
}
// 每一行的查看详情
const ShowDetails = (row) => {
  console.log('row :>> ', row)
  currentID.value = row._id
  Volunteerdetails.value = !Volunteerdetails.value
  Volunteereditor.value = true
  demo1.tableData.forEach((item, index) => {
    if (item._id == row._id) {
      information.value = item
    }
  })
}
//
// 每一行的删除
const DeleteVolun = async (row) => {
  console.log('row :>> ', row)
  DeleteMsg.value.Name = row.instr_name
  DeleteMsg.value.ID = row._id
  BatchDelete.value = true
  // demo1.loading = true;
}
const SingleDelete = async () => {
  const res2 = await http.delete('/instrument/delete-Instrument', {
    deleteData: [DeleteMsg.value.ID],
  })
  if (res2.status == 0) {
    BatchDelete.value = false
    message({
      message: '删除成功',
      ShowClose: true,
      type: 'success',
    })
  }
  reqProjectList()
}
// 关闭修改页面请求新的详情数据
const ResetInDetails = async () => {
  const res = await http.post('/instrument/find-instrument', {
    page: 1,
    size: 10,
    sort: SORT.value,
    content: demo1.filterName,
  })
  console.log('res :>> ', res)
  information.value = JSON.parse(JSON.stringify(res.instruments[0]))
}

// 点击表头的下拉箭头
const OperationBox = ref(null) //获取更多操作盒子的ref
const showIdOptions = ref(false) //更多操作盒子的显示/隐藏
const FixedIndex = ref(-1)
//点击表头的下拉箭头
const MoreOperation = (value, event) => {
  //   //console.log('value :>> ', value);
  const table = xTable.value
  const FixedList = table.getColumns()

  FixedList.forEach((item, index) => {
    console.log('item :>> ', item, item.fixed)
    if (item.title == value) {
      FixedIndex.value = index
      if (item.fixed) {
        CancelFixed.value = false
        // console.log('有值 :>> ');
      } else {
        CancelFixed.value = true
        // console.log('无值 :>> ');
      }
    }
  })
  //console.log('FixedIndex下标为 :>> ', FixedIndex);

  showIdOptions.value = false
  showIdOptions.value = true
  // 设置位置
  const Event = event
  OperationBox.value.style.left = event.clientX - 370 + 'px'
}

// note 全局加载完毕之后执行此事件
nextTick(() => {
  setHeaderColumn()
  // 执行 列表显示/隐藏
  ShowHidenColumn()
  // 固定列表本地数据
  FixedColumn()
  // 转移数据
  TransferData()
})

// 检查是否有本地数据()
const setHeaderColumn = () => {
  if (localStorage.getItem('MPA580ShowHiddenArr') == null) {
    // ////console.log('"第一次" :>> ', '第一次');
  } else {
    // ////console.log('"第二次" :>> ', '第二次');
    SetInstrumentListArr.value = JSON.parse(localStorage.getItem('MPA580ShowHiddenArr'))
  }
}
// 表头字段设置----> 点击操作的设置icon
const SetHeader = () => {
  SetTableHeader.value = true
  TemporaryData.value = JSON.parse(JSON.stringify(SetInstrumentListArr.value))

  //console.log('SetTableHeader', SetTableHeader.value);
}
// 表头字段设置----> 取消按钮
const CancelShowHiden = () => {
  SetTableHeader.value = false
  SetInstrumentListArr.value = JSON.parse(JSON.stringify(TemporaryData.value))
}
//  表头字段设置----> 恢复默认字段
const RecoverData = () => {
  console.log('DefaultHeaderData.value :>> ', DefaultHeaderData.value)
  // let defaultdata = readonly(DefaultHeaderData)
  SetInstrumentListArr.value = JSON.parse(JSON.stringify(DefaultHeaderData.value))
}
// 默认展示的字段/不可取消
let defaultHeader = ref(['操作'])
// 表头阻断设置---->的点击复选框
const CheckHeader = (i) => {
  console.log('SetTableHeaderArr(value.checked) :>> ', SetInstrumentListArr.value[i], i)
  // console.log('isReactive(SetTableHeaderArr) :>> ', isRef(SetTableHeaderArr));

  if (defaultHeader.value.indexOf(SetInstrumentListArr.value[i].lable) != -1) {
    message({
      message: `${SetInstrumentListArr.value[i].lable}不能删除`,
      Showclose: true,
      type: 'error',
    })
    SetInstrumentListArr.value[i].checked = true
  }
}
// ShowHidenColumn 设置column的的列表(确认按钮)
const ShowHidenColumn = () => {
  const $table = xTable.value
  const column = $table.getColumns()

  //console.log('$table.getColumns :>> ', $table.getColumns());

  for (let i = 0; i < SetInstrumentListArr.value.length; i++) {
    if (SetInstrumentListArr.value[i].checked == true) {
      // //console.log('object :>> ', SetInstrumentListArr[i].field);
      $table.showColumn(SetInstrumentListArr.value[i].field)
    }
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetInstrumentListArr.value[i].lable) {
        if (SetInstrumentListArr.value[i].checked == false) {
          $table.hideColumn(column[j].field)
        }
      }
    }
  }

  $table.refreshColumn()
  //console.log('$table.getColumns :>> ', $table.getColumns());
  SetTableHeader.value = false
}
// FixedColumn 设置column的固定列(初始化本地数据)
const FixedColumn = () => {
  const $table = xTable.value
  const column = $table.getColumns()

  //console.log('column:>> ', column);
  //console.log('SetInstrumentListArr', SetInstrumentListArr.value);
  for (let i = 0; i < SetInstrumentListArr.value.length; i++) {
    for (let j = 1; j < column.length; j++) {
      if (column[j].title == SetInstrumentListArr.value[i].lable) {
        if (SetInstrumentListArr.value[i].fixed != '') {
          toggleFixedColumn(j, SetInstrumentListArr.value[i].fixed)
        }
      }
    }
  }

  $table.refreshColumn()
  // //console.log('$table.getColumns :>> ', $table.getColumns());
  // SetTableHeader.value = false;
}

//  操作

// 处理出生日期的时间戳
const birthday = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD')
  }
})
// 处理注册时间戳
const createdAt = computed(() => (e) => {
  if (e) {
    return dayjs(e).format('YYYY-MM-DD HH:mm')
  }
})
// ! 显示按钮
let isShow = ref(false)
// mounted
onMounted(() => {
  reqProjectList()

  // 初始话存储数据下
  //console.log('demo1.tableData :>> ', demo1.tableData);
  //处理点击更多操作盒子 以外的地方隐藏盒子
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (showIdOptions.value == true) {
      if (e.target.id != 'MoreOptions') {
        showIdOptions.value = false
      }
    }
  })
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (isshowOperation.value == true) {
      if (e.target.id != 'operationBox') {
        isshowOperation.value = false
      }
    }
  })
  document.addEventListener('click', (e) => {
    // //console.log('e.target.id :>> ', e.target.id);
    if (isShow.value == true) {
      if (e.target.id != 'show_box') {
        isShow.value = false
      }
    }
    // 操作...
    if (ClickMoreOptions.value == true) {
      if (e.target.id != 'CMoreOption') {
        ClickMoreOptions.value = false
      }
      // console.log(ClickMoreOptions.value);
    }
  })
  // 处理显示 功能
  document.addEventListener('click', (e) => {
    // console.log('e.target.id :>> ', e.target.id);
    if (SetShow.value == true) {
      if (e.target.id != 'SetShowsBoxs') {
        SetShow.value = false
      }
    }
  })
})

// note 组件销毁
onBeforeUnmount(() => {
  const table = xTable.value
  console.log('组件销毁时的数值 :>> ', table)
  const FixedList = table.getColumns()
  // console.log('销毁时的列顺序', FixedList);
  for (let i = 1; i < FixedList.length; i++) {
    for (let j = 0; j < SetInstrumentListArr.value.length; j++) {
      if (FixedList[i].field == SetInstrumentListArr.value[j].field) {
        SetInstrumentListArr.value[j].fixed = FixedList[i].fixed
      }
    }
  }

  localStorage.setItem('MPA580ShowHiddenArr', JSON.stringify(SetInstrumentListArr.value))
})
</script>

<style lang="less" scoped>
@import url('@/style/components/Fullinstrument/MPA580Instrument.less');
</style>
