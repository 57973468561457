<template>
  <div></div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { message } from '../utils/resetMessage';

import { defineComponent, ref, onMounted, onBeforeUnmount, computed, watch } from 'vue';
const store = useStore();

let timer = ref(null);
// noOperateTime: 1000 * 60 * 30, // 用户不操作的时间
let noOperateTime = ref(1800000); // 用户不操作的时间 1800000

const isTimeOut = () => {
  //   console.log('进入方法 :>> ');
  clearInterval(timer.value);
  // bug 调试参数
  // console.log('是否超时 :>> ,,', store.state.user.TimeOut);

  if (store.state.user.TimeOut != true) {
    timer.value = setInterval(() => {
      let isLoginHref = window.location.hash; //返回锚点部分，例如："#/TestRecords"

      if (isLoginHref != '#/') {
        console.log('进入if :>> ');
        let lastClickTime = Number(sessionStorage.getItem('lastClickTime'));
        //   console.log('最后的时间lastClickTime :>> ', lastClickTime);
        let nowTime = new Date().getTime();
        if (nowTime - lastClickTime > noOperateTime.value) {
          store.commit('Set_TimeOut', true);
          message({
            ShowClose: true,
            message: '您已超过30分钟未操作系统,请重新登录使用!',
            type: 'warning',
          });
          // console.log('"超时登录了!!!!!!" :>> ', '超时登录了!!!!!!');

          // 退出逻辑
          // this.$router.push('/');
          // // 清除缓存数据
          // localStorage.removeItem('token');
          // localStorage.removeItem('access_token');
        } else {
          store.commit('Set_TimeOut', false);
        }
      }
      // }, 1000 * 5);
    }, 1000 * 60);
  }
};
onMounted(() => {
  // console.log('noOperateTime :>> ', this.noOperateTime);
  // console.log(window);
  /*
        第一步：
          组件初始化加载就绑定监听点击事件，注意：addEventListener的第三个参数，这里要加上。
          因为第三个参数决定了是冒泡还是捕获（false冒泡默认，true捕获），因为绑定监听点击事件，我们是在最
          顶层的DOM位置进行捕获点击事件，所以第三个参数true，要加上的，这样的话，内层的任意地方的点击事件
          我们就都能监听到了，然后存储一下点击的时间
      */
  window.addEventListener(
    'click',
    () => {
      // 为了方便，我们把点击事件的时间直接存到sessionStorage中去，这样方便获取比较
      sessionStorage.setItem('lastClickTime', new Date().getTime());
      isTimeOut();
    },
    true
  );
}),
  onBeforeUnmount(() => {
    // 最后一步，离开页面的时候，清除一下定时器，也解绑点击事件
    clearInterval(timer);
    window.removeEventListener('click', () => {}, true);
  });
</script>
