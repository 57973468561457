
const state={
    Statement:'',//声明
    Cover:"",//封面
    Directory:"",//目录
    Conclusion:"",//结论
    Annex:"",//附件
    SumCheck:0,//获取当前ckeck数量
    ViewReport:'',//查看报告详情
    ReportName:'',
    ReportNumber:"",
    ReportId:'',
    Details:{},//项目详情->审批页面用；
    ReportData1:[],//项目报告数据
    ReportData2:[],//项目报告数据
    ReportData3:[],//项目报告数据
    ReportData4:[],//项目报告数据
    ReportData5:[],//项目报告数据
    ReportData6:[],//项目报告数据
    ReportData7:[],//项目报告数据
}

const mutations={
    Set_ReportId(state,newValue){
        state.ReportId=newValue
    },
    Set_ReportNumber(state,newValue){
        state.ReportNumber=newValue
    },
    Set_ReportName(state,newValue){
        state.ReportName=newValue
    },
    Set_ReportData1(state,newValue){
        state.ReportData1=newValue
    },
    Set_ReportData2(state,newValue){
        state.ReportData2=newValue
    },
    Set_ReportData3(state,newValue){
        state.ReportData3=newValue
    },
    Set_ReportData4(state,newValue){
        state.ReportData4=newValue
    },
    Set_ReportData5(state,newValue){
        state.ReportData5=newValue
    },
    Set_ReportData6(state,newValue){
        state.ReportData6=newValue
    },
    Set_ReportData7(state,newValue){
        state.ReportData7=newValue
    },
    Set_Details(state,newValue){
        state.Details=newValue
    },
    Set_ViewReport(state,newValue){
        state.ViewReport=newValue
    },
    Set_Statement(state,newValue){
        state.Statement=newValue
    },
    Set_Cover(state,newValue){
        state.Cover=newValue
    },
    Set_Directory(state,newValue){
        state.Directory=newValue
    },
    Set_Conclusion(state,newValue){
        state.Conclusion=newValue
    },
    Set_Annex(state,newValue){
        state.Annex=newValue
    },
    Set_SumCheck(state,newValue){
        state.SumCheck=newValue
    },
}


export default{
    state,
    mutations
}